import { LocaleAction } from "./localeActions";
import { LoginAction } from "./loginActions";
import { PlanAction } from "./plansActions";
import { ProjectTreeActions } from "./projectTreeActions";
import { ProfileAction } from "../actions/profileActions";
import { CollectionsAction } from "./collectionsActions";
import { DashboardActions } from "./dashboardActions";
import { WeatherAction } from "./weatherActions";
import { MasterAction } from "./masterActions";
import { CartAction } from "./cartActions";
import { ThunkDispatch } from "redux-thunk";
import { CombinedState } from "redux";
import { SystemCombinedState } from "../reducers/systemReducer";
import { CacheDataActions } from "./cacheDataActions";
import { ProvisoryCartActions } from "./provisoryCartActions";
import { PERSIST, REHYDRATE } from "redux-persist";
import { CacheDataPersistedState } from "../reducers/cacheDataReducer";
import { CartPersistedState } from "../reducers/cartReducer";
import { CollectionsPersistedState } from "../reducers/collectionsReducer";
import { DashboardPersistedState } from "../reducers/dashboardReducer";
import { LocalePersistedState } from "../reducers/localeReducer";
import { LoginPersistedState } from "../reducers/loginReducer";
import { MasterPersistedState } from "../reducers/masterReducer";
import { ProfilePersistedState } from "../reducers/profileReducer";
import { ProvisoryCartPersistedState } from "../reducers/provisoryCartReducer";
import { SystemThunk } from "../systemThunk";
import { persistor } from "..";

type RehydrateActions = {
  type: typeof REHYDRATE;
  payload:
    | CacheDataPersistedState
    | CartPersistedState
    | CollectionsPersistedState
    | DashboardPersistedState
    | LocalePersistedState
    | LoginPersistedState
    | MasterPersistedState
    | ProfilePersistedState
    | ProvisoryCartPersistedState
    | undefined;
};

/**
 * This file re-exports a union of all Action types as a single `SystemAction` type. Every reducer
 * in the system receives all actions, so the type used in each reducer should be `SystemAction`.
 *
 * All new Action types should be added to this union.
 */
export type SystemAction =
  | PlanAction
  | LoginAction
  | LocaleAction
  | ProjectTreeActions
  | ProfileAction
  | CollectionsAction
  | DashboardActions
  | WeatherAction
  | MasterAction
  | CartAction
  | CacheDataActions
  | ProvisoryCartActions
  | RehydrateActions
  | {
      type: typeof PERSIST;
    };

export type Dispatch = ThunkDispatch<SystemCombinedState, unknown, SystemAction>;

export function whenCloseApp(): SystemThunk {
  return async (_, getState) => {
    const loginState = getState().login;
    if (loginState.keepLoggedIn) return;
    await persistor.purge();
  };
}
