import React from "react";
import { Image } from "./styles";

interface Props {
  src?: string;
  alt?: string;
  className?: string;
  appearance?:
    | "img-logo"
    | "img-icon"
    | "img-divulgation"
    | "img-divulgation-banner"
    | "img-rounded"
    | "img-partner"
    | "img-product-card"
    | "img-client-card"
    | "img-avatar"
    | "img-separator-section"
    | "img-logos-together"
    | "tag"
    | "dashboard-icon"
    | "project-icon";
}

export default (props: Props) => {
  return (
    <>
      <Image
        src={props.src}
        alt={props.alt}
        appearance={props.appearance}
        className={props.className}
      />
    </>
  );
};
