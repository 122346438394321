import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Mask } from "./styles";
import { Grid } from "@material-ui/core";
import TextField from "../../Inputs/Field";
import { Fields } from "./styles";

import SearchImage from "../../../../assets/icon/icon_search_thick_without_circle_light.svg";

interface Props {
  color?: "light";
  required?: boolean;
  open?: boolean;
}

export default (props: Props) => {
  const [searchValue, setSearchValue] = useState<string>("");

  //terms
  const intl = useIntl();
  const termSearch = intl.formatMessage({ id: "placeholder.search" });
  return (
    <>
      {/* <Mask color={props.color}> */}
      <Fields
        open={props.open}
      >
        <Grid item xs={12}>
          <TextField
            type={"text"}
            value={searchValue}
            onChange={setSearchValue}
            placeholder={termSearch}
            startAdornment={<img src={SearchImage} style={{ height: 18, width: 18 }} />}
            insertTextFieldPadding={30}
            notSelectedPlaceholderPadding={30}
            labelVerticalPadding={-2}
          />
        </Grid>
      </Fields>
      {/* </Mask> */}
    </>
  );
};
