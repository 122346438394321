import { Location } from "biohub-model";
import _ from "lodash";
import React from "react";
import { MemoMarker } from "../MemoMapComponents";

type BorderMarkersProps = {
  editingPolygon: Location[];
  draggableMarkers: boolean;
  onVertexMoved?: (index: number, newLocation: Location) => void;
  zIndex: number;
};

const _BorderMarkers = function (props: BorderMarkersProps): JSX.Element {
  const { editingPolygon, draggableMarkers } = props;
  return (
    <>
      {editingPolygon.map((l, i) => (
        <MemoMarker
          key={`editing-${i}`}
          position={l}
          draggable={draggableMarkers}
          onDragEnd={(e) => {
            const latLng = e.latLng;
            if (latLng === null) return;

            props.onVertexMoved?.(
              i, // Index of the vertex
              {
                // New location of the vertex that was moved.
                lat: latLng.lat(),
                lng: latLng.lng(),
              }
            );
          }}
          zIndex={props.zIndex}
        />
      ))}
    </>
  );
};

const BorderMarkers = React.memo(_BorderMarkers, (prevProps, newProps) => {
  if (prevProps.draggableMarkers !== newProps.draggableMarkers) return false;
  if (prevProps.onVertexMoved === undefined && newProps.onVertexMoved !== undefined) return false;
  if (!_.isEqual(prevProps.editingPolygon, newProps.editingPolygon)) return false;
  return true;
});

export default BorderMarkers;
