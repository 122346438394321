import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import Link from "../../components/Atomic/BasicComponents/Link";
import Container from "../../components/Atomic/Areas/Container";
import Row from "../../components/Atomic/Areas/Row";
import FormLogin from "../../components/Atomic/Forms/Login";
import Flags from "../../components/Atomic/Tools/FlagLocalePicker";
import { LoginPage, Actions } from "./styles";

// Images
import imageBackground from "../../assets/background/principal.jpg";

interface Props {
  imageBackground?: string | "url";
}

export default (props: Props) => {
  const intl = useIntl();
  return (
    <>
      <LoginPage background={imageBackground}>
        <Container>
          <Actions>
            <Link to="/" appearance={"button-outline"} color={"light"} size={"medium"}>
              {intl.formatMessage({ id: "action.back" })}
            </Link>
          </Actions>
          <Row alignItems={"center"}>
            <FormLogin />
          </Row>
        </Container>
        <Flags />
      </LoginPage>
    </>
  );
};
