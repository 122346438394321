import React from "react";
import _ from "lodash";

import userPointIcon from "../../../../../../assets/icon/user_icon.svg";
import { Location } from "biohub-model";
import { MemoMarker } from "../MemoMapComponents";

export default function UserLocationMarker(props: {
  userLocation: Location | null | undefined;
  zoom: number | undefined;
  zIndex: number;
}): JSX.Element {
  const { scaledSize, anchor } = _getUserIconScaleAndAnchor(props.zoom);
  return (
    <>
      {props.userLocation !== undefined && props.userLocation !== null ? (
        <MemoMarker
          key={`user-location`}
          icon={{
            url: userPointIcon,
            scaledSize: new google.maps.Size(scaledSize, scaledSize),
            anchor: new google.maps.Point(anchor, anchor),
          }}
          position={{
            lat: props.userLocation.lat,
            lng: props.userLocation.lng,
          }}
          zIndex={props.zIndex}
        />
      ) : (
        <></>
      )}
    </>
  );
}

/**
 * Function to dynamically create the size of the user location icon in the map.
 * @param zoom Map Zoom. Value between 1 and 19.
 */
const _getUserIconScaleAndAnchor = (
  zoom: number | undefined
): { scaledSize: number; anchor: number } => {
  const size = zoom !== undefined ? 36 + zoom - 2 : 30;
  return {
    scaledSize: size,
    anchor: ~~(size / 2),
  };
};
