import {
  Box,
  Grid,
  GridSize,
  GridSpacing,
  IconButton,
  makeStyles,
  Slider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import NumericInputColor from "../../BasicComponents/NumericInputColor";
import { Text } from "../../BasicComponents/Text/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

export type CompleteNumberEditorProps = {
  initialValue: number | undefined;
  unitText: string;
  label: string;
  legend?: string;
  minValue?: number;
  maxValue?: number;
  inComingConversionFactor: number;
  outComingConversionFactor: number;
  onChange: (n: number) => void;
  validateValue: (valid: boolean) => void;
  disabled?: boolean;
  decimalPrecision?: number;
  sliderStep?: number;
  fieldStep?: number;
  colorScheme: ColorScheme;
  gridScheme: GridScheme;
};

export type ColorScheme = "default" | "primary";
export type GridScheme = "default" | "spaced";

function CompleteNumberEditor(props: CompleteNumberEditorProps): JSX.Element {
  const [value, _setValue] = useState<number>(props.initialValue ?? 0);
  const [textInput, _setTextInput] = useState<string>(
    getInputTextAccordingValue({
      value: props.initialValue ?? 0,
      outComingConversionFactor: props.outComingConversionFactor,
      decimalPrecision: props.decimalPrecision,
    })
  );

  const inComingConversionFactor = props.inComingConversionFactor ?? 1;
  const outComingConversionFactor = props.outComingConversionFactor ?? 1;

  const setTextInput = (input: string) => {
    const value = parseFloat(input);
    if (Number.isNaN(value)) {
      _setValue(0);
    } else {
      props.onChange(value);
      _setValue(
        getDecimalPrecisionValue({
          value: value * inComingConversionFactor,
          decimalPrecision: props.decimalPrecision,
        })
      );
    }
    _setTextInput(input);
  };

  useEffect(() => {
    setTextInput(
      getDecimalPrecisionValue({
        value: value * props.outComingConversionFactor,
        decimalPrecision: props.decimalPrecision,
      }).toString()
    );
  }, [props.outComingConversionFactor]);

  const minValue = getMinValue({
    minValue: props.minValue,
    decimalPrecision: props.decimalPrecision,
  });
  const maxValue = getMaxValue({
    maxValue: props.maxValue,
    decimalPrecision: props.decimalPrecision,
  });

  const [isValidValue, setIsValidValue] = useState<boolean>(
    props.initialValue !== undefined &&
      props.initialValue < maxValue &&
      props.initialValue > minValue
  );
  useEffect(() => {
    let valid = true;
    const value = parseFloat(textInput);
    if (Number.isNaN(value)) {
      valid = false;
    } else {

      if (value > maxValue || value < minValue) {
        valid = false;
      }
    }
    setIsValidValue(valid);
    props.validateValue(valid);
  }, [textInput, props.minValue, props.maxValue]);

  const sliderStep = getSliderStep(props.sliderStep);

  const color = props.colorScheme === "primary" ? "var(--primary)" : undefined;
  const disabledColor = "#858585";
  const sliderColor = color ?? "black";
  const classes = useStyles({ color: color })();

  const inputControllerSpacing = getGridSpacing(props.gridScheme);
  const configScreenGridSizes = getGridScreenSizes(props.gridScheme);

  const intl = useIntl();

  return (
    <div style={{ paddingBottom: 2 }}>
      <Grid
        container
        justify="center"
        spacing={inputControllerSpacing}
        xs={12}
        // style={{ opacity: props.opacity }}
      >
        <Grid item xs={configScreenGridSizes[0]}>
          <Box height={10} />
          <NumericInputColor
            disabled={props.disabled}
            value={textInput}
            unit={props.unitText}
            isOutOfRange={!isValidValue}
            label={props.label}
            legend={props.legend}
            onChange={(text, _) => {
              setTextInput(text);
            }}
            onBlur={() => {
              if (textInput === "") {
                const convertedValue = minValue * outComingConversionFactor;
                setTextInput(
                  getDecimalPrecisionValue({
                    value: convertedValue,
                    decimalPrecision: props.decimalPrecision,
                  }).toString()
                );
              }
            }}
            step={props.fieldStep ?? 1}
          />
        </Grid>
        <Grid item xs={configScreenGridSizes[1]}>
          <Grid container xs={12}>
            <Grid item xs={11}>
              <Text
                style={{
                  color: props.disabled ? disabledColor : sliderColor,
                }}
              >
                {formatNumber(minValue, props.decimalPrecision, intl)}
              </Text>
            </Grid>

            <Grid item xs={1}>
              <Text
                style={{
                  color: props.disabled ? disabledColor : sliderColor,
                }}
              >
                {formatNumber(maxValue, props.decimalPrecision, intl)}
              </Text>
            </Grid>
          </Grid>

          <Slider
            disabled={props.disabled}
            style={{
              color: props.disabled ? disabledColor : sliderColor,
            }}
            value={value * outComingConversionFactor}
            min={minValue * outComingConversionFactor}
            max={maxValue}
            defaultValue={0}
            aria-labelledby="discrete-slider-always"
            step={sliderStep}
            onChange={(_, value) => {
              const decimalPrecisionValue = (value as number).toFixed(
                props.decimalPrecision ?? defaultDecimalPrecision
              );
              setTextInput(decimalPrecisionValue);
            }}
          />
          <Box height={5} />
        </Grid>
        <Grid item xs={configScreenGridSizes[2]}>
          <Box height={15} />
          <Grid container justify="center" spacing={1} xs={12}>
            <Grid item xs={6}>
              <IconButton
                className={classes.button}
                size="small"
                disabled={(() => {
                  if (props.disabled) return true;

                  const newValue = value - sliderStep * inComingConversionFactor;
                  if (newValue < minValue) return true;
                  return false;
                })()}
                onClick={(_) => {
                  const newValue = value - sliderStep * inComingConversionFactor;
                  if (newValue < minValue) return;

                  setTextInput(
                    getDecimalPrecisionValue({
                      value: newValue * outComingConversionFactor,
                      decimalPrecision: props.decimalPrecision,
                    }).toString()
                  );
                }}
              >
                <RemoveIcon className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton
                disabled={(() => {
                  if (props.disabled) return true;

                  const newValue = value + sliderStep * inComingConversionFactor;
                  if (newValue > maxValue) return true;
                  return false;
                })()}
                className={classes.button}
                size="small"
                onClick={(e) => {
                  const newValue = value + sliderStep * inComingConversionFactor;
                  if (newValue > maxValue) return;

                  setTextInput(
                    getDecimalPrecisionValue({
                      value: newValue * outComingConversionFactor,
                      decimalPrecision: props.decimalPrecision,
                    }).toString()
                  );
                }}
              >
                <AddIcon className={classes.icon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const getInputTextAccordingValue = (props: {
  value: number;
  outComingConversionFactor: number;
  decimalPrecision: number | undefined;
}) => {
  const { value, outComingConversionFactor, decimalPrecision } = props;
  return (value * outComingConversionFactor).toFixed(decimalPrecision ?? defaultDecimalPrecision);
};

const getMinValue = (props: {
  minValue: number | undefined;
  decimalPrecision: number | undefined;
}): number => {
  return getDecimalPrecisionValue({
    value: props.minValue ?? defaultMinimalValue,
    decimalPrecision: props.decimalPrecision,
  });
};

const getDecimalPrecisionValue = (props: {
  value: number;
  decimalPrecision: number | undefined;
}) => {
  const { value, decimalPrecision } = props;

  return parseFloat(
    (value ?? defaultMinimalValue).toFixed(decimalPrecision ?? defaultDecimalPrecision)
  );
};

const getMaxValue = (props: {
  maxValue: number | undefined;
  decimalPrecision: number | undefined;
}): number => {
  return getDecimalPrecisionValue({
    value: props.maxValue ?? defaultMaximalValue,
    decimalPrecision: props.decimalPrecision,
  });
};

const getSliderStep = (sliderStep: number | undefined) => {
  return sliderStep ?? defaultSliderStep;
};

const getGridSpacing = (gridScheme: GridScheme): GridSpacing => {
  switch (gridScheme) {
    case "default":
      return defaultGridSpacing;
    case "spaced":
      return spacedGridSpacing;
  }
};

const getGridScreenSizes = (gridScheme: GridScheme): GridSize[] => {
  switch (gridScheme) {
    case "default":
      return defaultGridScreenSize;
    case "spaced":
      return spacedGridScreenSize;
  }
};

function formatNumber(
  number: number,
  decimalPrecision: number | undefined,
  intl: IntlShape
): string {
  return intl.formatNumber(number, {
    maximumFractionDigits: decimalPrecision ?? defaultDecimalPrecision,
    notation: "compact",
    compactDisplay: "short",
  });
}

const useStyles = (props: { color?: string }) =>
  makeStyles((theme) => ({
    button: {
      color: props.color !== undefined ? props.color : "black",
      fontSize: "small",
      margin: theme.spacing(1),
    },
    icon: {
      fontSize: 17,
    },
  }));

const defaultDecimalPrecision = 3;
const defaultMinimalValue = 0;
const defaultMaximalValue = 1000;
const defaultSliderStep = 1;
const defaultGridSpacing: GridSpacing = 2;
const spacedGridSpacing: GridSpacing = 3;
const defaultGridScreenSize: GridSize[] = [5, 5, 2];
const spacedGridScreenSize: GridSize[] = [3, 8, 1];

export default React.memo(CompleteNumberEditor, (prevProps, newProps) => {
  if (prevProps.unitText !== newProps.unitText) return false;
  if (prevProps.label !== newProps.label) return false;
  if (prevProps.legend !== newProps.legend) return false;
  if (prevProps.minValue !== newProps.minValue) return false;
  if (prevProps.maxValue !== newProps.maxValue) return false;
  if (prevProps.inComingConversionFactor !== newProps.inComingConversionFactor) return false;
  if (prevProps.onChange !== newProps.onChange) return false;
  if (prevProps.disabled !== newProps.disabled) return false;

  return true;
});
