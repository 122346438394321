import { Area } from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { deleteArea } from "../../../store/actions/projectTreeActions";
import { SystemState } from "../../../store/reducers/systemReducer";
import DeleteModal from "./DeleteModal";

type Props = {
  area: Area;
  onCancel: () => void;
  onFinish: () => void;
};

export default function (props: Props): JSX.Element {
  const [stage, setStage] = useState<"confirmation" | "deleting">("confirmation");

  const deleting = useSelector((state: SystemState) => {
    const projectId = props.area.projectId;
    const areaId = props.area.id;

    const projectTreeState = state.projectTree;
    const area = projectTreeState.projectList
      ?.find((project) => project.id === projectId)
      ?.areas?.find((area) => area.id === areaId);

    return area?.beingDeleted ?? false;
  });

  useEffect(() => {
    if (deleting && stage !== "deleting") {
      setStage("deleting");
    }
    if (stage === "deleting" && !deleting) {
      props.onFinish();
    }
  }, [stage, deleting]);

  const dispatch = useDispatch();
  const intl = useIntl();

  const termDeleteArea = intl.formatMessage({ id: "map.deleteArea" });
  const termDeletingArea = intl.formatMessage({ id: "map.deletingArea" });
  const termProceed = intl.formatMessage({ id: "map.deletingArea.question" });

  return (
    <DeleteModal
      title={termDeleteArea}
      label={`${termDeletingArea} ${props.area?.name ?? "??"}. ${termProceed}`}
      loading={deleting}
      onClose={props.onCancel}
      onConfirm={() => {
        dispatch(deleteArea(props.area.projectId, props.area.id));
      }}
    />
  );
}
