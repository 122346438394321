import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { addItemCart } from "../../../../store/actions/cartActions";
import Button from "../../BasicComponents/Button";
import Image from "../../BasicComponents/Image";
import Clickable from "../../BasicComponents/Link";
import Text from "../../BasicComponents/Text";
import { Actions, Block, Description } from "./styles";
import { Item } from "../SimpleCarousel/styles";

interface Props {
  key?: string;
  productKey: string;
  image: string;
  title: string;
  description: string;
  price?: string;
  showButtons?: boolean | true;
  link?: string;
}

export default (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleAddCart = async (item: any) => {
    dispatch(addItemCart(item));
  };

  return (
    <>
      <Block key={props.key}>
        <Image alt={props.title} appearance={"img-product-card"} src={props.image} />
        <Description>
          <Text text={props.title} appearance={"title-semibold"} size={"small"} color={"primary"} />
          <Text text={props.description} appearance={"description-product"} color={"primary"} />
        </Description>
        {props.showButtons && (
          <Actions>
            {props.productKey && (
              <Clickable to={props.link}>
                <Button
                  text={intl.formatMessage({ id: "section.buy" })}
                  appearance={"outline-more"}
                  color={"highlighted"}
                />
              </Clickable>
            )}
          </Actions>
        )}
      </Block>
    </>
  );
};
