import styled from "styled-components";
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type Props = {
  color?: "primary";
};

export const Carousel = styled(Slider).attrs(
  (
    props: Props & {
      flewFlow?: "column" | "nowrap";
    }
  ) => ({
    color: props.color,
  })
)`
  margin: 2rem 0;
  ${(
    props: Props & {
      flewFlow?: "column" | "nowrap";
    }
  ) =>
    props.flewFlow !== undefined
      ? `
      flex-flow: ${props.flewFlow};
    `
      : `
      flew-flow: nowrap;
    `}

  ul {
    li {
      display: flex;
      justify-content: center;
    }
  }

  .react-multiple-carousel__arrow {
    background: var(--transparent);
    border: 3px solid var(--primary);
    :hover,
    :focus {
      background: var(--secondary);
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  ${(props: Props) =>
    props.color === "primary" &&
    `
      .react-multiple-carousel__arrow {
        background: var(--primary);
        border: 3px solid var(--white);
      }
  `}
`;
