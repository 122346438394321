import React from "react";
import { useIntl } from "react-intl";
import Column from "../../Areas/Column";
import Container from "../../Areas/Container";
import Row from "../../Areas/Row";
import Image from "../../BasicComponents/Image";
import Text from "../../BasicComponents/Text";
import Header from "../../Header";
import { Banner, Infos, Logo, TitleStyle } from "./styles";

interface Props {
  image?: string | "url";
  imageSize?: number;
  overlay?: boolean;
  logo?: string;
  title?: string;
  smallTitle?: string;
  largeTitle?: string;
  description?: string;
  children?: React.ReactNode;
  id?: string;
  showButtons?: boolean | true;
  darkenImage?: number;
}

export default (props: Props) => {
  const intl = useIntl();
  return (
    <Banner
      overlay={props.overlay}
      imageSize={props.imageSize}
      background={props.image}
      id={props.id}
      darkenBackground={props.darkenImage}
    >
      <Header />
      <Container>
        <Infos>
          <Row alignItems="center">
            {props.showButtons && <Column lg={1} md={1} sm={0} xs={0}></Column>}
            <Column lg={6} md={7} sm={7} xs={12}>
              {!props.smallTitle || !props.largeTitle ? (
                <TitleStyle>
                  <Text text={props.title} appearance={"title-bold"} color={"light"} />
                </TitleStyle>
              ) : (
                <>
                  <Text
                    text={props.smallTitle}
                    appearance={"title"}
                    color={"light"}
                    size={"medium"}
                  />
                  <Text
                    text={props.largeTitle}
                    appearance={"title"}
                    color={"light"}
                    size={"x-large"}
                  />
                </>
              )}
              <Text text={props.description} color={"light"} appearance={"description-banner"} />
              {/* {props.showButtons && (
                <Actions>
                  <Clickable to="/login">
                    <Button
                      text={intl.formatMessage({ id: "action.getStarted" })}
                      color={"primary-banner"}
                      size={"large"}
                      visible={true}
                    />
                  </Clickable>
                  <Clickable to="/about">
                    <Button
                      text={intl.formatMessage({ id: "action.learnMore" })}
                      color={"inherit"}
                      appearance={"outline-more"}
                      size={"large"}
                      visible={true}
                    />
                  </Clickable>
                </Actions>
              )} */}
            </Column>
            <Column lg={4} md={3} sm={4} xs={12}>
              {props.logo ? (
                <>
                  <Logo
                    slogan={intl.formatMessage({ id: "info.slogan" })}
                    lang={intl.formatMessage({ id: "language.region" })}
                  >
                    <Image
                      src={props.logo}
                      alt={intl.formatMessage({ id: "info.slogan" })}
                      appearance={"img-divulgation-banner"}
                    />
                  </Logo>
                </>
              ) : (
                <>
                  <b></b>
                </>
              )}
            </Column>
            {props.showButtons && <Column lg={1} md={1} sm={0} xs={0}></Column>}
          </Row>
        </Infos>
      </Container>
    </Banner>
  );
};
