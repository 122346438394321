import {
  Cpu,
  CpuModel,
  DeviceModel,
  Drone,
  DroneModel,
  Input,
  Releaser,
  ReleaserModel,
  Role,
} from "biohub-model";
import { Store } from "redux";
import { BiohubError, BiohubResponse } from "../../services/axios/BiohubApi";
import EquipmentService from "../../services/EquipmentService";
import { SystemState } from "../reducers/systemReducer";
import { SystemThunk } from "../systemThunk";
import {
  getCpuModels,
  getCpus,
  getDeviceModels,
  getDroneModels,
  getDrones,
  getInputs,
  getReleaserModels,
  getReleasers,
} from "./cacheDataActions";
import { Dispatch, SystemAction } from "./systemActions";
import {
  biobotModelId,
  biocoteModelId,
  biomiteModelId,
} from "../../core/helper/ReleaserModelHelper";
import { DroneModelInCollectionState } from "../reducers/collectionsReducer";

import matrix100Image from "../../assets/icon/matrice100.png";
import matrix200Image from "../../assets/icon/matrice200.png";
import phantom4Image from "../../assets/icon/phantom4.png";

export enum CollectionsActionsContext {
  releasers,
  releaserModels,
  drones,
  droneModels,
  cpus,
  cpuModels,
  inputs,
  deviceModels,
}

export const SET_LOADING_COLLECTION_CONTEXT = "SET_LOADING_COLLECTION_CONTEXT";
export const SET_ERROR_COLLECTION_CONTEXT = "SET_ERROR_COLLECTION_CONTEXT";
export const SET_DATA_COLLECTION_CONTEXT = "SET_DATA_COLLECTION_CONTEXT";
export const SET_lOADING_COLLECTION_CONTEXT_ITEM = "SET_lOADING_COLLECTION_CONTEXT_ITEM";
export const SET_DATA_COLLECTION_CONTEXT_ITEM = "SET_DATA_COLLECTION_CONTEXT_ITEM";

export type CollectionsAction =
  | {
      type: typeof SET_LOADING_COLLECTION_CONTEXT;
      payload: {
        context: CollectionsActionsContext;
      };
    }
  | {
      type: typeof SET_ERROR_COLLECTION_CONTEXT;
      payload: {
        context: CollectionsActionsContext;
        error: BiohubError;
      };
    }
  | {
      type: typeof SET_DATA_COLLECTION_CONTEXT;
      payload:
        | {
            context: CollectionsActionsContext.cpuModels;
            data: CpuModel[];
          }
        | {
            context: CollectionsActionsContext.cpus;
            data: Cpu[];
          }
        | {
            context: CollectionsActionsContext.droneModels;
            data: DroneModelInCollectionState[];
          }
        | {
            context: CollectionsActionsContext.drones;
            data: Drone[];
          }
        | {
            context: CollectionsActionsContext.releaserModels;
            data: ReleaserModel[];
          }
        | {
            context: CollectionsActionsContext.releasers;
            data: Releaser[];
          }
        | {
            context: CollectionsActionsContext.inputs;
            data: Input[];
          }
        | {
            context: CollectionsActionsContext.deviceModels;
            data: DeviceModel[];
          };
    }
  | {
      type: typeof SET_lOADING_COLLECTION_CONTEXT_ITEM;
      payload: {
        context: CollectionsActionsContext.cpus;
        cpuId: string;
        loading: boolean;
      };
    }
  | {
      type: typeof SET_DATA_COLLECTION_CONTEXT_ITEM;
      payload: {
        context: CollectionsActionsContext.cpus;
        cpu: Cpu;
      };
    };

export function fetchCollections(): SystemThunk {
  return async (dispatch) => {
    dispatch(fetchInputs());
    dispatch(fetchReleasers());
    dispatch(fetchReleaserModels());
    dispatch(fetchDrones());
    dispatch(fetchDroneModels());
    dispatch(fetchCpus());
    dispatch(fetchCpuModels());
    dispatch(fetchDeviceModels());
  };
}

function _fetchCollection<T>(
  actionsContext: CollectionsActionsContext,
  getFunction: () => Promise<{ data: T[] | undefined; error: BiohubError | undefined }>
): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await getFunction();
    if (result.error !== undefined) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    }
    if (result.data !== undefined) {
      dispatch({
        type: SET_DATA_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          data: result.data as any,
        },
      });
    }
  };
}

export function fetchReleasers(): SystemThunk {
  return async (dispatch, getState) => {
    const profile = getState().profile.userProfile;
    if (profile == null || profile.directClientId === null) return;

    dispatch(
      _fetchCollection(CollectionsActionsContext.releasers, async () => {
        const result = await getReleasers(
          dispatch,
          profile.role === Role.master ? undefined : profile.directClientId
        );
        return { data: result.releasers, error: result.error };
      })
    );
  };
}

export function fetchReleaserModels(): SystemThunk {
  return async (dispatch) => {
    dispatch(
      _fetchCollection(CollectionsActionsContext.releaserModels, async () => {
        const result = await getReleaserModels(dispatch);
        return { data: result.releaserModels, error: result.error };
      })
    );
  };
}

export function fetchDrones(): SystemThunk {
  return async (dispatch, getState) => {
    const profile = getState().profile.userProfile;
    if (profile == null || profile.directClientId === null) return;

    dispatch(
      _fetchCollection(CollectionsActionsContext.drones, async () => {
        const result = await getDrones(
          dispatch,
          profile.role === Role.master ? undefined : profile.directClientId
        );
        return { data: result.drones, error: result.error };
      })
    );
  };
}

export function fetchDroneModels(): SystemThunk {
  return async (dispatch) => {
    dispatch(
      _fetchCollection(CollectionsActionsContext.droneModels, async () => {
        const result = await getDroneModels(dispatch);
        return {
          data: result.droneModels?.map(droneModelToCollectionsStateDroneModel),
          error: result.error,
        };
      })
    );
  };
}

export function fetchCpus(): SystemThunk {
  return async (dispatch, getState) => {
    const profile = getState().profile.userProfile;
    if (profile == null || profile.directClientId === null) return;

    dispatch(
      _fetchCollection(CollectionsActionsContext.cpus, async () => {
        const result = await getCpus(
          dispatch,
          profile.role === Role.master ? undefined : profile.directClientId
        );
        return { data: result.cpus, error: result.error };
      })
    );
  };
}

export function fetchCpuModels(): SystemThunk {
  return async (dispatch) => {
    dispatch(
      _fetchCollection(CollectionsActionsContext.cpuModels, async () => {
        const result = await getCpuModels(dispatch);
        return { data: result.cpuModels, error: result.error };
      })
    );
  };
}

export function fetchInputs(): SystemThunk {
  return async (dispatch) => {
    dispatch(
      _fetchCollection(CollectionsActionsContext.inputs, async () => {
        const result = await getInputs(dispatch);
        return { data: result.inputs, error: result.error };
      })
    );
  };
}

export function fetchDeviceModels(): SystemThunk {
  return async (dispatch) => {
    dispatch(
      _fetchCollection(CollectionsActionsContext.deviceModels, async () => {
        const result = await getDeviceModels(dispatch);
        return { data: result.deviceModels, error: result.error };
      })
    );
  };
}

export function additionDrone(drone: Drone): SystemThunk {
  return async (dispatch) => {
    const actionsContext = CollectionsActionsContext.drones;
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await EquipmentService.createDrone(drone);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    } else {
      dispatch(fetchDrones());
    }
  };
}

export function updateDrone(id: string, drone: Partial<Drone>): SystemThunk {
  return async (dispatch) => {
    const actionsContext = CollectionsActionsContext.drones;
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await EquipmentService.updateDrone(id, drone);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    } else {
      dispatch(fetchDrones());
    }
  };
}

export function remotionDrone(id: string): SystemThunk {
  return async (dispatch) => {
    const actionsContext = CollectionsActionsContext.drones;
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await EquipmentService.deleteDrone(id);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    } else {
      dispatch(fetchDrones());
    }
  };
}

export function additionReleaser(
  releaser: Omit<Releaser, "serialNumber" | "devices">
): SystemThunk {
  return async (dispatch) => {
    const actionsContext = CollectionsActionsContext.releasers;
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await EquipmentService.createReleaser(releaser);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    } else {
      dispatch(fetchReleasers());
    }
  };
}

export function updateReleaser(releaser: { id: string } & Partial<Releaser>): SystemThunk {
  return async (dispatch) => {
    const actionsContext = CollectionsActionsContext.releasers;
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await EquipmentService.updateReleaser(releaser);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    } else {
      dispatch(fetchReleasers());
    }
  };
}

export function remotionReleaser(releaserId: string): SystemThunk {
  return async (dispatch) => {
    const actionsContext = CollectionsActionsContext.releasers;
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await EquipmentService.deleteReleaser(releaserId);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    } else {
      dispatch(fetchReleasers());
    }
  };
}

export function addCpu(cpu: Omit<Cpu, "serialNumber">): SystemThunk {
  return async (dispatch) => {
    const actionsContext = CollectionsActionsContext.cpus;
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await EquipmentService.addCpu(cpu);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    }
    dispatch(fetchCpus());
  };
}

export function updateCpu(cpu: Partial<Cpu>, cpuId: string): SystemThunk {
  return async (dispatch) => {
    const actionsContext = CollectionsActionsContext.cpus;
    dispatch({
      type: SET_LOADING_COLLECTION_CONTEXT,
      payload: {
        context: actionsContext,
      },
    });

    const result = await EquipmentService.updateCpu(cpu, cpuId);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: actionsContext,
          error: result.error,
        },
      });
    }
    dispatch(fetchCpus());
  };
}

function droneModelToCollectionsStateDroneModel(
  droneModel: DroneModel
): DroneModelInCollectionState {
  /// TODO: Remove that compatible drone releasers mock
  /// DJI Matrix 200
  if (droneModel.id === "3395887c-a3c2-4917-a583-c2c851193b36") {
    return {
      ...droneModel,
      compatibleReleaserModelIds: [biobotModelId, biocoteModelId, biomiteModelId],
      imagePath: matrix200Image,
    };
  }
  /// DJI Matrix 100 drone
  if (droneModel.id === "084375b9-6ab4-4a8f-8cbe-33531d9dc779") {
    return {
      ...droneModel,
      compatibleReleaserModelIds: [biobotModelId, biocoteModelId],
      imagePath: matrix100Image,
    };
  }

  /// DJI Phantom 4
  return {
    ...droneModel,
    compatibleReleaserModelIds: [biobotModelId],
    imagePath: phantom4Image,
  };
}

export function addCpuBlock(cpuId: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_lOADING_COLLECTION_CONTEXT_ITEM,
      payload: {
        context: CollectionsActionsContext.cpus,
        cpuId: cpuId,
        loading: true,
      },
    });

    const result = await EquipmentService.createCpuBlock(cpuId);
    if (!result.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: CollectionsActionsContext.cpus,
          error: result.error,
        },
      });
    }

    dispatch(
      reloadCpu(cpuId, async (dispatch) => {
        dispatch({
          type: SET_lOADING_COLLECTION_CONTEXT_ITEM,
          payload: {
            context: CollectionsActionsContext.cpus,
            cpuId: cpuId,
            loading: false,
          },
        });
      })
    );
  };
}

function reloadCpu(cpuId: string, afterLoad: (dispatch: Dispatch) => Promise<void>): SystemThunk {
  return async (dispatch) => {
    const readCpuResult = await EquipmentService.readCpu(cpuId);
    if (!readCpuResult.success) {
      dispatch({
        type: SET_ERROR_COLLECTION_CONTEXT,
        payload: {
          context: CollectionsActionsContext.cpus,
          error: readCpuResult.error,
        },
      });
    } else {
      dispatch({
        type: SET_DATA_COLLECTION_CONTEXT_ITEM,
        payload: {
          context: CollectionsActionsContext.cpus,
          cpu: readCpuResult.data,
        },
      });
    }

    await afterLoad(dispatch);
  };
}

export function removeCpuBlocks(cpuId: string, blockIds: string[]): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: SET_lOADING_COLLECTION_CONTEXT_ITEM,
      payload: {
        context: CollectionsActionsContext.cpus,
        cpuId: cpuId,
        loading: true,
      },
    });

    const results = await Promise.all(
      blockIds.map((blockId) => EquipmentService.unblockCpuBlock(blockId))
    );
    const resultErros = results
      .filter((result) => !result.success)
      .map(
        (result) =>
          result as {
            success: false;
            error: BiohubError;
          }
      );

    if (resultErros.length > 0) {
      resultErros.forEach((resultError) => {
        dispatch({
          type: SET_ERROR_COLLECTION_CONTEXT,
          payload: {
            context: CollectionsActionsContext.cpus,
            error: resultError.error,
          },
        });
      });
    }

    dispatch(
      reloadCpu(cpuId, async (dispatch) => {
        dispatch({
          type: SET_lOADING_COLLECTION_CONTEXT_ITEM,
          payload: {
            context: CollectionsActionsContext.cpus,
            cpuId: cpuId,
            loading: false,
          },
        });
      })
    );
  };
}
