import styled from "styled-components";

type Props = {
  color?: "primary";
  textAlign?: string;
  width?: string;
};

export const Information = styled.div.attrs((props: Props) => ({
  color: props.color,
  textAlign: props.textAlign,
  width: props.width,
}))`
  width: 80%;
  height: auto;
  display: table;
  color: var(--text-primary);

  .detailsProduct {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    C

    a {
      button {
        color: var(--white);
        background: var(--primary);
      }
    }
  }

  ${(props: Props) =>
    props.color === "primary" &&
    `
      margin-left: 5rem;
      color: var(--third);

      label {
        &:first-child{
          color: var(--third) !important;
        }
    
        &:nth-child(2) {
          color: var(--white);
        }
      }

      button {
        background: var(--white);
        color: var(--primary);
        border: 2px solid var(--primary);
        &:after {
          border-left-color: var(--white);
        }
      }

      @media screen and (max-width: 1024px) {
        margin-left: 2.5rem;
      }
  `}

  ${(props) =>
    props.width &&
    `
    width: ${props.width}%;
  `}

  ${(props: Props) =>
    props.textAlign === "center" &&
    `
    text-align: center;
  `}

  @media screen and (max-width: 680px) {
    width: 100%;
    margin: 0;
  }
`;

export const Actions = styled.span.attrs((props: Props) => ({}))`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  > a {
    & + a {
      margin-top: 1rem;

      @media (max-width: 580px) {
        margin-top: 0;
      }
    }
  }

  button {
    max-height: 35px;
  }
`;
