import { Button, Icon, Modal } from "@material-ui/core";
import React from "react";
import { ModalStyle, ModalTitle } from "./styles";

type Props = {
  showModal: boolean;
  width?: number;
  height?: number;
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  modalLeftShift?: number;
  modalTopShift?: number;
  lockedIcon?: boolean;
  setLocked?: (value: boolean) => void;
};

/**
 * Container for wrapping all modal dialogs with the correct style.
 */
export function ModalContainer(props: Props): JSX.Element {
  const lockType = props.lockedIcon ? "lock" : "lock_open";
  return (
    <Modal open={props.showModal} hideBackdrop>
      <ModalStyle
        style={{ width: props.width, height: props.height }}
        topShift={props.modalTopShift}
        leftShift={props.modalLeftShift}
      >
        {props.lockedIcon !== undefined && (
          <Button
            onClick={() =>
              props.setLocked !== undefined ? props.setLocked(!props.lockedIcon) : undefined
            }
            size="small"
            className={props.lockedIcon ? "lock-modal-closed" : "lock-modal-open"}
          >
            <Icon fontSize="small">{lockType}</Icon>
          </Button>
        )}
        <Button
          onClick={() => {
            props.onClose?.();
          }}
          size="small"
          className="close-modal"
        >
          <Icon fontSize="small">close</Icon>
        </Button>
        {props.title && <ModalTitle>{props.title}</ModalTitle>}
        {props.children}
      </ModalStyle>
    </Modal>
  );
}
