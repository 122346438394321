import React from "react";
import { useIntl } from "react-intl";
import Container from "../../components/Atomic/Areas/Container";
import Row from "../../components/Atomic/Areas/Row";
import Column from "../../components/Atomic/Areas/Column";
import Header from "../../components/Atomic/Header";
import Footer from "../../components/Atomic/Footer";
import Text from "../../components/Atomic/BasicComponents/Text";
import Button from "../../components/Atomic/BasicComponents/Button";
import {
  VerificationPage,
  ContentPage,
  DetailsDescription,
  DetailsData,
  DetailsCode,
  DetailsDataCode,
} from "./styles";

import {
  IconButton,
  Input,
  InputAdornment,
  FormControl,
  TextField,
  CircularProgress,
  Box,
  Icon,
  Button as MaterialButton,
  makeStyles,
} from "@material-ui/core";
import { Create, Done } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import ProfileService from "../../services/ProfileService";
import Countdown from "react-countdown";
import { addMinutes } from "date-fns";
import { BiohubErrorCode } from "../../services/axios/BiohubApi";

interface Props {}

export default (props: Props) => {
  // Here we need to create the setup account process
  // The user will see your basic informations like:
  // - An "email verified with success message"
  // - Your partial email
  // - Your partial cellphone
  // - A text field to enter the sms verification code
  // - A button to request a new code an a limit timer of the sended verication code
  // - Two text fields to fill in the password twice
  // - A button to finish the account creation

  // And maybe, in that same page we can request a new setup email,
  // so in that case, we will have an text field input to enter the email,
  // and after that a message request the user to go to your email.

  const intl = useIntl();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get("token");

  /**
   * Stage state variable.
   * The first stage is the enter date state.
   * The second stage is the success stage.
   * The third stage is the failure stage.
   */
  const [stage, setStage] = React.useState<1 | 2 | 3>(1);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [partialAccountInfo, setPartialAccountInfo] = React.useState<
    { partialEmail: string; partialNumber: string } | undefined
  >(undefined);
  const [codeValidUntil, setCodeValidUntil] = React.useState<Date | undefined | null>(undefined);

  const sendNewCode = async () => {
    if (token === null) return;
    setCodeValidUntil(undefined);
    const codeResult = await ProfileService.setupAccountRequestSms(token);
    if (!codeResult.success) {
      setCodeValidUntil(null);
      return;
    }
    setCodeValidUntil(codeResult.data.validUntil);
  };

  React.useEffect(() => {
    async function result(): Promise<void> {
      if (token !== null) {
        setLoading(true);
        const partialProfileData = await ProfileService.beginSetupAccount(token);
        if (partialProfileData.success) {
          setPartialAccountInfo(partialProfileData.data);
        } else {
          setStage(3);
        }
        setLoading(false);
        sendNewCode();
      }
    }
    result();
  }, [token]);

  const [inputSmsCode, setInputSmsCode] = React.useState<string>("X X X X X X");
  const [inputPassword, setInputPassword] = React.useState<string>("");
  const [inputConfirmPassword, setInputConfirmPassword] = React.useState<string>("");
  const [smsError, setSmsError] = React.useState<boolean>(false);

  //terms
  const termEmailVerification = intl.formatMessage({ id: "setupAccount.verifiedEmail" });
  const termTitle = intl.formatMessage({ id: "setupAccount.title" });
  const termDetails = intl.formatMessage({ id: "setupAccount.verification.description" });
  const termVerification = intl.formatMessage({ id: "setupAccount.verification.code" });

  const termDetailsConfirmation = intl.formatMessage({
    id: "setupAccount.verification.codeDescription",
  });
  const termActionSubmitConfirmation = intl.formatMessage({ id: "action.confirm" });
  const termAboutInfo = intl.formatMessage({ id: "info.about" });
  const termEmail = intl.formatMessage({ id: "info.emailAddress" });
  const termPhoneNumber = intl.formatMessage({ id: "placeholder.phone" });

  const termPassword = intl.formatMessage({ id: "setup.password" });
  const termConfirmPassword = intl.formatMessage({ id: "setup.confirmPassword" });

  const termDifferentPassword = intl.formatMessage({ id: "setup.password.different" });
  const termPasswordUppercaseElement = intl.formatMessage({ id: "setup.password.needUpperCase" });
  const termSpecialElement = intl.formatMessage({ id: "setup.password.needSpecialElement" });
  const termLength = intl.formatMessage({ id: "setup.password.lenght" });
  const termAccountCreated = intl.formatMessage({ id: "setupAccount.created" });
  const termError = intl.formatMessage({ id: "setupAccount.error" });
  const termTryAgain = intl.formatMessage({ id: "setupAccount.error.tryAgain" });
  const termWrongSms = intl.formatMessage({ id: "setupAccount.error.wrongSmsCode" })

  let validPassword = true;
  let passwordLabel = "";
  if (inputPassword.length < 6) {
    passwordLabel = passwordLabel + termLength + ". ";
    validPassword = false;
  }
  if (!/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(inputPassword)) {
    passwordLabel = passwordLabel + termSpecialElement + ". ";
    validPassword = false;
  }
  if (inputPassword.toLowerCase() === inputPassword) {
    passwordLabel = passwordLabel + termPasswordUppercaseElement + ". ";
    validPassword = false;
  }

  const countdownRender = (props: {
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    const { hours, minutes, seconds, completed } = props;

    const formatTimeComponent = (component: number): string => {
      let result = component.toString();
      while (result.length < 2) {
        result = "0" + result;
      }
      return result;
    };

    return (
      <span>
        <Box width={50}>
          {!completed && formatTimeComponent(minutes) + ":" + formatTimeComponent(seconds)}
          <IconButton
            onClick={() => {
              sendNewCode();
            }}
          >
            <Icon>send</Icon>
          </IconButton>
        </Box>
      </span>
    );
  };

  const disableDoneAccount = () => {
    return (
      token === null ||
      inputPassword !== inputConfirmPassword ||
      !validPassword ||
      codeValidUntil === null ||
      codeValidUntil === undefined ||
      new Date() > codeValidUntil
    );
  };

  const doneSetupAccount = async () => {
    if (disableDoneAccount() || token === null) return;
    setLoading(true);
    const result = await ProfileService.setupAccount(token, inputSmsCode, inputPassword);
    if (!result.success) {
      // TODO: Handle this erros
      switch (result.error.errorCode) {
        case BiohubErrorCode.SMS_VALIDATION_ERROR:
          setSmsError(true);
          break;
        case BiohubErrorCode.INTERNAL_SERVER_ERROR:
          setStage(3);
          break;
        default:
          break;
      }
    } else {
      setStage(2);
    }
    setLoading(false);
  };

  return (
    <>
      <VerificationPage>
        <Header color={"dark"} />
        <Container>
          {partialAccountInfo !== undefined ? (
            <ContentPage>
              {stage === 1 ? (
                <Row>
                  <Column xs={12}>
                    <Text text={termTitle} color={"primary"} size={"large"} />
                  </Column>
                  <Column>
                    <DetailsCode>
                      <Text
                        text={termEmailVerification}
                        color={"primary"}
                        appearance={"info-title"}
                        size={"medium"}
                      />
                    </DetailsCode>
                  </Column>
                  <Column xs={12} md={12}>
                    <DetailsDescription>
                      <Text text={termDetails} color={"primary"} />
                    </DetailsDescription>
                  </Column>
                  <Column xs={6} md={6}>
                    <DetailsData>
                      <Text
                        text={termEmail}
                        color={"primary"}
                        appearance={"info-title"}
                        size={"medium"}
                      />
                      <FormControl>
                        <Input
                          id="email-verification"
                          type="text"
                          defaultValue={partialAccountInfo.partialEmail}
                          onChange={() => {}}
                          disabled={true}
                        />
                      </FormControl>
                    </DetailsData>
                  </Column>
                  <Column xs={6} md={6}>
                    <DetailsData>
                      <Text
                        text={termPhoneNumber}
                        color={"primary"}
                        appearance={"info-title"}
                        size={"medium"}
                      />
                      <FormControl>
                        <Input
                          id="email-verification"
                          type="text"
                          defaultValue={partialAccountInfo.partialNumber}
                          onChange={() => {}}
                          disabled={true}
                        />
                      </FormControl>
                    </DetailsData>
                  </Column>
                  <Column xs={5} md={5}>
                    <DetailsDataCode>
                      <Text
                        text={termPassword}
                        color={"primary"}
                        appearance={"info-title"}
                        size={"medium"}
                      />
                      <PasswordField
                        id={"password-input"}
                        value={inputPassword}
                        onChange={(value) => setInputPassword(value)}
                        helperText={passwordLabel}
                      />
                    </DetailsDataCode>
                  </Column>
                  <Column xs={1} md={1}>
                    <Box height={10} width={10} />
                  </Column>
                  <Column xs={5} md={5}>
                    <DetailsDataCode>
                      <Text
                        text={termConfirmPassword}
                        color={"primary"}
                        appearance={"info-title"}
                        size={"medium"}
                      />
                      <PasswordField
                        id={"confirm-password-input"}
                        value={inputConfirmPassword}
                        onChange={(value) => setInputConfirmPassword(value)}
                        helperText={
                          inputPassword !== inputConfirmPassword ? termDifferentPassword : undefined
                        }
                      />
                    </DetailsDataCode>
                  </Column>
                  <Column xs={6} md={6}>
                    <DetailsDataCode>
                      <Text
                        text={termVerification}
                        color={"primary"}
                        appearance={"info-title"}
                        size={"medium"}
                      />
                      <SmsCodeField
                        id={"code-verification"}
                        helperText={smsError ? termWrongSms : termDetailsConfirmation}
                        value={inputSmsCode}
                        onChange={(value) => {
                          setInputSmsCode(value);
                        }}
                        onClick={() => setInputSmsCode("")}
                        error={smsError}
                      ></SmsCodeField>
                    </DetailsDataCode>
                  </Column>
                  <Column xs={6} md={6} style={{ display: "flex", alignItems: "center" }}>
                    <DetailsDataCode>
                      {codeValidUntil !== null ? (
                        codeValidUntil !== undefined ? (
                          <Countdown date={codeValidUntil} renderer={countdownRender} />
                        ) : (
                          <CircularProgress />
                        )
                      ) : (
                        <Icon>error</Icon>
                      )}
                    </DetailsDataCode>
                  </Column>
                  <Column xs={12} md={12}>
                    <Button
                      color={"highlighted"}
                      size={"large"}
                      position={"right"}
                      action={() => {
                        doneSetupAccount();
                      }}
                    >
                      {loading ? <CircularProgress /> : termActionSubmitConfirmation}
                    </Button>
                  </Column>
                </Row>
              ) : stage === 2 ? (
                <Row>
                  <Column xs={12}>
                    <Text text={termTitle} color={"primary"} size={"large"} />
                  </Column>
                  <Column>
                    <DetailsCode>
                      <Text
                        text={termAccountCreated}
                        color={"primary"}
                        appearance={"info-title"}
                        size={"medium"}
                      />
                    </DetailsCode>
                  </Column>
                </Row>
              ) : (
                <Row>
                  <Column xs={12}>
                    <Text text={termTitle} color={"primary"} size={"large"} />
                  </Column>
                  <Column>
                    <DetailsCode>
                      <Text
                        text={termError}
                        color={"primary"}
                        appearance={"info-title"}
                        size={"medium"}
                      />
                    </DetailsCode>
                  </Column>
                  <Column xs={12} md={12}>
                    <Button
                      color={"highlighted"}
                      size={"large"}
                      position={"right"}
                      action={() => {
                        setStage(1);
                      }}
                    >
                      {termTryAgain}
                      {loading && <CircularProgress />}
                    </Button>
                  </Column>
                </Row>
              )}
            </ContentPage>
          ) : (
            <>
              {/* TODO: Put something here */}
              <Box height={300} />
            </>
          )}
        </Container>
        <Footer image={true} description={termAboutInfo} />
      </VerificationPage>
    </>
  );
};

const helperTextStyles = makeStyles((theme) => ({
  root: {
    margin: 4,
    color: "black",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));

function PasswordField(props: {
  id: string;
  value: string;
  onChange: (value: string) => void;
  helperText?: string;
}): JSX.Element {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const classes = helperTextStyles();

  const error = props.helperText !== undefined && props.helperText.length > 0;

  return (
    <TextField
      className={error ? classes.error : classes.root}
      type={showPassword ? undefined : "password"}
      helperText={props.helperText}
      id={props.id}
      variant="outlined"
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={props.id + "-show"}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {<Icon>{showPassword ? "visibility_off" : "visibility"}</Icon>}
            </IconButton>
          </InputAdornment>
        ),
      }}
      disabled={false}
      error={error}
    />
  );
}

function SmsCodeField(props: {
  id: string;
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  helperText?: string;
  error: boolean;
}): JSX.Element {
  const [firstClick, setFirstClick] = React.useState<boolean>(true);

  const classes = helperTextStyles();

  const error = props.helperText !== undefined && props.helperText.length > 0;

  return (
    <TextField
      className={error ? classes.error : classes.root}
      helperText={props.helperText}
      id={props.id}
      variant="outlined"
      value={props.value}
      onClick={() => {
        if (firstClick) {
          props.onClick();
        }
        setFirstClick(false);
      }}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      disabled={false}
      error={props.error}
    />
  );
}
