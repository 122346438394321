import React from "react";
import Container from "../../Areas/Container";
import { Section } from "./styles";
import Text from "../../BasicComponents/Text";
import Image from "../../BasicComponents/Image";

interface Props {
  title: string;
  category?: string;
  productList?: Array<{
    id: string;
    title: string;
    description: string;
    image: string | "url";
    link: string | "url";
  }>;
  image: string;
}

export default (props: Props) => {
  return (
    <>
      <Section>
        <Container>
          <Text
            text={props.title}
            sectionTitleMark
            appearance={"title-semibold"}
            textAlign={"center"}
            color={"default"}
          />
          <Image src={props.image} alt={""} />
        </Container>
      </Section>
    </>
  );
};
