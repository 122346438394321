import styled from "styled-components";
import Fields from "@material-ui/core/TextField";

type Props = {
  type?: string;
  placeholder?: string;
  required?: boolean;
  backgroundColor?: string;
  notSelectedPlaceholderPadding?: number;
  labelVerticalPadding?: number;
};

export const Field = styled.span.attrs((props: Props) => ({}))`
  position: relative;
  display: inline-block;
`;

export const TextField = styled(Fields).attrs((props: Props) => ({
  backgroundColor: props.backgroundColor,
  notSelectedPlaceholderPadding: props.notSelectedPlaceholderPadding,
  labelVerticalPadding: props.labelVerticalPadding,
}))`
  > div {
    border-radius: 50px;
    text-align: center;
    outline: none;
    margin: 0;
    ${(props) =>
      props.backgroundColor !== undefined &&
      `
      background-color: ${props.backgroundColor};
    `}
  }
  label {
    width: calc(100% - 35px);
    display: block;
    margin-top: 0px;
    ${(props) =>
      props.labelVerticalPadding !== undefined &&
      `
      margin-top: ${props.labelVerticalPadding}px;
    `}
    ${(props) =>
      props.notSelectedPlaceholderPadding !== undefined &&
      `
      padding-left: ${props.notSelectedPlaceholderPadding}px;
    `}
  }
  label.MuiInputLabel-shrink {
    text-align: left;
    width: auto;
    padding-left: 0px;
  }
`;
