import React from "react";
import ClientCard from "../ClientCard";
import { Carousel } from "./styles";

export type listPartners = {
  id: string;
  name: string;
  role: string;
  testimonial: string;
  image: string | "url";
};
interface Props {
  items: Array<listPartners>;
}

export default (props: Props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {props.items.map((item: any) => (
        <ClientCard
          key={item.id}
          title={item.name}
          subtitle={item.role}
          description={item.testimonial}
          image={item.image}
        />
      ))}
    </Carousel>
  );
};
