import { Role } from "biohub-model";
import React from "react";
import { useIntl } from "react-intl";
import { CustomTreeItem, ListItemAction } from "./CustomTreeItem";
import { ProjectTreeItemTrailingIcon } from "./ProjectsTreeItemTrailingIcon";
import { Tooltip } from "@material-ui/core";

type Props = {
  projectId: string;
  areaId: string;
  areaName: string;
  isVisible: boolean;
  selectedAreaId: string | null;
  flightAmount: number | undefined;
  beingDeleted: boolean;
  isUpdating: boolean;
  areaSizeInHa: number;
  isRunningMissionPlanner: boolean;
  isLoadingFlights: boolean;
  isExportingAsKml: boolean;
  onClickArea: () => void;
  onOpenOrCloseArea: () => void;
  onChangeAreaVisibility: () => void;
  children: React.ReactNode[] | React.ReactNode;
  profileRole: Role;
  onClickToEditAreaSettings: () => void;
  onClickToDeleteArea: () => void;
  onClickDuplicateArea: () => void;
  onClickCopyArea: () => void;
  onClickExportAreaAsKML: () => void;
  onClickExportRouteAsKML: () => void;
};

export default function (props: Props): JSX.Element {
  const areaHa = props.areaSizeInHa;
  const areaHaStr = areaHa > 10 ? areaHa.toFixed(0) : areaHa.toFixed(1);

  const intl = useIntl();

  let areaTreeItemActions: ListItemAction[] | undefined;
  const profileRole = props.profileRole;
  if (profileRole < Role.external) {
    areaTreeItemActions = [
      {
        text: intl.formatMessage({ id: "map.editAreaProperties" }),
        action: () => {
          props.onClickToEditAreaSettings();
        },
      },
      {
        text: intl.formatMessage({ id: "map.copyArea" }),
        action: () => {
          props.onClickCopyArea();
        },
      },
      {
        text: intl.formatMessage({ id: "map.duplicateArea" }),
        action: () => {
          props.onClickDuplicateArea();
        },
      },
      {
        text: intl.formatMessage({ id: "map.kml.exportArea" }),
        action: () => {
          props.onClickExportAreaAsKML();
        },
      },
      {
        text: intl.formatMessage({ id: "map.kml.exportRoute" }),
        action: () => {
          props.onClickExportRouteAsKML();
        },
      },
    ];

    if (profileRole === Role.master || profileRole === Role.admin || profileRole === Role.manager) {
      areaTreeItemActions.push({
        text: intl.formatMessage({ id: "map.deleteArea" }),
        action: () => {
          props.onClickToDeleteArea();
        },
      });
    }
  }

  return (
    <CustomTreeItem
      id={`${props.projectId}-${props.areaId}`}
      key={`${props.projectId}-${props.areaId}`}
      highlighted={props.areaId === props.selectedAreaId}
      content={props.areaName}
      subtitle={`${areaHaStr} ha`}
      totalizer={props.flightAmount}
      loading={
        props.isLoadingFlights ||
        props.isUpdating ||
        props.beingDeleted ||
        props.isRunningMissionPlanner ||
        props.isExportingAsKml
      }
      onClick={props.onClickArea}
      onOpenAction={props.onOpenOrCloseArea}
      trailing={
        <Tooltip title={intl.formatMessage({ id: "map.toggleVisibility" })}>
          <div style={{ marginRight: 0 }}>
            <ProjectTreeItemTrailingIcon
              isHidden={!props.isVisible}
              onClick={props.onChangeAreaVisibility}
            />
          </div>
        </Tooltip>
      }
      actions={areaTreeItemActions}
    >
      {props.children}
    </CustomTreeItem>
  );
}
