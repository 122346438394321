import { Flight, FlightReport, Location, Role, UnitSystem } from "biohub-model";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import BaseMap from "../../../components/map/BaseMap";
import BaseMapController from "../../../components/map/BaseMapController";
import { boundingBoxForAreas } from "../../../core/geometricFunctions";
import { SystemState } from "../../../store/reducers/systemReducer";
import { MapReport } from "../styles";
import MapAreaComponent from "../../../components/map/impl/components/Area";
import ElevationService from "../../../services/routePlanner/ElevationService";
import UnitConversionHelper from "../../../core/helper/UnitConversionHelper";
import MapFlightComponent from "../../../components/map/impl/components/Flight";

interface Props {
  dataReport: FlightReport[0];
  flightToPlot: Flight;
  mode: "plan" | "flight";
}

export default (props: Props) => {
  const elementsZIndexes = {
    areaPolygonZIndex: 1,
    areaEditingPolygonPolylineZIndex: 1,
    areaEditingPolygonMarkersZIndex: 2,

    plannedPathZIndex: 2,
    plannedPathBorderZIndex: 3,

    userLocationZIndex: 4,

    waypointsDistanceMarkerZIndex: 5,
    waypointsDistanceLabelZIndex: 6,

    waypointOrientationMarkerZIndex: 7,
    waypointDropMarkerZIndex: 8,

    waypointElevationMarkerZIndex: 9,
    waypointElevationLabelZIndex: 10,

    homePointMarkerZIndex: 11,
    homePointElevationMarkerZIndex: 12,
    homePointElevationLabelZIndex: 13,

    flightReleaseTrackZIndex: 14,
    flightPathZIndex: 16,
    flightPathBorderZIndex: 15,
  };

  const [mapController, setMapController] = useState<BaseMapController | null>(null);

  const intl = useIntl();
  const flightToPlot = props.flightToPlot;

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  return (
    <>
      <MapReport>
        <BaseMap
          region={intl.formatMessage({ id: "map.defaultRegion" })}
          languageCode={intl.locale}
          mapTypeId={"labeled_satellite"}
          onInitialized={async (controller) => {
            setMapController(controller);

            await controller.moveToBoundingBox(
              boundingBoxForAreas([flightToPlot.flightEnvironmentSnapshot.plannedArea.polygon])!
            );
          }}
          onZoomChanged={() => {}}
          onCurrentCenterChanged={() => {}}
          onMapBoundsChanged={() => {}}
        >
          <MapAreaComponent
            {...elementsZIndexes}
            polygonId={
              props.mode === "plan"
                ? "flight-report-area-component-planned-area"
                : "flight-report-area-component-flight"
            }
            mapType={"satellite"}
            areaEditionMode={undefined}
            isSelected={true}
            polygon={flightToPlot.flightEnvironmentSnapshot.plannedArea.polygon}
            homePoint={flightToPlot.flightEnvironmentSnapshot.plannedArea.homePoint}
            homePointElevation={
              flightToPlot.flightEnvironmentSnapshot.plannedArea.homePointElevation
            }
            waypoints={flightToPlot.flightEnvironmentSnapshot.plannedArea.route.waypoints}
            formatHeight={(value) => {
              return `${UnitConversionHelper.distanceValue(
                value,
                unitSystem,
                1
              )} ${UnitConversionHelper.distanceUnit(unitSystem)}`;
            }}
            selectedWaypointsIndexes={[]}
            unitSystem={unitSystem}
            onClickWaypoint={() => {}}
            onPolygonClicked={() => {}}
            showWaypointDistances={props.mode === "flight" ? false : undefined}
            usingOnlineElevation={props.flightToPlot.flightEnvironmentSnapshot.mustConsiderRelief}
          />

          <>
            {props.mode === "flight" && flightToPlot.realData && (
              <MapFlightComponent
                key={"flight-report-flight-component"}
                flightId={flightToPlot.id}
                trackWidth={flightToPlot.flightEnvironmentSnapshot.trackWidth}
                flownRoute={flightToPlot.realData.completeRoute}
                logData={flightToPlot.realData.logData}
                releaseTracksZIndex={elementsZIndexes.flightReleaseTrackZIndex}
                pathZIndex={elementsZIndexes.flightPathZIndex}
                pathBorderZIndex={elementsZIndexes.flightPathBorderZIndex}
              />
            )}
          </>
        </BaseMap>
      </MapReport>
    </>
  );
};
