import {
  Icon,
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import {
  Company,
  Profile,
  Role,
  UnitSystem,
  Address,
  DirectClient,
  IndirectClient,
} from "biohub-model";
import React, { ChangeEvent, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  isNotPresentValue,
  isValidEmail,
  isValidNumber,
  isValidPhone,
  isValidPassword,
} from "../../../services/FormValidatorsService";
import {
  updateCompany,
  updateProfile,
  uploadDirectClientLogo,
  uploadIndirectClientLogo,
} from "../../../store/actions/profileActions";
import { SystemState } from "../../../store/reducers/systemReducer";
import { Block, Actions, EditAvatar, Logo } from "../styles";
import InsertPhone from "../../../components/Atomic/Inputs/Phone";
import { AddressSearchInfo, searchZipCode } from "../../../services/SearchPlaces";
import { BiohubResponse } from "../../../services/axios/BiohubApi";

interface EditProfileProps {
  pictureUrl?: string;
  profile: Profile;
  company?: Company;
  directClient?: DirectClient;
  indirectClient?: IndirectClient;
}

export default function EditProfile(props: EditProfileProps) {
  const [editing, setEditing] = useState<boolean>(false);

  const intl = useIntl();
  const termProfile = intl.formatMessage({ id: "placeholder.profile" });
  const termCompanyInformaion = intl.formatMessage({ id: "placeholder.company" });
  const termName = intl.formatMessage({ id: "placeholder.name" });
  const termEmail = intl.formatMessage({ id: "placeholder.email" });
  const termPhone = intl.formatMessage({ id: "placeholder.phone" });
  const termCompanyName = intl.formatMessage({ id: "placeholder.company.name" });
  const termAddress = intl.formatMessage({ id: "placeholder.address" });
  const termNumber = intl.formatMessage({ id: "placeholder.number" });
  const termCity = intl.formatMessage({ id: "placeholder.city" });
  const termState = intl.formatMessage({ id: "placeholder.state.department" });
  const termZIP = intl.formatMessage({ id: "placeholder.zip" });
  const termComplement = intl.formatMessage({ id: "placeholder.complement" });
  const termCompanyTradeName = intl.formatMessage({ id: "placeholder.trade.name" });

  const termActualPassword = intl.formatMessage({ id: "profile.login.currentPassword" });
  const termNewPassword = intl.formatMessage({ id: "profile.login.newPassword" });
  const termConfirmNewPassword = intl.formatMessage({ id: "profile.login.confirmNewPassword" });

  const { profile, company, pictureUrl } = props;
  const dispatch = useDispatch();

  const stateProfile = useSelector((systemState: SystemState) => systemState.profile.userProfile);

  const enableEditCompany = props.profile.role === Role.admin || props.profile.role === Role.master;

  const [open, setOpen] = useState<boolean>(false);
  const [photo, setPhoto] = useState<File | null>(null);
  const [errorImage, setErrorImage] = useState<boolean>(false);

  const termEditPicture = intl.formatMessage({ id: "action.edit.logo" });
  const termEditPictureTitle = intl.formatMessage({ id: "info.profile.edit.logo" });
  const termEditPictureDialog = intl.formatMessage({ id: "info.profile.edit.logo.dialog" });
  const termEditPictureChoose = intl.formatMessage({ id: "info.profile.edit.logo.buttom.choose" });
  const termEditPictureSend = intl.formatMessage({ id: "info.profile.edit.logo.buttom.send" });
  const termEditPictureCancel = intl.formatMessage({ id: "info.profile.edit.logo.buttom.cancel" });
  const termEditPictureInvalid = intl.formatMessage({ id: "info.profile.edit.logo.invalid" });
  const termPlaceHolderChoosePicture = intl.formatMessage({
    id: "placeholder.profile.select.picture",
  });

  const allowedImageFormats = ["image/jpeg", "image/jpg", "image/png"];
  const maxImageSizeAllowed = 2 * 1024 * 1024;

  const handleCloseDialog = (e: any) => {
    setOpen(false);
  };

  const handleChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== undefined && e.target.files !== null) {
      const file = e.target.files[0];
      if (file && allowedImageFormats.includes(file.type) && file.size <= maxImageSizeAllowed) {
        setPhoto(file);
        setErrorImage(false);
      } else {
        setErrorImage(true);
      }
    }
  };

  const handleUploadDirectLogo = () => {
    if (props.directClient === undefined) return;
    setOpen(false);
    if (photo) {
      const formData = new FormData();
      formData.append("directClientLogo", photo);
      dispatch(uploadDirectClientLogo(formData, props.directClient.id));
    }
  };

  const handleUploadIndirectLogo = () => {
    if (props.indirectClient === undefined) return;
    setOpen(false);
    if (photo) {
      const formData = new FormData();
      formData.append("indirectClientLogo", photo);
      dispatch(uploadIndirectClientLogo(formData, props.indirectClient.id));
    }
  };

  const [editingProfile, setEditingProfile] = React.useState<Profile>(props.profile);
  const [updatedProfileFields, setUpdatedProfileFields] = React.useState<
    Partial<Profile> & { id: string } & { role: Role }
  >({ id: props.profile.id, role: props.profile.role });
  const [editingCompany, setEditingCompany] = React.useState<Company | undefined>(props.company);
  const [updatedCompany, setUpdatedCompany] = React.useState<
    (Partial<Company> & { id: string }) | undefined
  >(props.company !== undefined ? { id: props.company.id } : undefined);

  const [actualPassword, setActualPassword] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState<string>("");

  const handleProfileChange = (event: React.ChangeEvent<any>) => {
    const keyField = event.target.name as keyof Profile;
    const value = event.target.value;
    setEditingProfile({ ...editingProfile, [keyField]: value });
    setUpdatedProfileFields({
      ...updatedProfileFields,
      [keyField]: props.profile[keyField] !== value ? value : undefined,
    });
  };

  const handleCompanyChange = (event: React.ChangeEvent<any>) => {
    if (props.company === undefined || updatedCompany === undefined || editingCompany === undefined)
      return;
    const keyField = event.target.name as keyof Company;
    const value = event.target.value;
    setEditingCompany({ ...editingCompany, [keyField]: value });
    setUpdatedCompany({
      ...updatedCompany,
      [keyField]: props.company[keyField] !== value ? value : undefined,
    });
  };

  const [invalidProfileName, setInvalidProfileName] = React.useState<boolean | undefined>(
    undefined
  );
  React.useEffect(() => {
    if (!editing) return;
    if (editingProfile.name.length > 0 || invalidProfileName !== undefined) {
      setInvalidProfileName(isNotPresentValue(editingProfile.name));
    }
  }, [editingProfile.name]);

  const [invalidProfileCellphoneNumber, setInvalidProfileCellphoneNumber] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (!editing) return;
    if (editingProfile.cellphoneNumber.length > 0 || invalidProfileCellphoneNumber !== undefined) {
      setInvalidProfileCellphoneNumber(isValidPhone(editingProfile.cellphoneNumber));
    }
  }, [editingProfile.cellphoneNumber]);

  const [invalidProfileAddressZip, setInvalidProfileAddressZip] = React.useState<
    boolean | undefined
  >(undefined);
  const [invalidProfileAddressStreet, setInvalidProfileAddressStreet] = React.useState<
    boolean | undefined
  >(undefined);
  const [invalidProfileAddressNumber, setInvalidProfileAddressNumber] = React.useState<
    boolean | undefined
  >(undefined);
  const [invalidProfileAddressCity, setInvalidProfileAddressCity] = React.useState<
    boolean | undefined
  >(undefined);
  const [invalidProfileAddressState, setInvalidProfileAddressState] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (!editing) return;
    if (editingProfile.role === Role.external) return;
    if (editingProfile.address.zipCode.length > 0 || invalidProfileAddressZip !== undefined) {
      setInvalidProfileAddressZip(isNotPresentValue(editingProfile.address.zipCode));
    }
    if (editingProfile.address.street.length > 0 || invalidProfileAddressStreet !== undefined) {
      setInvalidProfileAddressStreet(isNotPresentValue(editingProfile.address.street));
    }
    if (editingProfile.address.number.length > 0 || invalidProfileAddressNumber !== undefined) {
      setInvalidProfileAddressNumber(isNotPresentValue(editingProfile.address.number));
    }
    if (editingProfile.address.city.length > 0 || invalidProfileAddressCity !== undefined) {
      setInvalidProfileAddressCity(isNotPresentValue(editingProfile.address.city));
    }
    if (editingProfile.address.state.length > 0 || invalidProfileAddressState !== undefined) {
      setInvalidProfileAddressState(isNotPresentValue(editingProfile.address.state));
    }
  }, [(editingProfile as any)?.address]);

  const [invalidProfileEmail, setInvalidProfileEmail] = React.useState<boolean | undefined>(
    undefined
  );
  React.useEffect(() => {
    if (!editing) return;
    if (editingProfile.email.length > 0 || invalidProfileEmail !== undefined) {
      setInvalidProfileEmail(isValidEmail(editingProfile.email));
    }
  }, [editingProfile.email]);

  const [invalidCompanyName, setInvalidCompanyName] = React.useState<boolean | undefined>(
    undefined
  );
  React.useEffect(() => {
    if (!editing) return;
    if (editingCompany === undefined) return;
    if (editingCompany.name.length > 0 || invalidCompanyName !== undefined) {
      setInvalidCompanyName(isNotPresentValue(editingCompany.name));
    }
  }, [editingCompany?.name]);

  const [invalidCompanyComercialPhoneNumber, setInvalidCompanyComercialPhoneNumber] =
    React.useState<boolean | undefined>(undefined);
  React.useEffect(() => {
    if (!editing) return;
    if (editingCompany === undefined || editingCompany.commercialPhoneNumber === undefined) return;
    if (
      editingCompany.commercialPhoneNumber.length > 0 ||
      invalidCompanyComercialPhoneNumber !== undefined
    ) {
      setInvalidCompanyComercialPhoneNumber(isValidPhone(editingCompany.commercialPhoneNumber));
    }
  }, [editingCompany?.commercialPhoneNumber]);

  const [invalidCompanyAddressZip, setInvalidCompanyAddressZip] = React.useState<
    boolean | undefined
  >(undefined);
  const [invalidCompanyAddressStreet, setInvalidCompanyAddressStreet] = React.useState<
    boolean | undefined
  >(undefined);
  const [invalidCompanyAddressNumber, setInvalidCompanyAddressNumber] = React.useState<
    boolean | undefined
  >(undefined);
  const [invalidCompanyAddressCity, setInvalidCompanyAddressCity] = React.useState<
    boolean | undefined
  >(undefined);
  const [invalidCompanyAddressState, setInvalidCompanyAddressState] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (!editing) return;
    if (editingCompany === undefined) return;
    if (editingCompany.address.zipCode.length > 0 || invalidCompanyAddressZip !== undefined) {
      setInvalidCompanyAddressZip(isNotPresentValue(editingCompany.address.zipCode));
    }
    if (editingCompany.address.street.length > 0 || invalidCompanyAddressStreet !== undefined) {
      setInvalidCompanyAddressStreet(isNotPresentValue(editingCompany.address.street));
    }
    if (editingCompany.address.number.length > 0 || invalidCompanyAddressNumber !== undefined) {
      setInvalidCompanyAddressNumber(isNotPresentValue(editingCompany.address.number));
    }
    if (editingCompany.address.city.length > 0 || invalidCompanyAddressCity !== undefined) {
      setInvalidCompanyAddressCity(isNotPresentValue(editingCompany.address.city));
    }
    if (editingCompany.address.state.length > 0 || invalidCompanyAddressState !== undefined) {
      setInvalidCompanyAddressState(isNotPresentValue(editingCompany.address.state));
    }
  }, [editingCompany?.address]);

  const errorActualPassword =
    actualPassword.length === 0 &&
    (props.profile.email !== editingProfile.email || newPassword.length > 0);
  const errorNewPassword =
    (isValidPassword(newPassword) && newPassword.length > 0) ||
    (confirmNewPassword.length > 0 && newPassword.length == 0);
  const errorConfirmPassword = confirmNewPassword !== newPassword;

  const invalidInput: boolean =
    errorActualPassword ||
    errorNewPassword ||
    errorConfirmPassword ||
    invalidProfileName === true ||
    invalidProfileCellphoneNumber === true ||
    invalidProfileAddressZip === true ||
    invalidProfileAddressStreet === true ||
    invalidProfileAddressNumber === true ||
    invalidProfileAddressCity === true ||
    invalidProfileAddressState === true ||
    invalidProfileEmail === true ||
    invalidCompanyName === true ||
    invalidCompanyComercialPhoneNumber === true ||
    invalidCompanyAddressZip === true ||
    invalidCompanyAddressStreet === true ||
    invalidCompanyAddressNumber === true ||
    invalidCompanyAddressCity === true ||
    invalidCompanyAddressState === true;
  const termSave = intl.formatMessage({ id: "action.save" });
  const termCancelEdition = intl.formatMessage({ id: "action.cancel" });
  const termEdit = intl.formatMessage({ id: "action.edit" });

  const systemProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  const updateProfileAndCompany = () => {
    if (systemProfile === null) return;
    let updatedProfile = { ...updatedProfileFields };
    // Update profile
    if (Object.keys(updatedProfile).length > 2) {
      if (updatedProfile.role === Role.external) {
        if (systemProfile.directClientId === null) {
          updatedProfile = {
            ...updatedProfile,
            role: Role.external,
            directClientId: null,
            indirectClientId: systemProfile.indirectClientId,
          };
        } else {
          updatedProfile = {
            ...updatedProfile,
            role: Role.external,
            directClientId: systemProfile.directClientId,
            indirectClientId: null,
          };
        }
      }

      dispatch(updateProfile(updatedProfile, undefined, actualPassword, newPassword));
    } else if (actualPassword.length > 0 && newPassword.length > 0) {
      dispatch(
        updateProfile(
          { id: systemProfile.id, role: systemProfile.role },
          undefined,
          actualPassword,
          newPassword
        )
      );
    }
    // Update company
    if (updatedCompany !== undefined && Object.keys(updatedCompany).length > 1) {
      dispatch(updateCompany(updatedCompany));
    }
  };

  const cancelEdition = () => {
    setEditingProfile(props.profile);
    setEditingCompany(props.company);
    setEditing(false);
  };

  const classes = useStyles();

  const handleProfileAddress = (event: React.ChangeEvent<any>) => {
    if (editingProfile.role === Role.external) return;

    handleProfileChange({
      ...event,
      target: {
        ...event.target,
        name: "address",
        value: { ...editingProfile.address, [event.target.name]: event.target.value },
      },
    });
  };

  const handleCompanyAddress = (event: React.ChangeEvent<any>) => {
    if (editingCompany === undefined) return;

    handleCompanyChange({
      ...event,
      target: {
        ...event.target,
        name: "address",
        value: {
          ...editingCompany.address,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const handleBlurZipCode = (profile: boolean) => {
    return async (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const result: BiohubResponse<AddressSearchInfo> = await searchZipCode(
        event.currentTarget.value
      );
      if (result.success) {
        let previousAddress: Address;
        if (profile) {
          if (editingProfile.role === Role.external) return;
          previousAddress = editingProfile.address;
        } else {
          if (editingCompany === undefined) return;
          previousAddress = editingCompany.address;
        }

        const address: Address = {
          ...previousAddress,
          city: result.data.city,
          street: result.data.address,
          country: result.data.country,
          state: result.data.state,
        };

        const callback: (address: Address) => void = profile
          ? (address) => {
              if (
                editingProfile.role === Role.external ||
                updatedProfileFields.role === Role.external
              )
                return;

              setEditingProfile({ ...editingProfile, address: address });
              setUpdatedProfileFields({ ...updatedProfileFields, address: address });
            }
          : (address) => {
              if (editingCompany === undefined || updatedCompany === undefined) return;

              setEditingCompany({ ...editingCompany, address: address });
              setUpdatedCompany({ ...updatedCompany, address: address });
            };
        callback(address);
      }
    };
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12} xs={12}>
          <h2>{termProfile}</h2>
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            disabled={!editing}
            autoFocus
            margin="dense"
            id="name"
            label={termName}
            fullWidth
            variant="outlined"
            name="name"
            value={editingProfile.name}
            onChange={handleProfileChange}
            error={invalidProfileName}
          />
        </Grid>
        <Grid container xs={12} style={{ paddingRight: 4, paddingLeft: 4 }}>
          <InsertPhone
            disabled={!editing}
            fieldId="cellphoneNumber"
            labelId="country-code-label"
            label={intl.formatMessage({ id: "placeholder.cellphone" }) + "*"}
            error={invalidProfileCellphoneNumber}
            onChange={handleProfileChange}
            paperClass={classes.menuPaper}
            phone={editingProfile.cellphoneNumber}
            codeGridSize={4}
            inputGridSize={8}
            gridPaddingTop={8}
            countryCodeStyle={{ height: 40 }}
          />
        </Grid>
        {editingProfile.role !== Role.external && (
          <Grid item lg={12} xs={12}>
            <TextField
              disabled={!editing}
              margin="dense"
              id="profile-address-zipcode"
              label={termZIP}
              fullWidth
              variant="outlined"
              name="zipCode"
              value={editingProfile.address.zipCode}
              onChange={handleProfileAddress}
              error={invalidProfileAddressZip}
              onBlur={handleBlurZipCode(true)}
            />
          </Grid>
        )}
        {editingProfile.role !== Role.external && (
          <Grid item lg={9} xs={7}>
            <TextField
              disabled={!editing}
              margin="dense"
              id="profile-address-street"
              label={termAddress}
              fullWidth
              variant="outlined"
              name="street"
              value={editingProfile.address.street}
              onChange={handleProfileAddress}
              error={invalidProfileAddressStreet}
            />
          </Grid>
        )}
        {editingProfile.role !== Role.external && (
          <Grid item lg={3} xs={5}>
            <TextField
              disabled={!editing}
              margin="dense"
              id="profile-address-number"
              label={termNumber}
              fullWidth
              variant="outlined"
              name="number"
              value={editingProfile.address.number}
              onChange={handleProfileAddress}
              error={invalidProfileAddressNumber}
            />
          </Grid>
        )}
        {editingProfile.role !== Role.external && (
          <Grid item xs={12}>
            <TextField
              disabled={!editing}
              margin="dense"
              id="profile-address-complement"
              label={termComplement}
              fullWidth
              variant="outlined"
              name="complement"
              value={editingProfile.address.complement}
              onChange={handleProfileAddress}
              error={false}
            />
          </Grid>
        )}
        {editingProfile.role !== Role.external && (
          <Grid item lg={7} xs={8}>
            <TextField
              disabled={!editing}
              margin="dense"
              id="profile-address-city"
              label={termCity}
              fullWidth
              variant="outlined"
              name="city"
              value={editingProfile.address.city}
              onChange={handleProfileAddress}
              error={invalidProfileAddressCity}
            />
          </Grid>
        )}
        {editingProfile.role !== Role.external && (
          <Grid item lg={5} xs={4}>
            <TextField
              disabled={!editing}
              margin="dense"
              id="profile-address-state"
              label={termState}
              fullWidth
              variant="outlined"
              name="state"
              value={editingProfile.address.state}
              onChange={handleProfileAddress}
              error={invalidProfileAddressState}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            disabled={!editing}
            margin="dense"
            id="email"
            label={termEmail}
            fullWidth
            variant="outlined"
            name="email"
            value={editingProfile.email}
            onChange={handleProfileChange}
            error={invalidProfileEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            disabled={!editing}
            label={termActualPassword}
            id="profile-password"
            value={actualPassword}
            onChange={(newValue) => {
              setActualPassword(newValue);
            }}
            error={errorActualPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            disabled={!editing}
            label={termNewPassword}
            id="profile-new-password"
            value={newPassword}
            onChange={(newValue) => {
              setNewPassword(newValue);
            }}
            error={errorNewPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            disabled={!editing}
            label={termConfirmNewPassword}
            id="profile-confirm-password"
            value={confirmNewPassword}
            onChange={(newValue) => {
              setConfirmNewPassword(newValue);
            }}
            error={errorConfirmPassword}
          />
        </Grid>
        {profile.role !== undefined &&
          profile.role === Role.external &&
          props.indirectClient !== undefined && (
            <>
              <Grid item xs={12}>
                <h2>{termCompanyInformaion}</h2>
              </Grid>

              <Grid item xs={12}>
                <Logo>
                  <img src={props.indirectClient.logoUrl} alt={props.indirectClient.name} />
                </Logo>
              </Grid>

              <Grid item xs={12}>
                <Button onClick={() => setOpen(true)} size="small">
                  <Icon fontSize="small">create</Icon> {termEditPicture}
                </Button>
              </Grid>
            </>
          )}
        {editingCompany !== undefined &&
          profile.role !== undefined &&
          profile.role <= Role.admin &&
          props.directClient !== undefined && (
            <>
              <Grid item xs={12}>
                <h2>{termCompanyInformaion}</h2>
              </Grid>

              <Grid item xs={12}>
                <Logo>
                  <img src={props.directClient.logoUrl} alt={props.directClient.company?.name} />
                </Logo>
              </Grid>

              <Grid item xs={12}>
                <Button onClick={() => setOpen(true)} size="small">
                  <Icon fontSize="small">create</Icon> {termEditPicture}
                </Button>
              </Grid>
            </>
          )}

        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid item lg={12} xs={12}>
            <TextField
              disabled={!enableEditCompany || !editing}
              margin="dense"
              id="name"
              label={termCompanyName}
              fullWidth
              variant="outlined"
              name="name"
              value={editingCompany.name}
              onChange={handleCompanyChange}
              error={invalidCompanyName}
            />
          </Grid>
        )}
        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid item lg={12} xs={12}>
            <TextField
              disabled={!enableEditCompany || !editing}
              margin="dense"
              id="tradeName"
              label={termCompanyTradeName}
              fullWidth
              variant="outlined"
              name="tradeName"
              value={editingCompany.tradeName}
              onChange={handleCompanyChange}
              error={false}
            />
          </Grid>
        )}
        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid container xs={12} style={{ paddingRight: 4, paddingLeft: 4 }}>
            <InsertPhone
              disabled={!enableEditCompany || !editing}
              fieldId="commercialPhoneNumber"
              labelId="country-code-label-company"
              label={termPhone}
              error={invalidCompanyComercialPhoneNumber}
              onChange={handleCompanyChange}
              paperClass={classes.menuPaper}
              phone={editingCompany.commercialPhoneNumber}
              codeGridSize={4}
              inputGridSize={8}
              gridPaddingTop={8}
              countryCodeStyle={{ height: 40 }}
            />
          </Grid>

          // <Grid item lg={6} xs={12}>
          //   <TextField
          //     disabled={!enableEditCompany}
          //     margin="dense"
          //     id="commercialPhoneNumber"
          //     label={termPhone}
          //     fullWidth
          //     variant="outlined"
          //     name="commercialPhoneNumber"
          //     value={editingCompany.commercialPhoneNumber}
          //     onChange={handleCompanyChange}
          //     error={invalidCompanyComercialPhoneNumber}
          //   />
          // </Grid>
        )}
        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid item lg={12} xs={12}>
            <TextField
              disabled={!enableEditCompany || !editing}
              margin="dense"
              id="company-address-zipcode"
              label={termZIP}
              fullWidth
              variant="outlined"
              name="zipCode"
              value={editingCompany.address.zipCode}
              onChange={handleCompanyAddress}
              error={invalidCompanyAddressZip}
              onBlur={handleBlurZipCode(false)}
            />
          </Grid>
        )}
        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid item lg={9} xs={7}>
            <TextField
              disabled={!enableEditCompany || !editing}
              margin="dense"
              id="company-address-street"
              label={termAddress}
              fullWidth
              variant="outlined"
              name="street"
              value={editingCompany.address.street}
              onChange={handleCompanyAddress}
              error={invalidCompanyAddressStreet}
            />
          </Grid>
        )}
        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid item lg={3} xs={5}>
            <TextField
              disabled={!enableEditCompany || !editing}
              margin="dense"
              id="company-address-number"
              label={termNumber}
              fullWidth
              variant="outlined"
              name="number"
              value={editingCompany.address.number}
              onChange={handleCompanyAddress}
              error={invalidCompanyAddressNumber}
            />
          </Grid>
        )}
        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid item xs={12}>
            <TextField
              disabled={!enableEditCompany || !editing}
              margin="dense"
              id="company-address-complement"
              label={termComplement}
              fullWidth
              variant="outlined"
              name="complement"
              value={editingCompany.address.complement}
              onChange={handleCompanyAddress}
              error={false}
            />
          </Grid>
        )}
        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid item lg={7} xs={8}>
            <TextField
              disabled={!enableEditCompany || !editing}
              margin="dense"
              id="company-address-city"
              label={termCity}
              fullWidth
              variant="outlined"
              name="city"
              value={editingCompany.address.city}
              onChange={handleCompanyAddress}
              error={invalidCompanyAddressCity}
            />
          </Grid>
        )}
        {editingCompany !== undefined && profile.role !== undefined && profile.role <= Role.admin && (
          <Grid item lg={5} xs={4}>
            <TextField
              disabled={!enableEditCompany || !editing}
              margin="dense"
              id="company-address-state"
              label={termState}
              fullWidth
              variant="outlined"
              name="state"
              value={editingCompany.address.state}
              onChange={handleCompanyAddress}
              error={invalidCompanyAddressState}
            />
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="confirmation-dialog-title"
        onExiting={(e) => setPhoto(null)}
      >
        <DialogTitle id="confirmation-dialog-title">{termEditPictureTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{termEditPictureDialog}</DialogContentText>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <TextField
                disabled={true}
                error={errorImage}
                helperText={errorImage ? termEditPictureInvalid : null}
                fullWidth
                margin="dense"
                variant="outlined"
                value={photo ? photo.name : termPlaceHolderChoosePicture}
                onChange={(v) => {}}
              />
            </Grid>
            <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  accept="image/*"
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleChangePhoto}
                />
                <Button className="btn-choose" variant="outlined" component="span">
                  {termEditPictureChoose}
                </Button>
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {termEditPictureCancel}
          </Button>
          <Button
            disabled={photo ? false : true}
            onClick={
              profile.role === Role.admin ? handleUploadDirectLogo : handleUploadIndirectLogo
            }
            color="primary"
          >
            {termEditPictureSend}
          </Button>
        </DialogActions>
      </Dialog>
      <Actions className="flip-card">
        {editing ? (
          <>
            <Button
              onClick={(e) => {
                e.preventDefault();
                cancelEdition();
              }}
            >
              <Icon>cancel</Icon> <h6>{termCancelEdition}</h6>
            </Button>
            <Button
              disabled={invalidInput}
              onClick={(e) => {
                e.preventDefault();
                updateProfileAndCompany();
              }}
            >
              <Icon>save</Icon> <h6>{termSave}</h6>
            </Button>
          </>
        ) : (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setEditing(true);
            }}
          >
            <Icon>edit</Icon> <h6>{termEdit}</h6>
          </Button>
        )}
      </Actions>
    </>
  );
}

function PasswordField(props: {
  disabled?: boolean;
  id: string;
  value: string;
  label: string;
  onChange: (value: string) => void;
  error: boolean;
}): JSX.Element {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const classes = passwordFieldClasses();

  const error = props.error;

  return (
    <TextField
      className={error ? classes.error : classes.root}
      type={showPassword ? undefined : "password"}
      //helperText={props.helperText}
      fullWidth
      id={props.id}
      variant="outlined"
      label={props.label}
      size="small"
      value={props.value}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={props.disabled}
              aria-label={props.id + "-show"}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {<Icon>{showPassword ? "visibility_off" : "visibility"}</Icon>}
            </IconButton>
          </InputAdornment>
        ),
      }}
      disabled={props.disabled}
      error={error}
    />
  );
}

const passwordFieldClasses = makeStyles((theme) => ({
  root: {
    color: "black",
    fontSize: 14,
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.common.white,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
    top: "200px !important",
  },
}));
