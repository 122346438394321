import React from "react";
import { useIntl } from "react-intl";
import Text from "../../BasicComponents/Text";
import Button from "../../BasicComponents/Button";
import Clickable from "../../BasicComponents/Link";
import Image from "../../BasicComponents/Image";
import { Grid } from "@material-ui/core";
import Container from "../../Areas/Container";
import Column from "../../Areas/Column";
import Row from "../../Areas/Row";
import { Information, Actions } from "./styles";

interface Props {
  title?: string;
  subtitle?: string;
  description?: string;
  color?: "primary";
  linkTo?: string;
  textAlign?: "left" | "center" | "right";
  showButtons?: boolean | true;
  width?: string | "80";
  highlight?: string;
  extraDetails?: Array<{
    id: string;
    detail: string;
    image: string | "url";
  }>;
  labelButtonTerm?: string | "action.learnMore";
}

export default (props: Props) => {
  const intl = useIntl();

  return (
    <Information color={props.color} textAlign={props.textAlign} width={props.width}>
      <Text text={props.title} appearance={"title-semibold"} size={"medium"} color={"primary"} />
      <Text text={props.description} appearance={"description-block"} color={"default"} />

      <Text
        text={props.highlight}
        textAlign={props.textAlign}
        color={"highlighted"}
        appearance={"info-required"}
      />
      {props.showButtons && (
        <Actions>
          <Clickable to={props.linkTo}>
            <Button
              text={
                props.labelButtonTerm !== undefined
                  ? intl.formatMessage({ id: props.labelButtonTerm })
                  : ""
              }
              appearance={"outline-more"}
              color={props.color === "primary" ? "inherit" : "inherit-primary"}
              //size={"large"}
            />
          </Clickable>
          <Clickable to={props.linkTo}>
            <Button
              text={intl.formatMessage({ id: "action.learnMore.prices" })}
              color={"primary"}
              appearance={"highlighted"}
              size={"large"}
            />
          </Clickable>
        </Actions>
      )}
      <Grid className="detailsProduct">
        {props.extraDetails &&
          props.extraDetails.map((item) => (
            <Grid key={item.id} item lg={3} md={3} sm={3} className="alignImages">
              <Image src={item.image} />
              <Text
                text={item.detail}
                textAlign={props.textAlign}
                appearance={"description-block"}
                color={"secondary"}
              />
            </Grid>
          ))}
      </Grid>
    </Information>
  );
};
