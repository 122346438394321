import React from "react";
import { useIntl } from "react-intl";
import Link from "../../BasicComponents/Link";
import { Dropdown, Control, List, ListItem, Notifications, Badge, StyledAlert } from "./styles";
import history from "../../../../navigation/history";

interface Props {
  notificationItens?: Array<{
    id: string;
    notificationType: string;
    text: string;
    link: string;
    description: string;
    hasRead: boolean;
  }>;
}

export default (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function itemType(value: string) {
    switch (value) {
      case "error":
        return "error";
      case "info":
        return "info";
      case "success":
        return "success";
      case "warning":
        return "warning";
      default:
        return "success";
    }
  }

  return (
    <>
      <Dropdown>
        <Control aria-controls="notifications-list" aria-haspopup="true" onClick={handleClick}>
          <Notifications>{props.notificationItens && <Badge />}</Notifications>
        </Control>
        <List
          id="notifications-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props.notificationItens?.map((item) => (
            <ListItem
              onClick={() => {
                handleClose();
              }}
              className={"list-item"}
            >
              <StyledAlert
                onClick={() => {
                  history.push(item.link);
                  handleClose();
                }}
                severity={itemType(item.notificationType)}
              >
                {item.hasRead ? item.text : <strong>{item.text}</strong>}
              </StyledAlert>
            </ListItem>
          ))}
          <ListItem
            onClick={() => {
              handleClose();
            }}
            key={"all"}
            className={"all-notifications"}
          >
            <Link
              to="javascript:void(0);"
              onClick={() => {
                history.push("/dashboard/profile/notifications");
              }}
            >
              {"Ver tudo"}
            </Link>
          </ListItem>
        </List>
      </Dropdown>
    </>
  );
};
