import { FlightLogData, Location } from "biohub-model";
import React from "react";
import { MemoPolygon } from "../MemoMapComponents";

type Props = {
  flightId: string;
  logData: FlightLogData[];
  trackWidth: number;
  zIndex: number;
};

export default function (props: Props): JSX.Element {
  const circleRadius = props.trackWidth / 1000 / 2;

  const releasePointStyle = {
    strokeColor: "#32CD32",
    strokeOpacity: 0,
    fillColor: "#32CD32",
    fillOpacity: 0.5,
    zIndex: props.zIndex,
  };

  const logData = props.logData.sort(function (a, b) {
    if (a.releaserType > b.releaserType) return 1;
    if (a.releaserType < b.releaserType) return -1;
    return 0;
  });

  return (
    <>
      {logData.map((flightLogData, index) => {
        const releaseRoute = flightLogData.releaseRoute;

        return (
          <div key={`flight-${props.flightId}-released-route-${index}`}>
            {releaseRoute.map((path, pathIndex) => (
              <MemoPolygon
                key={`flight-${props.flightId}-released-${index}-${pathIndex}`}
                paths={path.map((location) =>
                  drawCircle(new google.maps.LatLng(location.lat, location.lng), circleRadius)
                )}
                options={releasePointStyle}
              />
            ))}
          </div>
        );
      })}
    </>
  );
}

/**
 * @param point: Center point of the circle being plot
 * @param radiusKm: Radius in km
 * @param dir: 1 = inwards, -1 = outwards
 * @returns A array of lat lng that specifies the circle (google.maps.LatLng)
 */
function drawCircle(
  point: google.maps.LatLng,
  radiusKm: number,
  dir: number = 1
): Array<google.maps.LatLng> {
  var d2r = Math.PI / 180; // degrees to radians
  var r2d = 180 / Math.PI; // radians to degrees
  var earthsradius = 3963; // 3963 is the radius of the earth in miles

  // Convert radius in km to miles
  const radius = radiusKm * 0.621371;

  var points = 32;

  // find the raidus in lat/lon
  var rlat = (radius / earthsradius) * r2d;
  var rlng = rlat / Math.cos(point.lat() * d2r);

  var extp = new Array<google.maps.LatLng>();
  if (dir == 1) {
    var start = 0;
    var end = points + 1;
  } // one extra here makes sure we connect the
  else {
    var start = points + 1;
    var end = 0;
  }
  for (var i = start; dir == 1 ? i < end : i > end; i = i + dir) {
    var theta = Math.PI * (i / (points / 2));
    let ey = point.lng() + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    let ex = point.lat() + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push(new google.maps.LatLng(ex, ey));
  }
  return extp;
}
