import React from "react";
import { useIntl } from "react-intl";
import Header from "../../../components/Atomic/Header";
import Footer from "../../../components/Atomic/Footer";
import Text from "../../../components/Atomic/BasicComponents/Text";
import Accordion from "../../../components/Atomic/BasicComponents/Accordion";
import { Container, Grid } from "@material-ui/core";
import { SupportPage, Content, Item, Description, Questions } from "../styles";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();

  // terms
  const supportTitle = intl.formatMessage({ id: "menu.support" });
  const supportSubtitle = intl.formatMessage({ id: "info.subtitleSupport" });
  const supportContent = intl.formatMessage({ id: "info.support" });
  const faqTitle = intl.formatMessage({ id: "menu.faq" });
  const faqSubtitle = intl.formatMessage({ id: "info.subtitleFaq" });
  const faqContent = intl.formatMessage({ id: "info.faq" });
  const faqQ1Title = intl.formatMessage({ id: "info.faqQuestion1" });
  const faqQ1Content = intl.formatMessage({ id: "info.faqQuestion1Content" });
  const faqQ2Title = intl.formatMessage({ id: "info.faqQuestion2" });
  const faqQ2Content = intl.formatMessage({ id: "info.faqQuestion2Content" });
  const faqQ3Title = intl.formatMessage({ id: "info.faqQuestion3" });
  const faqQ3Content = intl.formatMessage({ id: "info.faqQuestion3Content" });
  const whyTitle = intl.formatMessage({ id: "menu.why" });
  const whySubtitle = intl.formatMessage({ id: "info.subtitleWhy" });
  const whyContent = intl.formatMessage({ id: "info.why" });
  const resourcesTitle = intl.formatMessage({ id: "menu.resources" });
  const resourcesSubtitle = intl.formatMessage({ id: "info.subtitleResources" });
  const resourcesContent = intl.formatMessage({ id: "info.resources" });

  return (
    <>
      <SupportPage>
        <Header color={"dark"} />
        <Content id="support">
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <Item>
                  <Description>
                    <Text text={supportTitle} color={"primary"} appearance={"section-title"} />
                    <hr />
                    <Text text={supportSubtitle} color={"secondary"} appearance={"info-subtitle"} />
                    <Text text={supportContent} color={"dark"} appearance={"description-block"} />
                  </Description>
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Content>
        <Content id="faq">
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <Item>
                  <Description>
                    <Text text={faqTitle} color={"primary"} appearance={"section-title"} />
                    <hr />
                    <Text text={faqSubtitle} color={"secondary"} appearance={"info-subtitle"} />
                    <Text text={faqContent} color={"dark"} appearance={"description-block"} />

                    <Questions>
                      <Accordion id={"question1"} title={faqQ1Title} description={faqQ1Content} />
                      <Accordion id={"question2"} title={faqQ2Title} description={faqQ2Content} />
                      <Accordion id={"question3"} title={faqQ3Title} description={faqQ3Content} />
                    </Questions>
                  </Description>
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Content>
        <Content id="why">
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <Item>
                  <Description>
                    <Text text={whyTitle} color={"primary"} appearance={"section-title"} />
                    <hr />
                    <Text text={whySubtitle} color={"secondary"} appearance={"info-subtitle"} />
                    <Text text={whyContent} color={"dark"} appearance={"description-block"} />
                  </Description>
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Content>
        <Content id="resources">
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <Item>
                  <Description>
                    <Text text={resourcesTitle} color={"primary"} appearance={"section-title"} />
                    <hr />
                    <Text
                      text={resourcesSubtitle}
                      color={"secondary"}
                      appearance={"info-subtitle"}
                    />
                    <Text text={resourcesContent} color={"dark"} appearance={"description-block"} />
                  </Description>
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Content>
        <Footer image={true} description={intl.formatMessage({ id: "info.about" })} />
      </SupportPage>
    </>
  );
};
