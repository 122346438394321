import React from "react";
import { useIntl } from "react-intl";
import Container from "../../../components/Atomic/Areas/Container";
import Row from "../../../components/Atomic/Areas/Row";
import Column from "../../../components/Atomic/Areas/Column";
import Header from "../../../components/Atomic/Header";
import Footer from "../../../components/Atomic/Footer";
import PayCard from "../../../components/Atomic/Tools/PayCard";
import Text from "../../../components/Atomic/BasicComponents/Text";
import SignForm from "../../../components/Atomic/Forms/Sign";
import { SignPage } from "../styles";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();

  //terms
  const termTitle = intl.formatMessage({ id: "action.signUp" });
  const termAboutInfo = intl.formatMessage({ id: "info.about" });

  const payment = [
    {
      amount: 1,
      plan: "bioMAPS",
      mode: intl.formatMessage({ id: "info.per.year" }),
      price: 122.4,
    },
  ];

  return (
    <>
      <SignPage>
        <Header color={"dark"} />
        <Container>
          <Row>
            <Column xs={12}>
              <Text text={termTitle} color={"primary"} size={"large"} />
            </Column>
            <Column xs={12} md={6}>
              <SignForm />
            </Column>
            <Column xs={12} md={6}>
              <PayCard paymentInfo={payment} />
            </Column>
          </Row>
        </Container>
        <Footer image={true} description={termAboutInfo} />
      </SignPage>
    </>
  );
};
