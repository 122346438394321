import { Box } from "@material-ui/core";
import { Role } from "biohub-model";
import React from "react";
import { useIntl } from "react-intl";
import { CustomTreeItem, ListItemAction } from "./CustomTreeItem";
import { ProjectTreeItemTrailingIcon } from "./ProjectsTreeItemTrailingIcon";

type Props = {
  projectId: string;
  projectName: string;
  areaCount: number | undefined;
  areasAlreadyLoaded: boolean;
  isLoadingAreas: boolean;
  beingDeleted: boolean;
  isCreatingArea: boolean;
  isUpdating: boolean;
  selectedProjectId: string | null;
  isVisible: boolean;
  onClickProject: () => void;
  onOpenOrCloseProject: () => void;
  onChangeProjectVisibility: () => void;
  profileRole: Role;
  onClickToEditProjectSettings: () => void;
  onClickToAddAreaInProject: () => void;
  onClickToDeleteProject: () => void;
  onClickToCloseProject: () => void;
  onClickPasteCopiedArea: () => void;
  hasCopiedArea: boolean;
  children: React.ReactNode[];
};

export default function (props: Props): JSX.Element {
  const intl = useIntl();

  const areaCount = props.areaCount;

  const profileRole = props.profileRole;

  let projectItemActionsList: ListItemAction[] | undefined = [];

  if (profileRole < Role.external) {
    projectItemActionsList.push({
      text: intl.formatMessage({ id: "action.settings" }),
      action: () => {
        props.onClickToEditProjectSettings();
      },
    });
    projectItemActionsList.push({
      text: intl.formatMessage({ id: "map.newArea.createAreaModalTitle" }),
      action: () => {
        props.onClickToAddAreaInProject();
      },
    });
    if (props.hasCopiedArea) {
      projectItemActionsList.push({
        text: intl.formatMessage({ id: "map.pasteCopiedArea" }),
        action: () => {
          props.onClickPasteCopiedArea();
        },
      });
    }
    if (profileRole === Role.master || profileRole === Role.admin || profileRole === Role.manager) {
      projectItemActionsList.push({
        text: intl.formatMessage({ id: "action.remove" }),
        action: () => {
          props.onClickToDeleteProject();
        },
      });
    }
  }

  const isProjectSelected = props.projectId === props.selectedProjectId;

  if (isProjectSelected) {
    projectItemActionsList.push({
      text: intl.formatMessage({ id: "map.closeProject" }),
      action: () => {
        props.onClickToCloseProject();
      },
    });
  }

  if (projectItemActionsList.length === 0) {
    projectItemActionsList = undefined;
  }

  return (
    <CustomTreeItem
      id={props.projectId}
      key={props.projectId}
      content={props.projectName}
      totalizer={props.areaCount}
      trailing={
        props.areasAlreadyLoaded ? (
          <div style={{ marginRight: 0, marginTop: "-0.1em" }}>
            <ProjectTreeItemTrailingIcon
              isHidden={!props.isVisible}
              onClick={props.onChangeProjectVisibility}
            />
          </div>
        ) : (
          <Box width={5} />
        )
      }
      loading={
        props.isLoadingAreas || props.beingDeleted || props.isCreatingArea || props.isUpdating
      }
      highlighted={props.projectId === props.selectedProjectId}
      actions={projectItemActionsList}
      onClick={props.onClickProject}
      onOpenAction={props.onOpenOrCloseProject}
    >
      {props.children}
    </CustomTreeItem>
  );
}
