import React from "react";
import { AccordionDetail, AccordionItem, AccordionSummary, Icon, Text } from "../styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

interface Props {
  multiSelect: boolean;
  selected?: string;
  expanded?: boolean;
  onClick?: (id: string) => void;
  item: { id: string; title: string; body: string | React.ReactElement };
  lastChild: boolean;
}

export default (props: Props) => {
  const [hide, setHide] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (props.multiSelect === true && props.expanded) {
      setHide(false);
    } else {
      props.multiSelect === false && props.item.id === props.selected!
        ? setHide(false)
        : setHide(true);
    }
  }, [props.selected, props.item.id, props.multiSelect, props.expanded]);

  const handleHide = () => {
    props.expanded ? setHide(false) : setHide(!hide);
  };

  return (
    <AccordionItem selected={!hide}>
      <AccordionSummary
        onClick={props.multiSelect ? () => handleHide() : () => props.onClick!(props.item.id)}
        lastChild={props.lastChild}
        selected={!hide}
        key={props.item.id}
      >
        <Text style={{ width: "100%" }}>{props.item.title}</Text>{" "}
        <Icon>{hide ? <AddIcon /> : <RemoveIcon />}</Icon>
      </AccordionSummary>

      <AccordionDetail lastChild={props.lastChild} selected={!hide}>
        {props.item.body}
      </AccordionDetail>
    </AccordionItem>
  );
};
