import React from "react";
import { Card, CardContent, CardTitle } from "./styles";

interface Props {
  title: string;
  body: string | React.ReactElement;
  margin_bottom?: string | "0em"
}

export default (props: Props) => {
  return (
    <>
      <Card margin_bottom={props.margin_bottom}>
        <CardTitle>
          <div
            style={{
              paddingLeft: 10,
            }}
          >
            {props.title}
          </div>
        </CardTitle>
        <CardContent>{props.body}</CardContent>
      </Card>
    </>
  );
};
