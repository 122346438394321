import { Collapse, Grid } from "@material-ui/core";
import { Area, Project, Role, UnitSystem } from "biohub-model";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import React from "react";
import OverlayContainer from "../OverlayContainer";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import { calculateAreaHa, totalPathLength } from "../../../core/geometricFunctions";
import _ from "lodash";
import {
  plannedCoverageArea,
  plannedPathTime,
  polygonAvailableAreaForPlanning,
} from "../../../core/areaPlanning";
import { useIntl } from "react-intl";
import { timeToHMS } from "../../../core/formatter";
import UnitConversionHelper from "../../../core/helper/UnitConversionHelper";
import { castProjectInProjectTreeToProject } from "../../../store/reducers/projectTreeReducer";

type Props = {
  align?: "left" | "center";
  paddingLeft?: number;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
};

const _FooterOverlay = (props: Props): JSX.Element => {
  const { expanded, setExpanded } = props;

  const project = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const selectedProjectId = projectTreeState.selectedProjectId;
    if (selectedProjectId === null) return null;

    const project = projectTreeState.projectList?.find(
      (project) => project.id === selectedProjectId
    );
    if (project === undefined) return null;

    return project;
  });

  const area = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const selectedProjectId = projectTreeState.selectedProjectId;
    if (selectedProjectId === null) return null;

    const project = projectTreeState.projectList?.find(
      (project) => project.id === selectedProjectId
    );
    if (project === undefined) return null;

    const selectedAreaId = project.selectedAreaId;
    if (selectedAreaId === null) return null;

    const area = project.areas?.find((area) => area.id === selectedAreaId);
    if (area == undefined) return null;

    return area;
  });

  const width = 335;

  return (
    <div
      style={{
        display: "flex !important",
        flex: "1 1 content !important",
        flexDirection: "column",
        minHeight: "0px !important",
        alignItems: "center !important",
        alignSelf: "stretch !important",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          flexDirection: "row",
          justifyContent: props.align,
          paddingLeft: props.paddingLeft,
        }}
      >
        <div
          style={{
            height: "30px",
            // Same width as the info box itself
            width: width,
            // Offset this mostly to the right
            paddingLeft: width / 2,
            // When closed only, avoid the google maps copyright area.
            marginBottom: expanded ? "5px" : "9px",
            transition: "all 0.15s linear",
          }}
        >
          <ToggleButton
            expanded={expanded}
            onOpen={() => {
              setExpanded(true);
            }}
            onClose={() => {
              setExpanded(false);
            }}
          />
        </div>
      </div>
      <Collapse in={expanded} timeout="auto" style={{ width: width }}>
        <OverlayContainer>
          <InfoPanel
            project={project !== null ? castProjectInProjectTreeToProject(project) : null}
            area={area}
          />
        </OverlayContainer>
      </Collapse>
    </div>
  );
};

/**
 * A retractible translucent footer for the map.
 */

const FooterOverlay = React.memo(_FooterOverlay, (prevProps, newProps) => {
  if (prevProps.align !== newProps.align) return false;
  if (prevProps.expanded !== newProps.expanded) return false;
  if (prevProps.paddingLeft !== newProps.paddingLeft) return false;
  if (prevProps.setExpanded !== newProps.setExpanded) return false;

  return true;
});

export default FooterOverlay;

type ToggleButtonProps = {
  expanded: boolean;
  onOpen: () => void;
  onClose: () => void;
};

function ToggleButton(props: ToggleButtonProps): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        width: "24px",
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "row",
        padding: "5px",
        pointerEvents: "auto",
      }}
      onClick={() => {
        if (props.expanded) {
          props.onClose();
        } else {
          props.onOpen();
        }
      }}
    >
      <OverlayContainer
        style={{ display: "flex", flex: 1, flexDirection: "row" }}
        pointyBottomLeft={props.expanded}
        pointyBottomRight={props.expanded}
      >
        {props.expanded ? (
          <KeyboardArrowDown style={{ color: "var(--black)" }} />
        ) : (
          <KeyboardArrowUp style={{ color: "var(--black)" }} />
        )}
      </OverlayContainer>
    </div>
  );
}

type InfoPanelProps = {
  project: Project | null;
  area: Area | null;
};

const InfoPanel = React.memo(_InfoPanel, (prevProps, newProps) => {
  if (typeof prevProps.project !== typeof newProps.project) return false;
  if (prevProps.project?.name !== newProps.project?.name) return false;
  if (typeof prevProps.area !== typeof newProps.area) return false;
  if (prevProps.area?.name !== newProps.area?.name) return false;
  if (!_.isEqual(prevProps.area?.planned, newProps.area?.planned)) return false;
  return true;
});

function _InfoPanel(props: InfoPanelProps): JSX.Element {
  const area = props.area;

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const lines: { text: string; type?: "title" }[] = [];

  const intl = useIntl();
  const noProjectOpenTerm = intl.formatMessage({ id: "map.footer.noProjectOpen" });
  const possibleCoverageAreaTerm = intl.formatMessage({ id: "map.footer.possibleCoverageArea" });
  const coverageAreaTerm = intl.formatMessage({ id: "map.footer.coveredArea" });

  var lineOne = "";
  var lineTwo = "";

  if (props.project == null) {
    lineOne = noProjectOpenTerm;
    lineTwo = " – ";
  } else if (props.area == null) {
    lineOne = props.project!.name ?? props.project!.id;
    lineTwo = " - ";
  } else {
    lineOne = `${props.project!.name ?? props.project!.id} – ${props.area!.name}`;

    const areaValue = calculateAreaHa(props.area!.planned.polygon);
    const surfaceArea = areaValueFormatter(areaValue, unitSystem);

    lineTwo = `${surfaceArea}`;
    if (area !== null && area.planned.route !== undefined) {
      const flightTime = plannedPathTime(area.planned.route.waypoints, area.areaConfig.flightSpeed);
      const flightTimeFormatted = timeToHMS(
        flightTime.hours,
        flightTime.minutes,
        flightTime.seconds
      );

      lineTwo = `${surfaceArea} – ${flightTimeFormatted} – ${area.planned.route.waypoints.length} waypoints`;
    }
  }

  lines.push({
    type: "title",
    text: lineOne,
  });
  lines.push({
    text: lineTwo,
  });

  if (area !== null) {
    const waypoints = area.planned.route?.waypoints;

    const availablePlanningPolygon = polygonAvailableAreaForPlanning(
      area.planned.polygon,
      area.areaConfig.areaPadding,
      area.areaConfig.trackWidth
    );

    const availablePlanningPolygonArea = calculateAreaHa(availablePlanningPolygon);

    lines.push({
      text: possibleCoverageAreaTerm.replace(
        "{areaSize}",
        areaValueFormatter(availablePlanningPolygonArea, unitSystem)
      ),
    });
    if (waypoints !== undefined) {
      const coverageArea = plannedCoverageArea(waypoints, area.areaConfig.trackWidth) / 10000;

      lines.push({
        text: coverageAreaTerm
          .replace("{areaSize}", areaValueFormatter(coverageArea, unitSystem))
          .replace(
            "{percentage}",
            ((coverageArea / availablePlanningPolygonArea) * 100).toFixed(1)
          ),
      });
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      {lines.map((line, index) => (
        <p
          key={`footer-overlay-info-panel-${index}`}
          style={{ color: "var(--black)", margin: line.type === "title" ? "5px" : "0px" }}
        >
          {line.text}
        </p>
      ))}
    </div>
  );
}

const areaValueFormatter = (area: number, unitSystem: UnitSystem): string => {
  return (
    UnitConversionHelper.areaValue(area, unitSystem, 2) + UnitConversionHelper.areaUnit(unitSystem)
  );
};
