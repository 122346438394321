import React from "react";
import AccountPage from "./containers/Account";
import SettingsPage from "./containers/Settings";
import ProfilePage from "./containers/ProfileTable";
import ProfileTables from "./containers/ProfileTables";
import NotificationsPage from "./containers/Notifications";
import LicensesPage from "./containers/ManageLicenses";
import { Profile } from "./styles";

interface Props {
  activeItem?: "account" | "settings" | "management" | "notifications" | "licenses";
  notificationItens?: Array<{
    id: string;
    notificationType: string;
    text: string;
    link: string;
    description: string;
    hasRead: boolean;
  }>;
}

export default (props: Props) => {
  // this function maps the url and informs what type of component will be rendered
  function createComponent(value: any) {
    switch (value) {
      case "account":
        return <AccountPage />;
      case "settings":
        return <SettingsPage />;
      case "management":
        return <ProfileTables />;
      case "notifications":
        return <NotificationsPage notificationItens={props.notificationItens} />;
      case "licenses":
        return <LicensesPage />;
      default:
        return <ProfilePage />;
    }
  }

  return (
    <>
      <Profile>{createComponent(props.activeItem)}</Profile>
    </>
  );
};
