import React from "react";
import { Grid } from "@material-ui/core";
import TableUsers from "./TableUsers";
import TableIndirectClients from "./TableIndirectClients";
import TablePreRegistrations from "./TablePreRegistrations";
import TableReleasers from "./TableReleasers";
import TableDrones from "./TableDrones";
import TableCpu from "./TableCpu";
import {} from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { readAllDirectClients } from "../../../store/actions/masterActions";
import { SystemState } from "../../../store/reducers/systemReducer";
import { Role } from "biohub-model";
import TableDirectClients from "./TableDirectClients";

export default () => {
  const userProfile = useSelector((state: SystemState) => state.profile.userProfile);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (userProfile !== null) {
      if (userProfile.role === Role.master) {
        dispatch(readAllDirectClients());
      }
    }
  }, [userProfile]);

  return (
    <>
      <Grid container>
        <Grid item lg={12} sm={12}>
          <TableUsers />
        </Grid>
        {userProfile?.role === Role.master && (
          <Grid item lg={12} sm={12}>
            <TableDirectClients />
          </Grid>
        )}
        <Grid item lg={12} sm={12}>
          <TableCpu />
        </Grid>
        <Grid item lg={12} sm={12}>
          <TableDrones />
        </Grid>
        <Grid item lg={12} sm={12}>
          <TableReleasers />
        </Grid>
        <Grid item lg={12} sm={12}>
          <TableIndirectClients />
        </Grid>
        {userProfile?.role === Role.master && (
          <Grid item lg={12} sm={12}>
            <TablePreRegistrations />
          </Grid>
        )}
      </Grid>
    </>
  );
};
