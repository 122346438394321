import { Role } from "biohub-model";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  collapseArea,
  expandArea,
  exportAsKml,
  selectArea,
  setAreaInvisible,
  setAreaVisible,
} from "../../store/actions/projectTreeActions";
import { AreaInProjectTree } from "../../store/reducers/projectTreeReducer";
import { SystemState } from "../../store/reducers/systemReducer";
import AreaInProjectTreeComponent from "./components/AreaInProjectTree";
import { CustomTreeItem } from "./components/CustomTreeItem";
import ElementsState, { ElementsStateText } from "./components/ElementsState";
import ListFlightsInProjectTree from "./ListFlightsInProjectTree";
import { ExportKmlTypes } from "../../services/KmklService";

type Props = {
  projectId: string;
  onClickToEditAreaSettings: (area: AreaInProjectTree) => void;
  onClickToDeleteArea: (area: AreaInProjectTree) => void;
  onClickDuplicateArea: (area: AreaInProjectTree) => void;
  onClickCopyArea: (area: AreaInProjectTree) => void;
};

export default function (props: Props): JSX.Element {
  const areaListState: ElementsStateText = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const project = projectTreeState.projectList?.find((project) => project.id === props.projectId);
    if (project === undefined) return "empty";

    const areaList = project.areas;
    if (project.isLoadingAreas) {
      if (areaList !== null && areaList !== undefined) {
        return "reloading";
      } else {
        return "loading";
      }
    }

    if (areaList === null || areaList === undefined || areaList.length === 0) return "empty";

    return "regular";
  });

  const areaList = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const areas =
      projectTreeState.projectList?.find((project) => project.id === props.projectId)?.areas ?? [];

    return areas;
  });

  const selectedAreaId = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const selectedAreaId = projectTreeState.projectList?.find(
      (project) => project.id === props.projectId
    )?.selectedAreaId;

    if (selectedAreaId === undefined || selectedAreaId === null) return null;

    return selectedAreaId;
  });

  const profileRole = useSelector(
    (state: SystemState) => state.profile.userProfile?.role ?? Role.external
  );

  const dispatch = useDispatch();

  const intl = useIntl();

  return (
    <>
      {areaListState !== "regular" && (
        <ElementsState nodeId={props.projectId} state={areaListState} type={"areas"} />
      )}
      {areaList.map((area) => (
        <AreaInProjectTreeComponent
          key={`${props.projectId}-${area.id}-area-in-project-tree`}
          projectId={props.projectId}
          areaId={area.id}
          areaName={area.name}
          flightAmount={area.flightList?.length}
          beingDeleted={area.beingDeleted}
          isUpdating={area.isUpdating}
          areaSizeInHa={area.areaSizeInHa}
          isVisible={area.visible}
          isRunningMissionPlanner={area.isRunningMissionPlanner}
          isLoadingFlights={area.isLoadingFlights}
          selectedAreaId={selectedAreaId}
          onClickArea={() => {
            dispatch(selectArea(props.projectId, area.id));
          }}
          onOpenOrCloseArea={() => {
            if (!area.expanded) {
              dispatch(expandArea(props.projectId, area.id));
            } else {
              dispatch(collapseArea(props.projectId, area.id));
            }
          }}
          onChangeAreaVisibility={() => {
            if (area.visible) {
              dispatch(setAreaInvisible(props.projectId, area.id));
            } else {
              dispatch(setAreaVisible(props.projectId, area.id));
            }
          }}
          profileRole={profileRole}
          onClickToEditAreaSettings={() => props.onClickToEditAreaSettings(area)}
          onClickToDeleteArea={() => props.onClickToDeleteArea(area)}
          onClickDuplicateArea={() => props.onClickDuplicateArea(area)}
          onClickCopyArea={() => props.onClickCopyArea(area)}
          isExportingAsKml={area.isExportingAsKml}
          onClickExportAreaAsKML={() => {
            if (area.id === undefined) return;
            dispatch(exportAsKml(props.projectId, area.id, undefined, [ExportKmlTypes.polygon]));
          }}
          onClickExportRouteAsKML={() => {
            if (area.id === undefined) return;
            dispatch(exportAsKml(props.projectId, area.id, undefined, [ExportKmlTypes.route]));
          }}
        >
          <ListFlightsInProjectTree projectId={props.projectId} areaId={area.id} />
        </AreaInProjectTreeComponent>
      ))}
    </>
  );
}
