import { Box } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OverlayContainer from "../../../components/Atomic/OverlayContainer";
import { SystemState } from "../../../store/reducers/systemReducer";
import Dropdown from "../../../components/Atomic/Inputs/Dropdown";
import { Role } from "biohub-model";
import { useIntl } from "react-intl";
import { AreaInProjectTree, EditingAreaType } from "../../../store/reducers/projectTreeReducer";
import {
  enterEditAreaManualRouteMode,
  enterEditAreaPointsMode,
  enterEditAreaPolygonMode,
  enterEditAreaRouteMode,
  runMissionPlanner,
  updateEditingArea,
} from "../../../store/actions/projectTreeActions";

type Props = {
  onClickToSelectPlannedRoute: (area: AreaInProjectTree) => void;
  onClickToImportKmlRoute: (area: AreaInProjectTree) => void;
};

export default function (props: Props): JSX.Element {
  const openedArea = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const selectedProjectId = projectTreeState.selectedProjectId;
    if (selectedProjectId === null) return undefined;

    const selectedProject = projectTreeState.projectList?.find(
      (project) => project.id === selectedProjectId
    );
    if (selectedProject === undefined) return undefined;

    const selectedAreaId = selectedProject.selectedAreaId;
    if (selectedAreaId === undefined) return undefined;

    const selectedArea = selectedProject.areas?.find((area) => area.id === selectedAreaId);
    return selectedArea;
  });

  const dispatch = useDispatch();

  const intl = useIntl();

  const mustReleaseEntireArea = useSelector((state: SystemState) => {
    const userProfile = state.profile.userProfile;
    if (userProfile === null || userProfile.role === Role.external) return false;

    return userProfile.preferences.mustReleaseEntireArea;
  });

  const profileRole = useSelector((state: SystemState) =>
    state.profile.userProfile ? state.profile.userProfile.role : Role.external
  );

  const editingArea = useSelector((state: SystemState) => state.projectTree.editingArea);

  return (
    <>
      {openedArea !== undefined && (
        <Box style={boxStyle}>
          <OverlayContainer
            style={{
              marginRight: 10,
              marginTop: 10,
            }}
          >
            <div style={{ pointerEvents: "fill" }}>
              {/* <Icon style={{ color: "white", pointerEvents: "fill" }}>edit</Icon> */}
              <Dropdown<() => void>
                color="light"
                arrowColor="var(--black)"
                appearance="img-icon"
                listItems={((): { value: () => void; text: string }[] => {
                  const items = [];
                  if (profileRole < Role.external) {
                    if (
                      editingArea !== undefined &&
                      (editingArea.type === EditingAreaType.EditingEverything ||
                        editingArea.type === EditingAreaType.EditingPlanPoints)
                    ) {
                      items.push({
                        value: () => {
                          const editingAreaType = editingArea.type;

                          if (
                            editingAreaType !== EditingAreaType.EditingEverything &&
                            editingArea.type !== EditingAreaType.EditingPlanPoints
                          )
                            return;

                          const waypoints = editingArea.waypoints;

                          if (waypoints.length === 0) return;

                          const firstWaypointReleaserActions = waypoints[0].releaserActions;
                          const lastWatpointReleaserActions =
                            waypoints[waypoints.length - 1].releaserActions;

                          dispatch(
                            updateEditingArea({
                              ...editingArea,
                              waypoints: waypoints.reverse().map((waypoint, waypointIndex) => ({
                                ...waypoint,
                                releaserActions:
                                  waypointIndex === 0
                                    ? firstWaypointReleaserActions
                                    : waypointIndex === waypoints.length - 1
                                    ? lastWatpointReleaserActions
                                    : waypoint.releaserActions,
                              })),
                            })
                          );
                        },
                        text: intl.formatMessage({ id: "map.editMode.reverseRoute" }),
                      });
                    }

                    items.push({
                      value: () => {
                        dispatch(enterEditAreaPolygonMode(openedArea.projectId, openedArea.id));
                      },
                      text: intl.formatMessage({ id: "map.editPolygonPoints" }),
                    });
                    items.push({
                      value: () => {
                        dispatch(enterEditAreaRouteMode(openedArea.projectId, openedArea.id));
                      },
                      text: intl.formatMessage({ id: "map.editMode.editRoute" }),
                    });
                    items.push({
                      value: () => {
                        dispatch(enterEditAreaManualRouteMode(openedArea.projectId, openedArea.id));
                      },
                      text: intl.formatMessage({ id: "map.editMode.enterManualRoute" }),
                    });
                    items.push({
                      value: () => {
                        dispatch(enterEditAreaPointsMode(openedArea.projectId, openedArea.id));
                      },
                      text: intl.formatMessage({ id: "map.editPlan" }),
                    });
                    items.push({
                      value: () => {
                        dispatch(
                          runMissionPlanner({
                            projectId: openedArea.projectId,
                            areaId: openedArea.id,
                            areaConfig: openedArea.areaConfig,
                            areaPolygon: openedArea.planned.polygon,
                            configuredReleasers: openedArea.configuredReleasers,
                            homePoint: undefined,
                            mustReleaseEntireArea: mustReleaseEntireArea,
                          })
                        );
                      },
                      text: intl.formatMessage({ id: "map.replanRoute" }),
                    });
                  }
                  if (openedArea.flightList !== null && openedArea.flightList.length > 0) {
                    items.push({
                      value: () => {
                        props.onClickToSelectPlannedRoute(openedArea);
                      },
                      text: intl.formatMessage({ id: "map.plannedRoutes.title" }),
                    });
                  }
                  items.push({
                    value: () => {
                      props.onClickToImportKmlRoute(openedArea);
                    },
                    text: intl.formatMessage({ id: "map.importRouteKml" }),
                  });
                  return items;
                })()}
                onSelect={(f) => {
                  f();
                }}
              />
            </div>
          </OverlayContainer>
        </Box>
      )}
    </>
  );
}

const boxStyle: React.CSSProperties = {
  position: "absolute",
  display: "flex",
  justifyContent: "flex-start",
  flex: 1,
  alignItems: "flex-end",
  flexDirection: "column",
  zIndex: 20,
  backgroundColor: "#ffffff00", // transparent
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: "none",
};
