import React from "react";
import { IntlShape, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  Button,
  Icon,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { PreRegisterForm, Profile, Role } from "biohub-model";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";

import { TableList, Modal } from "../styles";
import PreRegisterService from "../../../services/PreRegisterService";

import UserTableComponent from "../../../components/Atomic/UserTable";
import { readPreRegistrations } from "../../../store/actions/masterActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function PreRegistrationsTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const loading = useSelector((state: SystemState) => state.master.loadingPreRegistrations);
  const preRegistrations = useSelector((state: SystemState) => state.master.preRegistrations);

  const [viewPreRegistration, setViewPreRegistration] = React.useState<PreRegisterForm>();

  const userProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  const handleClickOpen = (preRegister: PreRegisterForm) => {
    setViewPreRegistration(preRegister);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setViewPreRegistration(undefined);
  };

  React.useEffect(() => {
    dispatch(readPreRegistrations());
  }, []);

  //terms
  const termsName = intl.formatMessage({ id: "placeholder.name" });
  const termsPhone = intl.formatMessage({ id: "placeholder.phone" });
  const termsEmail = intl.formatMessage({ id: "placeholder.email" });
  const termsPreRegistrations = intl.formatMessage({ id: "info.preregistrations" });

  return (
    <div>
      {userProfile !== null &&
        userProfile.role <= Role.master &&
        (!loading ? (
          <UserTableComponent<PreRegisterForm>
            items={preRegistrations}
            titleTerm={termsPreRegistrations}
            addTerm={undefined}
            onAddFunction={undefined}
            classes={["id", termsName, termsEmail, termsPhone]}
            formatItems={(item: PreRegisterForm) => {
              return [item.id, item.name, item.email, item.cellPhoneNumber];
            }}
            onViewItem={(itemId: string) => {
              let selectedRegister: PreRegisterForm | undefined = undefined;
              for (let i = 0; i < preRegistrations.length && selectedRegister === undefined; i++) {
                if (preRegistrations[i].id === itemId) {
                  selectedRegister = preRegistrations[i];
                }
              }
              if (selectedRegister !== undefined) {
                handleClickOpen(selectedRegister);
              }
            }}
            onRemoveFunction={undefined}
          />
        ) : (
          <CircularProgress />
        ))}
      <div>
        <PreRegisterView
          open={open}
          intl={intl}
          closeCallback={handleClose}
          preRegister={viewPreRegistration}
        />
      </div>
    </div>
  );
}

interface PreRegisterViewProps {
  open: boolean;
  intl: IntlShape;
  preRegister?: PreRegisterForm;
  closeCallback: Function;
}

type PreRegisterViewState = {
  open: boolean;
  intl: IntlShape;
  name: string;
  email: string;
  phone: string;
  companyName: string;
  surveyAnswer: string;
  closeCallback: Function;
};

class PreRegisterView extends React.Component<PreRegisterViewProps, PreRegisterViewState> {
  _extractProps(props: PreRegisterViewProps): PreRegisterViewState {
    return {
      open: props.open,
      intl: props.intl,
      name: props.preRegister !== undefined ? props.preRegister.name : "",
      email: props.preRegister !== undefined ? props.preRegister.email : "",
      phone: props.preRegister !== undefined ? props.preRegister.cellPhoneNumber : "",
      companyName:
        props.preRegister !== undefined && props.preRegister.companyName !== undefined
          ? props.preRegister.companyName
          : "",
      surveyAnswer:
        props.preRegister !== undefined && props.preRegister.surveyAnswer !== undefined
          ? props.preRegister.surveyAnswer
          : "",
      closeCallback: props.closeCallback,
    };
  }

  constructor(props: PreRegisterViewProps) {
    super(props);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.state = this._extractProps(props);
  }

  componentWillReceiveProps(newProps: PreRegisterViewProps) {
    this.setState(this._extractProps(newProps));
  }

  render() {
    const intl = this.state.intl;
    //terms
    const termsPreRegistration = intl.formatMessage({ id: "info.preregistration" });
    const termClose = intl.formatMessage({ id: "generic.close" });
    const termViewDetails = intl.formatMessage({ id: "info.preregistrations.viewdetails" });
    const termsName = intl.formatMessage({ id: "placeholder.name" });
    const termsPhone = intl.formatMessage({ id: "placeholder.phone" });
    const termsEmail = intl.formatMessage({ id: "placeholder.email" });
    const termsCompany = intl.formatMessage({ id: "placeholder.company" });
    const termsSurveyAnswer = intl.formatMessage({ id: "placeholder.data.collect" });

    return (
      <Modal
        open={this.state.open}
        onClose={(e) => this.state.closeCallback()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {termsPreRegistration}
          <Icon onClick={(e) => this.state.closeCallback()}>close</Icon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{termViewDetails}</DialogContentText>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <TextField
                disabled={true}
                margin="dense"
                id="name"
                label={termsName}
                fullWidth
                name="name"
                variant="outlined"
                value={this.state.name}
              />
            </Grid>
            <Grid item lg={7} xs={7}>
              <TextField
                disabled={true}
                margin="dense"
                id="email"
                label={termsEmail}
                fullWidth
                name="email"
                variant="outlined"
                value={this.state.email}
              />
            </Grid>
            <Grid item lg={5} xs={5}>
              <TextField
                disabled={true}
                margin="dense"
                id="contact"
                label={termsPhone}
                fullWidth
                name="contact"
                variant="outlined"
                value={this.state.phone}
              />
            </Grid>

            <Grid item lg={5} xs={5}>
              <TextField
                disabled={true}
                margin="dense"
                id="contact"
                label={termsCompany}
                fullWidth
                name="companyName"
                variant="outlined"
                value={this.state.companyName}
              />
            </Grid>

            <Grid item lg={5} xs={5}>
              <TextField
                disabled={true}
                margin="dense"
                id="contact"
                label={termsSurveyAnswer}
                fullWidth
                name="surveyAnswer"
                variant="outlined"
                value={this.state.surveyAnswer}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => this.state.closeCallback()}
            className="cancel-button"
            startIcon={<Icon>close</Icon>}
          >
            {termClose}
          </Button>
        </DialogActions>
      </Modal>
    );
  }
}
