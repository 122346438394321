import React from "react";
import { useIntl } from "react-intl";
import Input from "../Field";
import { Block, Label, Select, Option, TextBox } from "./styles";

export type selectItems = {
  id: any;
  title: string;
};
interface Props {
  key: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  /**
   * Items available for choosing.
   *
   * Setting a value of "other" will display an additional field.
   */
  options: Array<{
    id: string | number;
    title: string;
  }>;
  /**
   * Gets called when a new option is selected, with the item's id.
   *
   * The type of the parameter will be the same as the id in the options array. You may need
   * to cast it.
   *
   */
  onChange: (id: string | number) => void;
}

export default (props: Props) => {
  const intl = useIntl();
  const [value, setValue] = React.useState(""); //fixed option selected
  const [visible, setVisible] = React.useState(false); //if true = displays text field for "Others"

  const [otherText, setOtherText] = React.useState("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);

    //if selected value = "Others": displays text field
    if (event.target.value === "other") {
      props.onChange(`other: ${event.target.value}`);
      return setVisible(true);
    } else {
      props.onChange(event.target.value as number | string);
      return setVisible(false);
    }
  };

  return (
    <>
      <Block variant="outlined" required={props.required}>
        <Label id={`label_${props.key}`}>{props.placeholder}</Label>
        <Select
          labelId={`label_${props.key}`}
          id={props.key}
          value={value}
          //fix selected value or display text fields
          onChange={handleChange}
          label={props.placeholder}
        >
          {/* creates list of options with values ​​received from the props */}
          {props.options.map((item) => (
            <Option value={item.id} key={`${item.id}`}>
              {item.title}
            </Option>
          ))}
        </Select>
        <TextBox visible={visible}>
          <Input
            placeholder={intl.formatMessage({ id: "option.which" })}
            type={"text"}
            required
            value={otherText}
            onChange={(text) => {
              setOtherText(text);
              props.onChange(`other: ${text}`);
            }}
          />
        </TextBox>
      </Block>
    </>
  );
};
