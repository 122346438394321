import { FlightLogData, Location } from "biohub-model";
import React from "react";
import FlightPolygon from "./core/polygons/FlightPolygon";
import FlightPolyline from "./core/polylines/FlightPolyline";

type Props = {
  flightId: string;
  flownRoute: Location[];
  logData: FlightLogData[];
  trackWidth: number;
  pathZIndex: number;
  pathBorderZIndex: number;
  releaseTracksZIndex: number;
};

export default function (props: Props): JSX.Element {
  return (
    <>
      <FlightPolyline
        key={`${props.flightId}-map-component-polyline`}
        flightId={props.flightId}
        flownRoute={props.flownRoute}
        pathZindex={props.pathZIndex}
        pathBorderZIndex={props.pathBorderZIndex}
      />
      <FlightPolygon
        key={`${props.flightId}-map-component-polygon`}
        flightId={props.flightId}
        logData={props.logData}
        trackWidth={props.trackWidth}
        zIndex={props.releaseTracksZIndex}
      />
    </>
  );
}
