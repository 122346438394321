import styled from "styled-components";
import { Select } from "@material-ui/core";

type Props = {};

export const Component = styled.section.attrs((props: Props) => ({}))``;

export const SelectFilterOptions = styled(Select).attrs((props: Props) => ({}))`
  min-width: 100px !important;
  align-self: center;
  margin-left: 10px;
  margin-top: 0px;
  border-radius: 0 !important;
  color: var(--third) !important;
  font-size: 40px !important;
  text-transform: lowercase;
  font-family: "GalanoGrotesque-Bold" !important;
  border-bottom: 1px solid #fff;

  > div {
    margin-top: 10px;
    padding: 0 !important;
    padding-right: 24px !important;
    font-size: 45px !important;
  }

  svg {
    top: 0;
    right: -30px;
    font-size: 70px;
    color: var(--third);
  }

  fieldset {
    border: none !important;
    border-bottom: 1px solid var(--white) !important;
  }

  @media screen and (max-width: 680px) {
    font-size: 34px !important;

    svg {
      top: -12px;
      right: -20px;
      font-size: 60px;
    }

    > div {
      margin-top: 0;
      font-size: 30px !important;
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 28px !important;
    margin-top: 10px;

    svg {
      top: -8px;
      right: -15px;
      font-size: 50px;
    }
  }
`;
