import React from "react";
import { Column, Content } from "./styles";

interface Props {
  xs?: any;
  sm?: any;
  md?: any;
  lg?: any;
  xl?: any;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export default (props: Props) => {
  return (
    <Column
      item
      style={props.style}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
    >
      <Content>{props.children}</Content>
    </Column>
  );
};
