import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import Icon from "../../BasicComponents/Icon";
import Text from "../../BasicComponents/Text";
import Link from "../../BasicComponents/Link";
import { Dropdown, Control, List, ListItem } from "./styles";

import ExpandMore from "@material-ui/icons/ExpandMore";
import { PopoverOrigin } from "@material-ui/core";
import Button from "../../Button";

interface Props<T> {
  disabled?: boolean;
  color?: "light";
  arrowColor?: string;
  label?: string;
  verticalPositioning?: number;
  appearance: string;
  iconType?: string;
  required?: boolean;
  listItems: Array<{
    value: T;
    link?: any;
    text: string;
  }>;
  /** Called with the item's "value" property. */
  onSelect?: (value: T) => void;
  // TODO: Check why the vertical value doesn't work
  // only the horizontal value works
  anchor?: PopoverOrigin;
}

export default function <T>(props: Props<T>) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  return (
    <>
      <Dropdown color={props.color}>
        <Control aria-controls="dropdown-list" aria-haspopup="true" onClick={handleClick}>
          {props.appearance === "img-icon" ? (
            <Icon type="expand_more" color={props.arrowColor}/>
          ) : props.appearance === "button-more" ? (
            <Button disabled={props.disabled}>
              <Text text={props.label} />
              <ExpandMore fontSize="small" />
            </Button>
          ) : (
            <Text text={props.label} />
          )}
        </Control>
        {!props.disabled && (<List
          style={{
            marginTop: props.verticalPositioning !== undefined ? props.verticalPositioning : 45,
          }}
          id="dropdown-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {props.listItems.length ? (
            props.listItems.map((item, index) => [
              <ListItem
                onClick={() => {
                  props.onSelect?.(item.value);
                  handleClose();
                }}
                key={index.toString()}
              >
                <Link
                  to="javascript:void(0);"
                  onClick={() => {
                    history.push(item.link);
                  }}
                >
                  {item.text}
                </Link>
              </ListItem>,
            ])
          ) : (
            <ListItem
              onClick={() => {
                handleClose();
              }}
            >
              <Link to="javascript:void(0);" onClick={() => {}}>
                {intl.formatMessage({ id: "report.noAvailable" })}
              </Link>
            </ListItem>
          )}
        </List>)}
      </Dropdown>
    </>
  );
}
