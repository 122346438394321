import React from "react";
import _ from "lodash";
import { WaypointDistanceLabel } from "../waypointDistances";
import distanceMarkerIcon from "./DistanceMarkerIcon";
import { MemoMarker } from "../MemoMapComponents";
import { OverlayView } from "@react-google-maps/api";
import { max } from "lodash";

type DistanceMarkerProps = {
  polygonId: string;
  distanceLegend: WaypointDistanceLabel;
  waypointIndex: number;
  yAnchor: number;
  markerZIndex: number;
  labelZIndex: number;
};

const _DistanceMarker = function (props: DistanceMarkerProps): JSX.Element {
  const { polygonId, distanceLegend, waypointIndex } = props;

  const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -(height / 2) - props.yAnchor,
  });

  const lines = props.distanceLegend.label.split("\n");
  const biggestLineLength = max(lines.map((line) => line.length))!;

  const opacity = 0.95;

  return (
    <>
      <MemoMarker
        key={`${polygonId}-distance-${waypointIndex}`}
        zIndex={props.markerZIndex}
        icon={distanceMarkerIcon(distanceLegend.label, props.yAnchor)}
        opacity={opacity}
        position={distanceLegend.location}
        clickable
        onClick={() => {}}
      />
      <OverlayView
        position={props.distanceLegend.location}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
        zIndex={props.labelZIndex}
      >
        <div
          style={{
            opacity: opacity,
            display: "flex",
            flexDirection: "column",
            color: "white",
            fontSize: "10px",
            fontWeight: 600,
            textAlign: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        >
          <>
            {lines.map((line) => (
              <div
                style={{
                  fontFamily: "Arial",
                }}
              >
                {line}
              </div>
            ))}
          </>
        </div>
      </OverlayView>
      ;
    </>
  );
};

/**
 * Memorization implementantion of the Distance Marker
 */
export const DistanceMarker = React.memo(_DistanceMarker, (prevProps, newProps) => {
  if (prevProps.polygonId !== newProps.polygonId) return false;
  if (prevProps.waypointIndex !== newProps.waypointIndex) return false;
  if (prevProps.yAnchor !== newProps.yAnchor) return false;
  if (!_.isEqual(prevProps.distanceLegend, newProps.distanceLegend)) return false;
  return true;
});

// these values were taken from the svg representation present in "icon_waypoint_up_border.svg"
type MapIcon = {
  svgPath: string;
  strokeColor: string;
  fillColor: string;
  opacity: number;
  scale?: number;
};

const polylineDistance: MapIcon = {
  svgPath: "",
  strokeColor: "#3FBFAD",
  fillColor: "#000000",
  opacity: 0.5,
  scale: 0.2,
};

const waypointHeightBackground: MapIcon = {
  svgPath:
    "M 150 100 h 300 a 30 20 0 0 1 30 20 v 50 a 30 20 0 0 1 -30 20 h -300 a 30 20 0 0 1 -30 -20 v -50 a 30 20 0 0 1 30 -20 z",
  strokeColor: "#3FBFAD",
  fillColor: "#000000",
  opacity: 0.5,
  scale: 0.2,
};
