import { WeatherInfo } from "biohub-model";
import { SystemAction } from "../actions/systemActions";
import { SET_WEATHER_DATA } from "../actions/weatherActions";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export type WeatherState = {
  /**
   * Weather data itself, including the location for which the data was searched.
   */
  weatherInfo: WeatherInfo | null;
  /**
   * Defines how the location for the current weather data was chosen.
   */
  sourceType:
    | "home-point"
    | "area-center"
    | "project-center"
    | "map-center"
    | "current-location"
    | "none";
};

const INITIAL_STATE: WeatherState = {
  weatherInfo: null,
  sourceType: "none",
};

// Reducer
export function weatherReducer(state = INITIAL_STATE, action: SystemAction): WeatherState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  switch (action.type) {
    case SET_WEATHER_DATA:
      return {
        weatherInfo: action.payload.weatherInfo,
        sourceType: action.payload.weatherSource,
      };
    default:
      return effectiveState;
  }
}

const weatherPersistConfig: PersistConfig<WeatherState> = {
  key: "weather",
  storage,
  whitelist: [],
};

export const weatherPersistedReducer = persistReducer(weatherPersistConfig, weatherReducer);
