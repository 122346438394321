import {
  Area,
  Cpu,
  CpuModel,
  DeviceModel,
  Drone,
  DroneModel,
  Input,
  Project,
  Releaser,
  ReleaserModel,
  Flight,
} from "biohub-model";
import { BiohubError, BiohubResponse, newBiohubSuccess } from "../../services/axios/BiohubApi";
import CacheDataService, { CachedDataRequestResult } from "../../services/CacheDataService";
import { CacheDataState, CacheDataType } from "../reducers/cacheDataReducer";
import { SystemThunk } from "../systemThunk";
import { Dispatch } from "./systemActions";
import naturalCompare from "natural-compare";
import { removeArrayDuplicates } from "../../core/utils";

export const SET_INITIALIZED = "SET_INITIALIZED";
export const SET_CACHE_PROJECTS = "SET_CACHE_PROJECTS";
export const SET_CACHE_AREAS = "SET_CACHE_AREAS";
export const SET_CACHE_CPUS = "SET_CACHE_CPUS";
export const SET_CACHE_CPU_MODELS = "SET_CACHE_CPU_MODELS";
export const SET_CACHE_DEVICE_MODELS = "SET_CACHE_DEVICE_MODELS";
export const SET_CACHE_DRONE_MODELS = "SET_CACHE_DRONE_MODELS";
export const SET_CACHE_INPUTS = "SET_CACHE_INPUTS";
export const SET_CACHE_DRONES = "SET_CACHE_DRONES";
export const SET_CACHE_FLIGHTS = "SET_CACHE_FLIGHTS";
export const SET_CACHE_PROJECT_FLIGHTS = "SET_CACHE_PROJECT_FLIGHTS";
export const SET_CACHE_RELEASERS = "SET_CACHE_RELEASERS";
export const SET_CACHE_RELEASER_MODELS = "SET_CACHE_RELEASER_MODELS";

export type CacheDataActions =
  | {
      type: typeof SET_INITIALIZED;
    }
  | {
      type: typeof SET_CACHE_PROJECTS;
      payload: CacheDataType<Project>;
    }
  | {
      type: typeof SET_CACHE_AREAS;
      payload: CacheDataType<Area> & { projectId: string };
    }
  | {
      type: typeof SET_CACHE_CPUS;
      payload: CacheDataType<Cpu>;
    }
  | {
      type: typeof SET_CACHE_CPU_MODELS;
      payload: CacheDataType<CpuModel>;
    }
  | {
      type: typeof SET_CACHE_DEVICE_MODELS;
      payload: CacheDataType<DeviceModel>;
    }
  | {
      type: typeof SET_CACHE_DRONE_MODELS;
      payload: CacheDataType<DroneModel>;
    }
  | {
      type: typeof SET_CACHE_INPUTS;
      payload: CacheDataType<Input>;
    }
  | {
      type: typeof SET_CACHE_DRONES;
      payload: CacheDataType<Drone>;
    }
  | {
      type: typeof SET_CACHE_RELEASERS;
      payload: CacheDataType<Releaser>;
    }
  | {
      type: typeof SET_CACHE_RELEASER_MODELS;
      payload: CacheDataType<ReleaserModel>;
    }
  | {
      type: typeof SET_CACHE_FLIGHTS;
      payload: CacheDataType<Flight> & { areaId: string; projectId: string };
    }
  | {
      type: typeof SET_CACHE_PROJECT_FLIGHTS;
      payload: CacheDataType<Flight> & { projectId: string };
    };

export function getProjects(dispatch: Dispatch): Promise<{
  projects?: Project[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<Project>(
        (state) => state.projects,
        (updatedData) => ({ type: SET_CACHE_PROJECTS, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getProjects("all", lastTimeReference),
        (project) => project.id,
        (projects, error) =>
          resolve({
            projects: sortProjectAndAreaListUsingNames(projects),
            error: error,
          })
      )
    );
  });
}

type GenericTypeWithName<T> = T & { name: string };
function sortProjectAndAreaListUsingNames<T>(
  list: GenericTypeWithName<T>[] | undefined
): T[] | undefined {
  if (list === undefined) return undefined;
  return list.sort((a, b) => naturalCompare(a.name.toLowerCase(), b.name.toLowerCase()));
}

function getDataUsingCache<T>(
  getStateData: (state: CacheDataState) => CacheDataType<T> | undefined,
  updateStateDataAction: (updatedData: CacheDataType<T>) => CacheDataActions,
  getChangedDataService: (
    lastTimeReference: Date | undefined
  ) => Promise<BiohubResponse<CachedDataRequestResult<T>>>,
  getDataId: (data: T) => string,
  processResult: (data: T[] | undefined, error: BiohubError | undefined) => void
): SystemThunk {
  return async (dispatch, getState) => {
    while (!getState().cache.initialized) {
      await delay(100);
    }

    const actualData = getStateData(getState().cache);
    const actualList = actualData?.data;

    const serviceResult = await getChangedDataService(actualData?.syncedAt);

    if (!serviceResult.success) {
      processResult(actualList, serviceResult.error);
    } else {
      const dataResult = serviceResult.data;

      const createdOrUpdatedData = dataResult.data;
      const remainingIdIfThereWasARemotion = dataResult.remainingIdIfThereWasARemotion;

      let mergedElementsMap: { [id: string]: T } = {};
      if (actualList !== undefined) {
        for (const element of actualList) {
          mergedElementsMap[getDataId(element)] = element;
        }
      }

      for (const element of createdOrUpdatedData) {
        mergedElementsMap[getDataId(element)] = element;
      }

      let newDataList = Object.keys(mergedElementsMap).map((key) => mergedElementsMap[key]);

      if (remainingIdIfThereWasARemotion !== undefined) {
        newDataList = newDataList.filter((data) =>
          remainingIdIfThereWasARemotion.includes(getDataId(data))
        );
      }

      dispatch(
        updateStateDataAction({
          data: newDataList,
          syncedAt: dataResult.actualTimeReference,
        })
      );

      processResult(newDataList, undefined);
    }
  };
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getProjectAreas(
  dispatch: Dispatch,
  projectId: string
): Promise<{
  areas?: Area[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<Area>(
        (state) => {
          const stateAreas = state.areas;
          if (stateAreas === undefined) return undefined;

          return stateAreas[projectId];
        },
        (updatedData) => ({
          type: SET_CACHE_AREAS,
          payload: {
            ...updatedData,
            projectId: projectId,
          },
        }),
        (lastTimeReference) =>
          CacheDataService.getProjectAreas(projectId, "all", lastTimeReference),
        (area) => area.id,
        (areas, error) =>
          resolve({
            areas: sortProjectAndAreaListUsingNames(areas),
            error: error,
          })
      )
    );
  });
}

export function getAreaFlights(
  dispatch: Dispatch,
  areaId: string,
  projectId: string
): Promise<{
  flights?: Flight[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<Flight>(
        (state) => {
          const projectsFlights = state.flights;
          if (projectsFlights === undefined) return undefined;

          const projectFlights = projectsFlights[projectId];
          if (projectFlights === undefined) return undefined;

          return projectFlights[areaId];
        },
        (updatedData) => ({
          type: SET_CACHE_FLIGHTS,
          payload: {
            ...updatedData,
            projectId: projectId,
            areaId: areaId,
          },
        }),
        (lastTimeReference) =>
          CacheDataService.getFlights(undefined, areaId, "all", lastTimeReference),
        (flight) => flight.id,
        (flights, error) => {
          resolve({
            flights: flights?.sort((a, b) =>
              a.startedAt.getTime() > b.startedAt.getTime() ? 1 : -1
            ),
            error: error,
          });
        }
      )
    );
  });
}

export function getProjectFlights(
  dispatch: Dispatch,
  projectId: string
): Promise<{
  flights?: Flight[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<Flight>(
        (state) => {
          const projectsFlights = state.flights;
          if (projectsFlights === undefined) return undefined;

          const projectFlights = projectsFlights[projectId];
          if (projectFlights === undefined) return undefined;

          return projectFlights[projectId];
        },
        (updatedData) => ({
          type: SET_CACHE_PROJECT_FLIGHTS,
          payload: {
            ...updatedData,
            projectId: projectId,
          },
        }),
        (lastTimeReference) =>
          CacheDataService.getFlights(projectId, undefined, "all", lastTimeReference),
        (flight) => flight.id,
        (flights, error) => {
          resolve({
            flights: flights?.sort((a, b) =>
              a.startedAt.getTime() > b.startedAt.getTime() ? 1 : -1
            ),
            error: error,
          });
        }
      )
    );
  });
}

export function getCpus(
  dispatch: Dispatch,
  directClientId: string | undefined
): Promise<{
  cpus?: Cpu[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<Cpu>(
        (state) => state.cpus,
        (updatedData) => ({ type: SET_CACHE_CPUS, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getCpus(directClientId, lastTimeReference),
        (cpu) => cpu.id,
        (cpus, error) =>
          resolve({
            cpus: cpus,
            error: error,
          })
      )
    );
  });
}

export function getCpuModels(dispatch: Dispatch): Promise<{
  cpuModels?: CpuModel[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<CpuModel>(
        (state) => state.cpuModels,
        (updatedData) => ({ type: SET_CACHE_CPU_MODELS, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getCpuModels(lastTimeReference),
        (cpuModel) => cpuModel.id,
        (cpuModels, error) =>
          resolve({
            cpuModels: cpuModels,
            error: error,
          })
      )
    );
  });
}

export function getDeviceModels(dispatch: Dispatch): Promise<{
  deviceModels?: DeviceModel[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<DeviceModel>(
        (state) => state.deviceModels,
        (updatedData) => ({ type: SET_CACHE_DEVICE_MODELS, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getDeviceModels(lastTimeReference),
        (deviceModel) => deviceModel.id,
        (deviceModels, error) =>
          resolve({
            deviceModels: deviceModels,
            error: error,
          })
      )
    );
  });
}

export function getDroneModels(dispatch: Dispatch): Promise<{
  droneModels?: DroneModel[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<DroneModel>(
        (state) => state.droneModels,
        (updatedData) => ({ type: SET_CACHE_DRONE_MODELS, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getDroneModels(lastTimeReference),
        (droneModel) => droneModel.id,
        (droneModels, error) =>
          resolve({
            droneModels: droneModels,
            error: error,
          })
      )
    );
  });
}

export function getInputs(dispatch: Dispatch): Promise<{
  inputs?: Input[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<Input>(
        (state) => state.inputs,
        (updatedData) => ({ type: SET_CACHE_INPUTS, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getInputs(lastTimeReference),
        (input) => input.id,
        (inputs, error) =>
          resolve({
            inputs: inputs,
            error: error,
          })
      )
    );
  });
}

export function getDrones(
  dispatch: Dispatch,
  directClientId: string | undefined
): Promise<{
  drones?: Drone[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<Drone>(
        (state) => state.drones,
        (updatedData) => ({ type: SET_CACHE_DRONES, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getDrones(directClientId, lastTimeReference),
        (drone) => drone.id,
        (drones, error) =>
          resolve({
            drones: drones,
            error: error,
          })
      )
    );
  });
}

export function getReleasers(
  dispatch: Dispatch,
  directClientId: string | undefined
): Promise<{
  releasers?: Releaser[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<Releaser>(
        (state) => state.releasers,
        (updatedData) => ({ type: SET_CACHE_RELEASERS, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getReleasers(directClientId, lastTimeReference),
        (releaser) => releaser.id,
        (releasers, error) =>
          resolve({
            releasers: releasers,
            error: error,
          })
      )
    );
  });
}

export function getReleaserModels(dispatch: Dispatch): Promise<{
  releaserModels?: ReleaserModel[];
  error: BiohubError | undefined;
}> {
  return new Promise((resolve, _) => {
    dispatch(
      getDataUsingCache<ReleaserModel>(
        (state) => state.releaserModels,
        (updatedData) => ({ type: SET_CACHE_RELEASER_MODELS, payload: updatedData }),
        (lastTimeReference) => CacheDataService.getReleaserModels(lastTimeReference),
        (releaserModel) => releaserModel.id,
        (releaserModels, error) =>
          resolve({
            releaserModels: releaserModels,
            error: error,
          })
      )
    );
  });
}
