import styled from "styled-components";

type Props = {
  selected?: boolean;
  lastChild?: boolean;
  color?: "light" | "primary" | "secondary" | "highlighted" | "dark";
  size?: "small" | "medium" | "large" | "x-large";
  textAlign?: "center";
  appearance?:
    | "title"
    | "section-title"
    | "info-title"
    | "description"
    | "description-block"
    | "info-subtitle"
    | "carousel-title"
    | "carousel-subtitle"
    | "description-banner"
    | "menu-title"
    | "price"
    | "info-name"
    | "info-value"
    | "info-description"
    | "block-title"
    | "small-title"
    | "small-description"
    | "details-description";
  transform?: "lowercase" | "uppercase" | "capitalize";
};

export const Accordion = styled.div.attrs((props: Props) => ({
  className: "box",
}))`
  display: flex;
  border: 1px solid var(--primary);
  border-radius: 20px;
  flex-direction: column;
  column-gap: 1rem;

  > * {
    &:first-child {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
  }

  > * {
    &:last-child {
      border-bottom-right-radius: 18px;
      border-bottom-left-radius: 18px;
    }
  }

  > * {
    &:not(:last-child) {
      border-bottom: 1px solid var(--primary);
    }
  }
`;

export const AccordionItem = styled.div.attrs((props: Props) => ({
  className: "box",
  selected: props.selected,
}))`
  transition: all 0.5s ease-out;

  ${(props: Props) =>
    props.selected === true &&
    `
    background-color: var(--primary);
  `}

  ${(props: Props) =>
    props.selected === false &&
    `
    background-color: var(--background-default);
  `}
`;

export const AccordionSummary = styled.div.attrs((props: Props) => ({
  className: "box",
  lastChild: props.lastChild,
  selected: props.selected,
}))`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  ${(props: Props) =>
    props.selected === false &&
    `
    color: var(--primary);
  `}

  ${(props: Props) =>
    props.selected === true &&
    `
    color: white;
  `}
`;

export const AccordionDetail = styled.div.attrs((props: Props) => ({
  className: "box",
}))`
  background-color: white;
  padding: 10px;

  ${(props: Props) =>
    props.selected === false &&
    `
    display: none;
  `}

  ${(props: Props) =>
    props.selected === true &&
    `
    display: flex;
  `}

  ${(props: Props) =>
    props.lastChild === true &&
    props.selected === true &&
    `
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
  `}
`;

export const Icon = styled.div.attrs((props: Props) => ({}))`
  padding: 10px 15px;
`;

export const Text = styled.label.attrs((props: Props) => ({
  color: props.color,
  size: props.size,
  appearance: props.appearance,
  textAlign: props.textAlign,
  transform: props.transform,
}))`
  display: block;
  white-space: pre-line;
  padding: 8px;
  cursor: pointer;
  font-weight: bold;

  ::selection {
    color: var(--primary);
    background: var(--secondary);
  }

  ${(props: Props) =>
    props.color === "light" &&
    `
    color: var(--white);
    border-color: var(--white);
  `}

  ${(props: Props) =>
    props.color === "primary" &&
    `
    color: var(--primary);
  `}

  ${(props: Props) =>
    props.color === "secondary" &&
    `
    color: var(--secondary);
  `}

  ${(props: Props) =>
    props.color === "highlighted" &&
    `
    color: var(--highlighted);
  `}

  ${(props: Props) =>
    props.appearance === "title" &&
    `
    font-family: "GalanoGrotesque-SemiBold";
    line-height: 1.1;
  `}

  ${(props: Props) =>
    props.appearance === "section-title" &&
    `
    line-height: 1;
    font-size: 32px;
    font-family: "GalanoGrotesque-Medium";
    letter-spacing: 1px;
  `}

  ${(props: Props) =>
    props.appearance === "info-title" &&
    `
    line-height: 1;
    font-size: 40px;
    font-family: "GalanoGrotesque-Medium";

    @media screen and (max-width: 680px) {
      font-size: 34px;
    } 

    @media screen and (max-width: 480px) {
      font-size: 28px;
    }
  `}

  ${(props: Props) =>
    props.appearance === "description" &&
    `
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 80px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  `}
  
  ${(props: Props) =>
    props.appearance === "description-block" &&
    `
    font-size: 14px;
    margin: 10px 0 5px;
    color: var(--tag-text-color);
    `}
  
      
  ${(props: Props) =>
    props.appearance === "description-block" &&
    props.size === "small" &&
    `
      font-size: 12px!important;
  `}

  ${(props: Props) =>
    props.appearance === "info-subtitle" &&
    `
    line-height: 1.2;
    font-size: 22px;
    margin: 2rem 0;
    font-family: "GalanoGrotesque-Medium";

    @media screen and (max-width: 480px) {
      margin: 0 0 15px;
    }
  `}
  
  ${(props: Props) =>
    props.appearance === "carousel-title" &&
    `
    text-align: center;
    font-family: "GalanoGrotesque-SemiBold"; 
    font-size: 42px;
    margin: 2rem 0 0;
  `}
  
  ${(props: Props) =>
    props.appearance === "carousel-subtitle" &&
    `
    text-align: center;
    font-size: 22px;
    line-height: 1.2;
    padding: 1rem 20% 2rem;

    @media screen and (max-width: 680px) {
      padding: 1rem 5% 2rem;
    }
    
    @media screen and (max-width: 579px) {
      padding: 1rem 10% 0;
    } 

  `}
  
  ${(props: Props) =>
    props.appearance === "description-banner" &&
    `
    margin-top: 2rem;
    width: 90%;
    margin-bottom: 3rem;

    @media screen and (max-width: 580px) {
      font-size: 13px;
      margin-bottom: 0;
    }
  `}
  
  ${(props: Props) =>
    props.appearance === "menu-title" &&
    `
    font-size: 24px;
    font-family: "GalanoGrotesque-Medium";
  `}
  
  ${(props: Props) =>
    props.appearance === "price" &&
    `
    font-size: 24px;
    font-family: "GalanoGrotesque-Medium";
    color: var(--text-primary);
  `}
  
  ${(props: Props) =>
    props.appearance === "info-name" &&
    `
    font-family: "GalanoGrotesque-SemiBold";
    color: var(--text-primary);
  `}
  
  ${(props: Props) =>
    props.appearance === "info-value" &&
    `
    font-size: 48px;
    line-height: 1.2;
    font-family: 'GalanoGrotesque-Semibold';
    color: var(--dashboard-primary-bg);

    @media screen and (max-width: 1600px) {
      font-size: 40px;
    }
  `}

  ${(props: Props) =>
    props.appearance === "info-description" &&
    `
      font-size: 14px;
      font-family: 'Galano Grotesque';
      color: var(--dashboard-text-default);
  `}

  ${(props: Props) =>
    props.appearance === "details-description" &&
    `
      font-size: 14px;
      font-family: 'GalanoGrotesque-Medium';
      color: var(--dashboard-text-default);
      margin: 2rem 0;
  `}

  ${(props: Props) =>
    props.appearance === "block-title" &&
    `
      font-family: 'GalanoGrotesque-Medium';
      font-size: 24px;
  `}

  ${(props: Props) =>
    props.appearance === "small-title" &&
    `
      font-family: 'GalanoGrotesque-Semibold';
      margin-top: 1.5rem;
  `}

  ${(props: Props) =>
    props.appearance === "small-description" &&
    `
      font-size: 14px;
      font-family: 'Galano Grotesque';
  `}

  ${(props: Props) =>
    props.size === "small" &&
    `
    font-size: 11px;
  `}

  ${(props: Props) =>
    props.size === "medium" &&
    `
    font-size: 20px;
    line-height: 1.2;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  `}

  ${(props: Props) =>
    props.size === "large" &&
    `
    font-size: 42px;

    @media screen and (max-width: 1440px) {
      font-size: 32px;
    }

    @media screen and (max-width: 1280px) {
      font-size: 24px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }

    @media screen and (max-width: 991px) {
      font-size: 30px;
    }

    @media screen and (max-width: 768px) {
      font-size: 28px;
    }

    @media screen and (max-width: 680px) {
      line-height: 1.1;
      font-size: 24px;
    }

    @media screen and (max-width: 480px) {
      font-size: 22px;
    }

    @media screen and (max-width: 420px) {
      font-size: 20px;
    }

    @media screen and (max-width: 380px) {
      font-size: 18px;
    }

    @media screen and (max-width: 300px) {
      font-size: 16px;
    }

  `}

  ${(props: Props) =>
    props.size === "x-large" &&
    `
    line-height: 0.75;
    font-size: 58px;

    @media screen and (max-width: 580px) {
      font-size: 44px;
    }
  `}

  ${(props: Props) =>
    props.textAlign === "center" &&
    `
    text-align: center;
  `}

  ${(props: Props) =>
    props.transform &&
    `
    text-transform: ${props.transform};
  `}
`;
