import styled from "styled-components";

//List
export const TableList = styled.div.attrs((props: {}) => ({}))`
  margin-bottom: 1rem;

  .MuiPaper-root {
    background: var(--card-background);
  }

  .table-header {
    background: var(--dashboard-table-header);
    border-radius: 4px 4px 0 0;
    font-family: "GalanoGrotesque-Semibold";
    text-transform: uppercase;
    letter-spacing: 0.35px;
    justify-content: space-between;

    button {
      padding: 0 10px;
      border-radius: 50px;
      color: var(--dashboard-menu-bg);
      padding-bottom: 5px;
      font-family: "GalanoGrotesque-SemiBold";
      font-weight: 600;
      margin-right: -20px;

      .material-icons {
        font-size: 20px;
        margin-top: 3px;
        margin-left: 3px;
      }
    }
  }

  svg,
  .material-icons {
    color: var(--dashboard-primary-bg);
    font-size: 22px;
    vertical-align: middle;
  }
`;
