import { Location, Waypoint } from "biohub-model";
import React from "react";
import WaypointDropMarker from "./markers/WaypointDropMarker";
import WaypointOrientationMarker from "./markers/WaypointOrientationMarker";
import WaypointHeightMarker from "./markers/WaypointHeightMarker";

type Props = {
  polygonId: string;
  waypointIndex: number;
  waypoint: Waypoint;
  isSelected: boolean;
  hasReleaserAction: boolean;
  isActiveLiberation: boolean;
  homePointElevation: number;
  elevation?: number;
  waypointScale?: number;
  showDistanceLabels?: boolean;
  formatHeight: (height: number) => string;
  editing: boolean;
  onClick: () => void;
  onMoved: (location: Location) => void;
  usingOnlineElevation: boolean;
  orientationMarkerZIndex: number;
  dropMarkerZIndex: number;
  elevationMarkerZIndex: number;
  elevationLabelZIndex: number;
};

export default function (props: Props): JSX.Element {
  if (props.waypointScale === 0) return <></>;
  return (
    <>
      {!props.editing && (
        <WaypointOrientationMarker
          polygonId={props.polygonId}
          waypointScale={props.waypointScale}
          location={props.waypoint.location}
          orientation={props.waypoint.orientation}
          waypointIndex={props.waypointIndex}
          onClick={props.onClick}
          zIndex={props.orientationMarkerZIndex}
        />
      )}
      <WaypointDropMarker
        polygonId={props.polygonId}
        draggable={props.editing}
        waypointScale={props.waypointScale}
        showDistanceLabels={props.showDistanceLabels}
        isActiveLiberation={props.isActiveLiberation}
        isSelected={props.isSelected}
        hasReleaserAction={props.hasReleaserAction}
        location={props.waypoint.location}
        waypointIndex={props.waypointIndex}
        onClick={props.onClick}
        onDragEnd={props.onMoved}
        zIndex={props.dropMarkerZIndex}
      />
      <WaypointHeightMarker
        polygonId={props.polygonId}
        waypointIndex={props.waypointIndex}
        location={props.waypoint.location}
        showDistanceLabel={props.showDistanceLabels ?? true}
        waypointHeight={props.waypoint.height}
        formatHeight={props.formatHeight}
        usingOnlineElevation={props.usingOnlineElevation}
        homePointElevation={props.homePointElevation}
        elevation={props.elevation}
        markerZIndex={props.elevationMarkerZIndex}
        labelZIndex={props.elevationLabelZIndex}
      />
    </>
  );
}
