import { Collapse } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React from "react";
import OverlayContainer from "../OverlayContainer";

type Props = {
  collapsedComponent: React.ReactNode;
  expandedComponent: React.ReactNode;
  style?: React.CSSProperties;

  expanded: boolean;
  setExpanded: (value: boolean) => void;
};

/**
 * Like an OverlayContainer, but collapsible horizontally.
 * For the moment, this only supports expanding to the left.
 */
export default function CollapsibleContainer(props: Props): JSX.Element {
  // const [expanded, setExpanded] = React.useState(false);
  const { expanded, setExpanded } = props;

  return (
    <OverlayContainer style={props.style}>
      <div
        style={{
          display: "flex",
          flex: "1, 1, content",
          minWidth: 0,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Chevron
          expanded={expanded}
          onOpened={() => setExpanded(true)}
          onClosed={() => setExpanded(false)}
        />
        {/**
         * Currently, the Collapse component doesn't behave correctly and keeps
         * occupying space even when collapsed. Horizontal collapse is only
         * supported in version 5 of the material ui lib, so when we update,
         * remove this first conditional and adjust the Collapse.
         */}
        {expanded && (
          <div
            style={{
              display: "flex",
              flex: "1, 1, content",
              minWidth: 0,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {props.expandedComponent}
          </div>
        )}
        {props.collapsedComponent}
      </div>
    </OverlayContainer>
  );
}

type ChevronProps = {
  expanded: boolean;
  onClosed: () => void;
  onOpened: () => void;
};

function Chevron(props: ChevronProps) {
  const IconComponent = props.expanded ? ChevronRight : ChevronLeft;
  return (
    <IconComponent
      style={{
        display: "flex",
        flex: "1, 1, content",
        pointerEvents: "auto",
        color: "var(--black)",
        transform: "scaleY(1.8)",
        transformOrigin: "center",
      }}
      onClick={() => {
        if (props.expanded) {
          props.onClosed();
        } else {
          props.onOpened();
        }
      }}
    />
  );
}
