import React from "react";
import { useIntl } from "react-intl";
import { InstantaneousReleaseRateLabel } from "../styles";
import UnitConversionHelper from "../../../../../core/helper/UnitConversionHelper";
import { ReleaseRateUnit, Role, UnitSystem } from "biohub-model";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";

type Props = {
  flightSpeed: number;
  trackWidth: number;
  releaseRate: number;
  releaseRateUnit: ReleaseRateUnit;
  color?: "light";
};

export default (props: Props): JSX.Element => {
  const intl = useIntl();
  const termInstantaneousReleaseRate = intl.formatMessage({
    id: "map.newProject.instantaneousReleaseRate",
  });

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  return (
    <InstantaneousReleaseRateLabel color="black">
      {termInstantaneousReleaseRate}
      {": "}
      {UnitConversionHelper.instReleaseRateValue(
        props.flightSpeed,
        props.trackWidth,
        props.releaseRate,
        props.releaseRateUnit,
        unitSystem,
        1
      )}
      {" " + UnitConversionHelper.instReleaseRateUnit(props.releaseRateUnit, unitSystem)}
    </InstantaneousReleaseRateLabel>
  );
};
