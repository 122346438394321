import { AreaConfig, BoundingBox, Location, Role, UnitSystem, Waypoint } from "biohub-model";
import _ from "lodash";
import React from "react";
import { MemoMarker } from "../../../components/map/impl/components/core/MemoMapComponents";
import { centerOfBoundingBox } from "../../../core/geometricFunctions";
import { Clusterer } from "@react-google-maps/api/node_modules/@react-google-maps/marker-clusterer";

type Props = {
  size: number;
  boundingBox: BoundingBox | null;
  areasCounter?: number;
  cluster: Clusterer;
  zIndex: number;
};

export default function (props: Props): JSX.Element {
  const markerIconUrl = "marker_cluster_red";
  if (props.boundingBox === null) return <></>;
  const center = centerOfBoundingBox(props.boundingBox);
  return (
    <MemoMarker
      zIndex={props.zIndex}
      icon={{
        url: `/${markerIconUrl}.png`,
        scaledSize: new google.maps.Size(props.size, props.size),
        anchor: new google.maps.Point(props.size / 2, props.size / 2),
        labelOrigin: new google.maps.Point(props.size / 2, props.size / 2),
      }}
      label={
        props.areasCounter !== undefined
          ? {
              color: "black",
              fontSize: "10px",
              fontWeight: "500",
              text: `${props.areasCounter}`,
            }
          : undefined
      }
      position={center}
      clusterer={props.cluster}
    />
  );
}
