import React from "react";
import Icon from "../../BasicComponents/Icon";
import { List, Item } from "./styles";
interface Props {
  socialItems: Array<{
    type: string | "facebook" | "instagram" | "twitter" | "linkedin";
    link: string | "url";
  }>;
}

export default (props: Props) => {
  return (
    <>
      <List>
        {/* creates list of options with values ​​received from the props */}
        {props.socialItems.map((item) => (
          <Item href={item.link} target="_blank" key={item.type}>
            <Icon type={item.type} />
          </Item>
        ))}
      </List>
    </>
  );
};
