import React from "react";
import { useIntl } from "react-intl";
import Text from "../../BasicComponents/Text";
import Image from "../../BasicComponents/Image";
import { About } from "./styles";

import imageAbout from "../../../../assets/logo/icon_blue.svg";

interface Props {
  image?: boolean;
  description: string;
}

export default (props: Props) => {
  const intl = useIntl();
  return (
    <>
      <About>
        {props.image === true && (
          <Image
            src={imageAbout}
            alt={intl.formatMessage({ id: "info.slogan" })}
            appearance={"img-logo"}
          />
        )}
        <Text text={props.description} />
      </About>
    </>
  );
};
