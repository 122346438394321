/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../BasicComponents/Button";
import Text from "../../../BasicComponents/Text";
import Image from "../../../BasicComponents/Image";
import Clickable from "../../../BasicComponents/Link";

import { maskZipcode } from "../../../../../services/FormMasksService";
import { isValidFormField } from "../../../../../services/FormValidatorsService";
import { searchZipCode } from "../../../../../services/SearchPlaces";
import { Country } from "../../../../../services/NationalityService";

import { addExtraAddress, clearExtraAddress } from "../../../../../store/actions/cartActions";
import { SystemState } from "../../../../../store/reducers/systemReducer";

import { Actions, InfoAddress, InputContainer, Modal } from "../styles";

// Images
import iconSearchZipcode from "../../../../../assets/icon/icon_search_zipcode.svg";

type Props = {
  countries: Country[];
  locale: string;
};

export default (props: Props) => {
  const intl = useIntl();

  const termActionAdd = intl.formatMessage({ id: "action.add" });
  const termActionEdit = intl.formatMessage({ id: "action.edit" });
  const termActionClean = intl.formatMessage({ id: "action.clean" });
  const termActionCancel = intl.formatMessage({ id: "action.cancel" });
  const termTitleBillingAddress = intl.formatMessage({ id: "cart.title.billing.address" });
  const termTitleShippingAddress = intl.formatMessage({ id: "cart.title.shipping.address" });
  const termTitleModalAddBillingAddress = intl.formatMessage({
    id: "cart.title.modal.billing.address",
  });
  const termTitleModalAddShippingAddress = intl.formatMessage({
    id: "cart.title.modal.shipping.address",
  });
  const termLabelDirection = intl.formatMessage({ id: "label.direction" });
  const termLabelSelectCountry = intl.formatMessage({ id: "label.select.country" });
  const termPlaceholderNameStreet = intl.formatMessage({ id: "placeholder.name.street" });
  const termPlaceholderStreetExamples = intl.formatMessage({ id: "placeholder.street.examples" });
  const termPlaceholderCountry = intl.formatMessage({ id: "placeholder.country" });
  const termPlaceholderState = intl.formatMessage({ id: "placeholder.state.department" });
  const termPlaceholderCity = intl.formatMessage({ id: "placeholder.city" });
  const termPlaceholderZipcode = intl.formatMessage({ id: "placeholder.zip" });

  const dispatch = useDispatch();
  const extraAddress = useSelector((state: SystemState) => state.cart.extraAddress);

  const [openModal, setOpenModal] = React.useState(false);
  const [typeActionModal, setTypeActionModal] = React.useState("");
  const [typeModal, setTypeModal] = React.useState("");

  const [dataExtraAddress, setDataExtraAddress] = React.useState({
    chargeAddress: {
      zipcode: "",
      street: "",
      complement: "",
      country: "",
      state: "",
      city: "",
    },
    sendAddress: {
      zipcode: "",
      street: "",
      complement: "",
      country: "",
      state: "",
      city: "",
    },
  });
  const [errors, setErrors] = React.useState({
    chargeAddress: {
      zipcode: false,
      street: false,
      complement: false,
      country: false,
      state: false,
      city: false,
    },
    sendAddress: {
      zipcode: false,
      street: false,
      complement: false,
      country: false,
      state: false,
      city: false,
    },
  });

  const handleOpenModal = (
    type?: "chargeAddress" | "sendAddress",
    typeAction?: "addAddress" | "editAddress"
  ) => {
    if (typeAction && type) {
      if (typeAction === "addAddress" && type === "chargeAddress") {
        setTypeActionModal(typeAction);
        setTypeModal(type);
      } else {
        setTypeActionModal(typeAction);
        setTypeModal(type);
        setDataExtraAddress({
          chargeAddress: {
            zipcode:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.zipcode
                : extraAddress.sendAddress.zipcode,
            street:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.street
                : extraAddress.sendAddress.street,
            complement:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.complement
                : extraAddress.sendAddress.complement,
            country:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.country
                : extraAddress.sendAddress.country,
            state:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.state
                : extraAddress.sendAddress.state,
            city:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.city
                : extraAddress.sendAddress.city,
          },
          sendAddress: {
            zipcode:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.zipcode
                : extraAddress.sendAddress.zipcode,
            street:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.street
                : extraAddress.sendAddress.street,
            complement:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.complement
                : extraAddress.sendAddress.complement,
            country:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.country
                : extraAddress.sendAddress.country,
            state:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.state
                : extraAddress.sendAddress.state,
            city:
              typeAction === "editAddress" && type === "chargeAddress"
                ? extraAddress.chargeAddress.city
                : extraAddress.sendAddress.city,
          },
        });
      }
    }
    setOpenModal(!openModal);
  };

  const handleOnChange = (e: React.ChangeEvent<any>, type: "chargeAddress" | "sendAddress") => {
    if (type === "chargeAddress") {
      setDataExtraAddress({
        ...dataExtraAddress,
        chargeAddress: {
          ...dataExtraAddress.chargeAddress,
          [e.target.name]: e.target.value,
        },
      });
      setErrors({
        ...errors,
        chargeAddress: {
          ...errors.chargeAddress,
          [e.target.name]: isValidFormField(e.target.name, e.target.value),
        },
      });
    } else {
      setDataExtraAddress({
        ...dataExtraAddress,
        sendAddress: {
          ...dataExtraAddress.sendAddress,
          [e.target.name]: e.target.value,
        },
      });
      setErrors({
        ...errors,
        sendAddress: {
          ...errors.sendAddress,
          [e.target.name]: isValidFormField(e.target.name, e.target.value),
        },
      });
    }
  };

  const handleSearchZipCode = async () => {
    if (typeModal === "chargeAddress") {
      const result = await searchZipCode(dataExtraAddress.chargeAddress.zipcode);
      if (result.success) {
        setDataExtraAddress({
          ...dataExtraAddress,
          chargeAddress: {
            ...dataExtraAddress.chargeAddress,
            street: result.data.address,
            city: result.data.city,
            country: result.data.country,
            state: result.data.state,
            complement: result.data.district,
          },
        });
        setErrors({
          ...errors,
          chargeAddress: {
            ...errors.chargeAddress,
            street: isValidFormField("street", result.data.address),
            city: isValidFormField("city", result.data.city),
            country: isValidFormField("country", result.data.country),
            state: isValidFormField("state", result.data.state),
            complement: isValidFormField("complement", result.data.district),
          },
        });
      }
    } else {
      const result = await searchZipCode(dataExtraAddress.sendAddress.zipcode);
      if (result.success) {
        setDataExtraAddress({
          ...dataExtraAddress,
          sendAddress: {
            ...dataExtraAddress.sendAddress,
            street: result.data.address,
            city: result.data.city,
            country: result.data.country,
            state: result.data.state,
            complement: result.data.district,
          },
        });
        setErrors({
          ...errors,
          sendAddress: {
            ...errors.sendAddress,
            street: isValidFormField("street", result.data.address),
            city: isValidFormField("city", result.data.city),
            country: isValidFormField("country", result.data.country),
            state: isValidFormField("state", result.data.state),
            complement: isValidFormField("complement", result.data.district),
          },
        });
      }
    }
  };

  const handleVerifyErrors = () => {
    if (typeModal === "chargeAddress") {
      for (let [, value] of Object.entries(errors.chargeAddress)) {
        if (value === true) {
          return true;
        }
      }

      let errorStateChargeAddress = {};

      for (let [key, value] of Object.entries(dataExtraAddress.chargeAddress)) {
        if (value === "") {
          errorStateChargeAddress = {
            ...errorStateChargeAddress,
            [key]: true,
          };
        }
      }

      setErrors({
        ...errors,
        chargeAddress: {
          ...errors.chargeAddress,
          ...errorStateChargeAddress,
        },
      });

      for (let [, value] of Object.entries(errorStateChargeAddress)) {
        if (value === true) {
          return true;
        }
      }
    } else {
      for (let [, value] of Object.entries(errors.sendAddress)) {
        if (value === true) {
          return true;
        }
      }

      let errorStateSendAddress = {};

      for (let [key, value] of Object.entries(dataExtraAddress.sendAddress)) {
        if (value === "") {
          errorStateSendAddress = {
            ...errorStateSendAddress,
            [key]: true,
          };
        }
      }

      setErrors({
        ...errors,
        sendAddress: {
          ...errors.sendAddress,
          ...errorStateSendAddress,
        },
      });

      for (let [, value] of Object.entries(errorStateSendAddress)) {
        if (value === true) {
          return true;
        }
      }
    }
  };

  const handleClearExtraAddress = (type: "chargeAddress" | "sendAddress") => {
    dispatch(clearExtraAddress(type));
  };

  const handleSubmit = () => {
    const verifyErrors = handleVerifyErrors();
    if (verifyErrors) {
      return;
    }

    if (typeModal === "chargeAddress") {
      dispatch(addExtraAddress("chargeAddress", dataExtraAddress));
    } else {
      dispatch(addExtraAddress("sendAddress", dataExtraAddress));
    }
    setOpenModal(!openModal);
  };

  return (
    <Grid container className="info-address">
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Text text={termTitleBillingAddress} appearance={"info-semibold"} />
        <InfoAddress>
          {Object.values(extraAddress.chargeAddress).every((x) => x !== "") && (
            <>
              <Text text={extraAddress.chargeAddress.street} />
              <Text text={extraAddress.chargeAddress.complement} />
              <Text text={extraAddress.chargeAddress.country} />
              <Text text={extraAddress.chargeAddress.city} />
              <Text text={extraAddress.chargeAddress.zipcode} />
            </>
          )}
          <Actions>
            <Clickable
              onClick={() =>
                Object.values(extraAddress.chargeAddress).some((x) => x === null || x === "")
                  ? handleOpenModal("chargeAddress", "addAddress")
                  : handleOpenModal("chargeAddress", "editAddress")
              }
            >
              <Text
                text={
                  Object.values(extraAddress.chargeAddress).some((x) => x === null || x === "")
                    ? termActionAdd
                    : termActionEdit
                }
              />
            </Clickable>
            {Object.values(extraAddress.chargeAddress).every((x) => x !== "") && (
              <Clickable onClick={() => handleClearExtraAddress("chargeAddress")}>
                <Text text={termActionClean} />
              </Clickable>
            )}
          </Actions>
        </InfoAddress>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Text text={termTitleShippingAddress} appearance={"info-semibold"} />
        <InfoAddress>
          {Object.values(extraAddress.sendAddress).every((x) => x !== "") && (
            <>
              <Text text={extraAddress.sendAddress.street} />
              <Text text={extraAddress.sendAddress.complement} />
              <Text text={extraAddress.sendAddress.country} />
              <Text text={extraAddress.sendAddress.city} />
              <Text text={extraAddress.sendAddress.zipcode} />
            </>
          )}
          <Actions>
            <Clickable
              onClick={() =>
                Object.values(extraAddress.sendAddress).some((x) => x === null || x === "")
                  ? handleOpenModal("sendAddress", "addAddress")
                  : handleOpenModal("sendAddress", "editAddress")
              }
            >
              <Text
                text={
                  Object.values(extraAddress.sendAddress).some((x) => x === null || x === "")
                    ? termActionAdd
                    : termActionEdit
                }
              />
            </Clickable>
            {Object.values(extraAddress.sendAddress).every((x) => x !== "") && (
              <Clickable onClick={() => handleClearExtraAddress("sendAddress")}>
                <Text text={termActionClean} />
              </Clickable>
            )}
          </Actions>
        </InfoAddress>
      </Grid>
      <Modal open={openModal} onClose={() => handleOpenModal()}>
        <DialogContent>
          <Text
            text={
              typeModal === "chargeAddress"
                ? typeActionModal === "editAddress"
                  ? "Editar endereço de cobrança"
                  : termTitleModalAddBillingAddress
                : typeActionModal === "editAddress"
                ? "Editar endereço de envio"
                : termTitleModalAddShippingAddress
            }
            textAlign={"center"}
            appearance={"info-semibold"}
          />
          <Grid container>
            <InputContainer>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Text
                  text={termPlaceholderZipcode}
                  color={"primary"}
                  appearance={"info-semibold"}
                />
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <TextField
                  fullWidth
                  name="zipcode"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    typeModal === "chargeAddress"
                      ? handleOnChange(e, "chargeAddress")
                      : handleOnChange(e, "sendAddress")
                  }
                  onBlur={() => handleSearchZipCode()}
                  value={
                    typeModal === "chargeAddress"
                      ? maskZipcode(dataExtraAddress.chargeAddress.zipcode)
                      : maskZipcode(dataExtraAddress.sendAddress.zipcode)
                  }
                  error={
                    typeModal === "chargeAddress"
                      ? errors.chargeAddress.zipcode
                      : errors.sendAddress.zipcode
                  }
                />
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <Button action={() => handleSearchZipCode()}>
                  <Image src={iconSearchZipcode} alt={""} />
                </Button>
              </Grid>
            </InputContainer>
            <InputContainer>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Text text={termLabelDirection} color={"primary"} appearance={"info-semibold"} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={"companyForm-complement"}>
                <TextField
                  fullWidth
                  name="street"
                  placeholder={termPlaceholderNameStreet}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    typeModal === "chargeAddress"
                      ? handleOnChange(e, "chargeAddress")
                      : handleOnChange(e, "sendAddress")
                  }
                  value={
                    typeModal === "chargeAddress"
                      ? dataExtraAddress.chargeAddress.street
                      : dataExtraAddress.sendAddress.street
                  }
                  error={
                    typeModal === "chargeAddress"
                      ? errors.chargeAddress.street
                      : errors.sendAddress.street
                  }
                />
                <TextField
                  fullWidth
                  name="complement"
                  placeholder={termPlaceholderStreetExamples}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    typeModal === "chargeAddress"
                      ? handleOnChange(e, "chargeAddress")
                      : handleOnChange(e, "sendAddress")
                  }
                  value={
                    typeModal === "chargeAddress"
                      ? dataExtraAddress.chargeAddress.complement
                      : dataExtraAddress.sendAddress.complement
                  }
                  error={
                    typeModal === "chargeAddress"
                      ? errors.chargeAddress.complement
                      : errors.sendAddress.complement
                  }
                />
              </Grid>
            </InputContainer>
            <InputContainer>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="companyForm-select companyForm-spacing"
              >
                <Text
                  text={termPlaceholderCountry}
                  color={"primary"}
                  appearance={"info-semibold"}
                />
                <FormControl variant={"outlined"} fullWidth>
                  <Select
                    fullWidth
                    key={"country"}
                    name="country"
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                      typeModal === "chargeAddress"
                        ? handleOnChange(e, "chargeAddress")
                        : handleOnChange(e, "sendAddress")
                    }
                    value={
                      typeModal === "chargeAddress"
                        ? dataExtraAddress.chargeAddress.country === ""
                          ? "0"
                          : dataExtraAddress.chargeAddress.country
                        : dataExtraAddress.sendAddress.country === ""
                        ? "0"
                        : dataExtraAddress.sendAddress.country
                    }
                    error={
                      typeModal === "chargeAddress"
                        ? dataExtraAddress.chargeAddress.country === "0"
                          ? true
                          : errors.chargeAddress.country
                        : dataExtraAddress.sendAddress.country === "0"
                        ? true
                        : errors.sendAddress.country
                    }
                  >
                    <MenuItem value={"0"}>{termLabelSelectCountry}</MenuItem>
                    {props.countries.map((item) => (
                      <MenuItem value={item.countryName}>{item.countryName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="companyForm-select companyForm-spacing"
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text
                    text={termPlaceholderState}
                    color={"primary"}
                    appearance={"info-semibold"}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    name="state"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      typeModal === "chargeAddress"
                        ? handleOnChange(e, "chargeAddress")
                        : handleOnChange(e, "sendAddress")
                    }
                    value={
                      typeModal === "chargeAddress"
                        ? dataExtraAddress.chargeAddress.state
                        : dataExtraAddress.sendAddress.state
                    }
                    error={
                      typeModal === "chargeAddress"
                        ? errors.chargeAddress.state
                        : errors.sendAddress.state
                    }
                  />
                </Grid>
              </Grid>
            </InputContainer>
            <InputContainer>
              <Grid item lg={6} md={6} sm={6} xs={6} className="companyForm-spacing">
                <Text text={termPlaceholderCity} color={"primary"} appearance={"info-semibold"} />
                <TextField
                  fullWidth
                  name="city"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    typeModal === "chargeAddress"
                      ? handleOnChange(e, "chargeAddress")
                      : handleOnChange(e, "sendAddress")
                  }
                  value={
                    typeModal === "chargeAddress"
                      ? dataExtraAddress.chargeAddress.city
                      : dataExtraAddress.sendAddress.city
                  }
                  error={
                    typeModal === "chargeAddress"
                      ? errors.chargeAddress.city
                      : errors.sendAddress.city
                  }
                />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={6} xs={6} className="companyForm-spacing">
                <Text text={termLabelCodePostal} color={"primary"} appearance={"info-semibold"} />
                <TextField
                  fullWidth
                  name="postalCode"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    typeModal === "chargeAddress"
                      ? handleOnChange(e, "chargeAddress")
                      : handleOnChange(e, "sendAddress")
                  }
                  value={
                    typeModal === "chargeAddress"
                      ? dataExtraAddress.chargeAddress.postalCode
                      : dataExtraAddress.sendAddress.postalCode
                  }
                  error={
                    typeModal === "chargeAddress"
                      ? errors.chargeAddress.postalCode
                      : errors.sendAddress.postalCode
                  }
                />
              </Grid> */}
            </InputContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button text={termActionCancel} action={handleOpenModal} color={"primary"} />
          <Button
            text={typeActionModal === "editAddress" ? termActionEdit : termActionAdd}
            action={handleSubmit}
            color={"primary"}
          />
        </DialogActions>
      </Modal>
    </Grid>
  );
};
