import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../Inputs/Field";
import Checkbox from "../../Inputs/Checkbox";
import Text from "../../BasicComponents/Text";
import Button from "../../BasicComponents/Button";
import Image from "../../BasicComponents/Image";
import FlipCard from "../../BasicComponents/FlipCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import history from "../../../../navigation/history";

import { Form, Fields, Logo } from "./styles";

import imageError from "../../../../assets/icon/icon_warning.svg";
import imageSuccess from "../../../../assets/icon/icon_success.svg";
import imageDescription from "../../../../assets/logo/logo_white.png";

import {
  finishLoginProcedures,
  logIn,
  resetError,
  resetPassword,
} from "../../../../store/actions/loginActions";
import { SystemState } from "../../../../store/reducers/systemReducer";
import { useLocation } from "react-router";
import { Box, Icon, IconButton, InputAdornment } from "@material-ui/core";

export default () => {
  const intl = useIntl();
  const [checked, setChecked] = React.useState<boolean>(true); //if true = keep connected
  const [successMessage, setSuccessMessage] = useState("");
  // State (from history) is expected to have a "from" field, but only when a redirection occured
  // due to the user not having enough privileges to view a page.
  const location = useLocation<any>();
  const nextPage: string = location.state?.from || "dashboard";

  const [formData, setFormData] = React.useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });

  const loading = useSelector((state: SystemState) => {
    const loginState = state.login;
    if (loginState.isLogging) return true;
    if (loginState.isGettingUserInformation) return true;
    if (loginState.isResettingPassword) return true;

    return false;
  });

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  //variable to control the stage of the login box
  //1: First stage, when the user needs to entry your information
  //2: Second stage, when we show success password request message
  //3: Third stage, when we only show error messages.
  const [stage, setStage] = useState<1 | 2 | 3>(1);

  const loginError = useSelector((state: SystemState) => state.login.error);
  useEffect(() => {
    if (loginError !== null) {
      setStage(3);
    }
  }, [loginError]);
  const successfullyResettingPassword = useSelector(
    (state: SystemState) => state.login.successfullyResettingPassword
  );
  useEffect(() => {
    if (successfullyResettingPassword === null) return;

    if (successfullyResettingPassword) {
      setStage(2);
    } else {
      setStage(3);
    }
  }, [successfullyResettingPassword]);

  const dispatch = useDispatch();

  // Once only, when opening this page, check if there's already a token stored. If so, send the
  // user straight to the dashboard. If the user wants to login again, they'll have to logout first.
  // Note that if the token is expired, any request done in the dashboard will return the user to
  // the login screen.
  useEffect(() => {
    dispatch(finishLoginProcedures());
  }, []);

  return (
    <>
      <Form
        onInvalid={(e) => {
          e.preventDefault();
        }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FlipCard
          height={380}
          active={stage !== 1}
          frontChildren={[
            <Logo
              slogan={intl.formatMessage({ id: "info.slogan" })}
              lang={intl.formatMessage({ id: "language.region" })}
            >
              <Image
                src={imageDescription}
                alt={intl.formatMessage({ id: "info.slogan" })}
                appearance={"img-divulgation"}
              />
            </Logo>,
            <Fields>
              <Text
                text={intl.formatMessage({ id: "action.login.message" })}
                color={"light"}
                size={"medium"}
                textAlign={"center"}
              />
              <Input
                placeholder={intl.formatMessage({ id: "placeholder.email" })}
                type={"email"}
                required
                value={formData.email}
                onChange={(t) => {
                  setFormData({
                    ...formData,
                    email: t,
                  });
                }}
              />
              <Input
                placeholder={intl.formatMessage({ id: "placeholder.password" })}
                type={showPassword ? undefined : "password"}
                required
                value={formData.password}
                onChange={(t) => {
                  setFormData({
                    ...formData,
                    password: t,
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={"signin-show-password"}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {<Icon>{showPassword ? "visibility_off" : "visibility"}</Icon>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Checkbox
                label={intl.formatMessage({ id: "action.keep.connected" })}
                color={"light"}
              />
            </Fields>,
            <>
              <Button
                color={"secondary"}
                size={"large"}
                type={"submit"}
                action={() => {
                  dispatch(logIn(formData.email, formData.password));
                }}
              >
                {loading ? <CircularProgress /> : intl.formatMessage({ id: "action.login" })}
              </Button>
              <Box height={10}></Box>
              {!loading && (
                <Button
                  color={"primary"}
                  size={"large"}
                  type={"submit"}
                  action={() => {
                    dispatch(resetPassword(formData.email));
                  }}
                >
                  {intl.formatMessage({ id: "login.resetPassword" })}
                </Button>
              )}
            </>,
          ]}
          backChildren={
            //Success request reset password stage
            stage === 2
              ? [
                  <Image
                    src={imageSuccess}
                    alt={intl.formatMessage({ id: "info.slogan" })}
                    appearance={"img-logo"}
                  />,
                  <Text
                    text={intl.formatMessage({ id: "action.success" })}
                    appearance={"title"}
                    color={"light"}
                    size={"medium"}
                    transform={"uppercase"}
                    textAlign={"center"}
                  />,
                  <Text
                    text={intl.formatMessage({ id: "login.resetPassword.success" })}
                    color={"light"}
                    textAlign={"center"}
                  />,
                  <Button
                    text={intl.formatMessage({ id: "action.back" })}
                    appearance={"button-link"}
                    size={"small"}
                    action={() => {
                      setStage(1);
                    }}
                  />,
                ]
              : // Error stage
                [
                  <Image
                    src={imageError}
                    alt={intl.formatMessage({ id: "info.slogan" })}
                    appearance={"img-logo"}
                  />,
                  <Text
                    text={intl.formatMessage({ id: "action.error" })}
                    appearance={"title"}
                    color={"light"}
                    size={"medium"}
                    transform={"uppercase"}
                    textAlign={"center"}
                  />,
                  <Text
                    text={
                      successfullyResettingPassword === false
                        ? intl.formatMessage({ id: "login.resetPassword.error" })
                        : intl.formatMessage({ id: "action.login.msgError" })
                    }
                    color={"light"}
                    textAlign={"center"}
                  />,
                  <Button
                    text={intl.formatMessage({ id: "action.back" })}
                    appearance={"button-link"}
                    size={"small"}
                    action={() => {
                      setStage(1);
                      dispatch(resetError());
                    }}
                  />,
                ]
          }
        />
      </Form>
    </>
  );
};
