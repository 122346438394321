import React from "react";
import Image from "../../BasicComponents/Image";
import { List, Item, Link, Submenu, SubItens } from "./styles";
import { Container } from "@material-ui/core";

interface Props {
  menuItems: Array<{
    text: string;
    active: boolean;
    link: string | "url";
    submenu?: Array<{
      text?: string;
      description?: string;
      image?: string | "url";
      link?: string | "url";
    }>;
    subtype?: Array<{
      text?: string;
      description?: string;
      image?: string | "url";
      link?: string | "url";
    }>;
  }>;
  orientation?: "horizontal" | "vertical" | "column";
  color?: "light" | "dark";
}

export default (props: Props) => {
  return (
    <>
      <List orientation={props.orientation}>
        {/* creates list of options with values ​​received from the props */}
        {props.menuItems.map((item) => (
          <Item
            color={props.color}
            key={item.text}
            active={item.active}
            className={item.submenu && "submenu"}
          >
            <Link href={item.link}>{item.text}</Link>
            {item.submenu && (
              <Submenu className={item.subtype && "subtype"}>
                <Container>
                  {item.submenu.map((sub) => (
                    <>
                      <Item>
                        <Link href={sub.link}>
                          <Image src={sub.image} alt={sub.text} appearance={"img-partner"} />
                          {sub.description}
                        </Link>
                      </Item>
                    </>
                  ))}
                  {item.subtype && (
                    <SubItens>
                      {item.subtype.map((type) => (
                        <>
                          <Item>
                            <Link href={type.link}>
                              <Image src={type.image} alt={type.text} appearance={"img-partner"} />
                              {type.text}
                            </Link>
                          </Item>
                        </>
                      ))}
                    </SubItens>
                  )}
                </Container>
              </Submenu>
            )}
          </Item>
        ))}
      </List>
    </>
  );
};
