import React from "react";
import { useIntl } from "react-intl";
import Container from "../../Areas/Container";
import Section from "../../Section";
import Carousel from "../../BasicComponents/ProductsCarousel";

interface Props {
  title: string;
  sectionColor?: "primary";
  items: Array<{
    id: string;
    product_id?: string;
    title: string;
    description: string;
    price: string;
    image: string | "url";
  }>;
}

export default (props: Props) => {
  const intl = useIntl();
  return (
    <>
      <Section
        sectionTitle={props.title}
        sectionColor={props.sectionColor}
        id="prices"        
        textAlign={"center"}
        appearanceText="subtitle-medium"
      >
        <Container>
          <Carousel items={props.items} color={props.sectionColor} showButtons={false} />          
        </Container>
      </Section>
    </>
  );
};
