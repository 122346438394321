import React from "react";
import { useIntl } from "react-intl";
import Text from "../../BasicComponents/Text";
import Button from "../../BasicComponents/Button";
import Table from "../../BasicComponents/Table";
import { Card, Infos } from "./styles";

interface Props {
  paymentInfo: Array<{
    amount: number;
    plan: string;
    mode: string;
    price: number;
  }>;
}

export default (props: Props) => {
  const intl = useIntl();

  const tableHeader = [
    { title: intl.formatMessage({ id: "info.amount" }) },
    { title: intl.formatMessage({ id: "info.plan" }) },
    { title: intl.formatMessage({ id: "info.mode" }) },
    { title: intl.formatMessage({ id: "info.price" }) },
  ];

  // terms
  const infoSummary = intl.formatMessage({ id: "info.summary" });
  const placeRegisterInfo = intl.formatMessage({ id: "info.register.information" });
  const placeRegisterTerms = intl.formatMessage({ id: "info.register.terms" });
  const placeLegalInfo = intl.formatMessage({ id: "info.legal.information" });
  const placeLegalTerms = intl.formatMessage({ id: "info.legal.terms" });
  const placePlansInfo = intl.formatMessage({ id: "info.plans.information" });
  const payNow = intl.formatMessage({ id: "action.pay.now" });
  const infoFooter = intl.formatMessage({ id: "info.total" });

  return (
    <>
      <Card>
        <Text text={infoSummary} appearance={"block-title"} />
        <Infos>
          <Text text={placeRegisterInfo} appearance={"small-title"} />
          <Text text={placeRegisterTerms} appearance={"small-description"} />
          <Text text={placeLegalInfo} appearance={"small-title"} />
          <Text text={placeLegalTerms} appearance={"small-description"} />
          <Text text={placePlansInfo} appearance={"small-title"} />
          <Table itemsHeader={tableHeader} itemsBody={props.paymentInfo} infoFooter={infoFooter} />
          <Button text={payNow} appearance={"highlighted"} size={"medium"} />
        </Infos>
      </Card>
    </>
  );
};
