import { Location, UnitSystem, Waypoint } from "biohub-model";
import React, { useEffect, useState } from "react";
import WaypointMarkers from "./WaypointMarkers";
import HomePointMarker from "./markers/HomePointMarker";
import PlannedRoutePolyline from "./polylines/PlannedRoutePolyline";
import { activeWaypoints } from "../../../../../core/areaPlanning";
import ElevationService from "../../../../../services/routePlanner/ElevationService";

type Props = {
  polygonId: string;
  editing: boolean;
  waypointScale?: number;
  showDistanceLabels?: boolean;
  waypoints: Waypoint[];
  formatHeight: (height: number) => string;
  selectedWaypointIndexes: number[];
  onClickWaypoint?: (waypoint: Waypoint, waypointIndex: number) => void;
  homePoint?: Location;
  homePointElevation?: number;
  onHomePointMoved?: (newLocation: Location) => void;
  unitSystem: UnitSystem;
  onWaypointMoved?: (index: number, newLocation: Location) => void;
  onClickPlannedRoute?: (segmentIndex: number, location: Location) => void;
  showWaypointDistances?: false;
  usingOnlineElevation: boolean;
  homePointMarkerZIndex: number;
  homePointElevationMarkerZIndex: number;
  homePointElevationLabelZIndex: number;
  plannedPathZIndex: number;
  plannedPathBorderZIndex: number;
  waypointsDistanceMarkerZIndex: number;
  waypointsDistanceLabelZIndex: number;
  waypointOrientationMarkerZIndex: number;
  waypointDropMarkerZIndex: number;
  waypointElevationMarkerZIndex: number;
  waypointElevationLabelZIndex: number;
};

export default function (props: Props): JSX.Element {
  const activeLiberations = props.editing ? undefined : activeWaypoints(props.waypoints);
  const waypointsWithActions = props.editing
    ? undefined
    : props.waypoints.map((waypoint) => Object.keys(waypoint.releaserActions).length > 0);

  const [planningElevationData, setPlanningElevationData] = useState<
    | {
        waypointsHeight: number[];
        homePointHeight: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (!props.editing && props.homePointElevation !== undefined) {
      setPlanningElevationData({
        waypointsHeight: props.waypoints.map((waypoint) => waypoint.elevation),
        homePointHeight: props.homePointElevation,
      });

      return;
    }
    const waypoints = props.waypoints;
    const homePoint = props.homePoint;

    if (waypoints.length === 0 || homePoint === undefined) return;

    const callback = async () => {
      const result = await ElevationService.getElevationsForPath([
        ...waypoints.map((waypoint) => waypoint.location),
        homePoint,
      ]);
      if (!result.success) {
        console.warn(result.error);
        return;
      }

      const elevationData = result.data;
      const homePointHeight = elevationData.pop()!;
      const waypointsHeight = [...elevationData];

      setPlanningElevationData({
        waypointsHeight: waypointsHeight,
        homePointHeight: homePointHeight,
      });
    };

    callback();
  }, [
    props.waypoints,
    props.homePoint ?? {
      lat: 0,
      lng: 0,
    },
    props.homePointElevation ?? 0.0,
  ]);

  return (
    <>
      <WaypointMarkers
        key={`planned-area-waypoint-markers-for-${props.polygonId}`}
        polygonId={props.polygonId}
        editing={props.editing}
        waypoints={props.waypoints}
        waypointScale={props.waypointScale}
        showDistanceLabels={props.showDistanceLabels}
        waypointsElevation={planningElevationData?.waypointsHeight}
        homePointElevation={planningElevationData?.homePointHeight}
        formatHeight={props.formatHeight}
        selectedWaypointIndexes={props.selectedWaypointIndexes}
        onClickWaypoint={(waypointIndex) => {
          props.onClickWaypoint?.(props.waypoints[waypointIndex], waypointIndex);
        }}
        activeLiberations={activeLiberations}
        unitSystem={props.unitSystem}
        onWaypointMoved={props.onWaypointMoved}
        showWaypointDistances={props.showWaypointDistances}
        waypointsWithActions={waypointsWithActions}
        usingOnlineElevation={props.usingOnlineElevation}
        waypointsDistanceMarkerZIndex={props.waypointsDistanceMarkerZIndex}
        waypointsDistanceLabelZIndex={props.waypointsDistanceLabelZIndex}
        waypointOrientationMarkerZIndex={props.waypointOrientationMarkerZIndex}
        waypointDropMarkerZIndex={props.waypointDropMarkerZIndex}
        waypointElevationMarkerZIndex={props.waypointElevationMarkerZIndex}
        waypointElevationLabelZIndex={props.waypointElevationLabelZIndex}
      />
      {props.homePoint !== undefined && (
        <HomePointMarker
          key={`planned-area-home-point-for-${props.polygonId}`}
          polygonId={props.polygonId}
          isEditing={props.editing}
          waypointScale={props.waypointScale}
          showDistanceLabels={props.showDistanceLabels}
          location={props.homePoint}
          onMoved={props.onHomePointMoved}
          homePointHeightLegend={
            planningElevationData?.homePointHeight !== undefined
              ? props.formatHeight(planningElevationData.homePointHeight)
              : undefined
          }
          markerZIndex={props.homePointMarkerZIndex}
          elevationMarkerZIndex={props.homePointElevationMarkerZIndex}
          elevationLabelZIndex={props.homePointElevationLabelZIndex}
        />
      )}
      <PlannedRoutePolyline
        key={`planned-area-planned-route-polyline-for-${props.polygonId}`}
        polygonId={props.polygonId}
        waypoints={props.waypoints}
        activeLiberations={undefined}
        onClick={props.onClickPlannedRoute}
        pathZIndex={props.plannedPathZIndex}
        pathBorderZIndex={props.plannedPathBorderZIndex}
      />
    </>
  );
}
