/**
 * Français (fr)
 */
const messagesMap: { [key: string]: string } = {
  "app.title": "bioHUB",
  // --- Página de login ---
  "login.title.login": "Connexion",
  "login.form.email": "Email",
  "login.form.password": "Mot de passe",
  "login.resetPassword": "J'ai oublié mon mot de passe",
  "login.resetPassword.success":
    "Processus de récupération de mot de passe effectué avec succès, vérifiez votre boîte de réception pour continuer.",
  "login.resetPassword.error":
    "Erreur lors de la récupération de votre compte. Veuillez vérifier vos coordonnées ou réessayer dans un instant.",

  "profile.login.currentPassword": "Mot de passe actuel",
  "profile.login.newPassword": "Nouveau mot de passe",
  "profile.login.confirmNewPassword": "Confirmer le nouveau mot de passe",

  "profile.registrationStatus.name": "Statut d'enregistrement",
  "profile.registrationStatus.completed": "Achevée",
  "profile.registrationStatus.pending": "En attente",

  // --- Overlays do mapa ---
  "map.overlays.weather.temperature": "Température",
  "map.overlays.weather.pressure": "Pression",
  "map.overlays.weather.humidity": "Humidité",
  "map.overlays.weather.wind": "Vent",
  "map.overlays.weather.windSpeed": "Vitesse du vent",
  "map.overlays.weather.windDirection": "Direction du vent",
  "map.overlays.weather.percentageClouds": "Des nuages",
  "map.overlays.weather.searchTime": "Temps de recherche",
  "map.overlays.weather.visibility": "Visibilité",
  "map.overlays.weather.sunrise": "Lever du soleil",
  "map.overlays.weather.sunset": "Coucher de soleil",
  "map.overlays.weather.timezone": "Fuseau horaire",
  "map.overlays.weather.optimizePolygon": "Optimiser le polygone",
  "map.overlays.weather.optimizePolygonTooltip": `Optimise la génération de polygones importés via Kml/Shp. 
  L'OPTIMISATION PEUT NE PAS GÉNÉRER LES RÉSULTATS ATTENDUS, PEUT CAUSER DES DISTORSIONS DANS LES POLYGONES GÉNÉRÉS`,
  // --- Textos a serem usados de maneira geral no app (por exemplo, em botões) ---
  "generic.ok": "OK",
  "generic.cancel": "Annuler",
  "generic.close": "Proche",
  "generic.yes": "Oui",
  "generic.no": "Non",
  "generic.next": "Prochaine",
  "generic.previous": "Précédente",
  "generic.continue": "Continuer",
  "generic.closeConfirmation.title": "Confirmer",
  "generic.closeConfirmation.discardChangesAndClose": "Ignorer les modifications et fermer ?",
  "generic.N/A": "Rien",
  "generic.copy": "Copie",

  "general.year": "année",
  "general.month": "mois",
  "general.hectares": "hectares",

  // --- Textos a serem usados em erros ---
  "generic.error.unknown": "Une erreur est survenue.",

  //-- Page Information --//
  "info.pageTitle": "bioHUB - NCB",
  "info.slogan": "Précis par Nature",
  "info.title":
    "Le premier et le seul HUB \nde technologies pour le \nContrôle Biologique de Précision",
  "info.description": "Prochainement!",
  "info.newsletter": "Inscrivez-vous à notre newsletter",
  "info.about": "bioHUB par NCB - Sistemas Embarcados",
  "info.copyright": "2021 ⓒ bioHUB - NCB. Tous droits réservés.",
  "info.address":
    "Parque Tecnológico de São José dos Campos - CEI - Sala 207 Estrada Doutor Altino Bondensan, 500 Distrito Eugênio de Mello São José dos Campos - SP - 12.247-016",
  "info.email": "contato@ncb.ind.br",
  "info.phone": "+55 (41) 98891-4324",
  "info.terms":
    "En acceptant les présentes conditions, l'utilisateur consent et accepte que la société NCB, ci-après dénommée Responsable du traitement, prenne des décisions concernant le traitement de ses données personnelles, ainsi que le traitement de ses données personnelles, impliquant des opérations telles que celles-ci. se référant à la collecte, la production, la réception, la classification, l'utilisation, l'accès, la reproduction, la transmission, la distribution, le traitement, l'archivage, le stockage, l'élimination, l'évaluation ou le contrôle des informations, la modification, la communication, le transfert, la diffusion ou l'extraction. Le Responsable du traitement est autorisé à partager les données personnelles du Titulaire avec d'autres agents de traitement de données, si nécessaire aux fins énumérées dans ce terme, sous réserve des principes et garanties établis par la loi n° 13.709.",
  "info.summary": "Résumé",
  "info.register.information": "Information d'inscription",
  "info.register.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.legal.information": "Informations du représentant légal",
  "info.legal.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.plans.information": "Informations sur le plan",
  "info.amount": "La quantité",
  "info.banner.plan.title": "Forfaits et tarifs",
  "info.plan": "Plat",
  "info.plan.name.bioHUB": "bioHUB",
  "info.plan.name.bioMAPS": "bioMAPS",
  "info.plan.name.bioBOT": "bioBOT",
  "info.plan.subTitle.bioMAPS": "Application de planification de mission de vol",
  "info.plan.description.bioMAPS":
    "Planificateur de mission pour DRONES axé sur le contrôle biologique de précision. Ce produit nécessite un abonnement bioHUB actif pour fonctionner.",
  "info.plan.type.monthly": "Mensuel",
  "info.plan.type.yearly": "Annuel",
  "info.plan.perDevice": "par appareil",
  "info.plan.ofDiscount": "réduction",
  "info.mode": "Mode",
  "info.price": "Valeur",
  "info.per.year": "Par An",
  "info.total": "Le total",
  "info.users": "Utilisateurs",
  "info.preregistrations": "Pré-inscription",
  "info.preregistration": "Pré-inscription",
  "info.preregistrations.viewdetails": "Ce sont les informations de cette pré-inscription.",
  "info.contract": "Contracter",
  "info.add.new": "Entrez les données ci-dessous pour ajouter un nouvel utilisateur.",
  "info.clients": "Les clientes",
  "info.termError": "Sua mensagem não pôde ser enviada no momento",
  "info.menuOptions": "Options de menu",
  "info.addCart": "Ajouter au panier",
  "info.purchase": "Acheter",

  "info.client.add": "Ajouter un client",
  "info.directClient.add": "Ajouter un client direct",
  "info.client.edit": "Modifier le client",
  "info.client.add.message": "Entrez les données ci-dessous pour ajouter un nouveau client.",
  "info.client.edit.message": "Modifiez les données ci-dessous pour mettre à jour votre client.",
  "info.client.personalInformations": "Informations personnelles:",
  "info.client.companyInformation": "Informations sur la société:",
  "info.client.documentType": "Type de document",
  "info.client.monetaryConfiguration": "Paramètres de facturation :",
  "info.client.currencyCode": "Monnaie",
  "info.client.nextDueDate": "Prochaine date d'échéance",

  "info.none": "Rien",
  "info.releaser": "Libérateur",
  "info.releasers": "libératrices",
  "info.releaser.edit": "Modifier l'outil de libération",
  "info.releaser.add": "Ajouter un déclencheur",
  "info.releaser.add.message":
    "Remplissez les informations ci-dessous pour ajouter un nouveau déclencheur.",
  "info.releaser.edit.message":
    "Modifiez les informations ci-dessous pour mettre à jour votre releaser.",
  "info.releaser.selectdrone": "Sélectionnez Drone:",
  "info.releaser.liberatormodel": "Modèle de déclencheur:",
  "info.releaser.selectclient": "Nom du client:",
  "info.releaser.models": "Des modèles",

  "info.drone": "Drone",
  "info.drones": "Drones",
  "info.manufacturer": "Fabricante",
  "info.drone.edit": "Modifier le drone",
  "info.drone.add": "Ajouter un drone",
  "info.drone.add.message": "Remplissez les informations ci-dessous pour ajouter un nouveau drone.",
  "info.drone.edit.message": "Modifiez les informations ci-dessous pour mettre à jour votre drone.",
  "info.drone.selectdrone": "Sélectionnez Drone:",
  "info.drone.selectclient": "Nom du client:",
  "info.drone.manufacturer": "Fabricant de drones",
  "info.drone.dronemodel": "Modèle de drone:",

  "info.cpu": "CPU",
  "info.cpus": "CPUs",
  "info.model": "Modèle",
  "info.serialNumber": "Numéro de série",
  "info.firmwareVersion": "Version du Firmware",
  "info.lastFirmware": "Dernière Firmware",
  "info.client": "Client",
  "info.cpu.add": "Ajouter CPU",
  "info.cpu.viewing": "Affichage du CPU",
  "info.cpu.model": "Modèle de CPU",
  "info.cpu.addingMessage": "Remplissez les champs ci-dessous pour ajouter un nouveau CPU",
  "info.cpu.viewingMessage": "Affichage du CPU ajouté",
  "info.releaser.addDevice": "Ajouter un appareil",
  "info.releaser.removeDevice": "Enlevez l'appareil",
  "info.devices": "Appareils",
  "info.device.model": "Modèle",

  "info.profiles": "Profils",
  "info.role.master": "Maître",
  "info.role.administrator": "Administrateur",
  "info.role.manager": "Directrice",
  "info.role.operator": "Opérateur",
  "info.role.external": "Externe",

  "info.profile.add": "Ajouter un utilisateur",
  "info.profile.edit": "Modifier l'utilisateur",
  "info.profile.add.message": "Entrez les données ci-dessous pour ajouter un nouvel utilisateur.",
  "info.profile.edit.message":
    "Modifiez les données ci-dessous pour mettre à jour votre utilisateur.",
  "info.profile.userType": "Type d'utilisateur",
  "info.profile.userType.manager": "Directeur",
  "info.profile.userType.operator": "Opérateur",
  "info.profile.userType.external": "Externe",
  "info.profile.personalInformations": "Informations personnelles:",
  "info.profile.legalInformations": "Informations légales:",
  "info.profile.agricultureNumber": "Numéro agricole",
  "info.profile.crea": "CREA",
  "info.profile.ava": "AVA",

  "info.profile.edit.image": "Modifier la photo de profil",
  "info.profile.edit.image.dialog": "La photo doit être au format jpg, png ou jpeg.",
  "info.profile.edit.image.buttom.choose": "Choisir la photo",
  "info.profile.edit.image.buttom.send": "Envoyer",
  "info.profile.edit.image.buttom.cancel": "Annuler",
  "info.profile.edit.image.invalid": "Impossible de télécharger votre photo.",

  "info.profile.edit.logo": "Changer votre logo",
  "info.profile.edit.logo.dialog": "Le logo doit être au format jpg, png ou jpeg.",
  "info.profile.edit.logo.buttom.choose": "Choisir le logo",
  "info.profile.edit.logo.buttom.send": "Envoyer",
  "info.profile.edit.logo.buttom.cancel": "Annuler",
  "info.profile.edit.logo.invalid": "Impossible de charger votre logo.",

  "info.profile.nationality": "Nationalité",

  "info.directClients": "Clients",

  //-- Pages --//
  "info.subtitleTerms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.pageTerms":
    "En acceptant les présentes conditions, l'utilisateur consent et accepte que la société NCB, ci-après dénommée Responsable du traitement, prenne des décisions concernant le traitement de ses données personnelles, ainsi que le traitement de ses données personnelles, impliquant des opérations telles que celles-ci. se référant à la collecte, la production, la réception, la classification, l'utilisation, l'accès, la reproduction, la transmission, la distribution, le traitement, l'archivage, le stockage, l'élimination, l'évaluation ou le contrôle des informations, la modification, la communication, le transfert, la diffusion ou l'extraction. Le Responsable du traitement est autorisé à partager les données personnelles du Titulaire avec d'autres agents de traitement de données, si nécessaire aux fins énumérées dans ce terme, sous réserve des principes et garanties établis par la loi n° 13.709.",
  "info.subtitlePrivacy": "Lorem ipsum dolor sit amet consecter",
  "info.privacy":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleSupport": "Lorem ipsum dolor sit amet consecter",
  "info.support":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleFaq": "Lorem ipsum dolor sit amet consecter",
  "info.faq":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion1": "Question 1 ?",
  "info.faqQuestion1Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion2": "Question 2 ?",
  "info.faqQuestion2Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion3": "Question ?",
  "info.faqQuestion3Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleWhy": "Lorem ipsum dolor sit amet consecter",
  "info.why":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleResources": "Lorem ipsum dolor sit amet consecter",
  "info.resources":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",

  "info.aboutTitle": "Qu'est-ce que le NCB?",
  "info.aboutNCB": "NCB?",
  "info.aboutDescription":
    "bioHub est une plate-forme numérique holistique qui permet une gestion efficace des parasites et des maladies grâce à des drones et des logiciels en mettant l'accent sur la veille économique.",
  "info.aboutVisionTitle": "Notre vision",
  "info.aboutVisionSub": "La réalité recherchée",
  "info.aboutVisionText":
    "Transformer le secteur agricole en favorisant la gestion des données et l'application de technologies et d'innovations durables pour la création de stratégies et l'utilisation affirmée de la lutte biologique. En trois ans, nous voulons que Bi All soit la référence dans la symbiose entre agriculture, science et technologie, après avoir exploité trois millions d'hectares de cultures.",
  "info.aboutMissionTitle": "Notre mission",
  "info.aboutMissionSub": "La raison d'être",
  "info.aboutMissionText":
    "Catalysez la nature grâce à la numérisation des données qui vous permet de créer des stratégies de prise de décision, assurant la meilleure gestion des ressources. Apporter des solutions aux écosystèmes agricoles grâce à la lutte biologique de précision.",
  "info.aboutValues": "Nos valeurs",
  "info.aboutPrecisionTitle": "Précision",
  "info.aboutPrecisionText":
    "Nous exécutons tous nos processus et services avec rigueur et selon la stratégie avec laquelle ils ont été planifiés.",
  "info.aboutTraceabilityTitle": "Traçabilité",
  "info.aboutTraceabilityText":
    "Nous garantissons une visibilité et un contrôle à chaque étape des processus pour obtenir les meilleurs résultats.",
  "info.aboutTeamTitle": "Notre équipe",
  "info.aboutTeamRoleCEO": "PDG et Fondateur",
  "info.aboutTeamRoleManager": "Gestion",
  "info.aboutTeamRoleEmployee": "Ingénierie",
  "info.aboutTeamDescriptionCEO":
    "Ingénierie électrique (FEI 2002)\nMaster Electronique (ITA 2007)\nDoctorat en systèmes aérospatiaux (ITA 2013)",
  "info.aboutTeamDescriptionManager":
    "Administration (UNILESTE 2005)\nGestion de l'entrepreneuriat (UNIPAC)",
  "info.aboutTeamDescriptionEmployee":
    "Matériel et logiciel intégrés\nIngénierie électrique (UNESP 2005)",
  "info.contactTitle": "Contactez-nous",
  "info.contactSubtitle": "Coordonnées par pays",
  "info.contactDescription":
    "Remplissez vos coordonnées dans le formulaire ci-dessous et attendez. Nous vous contacterons dès que possible!",
  "info.termTitle": "Le plan bioMAPS",
  "info.coin": "€",
  "info.termPriceMonth": "par mois / par appareil",
  "info.termPriceYear": "par an / par appareil (15% remise)",
  "info.termSpecifications": "Caractéristiques:",
  "info.termSpecificationsDesc":
    "Il s'agit du premier planificateur de mission de DRONES axé sur le contrôle de précision biologique. Il est possible de planifier des itinéraires de n'importe où dans le monde via le cloud et synchronisé avec une application mobile pour lancer des bios 100% suivis et contrôlés par GPS.",
  "info.termClassifications": "Évaluations: ",
  "info.termClassificationsDesc":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis curae cursus per, morbi neque viverra nam nascetur mauris metu consequat quis.",
  "info.termChoose": "Choisissez le forfait",
  "info.termComparisonTitle": "Comparer les fonctionnalités",
  "info.termComparisonMonth": "mois",
  "info.termComparisonYear": "année",
  "info.termComparisonEquipment": "équipement",
  "info.termComparisonPerEquipment": "par équipement",
  "info.termComparisonMonthDevice": "mois par appareil",
  "info.termComparisonYearDevice": "année par appareil",
  "info.termComparisonHectare": "hectare par mois",
  "info.termComparisonDescription": "Comprend une licence pour 1 appareil.",
  "info.termComparison.accessTitleBioHUB": "Accès à la plateforme",
  "info.termComparison.accessTitleBioMAPS": "Accès à l'application",
  "info.termComparison.accessTitleBioBOT": "Équipement",
  "info.termComparisonDescriptionBioHUB":
    "Comprend 5 licences d'opérateur ou 4 licences d'opérateur et 1 licence de gestionnaire. €15 pour chaque opérateur supplémentaire.",
  "info.introducing": "Présentation",
  "info.cameraQuality": "Qualité de la caméra",
  "info.flightTime": "Temps de vol",
  "info.capacity": "Capacité",
  "info.minutes": "minutes",
  "info.verificationTitle": "Vérification de l'e-mail",
  "info.verificationDescription":
    "La vérification nécessite une adresse e-mail valide. \nNous enverrons un code de confirmation à l'adresse e-mail que vous avez saisie afin de lancer le processus de validation. \nSi l'adresse indiquée ci-dessous est incorrecte, cliquez sur l'icône et modifiez vos coordonnées.",
  "info.emailAddress": "Adresse e-mail",
  "info.verificationCodeDescription": "Entrez le code envoyé à votre email :",
  "info.confirmationCode": "Code de vérification",
  "info.newPassword": "Nouveau mot de passe",
  "info.newPasswordDescription":
    "Veuillez définir un nouveau mot de passe pour accéder à notre plateforme.",
  "info.newPasswordDetails": "Entrez le nouveau mot de passe",
  "info.newPasswordConfirm": "Confirmez votre nouveau mot de passe",

  "setupAccount.title": "Complétez votre inscription",
  "setupAccount.created": "Compte créé avec succès",
  "setupAccount.error":
    "Une erreur s'est produite lors de la finalisation de votre inscription. Veuillez réessayer.",
  "setupAccount.error.tryAgain": "Réessayer",
  "setupAccount.error.wrongSmsCode": "Code SMS erroné",
  "setupAccount.verifiedEmail": "E-mail vérifié avec succès",
  "setupAccount.verification.code": "Code SMS",
  "setupAccount.verification.description":
    "La vérification nécessite un numéro de téléphone valide. \nNous vous enverrons un code de confirmation sur votre téléphone et vous devrez entrer le code correct pour terminer le processus de validation et créer le mot de passe de votre compte.",
  "setupAccount.verification.codeDescription":
    "Entrez le code à 6 chiffres qui a été envoyé à votre téléphone",
  "setup.password": "Tapez votre mot de passe",
  "setup.confirmPassword": "Confirmer votre mot de passe",
  "setup.password.different": "Le mot de passe ne correspond pas",
  "setup.password.needUpperCase": "Nécessaire un élément en majuscule",
  "setup.password.needSpecialElement": "Nécessaire un caractère spécial",
  "setup.password.lenght": "Nécessaire une longueur minimale de 6 caractères",

  "drone.usagePhantom4": "Phantom 4",
  "drone.usageDroneMatrix100": "Matrix 100",
  "drone.usageDroneMatrix200": "Matrix 200",

  "prod.productTitle": "Des produits",
  "prod.bioBotSubTitle": "Lanceur d'entrée biologique",
  "prod.bioBotDescription":
    "C'est un libérateur de parasites et d'œufs en vrac, utilisé principalement pour la lutte antiparasitaire. Avec elle, il est possible de lancer des intrants biologiques tels que Trichogramma, Telenomus et Chrysopa (tous testés et en exploitation commerciale avec DRONES au Brésil, en Colombie et en Amérique centrale).",
  "prod.bioBotWorksBioMaps": "Fonctionne avec l'application bioMAPS",
  "prod.bioBotWeatherProof": "Résistant aux intempéries",
  "prod.bioBotAutonomy": "+5 heures d'autonomie",
  "prod.equipmentTitle": "Ou des équipements plus polyvalents",
  "prod.equipmentDescription":
    "Avec elle, il est possible de libérer des intrants biologiques tels que Trichogramma, Telenomus et Chrysopa (tous testés et en exploitation commerciale avec DRONES au Brésil, en Colombie et en Amérique centrale).",
  "prod.biocoteDescription":
    "Il s'agit d'un libérateur de gobelets biodégradable avec masse cothesia utilisé principalement dans le contrôle des populations de chenilles. La canne à sucre est la principale culture traitée. (Développement exclusif de Biocontrol). 100% opérationnel et commercial. Votre version de pâtes uniques parasitées a été congelée car nous ne pouvons pas continuer les tests sur le terrain, nous reprendrons à partir de janvier 2021.",

  "prod.cultivationEquipmentTitle": "Les principales cultures ont bénéficié",
  "prod.cultivationSugarCane": "Canne à sucre",
  "prod.cultivationSoy": "Soja",
  "prod.cultivationBeans": "Des haricots",
  "prod.cultivationCorn": "Maïs",
  "prod.cultivationBanana": "Banane",
  "prod.cultivationAvocado": "Avocate",
  "prod.cultivationOilPalm": "Huile de palme",
  "prod.cultivationCoffee": "Café",
  "prod.cultivationForest": "Forêt", //add
  "prod.cultivationStrawberry": "Fraise",
  "prod.cultivationMelon": "Melon",
  "prod.cultivationPapaya": "Papaye",
  "prod.cultivationTulip": "Tulipe",
  "prod.cultivationCotton": "Coton",
  "prod.cultivationOthers": "Autres",
  "prod.cultivationContact":
    "Si la culture de votre entreprise n'est pas montrée ici, contactez-nous pour un conseil personnalisé",

  "prod.usageSugarcane": "Canne à sucre",
  "prod.usageForest": "Forêt",
  "prod.usageCotton": "Coton",
  "prod.usageCorn": "Maïs",
  "prod.usageSoybeans": "Soja",
  "prod.usageCoffee": "Café",
  "prod.usageStrawberry": "Forêt",
  "prod.usageMelon": "Melon",
  "prod.usagePapaya": "Papaye",
  "prod.usageTulip": "Tulipe",
  "prod.usageAvocado": "Avocate",
  "prod.usageBeans": "Des haricots",
  "prod.usageBanana": "Banane",
  "prod.usageOilPalm": "Huile de palme",

  "prod.specificationTitle": "Spécifications techniques",
  "prod.specificationSubTitle": "Accès à la plateforme bioHUB",
  "prod.specificationInfo":
    "Comprend 5 licences d'opérateur ou 4 licences d'opérateur et 1 licence de gestionnaire ; 15$ pour chaque opérateur supplémentaire.",

  "bio.usageTrichogrammaExiguum": "Trichogramma Exiguum",
  "bio.usageTrichogrammaGalloi": "Trichogramma Galloi",
  "bio.usageTrichogrammaAtopovirilia": "Trichogramma Atopovirilia",
  "bio.usageTrichogrammaBrassicae": "Trichogramma Brassicae",
  "bio.usageTrichogrammaPretiosum": "Trichogramma Pretiosum",
  "bio.usageChrysoperlaCarnea": "Chrysoperla Carnea",
  "bio.usageChrysoperlaExterna": "Chrysoperla Externa",
  "bio.usageStratiolaelapsScimitus": "Stratiolaelaps Scimitus",
  "bio.usageNeoseiulusCalifornicus": "Neoseiulus Californicus",
  "bio.usageTelenomusPodisi": "Telenomus Podisi",
  "bio.usageTelenomusRemus": "Telenomus Remus",
  "bio.usagePhytoseiulusPersimilis": "Phytoseiulus Persimilis",
  "bio.usageCotesiaFlavipes": "Cotesia Flavipes",
  "bio.usageCeraeochrysaCincta": "Ceraeochrysa Cincta",
  "bio.usageTetrastichusHowardi": "Tetrastichus Howardi",
  "bio.usageAmblyseiusCalifornicus": "Amblyseius Californicus",
  "bio.usageMetarhiziumAnisopliae": "Metarhizium Anisopliae",
  "bio.usageBeauveriaBassiana": "Beauveria Bassiana",

  "cart.title": "Mon panier",
  "cart.title.general": "Général",
  "cart.title.address": "Adresse personnelle et contact",
  "cart.title.address.company": "Adresse et contact de l'entreprise",
  "cart.title.representative": "Représentant légal",
  "cart.title.step1": "Étape 1: Détails sur l'entreprise",
  "cart.title.step2": "Étape 2: Termes",
  "cart.title.step3": "Étape 3: Payer",
  "cart.title.number.order": "N° de commande.: ",
  "cart.title.billing.address": "Adresse de facturation",
  "cart.title.shipping.address": "Adresse de livraison",
  "cart.title.payments.methods": "Méthodes de payement",
  "cart.title.modal.billing.address": "Ajouter une adresse de facturation",
  "cart.title.modal.shipping.address": "Ajouter une adresse de livraison",
  "cart.title.discount.coupon": "Coupon de réduction: ",
  "cart.title.modal.add.discount.coupon": "Ajouter un nouveau coupon de réduction",
  "cart.title.subtotal": "Subtotal",
  "cart.title.discounts": "Remises",
  "cart.title.taxes": "Impôts",
  "cart.button.download": "Télécharger",
  "cart.terms.information.use": "Utilisation des informations personnelles",
  "cart.terms.mandatory.contract": "Contrats obligatoires",
  "cart.info.payments.paypal": "Paypal",
  "cart.info.payments.pix": "Pix",
  "cart.info.payments.ticket": "Billet",
  "cart.action.empty": "Panier vide",
  "cart.message.error.CPF": "CPF invalide",
  "cart.message.error.CNPJ": "CNPJ invalide",
  "cart.message.error.fields.filled": "Assurez-vous que tous les champs sont remplis correctement!",
  "cart.message.error.checked": "Assurez-vous que tous les termes ont été sélectionnés!",
  "cart.message.error.extra.address": "Ajoutez au moins 1 adresse de livraison ou de facturation",
  "cart.message.error.item.cart": "Il n'y a aucun objet dans votre panier!",
  "cart.message.error.document.type": "Sélectionnez un type de document! (CPF, CNPJ, ID)",
  "cart.message.error.coupon.invalid": "Coupon invalide, essayez-en un autre !",
  "cart.message.error.coupon.add.exists": "Ce coupon a déjà été ajouté!",
  "cart.alreadyIncluded": "Déjà inclus:",
  "cart.chooseOneVersionOfTheProductMessage": "Choisissez une version à ajouter au panier :",
  "cart.product": "Produit:",

  //-- Landing Page --//
  "page.title": "Digitalisez votre champ, \nle premier pas vers \nl'avenir de l'agriculture",
  "page.description":
    "bioHUB est une plateforme numérique holistique qui permet une gestion efficace des ravageurs et des maladies grâce à des drones et des logiciels axés sur l'intelligence commerciale.",
  // Sections
  "section.about.title": "Nos produits",
  "section.about.category": "Software",
  "section.about.info.title": "Sur bioHUB",
  "section.about.info.subtitle":
    "BioHub est un système de gestion de contrôle biologique de précision",
  "section.about.info.description":
    "Une plate-forme cloud intégrée qui aide chaque unité de contrôle biologique à surveiller les ravageurs, à libérer des agents biologiques avec précision, à générer des rapports et à surveiller la prise de décision...",
  "section.biomaps.title": "Sur BioMAPS:",
  "section.biomaps.subtitle": "BioMaps est un planificateur de mission de vol autonome",
  "section.biomaps.description":
    "C'est le premier planificateur de mission de drone axé sur le contrôle biologique de précision. Il est utilisé pour planifier les itinéraires de vol pour l'identification et le traitement des ravageurs.",
  "section.biomaps.required": "bioMAPS nécessite un abonnement bioHUB actif",
  "section.how.works.title": "Comment ça marche?",
  "section.products.category": "Hardware",
  "section.products.description":
    "Chaque culture peut souffrir de différents parasites qui nécessitent un traitement spécialisé. C'est pourquoi nous avons créé des solutions biologiques idéales pour votre culture et votre modèle d'entreprise.",
  "section.products.subDescriptionCultivation": "je cultive la ",
  "section.products.subDescriptionDrone": "j'ai le drone ",
  "section.products.subDescriptionBiological": "je veux postuler ",
  "section.card.product.description.bioBOT":
    "C'est un libérateur de parasites et d'œufs en vrac utilisé principalement pour la 𝐥𝐮𝐭𝐭𝐞 𝐚𝐧𝐭𝐢𝐩𝐚𝐫𝐚𝐬𝐢𝐭𝐚𝐢𝐫𝐞. Avec elle, il est possible de libérer des intrants biologiques tels que Trichogramma, Telenomus et Chrysopa",
  "section.card.product.description.bioCOTE":
    "Il s'agit d'un lâcheur de récipients biodégradable avec des intrants biologiques utilisés dans le contrôle des populations de 𝐜𝐡𝐞𝐧𝐢𝐥𝐥𝐞𝐬.",
  "section.card.product.description.PHERObio":
    "C'est un lanceur de capsules avec des phéromones. Il est utilisé pour contrôler 𝐌𝐢𝐠𝐝𝐨𝐥𝐮𝐬 par confusion sexuelle.",
  "section.products.required": "Tous nos produits matériels nécessitent",
  "section.prices.category": "Tarification",
  "section.prices.title": "Le bon plan pour votre entreprise",
  "section.prices.description":
    "Nous avons plusieurs plans puissants pour mettre en valeur votre entreprise et être découvert en tant qu'entrepreneur créatif.",
  "section.clients.category": "Les Clients",
  "section.clients.title": "Avis de nos clients",
  "section.clients.opinions.men":
    "“J'ai reçu un excellent service client de la part des spécialistes qui m'ont aidé. Je le recommanderais à tous ceux qui veulent un excellent tableau de bord de grande qualité.”",
  "section.clients.opinions.women":
    "“Cela se résume vraiment à ceci : que toute vie est interdépendante. Nous sommes tous pris dans un réseau incontournable de réciprocité, liés à un seul vêtement du destin”",
  "section.clients.role.men": "Concepteur de produits",
  "section.clients.role.women": "Développeur web",
  "section.partners.category": "Les Partenaires",
  "section.buy": "Achat",

  // Section Product
  "section.product.benefited.crops.title": "Récoltes bénéficiées",
  "section.product.compatible.drone.title": "Drones compatibles",
  "section.product.highlight": "Points forts",
  "section.product.biological.title": "Biologiques",

  // Sections Product [bioBOT]
  "section.product.bioBOT.title": "bioBOT",
  "section.product.bioBOT.subtitle": "Libérateurs d'intrants biologiques",
  "section.product.bioBOT.description":
    "C'est un distributeur en vrac d'œufs parasités utilisé principalement pour le contrôle de la population des ravageurs. Avec lui, il est possible de libérer des agents biologiques des genres Trichogramma, Telenomus et Chrysopa. La canne à sucre, le maïs, les haricots, le soja, le coton, le café, la banane, le palmier et l'avocat sont les principales cultures traitées.",
  "section.product.bioBOT.how_it_works.title": "Comment fonctionne le BioBOT",

  // Sections Product [bioCOTE]
  "section.product.bioCOTE.title": "bioCOTE",
  "section.product.bioCOTE.subtitle": "Libérateurs d'intrants biologiques",
  "section.product.bioCOTE.description":
    "C'est un distributeur de masse du genre Cotesia utilisé principalement pour le contrôle de la population des chenilles. La canne à sucre est la principale culture traitée.",
  "section.product.bioCOTE.how_it_works.title": "Comment fonctionne le BioCOTE",

  // Sections Product [bioMITE]
  "section.product.bioMITE.title": "bioMITE",
  "section.product.bioMITE.subtitle": "Libérateurs d'intrants biologiques",
  "section.product.bioMITE.description":
    "C'est un distributeur d'acariens prédateurs Phytoseiulus et Neoseiulus. Après leur libération, ils attaquent et contrôlent les infestations d'autres acariens nuisibles dans les champs. Les principales cultures sont la fraise, la tomate, les fleurs, le tabac et les champignons.",
  "section.product.bioMITE.how_it_works.title": "Comment fonctionne le BioMITE",

  //-- Dashboard Information --//
  "dashboard.welcome": "Content de te revoir, \n",
  "dashboard.clients": "Les clientes",
  "dashboard.users": "Utilisateurs",
  "dashboard.total.users": "Nombre Total d'Utilisateurs",
  "dashboard.total.reports": "Rapport",
  "dashboard.total.releasedFlights": "Montant de la Libération",
  "dashboard.total.monthFlightYield": "Revenu de Vol Mensuel",
  "dashboard.leggend.plannedReleased": "Prévu / Libéré",
  "dashboard.total.clients": "Nombre Total de Clients",
  "dashboard.total.drones": "Nombre Total de Drones",
  "dashboard.total.projects": "Nombre Total de Projets",
  "dashboard.total.sales": "Total des Ventes",
  "dashboard.total.area": "Nombre Total d'Hectares Couverts",
  "dashboard.total.yearMonthLegend": "Mois / Année",
  "dashboard.hectares.covered": "Hectares Couverts",
  "dashboard.new.company": "Nouvelles entreprises",
  "dashboard.flight.client": "Vols par client",
  "dashboard.coverage.graph": "Graphique de la zone de couverture",
  "dashboard.scale": "Échelle",
  "dashboard.graphmodeview": "Vu par",
  "dashboard.filter": "Filtre",
  "dashboard.time.30minutes": "30 minutes",
  "dashboard.time.1hour": "1 heure",
  "dashboard.time.8hours": "8 heures",
  "dashboard.time.1day": "1 jour",
  "dashboard.time.1week": "1 semaine",
  "dashboard.view.general": "-",
  "dashboard.view.profile": "Profil",
  "dashboard.view.client": "Client",
  "dashboard.view.drone": "Drone",

  //-- Menu Information --//
  "menu.about": "À propos de NCB",
  "menu.products": "Produits",
  "menu.solutions": "Solutions",
  "menu.pricing": "Tarifs",
  "menu.contact": "Contacter",
  "menu.privacy": "Politique de confidentialité",
  "menu.terms": "Termes et conditions",
  "menu.title": "Travail",
  "menu.support": "Aide",
  "menu.faq": "FAQ's",
  "menu.resources": "Outils",
  "menu.why": "Pourquoi bioHUB?",
  "menu.home": "Démarrer",
  "menu.biomaps": "bioMAPS",
  "menu.profile": "Profil",
  "menu.plans": "Des Plans",
  "menu.settings": "Les paramètres",
  "menu.open.project": "Projet Ouvert",
  "menu.new.project": "Nouveau Projet",
  "menu.new.monitoring": "Nouvelle Surveillance",
  "menu.flight.monitoring": "Surveiller le Vol",
  "menu.trap.monitoring": "Surveiller le Piège",
  "menu.biomapsInfo":
    "BioMAP visualise les zones où les contrôleurs biologiques sont libérés via le planificateur de mission.",
  "menu.licenses": "Licences",
  "menu.traps": "Pièges",

  //-- Generic Actions --//
  "action.usageChoose": "Sélectionner...",
  "action.ContactUs": "Je souhaite commander du matériel spécifique",
  "action.login": "Entrer",
  "action.signUp": "Ouvrir un compte",
  "action.subscribe": "Abonner",
  "action.register": "Ouvrir un compte",
  "action.makeRegistration": "Faites votre pré-inscription ici!",
  "action.success": "Succès",
  "action.error": "Pardon!",
  "action.msgSuccess":
    "Votre pré-inscription a réussi dans notre base de données! \nBientôt, nous vous enverrons un message prochainement ✓",
  "action.msgError":
    "Une erreur s'est produite lors de votre pré-inscription! Veuillez réessayer plus tard.",
  "action.back": "Revenir",
  "action.getStarted": "Démarrer",
  "action.learnMore": "Savoir plus",
  "action.learnMore.prices": "Connaître les plans et les prix",
  "action.more": "Plus d'information...",
  "action.keep.connected": "Restez connecté",
  "action.login.message": "Entrez vos coordonnées pour vous connecter",
  "action.login.msgError":
    "Erreur de connexion. Veuillez vérifier vos données ou réessayer dans un instant.",
  "action.continue": "Continuer",
  "action.accept": "je suis d'accord",
  "action.indicator": "Inscrivez-vous en tant que: ",
  "action.signup.company": "Compagnie",
  "action.signup.single": "Gens",
  "action.accept.signup.terms":
    "J'accepte les conditions générales de la loi sur la protection des données LGPD.",
  "action.pay.now": "Payez Maintenant",
  "action.add.new": "Ajouter un nouveau",
  "action.edit": "Éditer",
  "action.clean": "Nettoyer",
  "action.edit.picture": "Changer la photo",
  "action.edit.logo": "Changer la logo",
  "action.remove": "Ôter",
  "action.save": "Sauver",
  "action.add": "Ajouter",
  "action.cancel": "Annuler",
  "action.undo": "Défaire",
  "action.redo": "Refaire",
  "action.finish": "OK",
  "action.income": "Le revenu",
  "action.send": "Soumettre",
  "action.confirm": "Confirmer",
  "action.sendVerification": "Soumettre le code de vérification",
  "action.saveData": "Enregistrer des données",
  "action.settings": "Paramètres",
  "action.confirmAction": "Voulez-vous vraiment terminer cette action?",
  "action.warn": "Avertissement",
  "action.cart.add": "Article ajouté au panier.",
  "action.cart.remove": "Article retiré du panier.",
  "action.cart.changedQuantity": "Quantité modifiée.",

  //-- Placeholders --//
  "placeholder.profile": "Profil",
  "placeholder.email": "Votre email",
  "placeholder.name": "Votre nom",
  "placeholder.phone": "Votre téléphone",
  "placeholder.ladlineNumber": "Numéro de téléphone fixe",
  "placeholder.company": "Votre entreprise",
  "placeholder.isCompanyQuestion": "Est-ce que l'entreprise ?",
  "placeholder.data.collect": "Comment êtes-vous arrivé à NCB?",
  "placeholder.password": "Mot de passe",
  "placeholder.search": "Rechercher",
  "placeholder.iva": "VAT",
  "placeholder.company.name": "Nom de la compagnie",
  "placeholder.zip": "Code Postal",
  "placeholder.address": "Route",
  "placeholder.number": "Téléphone portable",
  "placeholder.cellphone": "Celular",
  "placeholder.complement": "Complément",
  "placeholder.country": "Parents",
  "placeholder.state.department": "Etat / Département",
  "placeholder.city": "Ville",
  "placeholder.street": "Rue",
  "placeholder.comercial.phone": "Téléphone de travail",
  "placeholder.aviation.reg": "Immatriculation aéronautique",
  "placeholder.agriculture.reg": "Registre agricole",
  "placeholder.role": "Occupation",
  "placeholder.document": "Document",
  "placeholder.rg": "RG",
  "placeholder.crea": "CREA",
  "placeholder.ie": "I.E.",
  "placeholder.im": "I.M.",
  "placeholder.trade.name": "Nome Comercial",
  "placeholder.type": "Type d'utilisateur",
  "placeholder.contact": "Contact",
  "placeholder.description": "Bio",
  "placeholder.subject": "Sujet",
  "placeholder.message": "Un message",
  "placeholder.project": "Projet",
  "placeholder.area": "Aire",
  "placeholder.aircraft": "Aéronef",
  "placeholder.code.country": "Code Postal",
  "placeholder.name.street": "Nom de la rue:",
  "placeholder.street.examples": "Appartement, unité, bâtiment, étage, etc.",
  "placeholder.phone.mask": "(000) 0000-0000",
  "placeholder.CPF.mask": "XXX.XXX.XXX-XX",
  "placeholder.CNPJ.mask": "XX.XXX.XXX/0001-XX",

  "placeholder.releaser.serialnumber": "Numéro de série",
  "placeholder.releaser.nickname": "Nom de famille",
  "placeholder.releaser.searchclient": "Entrez le nom du client",
  "placeholder.releaser.releasermodels": "Des modèles",
  "placeholder.releaser.tablemodel": "Modèle",
  "placeholder.releaser.tableserialnumber": "Numéro de série.",
  "placeholder.profile.select.picture": "Aucune image sélectionnée...",

  "placeholder.drone.selectclient": "Client...",
  "placeholder.drone.selectcpu": "CPU...",
  "placeholder.drone.selectmanufacturer": "Fabricant de drones...",
  "placeholder.drone.selectdronemodel": "Modèle de drone...",
  "placeholder.drone.clientcpu": "CPU cliente",

  "placeholder.settings.insufficientpermissions":
    "Vous ne disposez pas des autorisations suffisantes pour modifier ces paramètres.",

  "profile.account": "Mon Compte",
  "profile.profile": "Mon Profil",
  "profile.settings.updated": "Préférences de profil mises à jour",

  "profile.userTableComponent.selection.selected": "choisi",
  "profile.userTableComponent.selection.clear": "Effacer la sélection",
  "profile.userTableComponent.selection.remove": "Supprimer les données sélectionnées",

  //-- Labels --//
  "label.document.ID": "ID",
  "label.document.CPF": "CPF",
  "label.document.CNPJ": "CNPJ",
  "label.document.RG": "Inscription générale (RG)",
  "label.document.IM": "Enregistrement municipal",
  "label.name.company": "Nom de la compagnie",
  "label.trade.name": "Raison social",
  "label.direction": "Adresse",
  "label.code.postal": "Code Postal",
  "label.fullname": "Nom complet",
  "label.phone": "Téléphone fixe",
  "label.cellphone": "Téléphone portable",
  "label.select.country": "Choisissez un pays",

  //-- Options --//
  "option.preferences.defaultCurvePercentage": "Courbure (%)",
  "option.preferences.mustReleaseEntireArea":
    "Activer l'équipement dans le premier waypoint et désactiver dans le dernier",
  "option.preferences.generalinfo": "Informations générales",
  "option.preferences.unitsystem": "Système d'unité",
  "option.preferences.twofactorauth": "Authentification à deux facteurs",
  "option.preferences.sexagesimalcoordinates": "Utiliser les coordonnées sexagésimales",
  "option.preferences.separatelybatteries": "Afficher les piles séparément",
  "option.preferences.metric": "Métrique",
  "option.preferences.imperial": "Impérial",
  "option.search.engine": "Moteurs de recherche",
  "option.facebook": "Facebook",
  "option.linkedin": "Linkedin",
  "option.recommendation": "Recommandations",
  "option.email.marketing": "E-mail Marketing",
  "option.events": "Événement",
  "option.others": "Autres",
  "option.which": "Quels?",

  //-- Map --//
  "map.title": "Carte",
  "map.defaultRegion": "FR",
  "map.editMode.enterManualRoute": "Itinéraire Manuel",
  "map.editMode.editRoute": "Modifier l'itinéraire",
  "map.editMode.manualRoute":
    "Touchez la zone pour insérer un nouveau waypoint ou faites glisser ceux qui ont déjà été saisis pour modifier l'itinéraire. ",
  "map.editMode.addPolygon":
    "Touchez sur le plan pour ajouter un point. Appuyez longuement sur un marqueur pour le déplacer.",
  "map.editMode.error.theePointsMinimum": "Indiquez au moins trois points.",
  "map.editMode.error.nearbyPoints":
    "S'il vous plaît, chaque waypoint doit être à au moins 1 mètre des autres.",
  "map.editMode.error.interesection":
    "L'aire tracée contient un ou plusieurs intersections. Merci de tracer un polygone simple.",
  "map.editMode.reverseRoute": "Ruta inversa",
  "map.removeArea": "Suprimmer cette aire?",
  "map.newArea": "Nouvelle aire",
  "map.loadingList": "chargement...",
  "map.loadingFlights": "chargement...",
  "map.noFlightsToShow": "Aucun vol à afficher.",
  "map.editPolygonPoints": "Modifier polygone",
  "map.editPlan": "Modifier le plan de zone",
  "map.importRouteKml": "Importer un itinéraire KML",
  "map.goToArea": "Aller dans la Région",
  "map.replanRoute": "Générer un nouvel itinéraire",
  "map.editAreaProperties": "Paramètres",
  "map.deleteArea": "Supprimmer aire",
  "map.duplicateArea": "Créer une copie de zone",
  "map.kml.exportArea": "Exporter l'aire comme KML",
  "map.kml.exportRoute": "Exporter l'itinéraire comme KML",
  "map.kml.exportFlight": "Exporter le vol comme KML",
  "map.copyArea": "Zone de copie",
  "map.pasteCopiedArea": "Coller la zone copiée",
  "map.deletingArea": "Suppression aire",
  "map.deletingArea.question": "Procéder?",
  "map.deleteProject": "Supprimer le projet",
  "map.deletingProject": "Suppression du projet",
  "map.deletingProject.question": "Procéder?",
  "map.importAreas.importAreasAction": "Importer des aires",
  "map.newArea.createAreaModalTitle": "Créer nouvelle aire",
  "map.newArea.curveMode": "Mode Courbe",
  "map.newArea.curveMode.curved": "Incurvé",
  "map.newArea.curveMode.normal": "Ordinaire",
  "map.newArea.headingMode": "Mode Cap",
  "map.newArea.headingMode.nextWaypoint": "Prochain Waypoint",
  "map.newArea.headingMode.manual": "Manuel",
  "map.newArea.importAreaModalTitle": "Importer aires",
  "map.newArea.editProjectModalTitle": "Modifier le projet",
  "map.newRoute.importKmlModalTitle": "Importer un itinéraire KML",
  "map.newArea.areaName": "Nom de l'aire",
  "map.newProject.title": "Créer nouveau projet",
  "map.newProject.indirectClientName": "Client",
  "map.newProject.projectName": "Nom du projet",
  "map.newProject.optionalImportKml": "Importer KML",
  "map.newProject.optionalImportShapefile": "Importer Shapefile",
  "map.newProject.skipImportingFiles": "Dessiner l'aire manuellement",
  "map.newProject.kmlFileChoose": "Ouvrir...",
  "map.newProject.shapefileFileChoose": "Ouvrir...",
  "map.newProject.releaser": "Libérateur",
  "map.newProject.input": "Intrant",
  "map.newProject.releaseRate": "Taux de libération",
  "map.newProject.calibrationFactor": "Facteur d'étalonnage",
  "map.type.roadmap": "Par défault",
  "map.type.satellite": "Satellite",
  "map.type.hybrid": "Hybride",
  "map.newProject.instantaneousReleaseRate": "Taux de libération instantanée",
  "map.areasAmountSing": "{amount} aire",
  "map.areasAmountPl": "{amount} aires",
  "map.enableLabels": "Libellés",
  "map.closeProject": "Fermer le projet",
  "map.toggleVisibility": "Basculer la visibilité",
  "map.toggleVisibility.disabled": "Désactivé",
  "map.openFlightReport": "Ouvrir le rapport de vol",
  "map.moreOptions": "Plus d'options",

  "map.search": "Recherche d'adresse",
  "map.search.noOptions": "Aucune option d'adresse",

  "map.plannedRoutes.title": "Itinéraires planifiés de la Région",
  "map.plannedRoutes.flightAmount": "Nombre de Vols",
  "map.plannedRoutes.plannedData": "Date de Planification",
  "map.plannedRoutes.untilToday": " - Aujourd'hui",
  "map.plannedRoutes.flightDate": "Date de Vol",
  "map.plannedRoutes.termPerformedFlights": "Vols Effectués",
  "map.plannedRoutes.areaSize": "Taille de la Zone",
  "map.plannedRoutes.flightTime": "Temps de Vol",
  "map.plannedRoutes.viewingOldPlan":
    "Vous consultez un ancien plan de la zone. \nCliquez sur « Quitter » pour revenir à la vue du planning actuel. \nCliquez sur « Remplacer » pour remplacer l'horaire actuel par l'horaire que vous consultez actuellement.",
  "map.plannedRoute.select": "Remplacer",
  "map.planedRoute.exit": "Quitter",

  "map.footer.noProjectOpen": "Aucun projet ouvert",
  "map.footer.possibleCoverageArea": "Zone de couverture possible: {areaSize}",
  "map.footer.coveredArea": "Surface couverte: {areaSize} ({percentage}%)",

  "map.unlinkedFlights.unlinkedFlights": "Vols non liés",
  "map.unlinkedFlights.cpuSerialNumber": "Fichier de journal :",
  "map.unlinkedFlights.action.linked": "Lier à un vol",
  "map.unlinkedFlights.action.linked.for.prefix": "Lier le vol ",
  "map.unlinkedFlights.action.linked.for.sufix": " à la zone : ",
  "map.unlinkedFlights.modal.project": "Projet",
  "map.unlinkedFlights.modal.area": "Zone",
  "map.unlinkedFlights.modal.drone": "Drone",
  "map.unlinkedFlights.modal.cancel": "Annuler",
  "map.unlinkedFlights.modal.link": "Lier",

  //-- Route planning --//
  "routePlanning.notFoundReleaser": "Liberador não encontrado",

  "routePlanning.editWaypoint": "Libérateur non trouvé",
  "routePlanning.param.areaPadding": "Indentation de l'aire",
  "routePlanning.param.flightHeight": "Hauteur",
  "routePlanning.param.flightSpeed": "Vitesse",
  "routePlanning.param.flightSpeed.legend":
    "Vitesse à laquelle le drone accélérera pour atteindre entre les points de passage (étant donné qu'il est nécessaire de ralentir pour effectuer des virages, il ne sera pas nécessairement atteint)",
  "routePlanning.param.trackWidth": "Largeur de bande",
  "routePlanning.param.considerRelief": "Élévation online",

  "routePlanning.param.waypoint.height": "Hauteur",
  "routePlanning.param.waypoint.curveRadius": "Rayon de courbe",
  "routePlanning.param.waypoint.speed": "Vitesse",
  "routePlanning.param.waypoint.speed.legend":
    "Vitesse à laquelle le drone accélérera pour atteindre le prochain point de passage (étant donné qu'il est nécessaire de ralentir pour effectuer des virages, il ne sera pas nécessairement atteint)",
  "routePlanning.param.waypoint.orientation": "Orientation",

  "routePlanning.param.waypoint.actions": "Actions",
  "routePlanning.param.waypoint.action.enableEquipment": "Activer l'équipement",
  "routePlanning.param.waypoint.action.disableEquipment": "Désactiver l'équipement",
  "routePlanning.param.waypoint.action.none": "Rien",

  // -- Regions -- //
  "region.brazil": "Brésil",
  "region.europe": "L'Europe",
  "region.other": "Autre",

  //-- LANGUAGES --//
  language: "Langue",
  "language.pt": "Portugais brésilien",
  "language.es": "Espanol",
  "language.en": "Anglais",
  "language.fr": "Français",
  "language.name": "Français",
  "language.region": "FR",
  "language.decimalSeparator": ",",
  "language.country": "Brésil",

  //- Reports -//
  "report.input": "Saisir",
  "report.issueDate": "Date d'émission:",
  "report.area": "Area",
  "report.plannedFlight": "Vol planifié",
  "report.details": "Des détails",
  "report.dateHour": "Date et l'heure:",
  "report.drone": "Drone:",
  "report.manufacturer": "Fabricant:",
  "report.anac": "ANAC:",
  "report.releasedProduct": "Produit sorti:",
  "report.releasing": "Libérateur",
  "report.bandWidth": "Largeur de bande",
  "report.plannedSpeed": "Vitesse planifiée",
  "report.releaseRate": "Taux de sortie",
  "report.realFlight": "Vol réel",
  "report.flightTime": "Temps de vol",
  "report.averageReleaseRate": "Taux de sortie moyen",
  "report.averageSpeed": "Vitesse moyenne",
  "report.totalReleased": "Total publié",
  "report.totalArea": "Superficie totale",
  "report.traps": "Les pièges",
  "report.serialNumber": "Número de série",
  "report.coveredHectares": "Hectares couverts",
  "report.dateMeasurament": "Date de la dernière mesure",
  "report.specie": "Espèce",
  "report.adultsNumber": "Nombre d'adultes",
  "report.noAvailable": "Aucun article disponible",
  "report.company": "Société:",
  "report.mapRecord": "Enregistrement MAPA:",
  "report.contractor": "Prestataire:",
  "report.property": "Propriété:",
  "report.localization": "Emplacement:",
  "report.county": "Comté:",
  "report.uf": "UF:",
  "report.doc": "CNPJ/CPF:",
  "report.serviceType": "Type de service:",
  "report.product": "Produit",
  "report.formulation": "Formulation",
  "report.dosage": "Dosage",
  "report.toxicClass": "Classe Toxique",
  "report.adjuvant": "Adjuvant",
  "report.culture": "Culture: ",
  "report.volume": "Volume (litres or kg / ha):",
  "report.others": "Autres: ",
  "report.agronomicalRecipe": "Recette agronomique n:",
  "report.issuedOn": "Publié le:",
  "report.basicParameter": "Paramètres de base de l'application",
  "report.maxTemperature": "Température max.=",
  "report.relativeUnitMin": "Unité relative min.=",
  "report.equipment": "Équipement=",
  "report.model": "Modèle=",
  "report.type": "Type=",
  "report.angle": "Angle=",
  "report.flightHeight": "Hauteur de vol=",
  "report.bandwidth": "Largeur de bande (m)=",
  "report.date": "Date",
  "report.signatureCREA": "Nom, signature et CREA du professionnel",
  "report.weatherConditions": "Conditions météorologiques dans l'application",
  "report.morning": "Matin",
  "report.evening": "Soir",
  "report.applicationStart": "Début de l'application: heures et fin: heures",
  "report.initial": "Initiale",
  "report.final": "Final",
  "report.temperature": "Température",
  "report.relativeHumidity": "Umid. Relatif",
  "report.aircraftPrefixe": "Préfixe - Avion:",
  "report.dgpsUse": "Utilisation du DGPS: oui ou non",
  "report.windSpeed": "La vitesse Vent",
  "report.trackCoordinates": "Suivi des coordonnées:",
  "report.observation": "Noter:",
  "report.signatureEng": "Nom, signature et CREA de l'ingénieur Agronomo:",
  "report.print": "Imprimer",

  // Pre-registration research
  "preregistration.interest.question":
    "Selon vous, qu'est-ce qui est le plus important dans le\ncontrôle biologique de précision?",
  "preregistration.interest.financial": "Financière",
  "preregistration.interest.financial.cost": "Cout d'opération",
  "preregistration.interest.financial.savings": "Économies d'intrants",
  "preregistration.interest.financial.pest": "Réduction des parasites",
  "preregistration.interest.financial.production": "Augmentation de la production",

  "preregistration.interest.sustainability": "Durabilité",
  "preregistration.interest.sustainability.carbonfootprint": "Réduction de l'empreinte carbone",
  "preregistration.interest.sustainability.pesticides": "Réduction des pesticides",
  "preregistration.interest.sustainability.environment": "Environnement",
  "preregistration.interest.sustainability.water": "Économie d'eau",

  "preregistration.interest.reliability": "Fiabilité",
  "preregistration.interest.reliability.precision": "Précision",
  "preregistration.interest.reliability.releasingrate": "Taux de libération",
  "preregistration.interest.reliability.tracking": "Suivi d'itinéraire",
  "preregistration.interest.reliability.coverage": "Zone de couverture",

  // Notifications
  "notif.newmessages": "Nouveaux Messages",
  "notif.pendencies": "Pendants",
  "notif.error": "Les Échecs",
  "notif.success": "Confirmation",

  // Header dropdown
  "headerDropdown.logout": "Se Déconnecter",

  "map.projectTree.projects.loading": "Chargement des projets",
  "map.projectTree.projects.empty": "Liste de projets vide",

  "map.projectTree.areas.loading": "Chargement des zones de projet",
  "map.projectTree.areas.empty": "Liste des zones vides",

  "map.projectTree.flights.loading": "Vols de la zone de chargement",
  "map.projectTree.flights.empty": "Liste de vols vide",

  "map.projectTree.amountOfProjectsWithLoadedAreas": "Projets",

  "cpu.block.blocked.general": "Le CPU est bloqué. Veuillez contacter les administrateurs.",
  "cpu.block.blocked.master": "Le CPU est bloqué",
  "cpu.block.notBlocked.general": "CPU libéré pour utilisation",
  "cpu.block.notBlocked.master": "Le processeur n'est pas bloqué",
  "cpu.block.cpuTitle": "Blocs CPU {cpuSerialNumber}",
  "cpu.block.addBlock": "Ajouter un bloc",
  "cpu.block.reason": "Raison de blocage",
  "cpu.block.createdAt": "Créé à",
  "cpu.block.appliedAt": "Appliqué à",
  "cpu.block.notApplied": "Pas appliqué",
  "cpu.block.unblockedAt": "Débloqué à",
  "cpu.block.reason.default": "Problèmes de paiement",
  "cpu.block.reason.idleness": "Oisiveté",
  "cpu.block.reason.manually": "NCB",
  "cpu.block.removeBlock": "Débloquer le CPU",

  "biohubError.unimplemented.error": "Erreur inattendue.",
  "biohubError.unkown.error":
    "Erreur inconnue. S'il vous plait, vérifiez votre connexion internet.",
  "biohubError.internal.server.error": "Problèmes de connexion au serveur.",
  "biohubError.validation.error":
    "Données incompatibles. Veuillez actualiser cette page pour charger la dernière version du site.",
  "biohubError.login.incorrect.user.password":
    "Connexion : Nom d'utilisateur ou mot de passe incorrect.",
  "biohubError.preregister.email.already.registered": "Pré-inscription : E-mail déjà enregistré.",
  "biohubError.preregister.phone.number.already.registered":
    "Pré-inscription : Téléphone déjà enregistré.",
  "biohubError.login.token.not.received": "Erreur de connexion au système.",
  "biohubError.logion.unrecgnized.client":
    "Plate-forme non autorisée. Assurez-vous d'utiliser l'URL officielle.",
  "biohubError.project.not.found": "Projet introuvable.",
  "biohubError.elevation.invalid.request": "Erreur lors de l'obtention des données d'altitude.",
  "biohubError.elevation.over.daily.limit":
    "Limite quotidienne de demande de données d'altitude dépassée.",
  "biohubError.elevation.over.query.limit":
    "La quantité de données d'élévation demandées dépasse la limite.",
  "biohubError.elevation.request.denied": "Accès refusé aux données d'altitude.",
  "biohubError.elevation.unknown.error": "Erreur inattendue.",
  "biohubError.geocoder.unknown.error":
    "Erreur dans le processus d'obtention des données d'un emplacement.",
  "biohubError.sms.validation.error": "Erreur lors de l'envoi du SMS de validation.",
  "biohubError.kml.route.out.of.area.bounds": "L'itinéraire KML est en dehors des limites de la zone.",
  "biohubError.kml.import.invalid.selected.area.bounds": "Limites de zone non valides.",
  

  "purchase.order.intent.number": "Identifiant d'intention d'achat",
  "purchase.order.intent.detail": "Détails de l'intention d'achat",
  "purchase.order.intent.message": "Remarque supplémentaire",
  "purchase.order.intent.phone": "Téléphone",

  "purchase.order.intent.contactTitle": "Soumission d'intérêt pour l'achat",
  "purchase.order.intent.contactDescription":
    "Renseignez vos coordonnées dans le formulaire ci-dessous et attendez que nous vous contactions sous peu.",

  "purchase.order.intent.mail.error":
    "Échec de l'envoi des données d'intention d'achat. Veuillez réessayer !",
  "purchase.order.intent.mail.validation.error":
    "Remplissez toutes les données de formulaire requises",
  "purchase.order.intent.mail.success": "Intention d'achat envoyée avec succès",

  "purchase.order.mail.content.product": "Produit",
  "purchase.order.mail.content.quantity": "Quantité",
  "purchase.order.mail.content.unit.prices": "Prix unitaires d'achat",
};

export default messagesMap;
