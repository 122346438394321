import styled from "styled-components";

type Props = {
  isVisible: boolean;
  noSpace: boolean;
  color?: "light" | "dark" | null;
  rotate: boolean;
  width?: number;
  height?: number;
  position?: "relative";
  topShift?: number;
  leftShift?: number;
};

export const ModalStyle = styled.div.attrs((props: Props) => ({
  color: props.color,
  width: props.width,
  height: props.height,
  topShift: props.topShift,
  leftShift: props.leftShift,
}))`
  background: var(--dashboard-background);
  border-radius: 5px;
  display: inline-block;
  padding: 10px;
  position: absolute;
  width: 520px;

  ${(props) => (props.height !== undefined ? `height: ${props.height};` : `height: 400px;`)}

  ${(props) =>
    props.leftShift !== undefined
      ? `
    left: calc(50% - ${props.leftShift}px);
    `
      : `
    left: calc(50% - 260px);
    `}
  ${(props) =>
    props.topShift !== undefined
      ? `
    top: calc(50% - ${props.topShift}px);
    `
      : `
    top: calc(50% - 190px);
    `}

  .close-modal {
    position: absolute;
    min-width: 40px;
    right: 0;
    top: 11px;
    color: var(--dashboard-secondary);
  }

  .lock-modal-closed {
    position: absolute;
    min-width: 40px;
    right: 35px;
    top: 11px;
    color: #f00;
  }

  .lock-modal-open {
    position: absolute;
    min-width: 40px;
    right: 35px;
    top: 11px;
    color: #000;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--dashboard-hr);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    box-shadow: inset 5px 10px 12px var(--gray);
  }

  ${(props: Props) =>
    props.color === "dark" &&
    `
      background: var(--overlay-84);
      label { color: var(--white); }

      .MuiPopover-paper {
        background-color: #000000e0;
        color: white;
        border: 0.5px solid #ffffff40
      }

      .fields {
        fieldset {
          border-color: var(--white);
        }

        input {
          color: var(--white);
        }

        input:-internal-autofill-selected {
          background: var(--input-background) !important;
        }

        div label, p {
          color: var(--white);
        }

        .select-item {
          border-color: var(--white);
        }

        .MuiSwitch-track {
          background: var(--white);
        }

        .MuiButton-outlined {
          border: 1px solid var(--dashboard-secondary);
          border-radius: 50px;
          color: var(--dashboard-secondary);
          width: 100%;
          background: #6f310624;
          height: 40px;
        }

        .MuiInputBase-input {
          color: var(--white);
        }

        .select-dropdown legend {
          color: var(--white);
          visibility: unset !important;
        }

      }
  `}

  @media screen and (max-width: 580px) {
    max-width: 88vw;
  }
`;

export const ModalTitle = styled.label.attrs((props: Props) => ({}))`
  border-bottom: 1px solid var(--dashboard-separator-line);
  padding: 0 10px 5px;
  margin-bottom: 1rem;
  width: 100%;
  display: block;
  font-size: 18px;
`;
export const Actions = styled.div.attrs((props: Props) => ({
  className: "actions",
}))`
  border-top: 1px solid var(--dashboard-separator-line);
  padding: 15px 0 0;
  margin-top: 1rem;
  width: 100%;
  display: block;
  text-align: right;

  button {
    background: var(--dashboard-secondary);
    border-radius: 25px;
    min-width: 100px;

    &.add-area {
      background: transparent;
      color: var(--dashboard-highlighted);
      margin-top: -15px;
      margin-right: 4px;
      border: 0;
      .material-icons {
        margin: 5px 0 0 0;
        font-size: 15px;
      }
    }

    &.cancel-action {
      float: left;
      background: var(--dashboard-danger);
    }

    &.Mui-disabled {
      cursor: not-allowed;
    }

    &.import-area {
      margin: auto;
      height: 28px;
      display: flex;
      padding: 0 15px;

      .MuiButton-label {
        padding-bottom: 5px;
      }

      .MuiButton-label,
      .material-icons {
        color: var(--white) !important;
        margin-top: 3px;
      }

      &:hover,
      &:focus {
        background: var(--dashboard-highlighted);
      }
    }
  }
`;

export const Fields = styled.span.attrs((props: Props) => ({
  className: "fields",
}))`
  & label.Mui-focused {
    color: black;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: var(--highlighted);
    }
  }

  padding: 5px 10px;
  display: block;
  overflow-y: auto;
  max-height: 260px;

  .MuiTextField-root {
    width: 100%;
  }

  .MuiGrid-grid-xs-4 {
    .MuiTextField-root {
      margin-top: 5px;
    }
  }

  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    margin-bottom: 5px;
  }

  .switch-label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 25px;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
  }

  fieldset {
    border-color: var(--black);
  }

  input {
    border-radius: 25px;
    background: transparent;
    color: var(--black);
    padding: 8px 12px;
  }

  input:-internal-autofill-selected {
    background: var(--input-background) !important;
  }

  .MuiTypography-colorTextSecondary {
    font-size: 15px;
  }

  div label {
    color: var(--black);
    font-size: 15px;
  }

  .MuiFormControlLabel-root {
    float: left;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .actions {
    right: 10px;
    padding-top: 10px;
    bottom: 15px;
    position: absolute;
    width: 96%;
    border-color: var(--dashboard-separator-line);

    .MuiButton-contained.Mui-disabled {
      color: var(--dashboard-separator-title);
      box-shadow: none;
      background-color: var(--dashboard-table-header);
      padding-bottom: 5px;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--dashboard-hr);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    box-shadow: inset 5px 10px 12px var(--gray);
  }
`;

export const Attachment = styled.span.attrs((props: Props) => ({}))`
  width: 100%;
  display: flex;
  clear: both;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  em {
    font-size: 15px;
    font-weight: 600;
    padding-right: 10px;
  }

  label {
    flex: 1;
    .MuiButton-outlinedPrimary {
      width: 100%;
      background: var(--dashboard-secondary);
      color: white;
      padding-top: 4px;
      padding-bottom: 8px;
      border: 0;
    }
  }

  .MuiButton-outlinedPrimary {
    width: 100%;
    background: var(--dashboard-secondary);
    color: white;
    padding-top: 4px;
    padding-bottom: 8px;
    border: 0;
  }
`;

export const SelectItem = styled.label.attrs((props: Props) => ({
  className: "select-item",
}))`
  border: 1px solid;
  border-radius: 25px;
  padding-left: 12px;
  border-color: var(--black);
  line-height: 1;
  margin-top: 0px;
  height: 36px;
  display: block;
  position: relative;

  b {
    font-weight: 100;
    display: inline-block;
    height: 34px;
    vertical-align: baseline;
    padding-right: 10px;
    font-size: 15px;
  }

  em {
    padding-top: 8px;
    font-size: 15px;
    font-style: normal;
    display: inline-block;
    height: 34px;
    vertical-align: middle;
    width: calc(80% - 5px);
    font-family: "GalanoGrotesque-Medium";
  }

  > span {
    position: absolute;
    right: 0;
    border-radius: 25px;
    overflow: hidden;
    * {
      color: var(--black);
    }
  }
`;

export const Action = styled.div.attrs((props: Props) => ({}))`
  width: 100%;
  display: flex;
  clear: both;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;

  .cancel-button {
    background: transparent;
    color: var(--dashboard-secondary);
    float: left;
    justify-content: flex-start;
    align-items: baseline;
    .MuiButton-startIcon {
      align-self: normal;
      margin: 0;
      margin-top: 5px;
    }
  }
`;

//List
export const TableList = styled.div.attrs((props: Props) => ({}))`
  margin-bottom: 1rem;

  .MuiPaper-root {
    background: var(--card-background);
  }

  .table-header {
    background: var(--dashboard-table-header);
    border-radius: 4px 4px 0 0;
    font-family: "GalanoGrotesque-Semibold";
    text-transform: uppercase;
    letter-spacing: 0.35px;
    justify-content: space-between;

    button {
      padding: 0 10px;
      border-radius: 50px;
      color: var(--dashboard-menu-bg);
      padding-bottom: 5px;
      font-family: "GalanoGrotesque-SemiBold";
      font-weight: 600;
      margin-right: -20px;

      .material-icons {
        font-size: 20px;
        margin-top: 3px;
        margin-left: 3px;
      }
    }
  }

  svg,
  .material-icons {
    color: var(--dashboard-primary-bg);
    font-size: 22px;
    vertical-align: middle;
  }
`;

export const Image = styled.img.attrs((props: { angle: number }) => ({
  angle: props.angle,
}))`
  width: 32px;
  height: 32px;

  ${(props) =>
    props.angle !== undefined &&
    `
    transform: rotate(${props.angle}deg);
  `}
`;

export const Info = styled.span.attrs((props: Props) => ({}))`
  display: inline-block;
  color: var(--white);
  font-weight: 600;
  margin: 5px 5px 0;
  text-align: center;
  filter: drop-shadow(1px 1px 2px black);
}


`;
