import {
  FormControl,
  Grid,
  GridSize,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { getNumberCountry } from "../../../../services/FormValidatorsService";
import countries from "../../../../localization/numbersCountry";

export default function (props: {
  disabled?: boolean;
  fieldId: string;
  labelId: string;
  phone: string;
  paperClass: string;
  label: string;
  error?: boolean;
  onChange: (event: React.ChangeEvent<any>) => void;

  codeGridSize?: GridSize;
  inputGridSize?: GridSize;
  gridPaddingTop?: number;
  countryCodeStyle?: React.CSSProperties;
}): JSX.Element {
  const intl = useIntl();
  const numberAndCountry = getNumberCountry(props.phone);

  return (
    <>
      <Grid
        item
        lg={props.codeGridSize ?? 3}
        xs={props.codeGridSize ?? 3}
        style={{ paddingTop: props.gridPaddingTop ?? 12 }}
      >
        <FormControl variant="outlined" fullWidth>
          <InputLabel id={props.labelId + "-country"}>
            {intl.formatMessage({ id: "placeholder.country" }) + "*"}
          </InputLabel>
          <Select
            style={props.countryCodeStyle}
            disabled={props.disabled}
            labelId={props.labelId + "-number"}
            id={props.fieldId + "-number-code"}
            name={props.fieldId + "-number-code"}
            value={"+" + numberAndCountry.code}
            onChange={(e) => {
              props.onChange({
                ...e,
                target: {
                  ...e.target,
                  name: props.fieldId,
                  value: e.target.value + numberAndCountry.phoneNumber,
                },
              });
            }}
            label={intl.formatMessage({ id: "placeholder.country" }) + "*"}
            MenuProps={{ classes: { paper: props.paperClass } }}
          >
            {countries.map((v) => (
              <MenuItem key={v.countryCode} value={"+" + v.code}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${v.countryCode.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${v.countryCode.toLowerCase()}.png 2x`}
                  alt=""
                />
                {`  +${v.code}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={props.inputGridSize ?? 9} xs={props.inputGridSize ?? 9}>
        <TextField
          disabled={props.disabled}
          margin="dense"
          id={props.fieldId}
          name={props.fieldId}
          label={props.label}
          fullWidth
          variant="outlined"
          value={numberAndCountry.phoneNumber}
          error={props.error}
          onChange={(e) => {
            const value = e.target.value.trim().replaceAll("-", "");
            props.onChange({
              ...e,
              target: {
                ...e.target,
                name: props.fieldId,
                value: "+" + numberAndCountry.code + value,
              },
            });
          }}
        />
      </Grid>
    </>
  );
}
