import { PersistConfig } from "redux-persist/es/types";
import {
  ADD_ITEM_CART,
  ADD_EXTRA_ADDRESS,
  REMOVE_ITEM_CART,
  CLEAR_CART,
  CALCULATE_SUB_TOTAL,
  CALCULATE_TOTAL,
  ADD_DISCOUNT_CART,
  CLEAR_DISCOUNT_CART,
  ADD_INFO_COMPANY,
  ADD_INFO_REPRESENTATIVE,
  CLEAR_EXTRA_ADDRESS,
  UPDATE_AMOUNT,
  CALCULATE_TOTAL_DISCOUNT,
  ADD_ORDER_NUMBER,
} from "../actions/cartActions";
import { SystemAction } from "../actions/systemActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

type Address = {
  zipcode: string;
  street: string;
  complement: string;
  country: string;
  state: string;
  city: string;
};

export type ExtraAddress = {
  chargeAddress: Address;
  sendAddress: Address;
};

export type Company = {
  name: string;
  tradeName: string;
  document: string;
  address: Address;
  commercialPhoneNumber: string;
};

export type Representative = {
  nationality: string;
  name: string;
  email: string;
  cpf: string;
  idPerson: string;
  rg: string;
  im?: string;
  address: Address;
  landlineNumber: string;
  cellphoneNumber: string;
};

export type Item = {
  id: string;
  title: string;
  amount: number;
  price: number;
  isEquipment: boolean;
};

export type Discount = {
  coupon: string;
  value: number;
};

export type CartState = {
  key: "cart";
  orderNumber: number;
  company: Company;
  representative: Representative;
  extraAddress: ExtraAddress;
  items: Item[];
  subTotal: number;
  discount: Discount[];
  totalDiscount: number;
  total: number;
  tax: number;
  isEquipment: boolean;
};

const INITIAL_STATE: CartState = {
  key: "cart",
  orderNumber: 0,
  company: {
    name: "",
    tradeName: "",
    document: "",
    address: {
      zipcode: "",
      street: "",
      complement: "",
      country: "",
      state: "",
      city: "",
    },
    commercialPhoneNumber: "",
  },
  representative: {
    nationality: "",
    name: "",
    email: "",
    cpf: "",
    idPerson: "",
    rg: "",
    im: "",
    address: {
      zipcode: "",
      street: "",
      complement: "",
      country: "",
      state: "",
      city: "",
    },
    landlineNumber: "",
    cellphoneNumber: "",
  },
  extraAddress: {
    chargeAddress: {
      zipcode: "",
      street: "",
      complement: "",
      country: "",
      state: "",
      city: "",
    },
    sendAddress: {
      zipcode: "",
      street: "",
      complement: "",
      country: "",
      state: "",
      city: "",
    },
  },
  items: [],
  subTotal: 0,
  discount: [],
  totalDiscount: 0,
  total: 0,
  tax: 0,
  isEquipment: false,
};

// Reducer
export function cartReducer(state = INITIAL_STATE, action: SystemAction): CartState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  switch (action.type) {
    case ADD_INFO_COMPANY:
      return {
        ...effectiveState,
        company: action.payload.company,
      };
    case ADD_INFO_REPRESENTATIVE:
      return {
        ...effectiveState,
        representative: action.payload.representative,
      };
    case ADD_ORDER_NUMBER:
      return {
        ...effectiveState,
        orderNumber: action.payload.orderNumber,
      };
    case ADD_EXTRA_ADDRESS:
      return {
        ...effectiveState,
        extraAddress:
          action.payload.type === "chargeAddress"
            ? {
                ...effectiveState.extraAddress,
                chargeAddress: action.payload.extraAddress.chargeAddress,
              }
            : {
                ...effectiveState.extraAddress,
                sendAddress: action.payload.extraAddress.sendAddress,
              },
      };
    case CLEAR_EXTRA_ADDRESS:
      return {
        ...effectiveState,
        extraAddress:
          action.payload.type === "chargeAddress"
            ? {
                ...effectiveState.extraAddress,
                chargeAddress: INITIAL_STATE.extraAddress.chargeAddress,
              }
            : {
                ...effectiveState.extraAddress,
                sendAddress: INITIAL_STATE.extraAddress.sendAddress,
              },
      };
    case ADD_ITEM_CART:
      return {
        ...effectiveState,
        items: action.payload.item,
      };
    case UPDATE_AMOUNT:
      return {
        ...effectiveState,
        items: effectiveState.items.map((i) =>
          i.id === action.payload.id ? { ...i, amount: action.payload.amount } : i
        ),
      };
    case CALCULATE_SUB_TOTAL:
      return {
        ...effectiveState,
        subTotal: action.payload.subTotal,
      };
    case CALCULATE_TOTAL:
      return {
        ...effectiveState,
        total: action.payload.total,
      };
    case ADD_DISCOUNT_CART:
      return {
        ...effectiveState,
        discount: [...effectiveState.discount, action.payload.discount],
      };
    case CALCULATE_TOTAL_DISCOUNT:
      return {
        ...effectiveState,
        totalDiscount: action.payload.totalDiscount,
      };
    case REMOVE_ITEM_CART:
      return {
        ...effectiveState,
        items: effectiveState.items.filter((i) => i.title !== action.payload.id),
      };
    case CLEAR_DISCOUNT_CART:
      return {
        ...effectiveState,
        discount: INITIAL_STATE.discount,
        totalDiscount: INITIAL_STATE.totalDiscount,
      };
    case CLEAR_CART:
      return {
        ...effectiveState,
        items: INITIAL_STATE.items,
        subTotal: INITIAL_STATE.subTotal,
        total: INITIAL_STATE.total,
        discount: INITIAL_STATE.discount,
        totalDiscount: INITIAL_STATE.totalDiscount,
      };
    default:
      return effectiveState;
  }
}

export type CartPersistedState = CartState;

const cartPersistConfig: PersistConfig<CartState> = {
  key: "cart",
  storage,
  whitelist: [
    "key",
    "orderNumber",
    "company",
    "representative",
    "extraAddress",
    "items",
    "subTotal",
    "discount",
    "totalDiscount",
    "total",
    "tax",
    "isEquipment",
  ],
};

export const cartPersistedReducer = persistReducer(cartPersistConfig, cartReducer);
