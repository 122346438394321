import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    `
    border-color: #1d4f90;
    color: #1d4f90;
    `}
`;

const BoldText = styled.span`
  font-size: 2rem;
  font-weight: bold;
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
}

export default function CartCard({ children, isActive, ...rest }: Props) {
  return (
    <>
      <StyledButton isActive={isActive} {...rest}>
        {typeof children === "string" ? <BoldText>{children}</BoldText> : children}
      </StyledButton>
    </>
  );
}
