import React from "react";
import { useIntl } from "react-intl";
import ProductCard from "../ProductCard";
import Button from "../../BasicComponents/Button";
import Clickable from "../../BasicComponents/Link";
import Text from "../../BasicComponents/Text";
import { Actions, Block, Description } from "../ProductCard/styles";
import { Carousel } from "./styles";
import { Item } from "../SimpleCarousel/styles";
interface Props {
  items: Array<{
    id: string;
    product_id?: string;
    title: string;
    description: string;
    price: string;
    image: string | "url";
    link?: string;
  }>;
  color?: "primary";
  showButtons?: boolean | true;
  flewFlow?: "column" | "nowrap";
}

export default (props: Props) => {
  const intl = useIntl();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {props.items.length > 0 && (
        <Carousel responsive={responsive} color={props.color} flewFlow={props.flewFlow}>
          {props.items.map((item: any) => (
            <ProductCard
              key={item.id}
              productKey={item.product_id}
              title={item.title}
              description={item.description}
              image={item.image}
              showButtons={props.showButtons}
              link={item.link}
            />
          ))}
        </Carousel>
      )}

      <Actions
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Clickable to={"/contact"}>
          <Button
            text={intl.formatMessage({ id: "action.ContactUs" })}
            appearance={"button-outline"}
            color={"inherit"}
            size={"medium"}
          />
        </Clickable>
      </Actions>
    </>
  );
};
