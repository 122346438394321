import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Dropdown from "@material-ui/core/Select";

type Props = {
  visible: boolean;
};

export const Block = styled(FormControl).attrs((props: Props) => ({}))`
  position: relative;
  display: inline-block;

  .MuiFormControl-root,
  .MuiInputBase-root {
    border-radius: 25px !important;
  }
`;

export const Label = styled(InputLabel).attrs((props: Props) => ({}))``;

export const Option = styled(MenuItem).attrs((props: Props) => ({}))``;

export const Select = styled(Dropdown).attrs((props: Props) => ({}))`
  > div {
    border-radius: 50px;
    outline: none;
    margin: 0;
  }
  label {
    width: 90%;
    text-align: center;
  }
  label.MuiInputLabel-shrink,
  label.MuiSelect-select.MuiSelect-selectMenu {
    text-align: left;
    width: auto;
  }
`;

export const TextBox = styled.span.attrs((props: Props) => ({
  visible: props.visible,
}))`
  display: none;
  ${(props) =>
    props.visible === true &&
    `
    display: inline-block;
    position: absolute;
    right: 30px;
    width: 75%;
    display: inline-block;
    top: -2px;
    
    
    > div {
      width: 100%;
      border-color: transparent;
      .MuiOutlinedInput-marginDense {
        border-color: transparent;
        border-radius: 0;
      }
      fieldset {
        border: 0;
        border-left: 1px solid rgba(var(--input-border), 0.75);
      }
      input {
        background: transparent;
        color: var(--white);
      }
      label {
        color: var(--white) !important;
      }
      label.MuiInputLabel-shrink {
        display: none;
      }
    }

      @media screen and (max-width: 680px) {
        width: 58%;
        top: -1px;
      }

      @media screen and (max-width: 480px) {
        width: 65%;
      }

    `}
`;
