import { CompletePlannedArea, Location } from "biohub-model";
import React from "react";
import _ from "lodash";
import { MemoPolygon } from "../MemoMapComponents";

type PolygonForAreaProps = {
  polygonId: string;
  isSelected: boolean;
  points: Location[];
  isLoading?: boolean;
  onPolygonClicked?: (location: Location) => void;
  showBorders?: false;
  zIndex: number;
};

const _PolygonForArea = function (props: PolygonForAreaProps): JSX.Element {
  const isLoading = props.isLoading ?? false;
  const { polygonId, isSelected, points } = props;

  const showBordersStrokeColorOpacity = props.showBorders === false ? "0" : "1";

  return (
    <MemoPolygon
      key={`${polygonId}-polygon`}
      path={points.map((point) => ({ lat: point.lat, lng: point.lng }))}
      options={{
        strokeColor: isLoading
          ? "rgba(60, 60, 60,0.6)"
          : isSelected
          ? `rgba(128, 222, 234, ${showBordersStrokeColorOpacity})`
          : `rgba(239, 83, 80, ${showBordersStrokeColorOpacity})`,
        fillColor: isLoading
          ? "rgba(60, 60, 60,0.6)"
          : isSelected
          ? "rgba(128, 222, 234)"
          : "rgba(239, 83, 80)",
        fillOpacity: isLoading ? 0.8 : isSelected ? 0.4 : 0.5,
        zIndex: props.zIndex,
      }}
      onClick={
        !isLoading && props.onPolygonClicked !== undefined
          ? (e) => {
              const latLng = e.latLng;
              if (latLng === null) return;

              props.onPolygonClicked!({
                lat: latLng.lat(),
                lng: latLng.lng(),
              });
            }
          : undefined
      }
    />
  );
};

const PolygonForArea = React.memo(_PolygonForArea, (prevProps, newProps) => {
  if (prevProps.isSelected !== newProps.isSelected) return false;
  if (!_.isEqual(prevProps.points, newProps.points)) return false;
  if (prevProps.onPolygonClicked !== newProps.onPolygonClicked) return false;
  return true;
});

export default PolygonForArea;
