/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box } from "@material-ui/core";
import { Area, Location, Project, Waypoint } from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import BaseMap from "../../components/map/BaseMap";
import {
  copyArea,
  createAreaDrawingArea,
  createAreaDuplicate,
  createAreas,
  createProject,
  createProjectDrawingArea,
  editAreaParameters,
  initMap,
  loadProjects,
  onMapBoundsChanged,
  onMapCenterChanged,
  onMapZoomChanged,
  pasteCopiedArea,
  processImportedRoute,
  updateArea,
  updateDiagonalScreenSize,
  updateEditingArea,
  updateProject,
} from "../../store/actions/projectTreeActions";
import {
  castProjectInProjectTreeToProject,
  EditingArea,
  EditingAreaType,
} from "../../store/reducers/projectTreeReducer";
import { SystemState } from "../../store/reducers/systemReducer";
import UserLocationOnTheMap from "./mapComponents/UserLocationOnTheMap";
import CreationModal, { CreationModalProps } from "./modals/CreationModalComponents";
import DeleteAreaModal from "./modals/DeleteAreaModal";
import DeleteProjectModal from "./modals/DeleteProjectModal";
import EditWaypointModal from "./modals/EditWaypointModal";
import PlannedRoutes from "./modals/PlannedRoutes";
import OpenedAreaActionsOverlay from "./overlays/OpenedAreaActionsOverlay";
import OverlayGroup from "./overlays/OverlayGroup";
import ProjectOverlay from "./overlays/ProjectOverlay";
import SpecialAreaPlanActionsOverlay from "./overlays/SpecialAreaPlanActionsOverlay";
import LoadingAreasOverLay from "./overlays/LoadingAreasOverLay";
import { Map } from "./styles";
import ProjectsOnTheMap from "./mapComponents/ProjectsOnTheMap";
import { BiohubError } from "../../services/axios/BiohubApi";

export default function (): JSX.Element {
  const elementsZIndexes = {
    defaultAreaPolygonZIndex: 0,
    selectedAreaPoygonZIndex: 1,
    areaEditingPolygonPolylineZIndex: 1,
    areaEditingPolygonMarkersZIndex: 2,

    plannedPathZIndex: 3,
    plannedPathBorderZIndex: 2,

    userLocationZIndex: 4,

    waypointsDistanceMarkerZIndex: 5,
    waypointsDistanceLabelZIndex: 6,

    waypointOrientationMarkerZIndex: 7,
    waypointDropMarkerZIndex: 8,

    waypointElevationMarkerZIndex: 9,
    waypointElevationLabelZIndex: 10,

    homePointMarkerZIndex: 11,
    homePointElevationMarkerZIndex: 12,
    homePointElevationLabelZIndex: 13,

    areaSummaryMarkerZIndex: 14,
    projectSummaryMarkerZIndex: 15,

    getFlightReleaseTrackZIndex: (flightIndex: number) =>
      elementsZIndexes.projectSummaryMarkerZIndex + 3 * flightIndex + 1,
    getFlightPathZIndex: (flightIndex: number) =>
      elementsZIndexes.projectSummaryMarkerZIndex + 3 * flightIndex + 3,
    getFlightPathBorderZIndex: (flightIndex: number) =>
      elementsZIndexes.projectSummaryMarkerZIndex + 3 * flightIndex + 2,
  };

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const width = dimensions.width;
  const height = dimensions.height;

  const updateWidthAndHeight = () => {
    if (window.innerHeight !== dimensions.height || window.innerWidth !== dimensions.width)
      setDimensions({ height: window.innerHeight, width: window.innerWidth });
  };

  const [pressingCtrlKey, setPressingCtrlKey] = useState(false);
  const [multipleWaypointEditionSelection, setMultipleWaypointEditionSelection] = useState<
    { areaId: string; waypoints: (Waypoint & { index: number })[] } | undefined
  >(undefined);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Control") setPressingCtrlKey(true);
  };
  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === "Control") setPressingCtrlKey(false);
  };

  useEffect(() => {
    updateWidthAndHeight();
  });

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("resize", updateWidthAndHeight);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProjects());
  }, []);

  const _initialOverlayExpansion: OverlayExpansionType = {
    isExpanded: false,
    expansionMoment: 0,
  };
  const [overlaysExpansion, _setOverlaysExpansion] = useState<OverlaysExpansionType>({
    projectTree: _initialOverlayExpansion,
    mapControls: _initialOverlayExpansion,
    footer: _initialOverlayExpansion,
    weather: _initialOverlayExpansion,
  });

  const _getElementExpansion = (elementType: keyof OverlaysExpansionType): OverlayExpansionType => {
    return overlaysExpansion[elementType];
  };

  const _getElementExpansionAndDecreaseExpansionMoment = (
    elementType: keyof OverlaysExpansionType
  ): OverlayExpansionType => {
    const element = _getElementExpansion(elementType);

    if (element.isExpanded) {
      return {
        isExpanded: element.isExpanded,
        expansionMoment: element.expansionMoment + 1,
      };
    }

    return element;
  };

  const _setIsExpandedElementSelectIsTargetElementOrWitchStateElementWeAreUsing = (
    processingElementType: keyof OverlaysExpansionType,
    processingElement: OverlayExpansionType,
    targetElementType: keyof OverlaysExpansionType
  ): OverlayExpansionType => {
    if (targetElementType === processingElementType) return processingElement;

    if (processingElement.isExpanded) {
      return _getElementExpansionAndDecreaseExpansionMoment(targetElementType);
    }

    return _getElementExpansion(targetElementType);
  };

  const _setIsExpandedElement = (
    elementType: keyof OverlaysExpansionType,
    value: boolean
  ): OverlaysExpansionType => {
    const previousElement = _getElementExpansion(elementType);
    const newElement: OverlayExpansionType = {
      isExpanded: value,
      expansionMoment: value ? 0 : previousElement.expansionMoment,
    };

    const projectTree = _setIsExpandedElementSelectIsTargetElementOrWitchStateElementWeAreUsing(
      elementType,
      newElement,
      "projectTree"
    );
    const mapControls = _setIsExpandedElementSelectIsTargetElementOrWitchStateElementWeAreUsing(
      elementType,
      newElement,
      "mapControls"
    );
    const footer = _setIsExpandedElementSelectIsTargetElementOrWitchStateElementWeAreUsing(
      elementType,
      newElement,
      "footer"
    );
    const weather = _setIsExpandedElementSelectIsTargetElementOrWitchStateElementWeAreUsing(
      elementType,
      newElement,
      "weather"
    );

    return {
      projectTree: projectTree,
      mapControls: mapControls,
      footer: footer,
      weather: weather,
    };
  };

  const _getOpenedOverlays = (
    overlaysExpansion: OverlaysExpansionType
  ): { project: boolean; mapControls: boolean; footer: boolean; weather: boolean } => {
    return {
      project: overlaysExpansion.projectTree.isExpanded,
      mapControls: overlaysExpansion.mapControls.isExpanded,
      footer: overlaysExpansion.footer.isExpanded,
      weather: overlaysExpansion.weather.isExpanded,
    };
  };

  const _lastOpenedOverlay = (
    overlaysExpansion: OverlaysExpansionType
  ): keyof OverlaysExpansionType => {
    const elements: (OverlayExpansionType & { elementType: keyof OverlaysExpansionType })[] = [];
    const keys = Object.keys(overlaysExpansion);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i] as keyof OverlaysExpansionType;
      const overlayExpansion = overlaysExpansion[key];
      elements.push({
        elementType: key,
        isExpanded: overlayExpansion.isExpanded,
        expansionMoment: overlayExpansion.isExpanded ? overlayExpansion.expansionMoment : 9999,
      });
    }

    elements.sort((a, b) => {
      if (a.expansionMoment > b.expansionMoment) return 1;
      else if (a.expansionMoment === b.expansionMoment) return 0;
      return -1;
    });

    return elements[0].elementType;
  };

  const _closeOverlay = (
    elementType: keyof OverlaysExpansionType,
    overlaysExpansion: OverlaysExpansionType
  ): OverlaysExpansionType => {
    return {
      ...overlaysExpansion,
      [elementType]: {
        ...overlaysExpansion[elementType],
        isExpanded: false,
      },
    };
  };

  const _processElementExpansionAccordingScreenSize = (
    _overlaysExpansion: OverlaysExpansionType
  ): OverlaysExpansionType => {
    let overlaysExpansion = {
      ..._overlaysExpansion,
    };

    /**
     * Here we need to control which elements are expanded or not according the actual screen size.
     *
     * Those are the rules:
     *
     * - Small screen width:
     *    1. Only map controls and weather overlays can stay open at the same time
     * - Medium screen width:
     *    1. When the footer opens, the map controls and project tree need to be close
     *    2. When the project tree or the map controls open, the footer need to be close
     * - Small height:
     *    1. Weather and map controls can't be open at the same time
     */

    const openedOverlays = _getOpenedOverlays(overlaysExpansion);
    if (width < smallScreenWidth) {
      let amountOfOpenedOverlaysConsideringWeatherAndMapControlsAsOne = 0;
      if (openedOverlays.project) {
        amountOfOpenedOverlaysConsideringWeatherAndMapControlsAsOne++;
      }
      if (openedOverlays.footer) {
        amountOfOpenedOverlaysConsideringWeatherAndMapControlsAsOne++;
      }
      if (openedOverlays.mapControls || openedOverlays.weather) {
        amountOfOpenedOverlaysConsideringWeatherAndMapControlsAsOne++;
      }

      if (amountOfOpenedOverlaysConsideringWeatherAndMapControlsAsOne > 1) {
        const lastOpenedOverlay = _lastOpenedOverlay(overlaysExpansion);
        if (lastOpenedOverlay === "mapControls" || lastOpenedOverlay === "weather") {
          overlaysExpansion = _closeOverlay("footer", overlaysExpansion);
          overlaysExpansion = _closeOverlay("projectTree", overlaysExpansion);
        } else if (lastOpenedOverlay === "footer") {
          overlaysExpansion = _closeOverlay("weather", overlaysExpansion);
          overlaysExpansion = _closeOverlay("mapControls", overlaysExpansion);
          overlaysExpansion = _closeOverlay("projectTree", overlaysExpansion);
        } else {
          overlaysExpansion = _closeOverlay("weather", overlaysExpansion);
          overlaysExpansion = _closeOverlay("mapControls", overlaysExpansion);
          overlaysExpansion = _closeOverlay("footer", overlaysExpansion);
        }
      }
    } else if (width < mediumScreenWidth) {
      const lastOpenedOverlay = _lastOpenedOverlay(overlaysExpansion);
      if (lastOpenedOverlay === "footer") {
        overlaysExpansion = _closeOverlay("projectTree", overlaysExpansion);
        overlaysExpansion = _closeOverlay("mapControls", overlaysExpansion);
      } else if (lastOpenedOverlay === "projectTree" || lastOpenedOverlay === "mapControls") {
        overlaysExpansion = _closeOverlay("footer", overlaysExpansion);
      }
    }

    if (height < smallScreenHeight) {
      if (overlaysExpansion.mapControls.isExpanded && overlaysExpansion.weather.isExpanded) {
        if (
          overlaysExpansion.mapControls.expansionMoment < overlaysExpansion.weather.expansionMoment
        ) {
          overlaysExpansion = _closeOverlay("weather", overlaysExpansion);
        } else {
          overlaysExpansion = _closeOverlay("mapControls", overlaysExpansion);
        }
      }
    }

    return overlaysExpansion;
  };

  useEffect(() => {
    _setOverlaysExpansion(_processElementExpansionAccordingScreenSize(overlaysExpansion));
    dispatch(updateDiagonalScreenSize({ width, height }));
  }, [height, width]);

  const setIsExpandedProjectTree = (value: boolean) => {
    const expansionResult = _setIsExpandedElement("projectTree", value);

    _setOverlaysExpansion(_processElementExpansionAccordingScreenSize(expansionResult));
  };

  const setIsExpandedMapControls = (value: boolean) => {
    const expansionResult = _setIsExpandedElement("mapControls", value);

    _setOverlaysExpansion(_processElementExpansionAccordingScreenSize(expansionResult));
  };

  const setIsExpandedFooterOverlay = (value: boolean) => {
    const expansionResult = _setIsExpandedElement("footer", value);

    _setOverlaysExpansion(_processElementExpansionAccordingScreenSize(expansionResult));
  };

  const setIsExpandedWeatherOverlay = (value: boolean) => {
    const expansionResult = _setIsExpandedElement("weather", value);

    _setOverlaysExpansion(_processElementExpansionAccordingScreenSize(expansionResult));
  };

  const [modalController, setModalController] = useState<MapModalController | undefined>(undefined);

  const intl = useIntl();

  const mapState = useSelector((state: SystemState) => state.projectTree.mapState);

  const editingAreaState = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    return projectTreeState.editingArea;
  });

  const openedOverlays = _getOpenedOverlays(overlaysExpansion);

  return (
    <Map color="dark">
      {/** Workaround - normally the height considers the toolbar's height, and then the map overflows. */}
      <div style={{ height: "100%", position: "relative" }}>
        <BaseMap
          region={intl.formatMessage({ id: "map.defaultRegion" })}
          languageCode={intl.locale}
          mapTypeId={mapState.mapTypeId}
          onInitialized={(controller) => {
            dispatch(initMap(controller));
          }}
          onClick={(location) => {
            if (editingAreaState === undefined) return;

            const newEditingArea = JSON.parse(JSON.stringify(editingAreaState)) as EditingArea;

            if (
              pressingCtrlKey &&
              (newEditingArea.type === EditingAreaType.EditingPlanPoints ||
                newEditingArea.type === EditingAreaType.EditingEverything)
            ) {
              newEditingArea.homePoint = location;
            } else if (
              newEditingArea.type === EditingAreaType.CreatingProject ||
              newEditingArea.type === EditingAreaType.DrawingNewArea ||
              newEditingArea.type === EditingAreaType.EditingPolygon ||
              newEditingArea.type === EditingAreaType.EditingEverything
            ) {
              newEditingArea.polygon.push(location);
            } else {
              return;
            }

            dispatch(updateEditingArea(newEditingArea));
          }}
          onZoomChanged={() => {
            dispatch(onMapZoomChanged());
          }}
          onCurrentCenterChanged={(mapCenter) => {
            dispatch(onMapCenterChanged(mapCenter));
          }}
          onMapBoundsChanged={(mapBounds) => {
            dispatch(onMapBoundsChanged(mapBounds));
          }}
        >
          <ProjectsOnTheMap
            {...elementsZIndexes}
            selectedWaypointsAreaId={multipleWaypointEditionSelection?.areaId}
            selectedWaypointIndexes={multipleWaypointEditionSelection?.waypoints.map(
              (waypoint) => waypoint.index
            )}
            pressingCtrlKey={pressingCtrlKey}
            onClickWaypoint={(area, waypoint, waypointIndex) => {
              if (pressingCtrlKey) {
                let selectedWaypoints = multipleWaypointEditionSelection?.waypoints ?? [];
                /// Clear the selection because it's a new area
                if (area.id !== multipleWaypointEditionSelection?.areaId) {
                  selectedWaypoints = [];
                }

                const indexInSelection = selectedWaypoints.findIndex(
                  (waypoint) => waypoint.index === waypointIndex
                );
                /// The user is removing a waypoint selection
                if (indexInSelection >= 0) {
                  selectedWaypoints.splice(indexInSelection, 1);
                }
                /// The user is selecting waypoint
                else {
                  selectedWaypoints.push({
                    ...waypoint,
                    index: waypointIndex,
                  });
                }

                setMultipleWaypointEditionSelection({
                  areaId: area.id,
                  waypoints: selectedWaypoints,
                });
              } else {
                const isASelectedWaypoint =
                  multipleWaypointEditionSelection?.areaId === area.id &&
                  multipleWaypointEditionSelection.waypoints.find(
                    (waypoint) => waypoint.index === waypointIndex
                  ) !== undefined;
                if (isASelectedWaypoint) {
                  setModalController({
                    type: MapModalControllerType.editingWaypoints,
                    area: area,
                    waypoints: multipleWaypointEditionSelection!.waypoints,
                  });
                } else {
                  setModalController({
                    type: MapModalControllerType.editingWaypoint,
                    area: area,
                    waypoint: {
                      ...waypoint,
                      index: waypointIndex,
                    },
                  });
                }
              }
            }}
          />
          <UserLocationOnTheMap zIndex={elementsZIndexes.userLocationZIndex} />
        </BaseMap>

        {/** Left drawer */}
        <ProjectOverlay
          height={height}
          expanded={openedOverlays.project}
          setExpanded={setIsExpandedProjectTree}
          onClickToCreateProject={() => {
            setModalController({
              type: MapModalControllerType.createProject,
            });
          }}
          onClickToEditProjectSettings={(project) => {
            setModalController({
              type: MapModalControllerType.editProject,
              project: castProjectInProjectTreeToProject(project),
            });
          }}
          onClickToAddAreaInProject={(project) => {
            setModalController({
              type: MapModalControllerType.createArea,
              project: castProjectInProjectTreeToProject(project),
            });
          }}
          onClickToDeleteProject={(project) => {
            setModalController({
              type: MapModalControllerType.deleteProject,
              project: castProjectInProjectTreeToProject(project),
            });
          }}
          onClickToEditAreaSettings={(area) => {
            setModalController({
              type: MapModalControllerType.editArea,
              area: area,
            });
          }}
          onClickToDeleteArea={(area) => {
            setModalController({
              type: MapModalControllerType.deleteArea,
              area: area,
            });
          }}
          onClickDuplicateArea={(area) => {
            dispatch(createAreaDuplicate(area, intl));
          }}
          onClickCopyArea={(area) => {
            dispatch(copyArea(area));
          }}
          onClickPasteCopiedArea={(project) => {
            dispatch(pasteCopiedArea(project.id));
          }}
        />

        {/** Map overlays */}
        <Box
          style={{
            position: "absolute",
            backgroundColor: "#ffffff00",
            top: -8,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none", // Let all clicks through
          }}
          flexDirection="column"
          justifyContent="space-between"
        >
          {((): JSX.Element => {
            if (modalController?.type === MapModalControllerType.editingWaypoint) {
              return (
                <EditWaypointModal
                  projectId={modalController.area.projectId}
                  areaId={modalController.area.id}
                  plannedArea={modalController.area.planned}
                  releasersConfiguration={modalController.area.configuredReleasers}
                  onFinish={() => setModalController(undefined)}
                  onClose={() => setModalController(undefined)}
                  type="single"
                  waypoint={modalController.waypoint}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.editingWaypoints) {
              return (
                <EditWaypointModal
                  projectId={modalController.area.projectId}
                  areaId={modalController.area.id}
                  plannedArea={modalController.area.planned}
                  releasersConfiguration={modalController.area.configuredReleasers}
                  onFinish={() => {
                    setMultipleWaypointEditionSelection(undefined);
                    setModalController(undefined);
                  }}
                  onClose={() => setModalController(undefined)}
                  type="multiple"
                  waypoints={modalController.waypoints}
                />
              );
            }

            if (modalController?.type === MapModalControllerType.selectPlannedRoute) {
              return (
                <PlannedRoutes
                  projectId={modalController.projectId}
                  areaId={modalController.areaId}
                  onClose={() => setModalController(undefined)}
                />
              );
            }

            if (modalController?.type === MapModalControllerType.deleteProject) {
              return (
                <DeleteProjectModal
                  project={modalController.project}
                  onCancel={() => setModalController(undefined)}
                  onFinish={() => setModalController(undefined)}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.deleteArea) {
              return (
                <DeleteAreaModal
                  area={modalController.area}
                  onCancel={() => setModalController(undefined)}
                  onFinish={() => setModalController(undefined)}
                />
              );
            }

            if (modalController?.type === MapModalControllerType.createProject) {
              return (
                <CreationModal
                  mode={"new-project"}
                  onClose={() => setModalController(undefined)}
                  onFinish={(projectInfo, areasInfo) => {
                    if (areasInfo.source === "file") {
                      dispatch(
                        createProject({
                          directClientId: projectInfo.directClientId,
                          indirectClientId: projectInfo.indirectClientId,
                          projectName: projectInfo.projectName,
                          areaConfig: projectInfo.areaConfig,
                          areas: areasInfo.areas,
                          configuredReleasers: projectInfo.configuredReleasers,
                        })
                      );
                    } else {
                      dispatch(createProjectDrawingArea(projectInfo, areasInfo.areaName));
                    }

                    setModalController(undefined);
                  }}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.editProject) {
              return (
                <CreationModal
                  mode={"edit-project"}
                  projectId={modalController.project.id}
                  onClose={() => setModalController(undefined)}
                  onFinish={(projectInfo) => {
                    dispatch(
                      updateProject({
                        ...modalController.project,
                        indirectClientId: projectInfo.indirectClientId,
                        name: projectInfo.projectName,
                        areaConfig: {
                          ...modalController.project.areaConfig,
                          ...projectInfo.areaConfig,
                        },
                        configuredReleasers: projectInfo.configuredReleasers,
                      })
                    );
                    setModalController(undefined);
                  }}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.createArea) {
              return (
                <CreationModal
                  mode={"add-area"}
                  projectId={modalController.project.id}
                  onClose={() => setModalController(undefined)}
                  onFinish={(areasInfo) => {
                    if (areasInfo.source === "file") {
                      dispatch(createAreas(modalController.project.id, areasInfo.areas));
                    } else {
                      dispatch(
                        createAreaDrawingArea(modalController.project.id, {
                          areaName: areasInfo.areaName,
                          areaConfig: areasInfo.areaConfig,
                          configuredReleasers: areasInfo.configuredReleasers,
                        })
                      );
                    }

                    setModalController(undefined);
                  }}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.editArea) {
              return (
                <CreationModal
                  mode={"edit-area"}
                  projectId={modalController.area.projectId}
                  areaId={modalController.area.id}
                  onClose={() => setModalController(undefined)}
                  onFinish={(
                    areaName,
                    areaConfig,
                    configuredReleasers,
                    mustReleaseEntireArea,
                    unlockedToExecuteMissionPlanner
                  ) => {
                    dispatch(
                      editAreaParameters(
                        modalController.area.projectId,
                        modalController.area.id,
                        areaName,
                        areaConfig,
                        configuredReleasers,
                        mustReleaseEntireArea,
                        unlockedToExecuteMissionPlanner
                      )
                    );

                    setModalController(undefined);
                  }}
                />
              );
            }
            if (modalController?.type === MapModalControllerType.importRouteKml) {
              return (
                <CreationModal
                  mode="import-route-kml"
                  projectId={modalController.area.projectId}
                  area={modalController.area}
                  onClose={() => setModalController(undefined)}
                  onFinish={(route) => {
                    dispatch(
                      processImportedRoute({
                        areaId: modalController.area.id,
                        projectId: modalController.area.projectId,
                        route: route,
                        areaConfig: modalController.area.areaConfig,
                        configuredReleasers: modalController.area.configuredReleasers,
                        homePoint: modalController.area.planned.homePoint,
                        mustReleaseEntireArea: true,
                      })
                    );
                    setModalController(undefined);
                  }}
                />
              );
            }
            return <></>;
          })()}
        </Box>
        <OverlayGroup
          isExpandedControls={openedOverlays.mapControls}
          setExpandedControls={setIsExpandedMapControls}
          isExpandedFooter={openedOverlays.footer}
          setExpandedFooter={setIsExpandedFooterOverlay}
          isExpandedWeather={openedOverlays.weather}
          setExpandedWeather={setIsExpandedWeatherOverlay}
          heightScreenType={
            height <= smallScreenHeight
              ? "small"
              : height <= mediumScreenHeight
              ? "medium"
              : "regular"
          }
          widthScreenType={
            width <= smallScreenWidth ? "small" : width <= mediumScreenWidth ? "medium" : "regular"
          }
          mediumScreenWeatherOverlayMarginBottom={5 + height - smallScreenHeight}
        />

        <OpenedAreaActionsOverlay
          onClickToImportKmlRoute={(area) => {
            setModalController({
              type: MapModalControllerType.importRouteKml,
              area,
            });
          }}
          onClickToSelectPlannedRoute={(area) => {
            setModalController({
              type: MapModalControllerType.selectPlannedRoute,
              projectId: area.projectId,
              areaId: area.id,
            });
          }}
        />

        <LoadingAreasOverLay />

        <SpecialAreaPlanActionsOverlay />
      </div>
    </Map>
  );
}

type OverlaysExpansionType = {
  projectTree: OverlayExpansionType;
  mapControls: OverlayExpansionType;
  footer: OverlayExpansionType;
  weather: OverlayExpansionType;
};

type OverlayExpansionType = {
  isExpanded: boolean;
  expansionMoment: number;
};

enum MapModalControllerType {
  createProject,
  editProject,
  deleteProject,
  createArea,
  editArea,
  deleteArea,
  selectPlannedRoute,
  editingWaypoint,
  editingWaypoints,
  importRouteKml,
}

type MapModalController =
  | {
      type: MapModalControllerType.createProject;
    }
  | {
      type: MapModalControllerType.editProject;
      project: Project;
    }
  | {
      type: MapModalControllerType.deleteProject;
      project: Project;
    }
  | {
      type: MapModalControllerType.createArea;
      project: Project;
    }
  | {
      type: MapModalControllerType.editArea;
      area: Area;
    }
  | {
      type: MapModalControllerType.deleteArea;
      area: Area;
    }
  | {
      type: MapModalControllerType.selectPlannedRoute;
      projectId: string;
      areaId: string;
    }
  | {
      type: MapModalControllerType.editingWaypoint;
      area: Area;
      waypoint: Waypoint & { index: number };
    }
  | {
      type: MapModalControllerType.editingWaypoints;
      area: Area;
      waypoints: (Waypoint & { index: number })[];
    }
  | {
      type: MapModalControllerType.importRouteKml;
      area: Area;
    };

/**
 * If the window has a width below that value the user can open or the map controls or the footer or the project tree.
 */
const smallScreenWidth = 700;
/**
 * If the window has a width below that value the user can open the map controls and the project tree together.
 */
const mediumScreenWidth = 1200;

/**
 * If the window has a height below that value the user can open or the search tool in the map controls or the weather overlay.
 */
const smallScreenHeight = 400;
/**
 * If the window has a height below that value the user can open the map controls and the weather overlay but we need to control each position.
 */
const mediumScreenHeight = 600;
