import { DirectClient, PreRegisterForm, Profile, Role } from "biohub-model";
import { SystemThunk } from "../systemThunk";
import { BiohubError } from "../../services/axios/BiohubApi";
import ProfileService from "../../services/ProfileService";
import PreRegisterService from "../../services/PreRegisterService";
import MasterService from "../../services/MasterService";

export const READ_DIRECT_CLIENTS = "READ_DIRECT_CLIENTS";
export const READ_DIRECT_CLIENTS_ERROR = "READ_DIRECT_CLIENTS_ERROR";
export const READ_DIRECT_CLIENTS_SUCCESS = "READ_DIRECT_CLIENTS_SUCCESS";

export const READ_PRE_REGISTRATIONS = "READ_PRE_REGISTRATIONS";
export const READ_PRE_REGISTRATIONS_ERROR = "READ_PRE_REGISTRATIONS_ERROR";
export const READ_PRE_REGISTRATIONS_SUCCESS = "READ_PRE_REGISTRATIONS_SUCCESS";

export const ADD_DIRECT_CLIENT = "ADD_DIRECT_CLIENT";
export const ADD_DIRECT_CLIENT_ERROR = "ADD_DIRECT_CLIENT_ERROR";
export const ADD_DIRECT_CLIENT_SUCCESS = "ADD_DIRECT_CLIENT_SUCCESS";

export type MasterAction =
  | {
      type: typeof READ_DIRECT_CLIENTS;
    }
  | {
      type: typeof READ_DIRECT_CLIENTS_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof READ_DIRECT_CLIENTS_SUCCESS;
      payload: {
        directClients: Array<DirectClient>;
      };
    }
  | {
      type: typeof READ_PRE_REGISTRATIONS;
    }
  | {
      type: typeof READ_PRE_REGISTRATIONS_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof READ_PRE_REGISTRATIONS_SUCCESS;
      payload: {
        preRegistrations: PreRegisterForm[];
      };
    }
  | {
      type: typeof ADD_DIRECT_CLIENT;
    }
  | {
      type: typeof ADD_DIRECT_CLIENT_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof ADD_DIRECT_CLIENT_SUCCESS;
    };

export function readAllDirectClients(): SystemThunk {
  return async (dispatch, getState) => {
    const profile = getState().profile.userProfile;
    if (profile === null || profile.role !== Role.master) return;

    dispatch({
      type: READ_DIRECT_CLIENTS,
    });

    const result = await ProfileService.readDirectClients();
    if (!result.success) {
      dispatch({
        type: READ_DIRECT_CLIENTS_ERROR,
        payload: {
          error: result.error,
        },
      });
      return;
    }

    dispatch({
      type: READ_DIRECT_CLIENTS_SUCCESS,
      payload: {
        directClients: result.data as Array<DirectClient>,
      },
    });
  };
}

export function readPreRegistrations(): SystemThunk {
  return async (dispatch, getState) => {
    const profile = getState().profile.userProfile;
    if (profile === null || profile.role !== Role.master) return;

    dispatch({
      type: READ_PRE_REGISTRATIONS,
    });

    const preRegistrations = await PreRegisterService.readPreRegistrations();
    if (!preRegistrations.success) {
      dispatch({
        type: READ_PRE_REGISTRATIONS_ERROR,
        payload: {
          error: preRegistrations.error,
        },
      });
    } else {
      dispatch({
        type: READ_PRE_REGISTRATIONS_SUCCESS,
        payload: {
          preRegistrations: preRegistrations.data,
        },
      });
    }
  };
}

export function addDirectClient(
  directClient: Omit<
    DirectClient,
    "acceptedTermsOfUse" | "emergencyUnblockRequestedAt" | "subscriptions" | "logoUrl" | "profiles"
  >,
  adminProfile: Omit<
    Profile & { role: Role.admin },
    | "profileImageUrl"
    | "isRegistrationCompleted"
    | "preferences"
    | "directClientId"
    | "latestRequestAt"
  >
): SystemThunk {
  return async (dispatch, getState) => {
    const profile = getState().profile.userProfile;
    if (profile === null || profile.role !== Role.master) return;

    dispatch({
      type: ADD_DIRECT_CLIENT,
    });

    const result = await MasterService.addDirectClient(directClient, adminProfile);
    if (!result.success) {
      dispatch({
        type: ADD_DIRECT_CLIENT_ERROR,
        payload: {
          error: result.error,
        },
      });
    } else {
      dispatch(readAllDirectClients());
    }
  };
}
