import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import history from "./history";
import { IntlProvider, useIntl } from "react-intl";

import Routes from "./Routes";
import { messagesForLocale } from "../localization";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store, persistor } from "../store";
import { BiohubLocale } from "../store/reducers/localeReducer";
import { SystemState } from "../store/reducers/systemReducer";
import HttpsRedirect from "./HttpsRedirect";
import { PersistGate } from "redux-persist/integration/react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { SET_INITIALIZED as SET_INITIALIZED_CACHE_REDUX } from "../store/actions/cacheDataActions";
import { loadProfileInformation } from "../store/actions/profileActions";
import { fetchCollections } from "../store/actions/collectionsActions";
import { whenCloseApp } from "../store/actions/systemActions";

const _LIBRARIES: Libraries = ["places"];

// Theme with our standard colors.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1d4f90",
    },
    secondary: {
      main: "#ef7622",
    },
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '&::-webkit-scrollbar': {
  //         width: '5px',
  //         height: '5px',
  //       },
  //       '&::-webkit-scrollbar-thumb': {
  //         background: 'var(--dashboard-hr)',
  //         borderRadius: '10px',
  //       },
  //       '&::-webkit-scrollbar-track': {
  //         background: 'transparent',
  //         borderRadius: '10px',
  //         boxShadow: 'inset 5px 10px 12px var(--gray)',
  //       }
  //     }
  //   }
  // }
});

/**
 * Items in this declaration:
 *
 * Provider - react-redux's store provider, injects the store for all components.
 * IntlProvider - Internationalization provider.
 * Router - Declares all navigations and decides which page to render at any given time.
 */
function Root(): JSX.Element {
  return (
    <HttpsRedirect>
      <Provider store={store}>
        <PersistedRoot />
      </Provider>
    </HttpsRedirect>
  );
}

let reloadingPage = false;

function PersistedRoot(): JSX.Element {
  const dispatch = useDispatch();

  const userId = useSelector((state: SystemState) => state.login.userId);
  const localeCode = useSelector((state: SystemState) => state.locale.localeCode);
  const localeChanged = useSelector((state: SystemState) => state.locale.changed);

  useEffect(() => {
    reloadingPage = false;

    window.addEventListener("beforeunload", (e) => {
      if (history.location.pathname !== "/reports/flight" && !reloadingPage) {
        dispatch(whenCloseApp());
      }
    });

    window.addEventListener("keydown", (e) => {
      reloadingPage = e.key === "F5" || (e.key?.toLowerCase() === "r" && e.ctrlKey);
    });
  }, []);

  return (
    <PersistGate
      loading={null}
      persistor={persistor}
      onBeforeLift={() => {
        dispatch({ type: SET_INITIALIZED_CACHE_REDUX });
        if (userId !== null) {
          dispatch(
            loadProfileInformation(userId, localeCode, localeChanged, () =>
              dispatch(fetchCollections())
            )
          );
        }
      }}
    >
      <ThemeProvider theme={theme}>
        <LocalizedRoot />
      </ThemeProvider>
    </PersistGate>
  );
}

/**
 * For use in Root only. Gets the locale from the store to pass it to IntlProvider.
 */
function LocalizedRoot(): JSX.Element {
  const locale: BiohubLocale = useSelector((state: SystemState) => state.locale.localeCode);

  return (
    <IntlProvider messages={messagesForLocale(locale)} locale={locale} defaultLocale="en">
      <Router history={history}>
        <Routes />
      </Router>
    </IntlProvider>
  );
}

export default Root;
