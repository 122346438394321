/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Container from "../../../components/Atomic/Areas/Container";
import Text from "../../../components/Atomic/BasicComponents/Text";
import CompanyDataForm from "../../../components/Atomic/Forms/CompanyData";
import Banner from "../../../components/Atomic/Tools/Banner";
import CartComponent from "../../../components/Atomic/Tools/Cart";
import DownloadTerms from "../../../components/Atomic/Tools/DownloadTerms";
import { isValidCNPJ, isValidCPF } from "../../../services/FormValidatorsService";

import { addOrderNumber } from "../../../store/actions/cartActions";
import { SystemState } from "../../../store/reducers/systemReducer";

import imgDownload from "../../../assets/gallery/bioHub_with_logo.png";

import { CartPage, TitleStepCart } from "../styles";
import { Country, loadLocales, getCountries } from "../../../services/NationalityService";
import Footer from "../../../components/Atomic/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default () => {
  const intl = useIntl();
  const localeSystem = useSelector((state: SystemState) => state.locale.localeCode);

  const dispatch = useDispatch();
  const history = useHistory();

  // terms
  const termTitleCart = intl.formatMessage({ id: "cart.title" });
  const termTitleStep2 = intl.formatMessage({ id: "cart.title.step2" });
  const termTitleStep3 = intl.formatMessage({ id: "cart.title.step3" });
  const termTitleNumberOrder = intl.formatMessage({ id: "cart.title.number.order" });
  const termTitleInformationUse = intl.formatMessage({ id: "cart.terms.information.use" });
  const termTitleMandatoryContract = intl.formatMessage({ id: "cart.terms.mandatory.contract" });
  const termMessageErrorCPF = intl.formatMessage({ id: "cart.message.error.CPF" });
  const termMessageErrorCNPJ = intl.formatMessage({ id: "cart.message.error.CNPJ" });
  const termMessageErrorFieldsFilled = intl.formatMessage({
    id: "cart.message.error.fields.filled",
  });
  const termMessageErrorChecked = intl.formatMessage({ id: "cart.message.error.checked" });
  const termMessageErrorExtraAddress = intl.formatMessage({
    id: "cart.message.error.extra.address",
  });
  const termMessageErrorItemCart = intl.formatMessage({ id: "cart.message.error.item.cart" });
  const termMessageErrorTypeDocument = intl.formatMessage({
    id: "cart.message.error.document.type",
  });

  const itemsCart = useSelector((state: SystemState) => state.cart.items);
  const company = useSelector((state: SystemState) => state.cart.company);
  const representative = useSelector((state: SystemState) => state.cart.representative);
  const orderNumber = useSelector((state: SystemState) => state.cart.orderNumber);
  const extraAddress = useSelector((state: SystemState) => state.cart.extraAddress);

  const [countries, setCountries] = React.useState<Country[]>([]);
  const [radioValue, setRadioValue] = React.useState("");

  const [errorsValidators, setErrorsValidators] = React.useState({
    company: {
      name: false,
      tradeName: false,
      document: false,
      zipcode: false,
      street: false,
      complement: false,
      country: false,
      state: false,
      city: false,
      postalCode: false,
      commercialPhoneNumber: false,
    },
    representative: {
      name: false,
      email: false,
      cpf: false,
      idPerson: false,
      rg: false,
      im: false,
      zipcode: false,
      street: false,
      complement: false,
      country: false,
      state: false,
      city: false,
      postalCode: false,
      landlineNumber: false,
      cellphoneNumber: false,
    },
  });

  const dataTerms = [
    {
      id: "TERM01",
      title: termTitleInformationUse,
      terms: [
        {
          id: "PER01",
          title: "TERM NAME. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          file: imgDownload,
          isChecked: false,
        },
        {
          id: "PER02",
          title: "TERM NAME. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          file: imgDownload,
          isChecked: false,
        },
        {
          id: "PER03",
          title: "TERM NAME. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          file: imgDownload,
          isChecked: false,
        },
      ],
    },
    {
      id: "TERM02",
      title: termTitleMandatoryContract,
      terms: [
        {
          id: "OBG01",
          title: "TERM NAME. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          file: imgDownload,
          isChecked: false,
        },
        {
          id: "OBG02",
          title: "TERM NAME. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          file: imgDownload,
          isChecked: false,
        },
        {
          id: "OBG03",
          title: "TERM NAME. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          file: imgDownload,
          isChecked: false,
        },
        {
          id: "OBG04",
          title: "TERM NAME. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          file: imgDownload,
          isChecked: false,
        },
      ],
    },
  ];
  const [termsContracts, setTermsContracts] = React.useState<any[]>(dataTerms);

  const loadCountries = async () => {
    await loadLocales();
    setCountries(await getCountries("pt"));
  };

  React.useEffect(() => {
    switch (localeSystem) {
      case "en":
        setTermsContracts(dataTerms);
        break;
      case "pt-BR":
        setTermsContracts(dataTerms);
        break;
      case "es":
        setTermsContracts(dataTerms);
        break;
      case "fr":
        setTermsContracts(dataTerms);
        break;
      default:
        setTermsContracts(dataTerms);
        break;
    }
  }, [localeSystem]);

  React.useEffect(() => {
    loadCountries();
  }, []);

  // Every time you reload page, update the order number
  const loadNumberRandom = async () => {
    if (!orderNumber) {
      dispatch(addOrderNumber(Math.floor(Math.random() * 10000 + 1)));
    }
  };

  React.useEffect(() => {
    loadNumberRandom();
  }, []);

  const handleVerifyErrors = () => {
    let errorStateCompany = {};
    let errorStateRepresentative = {};

    for (let [key, value] of Object.entries(company)) {
      if (value === "") {
        errorStateCompany = {
          ...errorStateCompany,
          [key]: true,
        };
      } else {
        errorStateCompany = {
          ...errorStateCompany,
          [key]: false,
        };
      }
      if (key === "address") {
        for (let [key, value] of Object.entries(company.address)) {
          if (value === "" || value === "0") {
            errorStateCompany = {
              ...errorStateCompany,
              [key]: true,
            };
          } else {
            errorStateCompany = {
              ...errorStateCompany,
              [key]: false,
            };
          }
        }
      }
      if (key === "document") {
        const documentCompany = company.document.replace(/[^\d]+/g, "");
        if (radioValue === "CPF") {
          const cpfValidCompany = isValidCPF(company.document);
          if (!cpfValidCompany) {
            errorStateCompany = {
              ...errorStateCompany,
              document: !cpfValidCompany,
            };
            toast.error(termMessageErrorCPF);
          }
        } else if (radioValue === "CNPJ") {
          const cnpjValidCompany = isValidCNPJ(company.document);
          if (!cnpjValidCompany) {
            errorStateCompany = {
              ...errorStateCompany,
              document: !cnpjValidCompany,
            };
            toast.error(termMessageErrorCNPJ);
          }
        } else {
          errorStateCompany = {
            ...errorStateCompany,
            document: false,
          };
        }
      }
    }

    for (let [key, value] of Object.entries(representative)) {
      if (representative.nationality === "BR") {
        if (key === "idPerson" || key === "im") {
          errorStateRepresentative = {
            ...errorStateRepresentative,
            [key]: false,
          };
        } else {
          if (value === "") {
            errorStateRepresentative = {
              ...errorStateRepresentative,
              [key]: true,
            };
          } else {
            errorStateRepresentative = {
              ...errorStateRepresentative,
              [key]: false,
            };
          }
          if (key === "cpf") {
            const documentRepresentative = representative.cpf.replace(/[^\d]+/g, "");
            if (documentRepresentative.length === 11) {
              const cpfValidRepresentative = isValidCPF(representative.cpf);
              if (!cpfValidRepresentative) {
                errorStateRepresentative = {
                  ...errorStateRepresentative,
                  cpf: !cpfValidRepresentative,
                };
                toast.error(termMessageErrorCPF);
              }
            } else {
              errorStateRepresentative = {
                ...errorStateRepresentative,
                cpf: true,
              };
            }
          }
        }
      } else {
        if (key === "cpf" || key === "im" || key === "rg") {
          errorStateRepresentative = {
            ...errorStateRepresentative,
            [key]: false,
          };
        } else {
          if (value === "") {
            errorStateRepresentative = {
              ...errorStateRepresentative,
              [key]: true,
            };
          } else {
            errorStateRepresentative = {
              ...errorStateRepresentative,
              [key]: false,
            };
          }
        }
      }
      if (key === "address") {
        for (let [key, value] of Object.entries(representative.address)) {
          if (value === "" || value === "0") {
            errorStateRepresentative = {
              ...errorStateRepresentative,
              [key]: true,
            };
          } else {
            errorStateRepresentative = {
              ...errorStateRepresentative,
              [key]: false,
            };
          }
        }
      }
    }

    setErrorsValidators({
      ...errorsValidators,
      company: {
        ...errorsValidators.company,
        ...errorStateCompany,
      },
      representative: {
        ...errorsValidators.representative,
        ...errorStateRepresentative,
      },
    });

    for (let [, value] of Object.entries(errorStateCompany)) {
      if (value === true) {
        return true;
      }
    }

    for (let [, value] of Object.entries(errorStateRepresentative)) {
      if (value === true) {
        return true;
      }
    }
  };

  const handleVeifyAllChecked = () => {
    let countNoChecked = 0;

    termsContracts.map((item) =>
      item.terms.forEach((i: any) => {
        if (i.isChecked === false) {
          countNoChecked += 1;
        }
      })
    );

    if (countNoChecked > 0) {
      return true;
    }
  };

  const handleVerifyChargeAddress = () => {
    for (let [, value] of Object.entries(extraAddress.chargeAddress)) {
      if (value === "") {
        return true;
      }
    }
  };

  const handleVerifySendAddress = () => {
    for (let [, value] of Object.entries(extraAddress.sendAddress)) {
      if (value === "") {
        return true;
      }
    }
  };

  async function handleSubmit() {
    if (radioValue === "") {
      toast.error(termMessageErrorTypeDocument);
      return;
    }

    const verifyError = handleVerifyErrors();
    if (verifyError) {
      toast.error(termMessageErrorFieldsFilled);
      return;
    }

    const verifyAllTermsCheck = handleVeifyAllChecked();
    if (verifyAllTermsCheck) {
      toast.error(termMessageErrorChecked);
      return;
    }

    const verifyChargeAddress = handleVerifyChargeAddress();
    const verifySendAddress = handleVerifySendAddress();
    if (verifyChargeAddress === true && verifySendAddress === true) {
      toast.error(termMessageErrorExtraAddress);
      return;
    }

    if (itemsCart.length === 0) {
      toast.error(termMessageErrorItemCart);
      return;
    }

    // TODO: Add route for redirect after all complete cart
    // history.push("/");
  }

  return (
    <>
      <CartPage>
        <Banner title={termTitleCart} />
        <Container>
          <CompanyDataForm
            countries={countries}
            locale={localeSystem}
            errorsValidators={errorsValidators}
            radioValue={radioValue}
            setRadioValue={setRadioValue}
          />
          <Text
            text={termTitleStep2}
            appearance={"title-semibold"}
            color={"primary"}
            size={"medium"}
          />
          <DownloadTerms termsContracts={termsContracts} setTermsContracts={setTermsContracts} />
          <TitleStepCart>
            <Text
              text={termTitleStep3}
              appearance={"title-semibold"}
              color={"primary"}
              size={"medium"}
            />
            <Text
              text={`${termTitleNumberOrder} ${orderNumber}`}
              appearance={"title-semibold"}
              size={"small"}
            />
          </TitleStepCart>
          <CartComponent countries={countries} handlePaySubmit={() => handleSubmit()} />
        </Container>
        <Footer image={true} description="" />
        <ToastContainer
          style={{
            width: 400,
          }}
          position="top-right"
          autoClose={3000}
          pauseOnHover
        />
      </CartPage>
    </>
  );
};
