import styled from "styled-components";

type Props = {};

export const List = styled.div.attrs((props: Props) => ({}))`
  width: 100%;
  display: block;
  padding: 0 2rem;
  margin: 3rem auto;

  li {
    > div {
      align-items: unset;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 750px) {
    li {
      > div {
        align-items: center;
        text-align: center;

        img {
          margin-right: unset;
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    padding: 0;
  }
`;
