import { Location } from "biohub-model";
import React from "react";
import { MemoMarker } from "../MemoMapComponents";

type Props = {
  polygonId: string;
  waypointIndex: number;
  location: Location;
  waypointScale?: number;
  orientation: number;
  onClick: () => void;
  zIndex: number;
};

export default function (props: Props): JSX.Element {
  const sizeMultiplier = props.waypointScale ?? 1;
  return (
    <MemoMarker
      key={`${props.polygonId}-marker-orientation-${props.waypointIndex}`}
      zIndex={props.zIndex}
      icon={{
        // Implementation using google maps Symbol to enable the rotation
        path: directionIcon.svgPath,
        strokeColor: directionIcon.strokeColor,
        fillColor: directionIcon.fillColor,
        fillOpacity: directionIcon.opacity,
        scale: directionIcon.scale !== undefined ? sizeMultiplier * directionIcon.scale : 1,
        anchor: new google.maps.Point(25, 26),
        rotation: props.orientation,
      }}
      position={props.location}
      // Add the waypoint click callback to it.
      clickable
      onClick={props.onClick}
    />
  );
}

const directionIcon = {
  svgPath: `M22.803,8.549L8.014,38.126
  c-0.287,0.598-0.036,1.314,0.562,1.602c0.339,0.164,0.734,0.157,1.068-0.015l13.605-7.255c0.35-0.188,0.77-0.188,1.12-0.001
  l13.737,7.271c0.582,0.311,1.305,0.09,1.613-0.492c0.182-0.342,0.186-0.75,0.014-1.096L24.938,8.549
  c-0.295-0.59-1.01-0.83-1.601-0.536C23.107,8.129,22.919,8.317,22.803,8.549z`,
  strokeColor: "#FFFFFF",
  fillColor: "#EF7622",
  opacity: 1,
  scale: 0.7,
};
