import { Company } from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  BiohubUnit,
  biohubUnit,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

async function updateCompany(
  company: { id: string } & Partial<Company>
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.put("/company/" + company.id, { company: company });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default {
  updateCompany,
};
