import styled from "styled-components";

type Props = {};

export const About = styled.span.attrs((props: Props) => ({}))`
  display: block;

  label:first-of-type {
    max-width: 330px;
    font-family: "BROmny";
    text-align: left;
  }

  img {
    margin: 1rem 0;
  }

  @media screen and (max-width: 680px) {
    margin-bottom: 15px;
    width: 100%;

    label:first-of-type {
      font-size: 14px;
      margin-top: 10px;
      align-self: flex-start;
    }
  }
`;
