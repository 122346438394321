import React from "react";
import { useIntl } from "react-intl";
import Header from "../../../components/Atomic/Header";
import Footer from "../../../components/Atomic/Footer";
import Text from "../../../components/Atomic/BasicComponents/Text";
import Button from "../../../components/Atomic/BasicComponents/Button";
import Clickable from "../../../components/Atomic/BasicComponents/Link"; //href only

import { Container, Grid } from "@material-ui/core";

import { TermsPage, Content, Item, Description } from "../styles";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();

  // terms
  const titleTerms = intl.formatMessage({ id: "menu.terms" });
  const subtitleTerms = intl.formatMessage({ id: "info.subtitleTerms" });
  const contentTerms = intl.formatMessage({ id: "info.pageTerms" });
  const termRegister = intl.formatMessage({ id: "action.signUp" });

  return (
    <>
      <TermsPage>
        <Header color={"dark"} />
        <Content id="terms">
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <Item>
                  <Description>
                    <Text text={titleTerms} color={"primary"} appearance={"section-title"} />
                    <hr />
                    <Text text={subtitleTerms} color={"secondary"} appearance={"info-subtitle"} />
                    <Text text={contentTerms} color={"dark"} appearance={"description-block"} />
                  </Description>
                </Item>
                <Clickable to={"/signUp"} className={"clickable"}>
                  <Button text={termRegister} appearance={"highlighted"} size={"large"} />
                </Clickable>
              </Grid>
            </Grid>
          </Container>
        </Content>
        <Footer image={true} description={intl.formatMessage({ id: "info.about" })} />
      </TermsPage>
    </>
  );
};
