import React from "react";
import { IntlShape, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  IconButton,
  Select,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Checkbox,
  Button,
  Icon,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
} from "@material-ui/core";
import { useState } from "react";
import { TableList } from "./styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

export type Props<T> = {
  titleTerm: string;
  onRemoveFunction?: (items: Array<string>) => void;
  items: Array<T>;
  classes: Array<string>;
  formatItems: (item: T) => Array<string>;
  onViewItem?: (itemId: string) => void;
  onAddFunction?: () => void;
  addTerm?: string;
  additionalIcon?: (item: T) => JSX.Element;
  getItemStyle?: (item: T) => Array<React.CSSProperties | undefined> | undefined;
  headerActionIcon?: JSX.Element;
  enableToMarkItem?: (item: T) => boolean;
  remotionTerm?: string;
};

type SortOrder = "asc" | "desc";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function TableHeader(props: {
  titleTerm: string;
  addTerm?: string;
  numSelected: number;
  onAddFunction?: () => void;
  onRemoveFunction?: () => void;
  clearSelection: () => void;
  actionIcon?: JSX.Element;
  remotionTerm?: string;
}) {
  const { titleTerm, addTerm, onAddFunction, numSelected, onRemoveFunction, clearSelection } =
    props;

  const intl = useIntl();
  const termSelected = intl.formatMessage({ id: "profile.userTableComponent.selection.selected" });
  const termClearSelection = intl.formatMessage({
    id: "profile.userTableComponent.selection.clear",
  });
  const termRemove = intl.formatMessage({
    id: props.remotionTerm ?? "profile.userTableComponent.selection.remove",
  });

  return (
    <>
      <Toolbar className="table-header">
        {props.actionIcon !== undefined && props.actionIcon}

        {titleTerm}
        {numSelected > 0 && (
          <Grid container xs={4}>
            <Tooltip title={termClearSelection}>
              <Button
                onClick={() => {
                  clearSelection();
                }}
                color="primary"
              >
                <Icon>cancel</Icon>
              </Button>
            </Tooltip>
            <Box width={10} />
            {numSelected.toString() + " " + termSelected}
            {onRemoveFunction !== undefined && (
              <Tooltip title={termRemove}>
                <Button
                  onClick={() => {
                    onRemoveFunction();
                  }}
                  color="primary"
                >
                  <Icon>delete</Icon>
                </Button>
              </Tooltip>
            )}
          </Grid>
        )}
        {addTerm !== undefined && onAddFunction !== undefined && (
          <Button
            onClick={() => {
              onAddFunction();
            }}
            color="primary"
          >
            <div style={{ marginTop: 5 }}>{addTerm}</div>
            <Icon>add</Icon>
          </Button>
        )}
      </Toolbar>
    </>
  );
}

function EnhancedTableHead(props: {
  styleClasses: ClassNameMap<"table" | "root" | "paper" | "visuallyHidden">;
  headers: Array<string>;
  order: SortOrder;
  orderClassIndex: number;
  onReverteOrder: (order: SortOrder) => void;
  onRequestSort: (classIndex: number) => void;
}) {
  const { styleClasses, headers, orderClassIndex, order, onRequestSort, onReverteOrder } = props;

  const headCells: Array<{
    index: number;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
  } | null> = [];

  headers.forEach((value, index) => {
    if (value.length > 0) {
      if (index === 0) {
        headCells.push({ index: index, numeric: false, disablePadding: true, label: "" });
      } else {
        headCells.push({ index: index, numeric: false, disablePadding: false, label: value });
      }
    } else {
      headCells.push(null);
    }
  });

  const reverseOrder: SortOrder = order === "asc" ? "desc" : "asc";

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) =>
          headCell == null ? (
            <TableCell key={index.toString()}></TableCell>
          ) : (
            <TableCell
              key={headCell.index.toString()}
              align="left"
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderClassIndex === headCell.index ? order : false}
            >
              <TableSortLabel
                active={orderClassIndex === headCell.index}
                direction={orderClassIndex === headCell.index ? order : reverseOrder}
                onClick={(e) => {
                  if (orderClassIndex === headCell.index) {
                    onReverteOrder(reverseOrder);
                  } else {
                    onRequestSort(headCell.index);
                  }
                }}
              >
                {headCell.label}
                {orderClassIndex === headCell.index ? (
                  <span className={styleClasses.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
        <TableCell padding="checkbox"></TableCell>
      </TableRow>
    </TableHead>
  );
}

type FormatItem<T> = {
  item: T;
  fields: Array<string>;
};

function getOrderItems<T>(
  items: Array<T>,
  formatItems: (item: T) => Array<string>,
  order: SortOrder,
  sortItem: number,
  itemsPerPage: number,
  viewPage: number
): Array<FormatItem<T> | null> {
  const itemList = items.map((item) => ({ fields: formatItems(item), item: item }));
  itemList.sort((_a: FormatItem<T>, _b: FormatItem<T>) => {
    const a = _a.fields;
    const b = _b.fields;
    let compareObj: Array<string>;
    let compareTo: Array<string>;
    if (order === "asc") {
      compareObj = a;
      compareTo = b;
    } else {
      compareObj = b;
      compareTo = a;
    }
    if (compareObj[sortItem] > compareTo[sortItem]) return 1;
    else if (compareObj[sortItem] === compareTo[sortItem]) return 0;
    else return -1;
  });

  const ret: Array<FormatItem<T> | null> = [];
  for (let i = 0; i < itemsPerPage && itemsPerPage * viewPage + i < itemList.length; i++) {
    ret.push(itemList[itemsPerPage * viewPage + i]);
  }
  if (ret.length < itemsPerPage) {
    ret.push(null);
  }

  return ret;
}

export default function <T>(props: Props<T>) {
  const [sortItem, setSortItem] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [viewPage, setViewPage] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);

  const styleClasses = useStyles();

  const {
    titleTerm,
    addTerm,
    items,
    classes,
    formatItems,
    onAddFunction,
    onViewItem,
    onRemoveFunction,
  } = props;

  return (
    <TableList>
      <Paper elevation={3}>
        {/* {props.toolbar} */}
        <TableHeader
          titleTerm={titleTerm}
          addTerm={addTerm}
          onAddFunction={onAddFunction}
          numSelected={selectedItems.length}
          onRemoveFunction={
            onRemoveFunction !== undefined
              ? () => {
                  onRemoveFunction(selectedItems);
                }
              : undefined
          }
          clearSelection={() => {
            setSelectedItems([]);
          }}
          actionIcon={props.headerActionIcon}
          remotionTerm={props.remotionTerm}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
            <EnhancedTableHead
              styleClasses={styleClasses}
              headers={props.additionalIcon ? [...classes, ""] : classes}
              order={sortOrder}
              orderClassIndex={sortItem}
              onRequestSort={(classIndex: number) => {
                setSortItem(classIndex);
              }}
              onReverteOrder={(newOrder: SortOrder) => {
                setSortOrder(newOrder);
              }}
            />
            <TableBody>
              {getOrderItems<T>(items, formatItems, sortOrder, sortItem, rowsPerPage, viewPage).map(
                (formatItem: FormatItem<T> | null, index: number) => {
                  if (formatItem === null) {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={false}
                        tabIndex={-1}
                        key={""}
                        selected={false}
                      >
                        <Box height={59} />
                      </TableRow>
                    );
                  }
                  const item = formatItem.fields;
                  const styles: Array<React.CSSProperties | undefined> | undefined =
                    props.getItemStyle !== undefined
                      ? props.getItemStyle(formatItem.item)
                      : undefined;
                  if (item.length === 0) {
                    return (
                      <TableRow style={{ height: 118 }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    );
                  }

                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isItemSelected = selectedItems.includes(item[0]);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={item[0]}
                      selected={isItemSelected}
                    >
                      {item.map((value, valueIndex) => {
                        const style =
                          styles !== undefined && styles[valueIndex] !== undefined
                            ? styles[valueIndex]
                            : undefined;
                        if (valueIndex === 0) {
                          return (
                            <TableCell padding="checkbox">
                              <Checkbox
                                style={style}
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                onClick={() => {
                                  if (isItemSelected) {
                                    const indexOf = selectedItems.indexOf(item[0]);
                                    if (indexOf !== -1) {
                                      const list = Array.from(selectedItems);
                                      list.splice(indexOf, 1);
                                      setSelectedItems(list);
                                    }
                                  } else {
                                    setSelectedItems([...selectedItems, item[0]]);
                                  }
                                }}
                                disabled={
                                  props.enableToMarkItem !== undefined
                                    ? !props.enableToMarkItem(formatItem.item)
                                    : false
                                }
                              />
                            </TableCell>
                          );
                        } else if (valueIndex === 1) {
                          return (
                            <TableCell
                              style={style}
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {value}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell style={style} align="left">
                              {value}
                            </TableCell>
                          );
                        }
                      })}
                      {props.additionalIcon && (
                        <TableCell padding="none">
                          {props.additionalIcon(formatItem.item)}
                        </TableCell>
                      )}

                      {onViewItem !== undefined && (
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              onViewItem(item[0]);
                            }}
                          >
                            <Icon>edit</Icon>
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={viewPage}
          onChangePage={(event: any, newPage: any) => {
            setViewPage(newPage);
          }}
          onChangeRowsPerPage={(event: any) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setViewPage(0);
          }}
        />
      </Paper>
    </TableList>
  );
}
