import { Dialog, Table } from "@material-ui/core";
import styled from "styled-components";

type Props = {};

export const Cart = styled.section.attrs(() => ({}))``;

export const CartCard = styled.div.attrs(() => ({}))`
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--primary);
  background: var(--white);

  .info-address {
    > div {
      padding: 1rem;
    }
  }

  .checkout-align {
    padding: 1rem;
    > div {
      width: 100%;
      padding: 1rem;
    }
  }

  .methodPay {
    padding: 1rem;

    .methodPay-action {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      div {
        &:last-child {
          margin-top: 0.5rem;
        }
      }
    }

    .methodPay-types {
      display: flex;
      /* align-items: center; */
      flex-wrap: wrap;
      margin: 1rem 0;

      > label {
        width: 100%;
        margin-bottom: 1rem;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        label {
          margin-top: auto;
        }

        &:last-child {
          img {
            margin-top: 10px;
          }
        }
      }
    }
  }
`;

export const TableCart = styled(Table).attrs(() => ({}))`
  thead th,
  tbody td {
    &:first-child {
      width: 50%;
    }

    &:nth-child(2) {
      width: 20%;
    }

    &:last-child {
      width: 30%;
    }
  }

  tr.tableRow-equipment {
    > td.tableCell-equipment {
      &:first-child {
        padding-left: 2rem;
      }
    }
  }

  td.tableCell-equipment {
    border: unset;
  }

  tbody {
    border-bottom: 1px solid var(--table-border);
  }

  .selectAmount-cart {
    > div {
      border-radius: 20px;

      .MuiOutlinedInput-input {
        padding: 10px;
      }

      svg {
        color: var(--primary);
      }

      fieldset {
        border: 2px solid var(--primary);
      }
    }
  }
`;

export const TitleCardCart = styled.div.attrs(() => ({}))`
  width: 100%;
  padding: 1rem;
  color: var(--white);
  background: var(--primary);
`;

export const InfoAddress = styled.div.attrs(() => ({}))`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  label {
    & + label {
      margin-top: 1rem;
    }
  }
`;

export const Actions = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  a {
    cursor: pointer;
    color: var(--highlighted);
    text-decoration: underline;

    & + a {
      margin-left: 8px;
      padding-left: 8px;
      border-left: 2px solid var(--primary);
    }

    > label {
      cursor: pointer;
    }
  }
`;

export const DiscountArea = styled.div.attrs(() => ({}))`
  .discountText-align {
    display: flex;
    align-items: center;

    label {
      & + label {
        text-transform: uppercase;
        margin-left: 3px;
      }
    }

    & ~ div {
      > div {
        margin-top: unset;
      }
    }
  }
`;

export const SubTotalArea = styled.div.attrs(() => ({}))`
  .subTotal-align {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 1rem;
    }
  }
`;

export const TotalArea = styled.div.attrs(() => ({}))`
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid var(--table-border);

  .total-align {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Modal = styled(Dialog).attrs((props: Props) => ({}))`
  .MuiDialogActions-root {
    width: 100%;
    justify-content: center;
  }

  button {
    min-width: 120px;
  }
`;

export const InputContainer = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;

  label {
    font-size: 16px !important;
  }

  button {
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  .companyForm-spacing {
    &:first-child {
      max-width: calc(50% - 20px);
      margin-right: 20px;
    }
  }

  .companyForm-select {
    label {
      margin-bottom: 1rem;
    }

    > div {
      > div {
        border-radius: 20px;

        .MuiOutlinedInput-input {
          padding: 10px;
        }
      }

      svg {
        color: var(--primary);
      }

      fieldset {
        border: 2px solid var(--primary);
      }
    }
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid var(--primary);
  }
  .MuiFilledInput-underline:before {
    border-bottom: 2px solid var(--primary);
  }

  div {
    /* &:first-child {
      flex: 1;
    } */

    /* &:nth-child(2) {
      > div {
        width: 100%;
      }
    } */

    &.companyForm-complement {
      > div:nth-child(2) {
        margin-top: 1rem;
      }
    }

    button {
      min-width: unset;
      padding: 0 5px;

      img,
      svg {
        width: 18px;
      }
    }

    &[role="radiogroup"] {
      flex-direction: unset !important;
    }
  }
`;
