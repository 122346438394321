import React from "react";
import Container from "../../Areas/Container";
import Section from "../../Section";
import Carousel from "../../BasicComponents/SimpleCarousel";
import { List } from "./styles";
import Text from "../../BasicComponents/Text";

interface Props {
  title: string;
  color?: "light" | "primary" | "mixed" | "dark-gray" | "light-gray";
  items: Array<{
    id: string;
    title: string;
    description: string;
    image: string | "url";
  }>;
}

export default (props: Props) => {
  return (
    <>
      <Section sectionColor={props.color} appearence="section-carousel">
        <Text
          text={props.title}
          color={"primary"}
          textAlign={"center"}
          appearance={"title-semibold"}
          size={"medium"}
        />
        <Container>
          <List>
            <Carousel items={props.items} color={"primary"} isCustomButtonGroup />
          </List>
        </Container>
      </Section>
    </>
  );
};
