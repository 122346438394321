import {
  DirectClient,
  Profile,
  Role,
  Company,
  IndirectClient,
  ProfilePreferences,
  Location,
} from "biohub-model";
import { REHYDRATE } from "redux-persist/es/constants";
import { BiohubError } from "../../services/axios/BiohubApi";
import { LOG_OUT } from "../actions/loginActions";
import {
  PROFILE_READING,
  PROFILE_READ_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_REQUEST_ERROR,
  PROFILE_UPDATE_REQUEST_SUCCESS,
  PROFILE_RELOAD,
  PROFILE_RELOAD_ERROR,
  PROFILE_RELOAD_SUCCESS,
  PROFILE_ADITION,
  PROFILE_ADITION_ERROR,
  PROFILE_ADITION_SUCCESS,
  PROFILE_UPDATE_COMPANY,
  PROFILE_UPDATE_COMPANY_ERROR,
  PROFILE_UPDATE_COMPANY_SUCCESS,
  PROFILE_RELOAD_DIRECT_CLIENT,
  PROFILE_RELOAD_DIRECT_CLIENT_ERROR,
  PROFILE_RELOAD_DIRECT_CLIENT_SUCCESS,
  ADITION_INDIRECT_CLIENT,
  ADITION_INDIRECT_CLIENT_ERROR,
  ADITION_INDIRECT_CLIENT_SUCCESS,
  UPDATE_INDIRECT_CLIENT,
  UPDATE_INDIRECT_CLIENT_ERROR,
  UPDATE_INDIRECT_CLIENT_SUCCESS,
  READ_INDIRECT_CLIENTS,
  READ_INDIRECT_CLIENTS_ERROR,
  READ_INDIRECT_CLIENTS_SUCCESS,
  UPDATE_PROFILE_PREFERENCES,
  UPDATE_PROFILE_PREFERENCES_ERROR,
  UPDATE_PROFILE_PREFERENCES_SUCCESS,
  PROFILE_REMOTION,
  PROFILE_REMOTION_ERROR,
  PROFILE_REMOTION_SUCCESS,
  INDIRECT_CLIENT_REMOTION,
  INDIRECT_CLIENT_REMOTION_ERROR,
  INDIRECT_CLIENT_REMOTION_SUCCESS,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE_ERROR,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  UPDATE_DIRECTCLIENT_LOGO,
  UPDATE_DIRECTCLIENT_LOGO_ERROR,
  UPDATE_DIRECTCLIENT_LOGO_SUCCESS,
  UPDATE_INDIRECTCLIENT_LOGO,
  UPDATE_INDIRECTCLIENT_LOGO_ERROR,
  UPDATE_INDIRECTCLIENT_LOGO_SUCCESS,
  PROFILE_RELOAD_INDIRECT_CLIENT,
  PROFILE_RELOAD_INDIRECT_CLIENT_ERROR,
  PROFILE_RELOAD_INDIRECT_CLIENT_SUCCESS,
} from "../actions/profileActions";
import { SystemAction } from "../actions/systemActions";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export type ProfileState = {
  key: "profile";
  error: BiohubError | null;
  userProfile: Profile | null;
  loadingProfileImage: boolean;
  loadingProfile: boolean;
  loadingProfiles: boolean;
  loadingLogoImage: boolean;
  directClient: DirectClient | null;
  indirectClient: IndirectClient | null;
  loadingDirectClient: boolean;
  loadingIndirectClient: boolean;
  loadingCompany: boolean;
  indirectClients: IndirectClient[] | null;
  loadingIndirectClients: boolean;
};

const INITIAL_STATE: ProfileState = {
  key: "profile",
  error: null,
  userProfile: null,
  loadingProfileImage: false,
  loadingProfile: false,
  loadingProfiles: false,
  loadingLogoImage: false,
  directClient: null,
  indirectClient: null,
  loadingDirectClient: false,
  loadingCompany: false,
  indirectClients: null,
  loadingIndirectClients: false,
  loadingIndirectClient: false,
};

export function findProfileIndex(profile: Partial<Profile>, profiles: Array<Profile>): number {
  return profiles.findIndex((i) => i.id === profile.id);
}

export function profileReducer(state = INITIAL_STATE, action: SystemAction): ProfileState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  let loadingField: keyof ProfileState;

  switch (action.type) {
    case LOG_OUT:
      return INITIAL_STATE;
    case REHYDRATE:
      if (action.payload?.key === "profile") {
        return {
          ...INITIAL_STATE,
          ...action.payload,
        };
      }

      return effectiveState;

    case PROFILE_READING:
      return {
        ...effectiveState,
        loadingProfile: true,
      };
    case PROFILE_READ_SUCCESS:
      return {
        ...effectiveState,
        loadingProfile: false,
        loadingProfileImage: false,
        userProfile: action.payload.profile,
        loadingDirectClient: false,
        directClient: action.payload.directClient,
        loadingIndirectClient: false,
        indirectClient: action.payload.indirectClient,
      };

    case PROFILE_UPDATE_REQUEST:
      loadingField =
        action.payload.updatingProfileId === effectiveState.userProfile?.id
          ? "loadingProfile"
          : "loadingProfiles";
      return {
        ...effectiveState,
        [loadingField]: true,
      };
    case PROFILE_UPDATE_REQUEST_ERROR:
      loadingField =
        action.payload.profileId === effectiveState.userProfile?.id
          ? "loadingProfile"
          : "loadingProfiles";
      return {
        ...effectiveState,
        [loadingField]: false,
        error: action.payload.error,
      };
    case PROFILE_UPDATE_REQUEST_SUCCESS:
      loadingField =
        action.payload.profileId === effectiveState.userProfile?.id
          ? "loadingProfile"
          : "loadingProfiles";
      return {
        ...effectiveState,
        [loadingField]: true,
      };
    case PROFILE_RELOAD:
      loadingField =
        action.payload.profileId === effectiveState.userProfile?.id
          ? "loadingProfile"
          : "loadingProfiles";
      return {
        ...effectiveState,
        [loadingField]: true,
      };
    case PROFILE_RELOAD_ERROR:
      loadingField =
        action.payload.profileId === effectiveState.userProfile?.id
          ? "loadingProfile"
          : "loadingProfiles";
      return {
        ...effectiveState,
        [loadingField]: false,
        error: action.payload.error,
      };
    case PROFILE_RELOAD_SUCCESS:
      return {
        ...effectiveState,
        loadingProfile: false,
        loadingProfileImage: false,
        userProfile: action.payload.profile,
      };

    case PROFILE_ADITION:
      return {
        ...effectiveState,
        loadingProfiles: true,
      };
    case PROFILE_ADITION_ERROR:
      return {
        ...effectiveState,
        loadingProfiles: false,
        error: action.payload.error,
      };
    case PROFILE_ADITION_SUCCESS:
      return {
        ...effectiveState,
        loadingProfiles: true,
      };

    case PROFILE_UPDATE_COMPANY:
      return {
        ...effectiveState,
        loadingCompany: true,
      };
    case PROFILE_UPDATE_COMPANY_ERROR:
      return {
        ...effectiveState,
        loadingCompany: false,
        error: action.payload.error,
      };
    case PROFILE_UPDATE_COMPANY_SUCCESS:
      return {
        ...effectiveState,
        loadingCompany: true,
      };

    case PROFILE_RELOAD_DIRECT_CLIENT:
      return {
        ...effectiveState,
        loadingDirectClient: true,
      };
    case PROFILE_RELOAD_DIRECT_CLIENT_ERROR:
      return {
        ...effectiveState,
        loadingDirectClient: false,
        error: action.payload.error,
      };
    case PROFILE_RELOAD_DIRECT_CLIENT_SUCCESS:
      return {
        ...effectiveState,
        loadingProfiles: false,
        loadingDirectClient: false,
        loadingCompany: false,
        loadingLogoImage: false,
        directClient: action.payload.directClient,
      };

    case PROFILE_RELOAD_INDIRECT_CLIENT:
      return {
        ...effectiveState,
        loadingIndirectClient: true,
      };
    case PROFILE_RELOAD_INDIRECT_CLIENT_ERROR:
      return {
        ...effectiveState,
        loadingIndirectClient: false,
        error: action.payload.error,
      };
    case PROFILE_RELOAD_INDIRECT_CLIENT_SUCCESS:
      return {
        ...effectiveState,
        loadingIndirectClient: false,
        loadingLogoImage: false,
        indirectClient: action.payload.indirectClient,
      };

    case READ_INDIRECT_CLIENTS:
      return {
        ...effectiveState,
        loadingIndirectClients: true,
      };
    case READ_INDIRECT_CLIENTS_ERROR:
      return {
        ...effectiveState,
        loadingIndirectClients: false,
        error: action.payload.error,
      };
    case READ_INDIRECT_CLIENTS_SUCCESS:
      return {
        ...effectiveState,
        loadingIndirectClients: false,
        indirectClients: action.payload.indirectClients,
      };

    case ADITION_INDIRECT_CLIENT:
      return {
        ...effectiveState,
        loadingIndirectClients: true,
      };
    case ADITION_INDIRECT_CLIENT_ERROR:
      return {
        ...effectiveState,
        loadingIndirectClients: false,
        error: action.payload.error,
      };
    case ADITION_INDIRECT_CLIENT_SUCCESS:
      return {
        ...effectiveState,
        loadingIndirectClients: true,
      };

    case UPDATE_INDIRECT_CLIENT:
      return {
        ...effectiveState,
        loadingIndirectClients: true,
      };
    case UPDATE_INDIRECT_CLIENT_ERROR:
      return {
        ...effectiveState,
        loadingIndirectClients: false,
        error: action.payload.error,
      };
    case UPDATE_INDIRECT_CLIENT_SUCCESS:
      return {
        ...effectiveState,
        loadingIndirectClients: true,
      };

    case UPDATE_PROFILE_PREFERENCES:
      return {
        ...effectiveState,
        loadingProfile: true,
      };
    case UPDATE_PROFILE_PREFERENCES_ERROR:
      return {
        ...effectiveState,
        loadingProfile: false,
        error: action.payload.error,
      };
    case UPDATE_PROFILE_PREFERENCES_SUCCESS:
      return {
        ...effectiveState,
        loadingProfile: true,
      };

    case PROFILE_REMOTION:
      return {
        ...effectiveState,
        loadingProfiles: true,
      };
    case PROFILE_REMOTION_ERROR:
      return {
        ...effectiveState,
        loadingProfiles: false,
        error: action.payload.error,
      };
    case PROFILE_REMOTION_SUCCESS:
      return {
        ...effectiveState,
        loadingProfiles: true,
      };

    case INDIRECT_CLIENT_REMOTION:
      return {
        ...effectiveState,
        loadingIndirectClients: true,
      };
    case INDIRECT_CLIENT_REMOTION_ERROR:
      return {
        ...effectiveState,
        loadingIndirectClients: false,
        error: action.payload.error,
      };
    case INDIRECT_CLIENT_REMOTION_SUCCESS:
      return {
        ...effectiveState,
        loadingIndirectClients: true,
      };

    case UPDATE_PROFILE_IMAGE:
      return {
        ...effectiveState,
        loadingProfileImage: true,
      };
    case UPDATE_PROFILE_IMAGE_ERROR:
      return {
        ...effectiveState,
        loadingProfileImage: false,
        error: action.payload.error,
      };
    case UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...effectiveState,
        loadingProfileImage: true,
      };
    case UPDATE_DIRECTCLIENT_LOGO:
      return {
        ...effectiveState,
        loadingLogoImage: true,
      };
    case UPDATE_DIRECTCLIENT_LOGO_ERROR:
      return {
        ...effectiveState,
        loadingLogoImage: false,
        error: action.payload.error,
      };
    case UPDATE_DIRECTCLIENT_LOGO_SUCCESS:
      return {
        ...effectiveState,
        loadingLogoImage: true,
      };
    case UPDATE_INDIRECTCLIENT_LOGO:
      return {
        ...effectiveState,
        loadingLogoImage: true,
      };
    case UPDATE_INDIRECTCLIENT_LOGO_ERROR:
      return {
        ...effectiveState,
        loadingLogoImage: false,
        error: action.payload.error,
      };
    case UPDATE_INDIRECTCLIENT_LOGO_SUCCESS:
      return {
        ...effectiveState,
        loadingLogoImage: true,
      };
    default:
      return effectiveState;
  }
}

export type ProfilePersistedState = Omit<
  ProfileState,
  | "error"
  | "loadingProfileImage"
  | "loadingProfile"
  | "loadingProfiles"
  | "loadingLogoImage"
  | "loadingDirectClient"
  | "loadingIndirectClient"
  | "loadingCompany"
  | "loadingIndirectClients"
>;

const profilePersistConfig: PersistConfig<ProfileState> = {
  key: "profile",
  storage,
  whitelist: ["key", "userProfile", "directClient", "indirectClient", "indirectClients"],
};

export const profilePersistedReducer = persistReducer(profilePersistConfig, profileReducer);
