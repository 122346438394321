import React from "react";
import AcordeonItem from "./AcordeonItem";
import { Accordion } from "./styles";

interface Props {
  /**
   * List with items to be drawn on the screen,
   * with a title and a body containing the content.
   */
  items: { id: string; title: string; body: string | React.ReactElement }[];
  /**
   * Allows multi selection of items or single selection.
   */
  multiSelect: boolean;
  /**
   * If multi selection is active, this property as true can
   * result in all items being expanded and do not choose to be collapsed.
   */
  expanded?: boolean;
  color?: "light" | "primary" | "secondary" | "highlighted" | "dark";
  size?: "small" | "medium" | "large" | "x-large";
  textAlign?: "center";
  appearance?:
    | "title"
    | "section-title"
    | "info-title"
    | "description"
    | "description-block"
    | "info-subtitle"
    | "carousel-title"
    | "carousel-subtitle"
    | "description-banner"
    | "menu-title"
    | "price"
    | "info-name"
    | "info-value"
    | "info-description"
    | "block-title"
    | "small-title"
    | "small-description"
    | "details-description";
  transform?: "lowercase" | "uppercase" | "capitalize";
}

export default (props: Props) => {
  const [itemSelected, setItemSelected] = React.useState<string>(
    props.multiSelect === false && props.expanded !== undefined ? "0" : ""
  );

  const handleChangeItemSelected = (itemId: string) => {
    setItemSelected(itemId);
  };

  return (
    <>
      <Accordion>
        {props.items.map((i) => (
          <AcordeonItem
            key={i.id}
            onClick={handleChangeItemSelected}
            selected={itemSelected}
            multiSelect={props.multiSelect}
            expanded={props.expanded}
            item={i}
            lastChild={props.items.lastIndexOf(i) === props.items.length - 1 ? true : false}
          />
        ))}
      </Accordion>
    </>
  );
};
