import { Profile, ProfilePreferences, Role } from "biohub-model";
import {
  BiohubLocale,
  BiohubRegion,
  LocalePersistedState,
  LocaleState,
} from "../reducers/localeReducer";
import { SystemState } from "../reducers/systemReducer";
import { SystemThunk } from "../systemThunk";
import { updateProfilePreferences } from "./profileActions";
import { PlansState } from "../reducers/plansReducer";
import { ProfilePersistedState, ProfileState } from "../reducers/profileReducer";
import { LoginPersistedState, LoginState } from "../reducers/loginReducer";
import { CacheDataPersistedState, CacheDataState } from "../reducers/cacheDataReducer";

// Action constants
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const CHANGE_REGION = "CHANGE_REGION";
export const SET_LOCALE_FROM_PREFERENCES = "SET_LOCALE_FROM_PREFERENCES";

// Action types
export type LocaleAction =
  /**
   * Action for setting the current locale based on the user's preferences.
   * This action should be emitted at a login.
   */
  | {
      type: typeof SET_LOCALE_FROM_PREFERENCES;
    }
  | {
      type: typeof CHANGE_REGION;
      payload: {
        region: BiohubRegion;
      };
    }
  /**
   * Action for manually setting a locale. If the user is logged in,
   * this will also change locale in the user's settings.
   */
  | {
      type: typeof CHANGE_LOCALE;
      payload: {
        locale: BiohubLocale;
      };
    };

// Action functions

export function setLocaleFromPreferences(): LocaleAction {
  return {
    type: SET_LOCALE_FROM_PREFERENCES,
  };
}

export function changeLocale(locale: BiohubLocale): SystemThunk {
  return async (dispatch, getState) => {
    // Fire a request for changing user preferences, but don't wait for it.
    // The request will complete in the background, and is meant to fail silently.
    // TODO! Send a request to update user preferences!
    // TODO: Check that integration

    const userProfile: Profile | null = getState().profile.userProfile;
    const profilePrefences: ProfilePreferences | undefined =
      userProfile !== null && userProfile.role !== Role.external
        ? userProfile.preferences
        : undefined;
    if (profilePrefences !== undefined && locale !== profilePrefences.languageCode) {
      dispatch(updateProfilePreferences({ ...profilePrefences, languageCode: locale }));
    }

    // This will set the locale locally.
    dispatch({
      type: CHANGE_LOCALE,
      payload: {
        locale: locale,
      },
    });
  };
}

export function changeUsageRegion(usageRegion: BiohubRegion): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_REGION,
      payload: {
        region: usageRegion,
      },
    });
  };
}
