import styled from "styled-components";

type Props = {};

export const Block = styled.div.attrs((props: Props) => ({}))`
  display: flex;
  flex-direction: column;
  background: var(--card-background);
  padding: 10px;
  margin: 15px;

  span:last-child {
    margin-top: auto;
  }

  @media screen and (max-width: 680px) {
    margin: 15px 5rem;
  }

  @media screen and (max-width: 579px) {
    margin: 15px 20px;
  }
`;

export const Description = styled.span.attrs((props: Props) => ({}))`
  width: 90%;
  text-align: left;
  display: block;
  line-height: 1.2;
  /* margin-bottom: 2rem; */
  padding: 10px;
`;

export const Actions = styled.span.attrs((props: Props) => ({}))`
  display: flex;
  justify-content: space-between;
  padding: 10px;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    button {
      margin-top: 15px;
    }
  }
`;
