import { Flight, FlightReport } from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

async function flightReport(queryParam: string): Promise<BiohubResponse<FlightReport>> {
  try {
    const result = await BiohubApi.get(`/flightReport?${queryParam}`);
    return newBiohubSuccess(result.data.flightReport as FlightReport);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readFlightsOfArea(areaId: string): Promise<BiohubResponse<Array<Flight>>> {
  try {
    const result = await BiohubApi.get("/flights?areaId=" + areaId);
    const flights = result.data["flights"] as Array<Flight>;
    return newBiohubSuccess(flights);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default { flightReport, readFlightsOfArea };

// export type FlightReport = {
//   //new version
//   id: string; //add
//   areaName: string; //add
//   releaseRateUnit: string;
//   planned: {
//     routeMapUrl: string;
//     inputName: string;
//     releasers: {
//       trackWidth: number;
//       speed: number;
//       releaseRate: number;
//     }[];
//   };
//   real: {
//     timestamp: Date;
//     drone: {
//       name: string;
//       anacNumber: number;
//       manufacturer: string; //add
//     };
//     routeMapUrl: string;
//     duration: number;
//     releasers: {
//       input: string;
//       averageReleaseRateMinutes: number;
//       averageReleaseRateHectare: number;
//       averageSpeed: number;
//       totalReleased: number;
//       totalAreaOverflown: number;
//     }[];
//   };
// };

// // Temporary
// const result = {
//   success: true,
//   data: {
//     id: "FLIGHT01", //add
//     areaName: "Teste_15_02_20", //add
//     releaseRateUnit: "Trichogramma exiggum - ML",
//     planned: {
//       routeMapUrl:
//         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d468258.9245399613!2d-46.44111363199002!3d-23.522686970071327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4d7fc6e7dbe1%3A0xafef88839defabc7!2sNCB%20Sistemas%20Embarcados!5e0!3m2!1spt-BR!2sbr!4v1621554104106!5m2!1spt-BR!2sbr",
//       inputName: "Trichogramma exiggum",
//       releasers: [
//         {
//           trackWidth: 8.024,
//           speed: 26.75,
//           releaseRate: 2.5,
//         },
//       ],
//     },
//     real: {
//       timestamp: new Date(),
//       drone: {
//         name: "Phantom 4 PRO",
//         anacNumber: 1912100001,
//         manufacturer: "DJI", //add
//       },
//       routeMapUrl:
//         "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d468258.9245399613!2d-46.44111363199002!3d-23.522686970071327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4d7fc6e7dbe1%3A0xafef88839defabc7!2sNCB%20Sistemas%20Embarcados!5e0!3m2!1spt-BR!2sbr!4v1621554104106!5m2!1spt-BR!2sbr",
//       duration: 9,
//       releasers: [
//         {
//           input: "Trichogramma exiggum",
//           averageReleaseRateMinutes: 2.23,
//           averageReleaseRateHectare: 2.5,
//           averageSpeed: 26.75,
//           totalReleased: 20.06,
//           totalAreaOverflown: 8.024,
//         },
//       ],
//     },
//   },
// };

// return newBiohubSuccess(result.data as FlightReport);
