import { TermsOfUse } from "biohub-model";
import {
  BiohubError,
  clearBiohubAuthorizationToken,
  setBiohubAuthorizationToken,
} from "../../services/axios/BiohubApi";
import { SystemAction } from "../actions/systemActions";
import { REHYDRATE, WebStorage } from "redux-persist";
import {
  LOG_IN_FAILURE,
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_OUT,
  RESET_ERROR,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  KEEP_DATA_PERSIST,
} from "../actions/loginActions";
import { PROFILE_READING, PROFILE_READ_SUCCESS } from "../actions/profileActions";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";

export type LoginState = {
  key: "login";
  userToken: string | null;
  userId: string | null;
  isLogging: boolean;
  isGettingUserInformation: boolean;
  isResettingPassword: boolean;
  successfullyResettingPassword: boolean | null;
  pendingTermsOfUse: TermsOfUse[] | null;
  hasPendingEmailVerification: boolean | null;
  keepLoggedIn: boolean;
  error: BiohubError | null;
};

const INITIAL_STATE: LoginState = {
  key: "login",
  userToken: null,
  userId: null,
  isLogging: false,
  isGettingUserInformation: false,
  isResettingPassword: false,
  successfullyResettingPassword: null,
  pendingTermsOfUse: null,
  hasPendingEmailVerification: null,
  keepLoggedIn: false,
  error: null,
};

export function loginReducer(state = INITIAL_STATE, action: SystemAction): LoginState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  switch (action.type) {
    // Redux-persist retrieve action
    case REHYDRATE:
      if (action.payload?.key === "login") {
        const rehydrateState = {
          ...INITIAL_STATE,
          ...action.payload,
          initializedReduxPersist: true,
        };
        const rehydrateUserToken = rehydrateState.userToken;
        if (rehydrateUserToken !== null) {
          setBiohubAuthorizationToken(rehydrateUserToken);
        } else {
          clearBiohubAuthorizationToken();
        }
        return rehydrateState;
      }
      return effectiveState;
    case PROFILE_READING:
      return {
        ...effectiveState,
        isGettingUserInformation: true,
        error: null,
      };
    case PROFILE_READ_SUCCESS:
      return {
        ...effectiveState,
        isGettingUserInformation: false,
        error: null,
      };
    case LOG_IN_REQUEST:
      return {
        ...effectiveState,
        isLogging: true,
        error: null,
      };
    case LOG_IN_SUCCESS:
      setBiohubAuthorizationToken(action.payload.userToken);
      return {
        ...effectiveState,
        isLogging: false,
        userToken: action.payload.userToken,
        userId: action.payload.userId,
      };
    case LOG_IN_FAILURE:
      clearBiohubAuthorizationToken();
      return {
        ...effectiveState,
        isLogging: false,
        isGettingUserInformation: false,
        isResettingPassword: false,
        error: action.payload.error,
        userToken: null,
        userId: null,
      };
    case RESET_ERROR:
      return {
        ...effectiveState,
        error: null,
      };
    case LOG_OUT:
      clearBiohubAuthorizationToken();
      return INITIAL_STATE;
    case RESET_PASSWORD_REQUEST:
      return {
        ...effectiveState,
        isResettingPassword: true,
        successfullyResettingPassword: null,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...effectiveState,
        isResettingPassword: false,
        successfullyResettingPassword: true,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...effectiveState,
        isResettingPassword: false,
        successfullyResettingPassword: false,
      };
    case KEEP_DATA_PERSIST:
      if (action.payload.status) {
        return {
          ...effectiveState,
          keepLoggedIn: true,
        };
      } else {
        return {
          ...effectiveState,
          keepLoggedIn: false,
        };
      }
    default:
      return effectiveState;
  }
}

export type LoginPersistedState = Omit<
  LoginState,
  "initialized" | "isLogging" | "isGettingUserInformation" | "isResettingPassword" | "error"
>;

const loginPersistConfig: PersistConfig<LoginState> = {
  key: "login",
  storage,
  whitelist: [
    "key",
    "userToken",
    "userId",
    "successfullyResettingPassword",
    "pendingTermsOfUse",
    "hasPendingEmailVerification",
    "keepLoggedIn",
  ],
};

export const loginPersistedReducer = persistReducer(loginPersistConfig, loginReducer);
