import styled from "styled-components";

type Props = {
  appearance?: "button-outline" | "outline-invert" | "terms" | "simple";
  color?: "light";
  size?: "small" | "medium" | "large";
};

export const Link = styled.a.attrs((props: Props) => ({
  appearance: props.appearance,
  color: props.color,
  size: props.size,
}))`
  font-size: 18px;
  line-height: 1;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  font-family: "GalanoGrotesque-SemiBold";
  letter-spacing: 0.75px;

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
  }

  ${(props: Props) =>
    props.appearance === "button-outline" &&
    props.color === "light" &&
    `
    border: 2px solid var(--white);
    color: var(--white);
    background: transparent;
    border-radius: 50px;
    min-width: 120px;
    min-height: 40px;
    line-height: 30px;
    margin-right: 15px;
    text-align: center;
    transition: all .3s ease;

    :hover, :focus {
      background: var(--white);
      color: var(--text-primary);      
    }

    @media screen and (max-width: 680px) {
      min-width: 80px;
      margin-right: 5px;
    }
  `}

  ${(props: Props) =>
    props.appearance === "outline-invert" &&
    `
    border: 2px solid var(--white);
    color: var(--primary);
    background: var(--white);
    border-radius: 50px;
    min-width: 180px;
    min-height: 40px;
    line-height: 30px;
    text-align: center;
    transition: all .3s ease;

    :hover, :focus {
      background: var(--secondary);
      color: var(--white);   
    }

    @media screen and (max-width: 680px) {
      min-width: 110px;
      margin-right: 10px;
    }
    
  `}

  ${(props: Props) =>
    props.appearance === "terms" &&
    `
    font-size: 14px;
    outline: none;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: var(--text-default);
    text-decoration: underline;
    vertical-align: middle;
    transition: all .3s ease;

    :hover, :focus {
      text-decoration: underline;
      text-decoration-color: var(--secondary); 
    }
  `}

  ${(props: Props) =>
    props.appearance === "simple" &&
    `
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: inline-block;
    font-family: "Galano Grotesque", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: var(--text-default);
    vertical-align: middle;
    transition: all .3s ease;

    :hover, :focus {
      color: var(--primary); 
    }
  `}

  ${(props: Props) =>
    props.size === "small" &&
    `
      font-size: 12px;
  `}

  ${(props: Props) =>
    props.size === "medium" &&
    `
      font-size: 14px;
  `}

  ${(props: Props) =>
    props.size === "large" &&
    `
      font-size: 16px;
  `}
`;
