import { Location, WeatherInfo } from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

async function retrieveWeatherData(coordinates: Location): Promise<BiohubResponse<WeatherInfo>> {
  try {
    const result = await BiohubApi.get(`/weather-for-coordinate`, {
      params: {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      },
    });
    return newBiohubSuccess(result.data as WeatherInfo);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default { retrieveWeatherData };
