import { Tab, Tabs } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import styled from "styled-components";

type Props = {};

export const Agrupable = styled.div.attrs((props: Props) => ({}))`
  display: inline-block;
  margin: 2rem 0 5rem 4rem;
  line-height: 1;

  label {
    font-family: "GalanoGrotesque-Semibold";
  }

  .MuiAlert-icon {
    margin-top: 2px;
  }

  @media screen and (max-width: 768px) {
    border: 0;
    padding: 0 5rem 0 0;
  }

  @media screen and (max-width: 580px) {
    padding: 0;
    width: 96%;
    margin: 0 auto;
    float: none;
  }
`;

export const Itens = styled(Tabs).attrs((props: Props) => ({}))`
  button {
    background: var(--white);
    border-radius: 50px;
    text-transform: initial;
    color: var(--text-primary);
    border: 2px solid transparent;
    font-size: 14px;
    margin: 5px;

    span {
      font-family: "GalanoGrotesque-Medium";
      padding-bottom: 5px;
    }
  }
  .Mui-selected {
    border-color: var(--highlighted);
  }
  .PrivateTabIndicator-colorSecondary-3 {
    background: transparent;
  }
`;

export const Controller = styled(Tab).attrs((props: Props) => ({}))``;

export const Box = styled.span.attrs((props: Props) => ({}))`
  label {
    margin-right: 5px;
  }
`;

export const ContentItem = styled.div.attrs((props: Props) => ({}))`
  .MuiFormControl-root {
    border-radius: 25px;
    width: 100%;
    z-index: 0;
  }
  .MuiSelect-icon {
    color: var(--input-secondary-text);
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 1;
    min-height: 20px;
  }
  fieldset,
  .MuiSelect-select {
    border-color: var(--input-secondary-border);
  }
  input,
  .MuiSelect-select {
    border-radius: 25px;
    background: var(--input-secondary-background);
    color: var(--input-secondary-text);
    line-height: 1;
    :focus {
      color: var(--input-secondary-text-focus) !important;
    }
  }
  div label {
    color: var(--input-secondary-text);
    font-family: "Galano Grotesque";
    font-size: 13px;
  }
  .Mui-focused {
    fieldset,
    .MuiSelect-select {
      border-color: var(--input-secondary-border-focus) !important;
      background: transparent !important;
    }
    color: var(--input-secondary-text-focus) !important;
  }
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense) {
    top: -8px;
    width: calc(100% - 35px);
    opacity: 0.8;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
  }
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense).Mui-focused,
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense).MuiFormLabel-filled {
    top: initial;
    width: auto;
    opacity: 1;
    font-weight: 500;
    font-size: 15px;
    color: var(--input-secondary-border-focus) !important;
  }
`;

export const PhoneNumber = styled(MuiPhoneNumber).attrs((props: Props) => ({}))`
  .MuiInput-underline: {
    borderBottom: 5px solid var(--input-tertiary-border-focus);
  }
`;