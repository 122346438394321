import {
  Icon,
  CircularProgress,
  Button,
  TextField,
  Grid,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  makeStyles,
  createStyles,
  Backdrop,
  Theme,
} from "@material-ui/core";
import {
  Address,
  Company,
  DirectClient,
  IndirectClient,
  Profile,
  ProfilePreferences,
  Role,
} from "biohub-model";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ProfileState } from "../../../store/reducers/profileReducer";
import { SystemState } from "../../../store/reducers/systemReducer";
import FlipCard from "../../../components/Atomic/BasicComponents/FlipCard";
import { Avatar, Block, Title, Infos, Actions, Loading, Modal } from "../styles";
import ProfileTables from "./ProfileTables";
import EditProfile from "./EditProfileAndCompany";
import {
  updateCompany,
  updateProfile,
  uploadImagePhoto,
} from "../../../store/actions/profileActions";
import { BiohubLocale } from "../../../store/reducers/localeReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export default function ProfileTable() {
  const allowedImageFormats = ["image/jpeg", "image/jpg", "image/png"];
  const maxImageSizeAllowed = 2 * 1024 * 1024;

  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [errorImage, setErrorImage] = useState<boolean>(false);
  const [photo, setPhoto] = useState<File | null>(null);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [profileChanges, setProfileChanges] = React.useState<
    | ({ id: string } & Partial<Profile> & {
          role: Role.admin | Role.external | Role.manager | Role.master | Role.operator;
        })
    | undefined
  >(undefined);
  const [companyChanges, setCompanyChanges] = React.useState<
    ({ id: string } & Partial<Company>) | undefined
  >(undefined);

  //terms
  const termContract = intl.formatMessage({ id: "info.contract" });
  const termSettings = intl.formatMessage({ id: "menu.settings" });
  const termEditPicture = intl.formatMessage({ id: "action.edit.picture" });
  const termEditPictureTitle = intl.formatMessage({ id: "info.profile.edit.image" });
  const termEditPictureDialog = intl.formatMessage({ id: "info.profile.edit.image.dialog" });
  const termEditPictureChoose = intl.formatMessage({ id: "info.profile.edit.image.buttom.choose" });
  const termEditPictureSend = intl.formatMessage({ id: "info.profile.edit.image.buttom.send" });
  const termEditPictureCancel = intl.formatMessage({ id: "info.profile.edit.image.buttom.cancel" });
  const termEditPictureInvalid = intl.formatMessage({ id: "info.profile.edit.image.invalid" });
  const termPlaceHolderChoosePicture = intl.formatMessage({
    id: "placeholder.profile.select.picture",
  });

  // temporary picture - styling only
  const picture = "https://i.pravatar.cc/120?img=8"; // profile.picture

  const profile: Profile | null = useSelector((state: SystemState) => state.profile.userProfile);
  const loading: boolean = useSelector(
    (state: SystemState) => state.profile.loadingProfile || state.profile.loadingCompany
  );
  const openLoading: boolean = useSelector(
    (state: SystemState) => state.profile.loadingProfileImage
  );
  const directClient = useSelector((state: SystemState) => {
    if (state.profile.directClient !== null) {
      return state.profile.directClient;
    }
    return undefined;
  });
  const indirectClient = useSelector((state: SystemState) => {
    if (state.profile.indirectClient !== null) {
      return state.profile.indirectClient;
    }
    return undefined;
  });

  const handleUploadImage = () => {
    setOpen(false);
    if (photo) {
      const formData = new FormData();
      formData.append("profileImage", photo);
      if (profile !== null) dispatch(uploadImagePhoto(formData, profile.id));
    }
  };

  const handleChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== undefined && e.target.files !== null) {
      const file = e.target.files[0];
      if (file && allowedImageFormats.includes(file.type) && file.size <= maxImageSizeAllowed) {
        setPhoto(file);
        setErrorImage(false);
      } else {
        setErrorImage(true);
      }
    }
  };

  const handleCloseDialog = (e: any) => {
    setOpen(false);
  };

  return (
    <>
      {/* inform the property "avatar" if displaying image - Avatar Component */}
      {/* the flipcard can be removed if there is no need for editing (or just the edit button and fields) */}

      {!loading && profile !== null ? (
        <FlipCard
          active={false}
          frontChildren={[
            <>
              <Block elevation={3} avatar>
                {profile.profileImageUrl && (
                  <Avatar>
                    <img src={profile.profileImageUrl} alt={profile.name} />
                  </Avatar>
                )}
                {profile !== undefined && (
                  <Button onClick={() => setOpen(true)}>
                    <Icon>create</Icon> {termEditPicture}
                  </Button>
                )}
                {profile !== undefined && <Box height={10} />}

                <Title>{profile.name}</Title>
                <Infos>
                  <ul>
                    <li>
                      <Icon>drafts</Icon>
                      <a href={`mailto:${profile.email}`}>{profile.email}</a>
                    </li>
                    <li>
                      <Icon>phone</Icon>
                      <a href={`tel:${profile.cellphoneNumber}`}>{profile.cellphoneNumber}</a>
                    </li>
                  </ul>
                </Infos>

                {directClient !== undefined && profile.role <= Role.admin && (
                  <Button
                    onClick={() => {
                      console.log(directClient.contract)
                      window.open(directClient.contract.documentUrl, "_blank");
                    }}
                  >
                    <Icon>visibility</Icon> <h6>{termContract}</h6>{" "}
                  </Button>
                )}
                {/* {(profile.role === Role.admin || profile.role === Role.master) && (
                  <>
                    <Description>You are a NCB's client. ;)!! </Description>
                  </>
                )} */}
              </Block>
              <Box height={10} />
              <Grid container spacing={1}>
                <Grid item lg={4}>
                  <Block elevation={3}>
                    <EditProfile
                      profile={profile}
                      company={directClient?.company}
                      pictureUrl={profile.profileImageUrl}
                      // onProfileChange={onProfileChange}
                      // onCompanyChange={onCompanyChange}
                      // actualPassword={actualPassword}
                      // onChangeActualPassword={(value) => setActualPassword(value)}
                      // newPassword={newPassword}
                      // onChangeNewPassword={(value) => setNewPassword(value)}
                      // confirmNewPassword={confirmNewPassword}
                      // onChangeConfirmNewPassword={(value) => setConfirmNewPassword(value)}
                      directClient={directClient}
                      indirectClient={indirectClient}
                    />
                  </Block>
                </Grid>
                <Grid item lg={8}>
                  <ProfileTables />
                </Grid>
              </Grid>
            </>,
          ]}
          backChildren={
            [
              // editConfig === true && profile.role !== Role.external && (
              //   <PrefencesForm
              //     intl={intl}
              //     profilePreferences={profile.preferences}
              //     calcelFunction={() => {
              //       setEditConfig(false);
              //     }}
              //     saveFunction={_updateProfilePreferences}
              //   />
              // ),
              // editInformation && (
              //   <EditForm
              //     cancelFunction={() => {
              //       setEditInformation(false);
              //     }}
              //     saveFunction={_updateProfileAndCompany}
              //     intl={intl}
              //     profile={profile}
              //     profilePictureUrl={picture}
              //     company={company}
              //   />
              // ),
            ]
          }
        />
      ) : (
        <Loading>
          <CircularProgress />
        </Loading>
      )}

      <Backdrop className={classes.backdrop} open={openLoading} onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="confirmation-dialog-title"
        onExiting={(e) => setPhoto(null)}
      >
        <DialogTitle id="confirmation-dialog-title">{termEditPictureTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{termEditPictureDialog}</DialogContentText>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <TextField
                disabled={true}
                error={errorImage}
                helperText={errorImage ? termEditPictureInvalid : null}
                fullWidth
                margin="dense"
                variant="outlined"
                value={photo ? photo.name : termPlaceHolderChoosePicture}
                onChange={(v) => {}}
              />
            </Grid>
            <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  accept="image/*"
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleChangePhoto}
                />
                <Button className="btn-choose" variant="outlined" component="span">
                  {termEditPictureChoose}
                </Button>
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {termEditPictureCancel}
          </Button>
          <Button disabled={photo ? false : true} onClick={handleUploadImage} color="primary">
            {termEditPictureSend}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
