import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import MapAreaComponent from "../../../components/map/impl/components/Area";
import { Area, Role, UnitSystem, Waypoint } from "biohub-model";
import UnitConversionHelper from "../../../core/helper/UnitConversionHelper";
import FlightOnTheMap from "./FlightOnTheMap";
import { selectArea } from "../../../store/actions/projectTreeActions";
import RenderAccordingBoundingBox from "./RenderAccordingBoundingBox";
import LongDistanceAreaSummary from "./LongDistanceAreaSummary";
import { Clusterer } from "@react-google-maps/api/node_modules/@react-google-maps/marker-clusterer";
import LongDistanceElementsGroup from "./LongDistanceElementsGroup";

type Props = {
  areasClusterSize: number;
  projectId: string;
  areaId: string;
  selectedWaypointIndexes?: number[];
  onClickWaypoint: (area: Area, waypoint: Waypoint, waypointIndex: number) => void;
  homePointMarkerZIndex: number;
  homePointElevationMarkerZIndex: number;
  homePointElevationLabelZIndex: number;
  plannedPathZIndex: number;
  plannedPathBorderZIndex: number;
  waypointsDistanceMarkerZIndex: number;
  waypointsDistanceLabelZIndex: number;
  waypointOrientationMarkerZIndex: number;
  waypointDropMarkerZIndex: number;
  waypointElevationMarkerZIndex: number;
  waypointElevationLabelZIndex: number;
  defaultAreaPolygonZIndex: number;
  selectedAreaPoygonZIndex: number;
  areaEditingPolygonPolylineZIndex: number;
  areaEditingPolygonMarkersZIndex: number;
  clusterer: Clusterer;
  areaSummaryMarkerZIndex: number;
  getFlightReleaseTrackZIndex: (flightIndex: number) => number;
  getFlightPathZIndex: (flightIndex: number) => number;
  getFlightPathBorderZIndex: (flightIndex: number) => number;
};

export default function (props: Props): JSX.Element {
  const area = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);

    if (area === undefined) return undefined;

    return {
      ...area,
      flightList: area.flightList?.map((flight) => flight.id),
    };
  });

  const zoom = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;
    return projectTreeState.mapState.zoom;
  });
  const isAreaSelected = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const project = projectTreeState.projectList?.find((project) => project.id === props.projectId);

    return project?.selectedAreaId === props.areaId;
  });

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const mapTypeId = useSelector((state: SystemState) => {
    return state.projectTree.mapState.mapTypeId;
  });

  if (area === undefined) return <></>;

  const areaPlan = area.viewingFlightPlan ?? area.planned;

  const dispatch = useDispatch();

  return (
    <>
      {area.visible && (
        <RenderAccordingBoundingBox elementBoundingBox={area.boundingBox}>
          <LongDistanceElementsGroup
            significantSize={props.areasClusterSize}
            boundingBoxDiagonalSize={area.boundingBoxDiagonalSize}
            render={(isSizeInsignificant) =>
              isSizeInsignificant ? (
                /* Each area if your size is insignificant render a blue marker like a cluster */
                <LongDistanceAreaSummary
                  size={props.areasClusterSize}
                  boundingBox={area.boundingBox}
                  waypointsCounter={area.planned.route?.waypoints.length}
                  clusterer={props.clusterer}
                  zIndex={props.areaSummaryMarkerZIndex}
                />
              ) : (
                <MapAreaComponent
                  {...props}
                  areaPolygonZIndex={
                    isAreaSelected ? props.selectedAreaPoygonZIndex : props.defaultAreaPolygonZIndex
                  }
                  polygonId={`${props.projectId}-${area.id}`}
                  mapType={
                    mapTypeId === "labeled_roadmap" || mapTypeId === "plain_roadmap"
                      ? "roadmap"
                      : "satellite"
                  }
                  areaEditionMode={undefined}
                  isSelected={isAreaSelected}
                  isLoading={area.isUpdating || area.isRunningMissionPlanner}
                  polygon={areaPlan.polygon}
                  homePoint={areaPlan.homePoint}
                  homePointElevation={areaPlan.homePointElevation}
                  waypoints={areaPlan.route?.waypoints ?? []}
                  zoom={zoom}
                  formatHeight={(value) => {
                    return `${UnitConversionHelper.distanceValue(
                      value,
                      unitSystem,
                      1
                    )} ${UnitConversionHelper.distanceUnit(unitSystem)}`;
                  }}
                  selectedWaypointsIndexes={props.selectedWaypointIndexes ?? []}
                  onClickWaypoint={(waypoint, waypointIndex) =>
                    props.onClickWaypoint(area, waypoint, waypointIndex)
                  }
                  onPolygonClicked={() => {
                    dispatch(selectArea(props.projectId, props.areaId));
                  }}
                  onVertexMoved={undefined}
                  onHomePointMoved={undefined}
                  unitSystem={unitSystem}
                  usingOnlineElevation={area.areaConfig.mustConsiderRelief}
                />
              )
            }
          />
        </RenderAccordingBoundingBox>
      )}
      {(area.flightList ?? []).map((flightId, flightIndex) => (
        <FlightOnTheMap
          key={`area-on-the-map--flight-on-the-map-${flightId}`}
          projectId={props.projectId}
          areaId={props.areaId}
          flightId={flightId}
          releaseTracksZIndex={props.getFlightReleaseTrackZIndex(flightIndex)}
          pathZIndex={props.getFlightPathZIndex(flightIndex)}
          pathBorderZIndex={props.getFlightPathBorderZIndex(flightIndex)}
        />
      ))}
    </>
  );
}
