import styled from "styled-components";
import imageSearch from "../../../../assets/icon/icon_search.svg";

type Props = {
  color?: "light";
  open?: boolean;
};

export const SearchField = styled.input.attrs((props: Props) => ({
  color: props.color,
}))`
  outline: none;
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  position: relative;
  font-family: inherit;
  font-size: 16px;
  font-family: "GalanoGrotesque-Medium";
  border: 0;
  padding: 5px 5px 2px;
  background: transparent;
  width: 300px;
  border-radius: 0;
  color: var(--dashboard-text-default);
  letter-spacing: 0.88px;
  transition: all 0.5s;

  ::placeholder {
    font-family: "GalanoGrotesque-Semibold";
    font-size: 16px;
    letter-spacing: 0.75px;
  }

  :-ms-input-placeholder {
    font-family: "GalanoGrotesque-Semibold";
    font-size: 16px;
    letter-spacing: 0.75px;
  }

  ::-ms-input-placeholder {
    font-family: "GalanoGrotesque-Semibold";
    font-size: 16px;
    letter-spacing: 0.75px;
  }

  :focus {
    /*width: 440px;*/
    border-color: var(--dashboard-highlighted);
    color: var(--dashboard-highlighted);
  }

  @media screen and (max-width: 680px) {
    ::placeholder {
      font-size: 14px;
    }

    :-ms-input-placeholder {
      font-size: 14px;
    }

    ::-ms-input-placeholder {
      font-size: 14px;
    }
  }
`;

export const Mask = styled.span.attrs((props: Props) => ({
  color: props.color,
}))`
  border: 0;
  height: 44px;
  padding-left: 35px;
  display: block;

  ${(props: Props) =>
    props.color === "light" &&
    `  
      background: transparent url(${imageSearch}) no-repeat left center / 28px;

      @media screen and (max-width: 680px) {
        height: 50px;
        padding-left: 34px;
        background-size: 30px;
      }

      input {
       // border-color: 1px solid var(--dashboard-light-border);
        color: var(--dashboard-light-text);

        ::placeholder {
          color: var(--dashboard-light-text);
        }

        :-ms-input-placeholder { 
          color: var(--dashboard-light-text);
        }

        ::-ms-input-placeholder { 
          color: var(--dashboard-light-text);
        }

        ::-webkit-search-decoration,
        ::-webkit-search-cancel-button {
          display: none; 
        }
        
        :focus {
          border-color: var(--dashboard-btn-success);
        }
      }
  `}
`;

export const Fields = styled.span.attrs((props: Props) => ({
  className: "fields",
  open: props.open
}))`
  padding: 5px 10px;
  display: block;
  max-height: 260px;

  .MuiTextField-root {
    max-width: 600px
    
    .MuiOutlinedInput-root {
      border-radius: 25px;
    }

    .Mui-focused {
      color: white;
    }

    .Mui-focused fieldset {
      border-color: white;
      border-width: thin;
    }
  }

  .MuiGrid-grid-xs-4 {
    .MuiTextField-root {
      margin-top: 5px;
    }
  }

  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    margin-bottom: 5px;
  }

  .switch-label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }


  .MuiSelect-outlined .MuiSelect-outlined {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
  }

  fieldset {
    border-color: var(--input-border-focus);
  }

  input {
    border-radius: 25px;
    background: transparent;
    color: var(--input-text-focus);
    padding: 8px 12px;
  }

  input:-internal-autofill-selected {
    background: var(--input-background) !important;
  }

  .MuiTypography-colorTextSecondary {
    font-size: 12px;
  }

  div label {
    color: var(--input-text-focus);
    font-size: 14px;
  }

  .MuiFormControlLabel-root {
    float: left;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .actions {
    right: 10px;
    padding-top: 10px;
    bottom: 15px;
    position: absolute;
    width: 96%;
    border-color: var(--dashboard-separator-line);

    .MuiButton-contained.Mui-disabled {
      color: var(--dashboard-separator-title);
      box-shadow: none;
      background-color: var(--dashboard-table-header);
      padding-bottom: 5px;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--dashboard-hr);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    box-shadow: inset 5px 10px 12px var(--gray);
  }
`;