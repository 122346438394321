import { Button } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import React from "react";
import { ButtonGroupProps } from "react-multi-carousel";

import { ButtonGroupCarousel } from "./styles";

export default ({ next, previous }: ButtonGroupProps) => {
  return (
    <ButtonGroupCarousel className="custom-button-group">
      <Button onClick={() => previous && previous()}>
        <ArrowLeft />
      </Button>
      <Button onClick={() => next && next()}>
        <ArrowRight />
      </Button>
    </ButtonGroupCarousel>
  );
};
