import React from "react";

///
/// Module that extracts constants from the environment file (.env), checks that they exist, then
/// exports them with the proper types.
/// TO ADD NEW CONSTANTS:
/// - First, add those values to the correct .env.* file (and the .env, of course)
/// - Add the new field to ConstantsType using the variable name prefix REACT_APP_
/// - In readConstants(), read the new constant using the correct method (for string or number). That's all.
///

type ConstantsType = {
  PORT: number | undefined;
  ENV_NAME: string;
  BACKEND_URL: string;
  GOOGLE_MAPS_API_KEY: string;
  APP_VERSION: string;
};

function readConstants(): ConstantsType {
  // Read all values from the environments file. A flag will be set if any of the reads fail.
  let undefinedVarFlag: boolean = false;
  const onUndefined = () => {
    undefinedVarFlag = true;
  };

  const port = _readOptionalIntegerFromEnv("PORT");
  const envName = _readStringFromEnv("ENV_NAME", onUndefined);
  const backendUrl = _readStringFromEnv("BACKEND_URL", onUndefined);
  const googleMapsApiKey = _readStringFromEnv("GOOGLE_MAPS_API_KEY", onUndefined);
  const appVersion = _readStringFromEnv("APP_VERSION", onUndefined);

  // If this flag got set somewhere in the way, then at least one of the values is unusable.
  if (undefinedVarFlag) {
    console.warn("MISSING VARIABLE(S)");
  }

  return {
    PORT: port,
    ENV_NAME: envName,
    BACKEND_URL: backendUrl,
    GOOGLE_MAPS_API_KEY: googleMapsApiKey,
    APP_VERSION: appVersion,
  };
}

function _readOptionalStringFromEnv(variableName: keyof ConstantsType): string | undefined {
  const value = process.env[`REACT_APP_${variableName}`];
  if (!value) {
    return undefined;
  }
  return value;
}

function _readStringFromEnv(variableName: keyof ConstantsType, onUndefined: () => void): string {
  const value = process.env[`REACT_APP_${variableName}`];
  if (value === undefined) {
    console.warn(`Variable ${variableName} is missing from .env file`);
    onUndefined();
    return "";
  }
  return value;
}

function _readOptionalIntegerFromEnv(variableName: keyof ConstantsType): number | undefined {
  const value = process.env[`REACT_APP_${variableName}`];
  if (value === undefined) {
    return undefined;
  }
  const parsed = _parseEnvNumber(value);
  if (parsed === null) {
    console.warn(`Variable ${variableName} should be an integer.`);
    return undefined;
  }
  return parsed;
}

function _readIntegerFromEnv(variableName: keyof ConstantsType, onUndefined: () => void): number {
  const value = process.env[`REACT_APP_${variableName}`];
  if (value === undefined) {
    console.warn(`Variable ${variableName} is missing from .env file`);
    onUndefined();
    return 0;
  }
  const isInteger = /^[0-9]+$/.test(value); // Test that this contains only digits
  const parsed = Number(value); // Also parse it
  if (!isInteger || parsed === undefined || isNaN(parsed)) {
    console.warn(`Variable ${variableName} should be an integer`);
    onUndefined();
    return 0;
  }
  return parsed;
}

function _parseEnvNumber(value: string): number | null {
  const isInteger = /^[0-9]+$/.test(value); // Test that this contains only digits
  const parsed = Number(value); // Also parse it
  if (!isInteger || parsed === undefined || isNaN(parsed)) {
    return null;
  }
  return parsed;
}

export default readConstants();
