import React, { useEffect } from "react";
import { GraphPage, Loading, Center } from "../styles";
import {
  CircularProgress,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Column from "../../../components/Atomic/Areas/Column";
import Card from "../../../components/Atomic/BasicComponents/Card";
import Image from "../../../components/Atomic/BasicComponents/Image";
import { Button } from "@material-ui/core";
import imageLineMap from "../../../assets/temp_line.png";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import DashboardService from "../../../services/DashboardService";
import { sub } from "date-fns";
import {
  loadDashboardHectaresGraph,
  loadDashboardIncomeGraph,
} from "../../../store/actions/dashboardActions";
import DatePicker from "react-date-picker";

import { DashboardSelectedGraph } from "../../../store/reducers/dashboardReducer";

import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { format, parseISO, subDays } from "date-fns";
import { DashboardGraphResponse, Role } from "biohub-model";
import { LegendLeft, MapRight } from "../styles";
import { add } from "date-fns/esm";
import intlFormat from "date-fns/fp/intlFormat/index";

const listOfScales: Array<{ scaleNumber: number; scaleIntlTag: string }> = [
  {
    scaleNumber: 30,
    scaleIntlTag: "dashboard.time.30minutes",
  },
  {
    scaleNumber: 60,
    scaleIntlTag: "dashboard.time.1hour",
  },
  {
    scaleNumber: 480,
    scaleIntlTag: "dashboard.time.8hours",
  },
  {
    scaleNumber: 1440,
    scaleIntlTag: "dashboard.time.1day",
  },
  {
    scaleNumber: 10080,
    scaleIntlTag: "dashboard.time.1week",
  },
];

enum DashboardSelectGraphMode {
  General,
  Profile,
  IndirectClient,
  Drone,
}

function _getStringGraphMode(selected: DashboardSelectGraphMode, intl: IntlShape): string {
  const generalTerm = intl.formatMessage({ id: "dashboard.view.general" });
  const profileTerm = intl.formatMessage({ id: "dashboard.view.profile" });
  const clientTerm = intl.formatMessage({ id: "dashboard.view.client" });
  const droneTerm = intl.formatMessage({ id: "dashboard.view.drone" });

  switch (selected) {
    case DashboardSelectGraphMode.General:
      return generalTerm;
    case DashboardSelectGraphMode.Profile:
      return profileTerm;
    case DashboardSelectGraphMode.IndirectClient:
      return clientTerm;
    case DashboardSelectGraphMode.Drone:
      return droneTerm;
    default:
      return "";
  }
}

function _getGraphModeFromString(
  selected: string,
  intl: IntlShape
): DashboardSelectGraphMode | null {
  const generalTerm = intl.formatMessage({ id: "dashboard.view.general" });
  const profileTerm = intl.formatMessage({ id: "dashboard.view.profile" });
  const clientTerm = intl.formatMessage({ id: "dashboard.view.client" });
  const droneTerm = intl.formatMessage({ id: "dashboard.view.drone" });

  switch (selected) {
    case generalTerm:
      return DashboardSelectGraphMode.General;
    case profileTerm:
      return DashboardSelectGraphMode.Profile;
    case clientTerm:
      return DashboardSelectGraphMode.IndirectClient;
    case droneTerm:
      return DashboardSelectGraphMode.Drone;

    default:
      return null;
  }
}

function _selectViews(
  graphData: DashboardGraphResponse,
  mode: string,
  onChange: any,
  actualView: string,
  termItemToView: string,
  intl: IntlShape
) {
  let array: Array<string> = [];
  switch (_getGraphModeFromString(mode, intl)) {
    case DashboardSelectGraphMode.Profile:
      array = Object.keys(graphData.profiles);
      break;
    case DashboardSelectGraphMode.IndirectClient:
      array = Object.keys(graphData.indirectClients);
      break;
    case DashboardSelectGraphMode.Drone:
      array = Object.keys(graphData.drones);
      break;
    default:
      break;
  }

  if (array.length === 0) {
    return <></>;
  }

  return (
    <FormControl variant="outlined" className="select-item">
      <InputLabel id="graph-view-item-label">{termItemToView}</InputLabel>
      <Select
        labelId="graph-view-item-label"
        id="graph-view-item"
        value={actualView}
        onChange={onChange}
        label="Item to view"
        name="graph-view-item"
      >
        {array.map(function (item: string) {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

function getGraphValues(
  graphData: DashboardGraphResponse,
  mode: string,
  item: string,
  initialDate: Date,
  finalDate: Date,
  intl: IntlShape
): Array<{ date: Date; value: number }> | undefined {
  if (graphData === undefined || initialDate === undefined || finalDate === undefined) {
    return undefined;
  }
  if (mode === _getStringGraphMode(DashboardSelectGraphMode.Profile, intl)) {
    if (graphData.profiles[item] !== undefined)
      return DashboardService.convertDataToDateAndValuesArray(
        graphData,
        initialDate,
        finalDate,
        item,
        undefined,
        undefined
      );
  } else if (mode === _getStringGraphMode(DashboardSelectGraphMode.IndirectClient, intl)) {
    if (graphData.indirectClients[item] !== undefined)
      return DashboardService.convertDataToDateAndValuesArray(
        graphData,
        initialDate,
        finalDate,
        undefined,
        item,
        undefined
      );
  } else if (mode === _getStringGraphMode(DashboardSelectGraphMode.Drone, intl)) {
    if (graphData.drones[item] !== undefined)
      return DashboardService.convertDataToDateAndValuesArray(
        graphData,
        initialDate,
        finalDate,
        undefined,
        undefined,
        item
      );
  }
  return DashboardService.convertDataToDateAndValuesArray(graphData, initialDate, finalDate);
}

export default (props: {}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const initialDate = useSelector(
    (state: SystemState) => new Date(Date.parse(state.dashboard.initialDate.toString()))
  );
  const finalDate = useSelector(
    (state: SystemState) => new Date(Date.parse(state.dashboard.finalDate.toString()))
  );

  const selectedGraph = useSelector((state: SystemState) => state.dashboard.selectedGraph);
  const [graphMode, setGraphMode] = React.useState(
    _getStringGraphMode(DashboardSelectGraphMode.General, intl)
  );
  const [modeView, setModeView] = React.useState("all");
  const reduxScale = useSelector((state: SystemState) => state.dashboard.scale);
  const [selectedScale, setSelectScale] = React.useState<number>(1);

  const loading: boolean = useSelector(
    (state: SystemState) =>
      (state.profile.userProfile?.role === Role.admin ||
        state.profile.userProfile?.role === Role.manager) &&
      (state.dashboard.isLoading || state.profile.loadingProfile)
  );
  const graphData: DashboardGraphResponse | undefined = useSelector(
    (state: SystemState) => state.dashboard.graphData
  );
  const graphValues: Array<{ date: Date; value: number }> | undefined = getGraphValues(
    graphData as DashboardGraphResponse,
    graphMode,
    modeView,
    initialDate,
    finalDate,
    intl
  );

  const profileRole = useSelector((state: SystemState) => state.profile.userProfile?.role);

  const loadInformation = (
    type: DashboardSelectedGraph,
    scaleIndex: number,
    initialDate: Date,
    finalDate: Date
  ) => {
    const scale = listOfScales[scaleIndex].scaleNumber;
    switch (type) {
      case DashboardSelectedGraph.Area:
        dispatch(loadDashboardHectaresGraph(initialDate, finalDate, scale));
        break;
      case DashboardSelectedGraph.Income:
        dispatch(loadDashboardIncomeGraph(initialDate, finalDate, scale));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let defaultScale: number = 1;
    for (let i = 0; i < listOfScales.length; i++) {
      const scale = listOfScales[i];
      if (scale.scaleNumber === reduxScale) {
        defaultScale = i;
        break;
      }
    }
    loadInformation(selectedGraph, defaultScale, initialDate, finalDate);
    setSelectScale(defaultScale);
  }, []);

  //terms
  const termScale = intl.formatMessage({ id: "dashboard.scale" });
  const termGraphMode = intl.formatMessage({ id: "dashboard.graphmodeview" });
  const termItemToView = intl.formatMessage({ id: "dashboard.filter" });

  return (
    <>
      {(profileRole === Role.admin || profileRole === Role.manager) && (
        <GraphPage>
          <Card title={""} appearance={"full-card"}>
            {!loading ? (
              <>
                <MapRight>
                  <Grid container spacing={3}>
                    <Grid item lg={3} sm={6} xs={12}>
                      <DatePicker
                        name="initialDate"
                        value={initialDate}
                        onChange={(info) => {
                          if (info) {
                            const castDate: Date = new Date(info.valueOf() as number);
                            loadInformation(selectedGraph, selectedScale, castDate, finalDate);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <DatePicker
                        name="finalDate"
                        value={finalDate}
                        onChange={(info) => {
                          if (info) {
                            const castDate: Date = new Date(info.valueOf() as number);
                            loadInformation(selectedGraph, selectedScale, initialDate, castDate);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} sm={4} xs={12}>
                      <FormControl variant="outlined" className="select-item">
                        <InputLabel id="graph-view-item-label">{termScale}</InputLabel>
                        <Select
                          labelId="graph-view-item-label"
                          id="graph-view-item"
                          value={selectedScale}
                          onChange={(e) => {
                            const index: number = e.target.value as number;
                            setSelectScale(index);
                            loadInformation(selectedGraph, index, initialDate, finalDate);
                          }}
                          label={termScale}
                          name="graph-view-item"
                        >
                          {listOfScales.map(function (scale, index) {
                            return (
                              <MenuItem value={index}>
                                {intl.formatMessage({ id: scale.scaleIntlTag })}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={4} xs={12}>
                      <FormControl variant="outlined" className="select-item">
                        <InputLabel id="graph-mode-label">{termGraphMode}</InputLabel>
                        <Select
                          labelId="graph-mode-label"
                          id="graph-mode"
                          value={graphMode}
                          onChange={(e) => setGraphMode(e.target.value as string)}
                          label="Graph mode"
                          style={{ color: "black" }}
                          name="graph-mode"
                        >
                          <MenuItem
                            value={_getStringGraphMode(DashboardSelectGraphMode.General, intl)}
                          >
                            {_getStringGraphMode(DashboardSelectGraphMode.General, intl)}
                          </MenuItem>
                          <MenuItem
                            value={_getStringGraphMode(DashboardSelectGraphMode.Profile, intl)}
                          >
                            {_getStringGraphMode(DashboardSelectGraphMode.Profile, intl)}
                          </MenuItem>
                          <MenuItem
                            value={_getStringGraphMode(
                              DashboardSelectGraphMode.IndirectClient,
                              intl
                            )}
                          >
                            {_getStringGraphMode(DashboardSelectGraphMode.IndirectClient, intl)}
                          </MenuItem>
                          <MenuItem
                            value={_getStringGraphMode(DashboardSelectGraphMode.Drone, intl)}
                          >
                            {_getStringGraphMode(DashboardSelectGraphMode.Drone, intl)}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={4} xs={12}>
                      {_selectViews(
                        graphData as DashboardGraphResponse,
                        graphMode,
                        (e: any) => {
                          setModeView(e.target.value as string);
                        },
                        modeView,
                        termItemToView,
                        intl
                      )}
                    </Grid>
                    {graph(
                      graphValues as Array<{ date: Date; value: number }>,
                      selectedGraph === DashboardSelectedGraph.Income ? "$" : "",
                      selectedGraph === DashboardSelectedGraph.Income ? "" : " ha"
                    )}
                  </Grid>
                </MapRight>
                <LegendLeft>
                  <Button
                    className={
                      selectedGraph === DashboardSelectedGraph.Area
                        ? "btn-legend active"
                        : "btn-legend"
                    }
                    onClick={(e) => {
                      loadInformation(
                        DashboardSelectedGraph.Area,
                        selectedScale,
                        initialDate,
                        finalDate
                      );
                    }}
                  >
                    {intl.formatMessage({ id: "report.area" })}
                  </Button>
                  <Button
                    className={
                      selectedGraph === DashboardSelectedGraph.Income
                        ? "btn-legend active"
                        : "btn-legend"
                    }
                    onClick={(e) => {
                      loadInformation(
                        DashboardSelectedGraph.Income,
                        selectedScale,
                        initialDate,
                        finalDate
                      );
                    }}
                  >
                    {intl.formatMessage({ id: "action.income" })}
                  </Button>
                </LegendLeft>
              </>
            ) : (
              <Loading>
                <CircularProgress />
              </Loading>
            )}
          </Card>
        </GraphPage>
      )}
    </>
  );
};

function graph(data: Array<{ date: Date; value: number }>, prefix: string, sufix: string) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#2451B7" stopOpacity={0.4} />
            <stop offset="75%" stopColor="#2451B7" stopOpacity={0.05} />
          </linearGradient>
        </defs>

        <Area dataKey="value" stroke="#2451B7" fill="url(#color)" />

        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickFormatter={(str) => {
            return "";
          }}
        />

        <YAxis
          dataKey="value"
          axisLine={false}
          tickLine={false}
          tickCount={8}
          width={100}
          tickFormatter={(number) => `${prefix}${number.toFixed(2)}${sufix}`}
        />

        <Tooltip content={<CustomTooltip />} />

        <CartesianGrid opacity={0.1} vertical={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
}

function CustomTooltip(props: any) {
  const intl = useIntl();
  const { active, payload, label } = props;
  let stringDate: string = "";

  // Scale to use in the date period string
  const reduxScale = useSelector((state: SystemState) => state.dashboard.scale);
  try {
    const date: Date = new Date(Date.parse(label));
    stringDate = intl.formatDate(date, {
      weekday: "long",
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    });
    stringDate = stringDate.charAt(0).toUpperCase() + stringDate.slice(1);
    const dateEnd: Date = add(date, { minutes: reduxScale });
    const showDaySufix: boolean =
      intl.formatDate(date, { weekday: "long", day: "numeric", month: "short" }) !==
      intl.formatDate(dateEnd, { weekday: "long", day: "numeric", month: "short" });
    const stringSufix = intl.formatDate(dateEnd, {
      weekday: showDaySufix ? "long" : undefined,
      day: showDaySufix ? "numeric" : undefined,
      month: showDaySufix ? "short" : undefined,
      hour: "2-digit",
      minute: "2-digit",
    });

    stringDate = stringDate + " - " + stringSufix;
  } catch (e) {}
  if (active) {
    return (
      <div className="tooltip">
        <h4 style={{ color: "black" }}>{stringDate}</h4>
        <p style={{ color: "black" }}>
          {payload !== null && payload.length > 0 ? payload[0].value.toFixed(2) : ""}
        </p>
      </div>
    );
  }
  return null;
}
