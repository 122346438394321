import styled from "styled-components";

export const ButtonGroupCarousel = styled.div.attrs(() => ({}))`
  button {
    min-width: unset;
    width: 50px;
    height: 50px;
    padding: 0;
    top: calc(50% - 40px);
    position: absolute;
    border-radius: 50%;
    border: 1px solid var(--primary);

    &:first-child {
      left: calc(-5% - 2px);
    }

    &:last-child {
      right: calc(-5% - 2px);
    }

    svg {
      font-size: 2rem;
      color: var(--primary);
    }
  }

  @media screen and (max-width: 1480px) {
    button {
      &:first-child {
        left: calc(-1% - 2px);
      }

      &:last-child {
        right: calc(-1% - 2px);
      }
    }
  }

  @media screen and (max-width: 600px) {
    button {
      &:first-child {
        left: calc(-1% + 10px);
      }

      &:last-child {
        right: calc(-1% + 10px);
      }
    }
  }
`;
