import {
  DirectClient,
  IndirectClient,
  Profile,
  ProfilePreferences,
  Role,
  UnitSystem,
  Address,
} from "biohub-model";
import { addMinutes } from "date-fns";
import BiohubApi, {
  BiohubResponse,
  BiohubUnit,
  biohubUnit,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

function removeNullOnUndefinedAddress(address: Address): Address {
  if (address.complement === null) delete address.complement;
  if (address.cityCode === null) delete address.cityCode;
  return address;
}

async function getProfile(userId: string): Promise<BiohubResponse<Profile>> {
  try {
    const result = await BiohubApi.get(`/profiles/${userId}`);
    const profile = result.data.profile as Profile;
    if (profile.role !== Role.external) {
      profile.address = removeNullOnUndefinedAddress(profile.address);
    }
    return newBiohubSuccess(profile);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateProfile(
  profile: { id: string } & Partial<Profile>,
  actualPassword?: string,
  newPassword?: string
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.put("/profiles/" + profile.id, {
      profile: profile,
      actualPassword:
        actualPassword !== undefined && actualPassword.length > 0 ? actualPassword : undefined,
      newPassword: newPassword !== undefined && newPassword.length > 0 ? newPassword : undefined,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readDirectClient(directClientId: string): Promise<BiohubResponse<DirectClient>> {
  try {
    const result = await BiohubApi.get("/direct-clients/" + directClientId);
    const directClient = result.data["directClient"] as DirectClient;
    if (directClient.company !== undefined) {
      directClient.company.address = removeNullOnUndefinedAddress(directClient.company.address);
    }
    return newBiohubSuccess(directClient);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readIndirectClient(): Promise<BiohubResponse<IndirectClient>> {
  try {
    const result = await BiohubApi.get("/indirect-clients");
    const indirectClient = result.data["indirectClient"];
    if (indirectClient === null) {
      throw Error("Error getting the indirect client");
    }

    return newBiohubSuccess(indirectClient as IndirectClient);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readIndirectClientProfile(
  indirectClientId: string
): Promise<BiohubResponse<Profile>> {
  try {
    const result = await BiohubApi.get("/indirect-clients/profile/" + indirectClientId);
    const profile = result.data["profile"] as Profile;
    return newBiohubSuccess(profile);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readDirectClients(): Promise<BiohubResponse<Array<DirectClient>>> {
  try {
    const result = await BiohubApi.get("/direct-clients");
    const directClients = result.data["directClients"] as Array<DirectClient>;

    directClients.forEach((directClient) => {
      if (directClient.company !== undefined) {
        directClient.company.address = removeNullOnUndefinedAddress(directClient.company.address);
      }
    });

    return newBiohubSuccess(directClients);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateDirectClient(
  directClient: { id: string } & Partial<DirectClient>
): Promise<BiohubResponse<BiohubUnit>> {
  //TODO: Backend implementation
  try {
    await BiohubApi.put("/direct-clients/" + directClient.id, directClient);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/*async function readProfiles(profile: Profile): Promise<BiohubResponse<Array<Profile>>> {
  if (profile.directClientId === null) {
    return extractBiohubErrorResponse(Error("Can't read the direct client of that profile"));
  }
  const readDirectClientResult = await readDirectClient(profile.directClientId);
  if (!readDirectClientResult.success) {
    return readDirectClientResult;
  }

  const directClient = readDirectClientResult.data as DirectClient;
  return newBiohubSuccess(directClient.profiles);
}*/

async function createProfile(profile: Profile): Promise<BiohubResponse<BiohubUnit>> {
  try {
    const response = await BiohubApi.post("/profiles", { profile: profile });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function removeProfile(profileId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.delete("/profiles/" + profileId);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readIndirectClients(
  directClientId: string
): Promise<BiohubResponse<Array<IndirectClient>>> {
  try {
    const response = await BiohubApi.get("/indirect-clients/" + directClientId);
    const indirectClients = response.data["indirectClients"] as Array<IndirectClient>;
    return newBiohubSuccess(indirectClients);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function addIndirectClient(
  indirectClient: IndirectClient,
  profile: Profile
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.post("/indirect-clients", { indirectClient: indirectClient, profile: profile });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateIndirectClient(
  indirectClient: { id: string } & Partial<IndirectClient>
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.put("/indirect-clients/" + indirectClient.id, {
      indirectClient: indirectClient,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function removeIndirectClient(indirectClientId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.delete("/indirect-clients/" + indirectClientId);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateProfilePreferences(preferences: { id: string } & Partial<ProfilePreferences>) {
  try {
    await BiohubApi.put("/profile-preferences/" + preferences.id, {
      profilePreferences: preferences,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function beginSetupAccount(
  token: string
): Promise<BiohubResponse<{ partialEmail: string; partialNumber: string }>> {
  try {
    const result = await BiohubApi.get("/set-first-password", {
      params: {
        token: token,
      },
    });
    return newBiohubSuccess(result.data as { partialEmail: string; partialNumber: string });
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function setupAccountRequestSms(
  token: string
): Promise<BiohubResponse<{ validUntil: Date }>> {
  try {
    const result = await BiohubApi.put(`/profile/sms-code?token=${token}`);
    return newBiohubSuccess({ validUntil: result.data as Date });
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateImageProfile(formData: FormData): Promise<BiohubResponse<BiohubUnit>> {
  try {
    const result = await BiohubApi.put("/profiles/profile-image", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateDirectClientLogo(formData: FormData): Promise<BiohubResponse<BiohubUnit>> {
  try {
    const result = await BiohubApi.put("/direct-clients/logo", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateIndirectClientLogo(formData: FormData): Promise<BiohubResponse<BiohubUnit>> {
  try {
    const result = await BiohubApi.put("/indirect-clients/logo", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function setupAccount(
  token: string,
  verificationCode: string,
  password: string
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    // TODO: Capture request errors
    const result = await BiohubApi.post("/set-first-password", {
      token: token,
      password: password,
      smsCode: verificationCode,
    });
    //console.log(result)
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function requestResetPassword(email: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    const result = await BiohubApi.get("/resetPassword/?email=" + email);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default {
  getProfile,
  updateProfile,
  readDirectClient,
  updateDirectClient,
  readIndirectClientProfile,
  //readProfiles,
  createProfile,
  removeProfile,
  readIndirectClients,
  addIndirectClient,
  removeIndirectClient,
  updateIndirectClient,
  updateProfilePreferences,
  beginSetupAccount,
  setupAccountRequestSms,
  setupAccount,
  readDirectClients,
  updateImageProfile,
  updateDirectClientLogo,
  updateIndirectClientLogo,
  requestResetPassword,
  readIndirectClient,
};
