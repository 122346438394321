import styled from "styled-components";

type Props = {
  background?: string | "url";
  slogan?: string;
  lang?: string;
};

export const Teaser = styled.section.attrs((props: Props) => ({
  background: props.background,
}))`
  min-height: 100vh;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: var(--background-default);

  ${(props: Props) =>
    props.background &&
    `
    background-image: url(${props.background}); 
    :before {
      content: "";
      background: var(--overlay-85);
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    } 

    animation-duration: 30s;
    animation-name: droneAnimation;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background-attachment: fixed;

  `}

  @media screen and (max-width: 1440px) {
    background-repeat: repeat-y;
  }
`;

export const Container = styled.div`
  width: 80vw;
  height: auto;
  display: flex;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  align-self: flex-start;
  flex: 1;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 90%;
  }
`;

export const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 2;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    margin: 2rem auto;
  }

  @media screen and (max-width: 768px) {
    display: table;
    margin: 0 auto;
  }
`;

export const Description = styled.span`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: auto;
  label:first-of-type {
    margin: 15px 0;
    display: block;
  }
`;

export const Logo = styled.span.attrs((props: Props) => ({
  slogan: props.slogan,
  lang: props.lang,
}))`
  color: var(--white);
  position  relative;
  margin-left: -8px;
  
  @media screen and (max-width: 768px) {
    img {
      width: 220px;
      margin-top: 0;
    }
  }
  
  @media screen and (max-width: 480px) {
    img {
      width: 160px;
      margin: 0;
      margin-left: -8px;
    }
  }

  ${(props: Props) =>
    props.slogan &&
    `
      :after {
        content: "${props.slogan}";
        font-size: 20.85px;
        font-family: "Gilmer";
        line-height: 1;
        left: 8px;
        position: absolute;
        bottom: 1px;
        font-weight: 500;
        letter-spacing: -.05px;
        white-space: nowrap;
      }

      @media screen and (max-width: 480px) {
        :after { font-size: 15px; }
      }
  `}

`;
