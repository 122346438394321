/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IntlShape, useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-date-picker";
import { DirectClient, DocumentType, IndirectClient, Profile, Role } from "biohub-model";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import {
  CircularProgress,
  Grid,
  Select,
  Button,
  Icon,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import {
  addIndirectClient,
  updateIndirectClient,
  removeIndirectClient,
} from "../../../store/actions/profileActions";
import UserTableComponent from "../../../components/Atomic/UserTable";
import InsertPhone from "../../../components/Atomic/Inputs/Phone";

import { TableList, Modal } from "../styles";
import {
  getNumberCountry,
  CountryResponse,
  isNotPresentValue,
  isValidEmail,
  isValidPhone,
  isValidNumber,
} from "../../../services/FormValidatorsService";
import { isEmpty, isNull, isUndefined } from "lodash";
import { isValidPhoneNumber } from "react-phone-number-input";
import ProfileService from "../../../services/ProfileService";
import DropdownOptions from "../../../components/Atomic/Inputs/DropdownOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  menuPaper: {
    maxHeight: 200,
    top: "200px !important",
  },
}));

export default function IndirecClientTable() {
  const classes = useStyles();
  const [languageCode, setLanguageCode] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const userProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  const loadingIndirectClients: boolean = useSelector(
    (state: SystemState) => state.profile.loadingIndirectClients
  );

  const [editingIndirectClient, editingIndirectClientSetVale] = React.useState<IndirectClient>();

  const dispatch = useDispatch();

  const directClient: DirectClient = useSelector(
    (state: SystemState) => state.profile.directClient as DirectClient
  );
  const indirectClients: IndirectClient[] | null = useSelector(
    (state: SystemState) => state.profile.indirectClients
  );

  const viewIndirectClient = (indirectClient?: IndirectClient, open?: boolean) => {
    if (indirectClient !== undefined) {
      editingIndirectClientSetVale(indirectClient);
    }
    editingIndirectClientSetVale(indirectClient);
    if (indirectClient !== undefined) {
      setOpen(true);
    }
    if (open !== undefined) {
      editingIndirectClientSetVale(undefined);
      setOpen(open);
    }
  };

  const handleClickNewIndirectClient = () => {
    viewIndirectClient(undefined, true);
  };

  const handleCloseNewIndirectClient = () => {
    viewIndirectClient(undefined, false);
  };

  const handleClickEditIndirectClient = (indirectClient: IndirectClient) => {
    viewIndirectClient(indirectClient);
  };

  const handleCloseEditIndirectClient = () => {
    viewIndirectClient(undefined, false);
  };

  const _extractNumberInformations = (phone: string): CountryResponse => {
    return getNumberCountry(phone);
  };

  const listToView: Array<IndirectClient> = indirectClients === null ? [] : indirectClients;

  const handlerAddIndirectClient = (
    addingIndirectClient: EditingIndirectClient,
    addingIndirectClientProfile: EditingIndirectClientProfile
  ) => {
    const indirectClient: IndirectClient = {
      ...addingIndirectClient,
      id: uuid(),
      logoUrl: "",
      price: parseFloat(addingIndirectClient.price.replace(",", ".")),
      directClientId: directClient.id,
    };

    const profile: Profile = {
      ...addingIndirectClientProfile,
      id: uuid(),
      role: Role.external,
      indirectClientId: indirectClient.id,
      directClientId: null,
      profileImageUrl: "",
      isRegistrationCompleted: false,
    };

    dispatch(addIndirectClient(indirectClient, profile));
    handleCloseNewIndirectClient();
  };

  const handleEditIndirectClient = (updatingIndirectClient: EditingIndirectClient) => {
    if (editingIndirectClient === undefined) return;

    const _editingIndirectClient = editingIndirectClient;

    let updatedIndirectClient: Partial<IndirectClient> & { id: string } = {
      id: _editingIndirectClient.id,
    };

    for (let [_key, value] of Object.entries(updatingIndirectClient)) {
      const key: keyof IndirectClient = _key as keyof IndirectClient;
      let field;
      if (_editingIndirectClient[key] !== value) {
        if (key === "price") {
          field = parseFloat(value as string);
        } else {
          field = value;
        }
        updatedIndirectClient = { ...updatedIndirectClient, [key]: field };
      }
    }

    dispatch(updateIndirectClient(updatedIndirectClient));
    handleCloseEditIndirectClient();
  };

  // terms
  const intl = useIntl();
  const termAddNew = intl.formatMessage({ id: "action.add.new" });
  const termClients = intl.formatMessage({ id: "info.clients" });
  const termName = intl.formatMessage({ id: "placeholder.name" });
  const termEmail = intl.formatMessage({ id: "placeholder.email" });
  const termContact = intl.formatMessage({ id: "placeholder.contact" });

  return (
    <div>
      {userProfile !== null &&
        userProfile.role === Role.admin &&
        (!loadingIndirectClients ? (
          <UserTableComponent<IndirectClient>
            items={listToView}
            titleTerm={termClients}
            addTerm={termAddNew}
            onAddFunction={handleClickNewIndirectClient}
            classes={["id", termName, termEmail, termContact]}
            formatItems={(item: IndirectClient) => {
              return [item.id, item.name, item.email, item.cellphoneNumber];
            }}
            onViewItem={(itemId: string) => {
              let indirectClient: IndirectClient | undefined = undefined;
              for (let i = 0; i < listToView.length && indirectClient === undefined; i++) {
                if (itemId === listToView[i].id) {
                  indirectClient = listToView[i];
                }
              }
              if (indirectClient !== undefined) handleClickEditIndirectClient(indirectClient);
            }}
            onRemoveFunction={(items: Array<string>) => {
              for (let i = 0; i < items.length; i++) {
                dispatch(removeIndirectClient(items[i]));
              }
            }}
          />
        ) : (
          <CircularProgress />
        ))}
      <div>
        <AddEditIndirectClient
          key={editingIndirectClient?.id ?? "add-edit-indirect-client"}
          open={open}
          editing={editingIndirectClient !== undefined ? true : false}
          indirectClient={editingIndirectClient}
          classeMenuPaper={classes.menuPaper}
          finishCallback={
            editingIndirectClient === undefined
              ? handlerAddIndirectClient
              : handleEditIndirectClient
          }
          cancelCallback={
            editingIndirectClient === undefined
              ? handleCloseNewIndirectClient
              : handleCloseEditIndirectClient
          }
        />
      </div>
    </div>
  );
}

type EditingIndirectClient = Omit<
  Omit<IndirectClient, "price"> & { price: string },
  "directClientId" | "id" | "logoUrl"
>;
type EditingIndirectClientProfile = Omit<
  Profile & { role: Role.external },
  | "id"
  | "profileImageUrl"
  | "latestRequestAt"
  | "isRegistrationCompleted"
  | "directClientId"
  | "indirectClientId"
  | "role"
>;

function AddEditIndirectClient(props: {
  classeMenuPaper: string;
  open: boolean;
  editing: boolean;
  indirectClient?: IndirectClient;
  finishCallback: (
    indirectClient: EditingIndirectClient,
    profile: EditingIndirectClientProfile
  ) => void;
  cancelCallback: () => void;
}) {
  const extractIndirectClientProps = (): EditingIndirectClient => {
    return props.indirectClient !== undefined
      ? { ...props.indirectClient, price: props.indirectClient.price.toFixed(3) }
      : {
          name: "",
          email: "",
          document: "",
          documentType: DocumentType.cnpj,
          cellphoneNumber: "",
          price: "",
          priceCurrencyCode: "",
          nextDueDate: new Date(),
        };
  };

  const [editingIndirectClient, setEditingIndirectClient] = React.useState<EditingIndirectClient>(
    extractIndirectClientProps()
  );
  const [editingIndirectClientProfile, setEditingIndirectClientProfile] =
    React.useState<EditingIndirectClientProfile>({
      name: "",
      email: "",
      cellphoneNumber: "",
    });

  React.useEffect(() => {
    if (props.open) {
      setEditingIndirectClient(extractIndirectClientProps());
    }
  }, [props.open]);

  //Load indirect client profile
  const [loadingProfile, setLoadingProfile] = React.useState<boolean>(false);
  React.useEffect(() => {
    const callback = async () => {
      if (props.open) {
        if (props.editing && props.indirectClient !== undefined) {
          setLoadingProfile(true);
          const profile = await ProfileService.readIndirectClientProfile(props.indirectClient.id);
          if (!profile.success) {
            console.warn("indirect client profile not found");
          } else {
            setEditingIndirectClientProfile({
              name: profile.data.name,
              email: profile.data.email,
              cellphoneNumber: profile.data.cellphoneNumber,
            });
          }
          setLoadingProfile(false);
        } else {
          setLoadingProfile(false);
        }
      }
    };
    callback();
  }, [props.open, props.editing, props.indirectClient]);

  const handleIndirectClientChange = (event: React.ChangeEvent<any>) => {
    setEditingIndirectClient({ ...editingIndirectClient, [event.target.name]: event.target.value });
  };

  const handleIndirectClientProfileChange = (event: React.ChangeEvent<any>) => {
    setEditingIndirectClientProfile({
      ...editingIndirectClientProfile,
      [event.target.name]: event.target.value,
    });
  };

  const [invalidIndirectClientName, setInvalidIndirectClientName] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (editingIndirectClient.name.length > 0 || invalidIndirectClientName !== undefined) {
      setInvalidIndirectClientName(isNotPresentValue(editingIndirectClient.name));
    }
  }, [editingIndirectClient.name]);

  const [invalidIndirectClientEmail, setInvalidIndirectClientEmail] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (editingIndirectClient.email.length > 0 || invalidIndirectClientEmail !== undefined) {
      setInvalidIndirectClientEmail(isValidEmail(editingIndirectClient.email));
    }
  }, [editingIndirectClient.email]);

  const [invalidIndirectClientCellphone, setInvalidIndirectClientCellphone] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (
      editingIndirectClient.cellphoneNumber.length > 0 ||
      invalidIndirectClientCellphone !== undefined
    ) {
      setInvalidIndirectClientCellphone(isValidPhone(editingIndirectClient.cellphoneNumber));
    }
  }, [editingIndirectClient.cellphoneNumber]);

  const [invalidInidirectClientDocument, setInvalidInidirectClientDocument] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (editingIndirectClient.document.length > 0 || invalidInidirectClientDocument !== undefined) {
      setInvalidInidirectClientDocument(isNotPresentValue(editingIndirectClient.document));
    }
  }, [editingIndirectClient.document]);

  const [invalidIndirectClientCurrencyCode, setInvalidIndirectClientCurrencyCode] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (
      editingIndirectClient.priceCurrencyCode.length > 0 ||
      invalidIndirectClientCurrencyCode !== undefined
    ) {
      setInvalidIndirectClientCurrencyCode(
        isNotPresentValue(editingIndirectClient.priceCurrencyCode)
      );
    }
  }, [editingIndirectClient.priceCurrencyCode]);

  const [invalidIndirectClientPrice, setInvalidIndirectClientPrice] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (editingIndirectClient.price.length > 0 || invalidIndirectClientPrice !== undefined) {
      setInvalidIndirectClientPrice(isValidNumber(editingIndirectClient.price));
    }
  }, [editingIndirectClient.price]);

  const [invalidProfileName, setInvalidProfileName] = React.useState<boolean | undefined>(
    undefined
  );
  React.useEffect(() => {
    if (editingIndirectClientProfile.name.length > 0 || invalidProfileName !== undefined) {
      setInvalidProfileName(isNotPresentValue(editingIndirectClientProfile.name));
    }
  }, [editingIndirectClientProfile.name]);

  const [invalidProfileEmail, setInvalidProfileEmail] = React.useState<boolean | undefined>(
    undefined
  );
  React.useEffect(() => {
    if (editingIndirectClientProfile.email.length > 0 || invalidProfileEmail !== undefined) {
      setInvalidProfileEmail(isValidEmail(editingIndirectClientProfile.email));
    }
  }, [editingIndirectClientProfile.email]);

  const [invalidProfileCellphoneNumber, setInvalidProfileCellphoneNumber] = React.useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    if (
      editingIndirectClientProfile.cellphoneNumber.length > 0 ||
      invalidProfileCellphoneNumber !== undefined
    ) {
      setInvalidProfileCellphoneNumber(isValidPhone(editingIndirectClientProfile.cellphoneNumber));
    }
  }, [editingIndirectClientProfile.cellphoneNumber]);

  const validInput =
    invalidIndirectClientName !== undefined &&
    invalidIndirectClientName === false &&
    invalidIndirectClientEmail !== undefined &&
    invalidIndirectClientEmail === false &&
    invalidIndirectClientCellphone !== undefined &&
    invalidIndirectClientCellphone === false &&
    invalidInidirectClientDocument !== undefined &&
    invalidInidirectClientDocument === false &&
    invalidIndirectClientCurrencyCode !== undefined &&
    invalidIndirectClientCurrencyCode === false &&
    invalidIndirectClientPrice !== undefined &&
    invalidIndirectClientPrice === false &&
    ((!props.editing && invalidProfileName !== undefined && invalidProfileName === false) ||
      props.editing) &&
    ((!props.editing && invalidProfileEmail !== undefined && invalidProfileEmail === false) ||
      props.editing) &&
    ((!props.editing &&
      invalidProfileCellphoneNumber !== undefined &&
      invalidProfileCellphoneNumber === false) ||
      props.editing);

  const intl = useIntl();

  return (
    <Modal
      open={props.open}
      onClose={(e) => props.cancelCallback()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {props.editing
          ? intl.formatMessage({ id: "info.client.edit" })
          : intl.formatMessage({ id: "info.client.add" })}
        <Icon onClick={(e) => props.cancelCallback()}>close</Icon>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.editing
            ? intl.formatMessage({ id: "info.client.edit.message" })
            : intl.formatMessage({ id: "info.client.add.message" })}
        </DialogContentText>
        <Grid container spacing={1}>
          <b>{intl.formatMessage({ id: "info.client.companyInformation" }) + "*"}</b>
          <Grid item lg={12} xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={intl.formatMessage({ id: "placeholder.name" })}
              fullWidth
              name="name"
              variant="outlined"
              value={editingIndirectClient.name}
              error={invalidIndirectClientName}
              onChange={handleIndirectClientChange}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              margin="dense"
              id="email"
              label={intl.formatMessage({ id: "placeholder.email" }) + "*"}
              fullWidth
              name="email"
              variant="outlined"
              value={editingIndirectClient.email}
              error={invalidIndirectClientEmail}
              onChange={handleIndirectClientChange}
            />
          </Grid>
          <InsertPhone
            fieldId="cellphoneNumber"
            labelId="languageCode-label"
            label={intl.formatMessage({ id: "placeholder.cellphone" }) + "*"}
            error={invalidIndirectClientCellphone}
            onChange={handleIndirectClientChange}
            paperClass={props.classeMenuPaper}
            phone={editingIndirectClient.cellphoneNumber}
          />
          <Grid item lg={6} xs={6}>
            <DropdownOptions<DocumentType>
              baseId="documenttype"
              label={intl.formatMessage({ id: "info.client.documentType" })}
              emptyOption={intl.formatMessage({ id: "info.none" })}
              options={[DocumentType.cpf, DocumentType.cnpj, DocumentType.iva]}
              getOptionName={(type) => {
                if (type === DocumentType.cpf) return "CPF";
                if (type === DocumentType.cnpj) return "CNPJ";
                return "ID";
              }}
              value={editingIndirectClient.documentType}
              onChange={(type) => {
                if (type !== undefined) {
                  setEditingIndirectClient({ ...editingIndirectClient, documentType: type });
                }
              }}
            />
          </Grid>
          <Grid item lg={6} xs={6}>
            <TextField
              margin="dense"
              id="document"
              label={getDocumentString(editingIndirectClient.documentType) + "*"}
              fullWidth
              name="document"
              variant="outlined"
              value={editingIndirectClient.document}
              error={invalidInidirectClientDocument}
              onChange={handleIndirectClientChange}
            />
          </Grid>
          {!loadingProfile ? (
            <>
              <b>{intl.formatMessage({ id: "info.client.personalInformations" }) + "*"}</b>
              <Grid item lg={12} xs={12}>
                <TextField
                  disabled={props.editing}
                  autoFocus
                  margin="dense"
                  id="name"
                  label={intl.formatMessage({ id: "placeholder.name" })}
                  fullWidth
                  name="name"
                  variant="outlined"
                  value={editingIndirectClientProfile.name}
                  error={invalidProfileName}
                  onChange={handleIndirectClientProfileChange}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  disabled={props.editing}
                  margin="dense"
                  id="email"
                  label={intl.formatMessage({ id: "placeholder.email" }) + "*"}
                  fullWidth
                  name="email"
                  variant="outlined"
                  value={editingIndirectClientProfile.email}
                  error={invalidProfileEmail}
                  onChange={handleIndirectClientProfileChange}
                />
              </Grid>
              <InsertPhone
                disabled={props.editing}
                fieldId="cellphoneNumber"
                labelId="languageCode-label"
                label={intl.formatMessage({ id: "placeholder.cellphone" }) + "*"}
                error={invalidProfileCellphoneNumber}
                onChange={handleIndirectClientProfileChange}
                paperClass={props.classeMenuPaper}
                phone={editingIndirectClientProfile.cellphoneNumber}
              />
            </>
          ) : (
            <CircularProgress />
          )}
          <Grid item lg={12} xs={12}>
            <b>{intl.formatMessage({ id: "info.client.monetaryConfiguration" })}</b>
          </Grid>
          <Grid item lg={6} xs={6}>
            <TextField
              margin="dense"
              id="priceCurrencyCode"
              label={intl.formatMessage({ id: "info.client.currencyCode" }) + "*"}
              fullWidth
              name="priceCurrencyCode"
              variant="outlined"
              value={editingIndirectClient.priceCurrencyCode}
              error={invalidIndirectClientCurrencyCode}
              onChange={handleIndirectClientChange}
            />
          </Grid>
          <Grid item lg={6} xs={6}>
            <TextField
              margin="dense"
              id="price"
              label={intl.formatMessage({ id: "info.price" }) + "*"}
              fullWidth
              name="price"
              variant="outlined"
              value={editingIndirectClient.price}
              error={invalidIndirectClientPrice}
              onChange={handleIndirectClientChange}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <b>{intl.formatMessage({ id: "info.client.nextDueDate" })}</b>
            <DatePicker
              name="nextDueDate"
              value={editingIndirectClient.nextDueDate}
              onChange={(info) => {
                setEditingIndirectClient({
                  ...editingIndirectClient,
                  nextDueDate: new Date(info.valueOf() as number),
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => props.cancelCallback()}
          className="cancel-button"
          startIcon={<Icon>close</Icon>}
        >
          Cancel
        </Button>
        <Button
          disabled={!validInput}
          onClick={(event) => {
            event.preventDefault();
            props.finishCallback(editingIndirectClient, editingIndirectClientProfile);
          }}
          variant="contained"
          color="primary"
        >
          {props.editing
            ? intl.formatMessage({ id: "action.save" })
            : intl.formatMessage({ id: "action.add" })}
        </Button>
      </DialogActions>
    </Modal>
  );
}

function getDocumentString(documentType: DocumentType): string {
  if (documentType === DocumentType.cnpj) return "CNPJ";
  else if (documentType === DocumentType.cpf) return "CPF";
  else return "ID";
}
