import { CircularProgress } from "@material-ui/core";
import { Profile } from "biohub-model";
import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import FlipCard from "../../../components/Atomic/BasicComponents/FlipCard";
import Settings from "../../Settings";
import { Block, Loading, Actions } from "../styles";

export default function ProfileTable() {
  const [active, setActive] = React.useState<boolean>(false);
  const profile: Profile | null = useSelector((state: SystemState) => state.profile.userProfile);
  const loadingProfile: boolean = useSelector((state: SystemState) => state.profile.loadingProfile);

  return (
    <>
      {/* inform the property "avatar" if displaying image - Avatar Component */}
      {/* the flipcard can be removed if there is no need for editing (or just the edit button and fields) */}

      {!loadingProfile && profile !== null ? (
        <FlipCard
          active={active}
          frontChildren={[
            <Block elevation={1}>
              <Settings />
              <Actions className="flip-card"></Actions>
            </Block>,
          ]}
          backChildren={[]}
        />
      ) : (
        <Loading>
          <CircularProgress />
        </Loading>
      )}
    </>
  );
}
