import React from "react";
import { useIntl } from "react-intl";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOnIcon from "@material-ui/icons/VisibilityOutlined";
import { IconButton, Tooltip } from "@material-ui/core";

type ProjectTreeItemTrailingIconProps = {
  isHidden: boolean;
  onClick: () => void;
};

export const ProjectTreeItemTrailingIcon = (props: ProjectTreeItemTrailingIconProps) => {
  const intl = useIntl();
  return (
    <div
      style={{
        marginLeft: 5,
      }}
    >
      <Tooltip title={intl.formatMessage({ id: "map.toggleVisibility" })}>
        <IconButton
          style={{ padding: "0px", paddingRight: 10, color: "var(--black)", marginRight: 0 }}
          onClick={(event) => {
            event.preventDefault();
            props.onClick();
          }}
        >
          {props.isHidden ? (
            <VisibilityOffIcon style={{ width: "20px", height: "20px", marginLeft: 10 }} />
          ) : (
            <VisibilityOnIcon style={{ width: "20px", height: "20px", marginLeft: 10 }} />
          )}
        </IconButton>
      </Tooltip>
    </div>
  );
};
