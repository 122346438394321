import React from "react";
import Text from "../../BasicComponents/Text";
import Image from "../../BasicComponents/Image";
import { Block, Description, Infos } from "./styles";

interface Props {
  key?: string;
  image: string;
  title: string;
  subtitle?: string;
  description: string;
}

export default (props: Props) => {
  return (
    <>
      <Block key={props.key}>
        <Image alt={props.title} src={props.image} />
        <Description>
          <Text text={props.title} appearance={"info-name"} />
          <hr />
          <Text
            text={props.description}
            appearance={"description-block"}
            color={"primary"}
            size={"small"}
          />
        </Description>
      </Block>
    </>
  );
};
