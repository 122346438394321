import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type Props = {
  color?: "light";
};

export const Label = styled(FormControlLabel).attrs((props: Props) => ({
  color: props.color,
}))`
  color: var(--text-default) !important;

  .MuiTypography-body1 {
    font-size: 14px;
  }

  ${(props: Props) =>
    props.color === "light" &&
    `
      color: var(--white)!important;
  `}
`;
