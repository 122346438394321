import styled from "styled-components";
type Props = {
  orientation?: "horizontal" | "vertical";
  color?: "light";
  open: boolean;
};

export const List = styled.ul.attrs((props: Props) => ({
  orientation: props.orientation,
  open: props.open,
}))`
  list-style: none;
  transition: all 1s ease-in-out;
  flex-direction: column;
  min-width: 90%;
  max-width: 100%;

  ${(props) =>
    props.orientation === "horizontal" &&
    `
    li {
      display: inline-block;

    }
  `}

  ${(props) =>
    props.orientation === "vertical" &&
    `
    li {
      display: flex; 
      align-items: center;
      flex-wrap: wrap;
      padding-left: 0;

      
      a {
        color: var(--dashboard-light-text); 
        font-family: "GalanoGrotesque-Medium";
        font-size: 18px;
        text-decoration: none;
        padding-bottom: 4px;
        text-align: left;
        display: flex;
        align-items: center;
        
        :hover, :focus {
          color: var(--dashboard-highlighted);
        } 
        
        img {
          max-width: 32px;
          max-height: 32px;
          object-fit: contain;
          display: inline-block;
          margin-right: 8px;
        }

        @media screen and (max-width: 1024px) {
          font-size: 18px;
        }

      }

    }
    
    ul {
      display: none;
      margin-left: 10px;

      ul li {
        flex-wrap: nowrap;
      }

    }
    
  `}

  
  ${(props: Props) =>
    props.open &&
    `
      display: flex!important;
      
      a {
        font-size: 14px !important;
        line-height: 1;
      }
  `}
`;

export const Item = styled.li.attrs((props: Props) => ({}))`
  text-align: center;
  padding: 10px 10px 0;
  position: relative;
`;

export const Label = styled.a.attrs((props: Props) => ({
  color: props.color,
}))`
  color: var(--gray);

  ${(props) =>
    props.color === "light" &&
    `
    color: var(--white); 
  `}
`;
