import React from "react";
import { Clickable, Icon } from "./styles";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";
import { changeUsageRegion } from "../../../../store/actions/localeActions";

export default (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const brazilRegionTerm = intl.formatMessage({ id: "region.brazil" });
  const europeRegionTerm = intl.formatMessage({ id: "region.europe" });
  const otherRegionTerm = intl.formatMessage({ id: "region.other" });

  const actualRegion = useSelector((state: SystemState) => state.locale.usageRegion);

  const localesTerms: string[] = [
    ((): string => {
      switch (actualRegion) {
        case "brazil":
          return brazilRegionTerm;
        case "es":
          return europeRegionTerm;
        case "other":
          return otherRegionTerm;
      }
    })(),
    brazilRegionTerm,
    europeRegionTerm,
    otherRegionTerm,
  ];

  return (
    <Icon>
      {localesTerms.map((localeTerm, index) => (
        <Clickable
          onClick={() => {
            if (index == 0) return;

            dispatch(changeUsageRegion(index === 1 ? "brazil" : index === 2 ? "es" : "other"));
          }}
        >
          {localeTerm}
        </Clickable>
      ))}
    </Icon>
  );
};
