import {
  Area,
  Cpu,
  CpuModel,
  DeviceModel,
  Drone,
  DroneModel,
  Input,
  Project,
  Flight,
  ReleaseData,
  Releaser,
  ReleaserModel,
} from "biohub-model";
import { REHYDRATE } from "redux-persist";
import Constants from "../../Constants";
import {
  SET_CACHE_AREAS,
  SET_CACHE_CPUS,
  SET_CACHE_CPU_MODELS,
  SET_CACHE_PROJECTS,
  SET_CACHE_FLIGHTS,
  SET_INITIALIZED,
  SET_CACHE_DEVICE_MODELS,
  SET_CACHE_DRONE_MODELS,
  SET_CACHE_INPUTS,
  SET_CACHE_DRONES,
  SET_CACHE_RELEASERS,
  SET_CACHE_RELEASER_MODELS,
  SET_CACHE_PROJECT_FLIGHTS,
} from "../actions/cacheDataActions";
import { LOG_IN_REQUEST, LOG_OUT } from "../actions/loginActions";
import { SystemAction } from "../actions/systemActions";
import { interceptDate } from "../../services/axios/BiohubApi";
import { removeArrayDuplicates } from "../../core/utils";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export type CacheDataType<T> = {
  syncedAt: Date;
  data: T[];
};

export type CacheDataState = {
  key: "cache";
  projects?: CacheDataType<Project>;
  areas?: {
    [projectId: string]: CacheDataType<Area>;
  };
  cpus?: CacheDataType<Cpu>;
  cpuModels?: CacheDataType<CpuModel>;
  deviceModels?: CacheDataType<DeviceModel>;
  droneModels?: CacheDataType<DroneModel>;
  inputs?: CacheDataType<Input>;
  drones?: CacheDataType<Drone>;
  releasers?: CacheDataType<Releaser>;
  flights?: {
    [projectId: string]: {
      [areaId: string]: CacheDataType<Flight>;
    };
  };
  releaserModels?: CacheDataType<ReleaserModel>;
  initialized: boolean;
  lastAppVersion?: string;
};

const INITIAL_STATE: CacheDataState = {
  key: "cache",
  initialized: false,
};

export function cacheDataReducer(state = INITIAL_STATE, action: SystemAction): CacheDataState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  let newStateAreas: {
    [projectId: string]: CacheDataType<Area>;
  } = effectiveState.areas ?? {};

  let newStateFlights: {
    [projectId: string]: { [areaId: string]: CacheDataType<Flight> };
  } = effectiveState.flights ?? {};

  let cacheFlightsProjectId: string;

  switch (action.type) {
    case SET_INITIALIZED:
      const actualAppVersion = Constants.APP_VERSION;

      if (effectiveState.lastAppVersion !== actualAppVersion) {
        return {
          ...INITIAL_STATE,
          initialized: true,
          lastAppVersion: actualAppVersion,
        };
      }

      return {
        ...interceptDate(effectiveState),
        initialized: true,
        lastAppVersion: actualAppVersion,
      };

    case REHYDRATE:
      if (action.payload?.key === "cache") {
        return {
          ...INITIAL_STATE,
          ...action.payload,
        };
      }

      return effectiveState;

    case LOG_OUT:
      return INITIAL_STATE;
    case LOG_IN_REQUEST:
      return INITIAL_STATE;

    case SET_CACHE_PROJECTS:
      const projectIds = action.payload.data.map((project) => project.id);

      const oldStateAreas = effectiveState.areas ?? {};

      newStateAreas = {};

      for (const projectId of projectIds) {
        if (Object.keys(oldStateAreas).includes(projectId)) {
          newStateAreas = {
            ...newStateAreas,
            [projectId]: oldStateAreas[projectId],
          };
        }
      }
      return {
        ...effectiveState,
        projects: action.payload,
        areas: newStateAreas,
      };

    case SET_CACHE_AREAS:
      newStateAreas = {
        ...newStateAreas,
        [action.payload.projectId]: {
          data: action.payload.data,
          syncedAt: action.payload.syncedAt,
        },
      };
      return {
        ...effectiveState,
        areas: newStateAreas,
      };

    case SET_CACHE_FLIGHTS:
      cacheFlightsProjectId = action.payload.projectId;
      const cacheFlightsAreaId = action.payload.areaId;
      if (newStateFlights[cacheFlightsProjectId] === undefined) {
        newStateFlights[cacheFlightsProjectId] = {};
      }
      newStateFlights[cacheFlightsProjectId] = {
        ...newStateFlights[cacheFlightsProjectId],
        [cacheFlightsAreaId]: {
          data: action.payload.data,
          syncedAt: action.payload.syncedAt,
        },
      };

      return {
        ...effectiveState,
        flights: newStateFlights,
      };
    case SET_CACHE_PROJECT_FLIGHTS:
      cacheFlightsProjectId = action.payload.projectId;

      if (newStateFlights[cacheFlightsProjectId] === undefined) {
        newStateFlights[cacheFlightsProjectId] = {};
      }
      newStateFlights[cacheFlightsProjectId] = {};
      const areaIds = removeArrayDuplicates(
        action.payload.data.map((flight) => flight.flightEnvironmentSnapshot.areaId!)
      );
      for (const areaId of areaIds) {
        newStateFlights[cacheFlightsProjectId] = {
          ...newStateFlights[cacheFlightsProjectId],
          [areaId]: {
            data: action.payload.data.filter(
              (flight) => flight.flightEnvironmentSnapshot.areaId === areaId
            ),
            syncedAt: action.payload.syncedAt,
          },
        };
      }
      return {
        ...effectiveState,
        flights: newStateFlights,
      };

    case SET_CACHE_CPUS:
      return {
        ...effectiveState,
        cpus: action.payload,
      };

    case SET_CACHE_CPU_MODELS:
      return {
        ...effectiveState,
        cpuModels: action.payload,
      };

    case SET_CACHE_DEVICE_MODELS:
      return {
        ...effectiveState,
        deviceModels: action.payload,
      };

    case SET_CACHE_DRONE_MODELS:
      return {
        ...effectiveState,
        droneModels: action.payload,
      };

    case SET_CACHE_INPUTS:
      return {
        ...effectiveState,
        inputs: action.payload,
      };

    case SET_CACHE_DRONES:
      return {
        ...effectiveState,
        drones: action.payload,
      };

    case SET_CACHE_RELEASERS:
      return {
        ...effectiveState,
        releasers: action.payload,
      };

    case SET_CACHE_RELEASER_MODELS:
      return {
        ...effectiveState,
        releaserModels: action.payload,
      };

    default:
      return effectiveState;
  }
}

export type CacheDataPersistedState = Omit<CacheDataState, "initialized">;

const persistCacheConfig: PersistConfig<CacheDataState> = {
  key: "cache",
  storage,
  whitelist: [
    "key",
    "projects",
    "areas",
    "cpus",
    "cpuModels",
    "deviceModels",
    "droneModels",
    "inputs",
    "drones",
    "releasers",
    "flights",
    "releaserModels",
    "lastAppVersion",
  ],
};

export const cachePersistedReducer = persistReducer(persistCacheConfig, cacheDataReducer);
