/**
 * Español (es)
 */
const messagesMap: { [key: string]: string } = {
  "app.title": "bioHUB",
  // --- Página de login ---
  "login.title.login": "Acesso",
  "login.form.email": "Email",
  "login.form.password": "Contraseña",
  "login.resetPassword": "Olvide mi contraseña",
  "login.resetPassword.success":
    "El proceso de recuperación de contraseña se realizó correctamente, verifique la bandeja de entrada de su correo electrónico para continuar.",
  "login.resetPassword.error":
    "Error al recuperar su cuenta. Verifique sus datos o vuelva a intentarlo en un momento.",

  "profile.login.currentPassword": "Contraseña actual",
  "profile.login.newPassword": "Nueva contraseña",
  "profile.login.confirmNewPassword": "Confirmar nueva contraseña",

  "profile.registrationStatus.name": "Estado de registro",
  "profile.registrationStatus.completed": "Completo",
  "profile.registrationStatus.pending": "Pendiente",

  // --- Overlays do mapa ---
  "map.overlays.weather.temperature": "Temperatura",
  "map.overlays.weather.pressure": "Presión",
  "map.overlays.weather.humidity": "Humedad",
  "map.overlays.weather.wind": "Viento",
  "map.overlays.weather.windSpeed": "Velocidad del viento",
  "map.overlays.weather.windDirection": "Dirección del viento",
  "map.overlays.weather.percentageClouds": "Nubes",
  "map.overlays.weather.searchTime": "Tiempo de búsqueda",
  "map.overlays.weather.visibility": "Visibilidad",
  "map.overlays.weather.sunrise": "Amanecer",
  "map.overlays.weather.sunset": "Puesta de sol",
  "map.overlays.weather.timezone": "Zona horaria",
  "map.overlays.weather.optimizePolygon": "Optimizar Polígono",
  "map.overlays.weather.optimizePolygonTooltip": `Optimiza la generación de polígonos importados vía Kml / Shp. 
  LA OPTIMIZACIÓN PUEDE NO GENERAR LOS RESULTADOS ESPERADOS, PUEDE CAUSAR DISTORCIONES EN LOS POLÍGONOS GENERADOS`,
  // --- Textos a serem usados de maneira geral no app (por exemplo, em botões) ---
  "generic.ok": "OK",
  "generic.cancel": "Cancelar",
  "generic.close": "Cerrar",
  "generic.yes": "Sí",
  "generic.no": "No",
  "generic.next": "Próxima",
  "generic.previous": "Previa",
  "generic.continue": "Continuar",
  "generic.closeConfirmation.title": "Confirmar",
  "generic.closeConfirmation.discardChangesAndClose": "¿Descartar cambios y cerrar?",
  "generic.N/A": "Ninguna",
  "generic.copy": "Copia",

  "general.year": "año",
  "general.month": "mes",
  "general.hectares": "hectares",

  // --- Textos a serem usados em erros ---
  "generic.error.unknown": "Se ha producido un error.",

  //-- Page Information --//
  "info.pageTitle": "bioHUB - NCB",
  "info.slogan": "Preciso por Naturaleza",
  "info.title": "El primer y único HUB \nde tecnología para el \nControl Biológico de Precisión",
  "info.description": "¡Próximamente!",
  "info.newsletter": "Suscríbase a nuestro boletín",
  "info.about": "bioHUB por NCB - Sistemas Embarcados",
  "info.copyright": "2021 ⓒ bioHUB - NCB. Todos los derechos reservados.",
  "info.address":
    "Parque Tecnológico de São José dos Campos - CEI - Sala 207 Estrada Doutor Altino Bondensan, 500 Distrito Eugênio de Mello São José dos Campos - SP - 12.247-016",
  "info.email": "contato@ncb.ind.br",
  "info.phone": "+55 (41) 98891-4324",
  "info.terms":
    "Al aceptar el presente término, el usuario consiente y acepta que la empresa NCB, en adelante el Responsable, tome decisiones sobre el tratamiento de sus datos personales, así como para llevar a cabo el tratamiento de sus datos personales, involucrando operaciones como las referido a la recogida, producción, recepción, clasificación, uso, acceso, reproducción, transmisión, distribución, tratamiento, archivo, almacenamiento, eliminación, evaluación o control de información, modificación, comunicación, cesión, difusión o extracción. El Responsable está autorizado a compartir los datos personales del Titular con otros agentes del tratamiento de datos, de ser necesario para los fines enumerados en este término, sujeto a los principios y garantías establecidos por la Ley N ° 13.709.",
  "info.summary": "Resumen",
  "info.register.information": "Información de Registro",
  "info.register.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.legal.information": "Información del representante legal",
  "info.legal.terms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.plans.information": "Información del plan",
  "info.amount": "La cantidad",
  "info.banner.plan.title": "Planes y precios",
  "info.plan": "Paquete",
  "info.plan.name.bioHUB": "bioHUB",
  "info.plan.name.bioMAPS": "bioMAPS",
  "info.plan.name.bioBOT": "bioBOT",
  "info.plan.subTitle.bioMAPS": "App de planificación de misiones de vuelo",
  "info.plan.description.bioMAPS":
    "Planificador de misiones para DRONES centrado en el control de precisión biológica. Este producto requiere una suscripción activa de bioHUB para funcionar.",
  "info.plan.type.monthly": "Mensual",
  "info.plan.type.yearly": "Anual",
  "info.plan.perDevice": "por dispositivo",
  "info.plan.ofDiscount": "descuento",
  "info.mode": "Modo",
  "info.price": "Precio",
  "info.per.year": "Por Año",
  "info.total": "Total",
  "info.users": "Usuarios",
  "info.preregistrations": "Preinscripción",
  "info.preregistration": "Preinscripción",
  "info.preregistrations.viewdetails": "Estos son los datos de este prerregistro.",
  "info.contract": "Contrato",
  "info.add.new": "Ingrese los datos a continuación para agregar un nuevo usuario.",
  "info.clients": "Clientes",
  "info.termError": "Su mensaje no se pudo enviar en este momento.",
  "info.menuOptions": "Opciones de menú",
  "info.addCart": "Añadir al carrito",
  "info.purchase": "Comprar",

  "info.client.add": "Agregar Cliente",
  "info.directClient.add": "Agregar cliente directa",
  "info.client.edit": "Editar Cliente",
  "info.client.add.message": "Ingrese los datos a continuación para agregar una nueva cliente.",
  "info.client.edit.message": "Edite los datos a continuación para actualizar su cliente.",
  "info.client.personalInformations": "Informaciones personales:",
  "info.client.companyInformation": "Información de la empresa:",
  "info.client.documentType": "Tipo de Documento",
  "info.client.monetaryConfiguration": "Configuración de Facturación:",
  "info.client.currencyCode": "Moneda",
  "info.client.nextDueDate": "Fecha de vencimiento Siguiente:",

  "info.none": "Ninguno",
  "info.releaser": "Liberadora",
  "info.releasers": "Liberadoras",
  "info.releaser.edit": "Editar liberador",
  "info.releaser.add": "Agregar liberador",
  "info.releaser.add.message":
    "Complete la información a continuación para agregar un nuevo lanzador.",
  "info.releaser.edit.message": "Edite la información a continuación para actualizar su versión.",
  "info.releaser.selectdrone": "Seleccione Drone:",
  "info.releaser.liberatormodel": "Modelo de liberador:",
  "info.releaser.selectclient": "Nombre del cliente:",
  "info.releaser.models": "Modelos",

  "info.drone": "Drone",
  "info.drones": "Drones",
  "info.manufacturer": "Fabricante",
  "info.drone.edit": "Editar drone",
  "info.drone.add": "Agregar drone",
  "info.drone.add.message": "Complete la información a continuación para agregar un nuevo drone.",
  "info.drone.edit.message": "Edite la siguiente información para actualizar su drone.",
  "info.drone.selectdrone": "Seleccione Drone:",
  "info.drone.selectclient": "Nombre del cliente:",
  "info.drone.manufacturer": "Fabricante de drones",
  "info.drone.dronemodel": "Modelo de drone:",

  "info.cpu": "CPU",
  "info.cpus": "CPUs",
  "info.model": "Modelo",
  "info.serialNumber": "Número de serie",
  "info.firmwareVersion": "Versión de Firmware",
  "info.lastFirmware": "Firmware más reciente",
  "info.client": "Cliente",
  "info.cpu.add": "Añadir CPU",
  "info.cpu.viewing": "Visualización de CPU",
  "info.cpu.model": "Modelo de CPU",
  "info.cpu.addingMessage": "Complete los campos a continuación para agregar una nueva CPU",
  "info.cpu.viewingMessage": "Visualización de la CPU añadida",
  "info.releaser.addDevice": "Añadir dispositivo",
  "info.releaser.removeDevice": "Retire el dispositivo",
  "info.devices": "Dispositivos",
  "info.device.model": "Modelo",

  "info.profiles": "Perfiles",
  "info.role.master": "Maestría",
  "info.role.administrator": "Administrador",
  "info.role.manager": "Gerente",
  "info.role.operator": "Operador",
  "info.role.external": "Externo",

  "info.profile.add": "Agregar Usuario",
  "info.profile.edit": "Editar Usuario",
  "info.profile.add.message": "Ingrese los datos a continuación para agregar un nuevo usuario.",
  "info.profile.edit.message": "Edite los datos a continuación para actualizar su usuario.",
  "info.profile.userType": "Tipo de usuario",
  "info.profile.userType.manager": "Gerente",
  "info.profile.userType.operator": "Operador",
  "info.profile.userType.external": "Externo",
  "info.profile.personalInformations": "Informaciones personales:",
  "info.profile.legalInformations": "Informaciones legales:",
  "info.profile.agricultureNumber": "Número de agricultura",
  "info.profile.crea": "CREA",
  "info.profile.ava": "AVA",

  "info.profile.edit.image": "Cambiar foto de perfil",
  "info.profile.edit.image.dialog": "La foto debe estar en formato jpg, png o jpeg.",
  "info.profile.edit.image.buttom.choose": "Escoge una foto",
  "info.profile.edit.image.buttom.send": "Mandar",
  "info.profile.edit.image.buttom.cancel": "Cancelar",
  "info.profile.edit.image.invalid": "No se pudo cargar tu foto.",

  "info.profile.edit.logo": "Cambia tu logo",
  "info.profile.edit.logo.dialog": "El logo debe estar en formato jpg, png o jpeg.",
  "info.profile.edit.logo.buttom.choose": "Elegir logo",
  "info.profile.edit.logo.buttom.send": "Mandar",
  "info.profile.edit.logo.buttom.cancel": "Cancelar",
  "info.profile.edit.logo.invalid": "No se puede cargar su logotipo.",

  "info.profile.nationality": "Nacionalidad",

  "info.directClients": "Clientes",

  //-- Pages --//
  "info.subtitleTerms":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.pageTerms":
    "Al aceptar el presente término, el usuario consiente y acepta que la empresa NCB, en adelante el Responsable, tome decisiones sobre el tratamiento de sus datos personales, así como para llevar a cabo el tratamiento de sus datos personales, involucrando operaciones como las referido a la recogida, producción, recepción, clasificación, uso, acceso, reproducción, transmisión, distribución, tratamiento, archivo, almacenamiento, eliminación, evaluación o control de información, modificación, comunicación, cesión, difusión o extracción. El Responsable está autorizado a compartir los datos personales del Titular con otros agentes del tratamiento de datos, de ser necesario para los fines enumerados en este término, sujeto a los principios y garantías establecidos por la Ley N ° 13.709.",
  "info.subtitlePrivacy": "Lorem ipsum dolor sit amet consecter",
  "info.privacy":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleSupport": "Lorem ipsum dolor sit amet consecter",
  "info.support":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleFaq": "Lorem ipsum dolor sit amet consecter",
  "info.faq":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion1": "¿Pregunta 1?",
  "info.faqQuestion1Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion2": "¿Pregunta 2?",
  "info.faqQuestion2Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.faqQuestion3": "Pregunta?",
  "info.faqQuestion3Content":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleWhy": "Lorem ipsum dolor sit amet consecter",
  "info.why":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",
  "info.subtitleResources": "Lorem ipsum dolor sit amet consecter",
  "info.resources":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis.",

  "info.aboutTitle": "¿Qué es NCB?",
  "info.aboutNCB": "NCB?",
  "info.aboutDescription":
    "bioHub es una plataforma digital holística que permite la gestión eficaz de plagas y enfermedades a través de drones y software con un enfoque en la inteligencia empresarial.",
  "info.aboutVisionTitle": "Nuestra visión",
  "info.aboutVisionSub": "La realidad que se busca",
  "info.aboutVisionText":
    "Transformar o setor agrícola, promovendo a gestão de dados e a aplicação de tecnologia e inovação sustentável para a criação de estratégias e o uso assertivo do controle biológico. Em três anos, queremos que a Bi All seja a referência na simbiose entre agricultura, ciência e tecnologia, após ter operado três milhões de hectares de lavouras.",
  "info.aboutMissionTitle": "Nuestra misión",
  "info.aboutMissionSub": "La razón de ser",
  "info.aboutMissionText":
    "Catalizar la naturaleza a través de la digitalización de datos que permita la creación de estrategias de toma de decisiones, asegurando la mejor gestión de los recursos. Brindar soluciones en ecosistemas agrícolas mediante un control biológico de precisión.",
  "info.aboutValues": "Nuestros valores",
  "info.aboutPrecisionTitle": "Precisión",
  "info.aboutPrecisionText":
    "Ejecutamos todos nuestros procesos y servicios con exactitud y según la estrategia con la que fueron planificados.",
  "info.aboutTraceabilityTitle": "Trazabilidad",
  "info.aboutTraceabilityText":
    "Aseguramos la visibilidad y el control en cada etapa de los procesos para obtener resultados óptimos.",
  "info.aboutTeamTitle": "Nuestro equipo",
  "info.aboutTeamRoleCEO": "CEO y fundador",
  "info.aboutTeamRoleManager": "Gestión",
  "info.aboutTeamRoleEmployee": "Ingeniería",
  "info.aboutTeamDescriptionCEO":
    "Ingeniería Eléctrica (FEI 2002)\nMaster en Electrónica (ITA 2007)\nDoctorado en Sistemas Aeroespaciales (ITA 2013)",
  "info.aboutTeamDescriptionManager":
    "Administración (UNILESTE 2005)\nGestión de Emprendimiento (UNIPAC)",
  "info.aboutTeamDescriptionEmployee":
    "Hardware y software integrados\nIngeniería Eléctrica (UNESP 2005)",
  "info.contactTitle": "Hable con nosotros",
  "info.contactSubtitle": "Información de contacto por país",
  "info.contactDescription":
    "Complete sus datos en el formulario a continuación y espere. ¡Nos pondremos en contacto con usted lo antes posible!",
  "info.termTitle": "El plan bioMAPS",
  "info.coin": "€",
  "info.termPriceMonth": "por mes / por dispositivo",
  "info.termPriceYear": "por año / por dispositivo (15% de descuento)",
  "info.termSpecifications": "Especificaciones:",
  "info.termSpecificationsDesc":
    "É o primeiro planejador de missão da DRONES com foco no controle de precisão biológica. É possível planejar rotas de qualquer lugar do mundo através da nuvem e sincronizadas com um aplicativo móvel para fazer lançamentos de insumos biológicos 100% rastreados e controlados por GPS.",
  "info.termClassifications": "Calificaciones:",
  "info.termClassificationsDesc":
    "Lorem ipsum dolor sit amet consecter ad ipiscing elit natoque orci augue, parturient montes hac elementum nisi ac fermentum duis curae cursus per, morbi neque viverra nam nascetur mauris metu consequat quis.",
  "info.termChoose": "Elija plan",
  "info.termComparisonTitle": "Comparar características",
  "info.termComparisonMonth": "mes",
  "info.termComparisonYear": "año",
  "info.termComparisonEquipment": "equipo",
  "info.termComparisonPerEquipment": "por equipo",
  "info.termComparisonMonthDevice": "mes por dispositivo",
  "info.termComparisonYearDevice": "año por dispositivo",
  "info.termComparisonHectare": "hectárea por mes",
  "info.termComparisonDescription": "Incluye licencia para 1 dispositivo.",
  "info.termComparison.accessTitleBioHUB": "Acceso a la plataforma",
  "info.termComparison.accessTitleBioMAPS": "Acceso a la app",
  "info.termComparison.accessTitleBioBOT": "Equipo",
  "info.termComparisonDescriptionBioHUB":
    "Incluye 5 licencia para operador o 4 licencias de operador y 1 licencia de manager. €15 por cada operador extra.",
  "info.introducing": "Introduciendo",
  "info.cameraQuality": "Calidad de la cámara",
  "info.flightTime": "Tiempo de vuelo",
  "info.capacity": "Capacidad",
  "info.minutes": "minutos",
  "info.verificationTitle": "Verificacion de email",
  "info.verificationDescription":
    "La verificación requiere una dirección de correo electrónico válida.  nEnviaremos un código de confirmación a la dirección de correo electrónico que ingresó para iniciar el proceso de validación.  nSi la dirección que se proporciona a continuación es incorrecta, haga clic en el icono y cambie sus datos.",
  "info.emailAddress": "Dirección de correo electrónico",
  "info.verificationCodeDescription": "Ingrese el código enviado a su correo electrónico:",
  "info.confirmationCode": "Verification Code",
  "info.newPassword": "Nueva Contraseña",
  "info.newPasswordDescription":
    "Establezca una nueva contraseña para acceder a nuestra plataforma.",
  "info.newPasswordDetails": "Ingrese la nueva contraseña",
  "info.newPasswordConfirm": "Confirma tu nueva contraseña",

  "setupAccount.title": "Completa tu Registro",
  "setupAccount.created": "Cuenta creada com éxito",
  "setupAccount.error":
    "Ocurrió un error en el proceso de finalización de su registro. Inténtalo de nuevo.",
  "setupAccount.error.tryAgain": "Intentar otra vez",
  "setupAccount.error.wrongSmsCode": "Código SMS incorrecto",
  "setupAccount.verifiedEmail": "Correo electrónico verificado correctamente",
  "setupAccount.verification.code": "Código SMS",
  "setupAccount.verification.description":
    "La verificación requiere un número de teléfono válido. \nEnviaremos un código de confirmación a su teléfono y debe ingresar el código correcto para finalizar el proceso de validación y crear la contraseña de su cuenta.",
  "setupAccount.verification.codeDescription":
    "Ingrese el código de 6 dígitos que se envió a su teléfono",
  "setup.password": "Ingresa tu contraseña",
  "setup.confirmPassword": "Confirmar la contraseña",
  "setup.password.different": "La contraseña no coincide",
  "setup.password.needUpperCase": "Necesario un elemento en mayúsculas",
  "setup.password.needSpecialElement": "Necesaria una letra especial",
  "setup.password.lenght": "Necesario una longitud mínima de 6 caracteres",

  "drone.usagePhantom4": "Phantom 4",
  "drone.usageDroneMatrix100": "Matrix 100",
  "drone.usageDroneMatrix200": "Matrix 200",

  "prod.productTitle": "Productos",
  "prod.bioBotSubTitle": "Lanzador de insumos biológicos",
  "prod.bioBotDescription":
    "Es un liberador de huevos y parásitos a granel utilizado principalmente para el control de plagas. Con él es posible liberar insumos biológicos como Trichogramma, Telenomus y Chrysopa (todos probados y en operación comercial con DRONES en Brasil, Colombia y Centroamérica).",
  "prod.bioBotWorksBioMaps": "Funciona con bioMAPS app",
  "prod.bioBotWeatherProof": "A prueba de clima",
  "prod.bioBotAutonomy": "+5 horas de autonomía",
  "prod.equipmentTitle": "El equipo más versátil",
  "prod.equipmentDescription":
    "Con él es posible liberar insumos biológicos como Trichogramma, Telenomus y Chrysopa (todos probados y en operación comercial con DRONES en Brasil, Colombia y Centroamérica).",
  "prod.biocoteDescription":
    "Es un liberador de copa biodegradable con masa de cothesia utilizado principalmente en el control poblacional de orugas. La caña de azúcar es el principal cultivo tratado. (Desarrollo exclusivo de Biocontrol). 100% operativo y comercial. Su versión de pasta única parasitada ha sido congelada porque no podemos continuar con las pruebas de campo, continuaremos a partir de enero de 2021.",

  "prod.cultivationEquipmentTitle": "Principales cultivos beneficiados",
  "prod.cultivationSugarCane": "Caña de azúcar",
  "prod.cultivationSoy": "Soya",
  "prod.cultivationBeans": "Frijol",
  "prod.cultivationCorn": "Maiz",
  "prod.cultivationBanana": "Plátano",
  "prod.cultivationAvocado": "Palta",
  "prod.cultivationOilPalm": "Palma aceitera",
  "prod.cultivationCoffee": "Café",
  "prod.cultivationForest": "Floresta",
  "prod.cultivationStrawberry": "Fresa",
  "prod.cultivationMelon": "Melón",
  "prod.cultivationPapaya": "Papaya",
  "prod.cultivationTulip": "Tulipán",
  "prod.cultivationCotton": "Algodón",
  "prod.cultivationOthers": "Otros",
  "prod.cultivationContact":
    "Si el cultivo de tu empresa no se muestra acá, ponte en contacto con nosotros para una asesoría personalizada",

  "prod.usageSugarcane": "Caña de azúcar",
  "prod.usageForest": "Bosque",
  "prod.usageCotton": "Algodón",
  "prod.usageCorn": "Maíz",
  "prod.usageSoybeans": "Soja",
  "prod.usageCoffee": "Café",
  "prod.usageStrawberry": "Fresa",
  "prod.usageMelon": "Melón",
  "prod.usagePapaya": "Papaya",
  "prod.usageTulip": "Tulipán",
  "prod.usageAvocado": "Palta",
  "prod.usageBeans": "Frijol",
  "prod.usageBanana": "Plátano",
  "prod.usageOilPalm": "Palma aceitera",

  "prod.specificationTitle": "Especificaciones técnicas",
  "prod.specificationSubTitle": "Acceso a la plataforma bioHUB",
  "prod.specificationInfo":
    "Incluye 5 licencias para operador o 4 licencias de operador y 1 licencia de manager; $15 por cada operador extra.",

  "bio.usageTrichogrammaExiguum": "Trichogramma Exiguum",
  "bio.usageTrichogrammaGalloi": "Trichogramma Galloi",
  "bio.usageTrichogrammaAtopovirilia": "Trichogramma Atopovirilia",
  "bio.usageTrichogrammaBrassicae": "Trichogramma Brassicae",
  "bio.usageTrichogrammaPretiosum": "Trichogramma Pretiosum",
  "bio.usageChrysoperlaCarnea": "Chrysoperla Carnea",
  "bio.usageChrysoperlaExterna": "Chrysoperla Externa",
  "bio.usageStratiolaelapsScimitus": "Stratiolaelaps Scimitus",
  "bio.usageNeoseiulusCalifornicus": "Neoseiulus Californicus",
  "bio.usageTelenomusPodisi": "Telenomus Podisi",
  "bio.usageTelenomusRemus": "Telenomus Remus",
  "bio.usagePhytoseiulusPersimilis": "Phytoseiulus Persimilis",
  "bio.usageCotesiaFlavipes": "Cotesia Flavipes",
  "bio.usageCeraeochrysaCincta": "Ceraeochrysa Cincta",
  "bio.usageTetrastichusHowardi": "Tetrastichus Howardi",
  "bio.usageAmblyseiusCalifornicus": "Amblyseius Californicus",
  "bio.usageMetarhiziumAnisopliae": "Metarhizium Anisopliae",
  "bio.usageBeauveriaBassiana": "Beauveria Bassiana",

  "cart.title": "Mi carrito",
  "cart.title.general": "General",
  "cart.title.address": "Dirección personal y contacto",
  "cart.title.address.company": "Dirección y contacto de la empresa",
  "cart.title.representative": "Representante legal",
  "cart.title.step1": "Paso 1: Datos sobre la empresa",
  "cart.title.step2": "Paso 2: Términos",
  "cart.title.step3": "Paso 3: Pago",
  "cart.title.number.order": "Orden no. ",
  "cart.title.billing.address": "Dirección de facturación",
  "cart.title.shipping.address": "Dirección de Envio",
  "cart.title.payments.methods": "Métodos de pago",
  "cart.title.modal.billing.address": "Añadir dirección de facturación",
  "cart.title.modal.shipping.address": "Añadir dirección de Envio",
  "cart.title.discount.coupon": "Cupón de descuento: ",
  "cart.title.modal.add.discount.coupon": "Agregar nuevo cupón de descuento",
  "cart.title.subtotal": "Total Parcial",
  "cart.title.discounts": "Descuentos",
  "cart.title.taxes": "Impuestos",
  "cart.button.download": "Descargar",
  "cart.terms.information.use": "Uso de información personal",
  "cart.terms.mandatory.contract": "Contratos Obligatorios",
  "cart.info.payments.paypal": "Paypal",
  "cart.info.payments.pix": "Pix",
  "cart.info.payments.ticket": "Boleto",
  "cart.action.empty": "Vaciar carrito",
  "cart.message.error.CPF": "CPF no válido",
  "cart.message.error.CNPJ": "CNPJ no válido",
  "cart.message.error.fields.filled":
    "¡Asegúrate de que todos los campos estén llenos correctamente!",
  "cart.message.error.checked": "¡Asegúrese de que todos los Términos hayan sido seleccionados!",
  "cart.message.error.extra.address": "Añade al menos 1 dirección de entrega o de facturación",
  "cart.message.error.item.cart": "¡No hay artículos en su carrito!",
  "cart.message.error.document.type": "Seleccione algún tipo de documento! (CPF, CNPJ, ID)",
  "cart.message.error.coupon.invalid": "Cupón no válido, prueba con otro!",
  "cart.message.error.coupon.add.exists": "¡Este cupón ya ha sido añadido!",
  "cart.alreadyIncluded": "Ya incluido:",
  "cart.chooseOneVersionOfTheProductMessage": "Elija una versión para agregar al carrito:",
  "cart.product": "Producto:",

  //-- Landing Page --//
  "page.title": "Digitalice su campo, \nel primer paso hacia \nel futuro de la agricultura",
  "page.description":
    "bioHUB es una plataforma digital holística que permite el manejo efectivo de plagas y enfermedades a través de drones y software enfocado en inteligencia empresarial.",
  // Sections
  "section.about.title": "Nuestros productos",
  "section.about.category": "Software",
  "section.about.info.title": "Acerca de bioHUB",
  "section.about.info.subtitle":
    "BioHub es un sistema de gestión de control biológico de precisión",
  "section.about.info.description":
    "Una plataforma en la nube integrada que ayuda a que toda la cadena de control biológico pueda monitorear plagas, liberar con precisión, generar informes y monitorear la toma de decisiones...",
  "section.biomaps.title": "Acerca de BioMAPS:",
  "section.biomaps.subtitle": "BioMAPS es un planificador de misiones de vuelo autónomas",
  "section.biomaps.description":
    "Es el primer planificador de misiones  para drones centrado en el control de precisión biológica. Se utiliza para planificar rutas de vuelo para identificación y tratamiento de plagas.",
  "section.biomaps.required": "BioMAPS requiere de una suscripción activa de bioHUB",
  "section.how.works.title": "¿Cómo funciona?",
  "section.products.category": "Hardware",
  "section.products.description":
    "Cada cultivo puede padecer de diferentes plagas que necesitan un tratamiento especializado. Es por eso que hemos creado soluciones biológicas ideales para tu cultivo y tu modelo de negocios.",
  "section.products.subDescriptionCultivation": "Yo cultivo  ",
  "section.products.subDescriptionDrone": "Yo tengo el dron ",
  "section.products.subDescriptionBiological": "Yo quiero aplicar ",
  "section.card.product.description.bioBOT":
    "Es un liberador de huevos y parásitos a granel utilizado principalmente para el 𝐜𝐨𝐧𝐭𝐫𝐨𝐥 𝐝𝐞 𝐩𝐥𝐚𝐠𝐚𝐬. Con él es posible liberar insumos biológicos como Trichogramma, Telenomus y Chrysopa",
  "section.card.product.description.bioCOTE":
    "Es un liberador de vasos biodegradables con insumos biológicos utilizado en el control poblacional de 𝐨𝐫𝐮𝐠𝐚𝐬.",
  "section.card.product.description.PHERObio":
    "Es un lanzador de cápsulas con feromonas. Se utiliza para controlar 𝐌𝐢𝐠𝐝𝐨𝐥𝐮𝐬 a través de la confusión sexual.",
  "section.products.required": "Todos nuestros productos de hardware requieren",
  "section.prices.category": "Precios",
  "section.prices.title": "El plan adecuado para su negocio",
  "section.prices.description":
    "Ofrecemos varios planes poderosos para exhibir su negocio y ser descubierto como un emprendedor creativo.",
  "section.clients.category": "Clientes",
  "section.clients.title": "Opiniones de nuestros clientes",
  "section.clients.opinions.men":
    "“Recibí un excelente servicio al cliente de parte de los especialistas que me ayudaron. Se lo recomendaría a cualquiera que quiera un excelente tablero que tenga una gran calidad”",
  "section.clients.opinions.women":
    "“Realmente se reduce a esto: que toda la vida está interrelacionada. Todos estamos atrapados en una red ineludible de reciprocidad, atados a una sola prenda del destino”",
  "section.clients.role.men": "Diseñador de producto",
  "section.clients.role.women": "Desarrolladora web",
  "section.partners.category": "Socios",
  "section.buy": "Achat",

  // Section Product
  "section.product.benefited.crops.title": "Cosechas beneficiadas",
  "section.product.compatible.drone.title": "Drones compatibles",
  "section.product.highlight": "Aspectos destacados",
  "section.product.biological.title": "Biológicos",

  // Sections Product [bioBOT]
  "section.product.bioBOT.title": "bioBOT",
  "section.product.bioBOT.subtitle": "Liberadores de insumos biológicos",
  "section.product.bioBOT.description":
    "Es un dispensador de masas del género Cotesia utilizado principalmente para el control poblacional de las orugas. La caña de azúcar es el cultivo principal tratado.",
  "section.product.bioBOT.how_it_works.title": "¿Cómo funciona el BioBOT?",

  // Sections Product [bioCOTE]
  "section.product.bioCOTE.title": "bioCOTE",
  "section.product.bioCOTE.subtitle": "Liberadores de insumos biológicos",
  "section.product.bioCOTE.description":
    "Es un dispensador de ácaros depredadores Phytoseiulus y Neoseiulus. Después de su liberación, atacan y controlan las infestaciones de otros ácaros plaga en el campo. Las principales culturas son fresa, tomate, flores, tabaco y champiñón.",
  "section.product.bioCOTE.how_it_works.title": "¿Cómo funciona el BioCOTE?",

  // Sections Product [bioMITE]
  "section.product.bioMITE.title": "bioMITE",
  "section.product.bioMITE.subtitle": "Liberadores de insumos biológicos",
  "section.product.bioMITE.description":
    "Es un parásito en masa y liberador de huevos, utilizado principalmente para el control de plagas. Permite el lanzamiento de insumos biológicos como Trichogramma, Telenomus y Chrysopa (todos probados y en funcionamiento comercial con DRONES en Brasil, Colombia y América Central).",
  "section.product.bioMITE.how_it_works.title": "¿Cómo funciona el BioMITE?",

  //-- Dashboard Information --//
  "dashboard.welcome": "Bienvenido de nuevo, \n",
  "dashboard.clients": "Clientes",
  "dashboard.users": "Utilisateurs",
  "dashboard.total.users": "Total de Usuarios",
  "dashboard.total.reports": "Informes",
  "dashboard.total.releasedFlights": "Cantidad de liberación",
  "dashboard.total.monthFlightYield": "Ingresos mensuales de vuelo",
  "dashboard.leggend.plannedReleased": "Planeado / Liberada",
  "dashboard.total.clients": "Total de Clientes",
  "dashboard.total.drones": "Drones Totales",
  "dashboard.total.projects": "Proyectos Totales",
  "dashboard.total.sales": "Ventas Totales",
  "dashboard.total.area": "Total de Hectáreas Cubiertas",
  "dashboard.total.yearMonthLegend": "Mes / Año",
  "dashboard.hectares.covered": "Hectáreas Cubiertas",
  "dashboard.new.company": "Nuevas empresas",
  "dashboard.flight.client": "Vuelos por cliente",
  "dashboard.coverage.graph": "Gráfico del área de cobertura",
  "dashboard.scale": "Escala",
  "dashboard.graphmodeview": "Vista por",
  "dashboard.filter": "Filtrar",
  "dashboard.time.30minutes": "30 minutos",
  "dashboard.time.1hour": "1 hora",
  "dashboard.time.8hours": "8 horas",
  "dashboard.time.1day": "1 día",
  "dashboard.time.1week": "1 semana",
  "dashboard.view.general": "-",
  "dashboard.view.profile": "Perfil",
  "dashboard.view.client": "Cliente",
  "dashboard.view.drone": "Drone",

  //-- Menu Information --//
  "menu.about": "Acerca de NCB",
  "menu.products": "Productos",
  "menu.solutions": "Soluciones",
  "menu.pricing": "Precios",
  "menu.contact": "Contacto",
  "menu.privacy": "Política de privacidad",
  "menu.terms": "Términos y Condiciones",
  "menu.title": "Empresa",
  "menu.support": "Soporte",
  "menu.faq": "FAQ's",
  "menu.resources": "Recursos",
  "menu.why": "¿Por qué bioHUB?",
  "menu.home": "Comienzo",
  "menu.biomaps": "bioMAPS",
  "menu.profile": "Profile",
  "menu.plans": "Planes",
  "menu.settings": "Ajustes",
  "menu.open.project": "Proyecto Abierto",
  "menu.new.project": "Nuevo Proyecto",
  "menu.new.monitoring": "Nueva Monitorización",
  "menu.flight.monitoring": "Monitor de Vuelo",
  "menu.trap.monitoring": "Monitor de Trampa",
  "menu.biomapsInfo":
    "BioMAP visualiza las áreas donde se liberan los controladores biológicos a través del planificador de la misión.",
  "menu.licenses": "Licencias",
  "menu.traps": "Trampas",

  //-- Generic Actions --//
  "action.usageChoose": "Seleccionar...",
  "action.ContactUs": "Quiero pedir hardware específico",
  "action.login": "Entrar",
  "action.signUp": "Suscribirse",
  "action.subscribe": "Firmar",
  "action.register": "Registrarse",
  "action.makeRegistration": "¡Haz tu preinscripción aquí!",
  "action.success": "Éxito",
  "action.error": "Perdon",
  "action.msgSuccess":
    "¡Su preinscripción fue exitosa en nuestra base de datos! \nPronto te enviaremos un mensaje ✓",
  "action.msgError":
    "¡Tuvimos un error al realizar tu preinscripción! Por favor, inténtelo de nuevo más tarde.",
  "action.back": "Volver",
  "action.getStarted": "Comienzo",
  "action.learnMore": "Sepa mas",
  "action.learnMore.prices": "Conoce los planes y precios",
  "action.more": "Mas informaciones...",
  "action.keep.connected": "Mantente conectado",
  "action.login.message": "Ingrese sus datos para iniciar sesión",
  "action.login.msgError":
    "Error al iniciar sesión. Verifique sus datos o intente nuevamente en un momento.",
  "action.continue": "Continuar",
  "action.accept": "Estoy de acuerdo",
  "action.indicator": "Registrarse como: ",
  "action.signup.company": "Empresa",
  "action.signup.single": "Persona unica",
  "action.accept.signup.terms":
    "Acepto los términos generales de la ley de protección de datos LGPD.",
  "action.pay.now": "Pagar ahora",
  "action.add.new": "Agregar nuevo",
  "action.edit": "Cambiar",
  "action.clean": "Limpiar",
  "action.edit.picture": "Cambiar foto",
  "action.edit.logo": "Cambiar logo",
  "action.remove": "Eliminar",
  "action.save": "Ahorrar",
  "action.add": "Agregar",
  "action.cancel": "Cancelar",
  "action.undo": "Deshacer",
  "action.redo": "Rehacer",
  "action.finish": "OK",
  "action.income": "Ingreso",
  "action.send": "Enviar",
  "action.confirm": "Confirmar",
  "action.sendVerification": "Envíe el código de verificación",
  "action.saveData": "Guardar datos",
  "action.settings": "Definições",
  "action.confirmAction": "¿Está seguro de que desea completar esta acción?",
  "action.warn": "Advertencia",
  "action.cart.add": "Artículo añadido al carrito.",
  "action.cart.remove": "Artículo eliminado del carrito.",
  "action.cart.changedQuantity": "Cantidad modificada.",

  //-- Placeholders --//
  "placeholder.profile": "Perfil",
  "placeholder.email": "E-mail",
  "placeholder.name": "Nombre",
  "placeholder.phone": "Teléfono",
  "placeholder.ladlineNumber": "Número de teléfono fijo",
  "placeholder.company": "Empresa",
  "placeholder.isCompanyQuestion": "Es empresa?",
  "placeholder.data.collect": "¿Cómo se enteró de NCB?",
  "placeholder.password": "La contraseña",
  "placeholder.search": "Buscar",
  "placeholder.iva": "IVA",
  "placeholder.company.name": "Nombre de empresa",
  "placeholder.zip": "Código Postal",
  "placeholder.address": "Calle",
  "placeholder.number": "Número",
  "placeholder.cellphone": "Teléfono móvil",
  "placeholder.complement": "Complemento",
  "placeholder.country": "País",
  "placeholder.state.department": "Estado / Departamento",
  "placeholder.city": "Ciudad",
  "placeholder.street": "Calle",
  "placeholder.comercial.phone": "Teléfono del trabajho",
  "placeholder.aviation.reg": "Registro de Aviación",
  "placeholder.agriculture.reg": "Registro de Agricultura",
  "placeholder.role": "Ocupación",
  "placeholder.document": "Documento",
  "placeholder.rg": "RG",
  "placeholder.crea": "CREA",
  "placeholder.ie": "I.E.",
  "placeholder.im": "I.M.",
  "placeholder.trade.name": "Nombre Comercial",
  "placeholder.type": "Tipo de usuario",
  "placeholder.contact": "Contacto",
  "placeholder.description": "Bio",
  "placeholder.subject": "Sujeto",
  "placeholder.message": "Mensaje",
  "placeholder.project": "Proyecto",
  "placeholder.area": "Área",
  "placeholder.aircraft": "Aeronave",
  "placeholder.code.country": "Código de País",
  "placeholder.name.street": "Nombre de la calle:",
  "placeholder.street.examples": "Apartamento, unidad, edificio, piso, etc.",
  "placeholder.phone.mask": "(000) 0000-0000",
  "placeholder.CPF.mask": "XXX.XXX.XXX-XX",
  "placeholder.CNPJ.mask": "XX.XXX.XXX/0001-XX",

  "placeholder.releaser.serialnumber": "Numero de serie",
  "placeholder.releaser.nickname": "Apellido",
  "placeholder.releaser.releasermodels": "Modelos",
  "placeholder.releaser.tablemodel": "Modelo",
  "placeholder.releaser.tableserialnumber": "Número de serie.",
  "placeholder.releaser.searchclient": "Ingrese el nombre del cliente",
  "placeholder.profile.select.picture": "No se seleccionó ninguna imagen...",

  "placeholder.drone.selectclient": "Cliente...",
  "placeholder.drone.selectcpu": "CPU...",
  "placeholder.drone.selectmanufacturer": "Fabricante de drones...",
  "placeholder.drone.selectdronemodel": "Modelo de drone...",
  "placeholder.drone.clientcpu": "CPU del cliente",

  "placeholder.settings.insufficientpermissions":
    "No tiene permisos suficientes para cambiar esta configuración.",

  "profile.userTableComponent.selection.selected": "seleccionado",
  "profile.userTableComponent.selection.clear": "Borrar selección",
  "profile.userTableComponent.selection.remove": "Eliminar datos seleccionados",

  "profile.account": "Mi Cuenta",
  "profile.profile": "Mi Perfil",
  "profile.settings.updated": "Preferencias de perfil actualizadas",

  //-- Labels --//
  "label.document.ID": "ID",
  "label.document.CPF": "CPF",
  "label.document.CNPJ": "CNPJ",
  "label.document.RG": "Registro General (RG)",
  "label.document.IM": "Inscripción Municipal",
  "label.name.company": "Nombre de la empresa",
  "label.trade.name": "Razón social",
  "label.direction": "Habla a",
  "label.code.postal": "Código Postal",
  "label.fullname": "Nombre completo",
  "label.phone": "Teléfono fijo",
  "label.cellphone": "Teléfono celular",
  "label.select.country": "Seleccione un país",

  //-- Options --//
  "option.preferences.defaultCurvePercentage": "Curvatura (%)",
  "option.preferences.mustReleaseEntireArea":
    "Activar equipo en el primer waypoint y desactivar en el último",
  "option.preferences.generalinfo": "Informaciones generales",
  "option.preferences.unitsystem": "Unidad de sistema",
  "option.preferences.twofactorauth": "Autenticación de dos factores",
  "option.preferences.sexagesimalcoordinates": "Usar coordenadas sexagesimales",
  "option.preferences.separatelybatteries": "Mostrar baterías por separado",
  "option.preferences.metric": "Métrico",
  "option.preferences.imperial": "Imperial",
  "option.search.engine": "Motores de búsqueda",
  "option.facebook": "Facebook",
  "option.linkedin": "Linkedin",
  "option.recommendation": "Recomendaciones",
  "option.email.marketing": "E-mail Marketing",
  "option.events": "Eventos",
  "option.others": "Otros",
  "option.which": "¿Cuales?",

  //-- Map --//
  "map.title": "Mapa",
  "map.defaultRegion": "ES",
  "map.editMode.enterManualRoute": "Ruta Manual",
  "map.editMode.editRoute": "Editar Ruta",
  "map.editMode.manualRoute":
    "Toque el área para insertar un nuevo waypoint o arrastre los que ya se han ingresado para modificar la ruta. ",
  "map.editMode.addPolygon":
    "Toque en el mapa para agregar un nuevo punto. Mantenga un marcador presionado para moverlo.",
  "map.editMode.error.theePointsMinimum": "Por favor, indique al menos tres puntos.",
  "map.editMode.error.nearbyPoints":
    "Por favor, cada waypoint debe estar al menos a 1 metro de distancia de los demás.",
  "map.editMode.error.interesection":
    "El área dibujada tiene una o más intersecciones. Por favor, dibuja un polígono simple.",
  "map.editMode.reverseRoute": "Ruta inversa",
  "map.removeArea": "¿Eliminar esta área?",
  "map.newArea": "Nueva área",
  "map.loadingList": "cargando...",
  "map.loadingFlights": "cargando...",
  "map.noFlightsToShow": "No flights.",
  "map.editPolygonPoints": "Editar polígono",
  "map.editPlan": "Editar plano de área",
  "map.importRouteKml": "Importar ruta KML",
  "map.goToArea": "Ir a la Zona",
  "map.replanRoute": "Generar nueva ruta",
  "map.editAreaProperties": "Ajustes",
  "map.deleteArea": "Eliminar área",
  "map.duplicateArea": "Crear copia de área",
  "map.kml.exportArea": "Exportar área como KML",
  "map.kml.exportRoute": "Exportar ruta como KML",
  "map.kml.exportFlight": "Exportar vuelo como KML",
  "map.copyArea": "Copiar área",
  "map.pasteCopiedArea": "Pegar área copiada",
  "map.deletingArea": "Eliminando área",
  "map.deletingArea.question": "¿Continuar?",
  "map.deleteProject": "Eliminar proyecto",
  "map.deletingProject": "Eliminando proyecto",
  "map.deletingProject.question": "¿Proceder?",
  "map.importAreas.importAreasAction": "Importar áreas",
  "map.newArea.createAreaModalTitle": "Crear nueva área",
  "map.newArea.curveMode": "Modo de Curva",
  "map.newArea.curveMode.curved": "Curvo",
  "map.newArea.curveMode.normal": "Normal",
  "map.newArea.headingMode": "Modo de Rumbo",
  "map.newArea.headingMode.nextWaypoint": "Seguiente Waypoint",
  "map.newArea.headingMode.manual": "Manual",
  "map.newArea.importAreaModalTitle": "Importar áreas",
  "map.newArea.editProjectModalTitle": "Editar proyecto",
  "map.newRoute.importKmlModalTitle": "Importar ruta KML",
  "map.newArea.areaName": "Nombre del área",
  "map.newProject.title": "Crear proyecto",
  "map.newProject.indirectClientName": "Cliente",
  "map.newProject.projectName": "Nombre del proyecto",
  "map.newProject.optionalImportKml": "Importar KML",
  "map.newProject.optionalImportShapefile": "Importar Shapefile",
  "map.newProject.skipImportingFiles": "Dibujar el área manualmente",
  "map.newProject.kmlFileChoose": "Abrir...",
  "map.newProject.shapefileFileChoose": "Abrir...",
  "map.newProject.releaser": "Liberador",
  "map.newProject.input": "Insumo",
  "map.newProject.releaseRate": "Tasa de liberación",
  "map.newProject.calibrationFactor": "Factor de calibracion",
  "map.type.roadmap": "Predeterminado",
  "map.type.satellite": "Satélite",
  "map.type.hybrid": "Híbrido",
  "map.newProject.instantaneousReleaseRate": "Tasa de liberación instantánea",
  "map.areasAmountSing": "{amount} área",
  "map.areasAmountPl": "{amount} áreas",
  "map.enableLabels": "Etiquetas",
  "map.closeProject": "Cerrar proyecto",
  "map.toggleVisibility": "Mostrar / Ocultar",
  "map.toggleVisibility.disabled": "Desactivado",
  "map.openFlightReport": "Abrir informe de vuelo",
  "map.moreOptions": "Más opciones",

  "map.search": "Búsqueda de Dirección",
  "map.search.noOptions": "Sin opción de dirección",

  "map.plannedRoutes.title": "Rutas planificadas del Área",
  "map.plannedRoutes.flightAmount": "Numero de Vuelos",
  "map.plannedRoutes.plannedData": "Fecha de planificación",
  "map.plannedRoutes.untilToday": " - Hoje",
  "map.plannedRoutes.flightDate": "Fecha de Vuelo",
  "map.plannedRoutes.termPerformedFlights": "Vuelos Realizados",
  "map.plannedRoutes.areaSize": "Tamaño del Área",
  "map.plannedRoutes.flightTime": "Tiempo de Vuelo",
  "map.plannedRoutes.viewingOldPlan":
    'Está viendo un plano antiguo para el área. \nHaga clic en "Salir" para volver a ver la planificación actual. \nHaga clic en "Reemplazar" para reemplazar el programa actual con el programa que está viendo ahora.',
  "map.plannedRoute.select": "Reemplazar",
  "map.planedRoute.exit": "Salir",

  "map.footer.noProjectOpen": "Ningún proyecto abierto",
  "map.footer.possibleCoverageArea": "Posible área de cobertura: {areaSize}",
  "map.footer.coveredArea": "Área cubierta: {areaSize} ({percentage}%)",

  "map.unlinkedFlights.unlinkedFlights": "Vuelos no vinculados",
  "map.unlinkedFlights.cpuSerialNumber": "Archivo de registro:",
  "map.unlinkedFlights.action.linked": "Vincular a un vuelo",
  "map.unlinkedFlights.action.linked.for.prefix": "Vincular vuelo ",
  "map.unlinkedFlights.action.linked.for.sufix": " al área: ",
  "map.unlinkedFlights.modal.project": "Proyecto",
  "map.unlinkedFlights.modal.area": "Área",
  "map.unlinkedFlights.modal.drone": "Dron",
  "map.unlinkedFlights.modal.cancel": "Cancelar",
  "map.unlinkedFlights.modal.link": "Vincular",

  //-- Route planning --//
  "routePlanning.notFoundReleaser": "Liberador no encontrado",

  "routePlanning.editWaypoint": "Editar Waypoint",
  "routePlanning.param.areaPadding": "Indentación de área",
  "routePlanning.param.flightHeight": "Altura",
  "routePlanning.param.flightSpeed": "Velocidad",
  "routePlanning.param.flightSpeed.legend":
    "Velocidad a la que el dron acelerará para llegar entre los puntos de referencia (ya que es necesario desacelerar para hacer giros, no necesariamente se alcanzará)",
  "routePlanning.param.trackWidth": "Ancho de banda",
  "routePlanning.param.considerRelief": "Elevación online",

  "routePlanning.param.waypoint.height": "Altura",
  "routePlanning.param.waypoint.curveRadius": "Radio de curva",
  "routePlanning.param.waypoint.speed": "Velocidad",
  "routePlanning.param.waypoint.speed.legend":
    "Velocidad a la que el dron acelerará para llegar al siguiente punto de referencia (ya que es necesario desacelerar para realizar giros, no necesariamente se alcanzará)",
  "routePlanning.param.waypoint.orientation": "Orientación",

  "routePlanning.param.waypoint.actions": "Comportamiento",
  "routePlanning.param.waypoint.action.enableEquipment": "Habilitar Equipo",
  "routePlanning.param.waypoint.action.disableEquipment": "Desactivar Equipo",
  "routePlanning.param.waypoint.action.none": "Ninguna",

  // -- Regions -- //
  "region.brazil": "Brasil",
  "region.europe": "Europa",
  "region.other": "Otro",

  //-- LANGUAGES --//
  language: "Idioma",
  "language.pt": "Portugués brasileño",
  "language.es": "Español",
  "language.en": "Inglés",
  "language.fr": "Francés",
  "language.name": "Español",
  "language.region": "ES",
  "language.decimalSeparator": ",",
  "language.country": "Brasil",

  //- Reports -//
  "report.input": "Aporte",
  "report.issueDate": "Fecha de emisión:",
  "report.area": "Área",
  "report.plannedFlight": "Vuelo Planejado",
  "report.details": "Detalles",
  "report.dateHour": "Fecha y Hora:",
  "report.drone": "Drone:",
  "report.manufacturer": "Fabricante:",
  "report.anac": "ANAC:",
  "report.releasedProduct": "Producto Lanzado:",
  "report.releasing": "Liberador",
  "report.bandWidth": "Banda Ancha",
  "report.plannedSpeed": "Velocidad Planejada",
  "report.releaseRate": "Tasa de liberación",
  "report.realFlight": "Vuelo Real",
  "report.flightTime": "Tiempo de vuelo",
  "report.averageReleaseRate": "Tasa de liberación promedio",
  "report.averageSpeed": "Velocidad Media",
  "report.totalReleased": "Total Liberado",
  "report.totalArea": "Área Total",
  "report.traps": "Trampas",
  "report.serialNumber": "Numero de serie",
  "report.coveredHectares": "Hectáreas cubiertas",
  "report.dateMeasurament": "Fecha de la última medición",
  "report.specie": "Especies",
  "report.adultsNumber": "Número de Adultos",
  "report.noAvailable": "No hay artículos disponibles",
  "report.company": "Empresa:",
  "report.mapRecord": "Registro MAPA:",
  "report.contractor": "Contratista:",
  "report.property": "Propriedad:",
  "report.localization": "Localización:",
  "report.county": "Condado:",
  "report.uf": "UF:",
  "report.doc": "CNPJ/CPF:",
  "report.serviceType": "Tipo de servicio:",
  "report.product": "Producto",
  "report.formulation": "Formulación",
  "report.dosage": "Dosis",
  "report.toxicClass": "Clase tóxica",
  "report.adjuvant": "Auxiliar",
  "report.culture": "Cultura: ",
  "report.volume": "Volumen (litros ou kg/ha):",
  "report.others": "Otros: ",
  "report.agronomicalRecipe": "Receta agronómica n:",
  "report.issuedOn": "Emitido el:",
  "report.basicParameter": "Parámetros de aplicación básicos",
  "report.maxTemperature": "Temperatura Máx.=",
  "report.relativeUnitMin": "Unidad Relativa Min.=",
  "report.equipment": "Equipo=",
  "report.model": "Modelo=",
  "report.type": "Tipo=",
  "report.angle": "Ángulo=",
  "report.flightHeight": "Altura de vuelo=",
  "report.bandwidth": "Ancho de banda(m)=",
  "report.date": "Fecha",
  "report.signatureCREA": "Nombre, firma y CREA del profesional",
  "report.weatherConditions": "Condiciones climáticas en la aplicación",
  "report.morning": "Mañana",
  "report.evening": "Noche",
  "report.applicationStart": "Inicio de la aplicación: horas y finalización: horas",
  "report.initial": "Inicial",
  "report.final": "Final",
  "report.temperature": "Temperatura",
  "report.relativeHumidity": "Umid. Relativo",
  "report.aircraftPrefixe": "Prefijo - Aeronave:",
  "report.dgpsUse": "Uso do DGPS: Sí o No",
  "report.windSpeed": "Veloc. Viento",
  "report.trackCoordinates": "Seguimiento de coordenadas:",
  "report.observation": "Nota:",
  "report.signatureEng": "Nombre, firma y CREA del Ing. Agronomo:",
  "report.print": "Imprimir",

  // Pre-registration research
  "preregistration.interest.question":
    "¿Qué crees que es más importante en\nControl Biológico de Precisión?",
  "preregistration.interest.financial": "Financiero",
  "preregistration.interest.financial.cost": "Costo de operacion",
  "preregistration.interest.financial.savings": "Ahorro de insumos",
  "preregistration.interest.financial.pest": "Reducción de plagas",
  "preregistration.interest.financial.production": "Incremento de producción",

  "preregistration.interest.sustainability": "Sustentabilidad",
  "preregistration.interest.sustainability.carbonfootprint": "Reducción de la huella de carbono",
  "preregistration.interest.sustainability.pesticides": "Reducción de Plaguicidas",
  "preregistration.interest.sustainability.environment": "Medio ambiente",
  "preregistration.interest.sustainability.water": "Economía del agua",

  "preregistration.interest.reliability": "Confiabilidad",
  "preregistration.interest.reliability.precision": "Precisión",
  "preregistration.interest.reliability.releasingrate": "Tasa de liberación",
  "preregistration.interest.reliability.tracking": "Seguimiento de ruta",
  "preregistration.interest.reliability.coverage": "Cobertura de área",

  // Notifications
  "notif.newmessages": "Nuevos Mensajes",
  "notif.pendencies": "Pendientes",
  "notif.error": "Fracassos",
  "notif.success": "Confirmaciones",

  // Header dropdown
  "headerDropdown.logout": "Cerrar Sesión",

  "map.projectTree.projects.loading": "Cargando proyectos",
  "map.projectTree.projects.empty": "Lista de proyectos vacía",

  "map.projectTree.areas.loading": "Cargando áreas de proyecto",
  "map.projectTree.areas.empty": "Lista de áreas vacías",

  "map.projectTree.flights.loading": "Vuelos del área de carga",
  "map.projectTree.flights.empty": "Lista de vuelos vacía",

  "map.projectTree.amountOfProjectsWithLoadedAreas": "Proyectos",

  "cpu.block.blocked.general":
    "La CPU está bloqueada. Por favor, póngase en contacto con los administradores.",
  "cpu.block.blocked.master": "La CPU está bloqueada",
  "cpu.block.notBlocked.general": "CPU liberada para su uso",
  "cpu.block.notBlocked.master": "La CPU no está bloqueada",
  "cpu.block.cpuTitle": "Bloques de CPU {cpuSerialNumber}",
  "cpu.block.addBlock": "Agregar bloque",
  "cpu.block.reason": "Motivo del bloqueo",
  "cpu.block.createdAt": "Creado en",
  "cpu.block.appliedAt": "Aplicado en",
  "cpu.block.notApplied": "Not applied",
  "cpu.block.unblockedAt": "Desbloqueado en",
  "cpu.block.reason.default": "Problemas de pago",
  "cpu.block.reason.idleness": "Ociosidad",
  "cpu.block.reason.manually": "NCB",
  "cpu.block.removeBlock": "Desbloquear CPU",

  "biohubError.unimplemented.error": "Error inesperado.",
  "biohubError.unkown.error": "Error desconocido. Por favor revise su conexion a internet.",
  "biohubError.internal.server.error": "Problemas de conexión al servidor.",
  "biohubError.validation.error":
    "Datos incompatibles. Actualice esta página para cargar la última versión del sitio.",
  "biohubError.login.incorrect.user.password":
    "Inicio de sesión: Nombre de usuario o contraseña incorrectos.",
  "biohubError.preregister.email.already.registered": "Pre-registro: Email ya registrado.",
  "biohubError.preregister.phone.number.already.registered":
    "Pre-registro: Teléfono ya registrado.",
  "biohubError.login.token.not.received": "Error al iniciar sesión em el sistema.",
  "biohubError.logion.unrecgnized.client":
    "Plataforma no autorizada. Asegúrate de estar usando la URL oficial.",
  "biohubError.project.not.found": "Proyecto no encontrado.",
  "biohubError.elevation.invalid.request": "Error al obtener datos de elevación.",
  "biohubError.elevation.over.daily.limit":
    "Se excedió el límite de solicitud de datos de elevación diaria.",
  "biohubError.elevation.over.query.limit":
    "La cantidad de datos de elevación solicitados supera el límite.",
  "biohubError.elevation.request.denied": "Acceso denegado a los datos de elevación.",
  "biohubError.elevation.unknown.error": "Error inesperado.",
  "biohubError.geocoder.unknown.error":
    "Error en el proceso de obtención de datos de una ubicación.",
  "biohubError.sms.validation.error": "Error al enviar SMS de validación.",
  "biohubError.kml.route.out.of.area.bounds": "Ruta fuera de los límites del área.",
  "biohubError.kml.import.invalid.selected.area.bounds": "Área seleccionada inválida.",

  "purchase.order.intent.number": "ID de intento de compra",
  "purchase.order.intent.detail": "Detalles de intención de compra",
  "purchase.order.intent.message": "Nota adicional",
  "purchase.order.intent.phone": "Teléfono",

  "purchase.order.intent.contactTitle": "Envío de interés de compra",
  "purchase.order.intent.contactDescription":
    "Complete sus datos en el siguiente formulario y espere a que nos comuniquemos con usted en breve.",

  "purchase.order.intent.mail.error":
    "Error al enviar los datos de intención de compra. ¡Inténtalo de nuevo!",
  "purchase.order.intent.mail.validation.error":
    "Complete todos los datos requeridos del formulario",
  "purchase.order.intent.mail.success": "Intento de compra enviado con éxito",

  "purchase.order.mail.content.product": "Producto",
  "purchase.order.mail.content.quantity": "Cantidad",
  "purchase.order.mail.content.unit.prices": "Precios unitarios de compra",
};

export default messagesMap;
