import { Input } from "biohub-model";

export const biobotModelId = "310a8f51-eaad-49e8-b8a3-a3806aa70e4f";
export const biocoteModelId = "1c6cf6b0-2dcc-47b8-89c6-daf587602668";
export const biomiteModelId = "4d9b0ef1-a8a6-4b10-85c5-b958a08015b9";

const releaserModelHasCalibrationFactor = (releaserModelId: string): boolean => {
  if (releaserModelId === biobotModelId) return true;

  return false;
};

const releaserModelInputs = (releaserModelId: string, inputs: Input[]): Input[] => {
  return inputs.filter((input) => input.releaserModelId === releaserModelId);
};

export default {
  releaserModelHasCalibrationFactor,
  releaserModelInputs,
};
