import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEditionMode,
  finishAreaPlanningEdition,
  putFlightPlanInAreaPlan,
  quitViewFlightPlan,
  updateEditingArea,
} from "../../../../store/actions/projectTreeActions";
import { SystemState } from "../../../../store/reducers/systemReducer";
import EditingAreaPlanOverlay from "./EditingAreaPlanOverlay";
import ViewOldPlannedAreaOverlay from "./ViewOldPlannedAreaOverlay";

export default function (): JSX.Element {
  const editingAreaState = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    return projectTreeState.editingArea;
  });

  const selectedAreaViewingOldFlightPlanState = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const selectedProjectId = projectTreeState.selectedProjectId;
    if (selectedProjectId === null) return undefined;

    const selectedProject = projectTreeState.projectList?.find(
      (project) => project.id === selectedProjectId
    );
    if (selectedProject === undefined) return undefined;

    const selectedAreaId = selectedProject.selectedAreaId;
    if (selectedAreaId === null) return undefined;

    const selectedArea = selectedProject.areas?.find((area) => area.id === selectedAreaId);
    if (selectedArea === undefined) return undefined;

    if (selectedArea.viewingFlightPlan === undefined) return undefined;

    return {
      projectId: selectedProjectId,
      areaId: selectedAreaId,
      plan: selectedArea.viewingFlightPlan,
    };
  });

  const dispatch = useDispatch();

  return (
    <>
      {editingAreaState !== undefined ? (
        <EditingAreaPlanOverlay
          editingArea={editingAreaState}
          onCancel={() => {
            dispatch(closeEditionMode());
          }}
          onFinish={() => {
            dispatch(finishAreaPlanningEdition());
          }}
          setEditingPlan={(editingArea) => {
            dispatch(updateEditingArea(editingArea));
          }}
        />
      ) : selectedAreaViewingOldFlightPlanState?.plan !== undefined ? (
        <ViewOldPlannedAreaOverlay
          viewingOldPlannedArea={selectedAreaViewingOldFlightPlanState.plan}
          onCancel={() => {
            dispatch(
              quitViewFlightPlan(
                selectedAreaViewingOldFlightPlanState.projectId,
                selectedAreaViewingOldFlightPlanState.areaId
              )
            );
          }}
          onSelect={() => {
            dispatch(
              putFlightPlanInAreaPlan(
                selectedAreaViewingOldFlightPlanState.projectId,
                selectedAreaViewingOldFlightPlanState.areaId,
                selectedAreaViewingOldFlightPlanState.plan
              )
            );
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
