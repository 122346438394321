import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { DialogActions, DialogContent, Grid, TextField } from "@material-ui/core";
import Text from "../../BasicComponents/Text";
import Clickable from "../../BasicComponents/Link";
import Image from "../../BasicComponents/Image";
import Button from "../../BasicComponents/Button";
import ExtraAddress from "./ExtraAddress";

import {
  addDiscountCart,
  clearCart,
  clearDiscountCart,
} from "../../../../store/actions/cartActions";
import { SystemState } from "../../../../store/reducers/systemReducer";

import { Country } from "../../../../services/NationalityService";
import { formatPrice } from "../../../../services/FormValidatorsService";
import { BiohubLocale } from "../../../../store/reducers/localeReducer";

import TableCart from "./TableCart";

import {
  Actions,
  Cart,
  CartCard,
  DiscountArea,
  InputContainer,
  Modal,
  SubTotalArea,
  TitleCardCart,
  TotalArea,
} from "./styles";

// Images
import iconPaypalPayment from "../../../../assets/icon/icon_paypal_payment.svg";
import iconPixPayment from "../../../../assets/icon/icon_pix_payment.svg";
import iconTicketPayment from "../../../../assets/icon/icon_ticket_payment.svg";

type Props = {
  countries: Country[];
  handlePaySubmit: Function;
};

export default (props: Props) => {
  const intl = useIntl();
  const localeSystem: BiohubLocale = useSelector((state: SystemState) => state.locale.localeCode);

  // terms
  const termTitleAddress = intl.formatMessage({ id: "placeholder.address" });
  const termTitlePaymentMethod = intl.formatMessage({ id: "cart.title.payments.methods" });
  const termTitleTotal = intl.formatMessage({ id: "info.total" });
  const termTitleDiscountCoupon = intl.formatMessage({ id: "cart.title.discount.coupon" });
  const termTitleModalDiscountCoupon = intl.formatMessage({
    id: "cart.title.modal.add.discount.coupon",
  });
  const termTypeMethodPaymentPaypal = intl.formatMessage({ id: "cart.info.payments.paypal" });
  const termTypeMethodPaymentPix = intl.formatMessage({ id: "cart.info.payments.pix" });
  const termTypeMethodPaymentTicket = intl.formatMessage({ id: "cart.info.payments.ticket" });
  const termActionPayNow = intl.formatMessage({ id: "action.pay.now" });
  const termActionEmptyCart = intl.formatMessage({ id: "cart.action.empty" });
  const termActionAdd = intl.formatMessage({ id: "action.add" });
  const termActionAddNew = intl.formatMessage({ id: "action.add.new" });
  const termActionClean = intl.formatMessage({ id: "action.clean" });
  const termActionCancel = intl.formatMessage({ id: "action.cancel" });
  const termTitleSubtotal = intl.formatMessage({ id: "cart.title.subtotal" });
  const termTitleDiscounts = intl.formatMessage({ id: "cart.title.discounts" });
  const termTitleTaxes = intl.formatMessage({ id: "cart.title.taxes" });
  const termMessageErrorItemCart = intl.formatMessage({ id: "cart.message.error.item.cart" });

  const dispatch = useDispatch();

  const itemsCart = useSelector((state: SystemState) => state.cart.items);
  const discountCart = useSelector((state: SystemState) => state.cart.discount);
  const totalDiscount = useSelector((state: SystemState) => state.cart.totalDiscount);
  const subTotal = useSelector((state: SystemState) => state.cart.subTotal);
  const total = useSelector((state: SystemState) => state.cart.total);

  const [openModalDiscount, setOpenModalDiscount] = React.useState(false);

  const [couponDiscount, setCouponDiscount] = React.useState("");

  const handleOpenModalDiscount = () => {
    setOpenModalDiscount(!openModalDiscount);
  };

  const handleAddDiscount = async () => {
    if (couponDiscount === "") {
      return;
    }

    dispatch(addDiscountCart(couponDiscount, intl));

    setCouponDiscount("");
    setOpenModalDiscount(!openModalDiscount);
  };

  const handleClearDiscount = () => {
    dispatch(clearDiscountCart());
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  return (
    <>
      <Cart>
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <CartCard>
              <TitleCardCart>
                <Text text={termTitleAddress} appearance={"info-semibold"} />
              </TitleCardCart>
              <ExtraAddress countries={props.countries} locale={localeSystem} />
              <TitleCardCart>
                <Text text={"Produtos e Serviços"} appearance={"info-semibold"} />
              </TitleCardCart>
              <Grid container className={"checkout-align"}>
                {itemsCart.length > 0 ? (
                  <TableCart dataTable={itemsCart} isDiscountUpdate />
                ) : (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Text
                      text={termMessageErrorItemCart}
                      textAlign={"center"}
                      appearance={"info-semibold"}
                    />
                  </Grid>
                )}
                <DiscountArea>
                  <Grid container spacing={2}>
                    {discountCart.length > 0 ? (
                      <>
                        {discountCart.map((item) => (
                          <Grid
                            item
                            key={item.coupon}
                            lg={12}
                            md={12}
                            sm={12}
                            className="discountText-align"
                          >
                            <Text text={termTitleDiscountCoupon} />
                            <Text text={item.coupon} appearance={"info-semibold"} />
                          </Grid>
                        ))}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Actions>
                            <Clickable onClick={() => handleOpenModalDiscount()}>
                              <Text text={termActionAddNew} />
                            </Clickable>
                            <Clickable onClick={() => handleClearDiscount()}>
                              <Text text={termActionClean} />
                            </Clickable>
                          </Actions>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Text text={termTitleDiscountCoupon} appearance={"info-semibold"} />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Actions>
                            <Clickable onClick={() => handleOpenModalDiscount()}>
                              <Text text={termActionAddNew} />
                            </Clickable>
                          </Actions>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Modal open={openModalDiscount} onClose={() => handleOpenModalDiscount()}>
                    <DialogContent>
                      <Text
                        text={termTitleModalDiscountCoupon}
                        appearance={"info-semibold"}
                        textAlign={"center"}
                      />
                      <Grid container>
                        <InputContainer>
                          <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Text
                              text={termTitleDiscountCoupon}
                              appearance={"info-semibold"}
                              color={"primary"}
                            />
                          </Grid>
                          <Grid item lg={8} md={8} sm={8} xs={8}>
                            <TextField
                              fullWidth
                              name="discount"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setCouponDiscount(e.target.value)
                              }
                              value={couponDiscount}
                            />
                          </Grid>
                        </InputContainer>
                        <DialogActions>
                          <Button
                            text={termActionCancel}
                            action={handleOpenModalDiscount}
                            color={"primary"}
                          />
                          <Button
                            text={termActionAdd}
                            color={"primary"}
                            action={() => handleAddDiscount()}
                          />
                        </DialogActions>
                      </Grid>
                    </DialogContent>
                  </Modal>
                </DiscountArea>
                <SubTotalArea>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="subTotal-align">
                      <Text text={termTitleSubtotal} appearance={"info-semibold"} />
                      <Text
                        text={formatPrice(localeSystem, subTotal)}
                        appearance={"info-semibold"}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="subTotal-align">
                      <Text text={termTitleDiscounts} />
                      <Text text={formatPrice(localeSystem, totalDiscount)} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="subTotal-align">
                      <Text text={termTitleTaxes} />
                      <Text text={formatPrice(localeSystem, 0)} />
                    </Grid>
                  </Grid>
                </SubTotalArea>
                <TotalArea>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="total-align">
                      <Text text={termTitleTotal} appearance={"info-semibold"} />
                      <Text text={formatPrice(localeSystem, total)} appearance={"info-semibold"} />
                    </Grid>
                  </Grid>
                </TotalArea>
              </Grid>
            </CartCard>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <CartCard>
              <Grid container className={"methodPay"}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text text={termTitleTotal} appearance={"info-semibold"} />
                  <Text
                    text={formatPrice(localeSystem, total)}
                    appearance={"info-semibold"}
                    color={"primary"}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className={"methodPay-types"}>
                  <Text text={termTitlePaymentMethod} appearance={"info-semibold"} />
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Image src={iconPaypalPayment} alt={""} />
                    <Text text={termTypeMethodPaymentPaypal} />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Image src={iconPixPayment} alt={""} />
                    <Text text={termTypeMethodPaymentPix} />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Image src={iconTicketPayment} alt={""} />
                    <Text text={termTypeMethodPaymentTicket} />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className={"methodPay-action"}>
                  <Actions>
                    <Clickable onClick={() => props.handlePaySubmit()}>
                      <Button text={termActionPayNow} color="highlighted" />
                    </Clickable>
                  </Actions>
                  {itemsCart.length > 0 && (
                    <Actions>
                      <Clickable onClick={handleClearCart}>
                        <Text text={termActionEmptyCart} />
                      </Clickable>
                    </Actions>
                  )}
                </Grid>
              </Grid>
            </CartCard>
          </Grid>
        </Grid>
      </Cart>
    </>
  );
};
