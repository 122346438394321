import { Location } from "biohub-model";
import { off } from "process";
import React from "react";
import { MemoMarker } from "../MemoMapComponents";

type Props = {
  polygonId: string;
  waypointIndex: number;
  isSelected: boolean;
  isActiveLiberation: boolean;
  hasReleaserAction: boolean;
  showDistanceLabels?: boolean;
  waypointScale?: number;
  location: Location;
  draggable: boolean;
  onDragEnd?: (location: Location) => void;
  onClick: () => void;
  zIndex: number;
};

export default function (props: Props): JSX.Element {
  const markerIconUrl = ((): string => {
    if (props.isSelected) return "icon_marker_thick_green_blue_border";

    if (props.isActiveLiberation) {
      return "icon_marker_thick_blue_green_border";
    } else {
      return "icon_marker_thick_blue_red_border";
    }
  })();

  const sizeMultiplier = props.waypointScale ?? 1;
  const showDistanceLabel = props.showDistanceLabels ?? true;

  return (
    <MemoMarker
      key={`${props.polygonId}-marker-drop-${props.waypointIndex}`}
      zIndex={props.zIndex}
      icon={{
        // Using the google maps Icon implementation to try to perform better
        url: `/${markerIconUrl}.png`,
        scaledSize: new google.maps.Size(sizeMultiplier * 36, sizeMultiplier * 36),
      }}
      draggable={props.draggable}
      onDragEnd={async (e: google.maps.MapMouseEvent) => {
        if (props.draggable && props.onDragEnd !== undefined) {
          const latLng = e.latLng;
          if (latLng === null) return;

          const newLocation: Location = {
            lat: latLng.lat(),
            lng: latLng.lng(),
          };
          props.onDragEnd(newLocation);
        }
      }}
      label={
        showDistanceLabel
          ? {
              color: "white",
              fontSize: "10px",
              fontWeight: "600",
              text: `${props.waypointIndex + 1}`,
            }
          : undefined
      }
      position={props.location}
      clickable
      onClick={() => {
        props.onClick();
      }}
    />
  );
}
