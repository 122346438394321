import { Plan } from "../reducers/plansReducer";

// Action constants

export const ADD_PLAN = "ADD_PLAN";
export const CLEAR_PLANS = "CLEAR_PLANS";

// Action types
export type PlanAction =
  | {
      type: typeof ADD_PLAN;
      payload: Plan;
    }
  | {
      type: typeof CLEAR_PLANS;
    };

// Action functions

export function addPlanAction(plan: Plan): PlanAction {
  return {
    type: ADD_PLAN,
    payload: plan,
  };
}

export function clearPlansAction(): PlanAction {
  return {
    type: CLEAR_PLANS,
  };
}
