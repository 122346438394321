import styled from "styled-components";

type Props = {
  open: boolean;
  retractedTop?: boolean;
  openWidth?: string;
  closeWidth?: string;
};

export const Dashboard = styled.section.attrs((props: Props) => ({}))`
  //min-height: 100vh;
  width: 100%;
  height: auto;
  position: relative;
  background: var(--dashboard-background);
  color: var(--dashboard-text-default);
  display: flex;

  @media screen and (max-width: 1440px) {
  }
`;

export const Header = styled.header.attrs((props: Props) => ({
  open: props.open,
}))`
  position: fixed;
  top: -2px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: var(--dashboard-primary-bg);
  color: var(--dashboard-light-text);
  padding: 1px 0;
  z-index: 2;
  left: 0;
  width: 100%;
  transition: all 0.3s linear;

  .MuiContainer-maxWidthLg {
    max-width: 100vw;
  }

  @media screen and (max-width: 1600px) {
  }

  @media screen and (max-width: 1024px) {
  }

  ${(props: Props) =>
    !props.open &&
    `
      left: 0;
      width: 100%;

      @media screen and (max-width: 1600px) {

      }

      @media screen and (max-width: 1024px) {

      }

      @media screen and (max-width: 768px) {
        // padding: 10px 0;
      }

      @media screen and (max-width: 680px) {

      }

      @media screen and (max-width: 579px) {
        left: 0;
        width: 100%;
        padding-left: 10px;
      }
  
    `}
`;

export const Board = styled.div.attrs((props: Props) => ({
  open: props.open,
  retractedTop: props.retractedTop,
  openWidth: props.openWidth,
  closeWidth: props.closeWidth,
}))`
  top: 75px;
  height: calc(100vh - 75px);
  ${(props: Props) =>
    props.open &&
    props.openWidth &&
    `
    left: ${props.openWidth};
    width: calc(100vw - ${props.openWidth});
  `}
  position: relative;
  transition: all 0.3s linear;

  @media screen and (max-width: 580px) {
    width: 100vw;
  }

  @media screen and (max-width: 1600px) {
    .project-info {
      width: 375px;
      left: initial;
      right: 10px;
      bottom: 100px;
    }
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 680px) {
    .project-info {
      width: 320px;
    }
  }

  ${(props: Props) =>
    !props.open &&
    props.closeWidth &&
    `
    left: ${props.closeWidth};
    width: calc(100vw - ${props.closeWidth} - 14px);
    
    .new-area {
      left: 70px;
    }
          
    @media screen and (max-width: 579px) {
      left: 0;
      width: 100%;
    }

    .board-body {
      padding: 3rem 8rem!important;

      @media screen and (max-width: 1024px) {
        padding: 3rem 2rem!important;
      }

      @media screen and (max-width: 680px) {
        padding: 1.5rem 0!important;
      }
    }

    .project-info {
      left: 250px;
      width: 420px;

      @media screen and (max-width: 1600px) {
        width: 375px;
        left: initial;
        right: 10px;
        bottom: 100px;
      }

      @media screen and (max-width: 1300px) {
        width: 285px;
      }
    }

  `}

  ${(props: Props) =>
    props.open &&
    props.openWidth &&
    `
    left: ${props.openWidth};
    width: calc(100vw - ${props.openWidth} - 14px);
    `}

  ${(props: Props) =>
    props.retractedTop !== undefined &&
    props.retractedTop &&
    `
    top: 0px;
    height: 100vh;
    transition: all 0.3s linear;

    .new-area {
      left: 70px;
    }
          
    @media screen and (max-width: 579px) {
      left: 0;
      width: 100%;
    }

    .board-body {
      padding: 3rem 8rem!important;

      @media screen and (max-width: 1024px) {
        padding: 3rem 2rem!important;
      }

      @media screen and (max-width: 680px) {
        padding: 1.5rem 0!important;
      }
    }

    .project-info {
      left: 250px;
      width: 420px;

      @media screen and (max-width: 1600px) {
        width: 375px;
        left: initial;
        right: 10px;
        bottom: 100px;
      }

      @media screen and (max-width: 1300px) {
        width: 285px;
      }
    }

  `}
`;

/* ----------------------    Initial Page */
export const InitialPage = styled.div.attrs((props: Props) => ({
  className: "board-body",
}))`
  width: 100%;
  height: auto;
  position: relative;
  color: var(--primary);
  padding: 3rem;

  .MuiGrid-item {
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    .MuiGrid-item.MuiGrid-grid-lg-3 {
      :nth-of-type(1) {
        .card {
          > label {
            color: var(--primary) !important;
          }
          display: inline-block;
          &:nth-of-type(odd) {
            width: 40%;
            float: left;
          }
          &:nth-of-type(even) {
            width: 60%;
            float: right;
          }
        }
      }
      .card {
        > label {
          color: var(--primary) !important;
        }
      }
    }
    .MuiGrid-grid-lg-6 {
      .card {
        > label {
          color: var(--transparent) !important;
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 680px) {
    width: 100vw;
    .MuiGrid-grid-lg-3 {
      :nth-of-type(1) {
        .card {
          > label {
            display: none;
          }
          display: inline-block;
          width: 100% !important;
          float: none;
        }
      }
    }
    .MuiGrid-grid-lg-6 {
      .card {
        > label {
          display: none;
        }
      }
    }
  }
`;

export const LegendLeft = styled.div.attrs((props: Props) => ({}))`
  position: absolute;
  max-width: 18%;
  min-width: 18%;
  height: 90%;
  bottom: 50px;
  left: 0;
  background: var(--white);
  border: 0;
  border-radius: 25px;
  overflow: hidden;
  padding: 15px 0;
  box-shadow: 0 3px 6px var(--card-shadow);

  .btn-legend {
    display: block;
    white-space: pre-line;
    font-size: 15px;
    line-height: 1.2;
    font-family: "GalanoGrotesque-SemiBold";
    color: var(--dashboard-primary-bg);
    text-transform: capitalize;
    font-weight: 900;
    padding-left: 6px;
    padding-rigth: 2px;
    border-left: 14px solid transparent;
    border-radius: 0 !important;
    margin-bottom: 10px;

    .MuiButton-label {
      padding-bottom: 3px;
      margin-top: -7px;
    }

    &.active,
    :hover,
    :focus {
      border-left: 14px solid var(--dashboard-highlighted);
      background: transparent;
    }
  }

  @media screen and (max-width: 1300px) {
    max-width: 130px;
    height: 80%;
  }

  @media screen and (max-width: 700px) {
    max-width: 20%;
    min-width: 20%;
    height: 15%;
    top: 5%;
    border-radius: 10px;
    padding: 10px 0
    .btn-legend {
      font-size: 15px !important;
      padding-left: 5px;
      margin-bottom: 5px;
    }
  }
`;

export const LegendRight = styled.div.attrs((props: Props) => ({}))`
  // flex: 1;

  @media screen and (max-width: 1300px) {
    flex: auto;
    min-width: 100%;
  }
`;


export const MapLeft = styled.div.attrs((props: Props) => ({}))`
  img {
    width: 280px;

    @media screen and (max-width: 1300px) {
      width: 100%; // Torna a imagem 100% da largura do contêiner pai
    }
  }
`;

export const MapRight = styled.div.attrs((props: Props) => ({}))`
  padding: 3rem;

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border-bottom: 2px solid var(--primary);
    border-radius: 0;
    padding: 0;
  }

  .MuiInputLabel-formControl {
    left: -10px;
  }

  .select-item .MuiSelect-root {
    padding: 20px 5px 10px;
  }

  .MuiSelect-iconOutlined {
    right: 0;
    color: var(--primary);
  }

  .react-date-picker .react-date-picker__wrapper {
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid var(--primary) !important;
    svg,
    svg * {
      stroke: var(--primary) !important;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 2rem; // Ajuste conforme necessário
  }
`;

export const List = styled.div.attrs((props: Props) => ({}))`
  align-items: baseline;
  width: 100%;

  div {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1600px) {
    div {
      align-self: baseline;
      flex-direction: row;
      text-align: left;
      width: 100%;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    div {
      flex-direction: column;
      text-align: center;
      width: 100%;
      margin: 0 0 15px;
      &:last-of-type {
        margin: 0;
      }
      img {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    align-self: baseline;
    div {
      width: 100%;
      flex-direction: row;
      margin-bottom: 10px;
      align-self: baseline;
    }
  }
`;

export const Loading = styled.div.attrs((props: Props) => ({}))`
  display: table;
  margin: 2rem auto;
`;
//-----------------*/

/* ----------------------  End Initial Page */

/* ----------------------    Graph Page */
export const GraphPage = styled.div.attrs((props: Props) => ({}))`
  .select-item {
    width: 100%;
    margin-top: 10px;
    .MuiSelect-root {
      padding: 10px;
    }
  }

  .react-date-picker {
    width: 100%;
    margin-top: 10px;
    .react-date-picker__wrapper {
      border-radius: 5px;
      flex-grow: 1;
      flex-shrink: 0;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 5px;
      color: var(--dashboard-text-default);
      svg,
      svg * {
        stroke: var(--dashboard-separator-title);
      }
    }
  }

  .recharts-text {
    fill: var(--dashboard-separator-title);
  }

  .recharts-responsive-container {
    width: 90% !important;
    margin: 2rem 2rem 1rem;

    @media screen and (max-width: 1280px) {
      margin: 1rem 0;
    }
  }
`;

export const Center = styled.span.attrs((props: Props) => ({}))`
  display: table;
  margin: auto;

  @media screen and (max-width: 1440px) {
  }
`;

export const Right = styled.span.attrs((props: Props) => ({}))`
  display: inline-flex;
  float: right;
  align-items: center;
  column-gap: 0px;
`;
