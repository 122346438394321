import React, { useState } from "react";
import { useIntl } from "react-intl";
import About from "../Tools/About";
//import Newsletter from "../Forms/Newsletter";
import Column from "../Areas/Column";
import Container from "../Areas/Container";
import Row from "../Areas/Row";
import { Footer, Copy, ToTop } from "./styles";
import RegionPicker from "../Tools/RegionPicker";
import Icon from "../BasicComponents/Icon";
import Text from "../BasicComponents/Text";
import Flags from "../Tools/FlagLocalePicker";
import Nav from "../Tools/Menu";
import Networks from "../Tools/Social";

interface Props {
  image?: boolean;
  description: string;
}

export default (props: Props) => {
  const intl = useIntl();

  // to top button
  const [showToTop, setShowToTop] = useState(false);
  function toTop() {
    //defines when the to-top button should be displayed
    if (window.pageYOffset > 400) {
      setShowToTop(true);
    } else if (window.pageYOffset <= 400) {
      setShowToTop(false);
    }
  }
  window.addEventListener("scroll", toTop);

  const menuItems = [
    // {
    //   active: false,
    //   link: "/prices",
    //   text: intl.formatMessage({ id: "section.prices.category" }),
    // },
    { active: false, link: "/about", text: intl.formatMessage({ id: "menu.about" }) },
    { active: false, link: "/contact", text: intl.formatMessage({ id: "menu.contact" }) },
    { active: false, link: "/solutions", text: intl.formatMessage({ id: "menu.solutions" }) },
  ];

  const copyItems = [
    { active: false, link: "/privacy", text: intl.formatMessage({ id: "menu.privacy" }) },
    { active: false, link: "/terms", text: intl.formatMessage({ id: "menu.terms" }) },
  ];

  const socialItems = [
    { type: "linkedin", link: "https://www.linkedin.com/company/77182512" },
    { type: "facebook", link: "https://www.facebook.com/flybiohub" },
    //{ type: "twitter", link: "" },
    { type: "instagram", link: "https://www.instagram.com/flybiohub" },
  ];

  return (
    <>
      <Footer>
        <Container>
          <Row>
            <Column lg={4} md={4} sm={6}>
              <About
                image={props.image}
                description={intl.formatMessage({ id: "page.description" })}
              />
            </Column>
            <Column lg={3} md={3} sm={6}>
              <Text
                text={intl.formatMessage({ id: "app.title" })}
                appearance={"title"}
                size={"medium"}
              />
              <Nav menuItems={menuItems} orientation={"column"} color={"dark"} />
              <Networks socialItems={socialItems} />
            </Column>
            {/* <Column lg={5} md={5} sm={12} xs={12}>
              <Newsletter />
            </Column> */}
          </Row>
        </Container>
        <Copy>
          <Container>
            <Row>
              <Column lg={9} sm={6} xs={12}>
                <Text size={"small"} text={intl.formatMessage({ id: "info.copyright" })} />
              </Column>
              <Column lg={3} sm={6} xs={12}>
                <Nav menuItems={copyItems} orientation={"horizontal"} color={"dark"} />
              </Column>
            </Row>
          </Container>
        </Copy>
        <Flags />
        <RegionPicker />
        <ToTop href="#top" style={{ display: showToTop ? "inline" : "none" }}>
          <Icon type={"expand_less"}></Icon>
        </ToTop>
      </Footer>
    </>
  );
};
