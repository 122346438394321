import { OutlinedInputProps } from "@material-ui/core";
import React, { useState } from "react";
import { TextField } from "./styles";

interface Props {
  type?: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  onChange: (text: string) => void;
  backgroundColor?: string;
  color?: "primary" | "secondary";
  // Place to put an adornment component in the TextField
  startAdornment?: JSX.Element;
  // Puttting an adorment component you will need to control the
  // insert text field padding and the not selected placeholder padding
  // to not overlap the icon
  // a good value for inserTextFieldPadding is 30
  insertTextFieldPadding?: number;
  // a good value for notSelectedPlaceholderPadding is 30
  notSelectedPlaceholderPadding?: number;
  labelVerticalPadding?: number;
  InputProps?: Partial<OutlinedInputProps>;
}

export default (props: Props) => {
  return props.startAdornment !== undefined ? (
    <div style={{ position: "relative" }}>
      {props.startAdornment !== undefined && (
        <div style={{ position: "absolute", paddingTop: 8, paddingLeft: 12 }}>
          {props.startAdornment}
        </div>
      )}
      <_TextField {...props} />
    </div>
  ) : (
    <_TextField {...props} />
  );
};

const _TextField = function (props: Props) {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <TextField
      label={props.placeholder}
      type={props.type}
      required={props.required}
      variant="outlined"
      size="small"
      value={props.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value);
      }}
      backgroundColor={props.backgroundColor}
      color={props.color}
      InputProps={{
        ...props.InputProps,
        style: {
          paddingLeft: props.insertTextFieldPadding,
        },
      }}
      notSelectedPlaceholderPadding={props.notSelectedPlaceholderPadding}
      focused={focused}
      labelVerticalPadding={props.labelVerticalPadding}
      onFocus={(e) => setFocused(true)}
      onBlur={(e) => setFocused(false)}
    />
  );
};
