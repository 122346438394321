import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import MapFlightComponent from "../../../components/map/impl/components/Flight";
import RenderAccordingBoundingBox from "./RenderAccordingBoundingBox";
import { boundingBoxForLocations } from "../../../core/geometricFunctions";

type Props = {
  projectId: string;
  areaId: string;
  flightId: string;
  releaseTracksZIndex: number;
  pathZIndex: number;
  pathBorderZIndex: number;
};

export default function (props: Props): JSX.Element {
  const flight = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const flight = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId)
      ?.flightList?.find((flight) => flight.id === props.flightId);

    return flight;
  });

  const flightBoundingBox =
    flight && flight.visible && flight.realData
      ? boundingBoxForLocations(flight.realData.completeRoute)
      : undefined;

  return (
    <>
      {flight && flight.visible && flightBoundingBox && (
        <RenderAccordingBoundingBox elementBoundingBox={flightBoundingBox!}>
          <MapFlightComponent
            flightId={flight.id}
            trackWidth={flight.flightEnvironmentSnapshot.trackWidth}
            flownRoute={flight.realData!.completeRoute}
            logData={flight.realData!.logData}
            releaseTracksZIndex={props.releaseTracksZIndex}
            pathZIndex={props.pathZIndex}
            pathBorderZIndex={props.pathBorderZIndex}
          />
        </RenderAccordingBoundingBox>
      )}
    </>
  );
}
