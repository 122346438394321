import React from "react";
import { useIntl } from "react-intl";
import Container from "../../Areas/Container";
import Image from "../../BasicComponents/Image";
import Section from "../../Section";
import { Clickable, Logos } from "./styles";

/* Images */
import imageKoppert from "../../../../assets/partners/koppert.png";
import imagePopoyan from "../../../../assets/partners/popoyan.png";
import imageScientia from "../../../../assets/partners/hisscientia.png";
import imageFapesp from "../../../../assets/partners/fapesp.png";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();
  const partners = [
    {
      id: "PART01",
      name: "Koppert",
      image: imageKoppert,
    },
    {
      id: "PART02",
      name: "Popoyán",
      image: imagePopoyan,
    },
    {
      id: "PART03",
      name: "Scientia",
      image: imageScientia,
    },
    {
      id: "PART04",
      name: "Fapesp",
      image: imageFapesp,
    },
  ];

  return (
    <>
      <Section
        category={intl.formatMessage({ id: "section.partners.category" })}
        sectionColor={"light"}
        id="contact"
        appearanceText={"title-semibold"}
        sizeText={"medium"}
      >
        <Container>
          <Logos>
            {partners.map((item) => (
              <Clickable key={item.id}>
                <Image src={item.image} alt={item.name} appearance={"img-partner"} />
              </Clickable>
            ))}
          </Logos>
        </Container>
      </Section>
    </>
  );
};
