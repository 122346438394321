import styled from "styled-components";

type Props = {};

export const Logos = styled.div.attrs((props: Props) => ({}))`
  display: block;
  width: 100%;
`;

export const Clickable = styled.span.attrs((props: Props) => ({}))`
  display: inline-flex;
  cursor: pointer;
  width: 25%;
  text-align: center;
  align-items: center;
  height: 80px;

  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;
