import { Link, Icon, CircularProgress, Button, TextField, Grid } from "@material-ui/core";
import { Company, DirectClient, Profile } from "biohub-model";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ProfileState } from "../../../store/reducers/profileReducer";
import { SystemState } from "../../../store/reducers/systemReducer";
import FlipCard from "../../../components/Atomic/BasicComponents/FlipCard";
import { Block, Actions, EditAvatar, Contract, Loading, Action } from "../styles";

export default function ProfileTable() {
  const intl = useIntl();
  const [active, setActive] = React.useState<boolean>(false);

  //terms
  const termContract = intl.formatMessage({ id: "info.contract" });
  const termSave = intl.formatMessage({ id: "action.save" });
  const termName = intl.formatMessage({ id: "placeholder.name" });
  const termEmail = intl.formatMessage({ id: "placeholder.email" });
  const termPhone = intl.formatMessage({ id: "placeholder.phone" });
  const termCompanyName = intl.formatMessage({ id: "placeholder.trade.name" });
  const termAddress = intl.formatMessage({ id: "placeholder.address" });
  const termNumber = intl.formatMessage({ id: "placeholder.number" });
  const termCity = intl.formatMessage({ id: "placeholder.city" });
  const termState = intl.formatMessage({ id: "placeholder.state.department" });
  const termZIP = intl.formatMessage({ id: "placeholder.zip" });
  const termDescription = intl.formatMessage({ id: "placeholder.description" });
  const termEditPicture = intl.formatMessage({ id: "action.edit.picture" });

  const loadingProfileImage: boolean = useSelector(
    (state: SystemState) => state.profile.loadingProfileImage
  );
  // temporary picture - styling only
  const picture = "https://i.pravatar.cc/120?img=8"; // profile.picture

  const profileState: ProfileState = useSelector((state: SystemState) => state.profile);
  const systemProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );
  const [profile, setProfile] = useState<Profile | null>(systemProfile);
  const loadingProfile: boolean = useSelector((state: SystemState) => state.profile.loadingProfile);
  const directClient: DirectClient | null = profileState.directClient as DirectClient;

  useEffect(() => {
    if (loadingProfileImage) {
    } else {
      setProfile(systemProfile);
    }
  }, [systemProfile, loadingProfileImage]);

  let company: Company | undefined =
    directClient !== null ? (directClient.company as Company) : undefined;

  return (
    <>
      {/* inform the property "avatar" if displaying image - Avatar Component */}
      {/* the flipcard can be removed if there is no need for editing (or just the edit button and fields) */}

      {!loadingProfile && profile !== null ? (
        <FlipCard
          active={active}
          frontChildren={[
            <Block elevation={1} avatar>
              <Grid container spacing={1}>
                <Grid item lg={3} xs={12}>
                  <EditAvatar>
                    <img src={profile.profileImageUrl} alt={profile.name} />
                    <Button>
                      <Icon>create</Icon> {termEditPicture}
                    </Button>
                    <Contract>
                      <Link href={directClient.contract.documentUrl} target="_blank">
                        {termContract}
                      </Link>
                      <Icon onClick={() => {}}>delete</Icon>
                    </Contract>
                  </EditAvatar>
                </Grid>
                <Grid container spacing={1} lg={9} xs={12}>
                  <Grid item lg={7} xs={12}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label={termName}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={5} xs={12}>
                    <TextField
                      margin="dense"
                      id="company"
                      label={termCompanyName}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      id="email"
                      label={termEmail}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      margin="dense"
                      id="telephone"
                      label={termPhone}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      margin="dense"
                      id="zip"
                      label={termZIP}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={9} xs={7}>
                    <TextField
                      margin="dense"
                      id="address"
                      label={termAddress}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={3} xs={5}>
                    <TextField
                      margin="dense"
                      id="nome"
                      label={termNumber}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={7} xs={8}>
                    <TextField
                      margin="dense"
                      id="city"
                      label={termCity}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={5} xs={4}>
                    <TextField
                      margin="dense"
                      id="state"
                      label={termState}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="description"
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      label={termDescription}
                      multiline
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Actions className="flip-card">
                <Action>
                  <Button onClick={() => {}} variant="contained" color="primary">
                    {termSave}
                  </Button>
                </Action>
              </Actions>
            </Block>,
          ]}
          backChildren={[]}
        />
      ) : (
        <Loading>
          <CircularProgress />
        </Loading>
      )}
    </>
  );
}
