export type CountryNumber = { countryCode: string; countryName: string; code: string };

const countries: CountryNumber[] = [
  { countryCode: "AD", countryName: "Andorra", code: "376" },
  {
    countryCode: "AE",
    countryName: "United Arab Emirates",
    code: "971",
  },
  { countryCode: "AF", countryName: "Afghanistan", code: "93" },
  {
    countryCode: "AG",
    countryName: "Antigua and Barbuda",
    code: "1-268",
  },
  { countryCode: "AI", countryName: "Anguilla", code: "1-264" },
  { countryCode: "AL", countryName: "Albania", code: "355" },
  { countryCode: "AM", countryName: "Armenia", code: "374" },
  { countryCode: "AO", countryName: "Angola", code: "244" },
  { countryCode: "AQ", countryName: "Antarctica", code: "672" },
  { countryCode: "AR", countryName: "Argentina", code: "54" },
  { countryCode: "AS", countryName: "American Samoa", code: "1-684" },
  { countryCode: "AT", countryName: "Austria", code: "43" },
  {
    countryCode: "AU",
    countryName: "Australia",
    code: "61",
    //suggested: true,
  },
  { countryCode: "AW", countryName: "Aruba", code: "297" },
  { countryCode: "AX", countryName: "Alland Islands", code: "358" },
  { countryCode: "AZ", countryName: "Azerbaijan", code: "994" },
  {
    countryCode: "BA",
    countryName: "Bosnia and Herzegovina",
    code: "387",
  },
  { countryCode: "BB", countryName: "Barbados", code: "1-246" },
  { countryCode: "BD", countryName: "Bangladesh", code: "880" },
  { countryCode: "BE", countryName: "Belgium", code: "32" },
  { countryCode: "BF", countryName: "Burkina Faso", code: "226" },
  { countryCode: "BG", countryName: "Bulgaria", code: "359" },
  { countryCode: "BH", countryName: "Bahrain", code: "973" },
  { countryCode: "BI", countryName: "Burundi", code: "257" },
  { countryCode: "BJ", countryName: "Benin", code: "229" },
  { countryCode: "BL", countryName: "Saint Barthelemy", code: "590" },
  { countryCode: "BM", countryName: "Bermuda", code: "1-441" },
  { countryCode: "BN", countryName: "Brunei Darussalam", code: "673" },
  { countryCode: "BO", countryName: "Bolivia", code: "591" },
  { countryCode: "BR", countryName: "Brazil", code: "55" },
  { countryCode: "BS", countryName: "Bahamas", code: "1-242" },
  { countryCode: "BT", countryName: "Bhutan", code: "975" },
  { countryCode: "BV", countryName: "Bouvet Island", code: "47" },
  { countryCode: "BW", countryName: "Botswana", code: "267" },
  { countryCode: "BY", countryName: "Belarus", code: "375" },
  { countryCode: "BZ", countryName: "Belize", code: "501" },
  {
    countryCode: "CA",
    countryName: "Canada",
    code: "1",
    //suggested: true,
  },
  {
    countryCode: "CC",
    countryName: "Cocos (Keeling) Islands",
    code: "61",
  },
  {
    countryCode: "CD",
    countryName: "Congo, Democratic Republic of the",
    code: "243",
  },
  {
    countryCode: "CF",
    countryName: "Central African Republic",
    code: "236",
  },
  {
    countryCode: "CG",
    countryName: "Congo, Republic of the",
    code: "242",
  },
  { countryCode: "CH", countryName: "Switzerland", code: "41" },
  { countryCode: "CI", countryName: "Cote d'Ivoire", code: "225" },
  { countryCode: "CK", countryName: "Cook Islands", code: "682" },
  { countryCode: "CL", countryName: "Chile", code: "56" },
  { countryCode: "CM", countryName: "Cameroon", code: "237" },
  { countryCode: "CN", countryName: "China", code: "86" },
  { countryCode: "CO", countryName: "Colombia", code: "57" },
  { countryCode: "CR", countryName: "Costa Rica", code: "506" },
  { countryCode: "CU", countryName: "Cuba", code: "53" },
  { countryCode: "CV", countryName: "Cape Verde", code: "238" },
  { countryCode: "CW", countryName: "Curacao", code: "599" },
  { countryCode: "CX", countryName: "Christmas Island", code: "61" },
  { countryCode: "CY", countryName: "Cyprus", code: "357" },
  { countryCode: "CZ", countryName: "Czech Republic", code: "420" },
  {
    countryCode: "DE",
    countryName: "Germany",
    code: "49",
    //suggested: true,
  },
  { countryCode: "DJ", countryName: "Djibouti", code: "253" },
  { countryCode: "DK", countryName: "Denmark", code: "45" },
  { countryCode: "DM", countryName: "Dominica", code: "1-767" },
  {
    countryCode: "DO",
    countryName: "Dominican Republic",
    code: "1-809",
  },
  { countryCode: "DZ", countryName: "Algeria", code: "213" },
  { countryCode: "EC", countryName: "Ecuador", code: "593" },
  { countryCode: "EE", countryName: "Estonia", code: "372" },
  { countryCode: "EG", countryName: "Egypt", code: "20" },
  { countryCode: "EH", countryName: "Western Sahara", code: "212" },
  { countryCode: "ER", countryName: "Eritrea", code: "291" },
  { countryCode: "ES", countryName: "Spain", code: "34" },
  { countryCode: "ET", countryName: "Ethiopia", code: "251" },
  { countryCode: "FI", countryName: "Finland", code: "358" },
  { countryCode: "FJ", countryName: "Fiji", code: "679" },
  {
    countryCode: "FK",
    countryName: "Falkland Islands (Malvinas)",
    code: "500",
  },
  {
    countryCode: "FM",
    countryName: "Micronesia, Federated States of",
    code: "691",
  },
  { countryCode: "FO", countryName: "Faroe Islands", code: "298" },
  {
    countryCode: "FR",
    countryName: "France",
    code: "33",
    //suggested: true,
  },
  { countryCode: "GA", countryName: "Gabon", code: "241" },
  { countryCode: "GB", countryName: "United Kingdom", code: "44" },
  { countryCode: "GD", countryName: "Grenada", code: "1-473" },
  { countryCode: "GE", countryName: "Georgia", code: "995" },
  { countryCode: "GF", countryName: "French Guiana", code: "594" },
  { countryCode: "GG", countryName: "Guernsey", code: "44" },
  { countryCode: "GH", countryName: "Ghana", code: "233" },
  { countryCode: "GI", countryName: "Gibraltar", code: "350" },
  { countryCode: "GL", countryName: "Greenland", code: "299" },
  { countryCode: "GM", countryName: "Gambia", code: "220" },
  { countryCode: "GN", countryName: "Guinea", code: "224" },
  { countryCode: "GP", countryName: "Guadeloupe", code: "590" },
  { countryCode: "GQ", countryName: "Equatorial Guinea", code: "240" },
  { countryCode: "GR", countryName: "Greece", code: "30" },
  {
    countryCode: "GS",
    countryName: "South Georgia and the South Sandwich Islands",
    code: "500",
  },
  { countryCode: "GT", countryName: "Guatemala", code: "502" },
  { countryCode: "GU", countryName: "Guam", code: "1-671" },
  { countryCode: "GW", countryName: "Guinea-Bissau", code: "245" },
  { countryCode: "GY", countryName: "Guyana", code: "592" },
  { countryCode: "HK", countryName: "Hong Kong", code: "852" },
  {
    countryCode: "HM",
    countryName: "Heard Island and McDonald Islands",
    code: "672",
  },
  { countryCode: "HN", countryName: "Honduras", code: "504" },
  { countryCode: "HR", countryName: "Croatia", code: "385" },
  { countryCode: "HT", countryName: "Haiti", code: "509" },
  { countryCode: "HU", countryName: "Hungary", code: "36" },
  { countryCode: "ID", countryName: "Indonesia", code: "62" },
  { countryCode: "IE", countryName: "Ireland", code: "353" },
  { countryCode: "IL", countryName: "Israel", code: "972" },
  { countryCode: "IM", countryName: "Isle of Man", code: "44" },
  { countryCode: "IN", countryName: "India", code: "91" },
  {
    countryCode: "IO",
    countryName: "British Indian Ocean Territory",
    code: "246",
  },
  { countryCode: "IQ", countryName: "Iraq", code: "964" },
  {
    countryCode: "IR",
    countryName: "Iran, Islamic Republic of",
    code: "98",
  },
  { countryCode: "IS", countryName: "Iceland", code: "354" },
  { countryCode: "IT", countryName: "Italy", code: "39" },
  { countryCode: "JE", countryName: "Jersey", code: "44" },
  { countryCode: "JM", countryName: "Jamaica", code: "1-876" },
  { countryCode: "JO", countryName: "Jordan", code: "962" },
  {
    countryCode: "JP",
    countryName: "Japan",
    code: "81",
    //suggested: true,
  },
  { countryCode: "KE", countryName: "Kenya", code: "254" },
  { countryCode: "KG", countryName: "Kyrgyzstan", code: "996" },
  { countryCode: "KH", countryName: "Cambodia", code: "855" },
  { countryCode: "KI", countryName: "Kiribati", code: "686" },
  { countryCode: "KM", countryName: "Comoros", code: "269" },
  {
    countryCode: "KN",
    countryName: "Saint Kitts and Nevis",
    code: "1-869",
  },
  {
    countryCode: "KP",
    countryName: "Korea, Democratic People's Republic of",
    code: "850",
  },
  { countryCode: "KR", countryName: "Korea, Republic of", code: "82" },
  { countryCode: "KW", countryName: "Kuwait", code: "965" },
  { countryCode: "KY", countryName: "Cayman Islands", code: "1-345" },
  { countryCode: "KZ", countryName: "Kazakhstan", code: "7" },
  {
    countryCode: "LA",
    countryName: "Lao People's Democratic Republic",
    code: "856",
  },
  { countryCode: "LB", countryName: "Lebanon", code: "961" },
  { countryCode: "LC", countryName: "Saint Lucia", code: "1-758" },
  { countryCode: "LI", countryName: "Liechtenstein", code: "423" },
  { countryCode: "LK", countryName: "Sri Lanka", code: "94" },
  { countryCode: "LR", countryName: "Liberia", code: "231" },
  { countryCode: "LS", countryName: "Lesotho", code: "266" },
  { countryCode: "LT", countryName: "Lithuania", code: "370" },
  { countryCode: "LU", countryName: "Luxembourg", code: "352" },
  { countryCode: "LV", countryName: "Latvia", code: "371" },
  { countryCode: "LY", countryName: "Libya", code: "218" },
  { countryCode: "MA", countryName: "Morocco", code: "212" },
  { countryCode: "MC", countryName: "Monaco", code: "377" },
  {
    countryCode: "MD",
    countryName: "Moldova, Republic of",
    code: "373",
  },
  { countryCode: "ME", countryName: "Montenegro", code: "382" },
  {
    countryCode: "MF",
    countryName: "Saint Martin (French part)",
    code: "590",
  },
  { countryCode: "MG", countryName: "Madagascar", code: "261" },
  { countryCode: "MH", countryName: "Marshall Islands", code: "692" },
  {
    countryCode: "MK",
    countryName: "Macedonia, the Former Yugoslav Republic of",
    code: "389",
  },
  { countryCode: "ML", countryName: "Mali", code: "223" },
  { countryCode: "MM", countryName: "Myanmar", code: "95" },
  { countryCode: "MN", countryName: "Mongolia", code: "976" },
  { countryCode: "MO", countryName: "Macao", code: "853" },
  {
    countryCode: "MP",
    countryName: "Northern Mariana Islands",
    code: "1-670",
  },
  { countryCode: "MQ", countryName: "Martinique", code: "596" },
  { countryCode: "MR", countryName: "Mauritania", code: "222" },
  { countryCode: "MS", countryName: "Montserrat", code: "1-664" },
  { countryCode: "MT", countryName: "Malta", code: "356" },
  { countryCode: "MU", countryName: "Mauritius", code: "230" },
  { countryCode: "MV", countryName: "Maldives", code: "960" },
  { countryCode: "MW", countryName: "Malawi", code: "265" },
  { countryCode: "MX", countryName: "Mexico", code: "52" },
  { countryCode: "MY", countryName: "Malaysia", code: "60" },
  { countryCode: "MZ", countryName: "Mozambique", code: "258" },
  { countryCode: "NA", countryName: "Namibia", code: "264" },
  { countryCode: "NC", countryName: "New Caledonia", code: "687" },
  { countryCode: "NE", countryName: "Niger", code: "227" },
  { countryCode: "NF", countryName: "Norfolk Island", code: "672" },
  { countryCode: "NG", countryName: "Nigeria", code: "234" },
  { countryCode: "NI", countryName: "Nicaragua", code: "505" },
  { countryCode: "NL", countryName: "Netherlands", code: "31" },
  { countryCode: "NO", countryName: "Norway", code: "47" },
  { countryCode: "NP", countryName: "Nepal", code: "977" },
  { countryCode: "NR", countryName: "Nauru", code: "674" },
  { countryCode: "NU", countryName: "Niue", code: "683" },
  { countryCode: "NZ", countryName: "New Zealand", code: "64" },
  { countryCode: "OM", countryName: "Oman", code: "968" },
  { countryCode: "PA", countryName: "Panama", code: "507" },
  { countryCode: "PE", countryName: "Peru", code: "51" },
  { countryCode: "PF", countryName: "French Polynesia", code: "689" },
  { countryCode: "PG", countryName: "Papua New Guinea", code: "675" },
  { countryCode: "PH", countryName: "Philippines", code: "63" },
  { countryCode: "PK", countryName: "Pakistan", code: "92" },
  { countryCode: "PL", countryName: "Poland", code: "48" },
  {
    countryCode: "PM",
    countryName: "Saint Pierre and Miquelon",
    code: "508",
  },
  { countryCode: "PN", countryName: "Pitcairn", code: "870" },
  { countryCode: "PR", countryName: "Puerto Rico", code: "1" },
  {
    countryCode: "PS",
    countryName: "Palestine, State of",
    code: "970",
  },
  { countryCode: "PT", countryName: "Portugal", code: "351" },
  { countryCode: "PW", countryName: "Palau", code: "680" },
  { countryCode: "PY", countryName: "Paraguay", code: "595" },
  { countryCode: "QA", countryName: "Qatar", code: "974" },
  { countryCode: "RE", countryName: "Reunion", code: "262" },
  { countryCode: "RO", countryName: "Romania", code: "40" },
  { countryCode: "RS", countryName: "Serbia", code: "381" },
  { countryCode: "RU", countryName: "Russian Federation", code: "7" },
  { countryCode: "RW", countryName: "Rwanda", code: "250" },
  { countryCode: "SA", countryName: "Saudi Arabia", code: "966" },
  { countryCode: "SB", countryName: "Solomon Islands", code: "677" },
  { countryCode: "SC", countryName: "Seychelles", code: "248" },
  { countryCode: "SD", countryName: "Sudan", code: "249" },
  { countryCode: "SE", countryName: "Sweden", code: "46" },
  { countryCode: "SG", countryName: "Singapore", code: "65" },
  { countryCode: "SH", countryName: "Saint Helena", code: "290" },
  { countryCode: "SI", countryName: "Slovenia", code: "386" },
  {
    countryCode: "SJ",
    countryName: "Svalbard and Jan Mayen",
    code: "47",
  },
  { countryCode: "SK", countryName: "Slovakia", code: "421" },
  { countryCode: "SL", countryName: "Sierra Leone", code: "232" },
  { countryCode: "SM", countryName: "San Marino", code: "378" },
  { countryCode: "SN", countryName: "Senegal", code: "221" },
  { countryCode: "SO", countryName: "Somalia", code: "252" },
  { countryCode: "SR", countryName: "Suriname", code: "597" },
  { countryCode: "SS", countryName: "South Sudan", code: "211" },
  {
    countryCode: "ST",
    countryName: "Sao Tome and Principe",
    code: "239",
  },
  { countryCode: "SV", countryName: "El Salvador", code: "503" },
  {
    countryCode: "SX",
    countryName: "Sint Maarten (Dutch part)",
    code: "1-721",
  },
  {
    countryCode: "SY",
    countryName: "Syrian Arab Republic",
    code: "963",
  },
  { countryCode: "SZ", countryName: "Swaziland", code: "268" },
  {
    countryCode: "TC",
    countryName: "Turks and Caicos Islands",
    code: "1-649",
  },
  { countryCode: "TD", countryName: "Chad", code: "235" },
  {
    countryCode: "TF",
    countryName: "French Southern Territories",
    code: "262",
  },
  { countryCode: "TG", countryName: "Togo", code: "228" },
  { countryCode: "TH", countryName: "Thailand", code: "66" },
  { countryCode: "TJ", countryName: "Tajikistan", code: "992" },
  { countryCode: "TK", countryName: "Tokelau", code: "690" },
  { countryCode: "TL", countryName: "Timor-Leste", code: "670" },
  { countryCode: "TM", countryName: "Turkmenistan", code: "993" },
  { countryCode: "TN", countryName: "Tunisia", code: "216" },
  { countryCode: "TO", countryName: "Tonga", code: "676" },
  { countryCode: "TR", countryName: "Turkey", code: "90" },
  {
    countryCode: "TT",
    countryName: "Trinidad and Tobago",
    code: "1-868",
  },
  { countryCode: "TV", countryName: "Tuvalu", code: "688" },
  {
    countryCode: "TW",
    countryName: "Taiwan, Province of China",
    code: "886",
  },
  {
    countryCode: "TZ",
    countryName: "United Republic of Tanzania",
    code: "255",
  },
  { countryCode: "UA", countryName: "Ukraine", code: "380" },
  { countryCode: "UG", countryName: "Uganda", code: "256" },
  {
    countryCode: "US",
    countryName: "United States",
    code: "1",
    //suggested: true,
  },
  { countryCode: "UY", countryName: "Uruguay", code: "598" },
  { countryCode: "UZ", countryName: "Uzbekistan", code: "998" },
  {
    countryCode: "VA",
    countryName: "Holy See (Vatican City State)",
    code: "379",
  },
  {
    countryCode: "VC",
    countryName: "Saint Vincent and the Grenadines",
    code: "1-784",
  },
  { countryCode: "VE", countryName: "Venezuela", code: "58" },
  {
    countryCode: "VG",
    countryName: "British Virgin Islands",
    code: "1-284",
  },
  {
    countryCode: "VI",
    countryName: "US Virgin Islands",
    code: "1-340",
  },
  { countryCode: "VN", countryName: "Vietnam", code: "84" },
  { countryCode: "VU", countryName: "Vanuatu", code: "678" },
  { countryCode: "WF", countryName: "Wallis and Futuna", code: "681" },
  { countryCode: "WS", countryName: "Samoa", code: "685" },
  { countryCode: "XK", countryName: "Kosovo", code: "383" },
  { countryCode: "YE", countryName: "Yemen", code: "967" },
  { countryCode: "YT", countryName: "Mayotte", code: "262" },
  { countryCode: "ZA", countryName: "South Africa", code: "27" },
  { countryCode: "ZM", countryName: "Zambia", code: "260" },
  { countryCode: "ZW", countryName: "Zimbabwe", code: "263" },
];

const countriesOrder = countries
  .map((c) => ({
    ...c,
    code: c.code.replace(/[^0-9]/g, ""),
  }))
  .sort((a: CountryNumber, b: CountryNumber) => (Number(a.code) > Number(b.code) ? 1 : -1))
  .map((c) => ({
    ...c,
    code: Number(c.code) > 1000 ? c.code.substr(0, 1) + "-" + c.code.substr(1, 3) : c.code,
  }));

export default countriesOrder;
