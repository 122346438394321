import React from "react";
import Tag from "../../BasicComponents/Tag";
import Text from "../../BasicComponents/Text";
import Image from "../../BasicComponents/Image";
import Information from "../TextLearnMore";
import Container from "../../Areas/Container";
import Column from "../../Areas/Column";
import Row from "../../Areas/Row";
import { Section } from "./styles";

interface Props {
  sectionTitle?: string;
  sectionColor?: "primary";
  category?: string;
  highlight?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  image?: string;
  direction?: string;
  id?: string;
  sectionTitleMark?: boolean;
  textEndSection?: string;
  imageSeparatorPlus?: string;
  textAlign?: string;
  linkTo?: string;
  extraDetails?: Array<{
    id: string;
    detail: string;
    image: string | "url";
  }>;
  labelButtonTerm?: string | "action.learnMore";
}

export default (props: Props) => {
  return (
    <>
      <Section
        imageSeparatorPlus={props.imageSeparatorPlus}
        sectionColor={props.sectionColor}
        id={props.id}
      >
        <Container>
          {props.sectionTitle && (
            <Text
              text={props.sectionTitle}
              appearance={"title-semibold"}
              textAlign={"center"}
              color={"default"}
              sectionTitleMark={props.sectionTitleMark}
            />
          )}
          {props.category && (
            <Tag
              text={props.category}
              appearance={"subtitle-semibold"}
              textAlign={"center"}
              color={"primary"}
            />
          )}
          <Row direction={props.direction}>
            <Column lg={6} sm={5} xs={12}>
              <Information
                title={props.title}
                subtitle={props.subtitle}
                description={props.description}
                color={props.sectionColor}
                showButtons={props.labelButtonTerm !== undefined}
                linkTo={props.linkTo}
                highlight={props.highlight}
                extraDetails={props.extraDetails}
                labelButtonTerm={props.labelButtonTerm}
              />
            </Column>
            <Column lg={6} sm={5} xs={12}>
              <Image src={props.image} alt={props.category} />
            </Column>
          </Row>
          {props.textEndSection && (
            <Row alignItems="center">
              <Column lg={12} sm={12}>
                <Text
                  textAlign="center"
                  text={props.textEndSection}
                  color={"secondary"}
                  appearance={"info-required"}
                />
              </Column>
            </Row>
          )}
        </Container>
        {props.imageSeparatorPlus && (
          <Image src={props.imageSeparatorPlus} appearance={"img-separator-section"} />
        )}
      </Section>
    </>
  );
};
