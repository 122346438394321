import { ReleaseRateUnit, ReleaserModel, ReleaserType, UnitSystem } from "biohub-model";

const distanceUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.imperial) {
    return "ft";
  }
  return "m";
};

const distanceValue = (
  meterDistance: number,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  if (unitSystem === UnitSystem.imperial) {
    return (meterDistance * _mToFt).toFixed(decimalPrecision);
  }
  return meterDistance.toFixed(decimalPrecision);
};

const speedUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.imperial) {
    return "mph";
  }
  return "km/h";
};

const speedValue = (
  kmhValue: number,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  if (unitSystem === UnitSystem.imperial) {
    return (kmhValue * _kphToMiph).toFixed(decimalPrecision);
  }
  return kmhValue.toFixed(decimalPrecision);
};

const volumeUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.imperial) {
    return "minim";
  }
  return "ml";
};

const volumeValue = (
  mlVolume: number,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  if (unitSystem === UnitSystem.imperial) {
    return (mlVolume * _mlToMinim).toFixed(decimalPrecision);
  }
  return mlVolume.toFixed(decimalPrecision);
};

const volumePerSpaceUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.imperial) {
    return "minim/ac";
  }
  return "ml/ha";
};

const volumePerSpaceValue = (
  mlPerHaValue: number,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  if (unitSystem === UnitSystem.imperial) {
    return (mlPerHaValue * _mlPerHaToMinimPerAc).toFixed(decimalPrecision);
  }
  return mlPerHaValue.toFixed(decimalPrecision);
};

const cupPerSpaceUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.imperial) {
    return "cup/ac";
  }
  return "cup/ha";
};

const cupPerSpaceValue = (
  cupPerHa: number,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  if (unitSystem === UnitSystem.imperial) {
    return (cupPerHa * _cupPerHaToCupPerAc).toFixed(decimalPrecision);
  }
  return cupPerHa.toFixed(decimalPrecision);
};

const volumePerTimeUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.imperial) {
    return "minim/min";
  }
  return "ml/min";
};

const volumePerTimeValue = (
  mlPerMinute: number,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  if (unitSystem === UnitSystem.imperial) {
    return (mlPerMinute * _mlToMinim).toFixed(decimalPrecision);
  }
  return mlPerMinute.toFixed(decimalPrecision);
};

const cupPerTimeUnit = (unitSystem: UnitSystem): string => {
  return "cup/min";
};

const cupPerTimeValue = (
  cupPerMinute: number,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  return cupPerMinute.toFixed(decimalPrecision);
};

const cupUnit = (unitSystem: UnitSystem): string => {
  return "cup";
};

const cupValue = (cup: number, unitSystem: UnitSystem, decimalPrecision: number = 3): string => {
  return cup.toFixed(decimalPrecision);
};

const areaUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.imperial) {
    return "ac";
  }
  return "ha";
};

const areaValue = (
  haValue: number,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  if (unitSystem === UnitSystem.imperial) {
    return (haValue * _haToAc).toFixed(decimalPrecision);
  }
  return haValue.toFixed(decimalPrecision);
};

type ConversionInfo = { incoming: number; outgoing: number };

const speedConversion = (unitSystem: UnitSystem): ConversionInfo => {
  if (unitSystem === UnitSystem.imperial) {
    return {
      incoming: _miphToKph,
      outgoing: _kphToMiph,
    };
  }
  return {
    incoming: 1,
    outgoing: 1,
  };
};

const distanceConversion = (unitSystem: UnitSystem): ConversionInfo => {
  if (unitSystem === UnitSystem.imperial) {
    return {
      incoming: _ftToM,
      outgoing: _mToFt,
    };
  }
  return {
    incoming: 1,
    outgoing: 1,
  };
};

const volumePerSpaceConversion = (unitSystem: UnitSystem): ConversionInfo => {
  if (unitSystem === UnitSystem.imperial) {
    return {
      incoming: _minimPerAcToMlPerHa,
      outgoing: _mlPerHaToMinimPerAc,
    };
  }
  return {
    incoming: 1,
    outgoing: 1,
  };
};

const cupPerSpaceConversion = (unitSystem: UnitSystem): ConversionInfo => {
  if (unitSystem === UnitSystem.imperial) {
    return {
      incoming: _cupPerAcToCupPerHa,
      outgoing: _cupPerHaToCupPerAc,
    };
  }

  return {
    incoming: 1,
    outgoing: 1,
  };
};

const instReleaseRateValue = (
  flightSpeed: number,
  trackWidth: number,
  releaseRate: number,
  releaseRateUnit: ReleaseRateUnit,
  unitSystem: UnitSystem,
  decimalPrecision: number = 3
): string => {
  if (releaseRateUnit === ReleaseRateUnit.cup) {
    /**
     * Instant releasing rate, in cup/min, is the product of speed in km/h, line width in m and
     * releasing rate in cup/ha. The correction factor is calculated by converting some units.
     */
    const instReleaseRate =
      flightSpeed *
      (1000 / 60) /** km/h to m/min speed */ *
      trackWidth /** m track width */ *
      releaseRate *
      (1 / 10000); /** cup/ha to cup / m2 */

    return cupPerTimeValue(instReleaseRate, unitSystem, decimalPrecision);
  } else {
    /**
     * Instant releasing rate, in mL/min, is the product of speed in km/h, line width in m and
     * releasing rate in mL/ha. The correction factor is calculated by converting some units.
     */

    const instReleaseRate =
      flightSpeed *
      (1000 / 60) /** km/h to m/min speed */ *
      trackWidth /** m track width */ *
      releaseRate *
      (1 / 10000); /** ml/ha to ml / m2 */

    return volumePerTimeValue(instReleaseRate, unitSystem, decimalPrecision);
  }
};

const instReleaseRateUnit = (releaseRateUnit: ReleaseRateUnit, unitSystem: UnitSystem): string => {
  if (releaseRateUnit === ReleaseRateUnit.volume) {
    return volumePerTimeUnit(unitSystem);
  } else {
    return cupPerTimeUnit(unitSystem);
  }
};

const releaseRateInterval = (
  releaserModelId: string,
  releaserModels: ReleaserModel[]
): { minimum: number; maximum: number } => {
  const releaserModel = releaserModels.find((model) => model.id === releaserModelId);
  if (releaserModel?.type === ReleaserType.bioBOT) {
    return {
      minimum: 0.1,
      maximum: 100,
    };
  } else if (releaserModel?.type === ReleaserType.bioCOTe) {
    return {
      minimum: 1,
      maximum: 32,
    };
  } else if (releaserModel?.type === ReleaserType.bioMITE) {
    return {
      minimum: 1,
      maximum: 1500,
    };
  }
  return {
    minimum: 0.5,
    maximum: 25,
  };
};

export default {
  distanceUnit,
  distanceValue,
  speedUnit,
  speedValue,
  volumeUnit,
  volumeValue,
  volumePerSpaceUnit,
  volumePerSpaceValue,
  cupPerSpaceUnit,
  cupPerSpaceValue,
  volumePerTimeUnit,
  volumePerTimeValue,
  cupPerTimeUnit,
  cupPerTimeValue,
  areaUnit,
  areaValue,
  cupUnit,
  cupValue,
  speedConversion,
  distanceConversion,
  volumePerSpaceConversion,
  cupPerSpaceConversion,
  instReleaseRateValue,
  instReleaseRateUnit,
  releaseRateInterval,
};

// Conversion factors
const _mToFt = 3.28084;
const _kphToMiph = 0.621371;
const _mlToMinim = 16.2307308969;
const _haToSqft = 107639;
const _haToAc = 2.4710538147;

// Compound conversions
const _mlPerHaToMinimPerAc = _mlToMinim / _haToAc;
const _minimPerAcToMlPerHa = 1 / _mlPerHaToMinimPerAc;
const _cupPerHaToCupPerAc = 1 / _haToAc;
const _cupPerAcToCupPerHa = 1 / _cupPerHaToCupPerAc;
const _miphToKph = 1 / _kphToMiph;
const _ftToM = 1 / _mToFt;
