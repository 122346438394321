import React, { useState } from "react";
import CollapsibleContainer from "../../../../../components/Atomic/CollapsibleOverlayContainer";
import ChangeMapTypesSelector from "./MapTypeButton";
import MapControls from "./MapControls";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../../../store/reducers/systemReducer";
import { changeMapType } from "../../../../../store/actions/projectTreeActions";

type Props = {
  expanded: boolean;
  setExpanded: (value: boolean) => void;
};

export default function MapControlsOverlay(props: Props): JSX.Element {
  const mapTypeId = useSelector((state: SystemState) => state.projectTree.mapState.mapTypeId);

  const dispatch = useDispatch();

  const [openSearch, setOpenSearch] = useState(false);

  return (
    <CollapsibleContainer
      expanded={props.expanded}
      setExpanded={props.setExpanded}
      style={{
        display: "flex !important",
        flex: "1 1 content !important",
        flexDirection: "row",
        minHeight: "0px  !important",
        minWidth: "0px  !important",
        margin: "5px 10px 10px !important",
        pointerEvents: "auto",
      }}
      collapsedComponent={
        <ChangeMapTypesSelector
          onChanged={(mapTypeId) => {
            dispatch(changeMapType(mapTypeId));
          }}
          value={mapTypeId}
        />
      }
      expandedComponent={<MapControls openSearch={openSearch} setOpenSearch={setOpenSearch} />}
    />
  );
}
