import { PersistConfig } from "redux-persist/es/types";
import {
  Price,
  hardwareSolutions,
  mandatoryBioHUBSolution,
  mandatoryBioMAPSSolution,
  mandatorySoftwareSolutions,
} from "../../services/provisoryCartSolutionsService";
import {
  ADD_PROVISORY_CART_ITEM,
  CLEAR_PROVISORY_CART,
  REMOVE_PROVISORY_CART_ITEM,
} from "../actions/provisoryCartActions";
import { SystemAction } from "../actions/systemActions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export type ProvisoryCartSolution = {
  id: string;
  name: string;
  amount: number;
  buyTotalPrice: Price | undefined;
  earlyTotalPrice: Price | undefined;
  monthlyTotalPrice: Price | undefined;
  hectarePerMonthPriceTotalPrice: Price | undefined;
};

export type AvailableSolution = {
  id: string;
  name: string;
  buyPrice: Price | undefined;
  earlyPrice: Price | undefined;
  monthlyPrice: Price | undefined;
  hectarePerMonthPrice: Price | undefined;
};

export type ProvisoryCartState = {
  key: "provisory-cart";
  cartSolutions: ProvisoryCartSolution[];
  availableSolutions: AvailableSolution[];
};

const initialAvailableSolutionsList = [
  {
    id: mandatoryBioHUBSolution.name,
    name: mandatoryBioHUBSolution.name,
    buyPrice: undefined,
    earlyPrice: mandatoryBioHUBSolution.earlyPrice,
    monthlyPrice: mandatoryBioHUBSolution.monthlyPrice,
    hectarePerMonthPrice: undefined,
  },
  {
    id: mandatoryBioMAPSSolution.name,
    name: mandatoryBioMAPSSolution.name,
    buyPrice: undefined,
    earlyPrice: mandatoryBioMAPSSolution.earlyPrice,
    monthlyPrice: mandatoryBioMAPSSolution.monthlyPrice,
    hectarePerMonthPrice: undefined,
  },
  ...hardwareSolutions.map(
    (solution): AvailableSolution => ({
      id: solution.name,
      name: solution.name,
      buyPrice: solution.setupPrice.buy.firstEquipmentPrice,
      earlyPrice: solution.setupPrice.rent.firstEquipmentEarlyPrice,
      monthlyPrice: solution.setupPrice.rent.firstEquipmentMonthlyPrice,
      hectarePerMonthPrice: solution.setupPrice.rent.firstEquipmentMonthlyPrice,
    })
  ),
];

const INITIAL_STATE: ProvisoryCartState = {
  key: "provisory-cart",
  cartSolutions: [],
  availableSolutions: initialAvailableSolutionsList,
};

export function provisoryCartReducer(
  state = INITIAL_STATE,
  action: SystemAction
): ProvisoryCartState {
  let effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  let cartSolutions: ProvisoryCartSolution[] = effectiveState.cartSolutions;
  let availableSolutions: AvailableSolution[] = effectiveState.availableSolutions;

  let findSolutionIndex: number;
  let findAvailableSolution: AvailableSolution | undefined;

  switch (action.type) {
    case ADD_PROVISORY_CART_ITEM:
      findSolutionIndex = cartSolutions.findIndex(
        (solution) => solution.id === action.payload.itemId
      );
      findAvailableSolution = availableSolutions.find(
        (solution) => solution.id === action.payload.itemId
      );

      if (findAvailableSolution === undefined) break;

      if (findSolutionIndex >= 0) {
        cartSolutions[findSolutionIndex].amount =
          cartSolutions[findSolutionIndex].amount + action.payload.amount;
        cartSolutions[findSolutionIndex].buyTotalPrice = sumPrices(
          cartSolutions[findSolutionIndex].buyTotalPrice,
          findAvailableSolution.buyPrice,
          action.payload.amount
        );
        cartSolutions[findSolutionIndex].earlyTotalPrice = sumPrices(
          cartSolutions[findSolutionIndex].earlyTotalPrice,
          findAvailableSolution.earlyPrice,
          action.payload.amount
        );
        cartSolutions[findSolutionIndex].monthlyTotalPrice = sumPrices(
          cartSolutions[findSolutionIndex].monthlyTotalPrice,
          findAvailableSolution.monthlyPrice,
          action.payload.amount
        );
        cartSolutions[findSolutionIndex].hectarePerMonthPriceTotalPrice = sumPrices(
          cartSolutions[findSolutionIndex].hectarePerMonthPriceTotalPrice,
          findAvailableSolution.hectarePerMonthPrice,
          action.payload.amount
        );
      } else {
        findAvailableSolution = availableSolutions.find(
          (solution) => solution.id === action.payload.itemId
        );

        if (findAvailableSolution === undefined) return effectiveState;

        cartSolutions.push({
          id: findAvailableSolution.id,
          name: findAvailableSolution.name,
          amount: action.payload.amount,
          buyTotalPrice: sumPrices(
            undefined,
            findAvailableSolution.buyPrice,
            action.payload.amount
          ),
          earlyTotalPrice: sumPrices(
            undefined,
            findAvailableSolution.earlyPrice,
            action.payload.amount
          ),
          monthlyTotalPrice: sumPrices(
            undefined,
            findAvailableSolution.monthlyPrice,
            action.payload.amount
          ),
          hectarePerMonthPriceTotalPrice: sumPrices(
            undefined,
            findAvailableSolution.hectarePerMonthPrice,
            action.payload.amount
          ),
        });
      }

      break;

    case REMOVE_PROVISORY_CART_ITEM:
      findSolutionIndex = cartSolutions.findIndex(
        (solution) => solution.id === action.payload.itemId
      );
      if (findSolutionIndex < 0) break;

      findAvailableSolution = availableSolutions.find(
        (solution) => solution.id === action.payload.itemId
      );

      if (findAvailableSolution === undefined) break;

      cartSolutions[findSolutionIndex].amount =
        cartSolutions[findSolutionIndex].amount - action.payload.amount;
      cartSolutions[findSolutionIndex].buyTotalPrice = sumPrices(
        cartSolutions[findSolutionIndex].buyTotalPrice,
        findAvailableSolution.buyPrice,
        -action.payload.amount
      );
      cartSolutions[findSolutionIndex].earlyTotalPrice = sumPrices(
        cartSolutions[findSolutionIndex].earlyTotalPrice,
        findAvailableSolution.earlyPrice,
        -action.payload.amount
      );
      cartSolutions[findSolutionIndex].monthlyTotalPrice = sumPrices(
        cartSolutions[findSolutionIndex].monthlyTotalPrice,
        findAvailableSolution.monthlyPrice,
        -action.payload.amount
      );
      cartSolutions[findSolutionIndex].hectarePerMonthPriceTotalPrice = sumPrices(
        cartSolutions[findSolutionIndex].hectarePerMonthPriceTotalPrice,
        findAvailableSolution.hectarePerMonthPrice,
        -action.payload.amount
      );
      if (cartSolutions[findSolutionIndex].amount <= 0) {
        cartSolutions.splice(findSolutionIndex);
      }

      break;

    case CLEAR_PROVISORY_CART:
      cartSolutions = [];
      availableSolutions = initialAvailableSolutionsList;

      break;
    default:
      return state;
  }

  effectiveState = {
    ...effectiveState,
    cartSolutions: cartSolutions,
    availableSolutions: availableSolutions,
  };

  if (cartSolutions.length > 0) {
    if (
      effectiveState.cartSolutions.find((item) => item.name === mandatoryBioHUBSolution.name) ===
      undefined
    ) {
      effectiveState = {
        ...effectiveState,
        cartSolutions: [
          {
            id: mandatoryBioHUBSolution.name,
            name: mandatoryBioHUBSolution.name,
            amount: 1,
            buyTotalPrice: undefined,
            earlyTotalPrice: mandatoryBioHUBSolution.earlyPrice,
            monthlyTotalPrice: mandatoryBioHUBSolution.monthlyPrice,
            hectarePerMonthPriceTotalPrice: undefined,
          },
          ...effectiveState.cartSolutions,
        ],
      };
    }
    if (
      effectiveState.cartSolutions.find((item) => item.name === mandatoryBioMAPSSolution.name) ===
      undefined
    ) {
      effectiveState = {
        ...effectiveState,
        cartSolutions: [
          {
            id: mandatoryBioMAPSSolution.name,
            name: mandatoryBioMAPSSolution.name,
            amount: 1,
            buyTotalPrice: undefined,
            earlyTotalPrice: mandatoryBioMAPSSolution.earlyPrice,
            monthlyTotalPrice: mandatoryBioMAPSSolution.monthlyPrice,
            hectarePerMonthPriceTotalPrice: undefined,
          },
          ...effectiveState.cartSolutions,
        ],
      };
    }
  }

  return effectiveState;
}

export type ProvisoryCartPersistedState = ProvisoryCartState;

const provisoryCartPersistConfig: PersistConfig<ProvisoryCartState> = {
  key: "provisoryCart",
  storage,
  whitelist: ["key", "cartSolutions", "availableSolutions"],
};

export const provisoryCartPersistedReducer = persistReducer(
  provisoryCartPersistConfig,
  provisoryCartReducer
);

function sumPrices(
  originalPrice: Price | undefined,
  sumPrice: Price | undefined,
  multiplierFactor: number
): Price | undefined {
  if (originalPrice === undefined && sumPrice === undefined) return undefined;

  if (sumPrice === undefined) return originalPrice;

  const usingPrice: Price = {
    brazilPrice: originalPrice?.brazilPrice ?? 0,
    europePrice: originalPrice?.europePrice ?? 0,
    otherPrice: originalPrice?.otherPrice ?? 0,
  };

  return {
    brazilPrice: usingPrice.brazilPrice + sumPrice.brazilPrice * multiplierFactor,
    europePrice: usingPrice.europePrice + sumPrice.europePrice * multiplierFactor,
    otherPrice: usingPrice.otherPrice + sumPrice.otherPrice * multiplierFactor,
  };
}
