import React from "react";
import { Tag } from "./styles";

interface Props {
  text?: string;
  color?: "light" | "primary" | "secondary";
  size?: "small" | "medium" | "large";
  textAlign?: "center" | "left" | "right";
  appearance?: "title-semibold" | "subtitle-semibold" | "subtitle-medium";
}

export default (props: Props) => {
  return (
    <>
      <Tag
        color={props.color}
        size={props.size}
        textAlign={props.textAlign}
        appearance={props.appearance}
      >
        {props.text}
      </Tag>
    </>
  );
};
