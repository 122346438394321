import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../assets/logo/icon_blue.svg";
import logoWhite from "../../../assets/logo/icon_white.svg";

type Props = {
  open: boolean;
  active: boolean;
  color?: "dark" | "light";
};

export const MenuBar = styled.nav.attrs((props: Props) => ({
  open: props.open,
}))`
  display: none;
  @media screen and (max-width: 991px) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 5;
    margin: 10px 20px;

    &:focus {
      outline: none;
    }

    span {
      width: 2rem;
      height: 0.25rem;
      background: ${({ open }) => (open ? "var(--highlighted)" : "var(--white)")};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-child(2) {
        opacity: ${({ open }) => (open ? "0" : "1")};
        transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
      }

      :nth-child(3) {
        transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      }
    }
  }
`;

export const Header = styled.header.attrs((props: Props) => ({
  open: props.open,
  color: props.color,
}))`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  position: relative;

  .MuiGrid-container {
    height: auto !important;
  }

  li {
    padding: 15px 5px;
    position: relative;
  }

  li a {
    color: var(--white);
  }

  ${(props) =>
    props.color === "dark" &&
    `

    align-items: flex-start;
    min-height: 100px; 

    li { 
      border-bottom: 1px solid var(--text-primary)!important; 
      &:hover:after {
        background: var(--secondary);
      }
      a { 
        color: var(--text-primary); 
      }
    }

    .actions {
      a:first-of-type {
        color: var(--text-primary);
        border-color: var(--primary);
      }
      a:last-of-type {
        color: var(--white);
        background: var(--secondary);
        border-color: var(--secondary);
        :hover, :focus {
          background: var(--primary);
        }
      }
    }

    nav span {
      background: var(--primary);
    }


  `}

  ${(props) =>
    props.color === "light" &&
    `
    .logo {
      filter: brightness(0) contrast(1) invert(1);
    }
  `}

  @media screen and (max-width: 680px) {
    margin: 10px -15px;
  }

  @media screen and (max-width: 991px) {
    ul {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    li {
      font-size: 13px;
    }
  }
`;

export const Bar = styled.span.attrs((props: Props) => ({}))``;

export const Mask = styled.span.attrs((props: Props) => ({
  open: props.open,
}))`
  ${(props) =>
    props.open === true &&
    `
      :before {
        content: "";
        width: 100vw;
        display: block;
        height: 100vh;
        background: var(--overlay-85);
        left: 0;
        top: 0;
        position: fixed;
        z-index: 1;
      }

      > ul {
        display: block!important;
        margin: 0;
        width: 280px;
        position: fixed;
        top: 0;
        left: 0;
        background: var(--background-default);
        color: var(--primary);
        height: 100vh;
        z-index: 2;
        padding-top: 80px;
        transform: translateX(0);
        opacity: 1;

        li {
          margin: 0 15px;
          text-align: left;
          border-bottom-color: var(--primary);
          a {
            color: var(--primary);
          }

        }
  
        :after {
          content: "";
          background-image: url(${logoWhite});
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: 20px;
          left: 16px;
          width: 50px;
          height: 50px;
          display: block;
        }

      }

      @media screen and (max-width: 991px) {
        nav {
          position: fixed;
          top: -5px;
          right: 40px;
          span { 
            background: var(--white)!important; 
            filter: drop-shadow(1px 1px 0 black);
          }
        }
        > ul { 
          background: var(--primary);
          overflow-y: auto;

          * {
            color: var(--white)!important;
            border-bottom-color: var(--white)!important;
          }

          .submenu ul {
            display: none;
          }

          li {
            height: auto!important;
            img {
              margin-bottom: 0!important; 
            }
          }

          .submenu:active ul,
          .submenu:hover ul {
            display: table;
            position: relative;
            top: initial;
            left: initial;
            width: 100%;
            transform: none;  
            opacity: 1;
            height: auto;
            margin-top: 10px;
            padding: 0;
            background: var(--box-shadow);

            ul {
              background: transparent;
              margin: 15px auto;
              padding: 15px; 
              li {
                text-align: center;
                height: auto !important;
                a {
                  height: auto !important;
                }
                img {
                  max-height: 30px !important;
                  max-width: 30px !important;
                  margin: 0 auto !important;
                }
              }
            }
            
            .MuiContainer-maxWidthLg {
              display: block;
              padding: 30px 0 0;
              li a {
                padding: 0;
                height: 100px;
              }
              img {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      @media screen and (max-width: 320px) {
        nav {
          position: fixed;
          top: -8px;
          right: -18px;
        }
      }
  `}
`;

export const Actions = styled.span.attrs((props: Props) => ({
  className: "actions",
}))`
  display: flex;
  margin-left: 50px;

  a {
    min-width: unset !important;
    width: 100%;
    position: relative;

    &:nth-child(2) {
      min-width: 140px !important;
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 991px) {
    float: right;

    a {
      min-width: 150px !important;
    }
  }

  @media screen and (max-width: 680px) {
    float: unset;
    margin: 0;
    margin-left: 15px;
    a {
      min-width: 80px !important;

      &:last-child {
        margin-left: 0;
      }
    }
    button {
      font-size: 12px;
      margin-right: 0 !important;
      margin-left: 5px !important;
    }
  }

  @media screen and (max-width: 480px) {
    margin-right: -30px;
    float: unset;
    a {
      min-width: 60px !important;

      &:nth-child(2) {
        min-width: 80px !important;
      }
    }
    button {
      font-size: 11px;
      padding: 2px 10px;
    }
  }
`;

export const List = styled.ul.attrs((props: Props) => ({
  className: "menu-top",
}))`
  width: 100%;
  list-style: none;
  font-family: "GalanoGrotesque-Medium";
  display: inline-flex;

  > li {
    border-bottom: 1px solid var(--white);
  }
`;

export const Item = styled.li.attrs((props: Props) => ({
  color: props.color,
  active: props.active,
}))`
  flex: 1;
  text-align: center;
  padding: 15px 5px;
  position: relative;
  cursor: pointer;

  :after {
    content: "";
    display: block;
    width: 20px;
    height: 6px;
    border-radius: 10px;
    background: var(--transparent);
    position: absolute;
    bottom: -3px;
    left: calc(50% - 10px);
  }

  &:hover,
  &:focus {
    :after {
      background: var(--secondary);
    }
  }

  &.submenu {
    &:hover,
    &:focus {
      a {
        color: var(--white);
      }
      :after {
        background: var(--secondary);
      }
      ul {
        top: 0 !important;
      }
    }
  }

  ${(props) =>
    props.color === "light" &&
    `
    &:hover, &:focus {
      :after {
        background: var(--secondary);
      }
    }
    
    a { 
      color: var(--white)!important; 
      :focus, :hover { 
        text-decoration: none; 
        color: var(--secondary);
      } 
    }
    
  `}

  ${(props) =>
    props.active &&
    `
      :after {
        background: var(--white);
        @media screen and (max-width: 991px) {
          background-color: var(--primary);
          bottom: -3px;
          left: 5%;
        }
      }
  `}
`;

export const LinkMenu = styled(Link).attrs((props: Props) => ({}))`
  color: initial;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    color: var(--secondary);
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
`;

export const Submenu = styled.ul.attrs((props: Props) => ({}))`
  position: fixed;
  background: var(--primary);
  width: 100vw;
  top: -475px;
  left: 0;
  height: 440px;
  color: var(--white);
  padding-top: 75px;
  z-index: -1;
  display: flex;
  align-items: center;
  transition: 0.65s all ease;

  .MuiContainer-maxWidthLg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  li {
    height: 100%;
    top: 0;
    list-style: none;
    display: contents;
    align-items: center;
  }

  a {
    padding: 40px;
    text-align: left;
    height: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;

    :hover {
      background: rgba(255 255 255 / 25%);
    }

    img {
      max-width: 200px;
      display: block;
      object-fit: contain;
    }
  }

  &.subtype {
  .MuiContainer-maxWidthLg {
    padding: 0 5rem;
    a {
      color: var(--white);
    }
    li:first-of-type {
      display: inline-flex;
      height: calc(100% + 30px);
      a {
        font-size: 14px;
        img {
          max-height: 60px;
          object-fit: cover;
          margin: 20px 0;
        }
      }
    }
  }


`;

export const SubItens = styled.ul.attrs((props: Props) => ({}))`
  background: transparent;
  width: 60%;
  height: 100%;
  color: var(--white);
  flex-flow: row wrap;
  transition: 0.65s all ease;

  li,
  li:first-of-type {
    float: left;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 33%;
    height: 50% !important;
    top: 0;
  }

  a {
    padding: 15px;

    img {
      display: block;
      max-width: 60px !important;
      max-height: 60px;
      object-fit: contain !important;
      margin: 1rem auto !important;
      text-align: center;
    }

    :hover {
      background: rgba(255 255 255 / 25%);
    }
  }

  @media screen and (max-width: 991px) {
  }
`;

export const NotifyCart = styled.div.attrs(() => ({}))`
  top: -12px;
  right: 3px;
  position: absolute;

  img {
    width: 14px;
  }
`;
