import React, { useRef, useState } from "react";
import { Button, Grid, withStyles, Tooltip } from "@material-ui/core";

import { useIntl } from "react-intl";
import { Fields, Attachment } from "../styles";
import _ from "lodash";
import Checkbox from "../../../../components/Atomic/Inputs/Checkbox";
import Icon from "../../../../components/Atomic/BasicComponents/Icon";

type AreaSourceStageProps = {
  onlyKml: boolean;
  onFinishWithFile: (file: File | null, polygonOptimization: boolean) => void;
  onFinishWithoutFile: (polygonOptimization: boolean) => void;
};

export default function (props: AreaSourceStageProps): JSX.Element {
  const inputFileKml = useRef<HTMLInputElement>(null);
  const inputFileShp = useRef<HTMLInputElement>(null);
  const [fileToImport, setFileToImport] = useState<File | null>(null);
  const [polygonOptimizationControl, setPolygonOptimizationControl] = useState<boolean>(false);

  const intl = useIntl();

  const _newProjectOptionalKmlImportLocalized: string = intl.formatMessage({
    id: "map.newProject.optionalImportKml",
  });
  const _newProjectOptionalShpImportLocalized: string = intl.formatMessage({
    id: "map.newProject.optionalImportShapefile",
  });
  const _newProjectSkipImportingFilesLocalized: string = intl.formatMessage({
    id: "map.newProject.skipImportingFiles",
  });
  const _optimizePolygonLocalized: string = intl.formatMessage({
    id: "map.overlays.weather.optimizePolygon",
  });
  const _optimizePolygonTooltipLocalized: string = intl.formatMessage({
    id: "map.overlays.weather.optimizePolygonTooltip",
  });

  return (
    <Fields>
      <Grid>
        <Grid item xs={12} style={{ marginTop: -10 }}>
          <Attachment>
            <input
              accept=".kml"
              style={{ display: "none" }}
              id="raised-button-kml"
              ref={inputFileKml}
              type="file"
              onChange={(e) => {
                const file = e.target.files ? e.target.files[0] : null;
                props.onFinishWithFile(file, polygonOptimizationControl);
              }}
            />
            <label htmlFor="raised-button-kml">
              <Stage2Button
                variant="outlined"
                size="small"
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                  e.preventDefault();
                  inputFileKml.current?.click();
                }}
              >
                {fileToImport !== null ? fileToImport.name : _newProjectOptionalKmlImportLocalized}
              </Stage2Button>
            </label>
          </Attachment>
        </Grid>
        {props.onlyKml ? null : (
          <>
            <Grid item xs={12}>
              <Attachment>
                <input
                  accept=".zip"
                  style={{ display: "none" }}
                  id="raised-button-shp"
                  ref={inputFileShp}
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files ? e.target.files[0] : null;
                    props.onFinishWithFile(file, polygonOptimizationControl);
                  }}
                />
                <label htmlFor="raised-button-shp">
                  <Stage2Button
                    variant="outlined"
                    size="small"
                    //component="span"
                    onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                      e.preventDefault();
                      inputFileShp.current?.click();
                    }}
                  >
                    {fileToImport !== null
                      ? fileToImport.name
                      : _newProjectOptionalShpImportLocalized}
                  </Stage2Button>
                </label>
              </Attachment>
            </Grid>
            <Grid item xs={12}>
              {/** Using this tag for the style. */}
              <Attachment>
                <Stage2Button
                  variant="outlined"
                  onClick={(_) => {
                    props.onFinishWithoutFile(polygonOptimizationControl);
                  }}
                >
                  {_newProjectSkipImportingFilesLocalized}
                </Stage2Button>
              </Attachment>
            </Grid>
          </>
        )}
        <Grid item container>
          <Grid item style={{ marginTop: 29 }}>
            <Checkbox
              label={_optimizePolygonLocalized}
              secondary
              onClick={(clicked) => {
                setPolygonOptimizationControl(!clicked);
              }}
              initialState={polygonOptimizationControl}
            />
          </Grid>
          <Grid
            item
            style={{ width: 7, height: 7, marginTop: 22, marginLeft: -10, marginRight: 6 }}
          >
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={_optimizePolygonTooltipLocalized}
              placement="bottom"
            >
              <div>
                <Icon type={"help"} color={"var(--black)"} />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Fields>
  );
}

const Stage2Button = withStyles({
  root: {
    width: "100%",
    borderRadius: 100,
    color: "var(--white)",
    fontSize: 15,
    backgroundColor: "var(--dashboard-secondary)",
    "&:hover": {
      backgroundColor: "var(--icon-focus)",
    },
  },
})(Button);
