import React from "react";
import Text from "../Text";
import Image from "../Image";

import CustomButtonGroup from "../../../v2/Carousel/CustomButtonGroup";

import { Carousel, Item, Description } from "./styles";

interface Props {
  items: Array<{
    id: string;
    title: string;
    description: string;
    image: string | "url";
  }>;
  color?: "primary";
  isCustomButtonGroup?: boolean;
}

export default (props: Props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {props.isCustomButtonGroup ? (
        <Carousel
          responsive={responsive}
          color={props.color}
          customButtonGroup={<CustomButtonGroup />}
          showDots
          arrows={false}
          renderButtonGroupOutside={true}
        >
          {props.items.map((item: any) => (
            <Item key={item.id}>
              <Image src={item.image} alt={item.title} />
              <Description>
                <Text
                  text={item.title}
                  color={"primary"}
                  appearance={"title-semibold"}
                  size={"small"}
                />
                <Text text={item.description} color={"dark"} appearance={"description-block"} />
              </Description>
            </Item>
          ))}
        </Carousel>
      ) : (
        <Carousel responsive={responsive} color={props.color}>
          {props.items.map((item: any) => (
            <Item key={item.id}>
              <Image src={item.image} alt={item.title} />
              <Description>
                <Text
                  text={item.title}
                  color={"primary"}
                  appearance={"title-semibold"}
                  size={"small"}
                />
                <Text text={item.description} color={"dark"} appearance={"description-block"} />
              </Description>
            </Item>
          ))}
        </Carousel>
      )}
    </>
  );
};
