import { Location } from "biohub-model";

const getCurrentUserLocation = async (): Promise<Location | null> => {
  try {
    const userLocation = await new Promise<Location>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            const coord = convertGeolocationPositionToLocation(position);
            resolve(coord);
          },
          (e) => reject(e)
        );
      } else {
        reject();
      }
    });

    return userLocation;
  } catch (e) {
    console.log(`Error getting user position ${e}`);
    return null;
  }
};

const convertGeolocationPositionToLocation = (position: GeolocationPosition): Location => {
  return { lat: position.coords.latitude, lng: position.coords.longitude };
};

const userPositionWatcher = (onUserPositionChanged: (userPosition: Location) => void): void => {
  navigator.geolocation.watchPosition((position) =>
    onUserPositionChanged(convertGeolocationPositionToLocation(position))
  );
};

export default {
  getCurrentUserLocation,
  userPositionWatcher,
};
