import { Area, Project, Flight, PlannedArea, Waypoint, Location } from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  biohubUnit,
  BiohubUnit,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";
import { v4 as uuid } from "uuid";
import _, { rest } from "lodash";
import haversine from "haversine";

export type ProjectWithAreaCount = Project & { areaCount?: number };

// Function to remove null fields in optional places an object
// to remove the AJV error sending it to the server
function removeFalsy(obj: any) {
  Object.keys(obj).forEach((k) => {
    obj[k] == undefined && delete obj[k];
    if (typeof obj[k] === "object") {
      removeFalsy(obj[k]);
    }
  });
}

async function addProject(project: Project): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.post("/projects", {
      project: project,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function deleteProject(projectId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.delete(`/projects/${projectId}`);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function addArea(area: Area): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.post("/areas", {
      area: area,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function deleteArea(areaId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.delete(`/areas/${areaId}`);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/**
 * Get a list of projects that the currently logged user can access.
 * Of course, the user must be currently logged in.
 */

async function listProjects(): Promise<BiohubResponse<ProjectWithAreaCount[]>> {
  try {
    const result = await BiohubApi.get("/projects");
    const projects = result.data.projects as ProjectWithAreaCount[];
    return newBiohubSuccess(projects.filter((p) => !p.deletedAt));
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

// Maybe it will have to use the "ProjectWithAreaCount" type to match the listProjects service
async function readProject(projectId: string): Promise<BiohubResponse<Project>> {
  try {
    const result = await BiohubApi.get(`/projects/${projectId}`);
    const project = result.data.project as ProjectWithAreaCount;
    return newBiohubSuccess(project);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/**
 * Get a list of areas for a project. The currently logged in user must have sufficient permissions
 * for the specified project.
 */
async function listAreas(projectId: string): Promise<BiohubResponse<Area[]>> {
  try {
    const result = await BiohubApi.get(`/areas/${projectId}`);
    const areas = result.data.areas as Area[];
    for (let i = 0; i < areas.length; i++) {
      removeFalsy(areas[i].configuredReleasers);
      // There was a change at some point that made home points optional, but before
      // that, the backend would return {lat: null, lng: null} instead of undefined.
      if (areas[i].planned.route === null) {
        areas[i].planned.route = undefined;
      }
    }
    return newBiohubSuccess(areas.filter((a) => !a.deletedAt));
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/**
 * Change details for a project. All fields are optional, except the id.
 */
async function updateProject(
  project: Partial<Project> & { id: string }
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.put(`projects/${project.id}`, {
      project,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/**
 * Change details for an area. All fields are optional, except the id.
 */
async function updateArea(
  area: Partial<Area> & { id: string }
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    console.log(JSON.stringify(area, null, 2))
    const result = await BiohubApi.put(`areas/${area.id}`, {
      area,
    });
    //console.log(result);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function listFlightsForArea(areaId: string): Promise<BiohubResponse<Flight[]>> {
  // const mockFlights: Flight[] = [
  //   {
  //     id: "flight-1",
  //     startedAt: new Date(),
  //     finishedAt: new Date(),
  //   } as any as Flight,
  //   {
  //     id: "flight-2",
  //     startedAt: new Date(),
  //     finishedAt: new Date(),
  //   } as any as Flight,
  // ];
  // return newBiohubSuccess(mockFlights);

  try {
    const flightsResult = await BiohubApi.get(`/flights?areaId=${areaId}`, {
      // params: {
      //   areaId,
      // },
    });
    return newBiohubSuccess(flightsResult.data.flights as Array<Flight>);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/**
 * Function to check if we have an update in the waypoint list
 * @param oldRoute Previous area watpoint list
 * @param newRoute new area waypoint list
 * @returns boolean with true if there is an update
 */
export function checkRouteUpdate(oldRoute: Waypoint[] | undefined, newRoute: Waypoint[]): boolean {
  if (oldRoute === undefined) return true;

  if (oldRoute.length !== newRoute.length) return true;

  const removeWaypointElevationData = (waypoints: Waypoint[]) =>
    waypoints.map((waypoint) => {
      const { elevation, ...rest } = waypoint;

      return rest;
    });

  if (!_.isEqual(removeWaypointElevationData(oldRoute), removeWaypointElevationData(newRoute)))
    return true;

  return false;
}

/**
 * Function to check if we have an update in the home point
 * @param oldHomePoint Previous area home point
 * @param newHomePoint New area home point
 * @returns boolean with true if there is an update
 */
export function checkHomePointUpdate(
  oldHomePoint: Location | undefined,
  newHomePoint: Location | undefined
): boolean {
  if (oldHomePoint === undefined) {
    if (newHomePoint === undefined) return false;
    return true;
  }
  if (newHomePoint === undefined) return true;

  if (oldHomePoint.lat !== newHomePoint.lat || oldHomePoint.lng !== newHomePoint.lng) return true;

  return false;
}

/**
 * Function to check if we have an update in the area polygon
 * @returns boolean with true if there is an update
 */
export function checkUpdatePolygon(oldPolygon: Location[], newPolygon: Location[]): boolean {
  if (oldPolygon.length !== newPolygon.length) return true;

  for (let i = 0; i < oldPolygon.length; i++) {
    if (oldPolygon[i].lat !== newPolygon[i].lat || oldPolygon[i].lng !== newPolygon[i].lng)
      return true;
  }
  return false;
}

export function plannedRouteControlUpdate(
  oldPlan: PlannedArea,
  updateParam:
    | { waypoints: Waypoint[]; homePoint: Location | undefined; polygon?: undefined }
    | { polygon: Location[] }
): PlannedArea {
  let plannedArea = _.cloneDeep(oldPlan);

  if (updateParam.polygon === undefined) {
    const { waypoints, homePoint } = updateParam;
    const updatedRoute: boolean = checkRouteUpdate(oldPlan.route?.waypoints, waypoints);
    if (updatedRoute) {
      plannedArea = {
        ...plannedArea,
        id: uuid(),
        generatedAt: new Date(),
        homePoint: homePoint,
        route: {
          id: uuid(),
          generatedAt: new Date(),
          waypoints: waypoints,
        },
      };
    }
    const updatedHomePoint = checkHomePointUpdate(plannedArea.homePoint, homePoint);
    if (updatedHomePoint) {
      plannedArea = {
        ...plannedArea,
        id: uuid(),
        generatedAt: new Date(),
        homePoint: homePoint,
      };
    }
  } else {
    if (checkUpdatePolygon(plannedArea.polygon, updateParam.polygon)) {
      plannedArea = {
        ...plannedArea,
        id: uuid(),
        generatedAt: new Date(),
        polygon: updateParam.polygon,
      };
    }
  }

  return plannedArea;
}

function getDistance(waypoint1: Waypoint, waypoint2: Waypoint): number {
  return haversine(waypoint1.location, waypoint2.location, {
    unit: "meter",
    format: "{lat,lng}",
  });
}

export function minMaxWaypointCurveRadius(
  waypointIndex: number,
  plannedRoute: Array<Waypoint> | undefined
): { minValue: number; maxValue: number } {
  if (plannedRoute === undefined || waypointIndex >= plannedRoute.length)
    return { minValue: 0.0, maxValue: 0.0 };
  if (waypointIndex === 0 || waypointIndex === plannedRoute.length - 1)
    return {
      minValue: 0.2,
      maxValue: 0.2,
    };
  const waypoint = plannedRoute[waypointIndex];
  const previousWaypoint = plannedRoute[waypointIndex - 1];
  const nextWaypoint = plannedRoute[waypointIndex + 1];
  const previousDistance = getDistance(waypoint, previousWaypoint);
  const nextDistance = getDistance(waypoint, nextWaypoint);

  const previousWaypointMaxCurveRadius = previousDistance - previousWaypoint.curveRadius;
  const nextWaypointMaxCurveRadius = nextDistance - nextWaypoint.curveRadius;
  if (previousWaypointMaxCurveRadius < nextWaypointMaxCurveRadius) {
    return {
      minValue: 0.2,
      maxValue: previousWaypointMaxCurveRadius,
    };
  }
  return {
    minValue: 0.2,
    maxValue: nextWaypointMaxCurveRadius,
  };
}

function getWaypointsDistances(waypoints: Waypoint[]): number[] {
  return waypoints.map((waypoint, waypointIndex) => {
    if (waypointIndex < waypoints.length - 1) {
      return getDistance(waypoint, waypoints[waypointIndex + 1]);
    }
    return 0.0;
  });
}

export function calculateCurveOfAllWaypoints(
  waypoints: Waypoint[],
  curvePercentage: number
): Waypoint[] {
  const distances = getWaypointsDistances(waypoints);

  return waypoints.map((waypoint, waypointIndex) => {
    let waypointCurve;
    if (waypointIndex === 0 || waypointIndex === waypoints.length - 1) {
      waypointCurve = 0.2;
    } else {
      const previousDistance = distances[waypointIndex - 1];
      const nextDistance = distances[waypointIndex];

      const minSegmentDistance = previousDistance < nextDistance ? previousDistance : nextDistance;

      waypointCurve = (minSegmentDistance * curvePercentage) / 200;
    }
    return {
      ...waypoint,
      curveRadius: waypointCurve,
    };
  });
}

export default {
  addProject,
  deleteProject,
  addArea,
  deleteArea,
  listProjects,
  readProject,
  listAreas,
  updateProject,
  updateArea,
  listFlightsForArea,
};
