import { ReleaserAction, Waypoint, Location, UnitSystem } from "biohub-model";
import {
  bearingBetween,
  calculateAreaHa,
  distanceBetween,
  totalPathLength,
} from "./geometricFunctions";
import offsetPolygon from "offset-polygon";

export const activeWaypoints = (waypoints: Waypoint[]): boolean[] => {
  // TODO: Check the implementation using multiple releasers
  let activeLiberations: boolean[] = [];
  waypoints?.forEach((waypoint, waypointIndex) => {
    let waypointHasAction: boolean = false;
    let waypointAction: boolean = false;
    for (let [_key, value] of Object.entries(waypoint.releaserActions)) {
      waypointHasAction = true;
      if (value.type === ReleaserAction.release) {
        waypointAction = true;
      }
    }

    if (waypointHasAction) {
      activeLiberations.push(waypointAction);
    } else {
      const previousAction = waypointIndex > 0 ? activeLiberations[waypointIndex - 1] : false;

      activeLiberations.push(previousAction);
    }
  });

  return activeLiberations;
};

export const plannedCoverageArea = (waypoints: Waypoint[], trackWidth: number): number => {
  const activeLiberations = activeWaypoints(waypoints);

  let linearDistance = 0.0;

  for (let i = 0; i < activeLiberations.length; i++) {
    const actualActive = activeLiberations[i];

    if (actualActive && i < activeLiberations.length - 1) {
      linearDistance =
        linearDistance + distanceBetween(waypoints[i].location, waypoints[i + 1].location);
    }
  }

  return linearDistance * trackWidth;
};

export const polygonAvailableAreaForPlanning = (
  polygon: Location[],
  areaPadding: number,
  trackWidth: number
): Location[] => {
  const polygonPadding = areaPadding - trackWidth / 2;

  const dimensionedPolygonPadding = polygonPadding * metersApproximaterForGeographicSpace;

  return offsetPolygon(
    _trimmedPolygonAndAsClockwise(polygon.map((vertex) => ({ x: vertex.lng, y: vertex.lat }))),
    dimensionedPolygonPadding
  ).map((value) => ({
    lat: value.y,
    lng: value.x,
  }));
};

const _trimmedPolygonAndAsClockwise = (
  vertices: { x: number; y: number }[]
): { x: number; y: number }[] => {
  const isClockWise = (): boolean => {
    // We use the winding of the polygon to discover its orientation.
    // https://www.element84.com/blog/determining-the-winding-of-a-polygon-given-as-a-set-of-ordered-points
    // I am unsure of what the relation is between this formula and the
    // shoelace formulat for calculating the area of a polygon.
    let winding = 0;
    for (let i = 0; i < vertices.length; i++) {
      const nextIndex = circularIndex(i + 1, vertices.length);

      const point = vertices[i];
      const nextPoint = vertices[nextIndex];

      winding += (nextPoint.x - point.x) * (nextPoint.y + point.y);
    }
    return winding > 0;
  };

  let result;
  if (!isClockWise()) {
    result = vertices.reverse();
  } else {
    result = vertices;
  }
  if (result.length > 1) {
    if (
      result[0].x === result[result.length - 1].x &&
      result[0].y === result[result.length - 1].y
    ) {
      result.pop();
    }
  }

  return result;
};

const circularIndex = (listIndex: number, listSize: number): number => {
  let index = listIndex;

  while (index < 0) {
    index = index + listSize;
  }

  return index % listSize;
};

export const plannedPathTime = (
  route: Waypoint[],
  speedKmH: number
): {
  hours: number;
  minutes: number;
  seconds: number;
} => {
  const routeDistance = totalPathLength(
    route.map((waypoint) => waypoint.location),
    UnitSystem.metric
  );
  const routeKmDistance = routeDistance / 1000;

  const flightTime = routeKmDistance / speedKmH;

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  hours = parseInt(flightTime.toFixed(0));

  const flightTimeExtractedHours = (flightTime - hours) * 60;

  minutes = parseInt(flightTimeExtractedHours.toFixed(0));

  const flightTimeExtractedMinutes = (flightTimeExtractedHours - minutes) * 60;

  seconds = parseInt(flightTimeExtractedMinutes.toFixed(0));

  return {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

const earthRadius = 6371007.2;

const metersApproximaterForGeographicSpace = 1 / ((2 * Math.PI * earthRadius) / 360);
