import { BoundingBox } from "biohub-model";
import React from "react";
import { useSelector } from "react-redux";
import { couldMapElementBeVisibleAccordingMapBounds } from "../../../core/geometricFunctions";
import { SystemState } from "../../../store/reducers/systemReducer";

type Props = {
  elementBoundingBox: BoundingBox;
  children: React.ReactNode;
};

export default function (props: Props): JSX.Element {
  const isInsideBoundingBox = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const mapBounds = projectTreeState.mapState.bounds;

    return couldMapElementBeVisibleAccordingMapBounds(props.elementBoundingBox, mapBounds);
  });

  if (!isInsideBoundingBox) return <></>;

  return <>{props.children}</>;
}
