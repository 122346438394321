import React from "react";
import { Link } from "./styles";

interface Props {
  children: React.ReactNode;
  className?: string;
  to?: string;
  target?: string;
  appearance?: "button-outline" | "outline-invert" | "terms" | "simple";
  color?: "light";
  size?: "small" | "medium" | "large";
  onClick?: () => void;
}

export default (props: Props) => {
  return (
    <>
      <Link
        href={props.to}
        target={props.target}
        appearance={props.appearance}
        color={props.color}
        size={props.size}
        onClick={props.onClick}
        className={props.className}
      >
        {props.children}
      </Link>
    </>
  );
};
