import React from "react";
import { useHistory } from "react-router";
import Icon from "../Icon";
import { List, Item, Label } from "./styles";
interface Props {
  items: Array<{
    key?: string;
    icon?: string | React.ReactNode;
    active?: boolean;
    active_icon?: string | React.ReactNode;
    link?: any | "url";
    action?: () => void;
    text: string;
    submenu?: Array<{
      key?: string;
      icon?: string;
      link?: any | "url";
      text: string;
      submenu?: Array<{
        key?: string;
        icon?: string;
        link?: any | "url";
        text: string;
      }>;
    }>;
  }>;
  orientation?: "horizontal" | "vertical";
  color?: "light";
  style?: React.CSSProperties;
}

export default (props: Props) => {
  const history = useHistory();
  //TODO: this can be improved
  const [open, setOpen] = React.useState(false);
  const [subOpen, setSubOpen] = React.useState(false);

  return (
    <>
      <List orientation={props.orientation} style={props.style}>
        {/* creates list of options with values ​​received in the props */}
        {props.items.map((item) => (
          <Item>
            {/** Navigation must be done through 'history' always. */}
            <Label
              href="javascript:void(0);"
              color={props.color}
              key={item.key}
              onClick={() => {
                // If there's an action, call it.
                if (item.action) {
                  item.action();
                }
                // If there's a link, follow it.
                if (item.link) {
                  history.push(item.link);
                }
              }}
            >
              {item.icon && typeof item.icon === "string" && <Icon type={item.icon} />}
              {item.icon && typeof item.icon !== "string" && item.icon}
              {item.text}
            </Label>
            {item.submenu && (
              <Label
                onClick={() => {
                  setOpen(!open);
                }}
                className="has-submenu"
              >
                <Icon type={open ? "expand_more" : "expand_less"} />
              </Label>
            )}
            {/** Right now we're not using the submenu. This part of the code
             * is likely outdated, but can be used again if we need submenus.
             */}
            {/* create first level submenu */}
            {item.submenu && (
              <List orientation={"vertical"} open={open}>
                {item.submenu.map((subitem: any) => (
                  <Item>
                    {/** Navigation must be done through 'history' always. */}
                    <Label
                      href="javascript:void(0);"
                      color={props.color}
                      key={subitem.key}
                      onClick={() => [setOpen(true), history.push(subitem.link)]}
                    >
                      {subitem.icon && <Icon type={subitem.icon} />}
                      {subitem.text}
                      {subitem.submenu && (
                        <Label
                          onClick={() => {
                            setSubOpen(!subOpen);
                          }}
                          className="has-submenu"
                        >
                          <Icon type={subOpen ? "expand_more" : "expand_less"} />
                        </Label>
                      )}
                    </Label>
                    {subitem.submenu && (
                      <List orientation={"vertical"} open={subOpen}>
                        {subitem.submenu.map((child: any) => (
                          <Item>
                            {/** Navigation must be done through 'history' always. */}
                            <Label
                              href="javascript:void(0);"
                              color={props.color}
                              key={child.key}
                              onClick={() => {
                                setOpen(true);
                                history.push(child.link);
                              }}
                            >
                              {child.icon && <Icon type={child.icon} />}
                              {child.text}
                            </Label>
                          </Item>
                        ))}
                      </List>
                    )}
                  </Item>
                ))}
              </List>
            )}
          </Item>
        ))}
      </List>
    </>
  );
};
