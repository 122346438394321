import { Flight, FlightLogData } from "biohub-model";
import React from "react";
import { CustomTreeItem, ListItemAction } from "./CustomTreeItem";
import { format } from "date-fns";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import VisibilityOnIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import DescriptionIcon from "@material-ui/icons/Description";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useIntl } from "react-intl";

type Props = {
  projectId: string;
  areaId: string;
  flight: Flight;
  viewingAreaPlanningId: string | null;
  visible: boolean;
  isExportingAsKml: boolean;
  onClick: () => void;
  onVisibilityChanged: () => void;
  onExportFlightAsKml: () => void;
};

export default function (props: Props): JSX.Element {
  const intl = useIntl();
  const flight = props.flight;
  const flightId = flight.id;
  const id = `${props.projectId}-${props.areaId}-${flightId}`;

  const logData = flight.realData?.logData;

  const realAreaFlown = logData?.[0]?.totalAreaOverflown;
  const logDataIsEmpty: boolean = !logData || logData.length === 0;
  const logDataIsInvalid: boolean = !_verifyFlightLogData(logData ?? []);
  const mustDisableReportGeneration: boolean = logDataIsEmpty || logDataIsInvalid;

  const sub_1 = `${realAreaFlown?.toFixed(1) ?? " - "} ha`;
  const sub_2: string = removeArrayDuplicates((logData ?? []).map((data) => data.input)).join(
    " / "
  );

  let flightsTreeItemActions: ListItemAction[] | undefined;

  if (!mustDisableReportGeneration) {
    flightsTreeItemActions = [
      {
        text: intl.formatMessage({ id: "map.openFlightReport" }),
        action: () => {
          window.open(
            `/reports/flight?flightId=${flight.id}&areaId=${props.areaId}&projectId=${props.projectId}`
          );
        },
      },
      {
        text: intl.formatMessage({ id: "map.kml.exportFlight" }),
        action: () => {
          props.onExportFlightAsKml();
        },
      },
    ];
  }

  return (
    <CustomTreeItem
      id={id}
      key={id}
      highlighted={flight.flightEnvironmentSnapshot.plannedArea.id === props.viewingAreaPlanningId}
      content={format(flight.startedAt, "dd/MM/yyyy hh:mm aaa")}
      subtitle={`${sub_1} / ${sub_2}`}
      actions={flightsTreeItemActions}
      trailing={
        <div
          style={{
            marginLeft: 5,
            marginRight: 0,
          }}
        >
          {!mustDisableReportGeneration ? (
            <Tooltip title={intl.formatMessage({ id: "map.toggleVisibility" })}>
              <IconButton
                style={{
                  padding: "0px",
                  paddingRight: 10,
                  color: "var(--black)",
                }}
                onClick={() => {
                  // If it's hidden pass true (meaning it should become visible).
                  props.onVisibilityChanged();
                }}
              >
                {props.visible ? (
                  <VisibilityOnIcon style={{ width: "20px", height: "20px", marginLeft: 10 }} />
                ) : (
                  <VisibilityOffIcon style={{ width: "20px", height: "20px", marginLeft: 10 }} />
                )}
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={intl.formatMessage({ id: "map.toggleVisibility.disabled" })}>
              <div>
                <IconButton
                  style={{
                    padding: "0px",
                    paddingRight: 10,
                    color: "#8a8a8a",
                  }}
                  disabled={true}
                >
                  <VisibilityOffIcon style={{ width: "20px", height: "20px", marginLeft: 10 }} />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </div>
      }
      onClick={props.onClick}
    />
  );
}

function _verifyFlightLogData(logData: FlightLogData[]): boolean {
  const flightLogDataUnitIntegrity = logData.map((data: FlightLogData): boolean => {
    const odometerDistanceVerification: boolean = data.odometerDistance > 0;
    return odometerDistanceVerification;
  });
  const checkIfAllAreValid = Object.values(flightLogDataUnitIntegrity).every((x) => x === true);

  return checkIfAllAreValid;
}

function removeArrayDuplicates<T>(array: T[]): T[] {
  return array.filter((value, index) => array.indexOf(value) === index);
}
