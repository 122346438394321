import styled from "styled-components";

export const Terms = styled.section.attrs(() => ({}))`
  /* padding: 1rem;
  border: 2px solid var(--primary);
  border-radius: 10px;
  background: var(--white); */

  .btn-terms {
    display: flex;
    justify-content: flex-end;
  }

  .acordeon-container {
    > div {
      width: 100%;
      margin: 0;
      margin-bottom: 3rem;

      > div {
        > .box {
          &:first-child {
            align-items: center;

            > div {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    .acordeonItems-body {
      & + div {
        margin-top: 1rem;
        /* padding-top: 1rem; */
      }
    }
  }
`;

export const ClickableDownload = styled.a.attrs(() => ({}))`
  text-decoration: none;
`;
