import { Cpu, DirectClient, Profile, Role } from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  biohubUnit,
  BiohubUnit,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

async function addDirectClient(
  directClient: Omit<
    DirectClient,
    "acceptedTermsOfUse" | "emergencyUnblockRequestedAt" | "subscriptions" | "logoUrl" | "profiles"
  >,
  adminProfile: Omit<
    Profile & { role: Role.admin },
    | "profileImageUrl"
    | "isRegistrationCompleted"
    | "preferences"
    | "directClientId"
    | "latestRequestAt"
  >,
  languageCode?: string
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.post("/direct-clients", {
      directClient: directClient,
      profile: adminProfile,
      languageCode: languageCode,
    });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default {
  addDirectClient,
};
