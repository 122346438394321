import React from "react";
import { useIntl } from "react-intl";
import Text from "../../BasicComponents/Text";
import Link from "../../BasicComponents/Link";
import Input from "../../Inputs/Field";
import Checkbox from "../../Inputs/Checkbox";
import Select from "../../Inputs/Select";
import Row from "../../Areas/Row";
import Column from "../../Areas/Column";
import { Agrupable, Itens, Controller, ContentItem, Box } from "./styles";

import ProfileService from "../../../../services/ProfileService";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function Content(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <ContentItem
      role="tabpanel"
      hidden={value !== index}
      id={`signup-tabs-${index}`}
      aria-labelledby={`sign-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </ContentItem>
  );
}

function linkTo(index: any) {
  return {
    id: `signup-tab-${index}`,
    "aria-controls": `signup-tabs-${index}`,
  };
}

export default () => {
  const intl = useIntl();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [formProfile, setFormProfile] = React.useState<{
    role: number;
    name: string;
    cellphoneNumber: string;
    email: string;
    zipCode: string;
    country: string;
    street: string;
    number: string;
    state: string;
    city: string;
    document: string;
    rg: string;
    landlineNumber: string;
    aviationNumber: string;
    crea: string;
    agricultureNumber: string;
    acceptedTermsOfUse: boolean;
  }>({
    role: 5,
    name: "",
    cellphoneNumber: "",
    email: "",
    zipCode: "",
    country: "",
    street: "",
    number: "",
    state: "",
    city: "",
    document: "",
    rg: "",
    landlineNumber: "",
    aviationNumber: "",
    crea: "",
    agricultureNumber: "",
    acceptedTermsOfUse: false,
  });

  const [formCompany, setFormCompany] = React.useState<{
    document: string;
    name: string;
    comercialPhoneNumber: string;
    tradeName: string;
    im: string;
    ie: string;
    zipCode: string;
    country: string;
    street: string;
    number: string;
    state: string;
    city: string;
    agricultureNumber: string;
    aviationNumber: string;
    acceptedTermsOfUse: boolean;
  }>({
    name: "",
    document: "",
    comercialPhoneNumber: "",
    tradeName: "",
    ie: "",
    im: "",
    agricultureNumber: "",
    aviationNumber: "",
    country: "",
    state: "",
    city: "",
    street: "",
    number: "",
    zipCode: "",
    acceptedTermsOfUse: false,
  });

  //terms
  const placeIndicator = intl.formatMessage({ id: "action.indicator" });
  const placeBtnCompany = intl.formatMessage({ id: "action.signup.company" });
  const placeBtnSingle = intl.formatMessage({ id: "action.signup.single" });
  const placeAccept = intl.formatMessage({ id: "action.accept" });
  const placeAcceptLink = intl.formatMessage({ id: "action.accept.signup.terms" });

  const placeIVA = intl.formatMessage({ id: "placeholder.iva" });
  const placeCompany = intl.formatMessage({ id: "placeholder.company.name" });
  const placeZIP = intl.formatMessage({ id: "placeholder.zip" });
  const placeAddress = intl.formatMessage({ id: "placeholder.address" });
  const placeNumber = intl.formatMessage({ id: "placeholder.number" });
  const placeComplement = intl.formatMessage({ id: "placeholder.complement" });
  const placeCountry = intl.formatMessage({ id: "placeholder.country" });
  const placeState = intl.formatMessage({ id: "placeholder.state.department" });
  const placeCity = intl.formatMessage({ id: "placeholder.city" });
  const placeComercialPhone = intl.formatMessage({ id: "placeholder.comercial.phone" });
  const placeAviation = intl.formatMessage({ id: "placeholder.aviation.reg" });
  const placeAgriculture = intl.formatMessage({ id: "placeholder.agriculture.reg" });
  const placeTradeName = intl.formatMessage({ id: "placeholder.trade.name" });
  const placeIE = intl.formatMessage({ id: "placeholder.ie" });
  const placeIM = intl.formatMessage({ id: "placeholder.im" });

  const placeRole = intl.formatMessage({ id: "placeholder.role" });
  const placeName = intl.formatMessage({ id: "placeholder.name" });
  const placeEmail = intl.formatMessage({ id: "placeholder.email" });
  const placePhone = intl.formatMessage({ id: "placeholder.phone" });
  const placeLandline = intl.formatMessage({ id: "placeholder.landline" });
  const placeDocument = intl.formatMessage({ id: "placeholder.document" });
  const placeRG = intl.formatMessage({ id: "placeholder.rg" });
  const placeCrea = intl.formatMessage({ id: "placeholder.crea" });

  // TODO: temporary options for select inputs
  const optionsCountry = [
    {
      id: "BR",
      title: "Brasil",
      value: "BR",
    },
  ];

  const optionsState = [
    {
      id: "SP",
      title: "São Paulo",
      value: "SP",
      city: [
        {
          id: "mogi",
          title: "Mogi das Cruzes",
          value: "SP",
        },
      ],
    },
  ];

  const optionsRole = [
    {
      id: 1,
      title: "Administrador",
    },
    {
      id: 2,
      title: "Funcionário",
    },
    {
      id: 1,
      title: "Externo",
    },
  ];

  return (
    <>
      <Agrupable>
        {/* Tab Header */}
        <Row alignItems={"center"}>
          <Column xs={12} sm={2}>
            <Text text={placeIndicator} size={"small"} color={"dark"} />
          </Column>
          <Column xs={12} sm={10}>
            <Itens
              value={value}
              onChange={handleChange}
              aria-label="signup-tabs"
              variant="fullWidth"
            >
              <Controller label={placeBtnCompany} {...linkTo(0)} />
              <Controller label={placeBtnSingle} {...linkTo(1)} />
            </Itens>
          </Column>
        </Row>

        {/* First Tab Content */}
        <Content value={value} index={0}>
          <Row spacing={2}>
            <Column xs={12} sm={5}>
              <Input
                placeholder={placeDocument}
                type={"text"}
                value={formCompany.document}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    document: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={7}>
              <Input
                placeholder={placeCompany}
                type={"text"}
                value={formCompany.name}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    name: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={5}>
              <Input
                placeholder={placeComercialPhone}
                type={"text"}
                value={formCompany.comercialPhoneNumber}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    comercialPhoneNumber: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={7}>
              <Input
                placeholder={placeTradeName}
                type={"text"}
                value={formCompany.tradeName}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    tradeName: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={5}>
              <Input
                placeholder={placeIM}
                type={"text"}
                value={formCompany.im}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    im: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={7}>
              <Input
                placeholder={placeIE}
                type={"text"}
                value={formCompany.ie}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    ie: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={5}>
              <Input
                placeholder={placeZIP}
                type={"text"}
                value={formCompany.zipCode}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    zipCode: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={7}>
              <Select
                placeholder={placeCountry}
                key={"company-country"}
                options={optionsCountry}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    country: s as string,
                  })
                }
              />
            </Column>
            <Column xs={8} sm={7}>
              <Input
                placeholder={placeAddress}
                type={"text"}
                value={formCompany.street}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    street: s,
                  })
                }
              />
            </Column>
            <Column xs={4} sm={5}>
              <Input
                placeholder={placeNumber}
                type={"text"}
                value={formCompany.number}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    number: s,
                  })
                }
              />
            </Column>
            <Column xs={4} sm={5}>
              <Select
                placeholder={placeState}
                key={"data-state"}
                options={optionsState}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    state: s as string,
                  })
                }
              />
            </Column>
            <Column xs={8} sm={7}>
              <Select
                placeholder={placeCity}
                key={"data-city"}
                options={optionsState[0].city}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    city: s as string,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={12}>
              <Input
                placeholder={placeAgriculture}
                type={"text"}
                value={formCompany.agricultureNumber}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    agricultureNumber: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={12}>
              <Input
                placeholder={placeAviation}
                type={"text"}
                value={formCompany.aviationNumber}
                onChange={(s) =>
                  setFormCompany({
                    ...formCompany,
                    aviationNumber: s,
                  })
                }
              />
            </Column>
            <Column xs={12}>
              <Box>
                <Checkbox label={placeAccept} secondary />
                <Link to={"/privacy"} appearance="terms">
                  {placeAcceptLink}
                </Link>
              </Box>
            </Column>
          </Row>
        </Content>

        {/* Second Tab Content */}
        <Content value={value} index={1}>
          <Row spacing={2}>
            <Column xs={12} sm={5}>
              <Select
                placeholder={placeRole}
                key={"data-role"}
                options={optionsRole}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    role: s as number,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={7}>
              <Input
                placeholder={placeName}
                type={"text"}
                value={formProfile.name}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    name: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={5}>
              <Input
                placeholder={placePhone}
                type={"text"}
                value={formProfile.cellphoneNumber}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    cellphoneNumber: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={7}>
              <Input
                placeholder={placeEmail}
                type={"text"}
                value={formProfile.email}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    email: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={5}>
              <Input
                placeholder={placeZIP}
                type={"text"}
                value={formProfile.zipCode}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    zipCode: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={7}>
              <Select
                placeholder={placeCountry}
                key={"data-country"}
                options={optionsCountry}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    country: s as string,
                  })
                }
              />
            </Column>
            <Column xs={8} sm={7}>
              <Input
                placeholder={placeAddress}
                type={"text"}
                value={formProfile.street}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    street: s,
                  })
                }
              />
            </Column>
            <Column xs={4} sm={5}>
              <Input
                placeholder={placeNumber}
                type={"text"}
                value={formProfile.number}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    number: s,
                  })
                }
              />
            </Column>
            <Column xs={4} sm={5}>
              <Select
                placeholder={placeState}
                key={"data-state"}
                options={optionsState}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    state: s as string,
                  })
                }
              />
            </Column>
            <Column xs={8} sm={7}>
              <Select
                placeholder={placeCity}
                key={"data-city"}
                options={optionsState[0].city}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    city: s as string,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={5}>
              <Input
                placeholder={placeDocument}
                type={"text"}
                value={formProfile.document}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    document: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={7}>
              <Input
                placeholder={placeRG}
                type={"text"}
                value={formProfile.rg}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    rg: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={12}>
              <Input
                placeholder={placeCrea}
                type={"text"}
                value={formProfile.crea}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    crea: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={12}>
              <Input
                placeholder={placeAgriculture}
                type={"text"}
                value={formProfile.agricultureNumber}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    agricultureNumber: s,
                  })
                }
              />
            </Column>
            <Column xs={12} sm={12}>
              <Input
                placeholder={placeAviation}
                type={"text"}
                value={formProfile.aviationNumber}
                onChange={(s) =>
                  setFormProfile({
                    ...formProfile,
                    aviationNumber: s,
                  })
                }
              />
            </Column>
            <Column xs={12}>
              <Box>
                <Checkbox label={placeAccept} secondary />
                <Link to={"/privacy"} appearance="terms">
                  {placeAcceptLink}
                </Link>
              </Box>
            </Column>
          </Row>
        </Content>
      </Agrupable>
    </>
  );
};
