import React from "react";
import { useIntl } from "react-intl";
import Footer from "../../components/Atomic/Footer/teaser";
import FormSign from "../../components/Atomic/Forms/PreRegistration";
import Text from "../../components/Atomic/BasicComponents/Text";
import Image from "../../components/Atomic/BasicComponents/Image";
import { Teaser, Container, Columns, Description, Logo } from "./styles";

// Images
import imageBackground from "../../assets/background/principal.jpg";
import imageDescription from "../../assets/logo/logo_white.png";

interface Props {
  imageBackground?: string | "url";
  imageDescription?: string;
}

export default (props: Props) => {
  const intl = useIntl();
  return (
    <>
      <Teaser background={imageBackground}>
        <Container>
          <Columns>
            <Description>
              <Logo
                slogan={intl.formatMessage({ id: "info.slogan" })}
                lang={intl.formatMessage({ id: "language.region" })}
              >
                <Image
                  src={imageDescription}
                  alt={intl.formatMessage({ id: "info.slogan" })}
                  appearance={"img-divulgation"}
                />
              </Logo>
              <Text
                text={intl.formatMessage({ id: "info.title" })}
                appearance={"title"}
                color={"light"}
                size={"large"}
              />
              <Text
                text={intl.formatMessage({ id: "info.description" })}
                color={"light"}
                transform={"uppercase"}
                appearance={"info-subtitle"}
              />
            </Description>
            <FormSign />
          </Columns>
        </Container>
        <Footer description={intl.formatMessage({ id: "info.about" })} />
      </Teaser>
    </>
  );
};
