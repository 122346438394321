import React from "react";
import { useIntl } from "react-intl";
import Header from "../../../components/Atomic/Header";
import Footer from "../../../components/Atomic/Footer";
import Text from "../../../components/Atomic/BasicComponents/Text";

import { Container, Grid } from "@material-ui/core";

import { PrivacyPage, Content, Item, Description } from "../styles";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();

  // terms
  const titlePrivacy = intl.formatMessage({ id: "menu.privacy" });
  const subtitlePrivacy = intl.formatMessage({ id: "info.subtitlePrivacy" });
  const contentPrivacy = intl.formatMessage({ id: "info.privacy" });

  return (
    <>
      <PrivacyPage>
        <Header color={"dark"} />
        <Content id="privacy">
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <Item>
                  <Description>
                    <Text text={titlePrivacy} color={"primary"} appearance={"section-title"} />
                    <hr />
                    <Text text={subtitlePrivacy} color={"secondary"} appearance={"info-subtitle"} />
                    <Text text={contentPrivacy} color={"dark"} appearance={"description-block"} />
                  </Description>
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Content>
        <Footer image={true} description={intl.formatMessage({ id: "info.about" })} />
      </PrivacyPage>
    </>
  );
};
