import React from "react";
import { useIntl } from "react-intl";
import Container from "../../Areas/Container";
import Section from "../../Section";
import Text from "../../BasicComponents/Text";
import Testimonials from "../../BasicComponents/Testimonials";
import {} from "./styles";
interface Props {
  title: string;
  subtitle?: string;
  items: Array<{
    id: string;
    name: string;
    role: string;
    testimonial: string;
    image: string | "url";
  }>;
}

export default (props: Props) => {
  const intl = useIntl();
  return (
    <>
      <Section sectionTitle={props.title} sectionTitleMark textAlign={"center"}>
        <Container>
          {props.subtitle && (
            <Text text={props.subtitle} appearance={"carousel-subtitle"} color={"primary"} />
          )}
          <Testimonials items={props.items} />
        </Container>
      </Section>
    </>
  );
};
