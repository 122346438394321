import React from "react";
import { Button } from "./styles";

interface Props {
  text?: string;
  appearance?:
    | "button-outline"
    | "outline-more"
    | "highlighted"
    | "button-link"
    | "outline-invert"
    | "button-print";
  size?: "small" | "medium" | "large";
  color?:
    | "inherit"
    | "dark"
    | "primary"
    | "secondary"
    | "highlighted"
    | "inherit-primary"
    | "primary-banner";
  position?: "left" | "right";
  action?: () => void;
  visible?: boolean;
  type?: string;
  children?: React.ReactNode;
}

export default (props: Props) => {
  return (
    <>
      {props.visible !== false && (
        <Button
          appearance={props.appearance}
          size={props.size}
          color={props.color}
          position={props.position}
          onClick={props.action}
          variant="contained"
          type={props.type}
          disableElevation
        >
          {props.text ?? props.children}
        </Button>
      )}
    </>
  );
};
