import React from "react";
import { Text } from "./styles";

interface Props {
  text?: string;
  color?: "light" | "primary" | "secondary" | "third" | "highlighted" | "dark" | "default";
  size?: "small" | "medium" | "large" | "x-large" | "x-small";
  textAlign?: "center" | "left" | "right";
  appearance?:
    | "title"
    | "title-bold"
    | "title-semibold"
    | "section-title"
    | "title-medium"
    | "info-title"
    | "info-bold"
    | "info-semibold"
    | "description"
    | "description-block"
    | "info-subtitle"
    | "carousel-title"
    | "carousel-subtitle"
    | "carousel-description"
    | "carousel-title-mixed"
    | "description-banner"
    | "description-product"
    | "description-opinions"
    | "menu-title"
    | "price"
    | "info-name"
    | "info-role"
    | "info-value"
    | "info-required"
    | "info-description"
    | "block-title"
    | "small-title"
    | "small-description"
    | "details-description"
    | "cultivation-text";
  transform?: "lowercase" | "uppercase" | "capitalize";
  sectionTitleMark?: boolean;
}

export default (props: Props) => {
  return (
    <>
      <Text
        color={props.color}
        appearance={props.appearance}
        transform={props.transform}
        size={props.size}
        textAlign={props.textAlign}
        sectionTitleMark={props.sectionTitleMark}
      >
        {props.text}
      </Text>
    </>
  );
};
