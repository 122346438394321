import styled from "styled-components";

type Props = {
  sectionColor?: "primary";
  imageSeparatorPlus?: string;
};

export const Section = styled.section.attrs((props: Props) => ({
  sectionColor: props.sectionColor,
  imageSeparatorPlus: props.imageSeparatorPlus,
}))`
  width: 100%;
  background: var(--background-default);
  position: relative;
  padding: 3rem 0 5rem;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  z-index: 0;

  ${(props: Props) =>
    props.sectionColor === "primary" &&
    `
    padding: 5rem 0 2rem;
    background: var(--primary);
    color: var(--white);

    label{
      &:last-child {
        color: var(--third);
      }
    }

    @media screen and (max-width: 768px) {
      .MuiGrid-align-items-xs-center {
        flex-direction: column-reverse;
      }

      img {
        margin: 2rem  2rem 0 !important;
      }
    }

    @media screen and (max-width: 480px) {
      padding: 2rem 0;
      img {
        margin: 2rem 0 !important;
      }
    }

 `}

  ${(props: Props) =>
    props.imageSeparatorPlus &&
    `
    z-index: 1;
 `}

  @media screen and (max-width: 680px) {
    img {
      margin: 2rem 2rem 0;
      width: calc(100% - 4rem);
    }
  }

  @media screen and (max-width: 480px) {
    padding: 3rem 0;
    img {
      margin: 2rem 0 0;
      width: 100%;
    }
  }
`;
