/* eslint-disable react-hooks/rules-of-hooks */
import { Checkbox, Collapse, Icon, IconButton, TextField, withStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2, { SweetAlert2Props } from "react-sweetalert2";
import Column from "../../Areas/Column";
import Row from "../../Areas/Row";
import Button from "../../BasicComponents/Button";
import { Agrupable, ContentItem, PhoneNumber } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";
import {
  convertProductsToOrderDetail,
  sendPurchaseOrderIntentMail,
} from "../../../../services/provisoryCartSolutionsService";
import { clearCart } from "../../../../store/actions/provisoryCartActions";
import { Address } from "biohub-model";
import { isValidPhoneNumber } from "react-phone-number-input";

interface Props {}

const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid var(--input-tertiary-border-focus)",
    },
  },
})(TextField);

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [termError, setTermError] = React.useState(intl.formatMessage({ id: "info.termError" }));
  const prefixOrderNumber: string = moment().format("O.YYYY.MM.DD");

  const [orderId, setOrderId] = React.useState<string>(prefixOrderNumber);
  const [notes, setNotes] = React.useState<string>("");
  const [orderDetail, setOrderDetail] = React.useState<string>("");
  const [administratorName, setAdministratorName] = React.useState<string>("");
  const [administratorDocument, setAdministratorDocument] = React.useState<string>("");
  const [administratorEmail, setAdministratorEmail] = React.useState<string>("");
  const [administratorPhone, setAdministratorPhone] = React.useState<string>("");

  const initialAddressData = {
    zipCode: "",
    street: "",
    number: "",
    complement: "",
    country: "",
    state: "",
    city: "",
  };

  const [administratorAddress, setAdministratorAddress] = React.useState<
    Omit<Address, "id" | "cityCode">
  >({ ...initialAddressData });
  const [isCompany, setIsCompany] = React.useState<boolean>(true);
  const [companyName, setCompanyName] = React.useState<string>("");
  const [companyDocument, setCompanyDocument] = React.useState<string>("");
  const [companyTradeName, setCompanyTradeName] = React.useState<string>("");
  const [companyEmail, setCompanyEmail] = React.useState<string>("");
  const [companyPhone, setCompanyPhone] = React.useState<string>("");

  const [companyAddress, setCompanyAddress] = React.useState<Omit<Address, "id" | "cityCode">>({
    ...initialAddressData,
  });
  const [alertProps, setAlertProps] = React.useState<SweetAlert2Props>({});

  const cartItems = useSelector((state: SystemState) => state.provisoryCart.cartSolutions);

  useEffect(() => {
    setOrderDetail(convertProductsToOrderDetail(cartItems, intl));
  }, []);

  const purchaseOrderIntentNumber = intl.formatMessage({ id: "purchase.order.intent.number" });
  const purchaseOrderIntentDetail = intl.formatMessage({ id: "purchase.order.intent.detail" });

  const placeName = intl.formatMessage({ id: "placeholder.name" });
  const placeEmail = intl.formatMessage({ id: "placeholder.email" });
  const placePhone = intl.formatMessage({ id: "purchase.order.intent.phone" });
  const placeNotes = intl.formatMessage({ id: "purchase.order.intent.message" });
  const documentTerm = useSelector((state: SystemState) => {
    const regionIsBrazil = state.locale.usageRegion === "brazil";

    return regionIsBrazil ? "RG" : intl.formatMessage({ id: "placeholder.document" });
  });
  const companyDocumentTerm = useSelector((state: SystemState) => {
    const regionIsBrazil = state.locale.usageRegion === "brazil";

    return regionIsBrazil ? "CNPJ" : intl.formatMessage({ id: "placeholder.document" });
  });
  const companyTradeNameTerm = intl.formatMessage({ id: "placeholder.trade.name" });
  const addressZipCodeTerm = intl.formatMessage({ id: "placeholder.zip" });
  const addressStreetTerm = intl.formatMessage({ id: "placeholder.street" });
  const addressNumberTerm = intl.formatMessage({ id: "placeholder.number" });
  const addressComplementTerm = intl.formatMessage({ id: "placeholder.complement" });
  const addressCountryTerm = intl.formatMessage({ id: "placeholder.country" });
  const addressStateTerm = intl.formatMessage({ id: "placeholder.state.department" });
  const addressCityTerm = intl.formatMessage({ id: "placeholder.city" });
  const administratorTerm = intl.formatMessage({ id: "info.role.administrator" }) + ":";
  const companySectionTerm = intl.formatMessage({ id: "report.company" });
  const isCompanyQuestionTerm = intl.formatMessage({ id: "placeholder.isCompanyQuestion" });
  const actionSend = intl.formatMessage({ id: "action.send" });

  const handleSendAction = async () => {
    const result = await sendPurchaseOrderIntentMail({
      orderId: orderId,
      orderDetail: orderDetail,
      notes: notes,
      administratorInfo: {
        name: administratorName,
        phone: administratorPhone,
        email: administratorEmail,
        document: administratorDocument,
        address: administratorAddress,
      },
      companyInfo: isCompany
        ? {
            name: companyName,
            tradeName: companyTradeName,
            phone: companyPhone,
            email: companyEmail,
            document: companyDocument,
            address: companyAddress,
          }
        : undefined,
    });
    if (result.success) {
      dispatch(clearCart());
      setAlertProps({
        show: true,
        title: intl.formatMessage({ id: "action.warn" }),
        text: intl.formatMessage({ id: `${result.data}` }),
        showConfirmButton: true,
        confirmButtonColor: "#1d4f90",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        onConfirm: () => {
          window.location.href = "/about";
        },
      });
    } else {
      setTermError(intl.formatMessage({ id: `${result.error.message}` }));
      setOpen(true);
    }
  };

  return (
    <>
      <Agrupable>
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon fontSize="inherit">close</Icon>
              </IconButton>
            }
          >
            {termError}
          </Alert>
        </Collapse>
        <ContentItem>
          <FormRender
            rows={[
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: purchaseOrderIntentNumber,
                      value: orderId,
                      required: false,
                      onChange: undefined,
                    },
                    sm: 8,
                    xs: 12,
                    visible: true,
                  },
                ],
              },
              {
                sectionElement: (
                  <b
                    style={{
                      paddingTop: 20,
                      paddingBottom: 10,
                    }}
                  >
                    {administratorTerm}
                  </b>
                ),
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: placeName,
                      value: administratorName,
                      required: true,
                      onChange: (value) => setAdministratorName(value),
                    },
                    sm: 6,
                    xs: 6,
                    visible: true,
                  },
                  {
                    info: {
                      isPhone: false,
                      placeholder: documentTerm,
                      value: administratorDocument,
                      required: true,
                      onChange: (value) => setAdministratorDocument(value),
                    },
                    sm: 6,
                    xs: 6,
                    visible: true,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: placeEmail,
                      value: administratorEmail,
                      required: true,
                      onChange: (value) => {
                        setOrderId(`${prefixOrderNumber}.${value}`);
                        setAdministratorEmail(value);
                      },
                    },
                    sm: 6,
                    xs: 6,
                    visible: true,
                  },
                  {
                    info: {
                      isPhone: true,
                      placeholder: placePhone,
                      value: administratorPhone,
                      required: true,
                      onChange: (value) => setAdministratorPhone(value),
                    },
                    sm: 6,
                    xs: 6,
                    visible: true,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressZipCodeTerm,
                      value: administratorAddress.zipCode,
                      onChange: (value) =>
                        setAdministratorAddress({
                          ...administratorAddress,
                          zipCode: value,
                        }),
                      required: true,
                    },
                    sm: 6,
                    xs: 12,
                    visible: true,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressStreetTerm,
                      value: administratorAddress.street,
                      onChange: (value) =>
                        setAdministratorAddress({
                          ...administratorAddress,
                          street: value,
                        }),
                      required: true,
                    },
                    sm: 8,
                    xs: 8,
                    visible: true,
                  },
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressNumberTerm,
                      value: administratorAddress.number,
                      onChange: (value) =>
                        setAdministratorAddress({
                          ...administratorAddress,
                          number: value,
                        }),
                      required: true,
                    },
                    sm: 4,
                    xs: 4,
                    visible: true,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressComplementTerm,
                      value: administratorAddress.complement ?? "",
                      onChange: (value) =>
                        setAdministratorAddress({
                          ...administratorAddress,
                          complement: value,
                        }),
                      required: false,
                    },
                    sm: 12,
                    xs: 12,
                    visible: true,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressCityTerm,
                      value: administratorAddress.city,
                      onChange: (value) =>
                        setAdministratorAddress({
                          ...administratorAddress,
                          city: value,
                        }),
                      required: true,
                    },
                    sm: 4,
                    xs: 4,
                    visible: true,
                  },
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressStateTerm,
                      value: administratorAddress.state,
                      onChange: (value) =>
                        setAdministratorAddress({
                          ...administratorAddress,
                          state: value,
                        }),
                      required: true,
                    },
                    sm: 4,
                    xs: 4,
                    visible: true,
                  },
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressCountryTerm,
                      value: administratorAddress.country,
                      onChange: (value) =>
                        setAdministratorAddress({
                          ...administratorAddress,
                          country: value,
                        }),
                      required: true,
                    },
                    sm: 4,
                    xs: 4,
                    visible: true,
                  },
                ],
              },

              {
                sectionElement: (
                  <div
                    style={{
                      paddingTop: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <b>{companySectionTerm}</b>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {isCompanyQuestionTerm}
                      <Checkbox
                        defaultChecked={isCompany}
                        value={isCompany}
                        onChange={() => setIsCompany(!isCompany)}
                      />
                    </div>
                  </div>
                ),
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: placeName,
                      value: companyName,
                      required: true,
                      onChange: (value) => setCompanyName(value),
                    },
                    sm: 6,
                    xs: 6,
                    visible: isCompany,
                  },
                  {
                    info: {
                      isPhone: false,
                      placeholder: companyTradeNameTerm,
                      value: companyTradeName,
                      required: true,
                      onChange: (value) => setCompanyTradeName(value),
                    },
                    sm: 6,
                    xs: 6,
                    visible: isCompany,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: companyDocumentTerm,
                      value: companyDocument,
                      required: true,
                      onChange: (value) => setCompanyDocument(value),
                    },
                    sm: 6,
                    xs: 12,
                    visible: isCompany,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: placeEmail,
                      value: companyEmail,
                      required: true,
                      onChange: (value) => setCompanyEmail(value),
                    },
                    sm: 6,
                    xs: 6,
                    visible: isCompany,
                  },
                  {
                    info: {
                      isPhone: true,
                      placeholder: placePhone,
                      value: companyPhone,
                      required: true,
                      onChange: (value) => setCompanyPhone(value),
                    },
                    sm: 6,
                    xs: 6,
                    visible: isCompany,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressZipCodeTerm,
                      value: companyAddress.zipCode,
                      onChange: (value) =>
                        setCompanyAddress({
                          ...companyAddress,
                          zipCode: value,
                        }),
                      required: true,
                    },
                    sm: 6,
                    xs: 12,
                    visible: isCompany,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressStreetTerm,
                      value: companyAddress.street,
                      onChange: (value) =>
                        setCompanyAddress({
                          ...companyAddress,
                          street: value,
                        }),
                      required: true,
                    },
                    sm: 8,
                    xs: 8,
                    visible: isCompany,
                  },
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressNumberTerm,
                      value: companyAddress.number,
                      onChange: (value) =>
                        setCompanyAddress({
                          ...companyAddress,
                          number: value,
                        }),
                      required: true,
                    },
                    sm: 4,
                    xs: 4,
                    visible: isCompany,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressComplementTerm,
                      value: companyAddress.complement ?? "",
                      onChange: (value) =>
                        setCompanyAddress({
                          ...companyAddress,
                          complement: value,
                        }),
                      required: false,
                    },
                    sm: 12,
                    xs: 12,
                    visible: isCompany,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressCityTerm,
                      value: companyAddress.city,
                      onChange: (value) =>
                        setCompanyAddress({
                          ...companyAddress,
                          city: value,
                        }),
                      required: true,
                    },
                    sm: 4,
                    xs: 4,
                    visible: isCompany,
                  },
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressStateTerm,
                      value: companyAddress.state,
                      onChange: (value) =>
                        setCompanyAddress({
                          ...companyAddress,
                          state: value,
                        }),
                      required: true,
                    },
                    sm: 4,
                    xs: 4,
                    visible: isCompany,
                  },
                  {
                    info: {
                      isPhone: false,
                      placeholder: addressCountryTerm,
                      value: companyAddress.country,
                      onChange: (value) =>
                        setCompanyAddress({
                          ...companyAddress,
                          country: value,
                        }),
                      required: true,
                    },
                    sm: 4,
                    xs: 4,
                    visible: isCompany,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: placeNotes,
                      value: notes,
                      required: false,
                      onChange: (value) => setNotes(value),
                      rows: 5,
                    },
                    sm: 12,
                    xs: 12,
                    visible: true,
                  },
                ],
              },
              {
                elements: [
                  {
                    info: {
                      isPhone: false,
                      placeholder: purchaseOrderIntentDetail,
                      value: orderDetail,
                      required: false,
                      onChange: undefined,
                      rows: 12,
                    },
                    sm: 12,
                    xs: 12,
                    visible: true,
                  },
                ],
              },
            ]}
            sendTerm={actionSend}
            alertProps={alertProps}
            onClickSend={handleSendAction}
          />
        </ContentItem>
      </Agrupable>
    </>
  );
};

const FormRender = (props: {
  rows: {
    sectionElement?: JSX.Element;
    elements: {
      info: FormItemProps;
      xs: number;
      sm: number;
      visible: boolean;
    }[];
  }[];
  sendTerm: string;
  alertProps: SweetAlert2Props;
  onClickSend: () => void;
}): JSX.Element => {
  const [validInputs, setValidInputs] = useState<boolean[][]>(
    props.rows.map((row) => row.elements.map((element) => !element.info.required))
  );
  const visibleInputs = props.rows.map((row) => row.elements.map((element) => element.visible));

  const [validInput, setValidInput] = useState<boolean>(false);

  useEffect(() => {
    const validInputsAndVisible = validInputs.map((row, rowIndex) =>
      row.map((element, elementIndex) =>
        visibleInputs[rowIndex][elementIndex] === false ? true : element
      )
    );

    setValidInput(
      validInputsAndVisible.map((e) => e.reduce((a, b) => a && b)).reduce((a, b) => a && b)
    );
  }, [validInputs, visibleInputs]);

  return (
    <>
      {props.rows.map((row, rowIndex) => (
        <div key={`purchase-order-intent-form-field-row-${rowIndex}`}>
          {row.sectionElement !== undefined && <Row>{row.sectionElement}</Row>}
          {(() => {
            const visibleElements = row.elements.filter((element) => element.visible);

            if (visibleElements.length === 0) return <></>;

            return (
              <Row spacing={3}>
                {visibleElements.map((element, elementIndex) => (
                  <Column
                    key={`purchase-order-intent-form-field-row-${rowIndex}-element-${elementIndex}`}
                    xs={element.xs}
                    sm={element.sm}
                  >
                    <FormItem
                      {...element.info}
                      setValidInput={(valid) => {
                        const newValidInputs = [...validInputs.map((list) => [...list])];
                        newValidInputs[rowIndex][elementIndex] = valid;

                        setValidInputs(newValidInputs);
                      }}
                      valid={validInputs[rowIndex][elementIndex]}
                    />
                  </Column>
                ))}
              </Row>
            );
          })()}
        </div>
      ))}
      <Row spacing={3}>
        <Column xs={12} sm={12}>
          <Button
            text={props.sendTerm}
            appearance={"outline-invert"}
            size={"large"}
            color={"primary"}
            position={"left"}
            action={() => {
              if (!validInput) return;

              props.onClickSend();
            }}
          />
          <SweetAlert2 {...props.alertProps} />
        </Column>
      </Row>
    </>
  );
};

type FormItemProps = {
  isPhone: boolean;
  required: boolean;
  value: string;
  placeholder: string;
  rows?: number;
  onChange: ((value: string) => void) | undefined;
};

const FormItem = (
  props: FormItemProps & { setValidInput: ((valid: boolean) => void) | undefined; valid: boolean }
): JSX.Element => {
  if (props.isPhone) {
    return (
      <PhoneNumber
        placeholder={props.placeholder}
        variant="standard"
        size="medium"
        defaultCountry="br"
        disableAreaCodes
        disableDropdown
        countryCodeEditable={true}
        name="phone"
        value={props.value}
        onChange={(e) => {
          const value = e.toString();

          const setValidInput = props.setValidInput;

          if (setValidInput !== undefined) {
            setValidInput(isValidPhoneNumber(value));
          }

          const onChange = props.onChange;
          if (onChange === undefined) return;

          onChange(value);
        }}
        required={props.required}
        error={!props.valid}
      />
    );
  }

  return (
    <CssTextField
      id="custom-css-standard-input"
      onChange={(e) => {
        const value = e.target.value;

        const setValidInput = props.setValidInput;

        if (setValidInput !== undefined) {
          setValidInput(value.length > 0);
        }

        const onChange = props.onChange;
        if (onChange === undefined) return;

        onChange(value);
      }}
      value={props.value}
      placeholder={props.placeholder}
      type={"text"}
      rows={props.rows}
      multiline={props.rows !== undefined}
      InputProps={{
        readOnly: props.onChange === undefined,
      }}
      error={!props.valid}
    />
  );
};
