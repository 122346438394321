import { Typography } from "@material-ui/core";
import React from "react";
import { Role, UnitSystem, WeatherInfo } from "biohub-model";
import { Image, Info } from "../../modals/styles";

import imageTemperature from "../../../../assets/icon/icon_temperature_border.svg";
import imageHumidity from "../../../../assets/icon/icon_humidity_border.svg";
import imagePressure from "../../../../assets/icon/icon_pressure.svg";
import imageCompassOutside from "../../../../assets/icon/icon_compass_without_arrow.svg";
import imageCompassInside from "../../../../assets/icon/icon_compass_only_arrow.svg";
import CollapsibleCard from "../../../../components/Atomic/CollapsibleCard";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";

/**
 * Returns a group of semi-transparent elements containing weather data. This component does not
 * make any weather requests - it simply receives weather data and renders it.
 *
 * This does not float by itself. It needs to be positioned in a floating element.
 */
export default function WeatherOverlay(props: {
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  marginBottom: number;
}): JSX.Element {
  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const weatherData = useSelector((state: SystemState) => state.weather.weatherInfo);

  return (
    <>
      <CollapsibleCard
        expanded={props.expanded}
        setExpanded={props.setExpanded}
        style={{
          display: "flex",
          flex: "1 1 content",
          flexDirection: "row",
          minHeight: 0,
          minWidth: 0,
          justifyContent: "flex-end",
          alignItems: "flex-end",
          alignSelf: "flex-end",
          margin: "5px 0px 5px",
          marginBottom: props.marginBottom,
        }}
      >
        <div style={{ display: "flex", flex: "1 1 content", flexDirection: "column" }}>
          <Info>
            <Image src={imageTemperature} alt={"temperature"} />
            <Typography>{formatTemperature(weatherData, unitSystem)}</Typography>
          </Info>
          <Info>
            <Image src={imageHumidity} alt={"humidity"} />
            <Typography>{formatHumidity(weatherData)}</Typography>
          </Info>
          <Info>
            <Image src={imagePressure} alt={"pressure"} />
            <Typography>{formatPressure(weatherData, unitSystem)}</Typography>
          </Info>
          <Info>
            <Image src={imageCompassOutside} alt={"wind"} />
            {/** Render the compass' arrow right in the middle. */}
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                height: "32px",
                width: "32px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Image
                src={imageCompassInside}
                alt={"wind-arrow"}
                angle={getWindDirectionAngle(weatherData?.windDegree ?? 0)}
              />
            </div>
            <Typography>{formatWind(weatherData, unitSystem)}</Typography>
          </Info>
        </div>
      </CollapsibleCard>
    </>
  );
}

function getWindDirectionAngle(direction: number): number {
  // The input wind direction is represented in meteorological form
  // (see: https://apollo.nvu.vsc.edu/classes/met130/notes/chapter8/wdir.html).
  // Here we convert that direction to a angular representation considering
  // that the image we need to rotate won't have rotation when the direction is 0º.

  return (direction + 180) % 360;
}

function formatTemperature(data: WeatherInfo | null, unitSystem: UnitSystem): string {
  if (!data || data.temperature === undefined) {
    return `N/A`;
  }
  const celsius = data.temperature;
  if (unitSystem === UnitSystem.imperial) {
    const fahrenheit = (1.8 * celsius + 32).toFixed(1);
    return `${fahrenheit}˚F`;
  }
  return `${celsius.toFixed(1)}˚C`;
}

function formatHumidity(data: WeatherInfo | null): string {
  if (!data || data.humidity === undefined) {
    return `N/A`;
  }
  return `${data.humidity}%`;
}

// function formatClouds(data: WeatherInfo | null): string {
//   if (!data || data.clouds === undefined) {
//     return `N/A`;
//   }
//   return `${data.clouds}%`;
// }

function formatPressure(data: WeatherInfo | null, unitSystem: UnitSystem): string {
  if (!data || data.pressure === undefined) {
    return `N/A`;
  }
  const pascalPressure = data.pressure;
  if (unitSystem === UnitSystem.imperial) {
    const psiPressure = (pascalPressure / 6894.7572931783).toFixed(1);
    return `${psiPressure}psi`;
  }
  return `${pascalPressure}Pa`;
}

// function formatVisibility(data: WeatherInfo | null): string {
//   if (!data || data.visibility === undefined) {
//     return `N/A`;
//   }
//   if (!data.visibility) {
//     // I'm not sure if this check is accurate, it's what the old code does.
//     return `N/A`; // <- Localize!
//   } else {
//     return `${(data.visibility / 1000).toFixed(1)}km`;
//   }
// }

function formatWind(data: WeatherInfo | null, unitSystem: UnitSystem): string {
  if (!data || data.windSpeed === undefined || data.windDegree === undefined) {
    return `N/A`;
  }
  const windSpeed = data.windSpeed;
  if (unitSystem === UnitSystem.imperial) {
    const milesWindSpeed = (windSpeed * 0.6213711922).toFixed(1);
    return `${milesWindSpeed}mph`;
  }
  return `${windSpeed.toFixed(1)}km/h`;
}

// function formatSunrise(data: WeatherInfo | null): string {
//   if (!data || data.sunrise === undefined) {
//     return `N/A`;
//   }
//   // First, offset with the timezone.
//   const offsetDate = addHours(data.sunrise, data.timezone);
//   // Return the time component only.
//   return `${offsetDate.toISOString().substr(11, 5)}`;
// }

// function formatSunset(data: WeatherInfo | null): string {
//   if (!data || data.sunset === undefined) {
//     return `N/A`;
//   }
//   // First, offset with the timezone.
//   const offsetDate = addHours(data.sunset, data.timezone);
//   // Return the time component only.
//   return `${offsetDate.toISOString().substr(11, 5)}`;
// }
