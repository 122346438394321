import React from "react";
import Text from "../Text";
import { Block, Card } from "./styles";

interface Props {
  title?: string;
  children?: React.ReactNode;
  appearance?: "primary" | "secondary" | "full-card" | "border";
}

export default (props: Props) => {
  return (
    <Block appearance={props.appearance}>
      {props.title && <Text text={props.title} />}
      <Card>{props.children}</Card>
    </Block>
  );
};
