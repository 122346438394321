/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";

import Acordeon from "../../../v2/Acordeon";
import Button from "../../BasicComponents/Button";

import { Terms, ClickableDownload } from "./styles";
import { BiohubLocale } from "../../../../store/reducers/localeReducer";
import { SystemState } from "../../../../store/reducers/systemReducer";

type TermsProps = {
  id: string;
  title: string;
  file: any;
  isChecked: boolean;
};

type TermsContracts = {
  id: string;
  title: string;
  terms: TermsProps[];
};

interface Props {
  termsContracts: TermsContracts[];
  setTermsContracts: React.Dispatch<React.SetStateAction<any>>;
}

export default (props: Props) => {
  const intl = useIntl();
  const localeSystem: BiohubLocale = useSelector((state: SystemState) => state.locale.localeCode);

  // terms
  const termTextButtonDownload = intl.formatMessage({ id: "cart.button.download" });

  const handleChecked = (id: string) => {
    props.setTermsContracts((state: TermsContracts[]) =>
      state.map((item) => ({
        ...item,
        terms: item.terms.map((i) =>
          i.id === id
            ? i.isChecked === false
              ? { ...i, isChecked: true }
              : { ...i, isChecked: false }
            : i
        ),
      }))
    );
  };

  return (
    <>
      <Terms>
        <Grid container spacing={2} className="acordeon-container">
          <Acordeon
            expanded={true}
            multiSelect={true}
            items={props.termsContracts.map((item) => ({
              id: item.id,
              title: item.title,
              body: (
                <Grid container>
                  {item.terms.map((term) => (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="acordeonItems-body">
                      <Grid container alignItems="center">
                        <Grid item lg={10} md={10} sm={10} xs={8}>
                          <FormControlLabel
                            control={
                              <Checkbox color={"primary"} onChange={() => handleChecked(term.id)} />
                            }
                            label={term.title}
                          />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xs={4} className={"btn-terms"}>
                          <ClickableDownload href={term.file} target="__blank" download={true}>
                            <Button text={termTextButtonDownload} color={"primary"} />
                          </ClickableDownload>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ),
            }))}
          />
        </Grid>
      </Terms>
    </>
  );
};
