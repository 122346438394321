import styled from "styled-components";

type Props = {
  orientation?: "horizontal" | "vertical" | "column";
  color?: "light" | "dark";
  active?: boolean;
};

export const List = styled.ul.attrs((props: Props) => ({
  orientation: props.orientation,
  className: "menu-top",
}))`
  width: 100%;
  list-style: none;
  font-family: "GalanoGrotesque-Medium";

  ${(props) =>
    props.orientation === "horizontal" &&
    `
    display: inline-flex;
    li {
      padding: 0 5px;
    }
  `}

  ${(props) =>
    props.orientation === "vertical" &&
    `
    li { display: block; }
  `}

  ${(props) =>
    props.orientation === "column" &&
    `
    margin-bottom: 2rem;
    li { 
      display: inline-block;
      width: 50%; 
      padding: 15px 0px 0px 0px;
      text-align: left;
    
      @media screen and (max-width: 991px) {
        padding-top: 5px;
      }
    }
  `}
`;

export const Item = styled.li.attrs((props: Props) => ({
  color: props.color,
  active: props.active,
}))`
  flex: 1;
  text-align: center;
  padding: 15px 5px;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 6px;
    border-radius: 10px;
    background: var(--transparent);
    position: absolute;
    bottom: -3px;
    left: calc(50% - 10px);
  }

  :hover {
    ul {
      top: 0;
    }
  }

  ${(props) =>
    props.color === "light" &&
    `
    &:hover, &:focus {
      :after {
        background: var(--secondary);
      }
    }
    
    a { 
      color: var(--white); 
      :focus, :hover { 
        text-decoration: none; 
        color: var(--secondary);
      } 
    }
    
    border-bottom: 1px solid var(--white);
  `}

  ${(props) =>
    props.active &&
    `
      :after {
        background: var(--white);
        @media screen and (max-width: 991px) {
          background-color: var(--primary);
          bottom: -3px;
          left: 5%;
        }
      }
  `}
`;

export const Link = styled.a.attrs((props: Props) => ({}))`
  color: initial;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-color: var(--secondary);
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
`;

export const Submenu = styled.ul.attrs((props: Props) => ({}))`
  position: fixed;
  background: var(--primary);
  width: 100vw;
  top: -440px;
  left: 0;
  height: 440px;
  color: var(--white);
  padding-top: 75px;
  z-index: -1;
  display: flex;
  align-items: center;
  transition: 0.65s all ease;

  .MuiContainer-maxWidthLg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  li {
    height: 100%;
    top: 0;
    list-style: none;
    display: contents;
    align-items: center;
  }

  a {
    padding: 40px;
    text-align: left;
    height: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;

    :hover {
      background: rgba(255 255 255 / 25%);
    }

    img {
      max-width: 200px;
      display: block;
      object-fit: contain;
    }
  }

  &.subtype {
  .MuiContainer-maxWidthLg {
    padding: 0 5rem;
    a {
      color: var(--white);
    }
    li:first-of-type {
      display: inline-flex;
      height: calc(100% + 30px);
      a {
        font-size: 14px;
        img {
          max-height: 60px;
          object-fit: cover;
          margin: 20px 0;
        }
      }
    }
  }


`;

export const SubItens = styled.ul.attrs((props: Props) => ({}))`
  background: transparent;
  width: 60%;
  height: 100%;
  color: var(--white);
  flex-flow: row wrap;
  transition: 0.65s all ease;

  li,
  li:first-of-type {
    float: left;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 33%;
    height: 50% !important;
    top: 0;
  }

  a {
    padding: 15px;

    img {
      display: block;
      max-width: 60px !important;
      max-height: 60px;
      object-fit: contain !important;
      margin: 1rem auto !important;
      text-align: center;
    }

    :hover {
      background: rgba(255 255 255 / 25%);
    }
  }

  @media screen and (max-width: 991px) {
  }
`;
