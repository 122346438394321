import { Box, CircularProgress, Tooltip } from "@material-ui/core";
import { ReleaserInfo } from "biohub-model/ts/FlightReport";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Column,
  Content,
  Description,
  Details,
  Divider,
  Footer,
  HeadColumn,
  Header,
  Image,
  Info,
  Item,
  List,
  ListItem,
  Loading,
  ReportPage,
  Row,
  Subtitle,
  Table,
  Title,
} from "./styles";
import FlightService from "../../services/FlightService";
import UnitConversionHelper from "../../core/helper/UnitConversionHelper";
import { ReleaserType, FlightReport, Role, UnitSystem } from "biohub-model";
import Button from "../../components/Atomic/BasicComponents/Button";

import iconArea from "../../assets/icon/icon_flight_invert.png";
import iconDrone from "../../assets/icon/icon_drone.png";
import { useSelector } from "react-redux";
import { SystemState } from "../../store/reducers/systemReducer";
import { useLocation } from "react-router-dom";

import FlightReportMap from "./containers/FlightReportMap";

/**
 * @param sec: Time duration in seconds
 * @returns converted string in hh:mm:ss format
 */
function toHHMMSS(sec: number): string {
  var sec_trunc = Math.trunc(sec);
  var hours = Math.floor(sec_trunc / 3600);
  var minutes = Math.floor((sec_trunc - hours * 3600) / 60);
  var seconds = sec_trunc - hours * 3600 - minutes * 60;

  var hoursOut: string = hours < 10 ? "0" + hours.toString() : hours.toString();
  var minOut: string = minutes < 10 ? "0" + minutes.toString() : minutes.toString();
  var secOut: string = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

  return hoursOut + ":" + minOut + ":" + secOut;
}

export default () => {
  // Gets the id passed
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const flightId = query.get("flightId");
  const areaId = query.get("areaId");
  const projectId = query.get("projectId");
  let queryParam: string;
  if (flightId !== null) {
    queryParam = `flightId=${flightId}`;
  } else if (areaId !== null) {
    queryParam = `areaId=${areaId}`;
  } else if (projectId !== null) {
    queryParam = `projectId=${projectId}`;
  } else {
    queryParam = "";
  }
  // The undefined state is maped to a loading component, the null state is maped to a error on
  // Loading component and the FlightReport state stores the actual FlightReport data
  const [flightReports, setFlightReports] = useState<FlightReport | undefined | null>(undefined);

  useEffect(() => {
    async function result(): Promise<void> {
      const dataResult = await FlightService.flightReport(queryParam);
      if (dataResult.success) {
        setFlightReports(dataResult.data);
      } else {
        setFlightReports(null);
      }
    }
    result();
  }, [queryParam]);

  const intl = useIntl();

  const internationalizationTerms = {
    termIssueDate: intl.formatMessage({ id: "report.issueDate" }),
    termArea: intl.formatMessage({ id: "report.area" }),
    termPlannedFlight: intl.formatMessage({ id: "report.plannedFlight" }),
    termDetails: intl.formatMessage({ id: "report.details" }),
    termDateHour: intl.formatMessage({ id: "report.dateHour" }),
    termDrone: intl.formatMessage({ id: "report.drone" }),
    termManufacturer: intl.formatMessage({ id: "report.manufacturer" }),
    termAnac: intl.formatMessage({ id: "report.anac" }),
    termReleasedProduct: intl.formatMessage({ id: "report.releasedProduct" }),
    termReleasing: intl.formatMessage({ id: "report.releasing" }),
    termBandwidth: intl.formatMessage({ id: "report.bandWidth" }),
    termPlannedSpeed: intl.formatMessage({ id: "report.plannedSpeed" }),
    termReleaseRate: intl.formatMessage({ id: "report.releaseRate" }),
    termCalibrationFactor: intl.formatMessage({ id: "map.newProject.calibrationFactor" }),
    termRealFlight: intl.formatMessage({ id: "report.realFlight" }),
    termFlightTime: intl.formatMessage({ id: "report.flightTime" }),
    termAverageReleaseRate: intl.formatMessage({ id: "report.averageReleaseRate" }),
    termAverageSpeed: intl.formatMessage({ id: "report.averageSpeed" }),
    termTotalReleased: intl.formatMessage({ id: "report.totalReleased" }),
    termTotalArea: intl.formatMessage({ id: "report.totalArea" }),
    termTraps: intl.formatMessage({ id: "report.traps" }),
    termSerialNumber: intl.formatMessage({ id: "report.serialNumber" }),
    termCoveredHectares: intl.formatMessage({ id: "report.coveredHectares" }),
    termDateMeasurement: intl.formatMessage({ id: "report.dateMeasurament" }),
    termSpecie: intl.formatMessage({ id: "report.specie" }),
    termNumberAdults: intl.formatMessage({ id: "report.adultsNumber" }),
    termNoAvailable: intl.formatMessage({ id: "report.noAvailable" }),
    termPrint: intl.formatMessage({ id: "report.print" }),
    termInput: intl.formatMessage({ id: "report.input" }),
  };
  const currentProfile = useSelector((state: SystemState) => state.profile.userProfile!);
  const unitSystem =
    currentProfile?.role !== Role.external
      ? currentProfile.preferences.unitSystem
      : UnitSystem.metric;

  return (
    <>
      <ReportPage>
        <Header>
          <Row>
            <HeadColumn left width={50}>
              <Image
                src={
                  flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.logoUrl
                    : ""
                }
                alt={
                  flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.name
                    : ""
                }
                logo
              />
            </HeadColumn>
            <HeadColumn right width={50}>
              <Info>
                <Title>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.name
                    : ""}
                </Title>
                <Item>
                  CNPJ:{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.document
                    : ""}{" "}
                  IE:{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.ie
                    : ""}{" "}
                  IM:{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.im
                    : ""}
                </Item>
                <Item>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.street
                    : ""}{" "}
                  Nº
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.number
                    : ""}
                </Item>
                <Item>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.complement
                    : ""}
                </Item>
                <Item>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.city
                    : ""}{" "}
                  -{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.state
                    : ""}{" "}
                  -{" "}
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.address.zipCode
                    : ""}
                </Item>
                <Item strong>
                  {flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceProvider.phoneNumber
                    : ""}
                </Item>
              </Info>
            </HeadColumn>
          </Row>
        </Header>
        <Content>
          <Row>
            <Column left width={50} style={{ paddingLeft: 30 }}>
              <Image
                src={
                  flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceConsumer.logoUrl
                    : ""
                }
                alt={
                  flightReports !== null && flightReports !== undefined
                    ? flightReports[0].serviceConsumer.name
                    : ""
                }
                logo
              />
            </Column>
            <Column right top width={50}>
              <Item>
                {internationalizationTerms.termIssueDate}{" "}
                {format(new Date(), "dd MMM yyyy hh:mm:ss aaa")}
              </Item>
              <Item>
                {flightReports !== null && flightReports !== undefined
                  ? flightReports[0].serviceConsumer.name
                  : ""}
              </Item>
              <Item>
                {flightReports !== null && flightReports !== undefined
                  ? flightReports[0].serviceConsumer.document
                  : ""}
              </Item>
              <Item>
                {flightReports !== null && flightReports !== undefined
                  ? flightReports[0].serviceConsumer.phoneNumber
                  : ""}
              </Item>
              <Box>
                <Button
                  text={internationalizationTerms.termPrint}
                  color={"primary"}
                  size={"large"}
                  appearance={"button-print"}
                  action={() => {
                    window.print();
                  }}
                />
              </Box>
            </Column>
          </Row>
          {flightReports !== undefined && flightReports !== null ? (
            <>
              {flightReports.map((flightReport, index) => (
                <>
                  <Row noPadding>
                    <Column colSpan={2} width={100}>
                      <Table>
                        <Header gray>
                          <Row>
                            <HeadColumn width={100}>
                              <Image src={iconArea} alt="Area" icon />
                              <Item>{internationalizationTerms.termArea}</Item>
                              <Item>{flightReport.areaName}</Item>
                            </HeadColumn>
                          </Row>
                        </Header>
                        <Content>
                          <Row noPadding>
                            <Column width={30} top>
                              <FlightReportMap
                                dataReport={flightReport}
                                mode="plan"
                                flightToPlot={flightReport.flight}
                              />
                            </Column>
                            <Column width={70} left>
                              <Subtitle>{internationalizationTerms.termPlannedFlight}</Subtitle>
                              <Details>
                                <Description>{internationalizationTerms.termDetails}</Description>
                                <List>
                                  <ListItem>
                                    {internationalizationTerms.termDateHour}
                                    <b>
                                      {format(
                                        flightReport.flight.startedAt,
                                        "dd MMM yyyy hh:mm:ss aaa"
                                      )}
                                    </b>
                                  </ListItem>
                                  {flightReport.drone !== null && (
                                    <ListItem>
                                      {internationalizationTerms.termDrone}{" "}
                                      <b>{flightReport.drone.name}</b>,{" "}
                                      {internationalizationTerms.termManufacturer}{" "}
                                      <b>{flightReport.drone.manufacturer}</b>,{" "}
                                      {internationalizationTerms.termAnac}{" "}
                                      <b>{flightReport.drone.anacNumber}</b>{" "}
                                    </ListItem>
                                  )}
                                </List>
                              </Details>
                              <PlannedReleasersInfoTable
                                flightData={flightReport.flightData}
                                terms={internationalizationTerms}
                                unitSystem={unitSystem}
                              />
                            </Column>
                          </Row>
                        </Content>
                      </Table>
                    </Column>
                  </Row>
                  <Row noPadding>
                    <Column width={100} colSpan={2} center>
                      <Divider />
                    </Column>
                  </Row>
                  <Row noPadding>
                    <Column colSpan={2} width={100}>
                      <Table>
                        <Content>
                          <Row noPadding>
                            <Column width={30} top>
                              <FlightReportMap
                                dataReport={flightReport}
                                flightToPlot={flightReport.flight}
                                mode="flight"
                              />
                            </Column>
                            <Column width={70} left>
                              <Subtitle>{internationalizationTerms.termRealFlight}</Subtitle>
                              <Details>
                                <Description>{internationalizationTerms.termDetails}</Description>
                                <List>
                                  <ListItem>
                                    {internationalizationTerms.termDateHour}{" "}
                                    <b>
                                      {/* flightReport.flightData[].real.startedAt */}
                                      {format(Date.now(), "dd MMM yyyy hh:mm:ss aaa")}
                                    </b>
                                  </ListItem>
                                  {flightReport.drone !== null && (
                                    <ListItem>
                                      {internationalizationTerms.termDrone}{" "}
                                      <b>{flightReport.drone.name}</b>,{" "}
                                      {internationalizationTerms.termManufacturer}{" "}
                                      <b>{flightReport.drone.manufacturer}</b>,{" "}
                                      {internationalizationTerms.termAnac}{" "}
                                      <b>{flightReport.drone.anacNumber}</b>{" "}
                                    </ListItem>
                                  )}
                                </List>
                              </Details>
                              <RealReleasersInfoTable
                                flightData={flightReport.flightData}
                                terms={internationalizationTerms}
                                unitSystem={unitSystem}
                              />
                            </Column>
                          </Row>
                        </Content>
                      </Table>
                    </Column>
                  </Row>
                </>
              ))}
            </>
          ) : flightReports === undefined ? (
            <Loading>
              <CircularProgress />
            </Loading>
          ) : (
            <div>Error.</div>
          )}
        </Content>
        <Footer breakPage>
          <Row noPadding>
            <Column width={100} colSpan={2} center>
              <Divider />
            </Column>
          </Row>
        </Footer>
      </ReportPage>
    </>
  );
};

function PlannedReleasersInfoTable(props: {
  flightData: ReleaserInfo;
  unitSystem: UnitSystem;
  terms: {
    termInput: string;
    termSerialNumber: string;
    termBandwidth: string;
    termReleasing: string;
    termPlannedSpeed: string;
    termReleaseRate: string;
    termCalibrationFactor: string;
    termDrone: string;
  };
}): JSX.Element {
  const releaserSerialNumbers: string[] = Object.keys(props.flightData);
  let allReleasersTypes: ReleaserType[] = [];
  for (let releaserSerialNumber of releaserSerialNumbers) {
    allReleasersTypes.push(props.flightData[releaserSerialNumber].releaserType);
  }
  const uniqueReleasersTypes = [...new Set(allReleasersTypes)];
  const terms = props.terms;
  let tables: JSX.Element[] = [];
  for (let releaserType of uniqueReleasersTypes) {
    tables.push(
      <Table key={`flight-report-planned-releasers-info-tablee-${releaserType}`}>
        <Header>
          <PlannedReleaserInfoHeader
            releaserType={releaserType}
            terms={terms}
            unitSystem={props.unitSystem}
          />
        </Header>
        <Content striped>
          <PlannedReleaserInfoRows
            flightData={props.flightData}
            releaserType={releaserType}
            terms={terms}
            unitSystem={props.unitSystem}
          />
        </Content>
      </Table>
    );
  }

  return <>{tables.map((table) => table)}</>;
}

function PlannedReleaserInfoHeader(props: {
  releaserType: ReleaserType;
  terms: {
    termInput: string;
    termSerialNumber: string;
    termBandwidth: string;
    termReleasing: string;
    termPlannedSpeed: string;
    termReleaseRate: string;
    termCalibrationFactor: string;
    termDrone: string;
  };
  unitSystem: UnitSystem;
}): JSX.Element {
  const terms = props.terms;
  return (
    <>
      <Row noPadding>
        <HeadColumn width={10}>
          {terms.termReleasing}
          <small></small>
        </HeadColumn>
        <HeadColumn width={12}>
          {terms.termInput}
          <small></small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termSerialNumber}
          <small></small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termBandwidth}
          <small>{`(${UnitConversionHelper.distanceUnit(props.unitSystem)})`}</small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termPlannedSpeed}
          <small>{`(${UnitConversionHelper.speedUnit(props.unitSystem)})`}</small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termReleaseRate}
          <small>
            {`(${
              props.releaserType === ReleaserType.bioBOT ||
              props.releaserType == ReleaserType.bioMITE
                ? UnitConversionHelper.volumePerSpaceUnit(props.unitSystem)
                : UnitConversionHelper.cupPerSpaceUnit(props.unitSystem)
            })`}
          </small>
        </HeadColumn>
        {props.releaserType !== ReleaserType.bioCOTe && (
          <HeadColumn width={15}>{terms.termCalibrationFactor}</HeadColumn>
        )}
      </Row>
    </>
  );
}

function PlannedReleaserInfoRows(props: {
  flightData: ReleaserInfo;
  releaserType: ReleaserType;
  terms: {
    termDrone: string;
  };
  unitSystem: UnitSystem;
}): JSX.Element {
  const terms = props.terms;
  const releaserTypeName = (releaserType: ReleaserType) =>
    releaserType === ReleaserType.bioBOT
      ? "bioBOT"
      : releaserType === ReleaserType.bioCOTe
      ? "bioCOTe"
      : "bioMITe";
  const releaserSerialNumbers: string[] = Object.keys(props.flightData);
  let rows: JSX.Element[] = [];
  for (let releaserSerialNumber of releaserSerialNumbers) {
    const releaserInfo = props.flightData[releaserSerialNumber];
    if (releaserInfo.releaserType !== props.releaserType) {
      continue;
    }
    rows.push(
      <Row key={`flight-report-releaser-info-row-${releaserSerialNumber}`} noPadding>
        <Column left width={10}>
          <Tooltip title={releaserTypeName(releaserInfo.releaserType)}>
            <Image src={iconDrone} alt={terms.termDrone} iconXS />
          </Tooltip>
        </Column>
        <Column left width={15}>
          {releaserInfo.real.input}
        </Column>
        <Column left width={15}>
          {releaserSerialNumber}
        </Column>
        <Column left width={15}>
          {UnitConversionHelper.distanceValue(releaserInfo.planned.trackWidth, props.unitSystem)}
        </Column>
        <Column left width={15}>
          {UnitConversionHelper.speedValue(releaserInfo.planned.speed, props.unitSystem)}
        </Column>
        <Column left width={15}>
          {releaserInfo.releaserType === ReleaserType.bioCOTe
            ? UnitConversionHelper.volumePerSpaceValue(
                releaserInfo.planned.releaseRate,
                props.unitSystem
              )
            : UnitConversionHelper.cupPerSpaceValue(
                releaserInfo.planned.releaseRate,
                props.unitSystem
              )}
        </Column>
        {props.releaserType !== ReleaserType.bioCOTe && (
          <Column left width={25}>
            {releaserInfo.planned.calibrationFactor !== undefined
              ? releaserInfo.planned.calibrationFactor.toFixed(3)
              : ""}
          </Column>
        )}
      </Row>
    );
  }

  return <>{rows.map((row) => row)}</>;
}

function RealReleasersInfoTable(props: {
  flightData: ReleaserInfo;
  unitSystem: UnitSystem;
  terms: {
    termInput: string;
    termSerialNumber: string;
    termBandwidth: string;
    termReleasing: string;
    termPlannedSpeed: string;
    termReleaseRate: string;
    termCalibrationFactor: string;
    termDrone: string;
    termFlightTime: string;
    termAverageReleaseRate: string;
    termAverageSpeed: string;
    termTotalReleased: string;
    termTotalArea: string;
  };
}): JSX.Element {
  const releaserSerialNumbers: string[] = Object.keys(props.flightData);
  let allReleasersTypes: ReleaserType[] = [];
  for (let releaserSerialNumber of releaserSerialNumbers) {
    allReleasersTypes.push(props.flightData[releaserSerialNumber].releaserType);
  }
  const uniqueReleasersTypes = [...new Set(allReleasersTypes)];
  const terms = props.terms;
  let tables: JSX.Element[] = [];
  for (let releaserType of uniqueReleasersTypes) {
    tables.push(
      <Table key={`flight-report-info-table-${releaserType}`}>
        <Header>
          <RealReleaserInfoHeader
            releaserType={releaserType}
            terms={terms}
            unitSystem={props.unitSystem}
          />
        </Header>
        <Content striped>
          <RealReleaserInfoRows
            flightData={props.flightData}
            releaserType={releaserType}
            terms={terms}
            unitSystem={props.unitSystem}
          />
        </Content>
      </Table>
    );
  }

  return <>{tables.map((table) => table)}</>;
}

function RealReleaserInfoHeader(props: {
  releaserType: ReleaserType;
  terms: {
    termReleasing: string;
    termFlightTime: string;
    termAverageReleaseRate: string;
    termAverageSpeed: string;
    termTotalReleased: string;
    termTotalArea: string;
  };
  unitSystem: UnitSystem;
}): JSX.Element {
  const terms = props.terms;
  return (
    <>
      <Row noPadding>
        <HeadColumn width={10}>
          {terms.termReleasing}
          <small></small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termFlightTime}
          <small>(00h:00min:00s)</small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termAverageReleaseRate}
          <small>
            {`(${
              props.releaserType === ReleaserType.bioBOT ||
              props.releaserType == ReleaserType.bioMITE
                ? UnitConversionHelper.volumePerTimeUnit(props.unitSystem)
                : UnitConversionHelper.cupPerTimeUnit(props.unitSystem)
            })`}
          </small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termAverageReleaseRate}
          <small>
            {`(${
              props.releaserType === ReleaserType.bioBOT ||
              props.releaserType === ReleaserType.bioMITE
                ? UnitConversionHelper.volumePerSpaceUnit(props.unitSystem)
                : UnitConversionHelper.cupPerSpaceUnit(props.unitSystem)
            })`}
          </small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termAverageSpeed}
          <small>{`(${UnitConversionHelper.speedUnit(props.unitSystem)})`}</small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termTotalReleased}
          <small>
            {`(${
              props.releaserType === ReleaserType.bioBOT ||
              props.releaserType === ReleaserType.bioMITE
                ? UnitConversionHelper.volumeUnit(props.unitSystem)
                : UnitConversionHelper.cupUnit(props.unitSystem)
            })`}
          </small>
        </HeadColumn>
        <HeadColumn width={15}>
          {terms.termTotalArea}
          <small>{`(${UnitConversionHelper.areaUnit(props.unitSystem)})`}</small>
        </HeadColumn>
      </Row>
    </>
  );
}

function RealReleaserInfoRows(props: {
  flightData: ReleaserInfo;
  releaserType: ReleaserType;
  terms: { termDrone: string };
  unitSystem: UnitSystem;
}): JSX.Element {
  const terms = props.terms;
  const releaserTypeName = (releaserType: ReleaserType) =>
    releaserType === ReleaserType.bioBOT
      ? "bioBOT"
      : releaserType === ReleaserType.bioCOTe
      ? "bioCOTe"
      : "bioMITe";
  const releaserSerialNumbers: string[] = Object.keys(props.flightData);
  let rows: JSX.Element[] = [];
  for (let releaserSerialNumber of releaserSerialNumbers) {
    const releaserInfo = props.flightData[releaserSerialNumber];
    if (releaserInfo.releaserType !== props.releaserType) {
      continue;
    }
    rows.push(
      <Row key={`flight-report-info-row-${releaserSerialNumber}`} noPadding>
        <Column left width={10}>
          <Tooltip title={releaserTypeName(releaserInfo.releaserType)}>
            <Image src={iconDrone} alt={terms.termDrone} iconXS />
          </Tooltip>
        </Column>
        <Column left width={15}>
          {toHHMMSS(releaserInfo.real.duration)}
        </Column>
        <Column left width={15}>
          {releaserInfo.releaserType === ReleaserType.bioCOTe
            ? UnitConversionHelper.volumePerTimeValue(
                releaserInfo.real.averageReleaseRateMinutes,
                props.unitSystem
              )
            : UnitConversionHelper.cupPerTimeValue(
                releaserInfo.real.averageReleaseRateMinutes,
                props.unitSystem
              )}
        </Column>
        <Column left width={15}>
          {releaserInfo.releaserType === ReleaserType.bioCOTe
            ? UnitConversionHelper.volumePerSpaceValue(
                releaserInfo.real.averageReleaseRateHectare,
                props.unitSystem
              )
            : UnitConversionHelper.cupPerSpaceValue(
                releaserInfo.real.averageReleaseRateHectare,
                props.unitSystem
              )}
        </Column>
        <Column left width={15}>
          {UnitConversionHelper.speedValue(releaserInfo.real.averageSpeed, props.unitSystem)}
        </Column>
        <Column left width={15}>
          {releaserInfo.releaserType === ReleaserType.bioCOTe
            ? UnitConversionHelper.volumeValue(releaserInfo.real.totalReleased, props.unitSystem)
            : UnitConversionHelper.cupValue(releaserInfo.real.totalReleased, props.unitSystem)}
        </Column>
        <Column left width={15}>
          {UnitConversionHelper.areaValue(releaserInfo.real.totalAreaOverflown, props.unitSystem)}
        </Column>
      </Row>
    );
  }

  return <>{rows.map((row) => row)}</>;
}
