import React from "react";
import { useIntl } from "react-intl";
import { MapPage, Content, Row, Column, Item, Block, Map } from "../styles";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();

  //terms
  const termCompany = intl.formatMessage({ id: "report.company" });
  const termMapRecord = intl.formatMessage({ id: "report.mapRecord" });
  const termContractor = intl.formatMessage({ id: "report.contractor" });
  const termProperty = intl.formatMessage({ id: "report.property" });
  const termLocalization = intl.formatMessage({ id: "report.localization" });
  const termCounty = intl.formatMessage({ id: "report.county" });
  const termUF = intl.formatMessage({ id: "report.uf" });
  const termDoc = intl.formatMessage({ id: "report.doc" });
  const termServiceType = intl.formatMessage({ id: "report.serviceType" });
  const termProduct = intl.formatMessage({ id: "report.product" });
  const termFormulation = intl.formatMessage({ id: "report.formulation" });
  const termDosage = intl.formatMessage({ id: "report.dosage" });
  const termToxicClass = intl.formatMessage({ id: "report.toxicClass" });
  const termAdjuvant = intl.formatMessage({ id: "report.adjuvant" });
  const termCulture = intl.formatMessage({ id: "report.culture" });
  const termArea = intl.formatMessage({ id: "report.area" });
  const termOthers = intl.formatMessage({ id: "report.others" });
  const termAgronomicalRecipe = intl.formatMessage({ id: "report.agronomicalRecipe" });
  const termIssuedOn = intl.formatMessage({ id: "report.issuedOn" });
  const termBasicParameter = intl.formatMessage({ id: "report.basicParameter" });
  const termMaxTemperature = intl.formatMessage({ id: "report.maxTemperature" });
  const termRelativeUnitMin = intl.formatMessage({ id: "report.relativeUnitMin" });
  const termEquipment = intl.formatMessage({ id: "report.equipment" });
  const termModel = intl.formatMessage({ id: "report.model" });
  const termType = intl.formatMessage({ id: "report.type" });
  const termAngle = intl.formatMessage({ id: "report.angle" });
  const termVolume = intl.formatMessage({ id: "report.volume" });
  const termFlightHeight = intl.formatMessage({ id: "report.flightHeight" });
  const termBandwidth = intl.formatMessage({ id: "report.bandwidth" });
  const termDate = intl.formatMessage({ id: "report.date" });
  const termSignatureCREA = intl.formatMessage({ id: "report.signatureCREA" });
  const termWeatherConditions = intl.formatMessage({ id: "report.weatherConditions" });
  const termMorning = intl.formatMessage({ id: "report.morning" });
  const termEvening = intl.formatMessage({ id: "report.evening" });
  const termApplicationStart = intl.formatMessage({ id: "report.applicationStart" });
  const termInitial = intl.formatMessage({ id: "report.initial" });
  const termFinal = intl.formatMessage({ id: "report.final" });
  const termTemperature = intl.formatMessage({ id: "report.temperature" });
  const termRelativeHumidity = intl.formatMessage({ id: "report.relativeHumidity" });
  const termAircraftPrefixe = intl.formatMessage({ id: "report.aircraftPrefixe" });
  const termDGPSUse = intl.formatMessage({ id: "report.dgpsUse" });
  const termWindSpeed = intl.formatMessage({ id: "report.windSpeed" });
  const termTrackCoordinates = intl.formatMessage({ id: "report.trackCoordinates" });
  const termObservation = intl.formatMessage({ id: "report.observation" });
  const termSignatureEng = intl.formatMessage({ id: "report.signatureEng" });

  return (
    <>
      <MapPage>
        <Content>
          <Row noPadding>
            <Column rowSpan={5} width={20} left>
              <Block height={200}>
                <Item>{termCompany} NCB Sistemas Embarcados EIRELI - EPP</Item>
                <Item>{termMapRecord} NA</Item>
              </Block>
            </Column>
            <Column left colSpan={6} width={80}>
              <Item>{termContractor}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column left colSpan={6} width={80}>
              <Item>{termProperty}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column left colSpan={6} width={80}>
              <Item>{termLocalization} São Paulo</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column left colSpan={4} width={55}>
              <Item>{termCounty}</Item>
            </Column>
            <Column left colSpan={2} width={25}>
              <Item>{termUF}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column left colSpan={6} width={80}>
              <Item>{termDoc}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20}>
              <Item>
                <b>{termServiceType}</b>
              </Item>
            </Column>
            <Column width={15}>
              <Item>{termProduct}</Item>
            </Column>
            <Column width={10}>
              <Item>{termFormulation}</Item>
            </Column>
            <Column width={15}>
              <Item>{termDosage}</Item>
            </Column>
            <Column width={15}>
              <Item>{termToxicClass}</Item>
            </Column>
            <Column width={15}>
              <Item>{termAdjuvant}</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termCulture}</Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termArea} (ha):</Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termVolume}</Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={15}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termOthers}</Item>
            </Column>
            <Column width={45} colSpan={3} left>
              <Item>{termAgronomicalRecipe}</Item>
            </Column>
            <Column width={30} colSpan={2} left>
              <Item>{termIssuedOn}</Item>
            </Column>
            <Column width={5}>
              <Item></Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20}>
              <Item>
                <b>{termBasicParameter}</b>
              </Item>
            </Column>
            <Column rowSpan={10} colSpan={6} width={80}>
              <Item>
                <Map
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d468258.9245399613!2d-46.44111363199002!3d-23.522686970071327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc4d7fc6e7dbe1%3A0xafef88839defabc7!2sNCB%20Sistemas%20Embarcados!5e0!3m2!1spt-BR!2sbr!4v1621554104106!5m2!1spt-BR!2sbr"
                  loading="lazy"
                  area
                />
              </Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termMaxTemperature}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termRelativeUnitMin}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termEquipment}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termModel}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termType}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termAngle}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termFlightHeight}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termBandwidth}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Block height={80}>
                <Item>{termDate}</Item>
                <Item>{termSignatureCREA}</Item>
              </Block>
            </Column>
          </Row>
          <Row noPadding gray>
            <Column width={100} colSpan={7}>
              <Item>
                <b>{termWeatherConditions}</b>
              </Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column rowSpan={2} width={20} left>
              <Item>{termDate}: </Item>
            </Column>
            <Column width={20} colSpan={2}>
              <Item>{termMorning}</Item>
            </Column>
            <Column width={20} colSpan={2}>
              <Item>{termEvening}</Item>
            </Column>
            <Column width={40} rowSpan={2} colSpan={2} left>
              <Item>{termApplicationStart}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={10}>
              <Item>{termInitial}</Item>
            </Column>
            <Column width={10}>
              <Item>{termFinal}</Item>
            </Column>
            <Column width={10}>
              <Item>{termInitial}</Item>
            </Column>
            <Column width={10}>
              <Item>{termFinal}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termTemperature} ºC</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={40} colSpan={2} left>
              <Item>{termApplicationStart}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termRelativeHumidity} %</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={5} left>
              <Item>{termAircraftPrefixe}</Item>
            </Column>
            <Column width={35} left>
              <Item>{termDGPSUse}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termWindSpeed} - km/h</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column colSpan={2} width={40} left>
              <Item>{termTrackCoordinates}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column colSpan={7} width={100}>
              <Item></Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column rowSpan={2} width={20} left>
              <Item>{termDate}</Item>
            </Column>
            <Column colSpan={2} width={20}>
              <Item>{termMorning}</Item>
            </Column>
            <Column colSpan={2} width={20}>
              <Item>{termEvening}</Item>
            </Column>
            <Column rowSpan={2} colSpan={2} width={40} left>
              <Item>{termApplicationStart}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={10}>
              <Item>{termInitial}</Item>
            </Column>
            <Column width={10}>
              <Item>{termFinal}</Item>
            </Column>
            <Column width={10}>
              <Item>{termInitial}</Item>
            </Column>
            <Column width={10}>
              <Item>{termFinal}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termTemperature} ºC</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={40} colSpan={2} left>
              <Item>{termApplicationStart}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termRelativeHumidity} %</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={5} left>
              <Item>{termAircraftPrefixe}</Item>
            </Column>
            <Column width={35} left>
              <Item>{termDGPSUse}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termWindSpeed} - km/h</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={40} colSpan={2} left>
              <Item>{termTrackCoordinates}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={100} colSpan={7} left>
              <Item>{termObservation}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={100} colSpan={7} left>
              <Block height={120}>
                <Item>{termDate}</Item>
              </Block>
            </Column>
          </Row>
          <Row noPadding>
            <Column rowSpan={2} width={20} left>
              <Item>{termDate}</Item>
            </Column>
            <Column colSpan={2} width={20}>
              <Item>{termMorning}</Item>
            </Column>
            <Column colSpan={2} width={20}>
              <Item>{termEvening}</Item>
            </Column>
            <Column rowSpan={2} colSpan={2} width={40} left>
              <Item>{termApplicationStart}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={10}>
              <Item>{termInitial}</Item>
            </Column>
            <Column width={10}>
              <Item>{termFinal}</Item>
            </Column>
            <Column width={10}>
              <Item>{termInitial}</Item>
            </Column>
            <Column width={10}>
              <Item>{termFinal}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termTemperature} ºC</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column colSpan={2} width={40} left>
              <Item>{termApplicationStart}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termRelativeHumidity} %</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={5} left>
              <Item>{termAircraftPrefixe}</Item>
            </Column>
            <Column width={35} left>
              <Item>{termDGPSUse}</Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>{termWindSpeed} - km/h</Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column width={10}>
              <Item></Item>
            </Column>
            <Column colSpan={2} width={40} left>
              <Item>{termTrackCoordinates}</Item>
            </Column>
          </Row>
          <Row noPadding gray>
            <Column colSpan={7} width={100}>
              <Item></Item>
            </Column>
          </Row>
          <Row noPadding>
            <Column width={20} left>
              <Item>Data:</Item>
            </Column>
            <Column colSpan={6} width={80}>
              <Item>{termSignatureEng}</Item>
            </Column>
          </Row>
        </Content>
      </MapPage>
    </>
  );
};
