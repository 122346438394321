import { Grid, Select, MenuItem, Switch, Box } from "@material-ui/core";
import { AreaConfig, Profile, ProfilePreferences, Role, UnitSystem } from "biohub-model";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { v4 as uuid } from "uuid";

import { Adjust, SettingsPage, Toggle } from "../../Settings/styles";

import AreaConfigEditor from "../../../components/Atomic/Inputs/AreaConfigEditor";

function stringToUnitSystem(unitSystem: string): UnitSystem | null {
  switch (unitSystem) {
    case "Imperial":
      return UnitSystem.imperial;
    case "Metric":
      return UnitSystem.metric;
    default:
      return null;
  }
}

function unitSystemToString(unitSystem: UnitSystem): string {
  switch (unitSystem) {
    case UnitSystem.imperial:
      return "Imperial";
    case UnitSystem.metric:
      return "Metric";
    default:
      return "";
  }
}

interface EditProfilePreferencesProps {
  profile: Profile;
  onChange: (newProfile: Partial<Profile>) => void;
  configScreenGridSizes?: Array<number>;

  validateValue: (valid: boolean) => void;
}

export default function EditProfilePreferences(props: EditProfilePreferencesProps) {
  const intl = useIntl();
  const { profile } = props;

  const [areaConfigObjectKey, setAreaConfigObjectKey] = React.useState<string>(uuid());

  //terms
  const termUnitSystem = intl.formatMessage({ id: "option.preferences.unitsystem" });
  const termMetricSystem = intl.formatMessage({ id: "option.preferences.metric" });
  const termImperialSystem = intl.formatMessage({ id: "option.preferences.imperial" });
  const termLanguage = intl.formatMessage({ id: "language" });
  const termPtBr = intl.formatMessage({ id: "language.pt" });
  const termEnglish = intl.formatMessage({ id: "language.en" });
  const termSpanish = intl.formatMessage({ id: "language.es" });
  const termFrench = intl.formatMessage({ id: "language.fr" });
  const termTwoFactorAuth = intl.formatMessage({ id: "option.preferences.twofactorauth" });

  const termReleaseEntireArea = intl.formatMessage({
    id: "option.preferences.mustReleaseEntireArea",
  });
  const termUseSexagimalCoordinates = intl.formatMessage({
    id: "option.preferences.sexagesimalcoordinates",
  });
  const termSeparatelyBatties = intl.formatMessage({
    id: "option.preferences.separatelybatteries",
  });
  const termSettings = intl.formatMessage({ id: "menu.settings" });

  const onUpdateProfilePreferences = (newPreferences: ProfilePreferences) => {
    if (profile.role !== Role.external) props.onChange({ preferences: newPreferences });
  };

  return (
    <>
      {profile.role !== Role.external && (
        <Adjust>
          <SettingsPage>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>{termSettings}</h2>
              </Grid>
              <Grid container item lg={12} xs={12} spacing={1}>
                <Grid container item lg={6} xs={12}>
                  {termUnitSystem}
                  <Grid container item xs={12}>
                    <Box height={5} />
                  </Grid>
                  <Select
                    labelId="unitSystem-label"
                    id={"unitSystem"}
                    value={unitSystemToString(profile.preferences.unitSystem)}
                    onChange={(e) => {
                      setAreaConfigObjectKey(uuid());
                      onUpdateProfilePreferences({
                        ...profile.preferences,
                        unitSystem: stringToUnitSystem(e.target.value as string) as UnitSystem,
                      });
                    }}
                    label={termUnitSystem}
                    name={"unitSystem"}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value={unitSystemToString(UnitSystem.metric)}>
                      {termMetricSystem}
                    </MenuItem>
                    <MenuItem value={unitSystemToString(UnitSystem.imperial)}>
                      {termImperialSystem}
                    </MenuItem>
                  </Select>
                </Grid>

                <Grid container item xs={12}>
                  <Box height={10} />
                </Grid>

                <Grid item lg={12} xs={12} alignItems={"center"}>
                  {termTwoFactorAuth}
                  <Switch
                    onChange={(name, value) => {
                      onUpdateProfilePreferences({
                        ...profile.preferences,
                        useTwoFactorAuthentication: value,
                      });
                    }}
                    checked={profile.preferences.useTwoFactorAuthentication}
                    name={"useTwoFactorAuthentication"}
                    onChangeCapture={(e) => {}}
                    color="primary"
                  />
                </Grid>

                <Grid item lg={12} xs={12} alignItems={"center"}>
                  {termUseSexagimalCoordinates}
                  <Switch
                    onChange={(name, value) => {
                      onUpdateProfilePreferences({
                        ...profile.preferences,
                        useSexagesimalCoordinates: value,
                      });
                    }}
                    checked={profile.preferences.useSexagesimalCoordinates}
                    name={"useSexagesimalCoordinates"}
                    onChangeCapture={() => {}}
                    color="primary"
                  />
                </Grid>
                <Grid item lg={12} xs={12} alignItems={"center"}>
                  {termReleaseEntireArea}
                  <Switch
                    onChange={(name, value) => {
                      onUpdateProfilePreferences({
                        ...profile.preferences,
                        mustReleaseEntireArea: value,
                      });
                    }}
                    checked={profile.preferences.mustReleaseEntireArea}
                    name={"mustReleaseEntireArea"}
                    onChangeCapture={() => {}}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Box height={profile.role !== undefined && profile.role <= Role.admin ? 15 : 0} />

            {profile.role !== undefined && profile.role <= Role.admin && (
              <Grid item spacing={1} lg={12} xs={12}>
                <Box height={20} />
                <AreaConfigEditor
                  colorScheme="primary"
                  gridScheme="spaced"
                  //key={areaConfigObjectKey}
                  areaConfig={profile.preferences.areaPreferences}
                  unitSystem={profile.preferences.unitSystem}
                  onChange={(propName, value) => {
                    const newAreaPreferences = profile.preferences.areaPreferences as any;
                    newAreaPreferences[propName] = value;
                    onUpdateProfilePreferences({
                      ...profile.preferences,
                      areaPreferences: newAreaPreferences as AreaConfig,
                    });
                  }}
                  validateValue={props.validateValue}
                  usingOnlineElevation={profile.preferences.areaPreferences.mustConsiderRelief}
                />

                <Grid xs={12}>
                  <Box height={15} />
                </Grid>
                <Grid item lg={12} xs={12} alignItems={"center"}>
                  {termSeparatelyBatties}
                  <Switch
                    onChange={(name, value) => {
                      onUpdateProfilePreferences({
                        ...profile.preferences,
                        showBatteriesSeparately: value,
                      });
                    }}
                    checked={profile.preferences.showBatteriesSeparately}
                    name={"showBatteriesSeparately"}
                    onChangeCapture={() => {}}
                    color="primary"
                  />
                </Grid>
              </Grid>
            )}
          </SettingsPage>
        </Adjust>
      )}
    </>
  );
}

// Previous implementation of the EditProfilePreferences
// export interface EditPreferencesProps {
//   intl: IntlShape;
//   calcelFunction: Function;
//   saveFunction: Function;
//   profilePreferences: ProfilePreferences;
// }

// type PrefencesFormState = {
//   intl: IntlShape;
//   unitSystem: string;
//   useTwoFactorAuthentication: boolean;
//   id: string;
//   updatingPreferences: ProfilePreferences;
//   languageCode: string;
//   useSexagesimalCoordinates: boolean;
//   coordinateDecimalPrecision: string;
//   showBatteriesSeparately: boolean;
//   saveFunction: Function;
//   cancelFunction: Function;
//   areaConfigObjetKey: string;
//   areaConfig: AreaConfig;
// };

// export default class PrefencesForm extends React.Component<
//   EditPreferencesProps,
//   PrefencesFormState
// > {
//   _extractProps(props: EditPreferencesProps): PrefencesFormState {
//     return {
//       updatingPreferences: props.profilePreferences,
//       id: props.profilePreferences.id,
//       intl: props.intl,
//       saveFunction: props.saveFunction,
//       cancelFunction: props.calcelFunction,
//       unitSystem: unitSystemToString(props.profilePreferences.unitSystem),
//       useTwoFactorAuthentication: props.profilePreferences.useTwoFactorAuthentication,
//       languageCode: props.profilePreferences.languageCode,
//       useSexagesimalCoordinates: props.profilePreferences.useSexagesimalCoordinates,
//       coordinateDecimalPrecision: props.profilePreferences.coordinateDecimalPrecision.toString(),
//       showBatteriesSeparately: props.profilePreferences.showBatteriesSeparately,
//       areaConfigObjetKey: uuid(),
//       areaConfig: props.profilePreferences.areaPreferences,
//     };
//   }

//   constructor(props: EditPreferencesProps) {
//     super(props);
//     this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
//     this.handleChange = this.handleChange.bind(this);
//     this._stateToProfilePreferences = this._stateToProfilePreferences.bind(this);
//     this.state = this._extractProps(props);
//   }

//   componentWillReceiveProps(newProps: EditPreferencesProps) {
//     this.setState(this._extractProps(newProps));
//   }

//   handleChange(event: React.ChangeEvent<any>) {
//     this.setState({
//       ...this.state,
//       [event.target.name]: event.target.value,
//       areaConfigObjetKey:
//         event.target.name === "unitSystem" ? uuid() : this.state.areaConfigObjetKey,
//     });
//   }

//   handleSwitch(name: string, value: boolean) {
//     this.setState({ ...this.state, [name]: value });
//   }

//   _stateToProfilePreferences(): { id: string } & Partial<ProfilePreferences> {
//     const form = this.state;
//     const updatingPreferences: ProfilePreferences = this.state.updatingPreferences;
//     const updatingUnitSystem: UnitSystem = stringToUnitSystem(form.unitSystem) as UnitSystem;
//     const updatingedDecimalPrecision: number = parseFloat(form.coordinateDecimalPrecision);
//     const updatedPreferences: { id: string } & Partial<ProfilePreferences> = {
//       id: updatingPreferences.id,
//       languageCode:
//         form.languageCode !== updatingPreferences.languageCode ? form.languageCode : undefined,
//       useSexagesimalCoordinates:
//         form.useSexagesimalCoordinates !== updatingPreferences.useSexagesimalCoordinates
//           ? form.useSexagesimalCoordinates
//           : undefined,
//       coordinateDecimalPrecision:
//         updatingedDecimalPrecision !== updatingPreferences.coordinateDecimalPrecision
//           ? updatingedDecimalPrecision
//           : undefined,
//       showBatteriesSeparately:
//         form.showBatteriesSeparately !== updatingPreferences.showBatteriesSeparately
//           ? form.showBatteriesSeparately
//           : undefined,
//       unitSystem:
//         updatingUnitSystem !== updatingPreferences.unitSystem ? updatingUnitSystem : undefined,
//       useTwoFactorAuthentication:
//         form.useTwoFactorAuthentication !== updatingPreferences.useTwoFactorAuthentication
//           ? form.useTwoFactorAuthentication
//           : undefined,
//       areaPreferences: this.state.areaConfig,
//     };

//     return updatedPreferences;
//   }

//   render() {
//     // terms
//     const intl = this.state.intl;
//     const termSettings = intl.formatMessage({ id: "action.settings" });
//     const termGeneralSettings = intl.formatMessage({ id: "option.preferences.generalinfo" });
//     const termUnitSystem = intl.formatMessage({ id: "option.preferences.unitsystem" });
//     const termMetricSystem = intl.formatMessage({ id: "option.preferences.metric" });
//     const termImperialSystem = intl.formatMessage({ id: "option.preferences.imperial" });
//     const termLanguage = intl.formatMessage({ id: "language" });
//     const termPtBr = intl.formatMessage({ id: "language.pt" });
//     const termEnglish = intl.formatMessage({ id: "language.en" });
//     const termSpanish = intl.formatMessage({ id: "language.es" });
//     const termFrench = intl.formatMessage({ id: "language.fr" });
//     const termTwoFactorAuth = intl.formatMessage({ id: "option.preferences.twofactorauth" });
//     const termUseSexagimalCoordinates = intl.formatMessage({
//       id: "option.preferences.sexagesimalcoordinates",
//     });
//     const termProject = intl.formatMessage({ id: "placeholder.project" });
//     const termAircraft = intl.formatMessage({ id: "placeholder.aircraft" });
//     const termSeparatelyBatties = intl.formatMessage({
//       id: "option.preferences.separatelybatteries",
//     });
//     const termCancel = intl.formatMessage({ id: "action.cancel" });
//     const termSave = intl.formatMessage({ id: "action.save" });
//     return (
//       <>
//         <Block elevation={1}>
//           <div style={{ maxWidth: 800 }}>
//             <Adjust>
//               <b>{termSettings}</b>
//               <SettingsPage>
//                 <Grid container spacing={1}>
//                   <Grid container item lg={10} xs={12} spacing={1}>
//                     <Grid item lg={12} xs={12}>
//                       <b>{termGeneralSettings}</b>
//                     </Grid>
//                     <Grid container item lg={6} xs={12}>
//                       {termUnitSystem}
//                       <Select
//                         labelId="unitSystem-label"
//                         id={"unitSystem"}
//                         value={this.state.unitSystem}
//                         onChange={this.handleChange}
//                         label={termUnitSystem}
//                         name={"unitSystem"}
//                         variant="outlined"
//                         fullWidth
//                       >
//                         <MenuItem value={unitSystemToString(UnitSystem.metric)}>
//                           {termMetricSystem}
//                         </MenuItem>
//                         <MenuItem value={unitSystemToString(UnitSystem.imperial)}>
//                           {termImperialSystem}
//                         </MenuItem>
//                       </Select>
//                     </Grid>

//                     <Grid container item lg={6} xs={12}>
//                       {termLanguage}
//                       <Select
//                         labelId="languageCode-label"
//                         id={"languageCode"}
//                         value={this.state.languageCode}
//                         onChange={this.handleChange}
//                         label={termLanguage}
//                         name={"languageCode"}
//                         variant="outlined"
//                         fullWidth
//                       >
//                         <MenuItem value={"pt-BR"}>{termPtBr}</MenuItem>
//                         <MenuItem value={"en"}>{termEnglish}</MenuItem>
//                         <MenuItem value={"es"}>{termSpanish}</MenuItem>
//                         <MenuItem value={"fr"}>{termFrench}</MenuItem>
//                       </Select>
//                     </Grid>

//                     <Grid item lg={6} xs={12} alignItems={"center"}>
//                       {termTwoFactorAuth}
//                       <Switch
//                         onChange={(e) =>
//                           this.handleSwitch(
//                             "useTwoFactorAuthentication",
//                             !this.state.useTwoFactorAuthentication
//                           )
//                         }
//                         checked={this.state.useTwoFactorAuthentication}
//                         name={"useTwoFactorAuthentication"}
//                         onChangeCapture={this.handleChange}
//                         color="primary"
//                       />
//                     </Grid>

//                     <Grid item lg={6} xs={12} alignItems={"center"}>
//                       {termUseSexagimalCoordinates}
//                       <Switch
//                         onChange={(e) =>
//                           this.handleSwitch(
//                             "useSexagesimalCoordinates",
//                             !this.state.useSexagesimalCoordinates
//                           )
//                         }
//                         checked={this.state.useSexagesimalCoordinates}
//                         name={"useSexagesimalCoordinates"}
//                         onChangeCapture={this.handleChange}
//                         color="primary"
//                       />
//                     </Grid>
//                   </Grid>
//                 </Grid>

//                 <Grid container spacing={1}>
//                   <Grid item lg={12} xs={12}>
//                     <b>{termProject}</b>
//                   </Grid>
//                   <Box height={20} />
//                   <AreaConfigEditor
//                     key={this.state.areaConfigObjetKey}
//                     initialConfig={this.state.areaConfig}
//                     unitSystem={stringToUnitSystem(this.state.unitSystem) as UnitSystem}
//                     onChange={(config: AreaConfig) => {
//                       this.setState({ areaConfig: config });
//                     }}
//                   />
//                   <Grid container lg={12} xs={12}>
//                     <Grid item lg={12} xs={12}>
//                       <b>{termAircraft}</b>
//                     </Grid>
//                     <Grid item lg={12} xs={12} alignItems={"center"}>
//                       {termSeparatelyBatties}
//                       <Switch
//                         onChange={(e) =>
//                           this.handleSwitch(
//                             "showBatteriesSeparately",
//                             !this.state.showBatteriesSeparately
//                           )
//                         }
//                         checked={this.state.showBatteriesSeparately}
//                         name={"showBatteriesSeparately"}
//                         onChangeCapture={this.handleChange}
//                         color="primary"
//                       />
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </SettingsPage>
//             </Adjust>
//           </div>
//           <Actions className="flip-card">
//             <Button
//               onClick={() => {
//                 this.state.cancelFunction();
//               }}
//             >
//               <Icon>arrow_back</Icon> {termCancel}
//             </Button>
//             <Button
//               onClick={() => {
//                 const updatedPreferences = this._stateToProfilePreferences();
//                 this.state.saveFunction(updatedPreferences);
//               }}
//               variant="contained"
//               color="primary"
//             >
//               {termSave}
//             </Button>
//           </Actions>
//         </Block>
//       </>
//     );
//   }
// }
