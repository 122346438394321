import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exportAsKml,
  onClickFlightInProjectTree,
  setFlightInvisible,
  setFlightVisible,
} from "../../store/actions/projectTreeActions";
import { SystemState } from "../../store/reducers/systemReducer";
import ElementsState, { ElementsStateText } from "./components/ElementsState";
import FlightInProjectTree from "./components/FlightInProjectTree";
import { ExportKmlTypes } from "../../services/KmklService";

type Props = {
  projectId: string;
  areaId: string;
};

export default function (props: Props): JSX.Element {
  const flightListState: ElementsStateText = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);

    if (area === undefined) return "empty";

    const flightList = area.flightList;
    if (area.isLoadingFlights) {
      if (flightList !== null) return "reloading";
      else return "loading";
    }

    if (flightList === null || flightList.length === 0) return "empty";

    return "regular";
  });

  const flightList = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const flights =
      projectTreeState.projectList
        ?.find((project) => project.id === props.projectId)
        ?.areas?.find((area) => area.id === props.areaId)?.flightList ?? [];

    return flights;
  });

  const viewingAreaPlanningId = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    const area = projectTreeState.projectList
      ?.find((project) => project.id === props.projectId)
      ?.areas?.find((area) => area.id === props.areaId);

    if (area === undefined) return null;

    return area.viewingFlightPlan?.id ?? area.planned.id;
  });

  const dispatch = useDispatch();

  return (
    <>
      {flightListState !== "regular" && (
        <ElementsState
          nodeId={`${props.projectId}-${props.areaId}`}
          state={flightListState}
          type={"flights"}
        />
      )}
      {flightList.map((flight) => (
        <FlightInProjectTree
          key={`${props.projectId}-${props.areaId}-${flight.id}-flight-in-project-tree`}
          projectId={props.projectId}
          areaId={props.areaId}
          flight={flight}
          viewingAreaPlanningId={viewingAreaPlanningId}
          visible={flight.visible}
          onClick={() => {
            dispatch(onClickFlightInProjectTree(props.projectId, props.areaId, flight.id));
          }}
          onVisibilityChanged={() => {
            if (flight.visible) {
              dispatch(setFlightInvisible(props.projectId, props.areaId, flight.id));
            } else {
              dispatch(setFlightVisible(props.projectId, props.areaId, flight.id));
            }
          }}
          isExportingAsKml={flight.isExportingAsKml}
          onExportFlightAsKml={() => {
            if (!flight.id) return;
            dispatch(
              exportAsKml(props.projectId, props.areaId, flight.id, [ExportKmlTypes.flight])
            );
          }}
        />
      ))}
    </>
  );
}
