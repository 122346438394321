import React from "react";
import { MemoMarker } from "../MemoMapComponents";
import { OverlayView } from "@react-google-maps/api";
import { WaypointDistanceLabel } from "../waypointDistances";
import { max } from "lodash";
import { Location } from "biohub-model";

type Props = {
  polygonId: string;
  waypointIndex: number;
  location: Location;
  showDistanceLabel: boolean;
  waypointHeight: number;
  formatHeight: (height: number) => string;
  usingOnlineElevation: boolean;
  homePointElevation: number;
  elevation?: number;
  markerZIndex: number;
  labelZIndex: number;
};

export default (props: Props): JSX.Element => {
  if (!props.showDistanceLabel) return <></>;

  const waypointHeight = props.waypointHeight;
  const waypointElevation = props.elevation;

  const formattedWaypointHeight = props.formatHeight(waypointHeight);
  const lines: string[] = [formattedWaypointHeight];

  if (props.usingOnlineElevation && waypointElevation !== undefined) {
    const formattedWaypointHeightConsideringElevation = props.formatHeight(
      waypointElevation - props.homePointElevation + waypointHeight
    );

    if (formattedWaypointHeight !== formattedWaypointHeightConsideringElevation) {
      lines.push(`(${formattedWaypointHeightConsideringElevation})`);
    }
  }

  const opacity = 0.95;

  const backgroundLabelHeight = 18 * lines.length;

  return (
    <>
      <MemoMarker
        key={`${props.polygonId}-waypoint-${props.waypointIndex}-height-background`}
        zIndex={props.markerZIndex}
        icon={distanceIcon(lines, 28 + backgroundLabelHeight, backgroundLabelHeight)}
        opacity={opacity}
        position={props.location}
        clickable
        onClick={() => {}}
      />
      <OverlayView
        position={props.location}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(offsetWidth, offsetHeight) => ({
          x: -(offsetWidth / 2),
          y: -(offsetHeight + backgroundLabelHeight) / 2 - 28,
        })}
        zIndex={props.labelZIndex}
      >
        <div
          style={{
            opacity: opacity,
            display: "flex",
            flexDirection: "column",
            color: "white",
            fontSize: "10px",
            fontWeight: 600,
            textAlign: "center",
            justifyContent: "center",
            verticalAlign: "middle",
          }}
        >
          <>
            {lines.map((line) => (
              <div
                style={{
                  fontFamily: "Arial",
                }}
              >
                {line}
              </div>
            ))}
          </>
        </div>
      </OverlayView>
    </>
  );
};

function distanceIcon(lines: string[], yAnchor: number, height: number): google.maps.Icon {
  const biggestLineLength = max(lines.map((line) => line.length)) ?? 0;

  const width = 10 + 6 * biggestLineLength;
  const labelOrigin = new google.maps.Point(width / 2, 9 * lines.length);
  const anchor = new google.maps.Point(width / 2, yAnchor);

  return {
    url: "/icon_marker_distance.png",
    scaledSize: new google.maps.Size(width, height),
    anchor: anchor,
    labelOrigin: labelOrigin,
  };
}
