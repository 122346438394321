import { Height } from "@material-ui/icons";
import styled from "styled-components";

type Props = {
  active: boolean;
  color?: "light";
  height?: number;
};

export const FlipCard = styled.div.attrs((props: Props) => ({
  color: props.color,
  height: props.height,
  className: "flip",
}))`
  background-color: transparent;
  width: 100%;
  height: 330px;
  border: 0;
  perspective: 1000px;

  @media screen and (max-width: 991px) {
    height: 360px;
  }

  @media screen and (max-width: 768px) {
    height: 368px;
  }

  @media screen and (max-width: 380px) {
    height: 395px;
  }

  ${(props) =>
    props.color === "light" &&
    `
    background: var(--white);
    height: 480px;
  `}

  ${(props) =>
    props.height !== undefined &&
    `
    height: ` +
      props.height +
      `px!important;

    @media screen and (max-width: 768px) {
      height: ` +
      (props.height + 30) +
      `px!important;
    }

    @media screen and (max-width: 420px) {
      height: ` +
      (props.height + 50) +
      `px!important;
    }

    @media screen and (max-width: 340px) {
      height: ` +
      (props.height + 90) +
      `px!important;
    }
  `}
`;

export const Inner = styled.div.attrs((props: Props) => ({
  active: props.active,
}))`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.65s;
  transform-style: preserve-3d;

  ${(props) =>
    props.active &&
    `
    transform: rotateY(180deg);
  `}
`;

export const Front = styled.div.attrs((props: Props) => ({
  color: props.color,
}))`
  background-color: var(--flip-front-background);
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
  > label {
    color: var(--flip-front-text) !important;
    font-size: 17px !important;
    font-family: "Gilmer" !important;
    &:first-of-type {
      font-size: 20px !important;
    }
  }

  .flip-card {
    min-height: 54px;
    a,
    button {
      font-weight: 100;
      font-family: "Galano Grotesque";
      font-size: 14px !important;
      color: var(--dashboard-secondary);
      cursor: pointer;
      .material-icons {
        font-size: 18px;
        margin-right: 5px;
        margin-top: 5px;
      }
      :hover,
      :focus {
        background: transparent;
        color: var(--dashboard-primary-bg);
      }
      &.MuiButton-containedPrimary {
        .MuiButton-label {
          color: var(--white);
        }
        :hover,
        :focus {
          .MuiButton-label {
            color: var(--dashboard-primary-bg);
          }
        }
      }
    }

    @media screen and (max-width: 680px) {
      flex-direction: column;
    }
  }

  ${(props) =>
    props.color === "light" &&
    `
    background: transparent;
    .flip-card {
      min-height:54px;
      a, button {
        font-weight: 100;
        font-family: 'Galano Grotesque';
        font-size: 14px!important;
        color: var(--dashboard-secondary);
        cursor: pointer;
        .material-icons {
          font-size: 18px;
          margin-right: 5px;
          margin-top: 5px;
        }
        :hover, :focus {
          background: transparent;
          color: var(--dashboard-primary-bg);
        }
        &.MuiButton-containedPrimary {
          .MuiButton-label {
            color: var(--white);
          }
          :hover, :focus {
            .MuiButton-label { 
              color: var(--dashboard-primary-bg);
            }
          }
        }
      }

      @media screen and (max-width: 680px) {
        flex-direction: column;
      }
    }
  `}
`;

export const Back = styled.div.attrs((props: Props) => ({
  color: props.color,
  className: "back-flipcard",
}))`
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
  background-color: var(--flip-back-background);
  padding: 3rem;
  border-radius: 15px;
  transform: rotateY(180deg);
  > label {
    color: var(--flip-back-text) !important;
    line-height: 1.1 !important;
    font-size: 16px !important;
    &:nth-of-type(1) {
      letter-spacing: 2px;
      margin: 0 0 1rem;
      font-weight: 600 !important;
    }
  }

  fieldset {
    border-radius: 25px;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  @media screen and (max-width: 680px) {
    padding: 0;
  }

  ${(props) =>
    props.color === "light" &&
    `
    background: transparent;
    padding: 0;
    .flip-card {
      button {
        border-radius: 25px;
        padding-bottom: 10px;
        min-width: 120px;
        background: var(--dashboard-secondary);
        box-shadow: none;
        .material-icons {
          font-size: 18px;
          margin: 6px 5px 0 0;
        }
        &:nth-of-type(1) {
          background: transparent;
          color: var(--dashboard-secondary);
        }
      }
    }
    
  `}
`;
