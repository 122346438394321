import React from "react";
import { Grid } from "@material-ui/core";
import Tag from "../../BasicComponents/Tag";
import Text from "../../BasicComponents/Text";
import Image from "../../BasicComponents/Image";
import Information from "../TextLearnMore";
import Container from "../../Areas/Container";
import Row from "../../Areas/Row";
import { SectionProduct } from "./styles";

interface Props {
  title?: string;
  sectionColor?: "primary";
  subtitle?: string;
  description?: string;
  highlight?: string;
  image?: string;
  direction?: string;
  id?: string;
  mark?: boolean;
  extraDetails?: Array<{
    id: string;
    detail: string;
    image: string | "url";
  }>;
}

export default (props: Props) => {
  return (
    <>
      <SectionProduct sectionColor={props.sectionColor} id={props.id}>
        <Container>
          {props.title && (
            <Text
              text={props.title}
              appearance={"title-semibold"}
              textAlign={"center"}
              color={"default"}
              sectionTitleMark={props.mark}
            />
          )}
          {props.subtitle && (
            <Tag
              text={props.subtitle}
              appearance={"subtitle-semibold"}
              textAlign={"center"}
              color={"primary"}
            />
          )}
          <Row direction={props.direction}>
            <Information
              width={"100"}
              showButtons={false}
              textAlign={"center"}
              subtitle={props.subtitle}
              description={props.description}
              color={props.sectionColor}
            />
            <div className="center">
              <Image src={props.image} alt={props.subtitle} className="alignImages" />
            </div>
          </Row>

          <Row direction={"column"}>
            {props.highlight && (
              <Tag
                text={props.highlight}
                appearance={"subtitle-semibold"}
                textAlign={"center"}
                color={"primary"}
              />
            )}

            <Grid item lg={12} md={12} sm={12} className="detailsProduct">
              {props.extraDetails &&
                props.extraDetails.map((item) => (
                  <Grid key={item.id} item lg={3} md={3} sm={3} className="alignImages">
                    <Image src={item.image} />
                    <Text text={item.detail} textAlign={"center"} />
                  </Grid>
                ))}
            </Grid>
          </Row>
        </Container>
      </SectionProduct>
    </>
  );
};
