import styled from "styled-components";
import Input from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const Form = styled.form.attrs(() => ({}))`
  /* padding: 3rem 0; */
  padding-bottom: 3rem;

  .cardForm-container {
    display: flex;
    flex-direction: column;

    > label {
      margin-bottom: 1rem;
      font-size: 26px !important;
    }
  }

  .companyForm-spacing {
    &:first-child {
      max-width: calc(50% - 20px);
      margin-right: 20px;
    }
  }

  .companyForm-select {
    label {
      margin-bottom: 1rem;
    }

    > div {
      > div {
        border-radius: 20px;

        .MuiOutlinedInput-input {
          padding: 10px;
        }
      }

      svg {
        color: var(--primary);
      }

      fieldset {
        border: 2px solid var(--primary);
      }
    }
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid var(--primary);
  }

  @media (max-width: 600px) {
    .companyForm-select {
      & + div {
        margin-top: 1rem;
      }

      &:nth-child(2) label {
        margin-bottom: 0;
      }
    }

    .companyForm-spacing:first-child {
      max-width: 100%;
      margin-right: 0px;
    }
  }
`;

export const CardForm = styled.div.attrs(() => ({}))`
  flex-grow: 1;
  width: 100%;
  border: 2px solid var(--primary);
  border-top: none;
  border-radius: 10px;
  background: var(--white);

  > label {
    margin-bottom: 2rem;
  }
`;

export const TitleCardForm = styled.div.attrs(() => ({}))`
  width: 100%;
  padding: 1rem;
  color: var(--white);
  background: var(--primary);

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const InputContainer = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;

  label {
    font-size: 16px !important;
  }

  div {
    /* &:first-child {
      flex: 1;
    } */

    /* &:nth-child(2) {
      > div {
        width: 100%;
      }
    } */

    &.companyForm-complement {
      > div:nth-child(2) {
        margin-top: 1rem;
      }
    }

    button {
      min-width: unset;
      padding: 0 5px;

      img,
      svg {
        width: 18px;
      }
    }

    &[role="radiogroup"] {
      flex-direction: unset !important;
    }
  }
`;

export const PhoneInput = styled(Input).attrs(() => ({}))`
  width: 100%;
  padding: 6px 0 7px;
  border: 0;
  border-bottom: 2px solid var(--primary);
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  input {
    border: 0;
  }

  ${({ error }) =>
    error &&
    `
    border-bottom: 2px solid var(--dashboard-danger);
  `}
`;
