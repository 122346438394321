import styled from "styled-components";
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type Props = {
  color?: "primary";
};

export const Carousel = styled(Slider).attrs((props: Props) => ({
  color: props.color,
}))`
  margin: 2rem 0;

  .react-multiple-carousel__arrow {
    background: transparent;
    border: 2px solid var(--primary);
    &::before {
      color: var(--primary);
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multi-carousel-track {
    padding-bottom: 40px;
  }

  .react-multi-carousel-dot button {
    border-color: transparent;
    background: var(--dark-gray);
  }

  .react-multi-carousel-dot--active button {
    background: var(--primary);
  }

  @media screen and (max-width: 1024px) {
    .react-multi-carousel-item {
      padding: 0;
    }
  }
`;

export const ButtonGroupCarousel = styled.div.attrs((props: Props) => ({}))`
  button {
    min-width: unset;
    width: 50px;
    height: 50px;
    padding: 0;
    top: calc(50% - 40px);
    position: absolute;
    border-radius: 50%;
    border: 1px solid var(--primary);

    &:first-child {
      left: calc(-5% - 2px);
    }

    &:last-child {
      right: calc(-5% - 2px);
    }

    svg {
      font-size: 2rem;
      color: var(--primary);
    }
  }

  @media screen and (max-width: 1480px) {
    button {
      &:first-child {
        left: calc(-1% - 2px);
      }

      &:last-child {
        right: calc(-1% - 2px);
      }
    }
  }

  @media screen and (max-width: 600px) {
    button {
      &:first-child {
        left: calc(-1% + 10px);
      }

      &:last-child {
        right: calc(-1% + 10px);
      }
    }
  }
`;

export const Item = styled.div.attrs((props: Props) => ({}))`
  display: flex;
  align-items: center;
  margin: 0 15px;

  img {
    width: 70px;
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Description = styled.span.attrs((props: Props) => ({}))`
  label {
    &:last-child {
      margin: 5px 0;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 70%;
    display: block;
  }
`;
