import { Location, UnitSystem, Waypoint } from "biohub-model";
import React from "react";
import { DistanceMarker } from "./markers/DistanceMarker";
import { midpointLabels } from "./waypointDistances";
import WaypointMarker from "./WaypointMarker";

type Props = {
  polygonId: string;
  waypoints: Waypoint[];
  selectedWaypointIndexes: number[];
  waypointsElevation?: number[];
  homePointElevation?: number;
  formatHeight: (height: number) => string;
  editing: boolean;
  showDistanceLabels?: boolean;
  waypointScale?: number;
  onClickWaypoint?: (waypointIndex: number) => void;
  activeLiberations?: boolean[];
  waypointsWithActions?: boolean[];
  unitSystem: UnitSystem;
  onWaypointMoved?: (waypointIndex: number, newLocation: Location) => void;
  showWaypointDistances?: false;
  usingOnlineElevation: boolean;
  waypointsDistanceMarkerZIndex: number;
  waypointsDistanceLabelZIndex: number;
  waypointOrientationMarkerZIndex: number;
  waypointDropMarkerZIndex: number;
  waypointElevationMarkerZIndex: number;
  waypointElevationLabelZIndex: number;
};

export default function (props: Props): JSX.Element {
  const sizeMultiplier = props.waypointScale ?? 1;
  const showDistanceLabel = props.showDistanceLabels ?? true;
  return (
    <>
      {showDistanceLabel &&
        props.showWaypointDistances === undefined &&
        midpointLabels(
          props.waypoints.map((waypoint) => waypoint.location),
          props.unitSystem
        ).map((distanceLegend, waypointIndex) => (
          <DistanceMarker
            key={`waypoint-markers-${props.polygonId}-distance-${waypointIndex}`}
            polygonId={props.polygonId}
            distanceLegend={distanceLegend}
            waypointIndex={waypointIndex}
            yAnchor={-2}
            markerZIndex={props.waypointsDistanceMarkerZIndex}
            labelZIndex={props.waypointsDistanceLabelZIndex}
          />
        ))}
      {props.waypoints.map((waypoint, index) => (
        <WaypointMarker
          key={`waypoints-markers-${props.polygonId}-marker-${index}`}
          polygonId={props.polygonId}
          waypointScale={props.waypointScale}
          showDistanceLabels={props.showDistanceLabels}
          waypointIndex={index}
          waypoint={waypoint}
          isSelected={props.selectedWaypointIndexes.includes(index)}
          isActiveLiberation={
            props.activeLiberations === undefined ? true : props.activeLiberations[index]
          }
          hasReleaserAction={
            props.waypointsWithActions === undefined ? true : props.waypointsWithActions[index]
          }
          homePointElevation={props.homePointElevation ?? 0}
          elevation={
            props.waypointsElevation !== undefined && props.waypointsElevation.length > index
              ? props.waypointsElevation[index]
              : undefined
          }
          editing={props.editing}
          formatHeight={props.formatHeight}
          onClick={() => props.onClickWaypoint?.(index)}
          onMoved={(location) => props.onWaypointMoved?.(index, location)}
          usingOnlineElevation={props.usingOnlineElevation}
          orientationMarkerZIndex={props.waypointOrientationMarkerZIndex}
          dropMarkerZIndex={props.waypointDropMarkerZIndex}
          elevationMarkerZIndex={props.waypointElevationMarkerZIndex}
          elevationLabelZIndex={props.waypointElevationLabelZIndex}
        />
      ))}
    </>
  );
}
