import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Icon } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Text from "../../../components/Atomic/BasicComponents/Text";
import { Block, Questions } from "../styles";

interface Props {
  notificationItens?: Array<{
    id: string;
    notificationType: string;
    text: string;
    link: string;
    description: string;
    hasRead: boolean;
  }>;
}

export default (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function itemType(value: string) {
    switch (value) {
      case "error":
        return "error";
      case "info":
        return "info";
      case "success":
        return "success";
      case "warning":
        return "warning";
      default:
        return "success";
    }
  }

  return (
    <>
      <Block elevation={1} className={"notifications"}>
        <Text color={"dark"} text={"Notificações"} appearance={"block-title"} />
        <Questions>
          {props.notificationItens?.map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<Icon>expand_more</Icon>}
                aria-controls={`${item.id}-content`}
                id={`${item.id}-header`}
              >
                <Alert
                  onClick={() => {
                    handleClose();
                  }}
                  severity={itemType(item.notificationType)}
                >
                  {item.hasRead ? item.text : <strong>{item.text}</strong>}
                </Alert>
              </AccordionSummary>
              <AccordionDetails>
                <Text color={"dark"} text={item.description} />
              </AccordionDetails>
            </Accordion>
          ))}
        </Questions>
      </Block>
    </>
  );
};
