import styled from "styled-components";

type Props = {
  color?: "light" | "primary" | "secondary" | "third" | "highlighted" | "dark" | "default";
  size?: "small" | "medium" | "large" | "x-large" | "x-small";
  textAlign?: "center" | "left" | "right";
  appearance?:
    | "title"
    | "title-bold"
    | "title-semibold"
    | "subtitle-semibold"
    | "info-bold"
    | "info-semibold"
    | "section-title"
    | "title-medium"
    | "info-title"
    | "description"
    | "description-block"
    | "info-subtitle"
    | "carousel-title"
    | "carousel-subtitle"
    | "carousel-description"
    | "carousel-title-mixed"
    | "description-banner"
    | "description-product"
    | "description-opinions"
    | "menu-title"
    | "price"
    | "info-name"
    | "info-role"
    | "info-value"
    | "info-description"
    | "info-required"
    | "block-title"
    | "small-title"
    | "small-description"
    | "details-description"
    | "cultivation-text";
  transform?: "lowercase" | "uppercase" | "capitalize";
  sectionTitleMark?: boolean;
};

export const Text = styled.label.attrs((props: Props) => ({
  color: props.color,
  size: props.size,
  appearance: props.appearance,
  textAlign: props.textAlign,
  transform: props.transform,
  sectionTitleMark: props.sectionTitleMark,
}))`
  display: block;
  white-space: pre-line;
  ::selection {
    color: var(--primary);
    background: var(--secondary);
  }
 

  ${(props: Props) =>
    props.color === "light" &&
    `
    color: var(--white);
    border-color: var(--white);
  `}

  ${(props: Props) =>
    props.color === "primary" &&
    `
    color: var(--primary);
  `}

  ${(props: Props) =>
    props.color === "secondary" &&
    `
    color: var(--secondary);
  `}

  ${(props: Props) =>
    props.color === "third" &&
    `
    color: var(--third);
  `}

  ${(props: Props) =>
    props.color === "highlighted" &&
    `
    color: var(--highlighted);
  `}

  ${(props: Props) =>
    props.color === "default" &&
    `
    color: var(--text-default);
  `}

  ${(props: Props) =>
    props.appearance === "title" &&
    `
    font-family: "GalanoGrotesque-SemiBold";
    line-height: 1.1;
  `}

${(props: Props) =>
    props.appearance === "title-bold" &&
    `
    line-height: 1.1;
    font-size: 40px;
    font-family: "GalanoGrotesque-Bold";
    margin-bottom: 3rem;

    @media screen and (max-width: 1280px) {
      font-size: 34px;
    } 

    @media screen and (max-width: 980px) {
      font-size: 28px;
    } 

    @media screen and (max-width: 480px) {
      font-size: 26px;
    }
  `}

${(props: Props) =>
    props.appearance === "title-bold" &&
    props.size === "large" &&
    `
    font-size: 50px !important;
    font-family: "GalanoGrotesque-Bold";

    @media screen and (max-width: 1280px) {
      font-size: 40px !important;
    } 

    @media screen and (max-width: 980px) {
      font-size: 35px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 30px !important;
    }
  `}

${(props: Props) =>
    props.appearance === "cultivation-text" &&
    `
    line-height: 1;
    font-size: 45px;
    font-family: "GalanoGrotesque-Bold";

   

    @media screen and (max-width: 680px) {
      font-size: 28px !important;
    }
  `}

${(props: Props) =>
    props.appearance === "title-semibold" &&
    `
    margin-bottom: 1rem;
    line-height: 1;
    font-size: 42px !important;
    font-family: "GalanoGrotesque-SemiBold";
    margin-bottom: 1rem;

    @media screen and (max-width: 1200px) {
      font-size: 34px !important;
    } 

    @media screen and (max-width: 680px) {
      font-size: 28px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 24px !important;
    }
  `}

${(props: Props) =>
    props.appearance === "title-semibold" &&
    props.size === "medium" &&
    `
    line-height: 1;
    font-size: 34px !important;
    font-family: "GalanoGrotesque-SemiBold";

    @media screen and (max-width: 1200px) {
      font-size: 28px !important;
    } 

    @media screen and (max-width: 680px) {
      font-size: 24px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 20px !important;
    }
  `}

${(props: Props) =>
    props.appearance === "title-semibold" &&
    props.size === "small" &&
    `
    line-height: 1;
    font-size: 24px !important;
    font-family: "GalanoGrotesque-SemiBold";

    @media screen and (max-width: 1200px) {
      font-size: 20px !important;
    } 

    @media screen and (max-width: 680px) {
      font-size: 18px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 18px !important;
    }
  `}

  ${(props: Props) =>
    props.appearance === "section-title" &&
    `
    position: relative;
    line-height: 1;
    font-size: 32px;
    font-family: "GalanoGrotesque-Medium";
    letter-spacing: 1px;
  `}
  
  ${(props: Props) =>
    props.sectionTitleMark &&
    `
    position: relative;
    margin-top: 4rem;

    &::before {
      content: "";
      width: 60px;
      height: 15px;
      top: -40px;
      left: 50%;
      position: absolute;
      margin-right: 15px;
      border-radius: 15px;
      background: var(--primary);
      transform: translateX(-50%);
    }
  `}


  ${(props: Props) =>
    props.appearance === "info-title" &&
    `
    line-height: 1;
    font-size: 40px;
    font-family: "GalanoGrotesque-Medium";

    @media screen and (max-width: 680px) {
      font-size: 34px;
    } 

    @media screen and (max-width: 480px) {
      font-size: 28px;
    }
  `}

${(props: Props) =>
    props.appearance === "info-bold" &&
    `
    font-size: 20px !important;
    font-family: "GalanoGrotesque-Bold";

    @media screen and (max-width: 1280px) {
      font-size: 18px !important;
    } 

    @media screen and (max-width: 980px) {
      font-size: 18px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 16px !important;
    }
  `}

${(props: Props) =>
    props.appearance === "info-semibold" &&
    `
    font-size: 20px !important;
    font-family: "GalanoGrotesque-Semibold";

    @media screen and (max-width: 1280px) {
      font-size: 18px !important;
    } 

    @media screen and (max-width: 980px) {
      font-size: 18px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 16px !important;
    }
  `}

  ${(props: Props) =>
    props.appearance === "description" &&
    `
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 80px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  `}
  
  ${(props: Props) =>
    props.appearance === "description-block" &&
    `
    font-size: 16px;
    margin: 20px 0 40px;
    color: var(--tag-text-color);
    `}
  
      
  ${(props: Props) =>
    props.appearance === "description-block" &&
    props.size === "small" &&
    `
      font-size: 12px!important;
  `}

${(props: Props) =>
    props.appearance === "description-opinions" &&
    `
      line-height: 1.3;
      font-style: italic;
      font-family: "Galano Grotesque";
      font-size: 16px;
      margin-bottom: 1rem;
      color: var(--text-default);
    `}

  ${(props: Props) =>
    props.appearance === "info-subtitle" &&
    `
    line-height: 1.2;
    font-size: 22px;
    margin: 2rem 0;
    font-family: "GalanoGrotesque-Medium";

    @media screen and (max-width: 480px) {
      margin: 0 0 15px;
    }
  `}

${(props: Props) =>
    props.appearance === "title-medium" &&
    props.size === "medium" &&
    `
    line-height: 1.2;
    font-size: 22px;
    margin: 1rem 0;
    font-family: "GalanoGrotesque-Medium";

    // @media screen and (max-width: 480px) {
    //   margin: 0 0 15px;
    // }
  `}
  
  ${(props: Props) =>
    props.appearance === "carousel-title" &&
    `
    text-align: center;
    font-family: "GalanoGrotesque-SemiBold"; 
    font-size: 42px;
    margin: 2rem 0 0;
  `}
  
  ${(props: Props) =>
    props.appearance === "carousel-subtitle" &&
    `
    text-align: center;
    font-size: 22px;
    line-height: 1.2;
    padding: 1rem 20% 2rem;

    @media screen and (max-width: 680px) {
      padding: 1rem 5% 2rem;
    }
    
    @media screen and (max-width: 579px) {
      padding: 1rem 10% 0;
    } 

  `}

${(props: Props) =>
    props.appearance === "carousel-description" &&
    `
    text-align: center;
    font-size: 22px;
    line-height: 1.2;
    padding: 0 15% 2rem;

    @media screen and (max-width: 680px) {
      padding: 0 5% 2rem;
    }
    
    @media screen and (max-width: 579px) {
      padding: 0 10% 2rem;
    } 

  `}

${(props: Props) =>
    props.appearance === "carousel-title-mixed" &&
    `
    text-align: center;
    font-family: "GalanoGrotesque-SemiBold"; 
    font-size: 42px;
    margin: 2rem 0 0;
    position: relative;
    background: linear-gradient(to right, var(--white) 0%, var(--white) 43%, var(--secondary) 43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:after {
      content: "";
      margin-left: 10px;
      border-right: 18px solid transparent;
      border-top: 18px solid var(--secondary);
      border-left: 18px solid transparent;
      display: inline-block;
    }
  `}
  
  ${(props: Props) =>
    props.appearance === "description-banner" &&
    `
    font-family: "GalanoGrotesque-Medium";
    margin-top: 1rem;
    width: 75%;

    @media screen and (max-width: 980px) {
      width: 100%;
    }

    @media screen and (max-width: 580px) {
      width: 80%;
    }
  `}

${(props: Props) =>
    props.appearance === "description-product" &&
    `
      line-height: 1.3;
      font-family: "Galano Grotesque";
      font-size: 14px;
      margin: 5px 0 20px ;
      color: var(--tag-text-color);
  `}
  
  ${(props: Props) =>
    props.appearance === "menu-title" &&
    `
    font-size: 24px;
    font-family: "GalanoGrotesque-Medium";
  `}
  
  ${(props: Props) =>
    props.appearance === "price" &&
    `
    font-size: 24px;
    font-family: "GalanoGrotesque-Medium";
    color: var(--text-primary);
  `}
  
  ${(props: Props) =>
    props.appearance === "info-name" &&
    `
    font-size: 18px;
    font-family: "GalanoGrotesque-SemiBold";
    color: var(--text-primary);
  `}

${(props: Props) =>
    props.appearance === "info-role" &&
    `
    font-size: 14px;
    font-family: "Galano Grotesque";
    color: var(--text-default);
  `}
  
  ${(props: Props) =>
    props.appearance === "info-value" &&
    `
    font-size: 48px;
    line-height: 1.2;
    font-family: 'GalanoGrotesque-Semibold';
    color: var(--dashboard-primary-bg);

    @media screen and (max-width: 1600px) {
      font-size: 40px;
    }
  `}

  ${(props: Props) =>
    props.appearance === "info-description" &&
    `
      font-size: 14px;
      font-family: 'Galano Grotesque';
      color: var(--dashboard-text-default);
  `}

${(props: Props) =>
    props.appearance === "info-required" &&
    `
    margin-top: 2rem;
    font-size: 20px;
    font-family: "GalanoGrotesque-Semibold";

    @media screen and (max-width: 680px) {
      font-size: 18px;
    }

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }


  `}

  ${(props: Props) =>
    props.appearance === "details-description" &&
    `
      font-size: 14px;
      font-family: 'GalanoGrotesque-Medium';
      color: var(--dashboard-text-default);
      margin: 2rem 0;
  `}

  ${(props: Props) =>
    props.appearance === "block-title" &&
    `
      font-family: 'GalanoGrotesque-Medium';
      font-size: 24px;
  `}

  ${(props: Props) =>
    props.appearance === "small-title" &&
    `
      font-family: 'GalanoGrotesque-Semibold';
      margin-top: 1.5rem;
  `}

  ${(props: Props) =>
    props.appearance === "small-description" &&
    `
      font-size: 14px;
      font-family: 'Galano Grotesque';
  `}

  ${(props: Props) =>
    props.size === "small" &&
    `
    font-size: 11px;
  `}

  ${(props: Props) =>
    props.size === "medium" &&
    `
    font-size: 20px;
    line-height: 1.2;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  `}

  ${(props: Props) =>
    props.size === "large" &&
    `
    font-size: 42px;

    @media screen and (max-width: 1440px) {
      font-size: 32px;
    }

    @media screen and (max-width: 1280px) {
      font-size: 24px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }

    @media screen and (max-width: 991px) {
      font-size: 30px;
    }

    @media screen and (max-width: 768px) {
      font-size: 28px;
    }

    @media screen and (max-width: 680px) {
      line-height: 1.1;
      font-size: 24px;
    }

    @media screen and (max-width: 480px) {
      font-size: 22px;
    }

    @media screen and (max-width: 420px) {
      font-size: 20px;
    }

    @media screen and (max-width: 380px) {
      font-size: 18px;
    }

    @media screen and (max-width: 300px) {
      font-size: 16px;
    }

  `}

  ${(props: Props) =>
    props.size === "x-large" &&
    `
    line-height: 0.75;
    font-size: 58px;

    @media screen and (max-width: 580px) {
      font-size: 44px;
    }
  `}

  

  ${(props: Props) =>
    props.textAlign === "left" &&
    `
    text-align: left;
  `}

  ${(props: Props) =>
    props.textAlign === "right" &&
    `
    text-align: right;
  `}
  ${(props: Props) =>
    props.textAlign === "center" &&
    `
    text-align: center;
  `}

  ${(props: Props) =>
    props.transform &&
    `
    text-transform: ${props.transform};
  `}

${(props: Props) =>
    props.appearance === "title-semibold" &&
    props.size === "small" &&
    `
    line-height: 1;
    font-size: 26px !important;
    font-family: "GalanoGrotesque-SemiBold";

    @media screen and (max-width: 1200px) {
      font-size: 22px !important;
    } 

    @media screen and (max-width: 680px) {
      font-size: 20px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 18px !important;
    }
  `}

${(props: Props) =>
    props.appearance === "title-bold" &&
    props.size === "small" &&
    `
    line-height: 1.2;
    font-size: 20px;
    font-family: "GalanoGrotesque-Bold";

    @media screen and (max-width: 1280px) {
      font-size: 18px;
    } 

    @media screen and (max-width: 980px) {
      font-size: 18px;
    } 

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  `}

${(props: Props) =>
    props.appearance === "title-bold" &&
    props.size === "x-small" &&
    `
    line-height: 1.2;
    font-size: 18px;
    font-family: "GalanoGrotesque-Bold";

    @media screen and (max-width: 1280px) {
      font-size: 16px;
    } 

    @media screen and (max-width: 980px) {
      font-size: 16px;
    } 

    @media screen and (max-width: 480px) {
      font-size: 14px;
    }
  `}
`;
