import ptBrMessages from "./pt-BR";
import frMessages from "./fr";
import esMessages from "./es";
import enMessages from "./en";

/**
 * A locale that the user may select. A list of these is exported as `languages`.
 */
interface LanguageOption {
  localeCode: string;
  /** Language's name, in the language itself (ex. "English", "Español", "Português", etc.) */
  //languageName: string;

  /** New Structure **/
  appTitle: string;
  infoPageTitle: string;
  infoPageSlogan: string;
  pageTitle: string;
  pageDescription: string;
  sectionAboutTitle: string;
  sectionAboutCategory: string;
  sectionAboutInfoTitle: string;
  sectionAboutInfoSubtitle: string;
  sectionAboutInfoDescription: string;
  sectionBiomapsTitle: string;
  sectionBiomapsSubtitle: string;
  sectionBiomapsDescription: string;
  sectionProductsCategory: string;
  sectionPricesCategory: string;
  sectionPricesTitle: string;
  sectionPricesDescription: string;
  sectionClientsCategory: string;
  sectionClientsTitle: string;
  sectionPartnersCategory: string;

  dashboardWelcome: string;
  dashboardClients: string;
  dashboardUsers: string;
  dashboardTotalSales: string;
  dashboardHectaresCovered: string;
  dashboardNewCompany: string;
  dashboardFlightClient: string;
  dashboardCoverageGraph: string;

  infoTitle: string;
  infoDescription: string;
  infoNewsletter: string;
  infoAbout: string;
  infoCopyright: string;
  infoAddress: string;
  infoEmail: string;
  infoPhone: string;
  infoTerms: string;
  infoSummary: string;
  infoRegisterInfo: string;
  infoRegisterTerms: string;
  infoLegalInfo: string;
  infoLegalTerms: string;
  infoPlansInfo: string;
  infoAmount: string;
  infoPlan: string;
  infoMode: string;
  infoPrice: string;
  infoPerYear: string;
  infoTotal: string;
  infoUsers: string;
  infoContract: string;
  infoAddNew: string;
  infoClients: string;
  infoAboutTitle: string;
  infoAboutNCB: string;
  infoAboutDescription: string;
  infoAboutVisionTitle: string;
  infoAboutVisionSub: string;
  infoAboutVisionText: string;
  infoAboutMissionTitle: string;
  infoAboutMissionSub: string;
  infoAboutMissionText: string;
  infoAboutValues: string;
  infoContactTitlte: string;
  infoContactDescription: string;
  infoTermTitle: string;
  infoCoin: string;
  infoTermPriceMonth: string;
  infoTermPriceYear: string;
  infoTermSpecifications: string;
  infoTermSpecificationsDesc: string;
  infoTermClassifications: string;
  infoTermClassificationsDesc: string;
  infoTermChoose: string;
  infoIntroducing: string;
  infoCameraQuality: string;
  infoFlightTime: string;
  infoCapacity: string;
  infoMinutes: string;
  infoTermError: string;
  infoMenuOptions: string;
  infoVerificationTitle: string;
  infoVerificationDescription: string;
  infoEmailAddress: string;
  infoVerificationCodeDescription: string;
  infoConfirmationCode: string;
  infoNewPassword: string;
  infoNewPasswordDescription: string;
  infoNewPasswordDetails: string;
  infoNewPasswordConfirm: string;

  prodBiocoteDescription: string;
  prodUsageSugarcane: string;
  prodUsageForest: string;
  prodUsageCotton: string;
  prodUsageCorn: string;
  prodUsageSoybeans: string;

  menuAbout: string;
  menuProducts: string;
  menuPricing: string;
  menuPrivacy: string;
  menuContact: string;
  menuTerms: string;
  menuTitle: string;
  menuSupport: string;
  menuFaq: string;
  menuResources: string;
  menuWhy: string;
  menuHome: string;
  menuBiomaps: string;
  menuProfile: string;
  menuPlans: string;
  menuSettings: string;
  menuOpenProject: string;
  menuNewProject: string;
  menuNewMonitoring: string;
  menuFlightMonitoring: string;
  menuTrapMonitoring: string;
  menuBiomapsInfo: string;
  menuLicenses: string;
  menuTraps: string;

  actionLogin: string;
  actionSignUp: string;
  actionSubscribe: string;
  actionRegister: string;
  actionMakeRegistration: string;
  actionSuccess: string;
  actionError: string;
  actionMsgSuccess: string;
  actionMsgError: string;
  actionBack: string;
  actionGetStarted: string;
  actionLearnMore: string;
  actionMore: string;
  actionKeepConnected: string;
  actionLoginMsg: string;
  actionLoginMsgError: string;
  actionContinue: string;
  actionAccept: string;
  actionIndicator: string;
  actionSignupCompany: string;
  actionSignupSingle: string;
  actionAcceptSignupTerms: string;
  actionPayNow: string;
  actionAddNew: string;
  actionEdit: string;
  actionEditPicture: string;
  actionRemove: string;
  actionSave: string;
  actionCancel: string;
  actionIncome: string;
  actionSend: string;
  actionConfirm: string;
  actionSendVerification: string;
  actionSaveData: string;

  placeholderEmail: string;
  placeholderName: string;
  placeholderPhone: string;
  placeholderCompany: string;
  placeholderDataCollect: string;
  placeholderPassword: string;
  placeholderSearch: string;
  placeholderIVA: string;
  placeholderCompanyName: string;
  placeholderZIP: string;
  placeholderAddress: string;
  placeholderNumber: string;
  placeholderComplement: string;
  placeholderCountry: string;
  placeholderStateDepartment: string;
  placeholderComercialPhone: string;
  placeholderAviationReg: string;
  placeholderAgricultureReg: string;
  placeholderRole: string;
  placeholderDocument: string;
  placeholderRg: string;
  placeholderCrea: string;
  placeholderIE: string;
  placeholderIM: string;
  placeholderTradeName: string;
  placeholderType: string;
  placeholderContact: string;
  placeholderDescription: string;
  placeholderMessage: string;

  optionSearchEngine: string;
  optionFfacebook: string;
  optionLinkedin: string;
  optionRecommendation: string;
  optionEmailMarketing: string;
  optionEvents: string;
  optionOthers: string;
  optionWhat: string;

  languagePT: string;
  languageES: string;
  languageEN: string;
  languageFR: string;
  languageName: string;
  languageRegion: string;

  //- Reports -//
  reportIssueDate: string;
  reportArea: string;
  reportPlannedFlight: string;
  reportDetails: string;
  reportDateHour: string;
  reportDrone: string;
  reportManufacturer: string;
  reportAnac: string;
  reportReleasedProduct: string;
  reportReleasing: string;
  reportBandWidth: string;
  reportPlannedSpeed: string;
  reportReleaseRate: string;
  reportRealFlight: string;
  reportFlightTime: string;
  reportAverageReleaseRate: string;
  reportAverageSpeed: string;
  reportTotalReleased: string;
  reportTotalArea: string;
  reportTraps: string;
  reportSerialNumber: string;
  reportCoveredHectares: string;
  reportDateMeasurament: string;
  reportSpecie: string;
  reportAdultsNumber: string;
  reportNoAvailable: string;
  reportMapRecord: string;
  reportContractor: string;
  reportProperty: string;
  reportLocalization: string;
  reportCounty: string;
  reportUF: string;
  reportDoc: string;
  reportServiceType: string;
  reportProduct: string;
  reportFormulation: string;
  reportDosage: string;
  reportToxicClass: string;
  reportAdjuvant: string;
  reportCulture: string;
  reportVolume: string;
  reportOthers: string;
  reportAgronomicalRecipe: string;
  reportIssuedOn: string;
  reportBasicParameter: string;
  reportMaxTemperature: string;
  reportRelativeUnitMin: string;
  reportEquipment: string;
  reportModel: string;
  reportType: string;
  reportAngle: string;
  reportFlightHeight: string;
  reportBandwidth: string;
  reportDate: string;
  reportSignatureCREA: string;
  reportWeatherConditions: string;
  reportMorning: string;
  reportEvening: string;
  reportApplicationStart: string;
  reportInitial: string;
  reportFinal: string;
  reportTemperature: string;
  reportRelativeHumidity: string;
  reportAircraftPrefixe: string;
  reportDgpsUse: string;
  reportWindSpeed: string;
  reportTrackCoordinates: string;
  reportObservation: string;
  reportSignatureEng: string;
}

/**
 * Type of the main map of messages. It's a map of locale -> (key -> string).
 */
type LocalizationMessages = {
  [locale: string]: {
    // <- Key is a supported locale (e.g. "pt-BR")
    [key: string]: string; // <- Key is a message id (e.g. "login.form.email")
  };
};

/**
 * Map of locales to messages.
 */
const messagesMap: LocalizationMessages = {
  "pt-BR": ptBrMessages,
  fr: frMessages,
  es: esMessages,
  en: enMessages,
};

/// EXPORTS

/**
 * Obtain a messages map for the informed locale. Currently, this defaults to Portuguese.
 * @param locale The current locale code.
 */
export function messagesForLocale(locale: string): { [key: string]: string } {
  // Default to portuguese - maybe change this later.
  return messagesMap[locale] ?? messagesMap["pt-BR"];
}

/**
 * List of available languages. Present these options to the user when changing the language.
 */
export const languages: LanguageOption[] = [
  // Português (Brasil)
  {
    localeCode: "pt-BR",
    appTitle: ptBrMessages["app.title"],

    infoPageTitle: ptBrMessages["info.pageTitle"],
    infoPageSlogan: ptBrMessages["info.slogan"],
    pageTitle: ptBrMessages["page.title"],
    pageDescription: ptBrMessages["page.description"],
    sectionAboutTitle: ptBrMessages["section.about.title"],
    sectionAboutCategory: ptBrMessages["section.about.category"],
    sectionAboutInfoTitle: ptBrMessages["section.about.info.title"],
    sectionAboutInfoSubtitle: ptBrMessages["section.about.info.subtitle"],
    sectionAboutInfoDescription: ptBrMessages["section.about.info.description"],
    sectionBiomapsTitle: ptBrMessages["section.biomaps.title"],
    sectionBiomapsSubtitle: ptBrMessages["section.biomaps.subtitle"],
    sectionBiomapsDescription: ptBrMessages["section.biomaps.description"],
    sectionProductsCategory: ptBrMessages["section.products.category"],
    sectionPricesCategory: ptBrMessages["section.prices.category"],
    sectionPricesTitle: ptBrMessages["section.prices.title"],
    sectionPricesDescription: ptBrMessages["section.prices.description"],
    sectionClientsCategory: ptBrMessages["section.clients.category"],
    sectionClientsTitle: ptBrMessages["section.clients.title"],
    sectionPartnersCategory: ptBrMessages["section.partners.category"],

    dashboardWelcome: ptBrMessages["dashboard.welcome"],
    dashboardClients: ptBrMessages["dashboard.clients"],
    dashboardUsers: ptBrMessages["dashboard.users"],
    dashboardTotalSales: ptBrMessages["dashboard.total.sales"],
    dashboardHectaresCovered: ptBrMessages["dashboard.hectares.covered"],
    dashboardNewCompany: ptBrMessages["dashboard.new.company"],
    dashboardFlightClient: ptBrMessages["dashboard.flight.client"],
    dashboardCoverageGraph: ptBrMessages["dashboard.coverage.graph"],

    infoTitle: ptBrMessages["info.title"],
    infoDescription: ptBrMessages["info.description"],
    infoNewsletter: ptBrMessages["info.newsletter"],
    infoAbout: ptBrMessages["info.about"],
    infoCopyright: ptBrMessages["info.copyright"],
    infoAddress: ptBrMessages["info.address"],
    infoEmail: ptBrMessages["info.email"],
    infoPhone: ptBrMessages["info.phone"],
    infoTerms: ptBrMessages["info.terms"],
    infoSummary: ptBrMessages["info.summary"],
    infoRegisterInfo: ptBrMessages["info.register.information"],
    infoRegisterTerms: ptBrMessages["info.register.terms"],
    infoLegalInfo: ptBrMessages["info.legal.information"],
    infoLegalTerms: ptBrMessages["info.legal.terms"],
    infoPlansInfo: ptBrMessages["info.plans.information"],
    infoAmount: ptBrMessages["info.amount"],
    infoPlan: ptBrMessages["info.plan"],
    infoMode: ptBrMessages["info.mode"],
    infoPrice: ptBrMessages["info.price"],
    infoPerYear: ptBrMessages["info.per.year"],
    infoTotal: ptBrMessages["info.total"],
    infoUsers: ptBrMessages["info.users"],
    infoContract: ptBrMessages["info.contract"],
    infoAddNew: ptBrMessages["info.add.new"],
    infoClients: ptBrMessages["info.clients"],
    infoTermError: ptBrMessages["info.termError"],
    infoMenuOptions: ptBrMessages["info.menuOptions"],

    infoAboutTitle: ptBrMessages["info.aboutTitle"],
    infoAboutNCB: ptBrMessages["info.aboutNCB"],
    infoAboutDescription: ptBrMessages["info.aboutDescription"],
    infoAboutVisionTitle: ptBrMessages["info.aboutVisionTitle"],
    infoAboutVisionSub: ptBrMessages["info.aboutVisionSub"],
    infoAboutVisionText: ptBrMessages["info.aboutVisionText"],
    infoAboutMissionTitle: ptBrMessages["info.aboutMissionTitle"],
    infoAboutMissionSub: ptBrMessages["info.aboutMissionSub"],
    infoAboutMissionText: ptBrMessages["info.aboutMissionText"],
    infoAboutValues: ptBrMessages["info.aboutValues"],
    infoContactTitlte: ptBrMessages["info.contactTitle"],
    infoContactDescription: ptBrMessages["info.contactDescription"],
    infoTermTitle: ptBrMessages["info.termTitle"],
    infoCoin: ptBrMessages["info.coin"],
    infoTermPriceMonth: ptBrMessages["info.termPriceMonth"],
    infoTermPriceYear: ptBrMessages["info.termPriceYear"],
    infoTermSpecifications: ptBrMessages["info.termSpecifications"],
    infoTermSpecificationsDesc: ptBrMessages["info.termSpecificationsDesc"],
    infoTermClassifications: ptBrMessages["info.termClassifications"],
    infoTermClassificationsDesc: ptBrMessages["info.termClassificationsDesc"],
    infoTermChoose: ptBrMessages["info.termChoose"],
    infoIntroducing: ptBrMessages["info.introducing"],
    infoCameraQuality: ptBrMessages["info.cameraQuality"],
    infoFlightTime: ptBrMessages["info.flightTime"],
    infoCapacity: ptBrMessages["info.capacity"],
    infoMinutes: ptBrMessages["info.minutes"],
    infoVerificationTitle: ptBrMessages["info.verificationTitle"],
    infoVerificationDescription: ptBrMessages["info.verificationDescription"],
    infoEmailAddress: ptBrMessages["info.emailAddress"],
    infoVerificationCodeDescription: ptBrMessages["info.verificationCodeDescription"],
    infoConfirmationCode: ptBrMessages["info.confirmationCode"],
    infoNewPassword: ptBrMessages["info.newPassword"],
    infoNewPasswordDescription: ptBrMessages["info.newPasswordDescription"],
    infoNewPasswordDetails: ptBrMessages["info.newPasswordDetails"],
    infoNewPasswordConfirm: ptBrMessages["info.newPasswordConfirm"],

    prodBiocoteDescription: ptBrMessages["prod.biocoteDescription"],
    prodUsageSugarcane: ptBrMessages["prod.usageSugarcane"],
    prodUsageForest: ptBrMessages["prod.usageForest"],
    prodUsageCotton: ptBrMessages["prod.usageCotton"],
    prodUsageCorn: ptBrMessages["prod.usageCorn"],
    prodUsageSoybeans: ptBrMessages["prod.usageSoybeans"],

    menuAbout: ptBrMessages["menu.about"],
    menuProducts: ptBrMessages["menu.products"],
    menuPricing: ptBrMessages["menu.pricing"],
    menuContact: ptBrMessages["menu.contact"],
    menuPrivacy: ptBrMessages["menu.privacy"],
    menuTerms: ptBrMessages["menu.terms"],
    menuTitle: ptBrMessages["menu.title"],
    menuSupport: ptBrMessages["menu.support"],
    menuFaq: ptBrMessages["menu.faq"],
    menuResources: ptBrMessages["menu.resources"],
    menuWhy: ptBrMessages["menu.why"],
    menuHome: ptBrMessages["menu.home"],
    menuBiomaps: ptBrMessages["menu.biomaps"],
    menuProfile: ptBrMessages["menu.profile"],
    menuPlans: ptBrMessages["menu.plans"],
    menuSettings: ptBrMessages["menu.settings"],
    menuOpenProject: ptBrMessages["menu.open.project"],
    menuNewProject: ptBrMessages["menu.new.project"],
    menuNewMonitoring: ptBrMessages["menu.new.monitoring"],
    menuFlightMonitoring: ptBrMessages["menu.flight.monitoring"],
    menuTrapMonitoring: ptBrMessages["menu.trap.monitoring"],
    menuBiomapsInfo: ptBrMessages["menu.biomapsInfo"],
    menuLicenses: ptBrMessages["menu.licenses"],
    menuTraps: ptBrMessages["menu.traps"],

    actionLogin: ptBrMessages["action.login"],
    actionSignUp: ptBrMessages["action.signUp"],
    actionSubscribe: ptBrMessages["action.subscribe"],
    actionRegister: ptBrMessages["action.register"],
    actionMakeRegistration: ptBrMessages["action.makeRegistration"],
    actionSuccess: ptBrMessages["action.success"],
    actionError: ptBrMessages["action.error"],
    actionMsgSuccess: ptBrMessages["action.msgSuccess"],
    actionMsgError: ptBrMessages["action.msgError"],
    actionBack: ptBrMessages["action.back"],
    actionGetStarted: ptBrMessages["action.getStarted"],
    actionLearnMore: ptBrMessages["action.learnMore"],
    actionMore: ptBrMessages["action.more"],
    actionKeepConnected: ptBrMessages["action.keep.connected"],
    actionLoginMsg: ptBrMessages["action.login.message"],
    actionLoginMsgError: ptBrMessages["action.login.msgError"],
    actionContinue: ptBrMessages["action.continue"],
    actionAccept: ptBrMessages["action.accept"],
    actionIndicator: ptBrMessages["action.indicator"],
    actionSignupCompany: ptBrMessages["action.company"],
    actionSignupSingle: ptBrMessages["action.single"],
    actionAcceptSignupTerms: ptBrMessages["action.accept.signup.terms"],
    actionPayNow: ptBrMessages["action.pay.now"],
    actionAddNew: ptBrMessages["action.add.new"],
    actionEdit: ptBrMessages["action.edit"],
    actionEditPicture: ptBrMessages["action.edit.picture"],
    actionRemove: ptBrMessages["action.remove"],
    actionSave: ptBrMessages["action.save"],
    actionCancel: ptBrMessages["action.cancel"],
    actionIncome: ptBrMessages["action.income"],
    actionSend: ptBrMessages["action.send"],
    actionConfirm: ptBrMessages["action.confirm"],
    actionSendVerification: ptBrMessages["action.sendVerification"],
    actionSaveData: ptBrMessages["action.saveData"],

    placeholderEmail: ptBrMessages["placeholder.email"],
    placeholderName: ptBrMessages["placeholder.name"],
    placeholderPhone: ptBrMessages["placeholder.phone"],
    placeholderCompany: ptBrMessages["placeholder.company"],
    placeholderDataCollect: ptBrMessages["placeholder.data.collect"],
    placeholderPassword: ptBrMessages["placeholder.password"],
    placeholderSearch: ptBrMessages["placeholder.search"],
    placeholderIVA: ptBrMessages["placeholder.iva"],
    placeholderCompanyName: ptBrMessages["placeholder.company.name"],
    placeholderZIP: ptBrMessages["placeholder.zip"],
    placeholderAddress: ptBrMessages["placeholder.address"],
    placeholderNumber: ptBrMessages["placeholder.number"],
    placeholderComplement: ptBrMessages["placeholder.complement"],
    placeholderCountry: ptBrMessages["placeholder.country"],
    placeholderStateDepartment: ptBrMessages["placeholder.state.department"],
    placeholderComercialPhone: ptBrMessages["placeholder.comercial.phone"],
    placeholderAviationReg: ptBrMessages["placeholder.aviation.reg"],
    placeholderAgricultureReg: ptBrMessages["placeholder.agriculture.reg"],
    placeholderRole: ptBrMessages["placeholder.role"],
    placeholderDocument: ptBrMessages["placeholder.document"],
    placeholderRg: ptBrMessages["placeholder.rg"],
    placeholderCrea: ptBrMessages["placeholder.crea"],
    placeholderIE: ptBrMessages["placeholder.ie"],
    placeholderIM: ptBrMessages["placeholder.im"],
    placeholderTradeName: ptBrMessages["placeholder.trade.name"],
    placeholderType: ptBrMessages["placeholder.type"],
    placeholderContact: ptBrMessages["placeholder.contact"],
    placeholderDescription: ptBrMessages["placeholder.description"],
    placeholderMessage: ptBrMessages["placeholder.message"],

    optionSearchEngine: ptBrMessages["option.search.engine"],
    optionFfacebook: ptBrMessages["option.facebook"],
    optionLinkedin: ptBrMessages["option.linkedin"],
    optionRecommendation: ptBrMessages["option.recommendation"],
    optionEmailMarketing: ptBrMessages["option.email.marketing"],
    optionEvents: ptBrMessages["option.events"],
    optionOthers: ptBrMessages["option.others"],
    optionWhat: ptBrMessages["option.which"],

    languagePT: ptBrMessages["language.pt"],
    languageES: ptBrMessages["language.es"],
    languageEN: ptBrMessages["language.en"],
    languageFR: ptBrMessages["language.fr"],
    languageName: ptBrMessages["language.name"],
    languageRegion: ptBrMessages["language.region"],

    reportIssueDate: ptBrMessages["report.issueDate"],
    reportArea: ptBrMessages["report.area"],
    reportPlannedFlight: ptBrMessages["report.plannedFlight"],
    reportDetails: ptBrMessages["report.details"],
    reportDateHour: ptBrMessages["report.dateHour"],
    reportDrone: ptBrMessages["report.drone"],
    reportManufacturer: ptBrMessages["report.manufacturer"],
    reportAnac: ptBrMessages["report.anac"],
    reportReleasedProduct: ptBrMessages["report.releasedProduct"],
    reportReleasing: ptBrMessages["report.releasing"],
    reportBandWidth: ptBrMessages["report.bandWidth"],
    reportPlannedSpeed: ptBrMessages["report.plannedSpeed"],
    reportReleaseRate: ptBrMessages["report.releaseRate"],
    reportRealFlight: ptBrMessages["report.realFlight"],
    reportFlightTime: ptBrMessages["report.flightTime"],
    reportAverageReleaseRate: ptBrMessages["report.averageReleaseRate"],
    reportAverageSpeed: ptBrMessages["report.averageSpeed"],
    reportTotalReleased: ptBrMessages["report.totalReleased"],
    reportTotalArea: ptBrMessages["report.totalArea"],
    reportTraps: ptBrMessages["report.traps"],
    reportSerialNumber: ptBrMessages["report.serialNumber"],
    reportCoveredHectares: ptBrMessages["report.coveredHectares"],
    reportDateMeasurament: ptBrMessages["report.dateMeasurament"],
    reportSpecie: ptBrMessages["report.specie"],
    reportAdultsNumber: ptBrMessages["report.adultsNumber"],
    reportNoAvailable: ptBrMessages["report.noAvailable"],
    reportMapRecord: ptBrMessages["report.mapRecord"],
    reportContractor: ptBrMessages["report.contractor"],
    reportProperty: ptBrMessages["report.property"],
    reportLocalization: ptBrMessages["report.localization"],
    reportCounty: ptBrMessages["report.county"],
    reportUF: ptBrMessages["report.uf"],
    reportDoc: ptBrMessages["report.doc"],
    reportServiceType: ptBrMessages["report.serviceType"],
    reportProduct: ptBrMessages["report.product"],
    reportFormulation: ptBrMessages["report.formulation"],
    reportDosage: ptBrMessages["report.dosage"],
    reportToxicClass: ptBrMessages["report.toxicClass"],
    reportAdjuvant: ptBrMessages["report.adjuvant"],
    reportCulture: ptBrMessages["report.culture"],
    reportVolume: ptBrMessages["report.volume"],
    reportOthers: ptBrMessages["report.others"],
    reportAgronomicalRecipe: ptBrMessages["report.agronomicalRecipe"],
    reportIssuedOn: ptBrMessages["report.issuedOn"],
    reportBasicParameter: ptBrMessages["report.basicParameter"],
    reportMaxTemperature: ptBrMessages["report.maxTemperature"],
    reportRelativeUnitMin: ptBrMessages["report.relativeUnitMin"],
    reportEquipment: ptBrMessages["report.equipment"],
    reportModel: ptBrMessages["report.model"],
    reportType: ptBrMessages["report.type"],
    reportAngle: ptBrMessages["report.angle"],
    reportFlightHeight: ptBrMessages["report.flightHeight"],
    reportBandwidth: ptBrMessages["report.bandwidth"],
    reportDate: ptBrMessages["report.date"],
    reportSignatureCREA: ptBrMessages["report.signatureCREA"],
    reportWeatherConditions: ptBrMessages["report.weatherConditions"],
    reportMorning: ptBrMessages["report.morning"],
    reportEvening: ptBrMessages["report.evening"],
    reportApplicationStart: ptBrMessages["report.applicationStart"],
    reportInitial: ptBrMessages["report.initial"],
    reportFinal: ptBrMessages["report.final"],
    reportTemperature: ptBrMessages["report.temperature"],
    reportRelativeHumidity: ptBrMessages["report.relativeHumanity"],
    reportAircraftPrefixe: ptBrMessages["report.aircraftPrefixe"],
    reportDgpsUse: ptBrMessages["report.dgpsUse"],
    reportWindSpeed: ptBrMessages["report.windSpeed"],
    reportTrackCoordinates: ptBrMessages["report.trackCoordinates"],
    reportObservation: ptBrMessages["report.observation"],
    reportSignatureEng: ptBrMessages["report.signatureEng"],
  },
  // Francês
  {
    localeCode: "fr",
    appTitle: frMessages["app.title"],

    infoPageTitle: frMessages["info.pageTitle"],
    infoPageSlogan: frMessages["info.slogan"],
    pageTitle: frMessages["page.title"],
    pageDescription: frMessages["page.description"],
    sectionAboutTitle: frMessages["section.about.title"],
    sectionAboutCategory: frMessages["section.about.category"],
    sectionAboutInfoTitle: frMessages["section.about.info.title"],
    sectionAboutInfoSubtitle: frMessages["section.about.info.subtitle"],
    sectionAboutInfoDescription: frMessages["section.about.info.description"],
    sectionBiomapsTitle: frMessages["section.biomaps.title"],
    sectionBiomapsSubtitle: frMessages["section.biomaps.subtitle"],
    sectionBiomapsDescription: frMessages["section.biomaps.description"],
    sectionProductsCategory: frMessages["section.products.category"],
    sectionPricesCategory: frMessages["section.prices.category"],
    sectionPricesTitle: frMessages["section.prices.title"],
    sectionPricesDescription: frMessages["section.prices.description"],
    sectionClientsCategory: frMessages["section.clients.category"],
    sectionClientsTitle: frMessages["section.clients.title"],
    sectionPartnersCategory: frMessages["section.partners.category"],

    dashboardWelcome: frMessages["dashboard.welcome"],
    dashboardClients: frMessages["dashboard.clients"],
    dashboardUsers: frMessages["dashboard.users"],
    dashboardTotalSales: frMessages["dashboard.total.sales"],
    dashboardHectaresCovered: frMessages["dashboard.hectares.covered"],
    dashboardNewCompany: frMessages["dashboard.new.company"],
    dashboardFlightClient: frMessages["dashboard.flight.client"],
    dashboardCoverageGraph: frMessages["dashboard.coverage.graph"],

    infoTitle: frMessages["info.title"],
    infoDescription: frMessages["info.description"],
    infoNewsletter: frMessages["info.newsletter"],
    infoAbout: frMessages["info.about"],
    infoCopyright: frMessages["info.copyright"],
    infoAddress: frMessages["info.address"],
    infoEmail: frMessages["info.email"],
    infoPhone: frMessages["info.phone"],
    infoTerms: frMessages["info.terms"],
    infoSummary: frMessages["info.summary"],
    infoRegisterInfo: frMessages["info.register.information"],
    infoRegisterTerms: frMessages["info.register.terms"],
    infoLegalInfo: frMessages["info.legal.information"],
    infoLegalTerms: frMessages["info.legal.terms"],
    infoPlansInfo: frMessages["info.plans.information"],
    infoAmount: frMessages["info.amount"],
    infoPlan: frMessages["info.plan"],
    infoMode: frMessages["info.mode"],
    infoPrice: frMessages["info.price"],
    infoPerYear: frMessages["info.per.year"],
    infoTotal: frMessages["info.total"],
    infoUsers: frMessages["info.users"],
    infoContract: frMessages["info.contract"],
    infoAddNew: frMessages["info.add.new"],
    infoClients: frMessages["info.clients"],
    infoTermError: frMessages["info.termError"],
    infoMenuOptions: frMessages["info.menuOptions"],

    infoAboutTitle: frMessages["info.aboutTitle"],
    infoAboutNCB: frMessages["info.aboutNCB"],
    infoAboutDescription: frMessages["info.aboutDescription"],
    infoAboutVisionTitle: frMessages["info.aboutVisionTitle"],
    infoAboutVisionSub: frMessages["info.aboutVisionSub"],
    infoAboutVisionText: frMessages["info.aboutVisionText"],
    infoAboutMissionTitle: frMessages["info.aboutMissionTitle"],
    infoAboutMissionSub: frMessages["info.aboutMissionSub"],
    infoAboutMissionText: frMessages["info.aboutMissionText"],
    infoAboutValues: frMessages["info.aboutValues"],
    infoContactTitlte: frMessages["info.contactTitle"],
    infoContactDescription: frMessages["info.contactDescription"],
    infoTermTitle: frMessages["info.termTitle"],
    infoCoin: frMessages["info.coin"],
    infoTermPriceMonth: frMessages["info.termPriceMonth"],
    infoTermPriceYear: frMessages["info.termPriceYear"],
    infoTermSpecifications: frMessages["info.termSpecifications"],
    infoTermSpecificationsDesc: frMessages["info.termSpecificationsDesc"],
    infoTermClassifications: frMessages["info.termClassifications"],
    infoTermClassificationsDesc: frMessages["info.termClassificationsDesc"],
    infoTermChoose: frMessages["info.termChoose"],
    infoIntroducing: frMessages["info.introducing"],
    infoCameraQuality: frMessages["info.cameraQuality"],
    infoFlightTime: frMessages["info.flightTime"],
    infoCapacity: frMessages["info.capacity"],
    infoMinutes: frMessages["info.minutes"],
    infoVerificationTitle: frMessages["info.verificationTitle"],
    infoVerificationDescription: frMessages["info.verificationDescription"],
    infoEmailAddress: frMessages["info.emailAddress"],
    infoVerificationCodeDescription: frMessages["info.verificationCodeDescription"],
    infoConfirmationCode: frMessages["info.confirmationCode"],
    infoNewPassword: frMessages["info.newPassword"],
    infoNewPasswordDescription: frMessages["info.newPasswordDescription"],
    infoNewPasswordDetails: frMessages["info.newPasswordDetails"],
    infoNewPasswordConfirm: frMessages["info.newPasswordConfirm"],

    prodBiocoteDescription: frMessages["prod.biocoteDescription"],
    prodUsageSugarcane: frMessages["prod.usageSugarcane"],
    prodUsageForest: frMessages["prod.usageForest"],
    prodUsageCotton: frMessages["prod.usageCotton"],
    prodUsageCorn: frMessages["prod.usageCorn"],
    prodUsageSoybeans: frMessages["prod.usageSoybeans"],

    menuAbout: frMessages["menu.about"],
    menuProducts: frMessages["menu.products"],
    menuPricing: frMessages["menu.pricing"],
    menuContact: frMessages["menu.contact"],
    menuPrivacy: frMessages["menu.privacy"],
    menuTerms: frMessages["menu.terms"],
    menuTitle: frMessages["menu.title"],
    menuSupport: frMessages["menu.support"],
    menuFaq: frMessages["menu.faq"],
    menuResources: frMessages["menu.resources"],
    menuWhy: frMessages["menu.why"],
    menuHome: frMessages["menu.home"],
    menuBiomaps: frMessages["menu.biomaps"],
    menuProfile: frMessages["menu.profile"],
    menuPlans: frMessages["menu.plans"],
    menuSettings: frMessages["menu.settings"],
    menuOpenProject: frMessages["menu.open.project"],
    menuNewProject: frMessages["menu.new.project"],
    menuNewMonitoring: frMessages["menu.new.monitoring"],
    menuFlightMonitoring: frMessages["menu.flight.monitoring"],
    menuTrapMonitoring: frMessages["menu.trap.monitoring"],
    menuBiomapsInfo: frMessages["menu.biomapsInfo"],
    menuLicenses: frMessages["menu.licenses"],
    menuTraps: frMessages["menu.traps"],

    actionLogin: frMessages["action.login"],
    actionSignUp: frMessages["action.signUp"],
    actionSubscribe: frMessages["action.subscribe"],
    actionRegister: frMessages["action.register"],
    actionMakeRegistration: frMessages["action.makeRegistration"],
    actionSuccess: frMessages["action.success"],
    actionError: frMessages["action.error"],
    actionMsgSuccess: frMessages["action.msgSuccess"],
    actionMsgError: frMessages["action.msgError"],
    actionBack: frMessages["action.back"],
    actionGetStarted: frMessages["action.getStarted"],
    actionLearnMore: frMessages["action.learnMore"],
    actionMore: frMessages["action.more"],
    actionKeepConnected: frMessages["action.keep.connected"],
    actionLoginMsg: frMessages["action.login.message"],
    actionLoginMsgError: frMessages["action.login.msgError"],
    actionContinue: frMessages["action.continue"],
    actionAccept: frMessages["action.accept"],
    actionIndicator: frMessages["action.indicator"],
    actionSignupCompany: frMessages["action.company"],
    actionSignupSingle: frMessages["action.single"],
    actionAcceptSignupTerms: frMessages["action.accept.signup.terms"],
    actionPayNow: frMessages["action.pay.now"],
    actionAddNew: frMessages["action.add.new"],
    actionEdit: frMessages["action.edit"],
    actionEditPicture: frMessages["action.edit.picture"],
    actionRemove: frMessages["action.remove"],
    actionSave: frMessages["action.save"],
    actionCancel: frMessages["action.cancel"],
    actionIncome: frMessages["action.income"],
    actionSend: frMessages["action.send"],
    actionConfirm: frMessages["action.confirm"],
    actionSendVerification: frMessages["action.sendVerification"],
    actionSaveData: frMessages["action.saveData"],

    placeholderEmail: frMessages["placeholder.email"],
    placeholderName: frMessages["placeholder.name"],
    placeholderPhone: frMessages["placeholder.phone"],
    placeholderCompany: frMessages["placeholder.company"],
    placeholderDataCollect: frMessages["placeholder.data.collect"],
    placeholderPassword: frMessages["placeholder.password"],
    placeholderSearch: frMessages["placeholder.search"],
    placeholderIVA: frMessages["placeholder.iva"],
    placeholderCompanyName: frMessages["placeholder.company.name"],
    placeholderZIP: frMessages["placeholder.zip"],
    placeholderAddress: frMessages["placeholder.address"],
    placeholderNumber: frMessages["placeholder.number"],
    placeholderComplement: frMessages["placeholder.complement"],
    placeholderCountry: frMessages["placeholder.country"],
    placeholderStateDepartment: frMessages["placeholder.state.department"],
    placeholderComercialPhone: frMessages["placeholder.comercial.phone"],
    placeholderAviationReg: frMessages["placeholder.aviation.reg"],
    placeholderAgricultureReg: frMessages["placeholder.agriculture.reg"],
    placeholderRole: frMessages["placeholder.role"],
    placeholderDocument: frMessages["placeholder.document"],
    placeholderRg: frMessages["placeholder.rg"],
    placeholderCrea: frMessages["placeholder.crea"],
    placeholderIE: frMessages["placeholder.ie"],
    placeholderIM: frMessages["placeholder.im"],
    placeholderTradeName: frMessages["placeholder.trade.name"],
    placeholderType: frMessages["placeholder.type"],
    placeholderContact: frMessages["placeholder.contact"],
    placeholderDescription: frMessages["placeholder.description"],
    placeholderMessage: frMessages["placeholder.message"],

    optionSearchEngine: frMessages["option.search.engine"],
    optionFfacebook: frMessages["option.facebook"],
    optionLinkedin: frMessages["option.linkedin"],
    optionRecommendation: frMessages["option.recommendation"],
    optionEmailMarketing: frMessages["option.email.marketing"],
    optionEvents: frMessages["option.events"],
    optionOthers: frMessages["option.others"],
    optionWhat: frMessages["option.which"],

    languagePT: frMessages["language.pt"],
    languageES: frMessages["language.es"],
    languageEN: frMessages["language.en"],
    languageFR: frMessages["language.fr"],
    languageName: frMessages["language.name"],
    languageRegion: frMessages["language.region"],

    reportIssueDate: frMessages["report.issueDate"],
    reportArea: frMessages["report.area"],
    reportPlannedFlight: frMessages["report.plannedFlight"],
    reportDetails: frMessages["report.details"],
    reportDateHour: frMessages["report.dateHour"],
    reportDrone: frMessages["report.drone"],
    reportManufacturer: frMessages["report.manufacturer"],
    reportAnac: frMessages["report.anac"],
    reportReleasedProduct: frMessages["report.releasedProduct"],
    reportReleasing: frMessages["report.releasing"],
    reportBandWidth: frMessages["report.bandWidth"],
    reportPlannedSpeed: frMessages["report.plannedSpeed"],
    reportReleaseRate: frMessages["report.releaseRate"],
    reportRealFlight: frMessages["report.realFlight"],
    reportFlightTime: frMessages["report.flightTime"],
    reportAverageReleaseRate: frMessages["report.averageReleaseRate"],
    reportAverageSpeed: frMessages["report.averageSpeed"],
    reportTotalReleased: frMessages["report.totalReleased"],
    reportTotalArea: frMessages["report.totalArea"],
    reportTraps: frMessages["report.traps"],
    reportSerialNumber: frMessages["report.serialNumber"],
    reportCoveredHectares: frMessages["report.coveredHectares"],
    reportDateMeasurament: frMessages["report.dateMeasurament"],
    reportSpecie: frMessages["report.specie"],
    reportAdultsNumber: frMessages["report.adultsNumber"],
    reportNoAvailable: frMessages["report.noAvailable"],
    reportMapRecord: frMessages["report.mapRecord"],
    reportContractor: frMessages["report.contractor"],
    reportProperty: frMessages["report.property"],
    reportLocalization: frMessages["report.localization"],
    reportCounty: frMessages["report.county"],
    reportUF: frMessages["report.uf"],
    reportDoc: frMessages["report.doc"],
    reportServiceType: frMessages["report.serviceType"],
    reportProduct: frMessages["report.product"],
    reportFormulation: frMessages["report.formulation"],
    reportDosage: frMessages["report.dosage"],
    reportToxicClass: frMessages["report.toxicClass"],
    reportAdjuvant: frMessages["report.adjuvant"],
    reportCulture: frMessages["report.culture"],
    reportVolume: frMessages["report.volume"],
    reportOthers: frMessages["report.others"],
    reportAgronomicalRecipe: frMessages["report.agronomicalRecipe"],
    reportIssuedOn: frMessages["report.issuedOn"],
    reportBasicParameter: frMessages["report.basicParameter"],
    reportMaxTemperature: frMessages["report.maxTemperature"],
    reportRelativeUnitMin: frMessages["report.relativeUnitMin"],
    reportEquipment: frMessages["report.equipment"],
    reportModel: frMessages["report.model"],
    reportType: frMessages["report.type"],
    reportAngle: frMessages["report.angle"],
    reportFlightHeight: frMessages["report.flightHeight"],
    reportBandwidth: frMessages["report.bandwidth"],
    reportDate: frMessages["report.date"],
    reportSignatureCREA: frMessages["report.signatureCREA"],
    reportWeatherConditions: frMessages["report.weatherConditions"],
    reportMorning: frMessages["report.morning"],
    reportEvening: frMessages["report.evening"],
    reportApplicationStart: frMessages["report.applicationStart"],
    reportInitial: frMessages["report.initial"],
    reportFinal: frMessages["report.final"],
    reportTemperature: frMessages["report.temperature"],
    reportRelativeHumidity: frMessages["report.relativeHumanity"],
    reportAircraftPrefixe: frMessages["report.aircraftPrefixe"],
    reportDgpsUse: frMessages["report.dgpsUse"],
    reportWindSpeed: frMessages["report.windSpeed"],
    reportTrackCoordinates: frMessages["report.trackCoordinates"],
    reportObservation: frMessages["report.observation"],
    reportSignatureEng: frMessages["report.signatureEng"],
  },
  // Espanhol
  {
    localeCode: "es",
    appTitle: esMessages["app.title"],

    infoPageTitle: esMessages["info.pageTitle"],
    infoPageSlogan: esMessages["info.slogan"],
    pageTitle: esMessages["page.title"],
    pageDescription: esMessages["page.description"],
    sectionAboutTitle: esMessages["section.about.title"],
    sectionAboutCategory: esMessages["section.about.category"],
    sectionAboutInfoTitle: esMessages["section.about.info.title"],
    sectionAboutInfoSubtitle: esMessages["section.about.info.subtitle"],
    sectionAboutInfoDescription: esMessages["section.about.info.description"],
    sectionBiomapsTitle: esMessages["section.biomaps.title"],
    sectionBiomapsSubtitle: esMessages["section.biomaps.subtitle"],
    sectionBiomapsDescription: esMessages["section.biomaps.description"],
    sectionProductsCategory: esMessages["section.products.category"],
    sectionPricesCategory: esMessages["section.prices.category"],
    sectionPricesTitle: esMessages["section.prices.title"],
    sectionPricesDescription: esMessages["section.prices.description"],
    sectionClientsCategory: esMessages["section.clients.category"],
    sectionClientsTitle: esMessages["section.clients.title"],
    sectionPartnersCategory: esMessages["section.partners.category"],

    dashboardWelcome: esMessages["dashboard.welcome"],
    dashboardClients: esMessages["dashboard.clients"],
    dashboardUsers: esMessages["dashboard.users"],
    dashboardTotalSales: esMessages["dashboard.total.sales"],
    dashboardHectaresCovered: esMessages["dashboard.hectares.covered"],
    dashboardNewCompany: esMessages["dashboard.new.company"],
    dashboardFlightClient: esMessages["dashboard.flight.client"],
    dashboardCoverageGraph: esMessages["dashboard.coverage.graph"],

    infoTitle: esMessages["info.title"],
    infoDescription: esMessages["info.description"],
    infoNewsletter: esMessages["info.newsletter"],
    infoAbout: esMessages["info.about"],
    infoCopyright: esMessages["info.copyright"],
    infoAddress: esMessages["info.address"],
    infoEmail: esMessages["info.email"],
    infoPhone: esMessages["info.phone"],
    infoTerms: esMessages["info.terms"],
    infoSummary: esMessages["info.summary"],
    infoRegisterInfo: esMessages["info.register.information"],
    infoRegisterTerms: esMessages["info.register.terms"],
    infoLegalInfo: esMessages["info.legal.information"],
    infoLegalTerms: esMessages["info.legal.terms"],
    infoPlansInfo: esMessages["info.plans.information"],
    infoAmount: esMessages["info.amount"],
    infoPlan: esMessages["info.plan"],
    infoMode: esMessages["info.mode"],
    infoPrice: esMessages["info.price"],
    infoPerYear: esMessages["info.per.year"],
    infoTotal: esMessages["info.total"],
    infoUsers: esMessages["info.users"],
    infoContract: esMessages["info.contract"],
    infoAddNew: esMessages["info.add.new"],
    infoClients: esMessages["info.clients"],
    infoTermError: esMessages["info.termError"],
    infoMenuOptions: esMessages["info.menuOptions"],

    infoAboutTitle: esMessages["info.aboutTitle"],
    infoAboutNCB: esMessages["info.aboutNCB"],
    infoAboutDescription: esMessages["info.aboutDescription"],
    infoAboutVisionTitle: esMessages["info.aboutVisionTitle"],
    infoAboutVisionSub: esMessages["info.aboutVisionSub"],
    infoAboutVisionText: esMessages["info.aboutVisionText"],
    infoAboutMissionTitle: esMessages["info.aboutMissionTitle"],
    infoAboutMissionSub: esMessages["info.aboutMissionSub"],
    infoAboutMissionText: esMessages["info.aboutMissionText"],
    infoAboutValues: esMessages["info.aboutValues"],
    infoContactTitlte: esMessages["info.contactTitle"],
    infoContactDescription: esMessages["info.contactDescription"],
    infoTermTitle: esMessages["info.termTitle"],
    infoCoin: esMessages["info.coin"],
    infoTermPriceMonth: esMessages["info.termPriceMonth"],
    infoTermPriceYear: esMessages["info.termPriceYear"],
    infoTermSpecifications: esMessages["info.termSpecifications"],
    infoTermSpecificationsDesc: esMessages["info.termSpecificationsDesc"],
    infoTermClassifications: esMessages["info.termClassifications"],
    infoTermClassificationsDesc: esMessages["info.termClassificationsDesc"],
    infoTermChoose: esMessages["info.termChoose"],
    infoIntroducing: esMessages["info.introducing"],
    infoCameraQuality: esMessages["info.cameraQuality"],
    infoFlightTime: esMessages["info.flightTime"],
    infoCapacity: esMessages["info.capacity"],
    infoMinutes: esMessages["info.minutes"],
    infoVerificationTitle: esMessages["info.verificationTitle"],
    infoVerificationDescription: esMessages["info.verificationDescription"],
    infoEmailAddress: esMessages["info.emailAddress"],
    infoVerificationCodeDescription: esMessages["info.verificationCodeDescription"],
    infoConfirmationCode: esMessages["info.confirmationCode"],
    infoNewPassword: esMessages["info.newPassword"],
    infoNewPasswordDescription: esMessages["info.newPasswordDescription"],
    infoNewPasswordDetails: esMessages["info.newPasswordDetails"],
    infoNewPasswordConfirm: esMessages["info.newPasswordConfirm"],

    prodBiocoteDescription: esMessages["prod.biocoteDescription"],
    prodUsageSugarcane: esMessages["prod.usageSugarcane"],
    prodUsageForest: esMessages["prod.usageForest"],
    prodUsageCotton: esMessages["prod.usageCotton"],
    prodUsageCorn: esMessages["prod.usageCorn"],
    prodUsageSoybeans: esMessages["prod.usageSoybeans"],

    menuAbout: esMessages["menu.about"],
    menuProducts: esMessages["menu.products"],
    menuPricing: esMessages["menu.pricing"],
    menuContact: esMessages["menu.contact"],
    menuPrivacy: esMessages["menu.privacy"],
    menuTerms: esMessages["menu.terms"],
    menuTitle: esMessages["menu.title"],
    menuSupport: esMessages["menu.support"],
    menuFaq: esMessages["menu.faq"],
    menuResources: esMessages["menu.resources"],
    menuWhy: esMessages["menu.why"],
    menuHome: esMessages["menu.home"],
    menuBiomaps: esMessages["menu.biomaps"],
    menuProfile: esMessages["menu.profile"],
    menuPlans: esMessages["menu.plans"],
    menuSettings: esMessages["menu.settings"],
    menuOpenProject: esMessages["menu.open.project"],
    menuNewProject: esMessages["menu.new.project"],
    menuNewMonitoring: esMessages["menu.new.monitoring"],
    menuFlightMonitoring: esMessages["menu.flight.monitoring"],
    menuTrapMonitoring: esMessages["menu.trap.monitoring"],
    menuBiomapsInfo: esMessages["menu.biomapsInfo"],
    menuLicenses: esMessages["menu.licenses"],
    menuTraps: esMessages["menu.traps"],

    actionLogin: esMessages["action.login"],
    actionSignUp: esMessages["action.signUp"],
    actionSubscribe: esMessages["action.subscribe"],
    actionRegister: esMessages["action.register"],
    actionMakeRegistration: esMessages["action.makeRegistration"],
    actionSuccess: esMessages["action.success"],
    actionError: esMessages["action.error"],
    actionMsgSuccess: esMessages["action.msgSuccess"],
    actionMsgError: esMessages["action.msgError"],
    actionBack: esMessages["action.back"],
    actionGetStarted: esMessages["action.getStarted"],
    actionLearnMore: esMessages["action.learnMore"],
    actionMore: esMessages["action.more"],
    actionKeepConnected: esMessages["action.keep.connected"],
    actionLoginMsg: esMessages["action.login.message"],
    actionLoginMsgError: esMessages["action.login.msgError"],
    actionContinue: esMessages["action.continue"],
    actionAccept: esMessages["action.accept"],
    actionIndicator: esMessages["action.indicator"],
    actionSignupCompany: esMessages["action.company"],
    actionSignupSingle: esMessages["action.single"],
    actionAcceptSignupTerms: esMessages["action.accept.signup.terms"],
    actionPayNow: esMessages["action.pay.now"],
    actionAddNew: esMessages["action.add.new"],
    actionEdit: esMessages["action.edit"],
    actionEditPicture: esMessages["action.edit.picture"],
    actionRemove: esMessages["action.remove"],
    actionSave: esMessages["action.save"],
    actionCancel: esMessages["action.cancel"],
    actionIncome: esMessages["action.income"],
    actionSend: esMessages["action.send"],
    actionConfirm: esMessages["action.confirm"],
    actionSendVerification: esMessages["action.sendVerification"],
    actionSaveData: esMessages["action.saveData"],

    placeholderEmail: esMessages["placeholder.email"],
    placeholderName: esMessages["placeholder.name"],
    placeholderPhone: esMessages["placeholder.phone"],
    placeholderCompany: esMessages["placeholder.company"],
    placeholderDataCollect: esMessages["placeholder.data.collect"],
    placeholderPassword: esMessages["placeholder.password"],
    placeholderSearch: esMessages["placeholder.search"],
    placeholderIVA: esMessages["placeholder.iva"],
    placeholderCompanyName: esMessages["placeholder.company.name"],
    placeholderZIP: esMessages["placeholder.zip"],
    placeholderAddress: esMessages["placeholder.address"],
    placeholderNumber: esMessages["placeholder.number"],
    placeholderComplement: esMessages["placeholder.complement"],
    placeholderCountry: esMessages["placeholder.country"],
    placeholderStateDepartment: esMessages["placeholder.state.department"],
    placeholderComercialPhone: esMessages["placeholder.comercial.phone"],
    placeholderAviationReg: esMessages["placeholder.aviation.reg"],
    placeholderAgricultureReg: esMessages["placeholder.agriculture.reg"],
    placeholderRole: esMessages["placeholder.role"],
    placeholderDocument: esMessages["placeholder.document"],
    placeholderRg: esMessages["placeholder.rg"],
    placeholderCrea: esMessages["placeholder.crea"],
    placeholderIE: esMessages["placeholder.ie"],
    placeholderIM: esMessages["placeholder.im"],
    placeholderTradeName: esMessages["placeholder.trade.name"],
    placeholderType: esMessages["placeholder.type"],
    placeholderContact: esMessages["placeholder.contact"],
    placeholderDescription: esMessages["placeholder.description"],
    placeholderMessage: esMessages["placeholder.message"],

    optionSearchEngine: esMessages["option.search.engine"],
    optionFfacebook: esMessages["option.facebook"],
    optionLinkedin: esMessages["option.linkedin"],
    optionRecommendation: esMessages["option.recommendation"],
    optionEmailMarketing: esMessages["option.email.marketing"],
    optionEvents: esMessages["option.events"],
    optionOthers: esMessages["option.others"],
    optionWhat: esMessages["option.which"],

    languagePT: esMessages["language.pt"],
    languageES: esMessages["language.es"],
    languageEN: esMessages["language.en"],
    languageFR: esMessages["language.fr"],
    languageName: esMessages["language.name"],
    languageRegion: esMessages["language.region"],

    reportIssueDate: esMessages["report.issueDate"],
    reportArea: esMessages["report.area"],
    reportPlannedFlight: esMessages["report.plannedFlight"],
    reportDetails: esMessages["report.details"],
    reportDateHour: esMessages["report.dateHour"],
    reportDrone: esMessages["report.drone"],
    reportManufacturer: esMessages["report.manufacturer"],
    reportAnac: esMessages["report.anac"],
    reportReleasedProduct: esMessages["report.releasedProduct"],
    reportReleasing: esMessages["report.releasing"],
    reportBandWidth: esMessages["report.bandWidth"],
    reportPlannedSpeed: esMessages["report.plannedSpeed"],
    reportReleaseRate: esMessages["report.releaseRate"],
    reportRealFlight: esMessages["report.realFlight"],
    reportFlightTime: esMessages["report.flightTime"],
    reportAverageReleaseRate: esMessages["report.averageReleaseRate"],
    reportAverageSpeed: esMessages["report.averageSpeed"],
    reportTotalReleased: esMessages["report.totalReleased"],
    reportTotalArea: esMessages["report.totalArea"],
    reportTraps: esMessages["report.traps"],
    reportSerialNumber: esMessages["report.serialNumber"],
    reportCoveredHectares: esMessages["report.coveredHectares"],
    reportDateMeasurament: esMessages["report.dateMeasurament"],
    reportSpecie: esMessages["report.specie"],
    reportAdultsNumber: esMessages["report.adultsNumber"],
    reportNoAvailable: esMessages["report.noAvailable"],
    reportMapRecord: esMessages["report.mapRecord"],
    reportContractor: esMessages["report.contractor"],
    reportProperty: esMessages["report.property"],
    reportLocalization: esMessages["report.localization"],
    reportCounty: esMessages["report.county"],
    reportUF: esMessages["report.uf"],
    reportDoc: esMessages["report.doc"],
    reportServiceType: esMessages["report.serviceType"],
    reportProduct: esMessages["report.product"],
    reportFormulation: esMessages["report.formulation"],
    reportDosage: esMessages["report.dosage"],
    reportToxicClass: esMessages["report.toxicClass"],
    reportAdjuvant: esMessages["report.adjuvant"],
    reportCulture: esMessages["report.culture"],
    reportVolume: esMessages["report.volume"],
    reportOthers: esMessages["report.others"],
    reportAgronomicalRecipe: esMessages["report.agronomicalRecipe"],
    reportIssuedOn: esMessages["report.issuedOn"],
    reportBasicParameter: esMessages["report.basicParameter"],
    reportMaxTemperature: esMessages["report.maxTemperature"],
    reportRelativeUnitMin: esMessages["report.relativeUnitMin"],
    reportEquipment: esMessages["report.equipment"],
    reportModel: esMessages["report.model"],
    reportType: esMessages["report.type"],
    reportAngle: esMessages["report.angle"],
    reportFlightHeight: esMessages["report.flightHeight"],
    reportBandwidth: esMessages["report.bandwidth"],
    reportDate: esMessages["report.date"],
    reportSignatureCREA: esMessages["report.signatureCREA"],
    reportWeatherConditions: esMessages["report.weatherConditions"],
    reportMorning: esMessages["report.morning"],
    reportEvening: esMessages["report.evening"],
    reportApplicationStart: esMessages["report.applicationStart"],
    reportInitial: esMessages["report.initial"],
    reportFinal: esMessages["report.final"],
    reportTemperature: esMessages["report.temperature"],
    reportRelativeHumidity: esMessages["report.relativeHumanity"],
    reportAircraftPrefixe: esMessages["report.aircraftPrefixe"],
    reportDgpsUse: esMessages["report.dgpsUse"],
    reportWindSpeed: esMessages["report.windSpeed"],
    reportTrackCoordinates: esMessages["report.trackCoordinates"],
    reportObservation: esMessages["report.observation"],
    reportSignatureEng: esMessages["report.signatureEng"],
  },
  // Inglês
  {
    localeCode: "en",
    appTitle: enMessages["app.title"],

    infoPageTitle: enMessages["info.pageTitle"],
    infoPageSlogan: enMessages["info.slogan"],
    pageTitle: enMessages["page.title"],
    pageDescription: enMessages["page.description"],
    sectionAboutTitle: enMessages["section.about.title"],
    sectionAboutCategory: enMessages["section.about.category"],
    sectionAboutInfoTitle: enMessages["section.about.info.title"],
    sectionAboutInfoSubtitle: enMessages["section.about.info.subtitle"],
    sectionAboutInfoDescription: enMessages["section.about.info.description"],
    sectionBiomapsTitle: enMessages["section.biomaps.title"],
    sectionBiomapsSubtitle: enMessages["section.biomaps.subtitle"],
    sectionBiomapsDescription: enMessages["section.biomaps.description"],
    sectionProductsCategory: enMessages["section.products.category"],
    sectionPricesCategory: enMessages["section.prices.category"],
    sectionPricesTitle: enMessages["section.prices.title"],
    sectionPricesDescription: enMessages["section.prices.description"],
    sectionClientsCategory: enMessages["section.clients.category"],
    sectionClientsTitle: enMessages["section.clients.title"],
    sectionPartnersCategory: enMessages["section.partners.category"],

    dashboardWelcome: enMessages["dashboard.welcome"],
    dashboardClients: enMessages["dashboard.clients"],
    dashboardUsers: enMessages["dashboard.users"],
    dashboardTotalSales: enMessages["dashboard.total.sales"],
    dashboardHectaresCovered: enMessages["dashboard.hectares.covered"],
    dashboardNewCompany: enMessages["dashboard.new.company"],
    dashboardFlightClient: enMessages["dashboard.flight.client"],
    dashboardCoverageGraph: enMessages["dashboard.coverage.graph"],

    infoTitle: enMessages["info.title"],
    infoDescription: enMessages["info.description"],
    infoNewsletter: enMessages["info.newsletter"],
    infoAbout: enMessages["info.about"],
    infoCopyright: enMessages["info.copyright"],
    infoAddress: enMessages["info.address"],
    infoEmail: enMessages["info.email"],
    infoPhone: enMessages["info.phone"],
    infoTerms: enMessages["info.terms"],
    infoSummary: enMessages["info.summary"],
    infoRegisterInfo: enMessages["info.register.information"],
    infoRegisterTerms: enMessages["info.register.terms"],
    infoLegalInfo: enMessages["info.legal.information"],
    infoLegalTerms: enMessages["info.legal.terms"],
    infoPlansInfo: enMessages["info.plans.information"],
    infoAmount: enMessages["info.amount"],
    infoPlan: enMessages["info.plan"],
    infoMode: enMessages["info.mode"],
    infoPrice: enMessages["info.price"],
    infoPerYear: enMessages["info.per.year"],
    infoTotal: enMessages["info.total"],
    infoUsers: enMessages["info.users"],
    infoContract: enMessages["info.contract"],
    infoAddNew: enMessages["info.add.new"],
    infoClients: enMessages["info.clients"],
    infoTermError: enMessages["info.termError"],
    infoMenuOptions: enMessages["info.menuOptions"],

    infoAboutTitle: enMessages["info.aboutTitle"],
    infoAboutNCB: enMessages["info.aboutNCB"],
    infoAboutDescription: enMessages["info.aboutDescription"],
    infoAboutVisionTitle: enMessages["info.aboutVisionTitle"],
    infoAboutVisionSub: enMessages["info.aboutVisionSub"],
    infoAboutVisionText: enMessages["info.aboutVisionText"],
    infoAboutMissionTitle: enMessages["info.aboutMissionTitle"],
    infoAboutMissionSub: enMessages["info.aboutMissionSub"],
    infoAboutMissionText: enMessages["info.aboutMissionText"],
    infoAboutValues: enMessages["info.aboutValues"],
    infoContactTitlte: enMessages["info.contactTitle"],
    infoContactDescription: enMessages["info.contactDescription"],
    infoTermTitle: enMessages["info.termTitle"],
    infoCoin: enMessages["info.coin"],
    infoTermPriceMonth: enMessages["info.termPriceMonth"],
    infoTermPriceYear: enMessages["info.termPriceYear"],
    infoTermSpecifications: enMessages["info.termSpecifications"],
    infoTermSpecificationsDesc: enMessages["info.termSpecificationsDesc"],
    infoTermClassifications: enMessages["info.termClassifications"],
    infoTermClassificationsDesc: enMessages["info.termClassificationsDesc"],
    infoTermChoose: enMessages["info.termChoose"],
    infoIntroducing: enMessages["info.introducing"],
    infoCameraQuality: enMessages["info.cameraQuality"],
    infoFlightTime: enMessages["info.flightTime"],
    infoCapacity: enMessages["info.capacity"],
    infoMinutes: enMessages["info.minutes"],
    infoVerificationTitle: enMessages["info.verificationTitle"],
    infoVerificationDescription: enMessages["info.verificationDescription"],
    infoEmailAddress: enMessages["info.emailAddress"],
    infoVerificationCodeDescription: enMessages["info.verificationCodeDescription"],
    infoConfirmationCode: enMessages["info.confirmationCode"],
    infoNewPassword: enMessages["info.newPassword"],
    infoNewPasswordDescription: enMessages["info.newPasswordDescription"],
    infoNewPasswordDetails: enMessages["info.newPasswordDetails"],
    infoNewPasswordConfirm: enMessages["info.newPasswordConfirm"],

    prodBiocoteDescription: enMessages["prod.biocoteDescription"],
    prodUsageSugarcane: enMessages["prod.usageSugarcane"],
    prodUsageForest: enMessages["prod.usageForest"],
    prodUsageCotton: enMessages["prod.usageCotton"],
    prodUsageCorn: enMessages["prod.usageCorn"],
    prodUsageSoybeans: enMessages["prod.usageSoybeans"],

    menuAbout: enMessages["menu.about"],
    menuProducts: enMessages["menu.products"],
    menuPricing: enMessages["menu.pricing"],
    menuContact: enMessages["menu.contact"],
    menuPrivacy: enMessages["menu.privacy"],
    menuTerms: enMessages["menu.terms"],
    menuTitle: enMessages["menu.title"],
    menuSupport: enMessages["menu.support"],
    menuFaq: enMessages["menu.faq"],
    menuResources: enMessages["menu.resources"],
    menuWhy: enMessages["menu.why"],
    menuHome: enMessages["menu.home"],
    menuBiomaps: enMessages["menu.biomaps"],
    menuProfile: enMessages["menu.profile"],
    menuPlans: enMessages["menu.plans"],
    menuSettings: enMessages["menu.settings"],
    menuOpenProject: enMessages["menu.open.project"],
    menuNewProject: enMessages["menu.new.project"],
    menuNewMonitoring: enMessages["menu.new.monitoring"],
    menuFlightMonitoring: enMessages["menu.flight.monitoring"],
    menuTrapMonitoring: enMessages["menu.trap.monitoring"],
    menuBiomapsInfo: enMessages["menu.biomapsInfo"],
    menuLicenses: enMessages["menu.licenses"],
    menuTraps: enMessages["menu.traps"],

    actionLogin: enMessages["action.login"],
    actionSignUp: enMessages["action.signUp"],
    actionSubscribe: enMessages["action.subscribe"],
    actionRegister: enMessages["action.register"],
    actionMakeRegistration: enMessages["action.makeRegistration"],
    actionSuccess: enMessages["action.success"],
    actionError: enMessages["action.error"],
    actionMsgSuccess: enMessages["action.msgSuccess"],
    actionMsgError: enMessages["action.msgError"],
    actionBack: enMessages["action.back"],
    actionGetStarted: enMessages["action.getStarted"],
    actionLearnMore: enMessages["action.learnMore"],
    actionMore: enMessages["action.more"],
    actionKeepConnected: enMessages["action.keep.connected"],
    actionLoginMsg: enMessages["action.login.message"],
    actionLoginMsgError: enMessages["action.login.msgError"],
    actionContinue: enMessages["action.continue"],
    actionAccept: enMessages["action.accept"],
    actionIndicator: enMessages["action.indicator"],
    actionSignupCompany: enMessages["action.company"],
    actionSignupSingle: enMessages["action.single"],
    actionAcceptSignupTerms: enMessages["action.accept.signup.terms"],
    actionPayNow: enMessages["action.pay.now"],
    actionAddNew: enMessages["action.add.new"],
    actionEdit: enMessages["action.edit"],
    actionEditPicture: enMessages["action.edit.picture"],
    actionRemove: enMessages["action.remove"],
    actionSave: enMessages["action.save"],
    actionCancel: enMessages["action.cancel"],
    actionIncome: enMessages["action.income"],
    actionSend: enMessages["action.send"],
    actionConfirm: enMessages["action.confirm"],
    actionSendVerification: enMessages["action.sendVerification"],
    actionSaveData: enMessages["action.saveData"],

    placeholderEmail: enMessages["placeholder.email"],
    placeholderName: enMessages["placeholder.name"],
    placeholderPhone: enMessages["placeholder.phone"],
    placeholderCompany: enMessages["placeholder.company"],
    placeholderDataCollect: enMessages["placeholder.data.collect"],
    placeholderPassword: enMessages["placeholder.password"],
    placeholderSearch: enMessages["placeholder.search"],
    placeholderIVA: enMessages["placeholder.iva"],
    placeholderCompanyName: enMessages["placeholder.company.name"],
    placeholderZIP: enMessages["placeholder.zip"],
    placeholderAddress: enMessages["placeholder.address"],
    placeholderNumber: enMessages["placeholder.number"],
    placeholderComplement: enMessages["placeholder.complement"],
    placeholderCountry: enMessages["placeholder.country"],
    placeholderStateDepartment: enMessages["placeholder.state.department"],
    placeholderComercialPhone: enMessages["placeholder.comercial.phone"],
    placeholderAviationReg: enMessages["placeholder.aviation.reg"],
    placeholderAgricultureReg: enMessages["placeholder.agriculture.reg"],
    placeholderRole: enMessages["placeholder.role"],
    placeholderDocument: enMessages["placeholder.document"],
    placeholderRg: enMessages["placeholder.rg"],
    placeholderCrea: enMessages["placeholder.crea"],
    placeholderIE: enMessages["placeholder.ie"],
    placeholderIM: enMessages["placeholder.im"],
    placeholderTradeName: enMessages["placeholder.trade.name"],
    placeholderType: enMessages["placeholder.type"],
    placeholderContact: enMessages["placeholder.contact"],
    placeholderDescription: enMessages["placeholder.description"],
    placeholderMessage: enMessages["placeholder.message"],

    optionSearchEngine: enMessages["option.search.engine"],
    optionFfacebook: enMessages["option.facebook"],
    optionLinkedin: enMessages["option.linkedin"],
    optionRecommendation: enMessages["option.recommendation"],
    optionEmailMarketing: enMessages["option.email.marketing"],
    optionEvents: enMessages["option.events"],
    optionOthers: enMessages["option.others"],
    optionWhat: enMessages["option.which"],

    languagePT: enMessages["language.pt"],
    languageES: enMessages["language.es"],
    languageEN: enMessages["language.en"],
    languageFR: enMessages["language.fr"],
    languageName: enMessages["language.name"],
    languageRegion: enMessages["language.region"],

    reportIssueDate: enMessages["report.issueDate"],
    reportArea: enMessages["report.area"],
    reportPlannedFlight: enMessages["report.plannedFlight"],
    reportDetails: enMessages["report.details"],
    reportDateHour: enMessages["report.dateHour"],
    reportDrone: enMessages["report.drone"],
    reportManufacturer: enMessages["report.manufacturer"],
    reportAnac: enMessages["report.anac"],
    reportReleasedProduct: enMessages["report.releasedProduct"],
    reportReleasing: enMessages["report.releasing"],
    reportBandWidth: enMessages["report.bandWidth"],
    reportPlannedSpeed: enMessages["report.plannedSpeed"],
    reportReleaseRate: enMessages["report.releaseRate"],
    reportRealFlight: enMessages["report.realFlight"],
    reportFlightTime: enMessages["report.flightTime"],
    reportAverageReleaseRate: enMessages["report.averageReleaseRate"],
    reportAverageSpeed: enMessages["report.averageSpeed"],
    reportTotalReleased: enMessages["report.totalReleased"],
    reportTotalArea: enMessages["report.totalArea"],
    reportTraps: enMessages["report.traps"],
    reportSerialNumber: enMessages["report.serialNumber"],
    reportCoveredHectares: enMessages["report.coveredHectares"],
    reportDateMeasurament: enMessages["report.dateMeasurament"],
    reportSpecie: enMessages["report.specie"],
    reportAdultsNumber: enMessages["report.adultsNumber"],
    reportNoAvailable: enMessages["report.noAvailable"],
    reportMapRecord: enMessages["report.mapRecord"],
    reportContractor: enMessages["report.contractor"],
    reportProperty: enMessages["report.property"],
    reportLocalization: enMessages["report.localization"],
    reportCounty: enMessages["report.county"],
    reportUF: enMessages["report.uf"],
    reportDoc: enMessages["report.doc"],
    reportServiceType: enMessages["report.serviceType"],
    reportProduct: enMessages["report.product"],
    reportFormulation: enMessages["report.formulation"],
    reportDosage: enMessages["report.dosage"],
    reportToxicClass: enMessages["report.toxicClass"],
    reportAdjuvant: enMessages["report.adjuvant"],
    reportCulture: enMessages["report.culture"],
    reportVolume: enMessages["report.volume"],
    reportOthers: enMessages["report.others"],
    reportAgronomicalRecipe: enMessages["report.agronomicalRecipe"],
    reportIssuedOn: enMessages["report.issuedOn"],
    reportBasicParameter: enMessages["report.basicParameter"],
    reportMaxTemperature: enMessages["report.maxTemperature"],
    reportRelativeUnitMin: enMessages["report.relativeUnitMin"],
    reportEquipment: enMessages["report.equipment"],
    reportModel: enMessages["report.model"],
    reportType: enMessages["report.type"],
    reportAngle: enMessages["report.angle"],
    reportFlightHeight: enMessages["report.flightHeight"],
    reportBandwidth: enMessages["report.bandwidth"],
    reportDate: enMessages["report.date"],
    reportSignatureCREA: enMessages["report.signatureCREA"],
    reportWeatherConditions: enMessages["report.weatherConditions"],
    reportMorning: enMessages["report.morning"],
    reportEvening: enMessages["report.evening"],
    reportApplicationStart: enMessages["report.applicationStart"],
    reportInitial: enMessages["report.initial"],
    reportFinal: enMessages["report.final"],
    reportTemperature: enMessages["report.temperature"],
    reportRelativeHumidity: enMessages["report.relativeHumanity"],
    reportAircraftPrefixe: enMessages["report.aircraftPrefixe"],
    reportDgpsUse: enMessages["report.dgpsUse"],
    reportWindSpeed: enMessages["report.windSpeed"],
    reportTrackCoordinates: enMessages["report.trackCoordinates"],
    reportObservation: enMessages["report.observation"],
    reportSignatureEng: enMessages["report.signatureEng"],
  },
];
