import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLocale } from "../../../../store/actions/localeActions";
import { BiohubLocale } from "../../../../store/reducers/localeReducer";
import { SystemState } from "../../../../store/reducers/systemReducer";
import Image from "../../BasicComponents/Image";
import { Clickable, Flags } from "./styles";

/* Images */
import imageEN from "../../../../assets/flag/icon_en.svg";
import imageES from "../../../../assets/flag/icon_es.svg";
import imageFR from "../../../../assets/flag/icon_fr.svg";
import imagePT from "../../../../assets/flag/icon_pt.svg";

const FLAGS: {
  locale: BiohubLocale;
  flagSrc: string;
}[] = [
  { locale: "pt-BR", flagSrc: imagePT },
  { locale: "en", flagSrc: imageEN },
  { locale: "es", flagSrc: imageES },
  { locale: "fr", flagSrc: imageFR },
];

/**
 * Utility function that receives the FLAGS constant and returns an array of the same type, except
 * that the item corresponding to the selected locale appears as the first item.
 */
function _reorder(
  input: {
    locale: BiohubLocale;
    flagSrc: string;
  }[],
  currentLocale: BiohubLocale
): {
  locale: BiohubLocale;
  flagSrc: string;
}[] {
  // Find the index of the current locale.
  const index = input.findIndex((element) => element.locale === currentLocale);
  if (index === -1) {
    return input;
  }

  // Return a new array that's the selected item followed by the rest of the input.
  return [input[index], ...input];
}

type Props = {};

export default (_: Props) => {
  const currentLocale = useSelector((state: SystemState) => state.locale.localeCode);
  const dispatch = useDispatch();
  
  // Workaround para atualizar header
  // function reload () {
  //   setTimeout(function () {window.location.reload(true)}, 200);
  // }

  return (
    <>
      <Flags>
        {_reorder(FLAGS, currentLocale).map((flag, index) => (
          // On click, set the locale to this flag's locale. Set it to the global Redux store.
          <Clickable
            onClick={() => {
              dispatch(changeLocale(flag.locale));
              // reload(); workaround para atualizar header
            }}
            key={"flagLocale" + flag.locale + index}
          >
            <Image src={flag.flagSrc} alt={flag.locale} />
          </Clickable>
        ))}
      </Flags>
    </>
  );
};
