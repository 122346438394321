import { BoundingBox, Location } from "biohub-model";
import BaseMapController from "./BaseMapController";
import MapImplGoogle from "./impl/MapImplGoogle";
import React from "react";

export type BaseMapProps = {
  /**
   * Optionally define a region (recommended). This applies a bias for the map, for example when
   * searching for places.
   */
  region: string | undefined;
  /** Specify the language used in the map's interface.  */
  languageCode: string | undefined;

  /** Callback with a controller that can be used to programmatically affect the map. */
  onInitialized: (controller: BaseMapController) => void;

  /** Called in edit mode with a geographical location where the user clicked on the map. */
  onClick?: (l: Location) => void;

  /** State of the current map type */
  mapTypeId: BaseMapTypeId;
  onZoomChanged: (newValue: number) => void;
  onCurrentCenterChanged: (newValue: Location) => void;
  onMapBoundsChanged: (newBounds: BoundingBox) => void;

  children: JSX.Element[];
};

export type BaseMapTypeId =
  | "labeled_roadmap"
  | "plain_roadmap"
  | "labeled_satellite"
  | "plain_satellite";

export default function (props: BaseMapProps): JSX.Element {
  return <MapImplGoogle {...props} />;
}
