import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

type Props = {
  color?: "light";
};

export const Dropdown = styled.span.attrs((props: Props) => ({
  color: props.color,
}))`
  position: relative;
  display: inline-block;
  button {
    min-width: auto;
  }

  label {
    color: var(--white);
  }

  ${(props) =>
    props.color === "light" &&
    `
      * { color: var(--white); }
    `}
`;

export const Control = styled(Button).attrs((props: Props) => ({}))``;

export const List = styled(Menu).attrs((props: Props) => ({}))`
  .MuiMenu-paper {
    background: var(--white);
  }
`;

export const ListItem = styled(MenuItem).attrs((props: Props) => ({}))` 
  a {
    font-size: 14px;
    color: var(--black);
    font-family: "Galano Grotesque";
    font-weight: 500;
    text-decoration: none;
    outline: none;
    padding: 0;
    line-height: 1;
  }
  :hover{
      background: transparent;
      a {
        color: var(--primary);
      }
    }
`;
