import styled from "styled-components";

type Props = {};

export const Icon = styled.div.attrs((props: Props) => ({}))`
  position: fixed;
  bottom: 30px;
  right: 85px;
  z-index: 2;
  width: 80px;
  height: 35px;
  background: var(--flag-background);
  border-radius: 20px;
  box-shadow: 0 -3px 3px var(--flag-shadow);
  padding: 6px 10px 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  transition: ease 0.45s all;
  span {
    margin: 8px auto;
    width: auto;
    height: 20px;
    display: block;
    &:hover img {
      filter: saturate(0.45);
    }
  }
  &:focus,
  &:hover {
    min-height: 100px;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    box-shadow: none;
    bottom: 30px;
    right: 5px;
  }
`;

export const Clickable = styled.span.attrs((props: Props) => ({}))`
  display: inline-block;
  cursor: pointer;
`;
