import React from "react";
import { Row } from "./styles";

interface Props {
  spacing?: any;
  alignItems?: any;
  direction?: any;
  justify?: any;
  children?: React.ReactNode;
}

export default (props: Props) => {
  return (
    <Row
      container
      spacing={props.spacing}
      alignItems={props.alignItems}
      direction={props.direction}
      justify={props.justify}
    >
      {props.children}
    </Row>
  );
};
