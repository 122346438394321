import { DashboardGraphResponse, DashboardGraphValue, DashboardSummary } from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";
import { add, sub } from "date-fns";

async function getIncomeData(
  initialDate: Date,
  finalDate: Date,
  scale?: number
): Promise<BiohubResponse<DashboardGraphResponse>> {
  try {
    const result = await BiohubApi.get("/dashboard/income/", {
      params: {
        initialDate: initialDate,
        finalDate: finalDate,
        scale: scale,
      },
    });
    const dashboardInfos = result.data.dashboardInfos as DashboardGraphResponse;
    return newBiohubSuccess(dashboardInfos);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function getHectaresData(
  initialDate: Date,
  finalDate: Date,
  scale?: number
): Promise<BiohubResponse<DashboardGraphResponse>> {
  try {
    const result = await BiohubApi.get("/dashboard/hectares/", {
      params: {
        initialDate: initialDate,
        finalDate: finalDate,
        scale: scale,
      },
    });
    const dashboardInfos = result.data.dashboardInfos as DashboardGraphResponse;
    return newBiohubSuccess(dashboardInfos);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function getDashboardSummary(): Promise<BiohubResponse<DashboardSummary>> {
  try {
    const result = await BiohubApi.get("/dashboard/summary");
    const dashboardSummary = result.data.summary as DashboardSummary;
    return newBiohubSuccess(dashboardSummary);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

function convertDataToDateAndValuesArray(
  response: DashboardGraphResponse,
  initialDate: Date,
  finalDate: Date,
  profileName?: string,
  indirectClientName?: string,
  droneSerialNumber?: string
): Array<{ date: Date; value: number }> {
  const scale: number = response.scale;
  const array: Array<{ date: Date; value: number }> = [];

  let serviceArray: Array<DashboardGraphValue>;
  if (profileName !== undefined) {
    serviceArray = response.profiles[profileName];
  } else if (indirectClientName !== undefined) {
    serviceArray = response.indirectClients[indirectClientName];
  } else if (droneSerialNumber !== undefined) {
    serviceArray = response.drones[droneSerialNumber];
  } else {
    serviceArray = response.general;
  }

  let iterDate: Date = initialDate;
  let previousIndex: number = 0;
  for (let i = 0; i < serviceArray.length; i++) {
    while (previousIndex !== serviceArray[i].index) {
      if (array.length > 0) {
        array.push({ date: iterDate, value: 0.0 });
      }
      iterDate = add(iterDate, { minutes: scale });
      previousIndex++;
    }
    // If we don't have values in the begin of the graph we insert a zero value
    if (array.length === 0 && (iterDate.getTime() - initialDate.getTime()) / (1000 * 60) > scale) {
      array.push({ date: initialDate, value: 0.0 });
      if ((iterDate.getTime() - initialDate.getTime()) / (1000 * 60) > 2 * scale) {
        array.push({ date: sub(iterDate, { minutes: scale }), value: 0.0 });
      }
    }
    array.push({ date: iterDate, value: serviceArray[i].value });
    previousIndex++;
    iterDate = add(iterDate, { minutes: scale });
  }
  // If we don't have values in the end of the graph we insert a zero value
  if ((finalDate.getTime() - iterDate.getTime()) / (1000 * 60) > scale) {
    array.push({ date: iterDate, value: 0.0 });
    if ((finalDate.getTime() - iterDate.getTime()) / (1000 * 60) > 2 * scale) {
      array.push({ date: sub(finalDate, { minutes: scale }), value: 0.0 });
    }
  }

  return array;
}

export default {
  getIncomeData,
  getHectaresData,
  convertDataToDateAndValuesArray,
  getDashboardSummary,
};
