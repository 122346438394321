import { Box } from "@material-ui/core";
import React from "react";
import FooterOverlay from "../../../../components/Atomic/FooterOverlay";
import MapControlsOverlay from "./MapControlsOverlay";
import WeatherOverlay from "./WeatherOverlay";

type Props = {
  isExpandedControls: boolean;
  setExpandedControls: (value: boolean) => void;
  isExpandedFooter: boolean;
  setExpandedFooter: (value: boolean) => void;
  isExpandedWeather: boolean;
  setExpandedWeather: (value: boolean) => void;
  widthScreenType: "small" | "medium" | "regular";
  heightScreenType: "small" | "medium" | "regular";
  mediumScreenWeatherOverlayMarginBottom: number;
};

type ScreenType = "small" | "medium" | "regular";

export default function (props: Props): JSX.Element {
  // We use two separate boxes, both with absolute positioning, for independently
  // positioning the items in them. Both let all pointer events through, occupy
  // the entire screen and are transparent.

  const alignmentFooterOverlay = isScreenTypeLessThanOrEqual(props.widthScreenType, "medium")
    ? "left"
    : "center";

  return (
    <>
      <Box style={boxStyle}>
        <WeatherOverlay
          expanded={props.isExpandedWeather}
          setExpanded={props.setExpandedWeather}
          marginBottom={
            isScreenTypeLessThanOrEqual(props.heightScreenType, "small")
              ? 25
              : isScreenTypeLessThanOrEqual(props.heightScreenType, "medium")
              ? props.mediumScreenWeatherOverlayMarginBottom
              : 160
          }
        />
        <MapControlsOverlay
          expanded={props.isExpandedControls}
          setExpanded={props.setExpandedControls}
        />
      </Box>
      <Box
        style={{
          ...boxStyle,
          bottom: 0,
          alignItems: alignmentFooterOverlay,
        }}
      >
        <FooterOverlay
          paddingLeft={
            isScreenTypeLessThanOrEqual(props.widthScreenType, "medium") && props.isExpandedFooter
              ? 150
              : undefined
          }
          align={alignmentFooterOverlay}
          expanded={props.isExpandedFooter}
          setExpanded={props.setExpandedFooter}
        />
      </Box>
    </>
  );
}

function isScreenTypeLessThanOrEqual(screenType: ScreenType, comparison: ScreenType): boolean {
  if (comparison === "regular") return true;

  if (comparison === "medium") {
    if (screenType === "regular") return false;

    return true;
  }
  if (screenType === "small") return true;

  return false;
}

const boxStyle: React.CSSProperties = {
  position: "absolute",
  display: "flex",
  justifyContent: "flex-end",
  flex: 1,
  alignItems: "flex-end",
  flexDirection: "column",
  zIndex: 20,
  backgroundColor: "#ffffff00", // transparent
  pointerEvents: "none",
  top: 0,
  left: 0,
  right: 5,
  bottom: 20,
};
