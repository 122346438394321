import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// TODO: Fix the fixed colors - Others elements is changing the theme color
const buttonStyles = (props: { borderRadius?: number }) =>
  makeStyles((theme) => {
    const hoverColor = "var(--icon-focus)";
    const backgroundColor = "var(--dashboard-secondary)";
    const borderRadius = props.borderRadius !== undefined ? props.borderRadius : 20;
    return {
      root: {
        color: "var(--white)",
        border: `1px solid var(--white)`,
        backgroundColor: backgroundColor,
        "&:hover": {
          backgroundColor: hoverColor,
        },
        borderRadius: borderRadius,
      },
    };
  });

export default (props: {
  onClick?: () => void;
  disabled?: boolean | undefined;
  children: JSX.Element[] | JSX.Element | string;
  borderRadius?: number;
}) => {
  const classes = buttonStyles({ borderRadius: props.borderRadius })();
  return (
    <Button variant="contained" className={classes.root} onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </Button>
  );
};
