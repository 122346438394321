import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import React from "react";
import { useIntl } from "react-intl";
import Link from "../../../components/Atomic/BasicComponents/Link";
import Text from "../../../components/Atomic/BasicComponents/Text";
import Footer from "../../../components/Atomic/Footer"; //Footer padrão
import PurchaseOrderIntentForm from "../../../components/Atomic/Forms/PurchaseOrderIntent";

import { Container, Grid } from "@material-ui/core";
import Banner from "../../../components/Atomic/Tools/Banner";
import Card from "../../../components/v2/Card";
import {
  Box,
  Info2,
  Infos,
  PurchaseOrderIntentFormStyle,
  PurchaseOrderIntentPage,
  PurchaseOrderIntents,
} from "../styles";
/* Images */
import imageBackground from "../../../assets/background/contact.jpg";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();

  //terms
  const termTitle = intl.formatMessage({ id: "purchase.order.intent.contactTitle" });
  const termSubTitle = intl.formatMessage({ id: "info.contactSubtitle" });
  const termDescription = intl.formatMessage({
    id: "purchase.order.intent.contactDescription",
  });
  const termAddressInfo = intl.formatMessage({
    id: "info.address",
  });
  const termPhoneInfo = intl.formatMessage({
    id: "info.phone",
  });
  const termEmailInfo = intl.formatMessage({
    id: "info.email",
  });

  const urlMap = "https://maps.app.goo.gl/Ldpggm1hjNC6dkZE7";

  const cardContent = (
    <PurchaseOrderIntents>
      <Box>
        <RoomOutlinedIcon
          color={"primary"}
          style={{
            border: "2px solid var(--primary)",
            padding: 3,
            borderRadius: 18,
            fontSize: 35,
          }}
        />

        <Link to={urlMap} target="_blank" appearance={"simple"}>
          {termAddressInfo}
        </Link>
      </Box>

      <Box>
        <PhoneOutlinedIcon
          color={"primary"}
          style={{
            border: "2px solid var(--primary)",
            padding: 3,
            borderRadius: 18,
            fontSize: 35,
          }}
        />
        <Link to={`tel:${termPhoneInfo}`} appearance={"simple"}>
          {termPhoneInfo}
        </Link>
      </Box>

      <Box>
        <DraftsOutlinedIcon
          color={"primary"}
          style={{
            border: "2px solid var(--primary)",
            padding: 3,
            borderRadius: 18,
            fontSize: 35,
          }}
        />
        <Link to={`mailto:${termEmailInfo}`} appearance={"simple"}>
          {termEmailInfo}
        </Link>
      </Box>
    </PurchaseOrderIntents>
  );

  return (
    <>
      <PurchaseOrderIntentPage>
        <Banner
          overlay={false}
          image={imageBackground}
          imageSize={320}
          title={""}
          id="purchaseOrderIntent"
          darkenImage={30}
        />
        <Container>
          <Grid container spacing={3}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Infos>
                <Info2>
                  <Text
                    text={termTitle}
                    color={"primary"}
                    size={"large"}
                    appearance={"title-semibold"}
                  />
                </Info2>
                <Text text={termDescription} color={"dark"} />
              </Infos>
              <PurchaseOrderIntentFormStyle key={"formPurchaseOrderIntent"}>
                <PurchaseOrderIntentForm />
              </PurchaseOrderIntentFormStyle>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Info2>
                <Text
                  text={termSubTitle}
                  color={"primary"}
                  size={"medium"}
                  appearance={"small-title"}
                />
              </Info2>
              <Card title="Brasil" body={cardContent} />
            </Grid>
          </Grid>
        </Container>
        <Footer image={true} description={intl.formatMessage({ id: "info.about" })} />
      </PurchaseOrderIntentPage>
    </>
  );
};
