import * as isoContries from "i18n-iso-countries";
import pt from "i18n-iso-countries/langs/pt.json";
import en from "i18n-iso-countries/langs/en.json";
import es from "i18n-iso-countries/langs/es.json";
import it from "i18n-iso-countries/langs/it.json";

const localePT: isoContries.LocaleData = {
  locale: pt.locale,
  countries: pt.countries,
};

const localeIT: isoContries.LocaleData = {
  locale: it.locale,
  countries: it.countries,
};

const localeEN: isoContries.LocaleData = {
  locale: en.locale,
  countries: en.countries,
};

const localeES: isoContries.LocaleData = {
  locale: es.locale,
  countries: es.countries,
};

export type Country = {
  alpha2Code: string;
  countryName: string;
};

function loadLocales() {
  isoContries.registerLocale(localePT);
  isoContries.registerLocale(localeIT);
  isoContries.registerLocale(localeEN);
  isoContries.registerLocale(localeES);
}

function getCountries(language: string) {
  let countries: Country[] = [];

  const result: isoContries.LocalizedCountryNames<{
    select: "official";
  }> = isoContries.getNames(language, { select: "official" });

  for (let [key, value] of Object.entries(result)) {
    countries.push({ alpha2Code: key, countryName: value });
  }

  return countries;
}

export { loadLocales, getCountries };
