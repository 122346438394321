import styled from "styled-components";
import GridContainer from "@material-ui/core/Grid";

type Props = {
  direction?: string;
};

export const Row = styled(GridContainer).attrs((props: Props) => ({}))`
  ${(props: Props) =>
    props.direction === "row-reverse" &&
    `
    label, span {
      text-align: right;
    }
  `}
`;
