import React from "react";
import PropTypes from "prop-types";

/// This code is originally from react-https-redirect.

const isLocalHost = (hostname: string) =>
  !!(
    hostname === "localhost" ||
    hostname === "[::1]" ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

const HttpsRedirect = ({
  disabled,
  children,
}: {
  disabled?: boolean;
  children: React.ReactNode;
}): React.ReactElement | null => {
  if (
    !disabled &&
    typeof window !== "undefined" &&
    window.location &&
    window.location.protocol === "http:" &&
    !isLocalHost(window.location.hostname)
  ) {
    window.location.href = window.location.href.replace(/^http(?!s)/, "https");
    return null;
  }

  return <>{children}</>;
};

HttpsRedirect.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default HttpsRedirect;
