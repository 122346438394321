import styled from "styled-components";
type Props = {};

export const Table = styled.table.attrs((props: Props) => ({}))`
  width: 100%;
  margin: 1rem 0 3rem;
  font-size: 14px;
  font-family: "Galano Grotesque";
  border: 0;
`;

export const THead = styled.thead.attrs((props: Props) => ({}))`
  text-align: left;
`;

export const TBody = styled.tbody.attrs((props: Props) => ({}))`
  td {
    border-top: 1px solid var(--overlay-30);
    border-bottom: 1px solid var(--overlay-30);
  }
`;

export const TFoot = styled.tfoot.attrs((props: Props) => ({}))`
  td {
    font-family: "GalanoGrotesque-Semibold";
  }
`;

export const TR = styled.tr.attrs((props: Props) => ({}))``;

export const TH = styled.th.attrs((props: Props) => ({}))`
  font-weight: normal;
  padding: 10px 0;
`;

export const TD = styled.td.attrs((props: Props) => ({}))`
  padding: 10px 0;
`;
