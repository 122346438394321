import styled from "styled-components";

type Props = {};

export const Card = styled.div.attrs((props: Props) => ({}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin: 4rem 0;
  padding: 0 0 0 3rem;
  height: 100%;

  @media screen and (max-width: 580px) {
    margin: 0 0 5rem;
    padding: 0;
  }
`;

export const Infos = styled.div.attrs((props: Props) => ({}))`
  margin-top: 1rem;
`;
