import React from "react";
import { useIntl } from "react-intl";
import { Table, THead, TBody, TFoot, TR, TH, TD } from "./styles";
interface Props {
  itemsHeader: Array<{
    title: string;
  }>;
  itemsBody: Array<{
    amount: number;
    plan: string;
    mode: string;
    price: number;
  }>;
  infoFooter?: string;
}

export default (props: Props) => {
  const intl = useIntl();
  const termCoin = intl.formatMessage({ id: "info.coin" });

  return (
    <Table cellSpacing="0">
      <THead>
        <TR>
          {props.itemsHeader.map((item) => (
            <TH>{item.title}</TH>
          ))}
        </TR>
      </THead>
      <TBody>
        <TR>
          {props.itemsBody.map((content) => [
            <TD>{`${content.amount}`}</TD>,
            <TD>{content.plan}</TD>,
            <TD>{content.mode}</TD>,
            <TD>{`${content.price.toFixed(2).replace(".", ",")}`}</TD>,
          ])}
        </TR>
      </TBody>
      <TFoot>
        <TR>
          <TD>{props.infoFooter}</TD>
          <TD></TD>
          <TD></TD>
          {/* //TODO: total value - price converted */}
          <TD>
            {termCoin} {props.itemsBody[0].price.toFixed(2).replace(".", ",")}
          </TD>
        </TR>
      </TFoot>
    </Table>
  );
};
