import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import imageNotifications from "../../../../assets/icon/icon_notifications.svg";
import imageBadge from "../../../../assets/icon/icon_badge.svg";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

type Props = {};

export const Notifications = styled.span.attrs((props: Props) => ({}))`
  border: 0;
  height: 38px;
  width: 42px;
  display: block;
  position: relative;
  background: transparent url(${imageNotifications}) no-repeat center / 28px;
  margin: 1px 0 0;

  @media screen and (max-width: 680px) {
    width: 0;
    left: 55px;
    filter: drop-shadow(0 2px 0px black);
  }
`;

export const Badge = styled.span.attrs((props: Props) => ({}))`
  :after {
    content: "";
    width: 12px;
    display: block;
    height: 14px;
    position: absolute;
    right: 3px;
    top: 2px;
    background: transparent url(${imageBadge}) no-repeat left center / 7px;
  }
`;

export const Dropdown = styled.span.attrs((props: Props) => ({}))``;

export const Control = styled(Button).attrs((props: Props) => ({}))`
  padding: 0;
`;

export const List = styled(Menu).attrs((props: Props) => ({}))`
  .MuiAlert-root {
    background: transparent !important;
    padding: 0 10px 5px;
    color: var(--white);
  }
  margin-top: 45px;
  .MuiMenu-paper {
    background: var(--overlay-90);
  }
`;

export const ListItem = styled(MenuItem).attrs((props: Props) => ({}))`
  &.list-item {
    padding: 0 5px;
  }
  &.all-notifications {
    border-top: 1px solid var(--box-shadow);
    text-align: center;
    display: block;
    padding: 10px 0 0;
    a {
      font-family: "Galano Grotesque";
      color: var(--dashboard-secondary);
      font-size: 14px;
    }
    :hover,
    :focus {
      background: transparent;
      a {
        color: var(--primary);
      }
    }
  }
`;

export const StyledAlert = styled(Alert).attrs((props: Props) => ({}))``;