import styled from "styled-components";
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type Props = {
  isActive?: boolean;
  cultivationImage?: string;
};

export const Block = styled.div.attrs((props: Props) => ({}))`
  display: flex;
  flex-direction: column;
  background: var(--card-background);
  padding: 10px;
  margin: 15px;
  display: flex;
  flex-wrap: wrap;

  img {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
  }
  
  span:last-child {
    margin-top: auto;
  }

  @media screen and (max-width: 680px) {
    margin: 15px 5rem;
  }

  @media screen and (max-width: 579px) {
    margin: 15px 20px;
  }
`;

export const Description = styled.span.attrs((props: Props) => ({}))`
  width: 90%;
  text-align: left;
  display: block;
  line-height: 1.2;
  /* margin-bottom: 2rem; */
  padding: 10px;
`;


export const ProductPage = styled.section.attrs((props: Props) => ({}))`
  button {
    font-family: "GalanoGrotesque-SemiBold" !important;
  }

  @media screen and (max-width: 580px) {
    .banner {
      padding-bottom: 3rem;

      span:after {
        left: 51px;
        font-size: 20px;
        bottom: 12px;
      }
    }

    section {
      button {
        max-height: 34px;
        margin-top: 15px;
      }
    }
  }
`;

export const SignPage = styled.section.attrs((props: Props) => ({}))``;

export const VerificationPage = styled.section.attrs((props: Props) => ({}))`
  .MuiInput-root {
    position: relative;
    margin: 1rem 0;
    min-width: 280px;
    &:hover:before,
    &:focus:before,
    &:before {
      border-bottom: 0 !important;
    }
    svg {
      fill: var(--dashboard-icon-info);
    }
  }

  @media screen and (max-width: 680px) {
    .MuiButton-label {
      line-height: 1.2;
    }
  }
`;

export const ContentPage = styled.div.attrs((props: Props) => ({}))`
  padding: 3rem 8.333333%;
`;

export const DetailsDescription = styled.div.attrs((props: Props) => ({}))`
  margin: 2rem 0;
`;

export const DetailsData = styled.div.attrs((props: Props) => ({}))`
  margin: 1rem 0 2rem;
  > label {
    margin-bottom: 10px;
  }
`;

export const DetailsCode = styled.div.attrs((props: Props) => ({}))`
  margin: 1rem 0 2rem;
  display: flex;
  > label {
    margin-right: 20px;
    align-self: center;
  }
  input,
  .MuiFormLabel-root {
    font-size: 34px;
    text-align: center;
    color: var(--secondary);
    letter-spacing: 5px;
  }
  fieldset {
    border-radius: 50px;
    min-width: 280px;
  }
`;

export const DetailsPassword = styled.div.attrs((props: Props) => ({}))`
  margin: 1rem auto 2rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  > label {
    margin: 0 0 10px 15px;
  }
  input,
  .MuiFormLabel-root {
    font-size: 34px;
    text-align: center;
    color: var(--secondary);
    letter-spacing: 5px;

    @media screen and (max-width: 580px) {
      font-size: 22px;
      letter-spacing: 1px;
    }
  }
  fieldset {
    border-radius: 50px;
    width: 100%;
  }
  .MuiInputAdornment-root {
    position: absolute;
    right: 20px;
  }

  @media screen and (max-width: 991px) {
    width: 60%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ChangePasswordPage = styled.section.attrs((props: Props) => ({}))``;

export const AboutPage = styled.section.attrs((props: Props) => ({}))`
  @media screen and (max-width: 1200px) {
    .banner {
      min-height: 480px;
      .MuiGrid-container {
        height: 340px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .banner {
      min-height: 420px;
      .MuiGrid-container {
        height: 300px;
      }
    }

    .react-multi-carousel-item {
      div {
        align-items: center;
        text-align: center;
      }
    }
  }
`;

export const ProductsPage = styled.section.attrs((props: Props) => ({}))`
  .banner {
    min-height: 350px;
    animation: unset !important;
    background-attachment: unset !important;

    > div {
      .MuiGrid-container {
        height: 100%;
      }
    }
  }

  button {
    font-family: "GalanoGrotesque-SemiBold" !important;
  }

  .container {
    padding: 2rem;

    > div {
      &:first-child {
        max-width: calc(33.333333% - 100px);
      }

      &:nth-child(2) {
        max-width: calc(66.666667% - 50px);
      }

      & + div {
        padding-left: 50px;
        /* margin-left: 0px; */
        border-left: 2px solid var(--primary);
      }
    }
  }

  .MenuEquipments-container {
    div {
      &:first-child {
        label {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .cultivationContainer {
    > div:nth-child(2) {
      margin-top: 2rem;
    }
  }

  .detailsProduct {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;

    a {
      button {
        color: var(--white);
        background: var(--primary);
      }
    }
  }

  .descriptionProduct {
    label:nth-child(2) {
      margin: 0.5rem 0 1rem;
    }
  }

  .alignImages {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > label {
      margin-top: 0.5rem;
    }

    > a {
      margin-top: 2rem;

      button {
        min-width: unset !important;
        padding-left: 30px;
        padding-right: 30px;

        &::after {
          bottom: 0;
          right: -3px;
        }

        &:hover {
          &::after {
            border-left: 6px solid var(--white);
            right: -10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1060px) {
    .container {
      > div {
        &:first-child {
          max-width: 100%;
          flex-basis: 100%;
        }

        &:nth-child(2) {
          max-width: 100%;
          flex-basis: 100%;
        }

        & + div {
          padding-top: 5px;
          margin-top: 5px;
          border-left: unset;
          padding-left: unset;
          border-top: 2px solid var(--primary);
        }
      }
    }
  }
`;

export const CarouselEquipments = styled(Slider).attrs((props: Props) => ({}))`
  margin: 3rem 0 0;

  ul {
    max-height: 400px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
  }

  img {
    max-height: 100%;
  }

  .react-multi-carousel-track {
    padding-bottom: 40px;
  }

  .react-multi-carousel-dot-list {
    li {
      button {
        border: 0 solid transparent;
      }
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background: var(--highlighted);
    }
  }

  .react-multiple-carousel__arrow {
    background: transparent;
    border: 2px solid var(--white);
    :before {
      color: var(--white);
    }
    :hover,
    :focus {
      background: var(--secondary);
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }
`;

export const ButtonRightArrow = styled.button.attrs((props: Props) => ({}))`
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  border: 2px solid var(--white);
  border-radius: 50%;
  background: transparent;

  svg {
    width: 100%;
    font-size: 3rem;
    color: var(--white);
  }
`;

export const ButtonLeftArrow = styled.button.attrs((props: Props) => ({}))`
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  border: 2px solid var(--white);
  border-radius: 50%;
  background: transparent;

  svg {
    width: 100%;
    font-size: 3rem;
    color: var(--white);
  }
`;

export const MenuButtonEquipments = styled.button.attrs((props: Props) => ({
  isActive: props.isActive,
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
  width: 100%;
  margin: 5px 0;
  padding: 5px 10px;
  border-color: var(--primary);
  color: var(--primary);
  background: transparent;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  font-family: "GalanoGrotesque-Semibold";

  ${(props: Props) =>
    props.isActive &&
    `
    color: var(--white);
    background-color: var(--primary);

    svg {
      color: var(--primary);
    }
  `}
`;

export const CultivationItem = styled.div.attrs((props: Props) => ({
  cultivationImage: props.cultivationImage,
}))`
  width: 250px;
  height: 250px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

  ${(props) =>
    props.cultivationImage
      ? `
        background: url(${props.cultivationImage});
        background-size: cover;
        background-repeat: no-repeat;
  `
      : `
        background-color: var(--primary);
  `}
`;

export const CultivationItemOthers = styled.div.attrs((props: Props) => ({}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background: var(--primary);
`;

export const SpecificationItem = styled.div.attrs((props: Props) => ({}))`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 2px solid var(--primary);
  color: var(--text-default);
`;

export const ContactPage = styled.section.attrs((props: Props) => ({}))``;

export const PrivacyPage = styled.section.attrs((props: Props) => ({}))``;

export const PricesPage = styled.section.attrs((props: Props) => ({}))`
  .banner {
    min-height: unset;
    animation: unset;
    background-attachment: unset;
    background: position: unset;

    div {
      .MuiGrid-container {
        height: 200px;
      }
    }
  }

  button {
    &:after {
      right: 0px;
    }

    &:hover {
      &:after {
        right: -5px;
      }
    }
  }

  .comparationPlans-container {
    margin: 2rem 0;

    > label {
      margin-bottom: 2rem;
    }

    .comparationPlanes {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > label {
          margin-bottom: 0.5rem;
      }

      & + div {
        margin-left: 10px;
      }

      .textPlans-justify {
        justify-content: center;
      }

      > img {
        width: 50px;
        margin-bottom: 1rem;
      }

      div {
        & + div {
          margin: 1rem 0;
        }

        &:nth-last-child(2) {
          margin-bottom: 1rem;
        }
      }

      button {
        width: 100%;
        max-width: 150px;
        min-width: unset !important;
        margin-top: auto;
      }
    }

    svg {
      margin: 0.5rem 0;
      color: var(--primary);
    }
  }

  .listComparation {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 2px solid var(--primary);

      div:not(:first-child) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 30px;
      }
    }

    
    @media(max-width: 960px) {
      .comparationPlans-container {

        .comparationPlanes {
          max-width: calc(33% - 10px);
        }

        .MuiGrid-justify-xs-flex-end {
          justify-content: unset !important;
        }
      }

      .listComparation {
        > div {
          &:first-child {
            margin-bottom: 2rem;
          }
        }    
      }
    }
`;

export const SupportPage = styled.section.attrs((props: Props) => ({}))`
  .description {
    margin-left: 0;
    width: 100%;
  }
`;

export const TermsPage = styled.section.attrs((props: Props) => ({}))`
  .clickable {
    float: right;
    margin: 0 2rem 5rem;
  }
`;

export const CartPage = styled.section.attrs((props: Props) => ({}))`
  .Toastify__toast--error {
    background: var(--toast-error) !important;
  }

  .Toastify__toast--success {
    background: var(--toast-success) !important;
  }

  .banner {
    min-height: 250px;
    background-color: var(--primary);

    .MuiGrid-container {
      height: 100% !important;
      div {
        padding-bottom: 0;
      }
    }
  }

  > div {
    &:nth-child(2) {
      margin: 3rem auto;
    }

    > div {
      > label {
        margin-bottom: 2rem;
      }
    }
  }
`;

export const TitleStepCart = styled.div.attrs(() => ({}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Pillars = styled.section.attrs((props: Props) => ({}))`
  width: 100%;
  display: block;
  background: var(--white);
  /* background: linear-gradient(90deg, var(--white) 50%, transparent 50%);

  @media screen and (max-width: 768px) {
    background: linear-gradient(180deg, var(--white) 50%, transparent 50%);
  } */
`;

export const Content = styled.section.attrs((props: Props) => ({}))`
  width: 100%;
  display: block;
`;

export const Item = styled.div.attrs((props: Props) => ({}))`
  width: 100%;
  padding: 3rem 1.5rem;
  margin: 1rem 0;
  display: flex;

  img {
    width: 90px;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
    img {
      width: 70px;
    }
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    /* img {
      width: 50px;
    } */
  }
`;


export const Padding = styled.span.attrs((props: Props) => ({}))`
  padding: 7rem;
  display: table;
`;

export const Infos = styled.span.attrs((props: Props) => ({}))`
  margin-left: 15%;
  margin-right: 5%;
  padding-top: 1rem;
  display: table;
`;

export const Info2 = styled.span.attrs((props: Props) => ({}))`
  margin-bottom: 4%;
  padding-top: 1rem;
  display: table;
`;

export const ContactFormStyle = styled.span.attrs((props: Props) => ({}))`
  margin-left: 5.75%;
  display: table;
`;

export const Questions = styled.div.attrs((props: Props) => ({}))`
  margin: 2rem 0;

  .MuiAccordion-rounded {
    background: var(--card-background);
  }
`;

export const Box = styled.div.attrs((props: Props) => ({
  className: "box",
}))`
  display: flex;
  column-gap: 1rem;
  margin: 1.5rem 0;

  .material-icons {
    align-self: center;
  }

  label:nth-of-type(2) {
    font-size: 12px;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    .material-icons {
      align-self: flex-start;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    .material-icons {
      align-self: center;
    }
  }
`;

export const Informations = styled.div.attrs((props: Props) => ({}))`
  label {
    text-align: left;
    margin: 0 0 10px;
  }

  @media screen and (max-width: 768px) {
    .box {
      flex-direction: column;
      .material-icons {
        align-self: center;
      }
    }
  }
`;

export const Contacts = styled.div.attrs((props: Props) => ({}))`
  padding: 0 10px;
`;

export const Specifications = styled.div.attrs((props: Props) => ({}))`
  width: 80%;
  display: flex;
  margin: 3rem auto;
  text-align: center;

  img {
    margin: auto;
  }

  b {
    display: block;
  }

  .MuiGrid-item:nth-of-type(even) {
    position: relative;

    :before {
      content: "";
      display: block;
      width: 6px;
      height: 85px;
      background: var(--highlighted);
      border-radius: 10px;
      position: absolute;
      top: 35px;
      left: 0;
    }

    :after {
      content: "";
      display: block;
      width: 6px;
      height: 85px;
      background: var(--highlighted);
      border-radius: 10px;
      position: absolute;
      top: 35px;
      right: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    small,
    b {
      line-height: 1;
      display: block;
    }
  }

  @media screen and (max-width: 580px) {
    width: 100%;

    small {
      font-size: 12px;
    }
  }
`;

export const Details = styled.div.attrs((props: Props) => ({}))`
  width: 90%;
  margin-top: 3rem;
`;

export const Usage = styled.div.attrs((props: Props) => ({}))`
  margin: 2rem 0 5rem;
  width: 70%;

  @media screen and (max-width: 1200px) {
    line-height: 1;
    display: block;
    font-size: 12px;
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PlanesContainer = styled.div.attrs((props: Props) => ({}))`
  z-index: 100;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  transform: translateY(-80px);

  .acordeon-container {
    padding: 0 !important;

    .box {
      margin: 0;
    }

    .acordeonPlan-content {
      > div {
        padding: 0 40px;

        &:first-child {
          margin-top: 2rem;

          > div {
            padding: 0 20px;
          }
        }
        &:last-child {
          margin-bottom: 2rem;
        }
      }

      .logoPlan {
        img {
          width: 150px;
        }
      }
    }
  }

  .detailsPlane {
    margin-left: 2rem;
    margin-bottom: 2rem;

    label:nth-child(2) {
      margin: 1rem 0;
    }
  }

  .infoPlane {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .details-plans {
      > label {
        &:first-child,
        &:nth-last-child(-n + 2) {
          margin-bottom: 1rem;
        }
      }

      button {
        max-width: 100%;
      }
    }

    > div {
      &:nth-child(1) {
        margin-bottom: 1rem;
      }
    }

    button {
      margin-top: auto;
    }
  }

  @media (max-width: 600px) {
    .logoPlan {
      display: flex;
      justify-content: center;

      img {
        width: 100px;
      }
    }
  }
`;

export const PlanTextMixed = styled.div.attrs((props: Props) => ({}))`
  display: flex;
  align-items: baseline;
  flex-direction: row !important;
  width: 100%;
  font-size: 14px;

  label {
    & + label {
      margin-left: 3px;
    }
  }

  @media (max-width: 960px) {
    flex-wrap: wrap;
  }
`;
