import { SystemThunk } from "../systemThunk";

export const ADD_PROVISORY_CART_ITEM = "ADD_PROVISORY_CART_ITEM";
export const REMOVE_PROVISORY_CART_ITEM = "REMOVE_PROVISORY_CART_ITEM";
export const CLEAR_PROVISORY_CART = "CLEAR_PROVISORY_CART";

export type ProvisoryCartActions =
  | {
      type: typeof ADD_PROVISORY_CART_ITEM;
      payload: {
        itemId: string;
        amount: number;
      };
    }
  | {
      type: typeof REMOVE_PROVISORY_CART_ITEM;
      payload: {
        itemId: string;
        amount: number;
      };
    }
  | {
      type: typeof CLEAR_PROVISORY_CART;
    };

export function addCartItem(itemId: string, amount: number): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: ADD_PROVISORY_CART_ITEM,
      payload: {
        itemId: itemId,
        amount: amount,
      },
    });
  };
}

export function removeCartItem(itemId: string, amount: number): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: REMOVE_PROVISORY_CART_ITEM,
      payload: {
        itemId: itemId,
        amount: amount,
      },
    });
  };
}

export function clearCart(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_PROVISORY_CART,
    });
  };
}
