import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCpuLogs } from "../../../store/actions/projectTreeActions";
import { Profile } from "biohub-model";
import { SystemState } from "../../../store/reducers/systemReducer";
import CpuLogsTree from "./CpuLogsTree";
import { ProjectInProjectTree } from "../../../store/reducers/projectTreeReducer";

const CpuLogList = ({ projectList }: { projectList: ProjectInProjectTree[] }) => {
  const dispatch = useDispatch();
  const cpuLogs = useSelector((state: SystemState) => state.projectTree.cpuLogsList.cpuLogs);

  const systemProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  useEffect(() => {
    const fetchData = async () => {
      dispatch(loadCpuLogs(systemProfile?.directClientId as string));
    };

    fetchData();
  }, [dispatch, systemProfile]);

  return (
    <div style={{marginBottom: "0.5em"}}>
      <CpuLogsTree cpuLogs={cpuLogs} projectList={projectList}/>
    </div>
  );
};

export default CpuLogList;
