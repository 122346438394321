import FileSaver from "file-saver";
import BiohubApi, {
  BiohubResponse,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

export enum ExportKmlTypes {
  polygon = "polygon",
  flight = "flight",
  route = "route",
}

async function exportAsKmlFile(
  areaInfo: {
    id: string;
    name: string;
  },
  flightInfo:
    | {
        id: string;
        startedAt: Date;
      }
    | undefined,
  types: ExportKmlTypes[]
): Promise<BiohubResponse<null>> {
  try {
    const response = await BiohubApi.post(
      buildUrlWithParameters(areaInfo.id, flightInfo?.id, types),
      null,
      {
        headers: {
          "Content-Type": "application/vnd.google-earth.kml+xml",
        },
      }
    );
    const blob = new Blob([response.data], { type: "application/vnd.google-earth.kml+xml" });
    const fileNameFields: string[] = ["KML:FLYBIOHUB"];
    if (areaInfo !== undefined) {
      fileNameFields.push(`area-${areaInfo.name}`);
    }
    if (flightInfo !== undefined) {
      fileNameFields.push(`flight-${flightInfo.startedAt.toLocaleDateString()}`);
    }
    for (const type of types) {
      switch (type) {
        case ExportKmlTypes.polygon:
          fileNameFields.push(`POLYGON`);
          break;
        case ExportKmlTypes.route:
          fileNameFields.push("PLANNED-ROUTE");
          break;
        case ExportKmlTypes.flight:
          fileNameFields.push("FLIGHT");
          break;
      }
    }
    fileNameFields.push(`exported-at-${new Date().toLocaleDateString()}`);
    const fileName = `${fileNameFields.join("-")}.kml`;
    FileSaver.saveAs(blob, fileName);
    return newBiohubSuccess(null);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

function buildUrlWithParameters(
  areaId: string | undefined,
  flightId: string | undefined,
  types: ExportKmlTypes[]
) {
  let url = `/export-kml`;
  if (areaId) {
    url += `?areaId=${areaId}`;
  }
  if (flightId) {
    if (areaId) {
      url += `&flightId=${flightId}`;
    } else {
      url += `?flightId=${flightId}`;
    }
  }
  if (types.length > 0) {
    types.forEach((type, index) => {
      if (!areaId && !flightId && index === 0) {
        url += `?types[]=${type}`;
      } else {
        url += `&types[]=${type}`;
      }
    });
  }
  return url;
}

export default { exportAsKmlFile };
