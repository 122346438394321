import {
  Marker,
  MarkerProps,
  Polygon,
  PolygonProps,
  PolylineF,
  PolylineProps,
} from "@react-google-maps/api";
import React from "react";
import _ from "lodash";

function _MemoPolygon(props: PolygonProps) {
  return <Polygon {...props} />;
}

export const MemoPolygon = React.memo(_MemoPolygon, (prevProps, newProps) => {
  if (!_.isEqual(prevProps.path, newProps.path)) return false;
  if (!_.isEqual(prevProps.options, newProps.options)) return false;
  if (prevProps.onClick !== newProps.onClick) return false;
  return true;
});

function _MemoMarker(props: MarkerProps) {
  return <Marker {...props} />;
}

export const MemoMarker = React.memo(_MemoMarker, (prevProps, newProps) => {
  if (prevProps.draggable !== newProps.draggable) return false;
  if (prevProps.onClick !== newProps.onClick) return false;
  if (!_.isEqual(prevProps.position, newProps.position)) return false;
  if (!_.isEqual(prevProps.icon, newProps.icon)) return false;
  if (!_.isEqual(prevProps.label, newProps.label)) return false;
  if (prevProps.clickable !== newProps.clickable) return false;
  if (prevProps.onDragEnd !== newProps.onDragEnd) return false;
  return true;
});

function _MemoPolyline(props: PolylineProps) {
  return <PolylineF {...props} />;
}

export const MemoPolyline = React.memo(_MemoPolyline, (prevProps, newProps) => {
  if (!_.isEqual(prevProps.path, newProps.path)) return false;
  if (!_.isEqual(prevProps.options, newProps.options)) return false;
  if (prevProps.onClick !== newProps.onClick) return false;
  return true;
});
