import React from "react";
import { useSelector } from "react-redux";
import UserLocationMarker from "../../../components/map/impl/components/core/markers/UserLocationMarker";
import { SystemState } from "../../../store/reducers/systemReducer";

export default function (props: { zIndex: number }): JSX.Element {
  const mapZoom = useSelector((state: SystemState) => state.projectTree.mapState.zoom);
  const userLocation = useSelector((state: SystemState) => state.projectTree.userLocation);

  return <UserLocationMarker userLocation={userLocation} zoom={mapZoom} zIndex={props.zIndex} />;
}
