import styled from "styled-components";

type Props = {};

export const Center = styled.div.attrs((props: Props) => ({}))`
  text-align: center;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  hr {
    width: 30px;
    height: 10px;
    border-radius: 10px;
  }

  /* :before {
    content: "";
    display: block;
    width: 8px;
    height: 85px;
    background: var(--secondary);
    border-radius: 10px;
    position: absolute;
    top: calc(85px + 2.5rem);
    left: calc(50% - 10px);

    @media screen and (max-width: 1200px) {
      top: calc(85px + 0.25rem);
    }

    @media screen and (max-width: 991px) {
      left: calc(47% - 10px);
    }

    @media screen and (max-width: 580px) {
      left: 22px;
      transform: rotate(90deg);
      top: -20px;
    }
  } */

  @media screen and (max-width: 580px) {
    margin-bottom: 2rem;
  }
`;
