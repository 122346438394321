import React from "react";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  rotate: {
    transition: "transform 0.3s ease",
    transform: (props: any) => (props.expanded ? "rotate(180deg)" : "rotate(0deg)"),
  },
});

const CustomExpandIcon = ({ expanded }: any) => {
  const classes = useStyles({ expanded });
  return (
    <div style={{ display: "flex", marginLeft: "-1em", paddingRight: "0px", alignItems: "center" }}>
      <ExpandMoreIcon className={classes.rotate} />
    </div>
  );
};

export default CustomExpandIcon;
