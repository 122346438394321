import {
  Cpu,
  CpuModel,
  DeviceModel,
  Input,
  Releaser,
  ReleaserModel,
  Drone,
  DroneModel,
} from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  BiohubUnit,
  biohubUnit,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

async function readReleasers(): Promise<BiohubResponse<Releaser[]>> {
  try {
    const result = await BiohubApi.get("/releasers");
    const releasers = Array.from((result.data?.releasers ?? []) as Releaser[]);
    return newBiohubSuccess(releasers);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readCpus(directClientId?: string): Promise<BiohubResponse<Cpu[]>> {
  try {
    const url = directClientId ? `/cpu?directClientId=${directClientId}` : "/cpu";

    const result = await BiohubApi.get(url);
    const cpus = Array.from(result.data as Cpu[]);
    return newBiohubSuccess(cpus);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readReleaserModels(): Promise<BiohubResponse<ReleaserModel[]>> {
  try {
    const result = await BiohubApi.get("/releaser-models");
    const releaserModels = Array.from(result.data as ReleaserModel[]);
    return newBiohubSuccess(releaserModels);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readCpuModels(): Promise<BiohubResponse<CpuModel[]>> {
  try {
    const result = await BiohubApi.get("/cpu-models");
    const cpuModels = Array.from(result.data as CpuModel[]);
    return newBiohubSuccess(cpuModels);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readInputs(): Promise<BiohubResponse<Input[]>> {
  try {
    const result = await BiohubApi.get("/inputs");
    const inputs = Array.from((result.data.inputs ?? []) as Input[]);
    return newBiohubSuccess(inputs);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readDeviceModels(): Promise<BiohubResponse<DeviceModel[]>> {
  try {
    const result = await BiohubApi.get("/device-models");
    return newBiohubSuccess(result.data["deviceModels"] as DeviceModel[]);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readCpu(cpuId: string): Promise<BiohubResponse<Cpu>> {
  try {
    const url = `/cpu/${cpuId}`;

    const result = await BiohubApi.get(url);
    return newBiohubSuccess(result.data as Cpu);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readDrone(droneId: string): Promise<BiohubResponse<Drone>> {
  try {
    const result = await BiohubApi.get("/drones/" + droneId);
    const drone: Drone = result.data as Drone;
    return newBiohubSuccess(drone);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readDroneModels(): Promise<BiohubResponse<Array<DroneModel>>> {
  try {
    const result = await BiohubApi.get("/drone-models");
    const droneModels: DroneModel[] = result.data as DroneModel[];
    return newBiohubSuccess(droneModels);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function readDrones(directClientId?: string): Promise<BiohubResponse<Array<Drone>>> {
  try {
    let params = {};
    if (directClientId !== undefined) {
      params = { directClientId: directClientId };
    }
    const result = await BiohubApi.get("/drones", { params: params });
    const drones: Array<Drone> = result.data as Array<Drone>;
    return newBiohubSuccess(drones);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

// chamada da api
async function updateDrone(id: string, drone: Partial<Drone>): Promise<BiohubResponse<BiohubUnit>> {
  //This will be a master service
  try {
    await BiohubApi.put("/drones/" + id, drone);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function createDrone(drone: Drone): Promise<BiohubResponse<BiohubUnit>> {
  //This will be a master service
  try {
    await BiohubApi.post("/drones", drone);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function deleteDrone(droneId: string): Promise<BiohubResponse<BiohubUnit>> {
  //This will be a master service
  try {
    await BiohubApi.delete("/drones/" + droneId);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function addCpu(cpu: Omit<Cpu, "serialNumber">): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.post("/cpu", { cpu: cpu });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateCpu(cpu: Partial<Cpu>, cpuId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.put("/cpu/" + cpuId, { cpu: cpu });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function deleteCpu(cpuId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.delete("/cpu/" + cpuId);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function createCpuBlock(cpuId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.post(`/cpuBlock/${cpuId}`, { moment: new Date() });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function unblockCpuBlock(blockId: string): Promise<BiohubResponse<BiohubUnit>> {
  try {
    await BiohubApi.put(`/cpuBlock/register-unblock/${blockId}`, { moment: new Date() });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function createReleaser(
  releaser: Omit<Releaser, "serialNumber" | "devices">
): Promise<BiohubResponse<BiohubUnit>> {
  //This will be a master service
  try {
    await BiohubApi.post("/releasers", { releaser: releaser });
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function updateReleaser(
  releaser: { id: string } & Partial<Releaser>
): Promise<BiohubResponse<BiohubUnit>> {
  //This will be a master service
  try {
    await BiohubApi.put("/releasers/" + releaser.id, { releaser: releaser });

    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

async function deleteReleaser(releaserId: string): Promise<BiohubResponse<BiohubUnit>> {
  //This will be a master service
  try {
    await BiohubApi.delete("/releasers/" + releaserId);
    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default {
  readReleasers,
  readReleaserModels,
  readInputs,
  readCpus,
  readCpu,
  readCpuModels,
  readDeviceModels,
  readDrone,
  readDrones,
  updateDrone,
  createDrone,
  deleteDrone,
  readDroneModels,
  addCpu,
  updateCpu,
  deleteCpu,
  createCpuBlock,
  unblockCpuBlock,
  createReleaser,
  updateReleaser,
  deleteReleaser,
};
