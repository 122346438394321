import CompleteNumberEditor, {
  CompleteNumberEditorProps as Props,
  ColorScheme as _ColorScheme,
  GridScheme as _GridScheme,
} from "./default";

export type CompleteNumberEditorProps = Props;
export type ColorScheme = _ColorScheme;
export type GridScheme = _GridScheme;
export default CompleteNumberEditor;
