import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Icon } from "@material-ui/core";

import Text from "../Text";
import {} from "./styles";

interface Props {
  id: string;
  title: string;
  description: string;
  color?: "light" | "primary" | "secondary";
}

export default (props: Props) => {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<Icon>expand_more</Icon>}
          aria-controls={`${props.id}-content`}
          id={`${props.id}-header`}
        >
          <Text color={props.color} text={props.title} />
        </AccordionSummary>
        <AccordionDetails>
          <Text color={props.color} text={props.description} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
