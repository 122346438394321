import styled from "styled-components";

import imageBefore from "../../../../assets/icon/icon_before.svg";
import imageAfter from "../../../../assets/icon/icon_after.svg";

import imageBiohubIng from "../../../../assets/logo/scaffold_logo/only_logo/ING.svg";
import imageSloganIng from "../../../../assets/logo/scaffold_logo/logo_with_slogan/IN.svg";
import imageBiohubEs from "../../../../assets/logo/scaffold_logo/only_logo/ES.svg";
import imageSloganEs from "../../../../assets/logo/scaffold_logo/logo_with_slogan/ES.svg";
import imageBiohubPt from "../../../../assets/logo/scaffold_logo/only_logo/PT.svg";
import imageSloganPt from "../../../../assets/logo/scaffold_logo/logo_with_slogan/PT.svg";
import imageBiohubFr from "../../../../assets/logo/scaffold_logo/only_logo/FR.svg";
import imageSloganFr from "../../../../assets/logo/scaffold_logo/logo_with_slogan/FR.svg";
import { BiohubLocale } from "../../../../store/reducers/localeReducer";

type Props = {
  open: boolean;
  widthOpen?: string;
  widthClose?: string;
  localeCode?: BiohubLocale;
};

function navBarLogoLocalization(localeCode: BiohubLocale): Array<string> {
  switch (localeCode) {
    case "pt-BR":
      return [imageBiohubPt, imageSloganPt];
    case "en":
      return [imageBiohubIng, imageSloganIng];
    case "es":
      return [imageBiohubEs, imageSloganEs];
    case "fr":
      return [imageBiohubFr, imageSloganFr];
    default:
      return [imageBiohubPt, imageSloganPt];
  }
}

export const Nav = styled.div.attrs((props: Props) => ({
  open: props.open,
  widthOpen: props.widthOpen,
  widthClose: props.widthClose,
}))`
  display: inline-block;
  height: 100vh;
  max-width: 220px;
  display: flex;
  align-items: flex-left;
  justify-content: space-between;
  flex-direction: column;

  ${(props: Props) =>
    props.widthOpen &&
    props.open &&
    `
    width: ${props.widthOpen};
    `};
  position: fixed;
  background: linear-gradient(90deg, var(--dashboard-menu-bg) 395px, transparent 395px);
  color: var(--dashboard-light-text);
  top: 0px;
  left: 0;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 0.3s linear;

  li {
    justify-content: left;
    padding: 10px 25px 0px 6px;
    white-space: nowrap;
  }

  li ul li a:last-of-type .material-icons {
    margin-bottom: -35px;
  }

  // :before {
  //   content: "";
  //   background: transparent url(${imageBefore}) no-repeat 0 0 / contain;
  //   height: 25px;
  //   width: 25px;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   display: none;
  // }

  // :after {
  //   content: "";
  //   background: transparent url(${imageAfter}) no-repeat 0 0 / contain;
  //   height: 25px;
  //   width: 25px;
  //   display: block;
  //   justify-content: center;
  //   position: absolute;
  //   right: 0;
  //   top: 0;

  //   @media screen and (max-height: 1200px) {
  //     display: none;
  //   }
  // }

  .has-submenu {
    position: relative;
    left: 3px;
    bottom: 0;
    height: 10px;
  }

  li ul {
    margin-left: 0;
    li {
      .has-submenu {
        bottom: 5px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
  }

  ::-webkit-scrollbar {
    display: none; // Disables scrollbar in navbar
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--dashboard-highlighted);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    box-shadow: inset 5px 10px 12px var(--gray);
  }

  ${(props: Props) =>
    props.open === false &&
    props.widthClose &&
    `
      width: ${props.widthClose};
    
      background: linear-gradient(90deg,var(--dashboard-menu-bg) 215px,transparent 215px);

      :after { animation: pressMenu .5s ease-in-out; }

      li { justify-content: left; padding: 10px 0px 0px 6px;}
      
      li a { font-size: 0!important; }
      
      li a img {margin: 0;}

      li ul li a:last-of-type .material-icons { margin-bottom: initial; }

      ul ul { }

      li ul li .has-submenu {
        bottom: -23px;
        left: -35px;
      }

      .nav-itens {
        height: 84vh;
      }

      @media screen and (max-width: 1024px) {
        width: 75px;
      }

      @media screen and (max-width: 680px) {
        width: 75px; 
      }

      @media screen and (max-width: 579px) {
        left: -100px;
      }
      
    `}
`;

export const Logo = styled.span.attrs((props: Props) => ({
  open: props.open,
  localeCode: props.localeCode,
}))`
  height: 100px;
  width: 100%;
  ${(props: Props) => {
    const localeImages = navBarLogoLocalization(
      props.localeCode !== undefined ? props.localeCode : "en"
    );
    return `
      background: transparent url(${localeImages[1]}) no-repeat 0 0 / contain;
    `;
  }}
  margin: 14px 0px 0px 16px;
  display: block;
  transition: background 0.2s linear;
  position: fixed;
  pointer-events: none;
  ${(props: Props) => {
    const localeImages = navBarLogoLocalization(
      props.localeCode !== undefined ? props.localeCode : "en"
    );
    if (!props.open) {
      return `
      background: transparent url(${localeImages[0]}) no-repeat 0 0 / contain;
      height: 100px;
      width: 100%;
      margin: 14px 0px 0px 16px;
      position: fixed;
      
      @media screen and (max-width: 1024px) {
      }

      @media screen and (max-width: 680px) {
        margin: 10px;
      }
  `;
    }
  }}
`;

export const NavItems = styled.div.attrs((props: Props) => ({
  className: "nav-itens",
}))`
  display: flex;
  align-items: flex-center;
  justify-content: space-between;
  flex-direction: column;
  height: 84vh;
  width: calc(100% - 30px);
  margin-left: 14px;
  padding-right: 25px;

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 680px) {
  }
`;
