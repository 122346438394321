/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../store/reducers/systemReducer";
import { useIntl } from "react-intl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiohubErrorCode } from "../services/axios/BiohubApi";

type Props = {
  children: React.ReactNode;
};

export default (props: Props) => {
  const intl = useIntl();

  const FirstRender = () => {
    const firstRender = useRef(true);

    useEffect(() => {
      firstRender.current = false;
    }, []);

    return firstRender.current;
  };

  const firstRenderProjectTree = FirstRender();
  const firstRenderMaster = FirstRender();
  const firstRenderCollections = FirstRender();
  const firstRenderDashboard = FirstRender();
  const firstRenderLogin = FirstRender();
  const firstRenderProfile = FirstRender();

  const profileReducer = useSelector((state: SystemState) => {
    if (state.profile.error === undefined || state.profile.error === null) return null;
    return state.profile.error.errorCode;
  });

  const loginReducerError = useSelector((state: SystemState) => {
    if (state.login.error === undefined || state.login.error === null) return null;
    return state.login.error.errorCode;
  });

  const dashboardReducerError = useSelector((state: SystemState) => {
    if (state.dashboard.error === undefined || state.dashboard.error === null) return null;
    return state.dashboard.error.errorCode;
  });

  const collectionsReducerError = useSelector((state: SystemState) => {
    if (state.collections.error === undefined || state.collections.error === null) return null;
    return state.collections.error.errorCode;
  });

  const masterReducerError = useSelector((state: SystemState) => {
    if (state.master.error === undefined || state.master.error === null) return null;
    return state.master.error.errorCode;
  });

  const projectTreeReducerError = useSelector((state: SystemState) => {
    if (state.projectTree.projectsError === undefined || state.projectTree.projectsError === null)
      return null;
    return state.projectTree.projectsError.errorCode;
  });

  function bioHubErrorToString(errorCode: BiohubErrorCode): string {
    switch (errorCode) {
      case BiohubErrorCode.UNIMPLEMENTED:
        return "biohubError.unimplemented.error";
      case BiohubErrorCode.UNKNOWN_ERROR:
        return "biohubError.unkown.error";
      case BiohubErrorCode.INTERNAL_SERVER_ERROR:
        return "biohubError.internal.server.error";
      case BiohubErrorCode.VALIDATION_ERROR:
        return "biohubError.validation.error";
      case BiohubErrorCode.LOGIN_INCORRECT_USER_PASSWORD:
        return "biohubError.login.incorrect.user.password";
      case BiohubErrorCode.PREREGISTER_EMAIL_ALREADY_REGISTERED:
        return "biohubError.preregister.email.already.registered";
      case BiohubErrorCode.PREREGISTER_PHONE_NUMBER_ALREADY_REGISTERED:
        return "biohubError.preregister.phone.number.already.registered";
      case BiohubErrorCode.LOGIN_TOKEN_NOT_RECEIVED:
        return "biohubError.login.token.not.received";
      case BiohubErrorCode.LOGIN_UNRECOGNIZED_CLIENT:
        return "biohubError.logion.unrecgnized.client";
      case BiohubErrorCode.PROJECT_NOT_FOUND:
        return "biohubError.project.not.found";
      case BiohubErrorCode.ELEVATION_INVALID_REQUEST:
        return "biohubError.elevation.invalid.request";
      case BiohubErrorCode.ELEVATION_OVER_DAILY_LIMIT:
        return "biohubError.elevation.over.daily.limit";
      case BiohubErrorCode.ELEVATION_OVER_QUERY_LIMIT:
        return "biohubError.elevation.over.query.limit";
      case BiohubErrorCode.ELEVATION_REQUEST_DENIED:
        return "biohubError.elevation.request.denied";
      case BiohubErrorCode.ELEVATION_UNKNOWN_ERROR:
        return "biohubError.elevation.unknown.error";
      case BiohubErrorCode.GEOCODER_UNKNOWN_ERROR:
        return "biohubError.geocoder.unknown.error";
      case BiohubErrorCode.SMS_VALIDATION_ERROR:
        return "biohubError.sms.validation.error";
      case BiohubErrorCode.KML_ROUTE_OUT_OF_AREA_BOUNDS:
        return "biohubError.kml.route.out.of.area.bounds";
      case BiohubErrorCode.KML_IMPORT_INVALID_SELECTED_AREA_BOUNDS:
        return "biohubError.kml.import.invalid.selected.area.bounds";
      default:
        return "";
    }
  }

  useEffect(() => {
    if (firstRenderProfile || profileReducer === null) return;
    else toast(intl.formatMessage({ id: `${bioHubErrorToString(profileReducer)}` }));
  }, [profileReducer, firstRenderProfile]);

  useEffect(() => {
    if (firstRenderLogin || loginReducerError === null) return;
    else toast(intl.formatMessage({ id: `${bioHubErrorToString(loginReducerError)}` }));
  }, [loginReducerError, firstRenderLogin]);

  useEffect(() => {
    if (firstRenderDashboard || dashboardReducerError === null) return;
    else toast(intl.formatMessage({ id: `${bioHubErrorToString(dashboardReducerError)}` }));
  }, [dashboardReducerError, firstRenderDashboard]);

  useEffect(() => {
    if (firstRenderCollections || collectionsReducerError === null) return;
    else toast(intl.formatMessage({ id: `${bioHubErrorToString(collectionsReducerError)}` }));
  }, [collectionsReducerError, firstRenderCollections]);

  useEffect(() => {
    if (firstRenderMaster || masterReducerError === null) return;
    else toast(intl.formatMessage({ id: `${bioHubErrorToString(masterReducerError)}` }));
  }, [masterReducerError, firstRenderMaster]);

  useEffect(() => {
    if (firstRenderProjectTree || projectTreeReducerError === null) return;
    else toast(intl.formatMessage({ id: `${bioHubErrorToString(projectTreeReducerError)}` }));
  }, [projectTreeReducerError, firstRenderProjectTree]);

  return (
    <>
      <ToastContainer
        style={{
          width: 300,
        }}
        position="bottom-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {props.children}
    </>
  );
};
