import styled from "styled-components";

type Props = {};

export const Form = styled.form.attrs((props: Props) => ({}))`
  border-radius: 25px;
  padding: 2rem;
  margin: 2rem 0;
  background: var(--form-sign-background);
  border: 1px solid var(--form-sign-border);
  width: 80%;
  max-width: 480px;
  animation: opacity 1s ease-in-out;

  button {
    margin: 0 auto;
    width: 100%;
  }
  .MuiOutlinedInput-root {
    border-radius: 25px;
  }
  .MuiSelect-icon {
    color: var(--secondary);
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 1;
  }
  fieldset,
  .MuiSelect-select {
    border-color: var(--input-border);
  }
  input,
  .MuiSelect-select {
    border-radius: 25px;
    background: var(--input-background);
    color: var(--input-text);
    line-height: 1;
    &:focus {
      color: var(--input-text-focus) !important;
    }
  }
  div label {
    color: var(--input-text);
    font-family: "Galano Grotesque";
    font-size: 13px;
  }
  .Mui-focused {
    fieldset,
    .MuiSelect-select {
      border-color: var(--input-border-focus) !important;
    }
    color: var(--input-text-focus) !important;
  }
  label.MuiInputLabel-shrink,
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense) {
    background: var(--input-text-focus-bg);
    padding: 2px 4px 0 2px;
    border-radius: 0 8px 0 0;
    filter: contrast(0.7);
    color: var(--input-label-focus);
  }
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense) {
    top: -10px;
    width: calc(100% - 35px);
    opacity: 0.8;
    font-weight: 300;
    font-size: 13px;
  }
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense).Mui-focused,
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense).MuiFormLabel-filled {
    top: initial;
    width: auto;
    opacity: 1;
    font-weight: 300;
    font-size: 15px;
    color: var(--input-text-focus) !important;
    padding-left: 8px;
    filter: grayscale(1);
  }

  .question-btns {
    button {
      height: 25px;
      .MuiButton-label {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 420px) {
      button .MuiButton-label {
        font-size: 11px !important;
      }
    }

    @media screen and (max-width: 380px) {
      button .MuiButton-label {
        font-size: 9px !important;
      }
    }

    @media screen and (max-width: 320px) {
      button .MuiButton-label {
        font-size: 8px !important;
      }
    }
  }

  .select-item {
    label {
      width: 100%;
      text-align: left;
      color: var(--white);
    }
  }

  .mt-20 {
    margin-top: 10px;
    margin-left: 5px;
  }

  @media screen and (max-width: 1280px) {
    max-width: 440px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 430px;
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
    div label {
      font-size: 12px;
    }
    .MuiFormControl-root label:not(.MuiInputLabel-marginDense) {
      font-size: 11px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 12px;
    width: 100%;
    margin: 1rem 0 2rem;
    max-width: 100%;
  }
`;

export const Fields = styled.div.attrs((props: Props) => ({}))`
  padding: 2rem 0 0;
  width: 100%;
  > div {
    width: 100%;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 0 0;
  }
`;

export const Group = styled.span.attrs((props: Props) => ({}))`
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;

  > div:first-of-type {
    width: 40%;
  }
  > div:last-of-type {
    width: 58%;
    margin-left: 2%;
  }

  @media screen and (max-width: 768px) {
    > div:first-of-type {
      width: 100%;
    }
    > div:last-of-type {
      width: 100%;
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`;

export const Loading = styled.span.attrs((props: Props) => ({}))`
  position: absolute;
  bottom: 14px;
  left: 10px;
  .MuiCircularProgress-root {
    width: 28px !important;
    height: 28px !important;
  }
  * {
    stroke: white;
    color: white;
  }

  @media screen and (max-width: 480px) {
    bottom: 10px;
  }

  @media screen and (max-width: 380px) {
    bottom: 13px;
  }
`;

export const Terms = styled.div.attrs((props: Props) => ({}))`
  height: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  text-align: left;
  animation: opacity 1s ease-in-out;

  label {
    color: var(--white) !important;
    font-size: 12.75px !important;
    line-height: 1.2;
    letter-spacing: 0.45px;
  }

  @media screen and (max-width: 1200px) {
    label {
      font-size: 11.75px !important;
    }
  }

  @media screen and (max-width: 380px) {
    label {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 320px) {
    label {
      font-size: 11px !important;
    }
  }
`;

export const Accept = styled.span.attrs((props: Props) => ({}))`
  width: 100%;
  margin-bottom: 10px;
  bottom: 0;
  position: absolute;
  left: 0;
`;

export const ToBack = styled.span.attrs((props: Props) => ({}))`
  bottom: 45px;
  position: absolute;
  width: 90px;
  height: 14px;
  display: inline-block;
  right: 0;
`;
