import { Collapse } from "@material-ui/core";
import { ChevronLeft, ChevronRight, Search } from "@material-ui/icons";
import React from "react";
import OverlayContainer from "../OverlayContainer";

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  chevronContainerStyle?: React.CSSProperties;
  overlayContainerStyle?: React.CSSProperties;
  /** When specified, ensures the card will expand toward the left. */
  expandLeft?: boolean;
  /** When specified, ensures the card will expand toward the right. */
  expandRight?: boolean;
  openIcon?: "search";
  transformCloseIcon?: string | null;
  transformOpenIcon?: string | null;
  iconStyle?: React.CSSProperties;

  open?: boolean;

  expanded?: boolean;
  setExpanded?: (value: boolean) => void;
};

/**
 * A card that expands to one side of the screen.
 *
 * The props 'expandLeft' and 'expandRight' can be specified to ensure the card will expand
 * to a certain direction. Using both or none will default to expanding left.
 */
export default function CollapsibleCard(props: Props): JSX.Element {
  const [_expanded, _setExpanded] = React.useState(props.open !== undefined ? props.open : false);
  const expanded = props.expanded !== undefined ? props.expanded : _expanded;
  const setExpanded = props.setExpanded !== undefined ? props.setExpanded : _setExpanded;

  // Expand to the right when the prop is specified, and expand to the left in all other
  // cases.
  const shouldExpandToTheRight = props.expandRight && !props.expandLeft;

  return (
    <div
      style={{
        display: "flex",
        flex: "1, 1, content",
        minWidth: 0,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        ...props.style,
      }}
    >
      {!shouldExpandToTheRight && (
        <Chevron
          iconStyle={props.iconStyle}
          style={props.chevronContainerStyle}
          openIcon={props.openIcon}
          expanded={expanded}
          onOpened={() => setExpanded(true)}
          onClosed={() => setExpanded(false)}
          direction="left"
        />
      )}
      {/**
       * Currently, the Collapse component doesn't behave correctly and keeps
       * occupying space even when collapsed. Horizontal collapse is only
       * supported in version 5 of the material ui lib, so when we update,
       * remove this first conditional and adjust the Collapse.
       */}
      {expanded && (
        <OverlayContainer
          style={props.overlayContainerStyle}
          pointyBottomLeft={expanded}
        >
          <Collapse in={expanded} timeout="auto">
            {props.children}
          </Collapse>
        </OverlayContainer>
      )}

      {shouldExpandToTheRight && (
        <Chevron
          iconStyle={props.iconStyle}
          style={props.chevronContainerStyle}
          transformOpen={props.transformOpenIcon}
          transformClose={props.transformCloseIcon}
          openIcon={props.openIcon}
          expanded={expanded}
          onOpened={() => setExpanded(true)}
          onClosed={() => setExpanded(false)}
          direction="right"
        />
      )}
    </div>
  );
}

type ChevronProps = {
  expanded: boolean;
  style?: React.CSSProperties;
  /** Direction where the parent component opens. */
  direction: "right" | "left";
  onClosed: () => void;
  onOpened: () => void;
  openIcon?: "search";
  transformOpen?: string | null;
  transformClose?: string | null;
  iconStyle?: React.CSSProperties;
};

function Chevron(props: ChevronProps) {
  const IconComponent =
    props.direction === "left"
      ? props.expanded
        ? ChevronRight
        : props.openIcon !== undefined && props.openIcon === "search"
        ? Search
        : ChevronLeft
      : props.expanded
      ? ChevronLeft
      : props.openIcon !== undefined && props.openIcon === "search"
      ? Search
      : ChevronRight;

  const transformIcon = props.expanded ? props.transformOpen : props.transformClose;
  return (
    <div style={props.iconStyle}>
      <OverlayContainer
        pointyTopLeft={props.direction === "right" && props.expanded}
        pointyBottomLeft={props.direction === "right" && props.expanded}
        pointyTopRight={props.direction === "left" && props.expanded}
        pointyBottomRight={props.direction === "left" && props.expanded}
        style={{ height: "32px", width: "32px", ...props.style }}
      >
        <IconComponent
          style={{
            cursor: "pointer",
            display: "flex",
            flex: "1, 1, content",
            pointerEvents: "auto",
            color: "var(--black)",
            transform:
              transformIcon === undefined
                ? "scaleY(2)"
                : transformIcon === null
                ? ""
                : transformIcon,
            transformOrigin: "center",
          }}
          onClick={() => {
            if (props.expanded) {
              props.onClosed();
            } else {
              props.onOpened();
            }
          }}
        />
      </OverlayContainer>
    </div>
  );
}
