import styled from "styled-components";

type Props = {
  appearance?: "primary" | "secondary" | "full-card" | "border";
};

export const Block = styled.div.attrs((props: Props) => ({
  appearance: props.appearance,
  className: "card",
}))`
  background-color: var(--transparent);
  width: 100%;
  height: auto;
  border: 0;
  padding: 5px;

  ${(props: Props) =>
    props.appearance === "primary" &&
    `
      > label { 
        color: var(--dashboard-text-light); 
        font-size: 20px;
        font-family: "GalanoGrotesque-Semibold"
      }

  `}

  ${(props: Props) =>
    props.appearance === "secondary" &&
    `
      > label { 
        color: var(--dashboard-primary-bg); 
        font-size: 20px;
        font-family: "GalanoGrotesque-Semibold"
      }
      > span { flex-direction: column; }
  `}

  ${(props: Props) =>
    props.appearance === "border" &&
    `
      > label { 
        font-size: 20px;
        font-family: "GalanoGrotesque-Semibold"
      }
      > span { 
        border: 1px solid var(--dashboard-primary-bg); 
        min-height: 380px;

        @media screen and (max-width: 680px) {
            min-height: auto;
        }
      }
  `}

  ${(props: Props) =>
    props.appearance === "full-card" &&
    `
        border-top: 1px solid var(--dashboard-primary-bg); 
        margin-top: 2rem;
        padding-top: 1rem;      
        position: relative;
        display: inline-block;
        > span {
          width: 80%;
          float: right;  
        }
      }
  `}
`;

export const Card = styled.span.attrs((props: Props) => ({}))`
  background-color: var(--card-background);
  width: 100%;
  height: auto;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0 3px 6px var(--card-shadow);
  display: flex;
  align-items: center;
  min-height: 145px;
  margin: 15px 0 0;

  img {
    max-width: 96%;
  }

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;
