import React from "react";
import { useIntl } from "react-intl";
import Text from "../../BasicComponents/Text";
import Image from "../../BasicComponents/Image";
import Dropdown from "../../Inputs/Dropdown";
import { UserAvatar } from "./styles";
import { useDispatch } from "react-redux";
import { logOut } from "../../../../store/actions/loginActions";
import history from "../../../../navigation/history";
import { ChevronRight } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

interface Props {
  userId?: string;
  userName: string;
  userPicture?: string;
  userCompany?: string | undefined;
  appearance?: "user-info";
}

//TODO: definition of dropdown menu icons are pending
const menuProfile = [
  {
    value: "profile",
    text: "Minha Conta",
  },
  {
    value: "edit-profile",
    text: "Meu Perfil",
  },
  {
    value: "logout",
    text: "Sair",
  },
];

export default (props: Props) => {
  const intl = useIntl();

  //TODO: definition of dropdown menu icons are pending
  const menuProfile = [
    // {
    //   value: "edit-profile",
    //   text: intl.formatMessage({ id: "profile.profile" }),
    //   link: "/dashboard/profile",
    // },
    // {
    //   value: "profile",
    //   text: intl.formatMessage({id: "profile.account"}),
    //   link: "/dashboard/profile/account",
    // },
    // {
    //   value: "settings-profile",
    //   text: "Configurações",
    //   link: "/dashboard/profile/settings",
    // },
    // {
    //   value: "plans-profile",
    //   text: "Usuários",
    //   link: "/dashboard/profile/users",
    // },
    // {
    //   value: "licenses",
    //   text: "Licenças",
    //   link: "/dashboard/profile/licenses",
    // },
    {
      value: "logout",
      text: intl.formatMessage({ id: "headerDropdown.logout" }),
    },
  ];
  const dispatch = useDispatch();

  // Extra style to dropdown
  const useStyles = makeStyles({
    positioning: {
      marginTop: 45,
    },
  });
  const classes = useStyles();

  return (
    <>
      <UserAvatar key={props.userId} appearance={props.appearance}>
        {props.userPicture && (
          <Image src={props.userPicture} alt={props.userName} appearance={"img-avatar"} />
        )}
        <Text
          text={`${intl.formatMessage({ id: "dashboard.welcome" })} ${props.userName}!`}
          size={"small"}
        />
        {props.userCompany && <Text text={props.userCompany} />}
        <Dropdown
          listItems={menuProfile}
          appearance={"img-icon"}
          color={"light"}
          verticalPositioning={45}
          onSelect={(item) => {
            switch (item) {
              case "profile":
                history.push("/dashboard/profile");
                return;
              case "edit-profile":
                history.push("/dashboard/profile");
                return;
              case "logout":
                dispatch(logOut());
                return;
            }
          }}
        />
      </UserAvatar>
    </>
  );
};
