import styled from "styled-components";
type Props = {
  background?: string | "url";
  imageSize?: number;
  overlay?: boolean;
  slogan?: string;
  lang?: string;
  darkenBackground?: number;
};

export const Banner = styled.div.attrs((props: Props) => ({
  background: props.background,
  className: "banner",
  darkenBackground: props.darkenBackground,
}))`
  min-height: 580px;
  height: auto;

  ${(props: Props) =>
    props.imageSize &&
    `
      min-height: ${props.imageSize}px;
      height: ${props.imageSize}px;
  `}

  ${(props: Props) =>
    props.overlay === false &&
    `
    :before {
      background: none !important;
    }
  `}

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: var(--background-default);

  @media screen and (max-width: 1200px) {
    min-height: 480px;
  }

  @media screen and (max-width: 1024px) {
    min-height: 420px;
  }

  ${(props: Props) =>
    props.background &&
    `
    background: ${
      props.darkenBackground !== undefined
        ? `linear-gradient( rgba(0, 0, 0, ${(props.darkenBackground / 100).toFixed(
            2
          )}), rgba(0, 0, 0, ${(props.darkenBackground / 100).toFixed(2)}) ), url(${
            props.background
          });`
        : `url(${props.background});`
    }
    :before {
      
      content: "";
      background: var(--overlay-85);
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    animation-duration: 80s;
    animation-name: droneAnimation;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background-attachment: fixed;

  `}

  .MuiGrid-container {
    height: 440px;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      height: 340px;
    }

    @media screen and (max-width: 1024px) {
      height: 300px;
    }
  }

  > .MuiContainer-maxWidthLg {
    z-index: 0;
  }

  @media screen and (max-width: 1440px) {
    background-repeat: repeat-y;
  }
`;

export const Actions = styled.span.attrs((props: Props) => ({}))`
  margin-top: 2rem;
  display: block;
  button {
    margin-right: 15px;
    min-width: 180px;
  }
  @media screen and (max-width: 780px) {
    button {
      min-width: 140px !important;
      &::after {
        right: -7px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    button {
      min-width: 110px !important;
      margin-right: 5px;

      &::after {
        right: -7px;
      }
    }
  }
`;

export const TitleStyle = styled.div.attrs((props: Props) => ({}))`
  padding-bottom: 10rem;
`;

export const Infos = styled.div.attrs((props: Props) => ({}))`
  padding-bottom: 3rem;

  @media screen and (max-width: 1200px) {
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 680px) {
    .MuiGrid-align-items-xs-center {
      flex-direction: column-reverse;
      max-width: 100%;
      height: auto;
    }
  }
`;

export const Logo = styled.span.attrs((props: Props) => ({
  slogan: props.slogan,
  lang: props.lang,
}))`
  color: var(--white);
  position: relative;
  margin: auto;
  display: table;
  float: none;
  height: 124px;
  width: 210px;

  @media screen and (max-width: 768px) {
    height: 70px;
  }

  @media screen and (max-width: 680px) {
    width: 100vw;
    float: left;
    text-align: left;
    margin-bottom: 1rem;
  }

  ${(props: Props) =>
    props.slogan &&
    `
      :after {
        content: "${props.slogan}";
        font-size: 25px;
        font-family: "GalanoGrotesque-Medium";
        line-height: 1;
        left: 65px;
        bottom: 50px;
        white-space: nowrap;
        position: absolute;
        font-weight: 500;
        letter-spacing: .125px;

        @media screen and (max-width: 768px) {
          bottom: 12px;
        }

        @media screen and (max-width: 680px) {
          left: 52px;
        }
        

      }
  `}

  ${(props: Props) =>
    props.lang === "BR" &&
    `
    :after {
      font-size: 21.15px;

      @media screen and (max-width: 1200px) {
        font-size: 17px;
      }

      @media screen and (max-width: 991px) {
        font-size: 15px;
      }
    }
  `}

  ${(props: Props) =>
    props.lang === "EN" &&
    `
      :after { 
        font-size: 20px;

        @media screen and (max-width: 1200px) {
          font-size: 17px;
        }

        @media screen and (max-width: 991px) {
          font-size: 15px;
        }
      }
  `}

  ${(props: Props) =>
    props.lang === "ES" &&
    `
      :after { 
        font-size: 19.45px;

        @media screen and (max-width: 1200px) {
          font-size: 17px;
        }

        @media screen and (max-width: 991px) {
          font-size: 15px;
        }
      }
  `}

  ${(props: Props) =>
    props.lang === "FR" &&
    `
      :after { 
        font-size: 20px;

        @media screen and (max-width: 1200px) {
          font-size: 17px;
        }

        @media screen and (max-width: 991px) {
          font-size: 15px;
        }
      }
  `}
`;
