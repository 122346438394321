import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import OverlayContainer from "../../../components/Atomic/OverlayContainer";
import { couldMapElementBeVisibleAccordingMapBounds } from "../../../core/geometricFunctions";
import { SystemState } from "../../../store/reducers/systemReducer";

export default function (): JSX.Element {
  const isLoadingAreaInsideBoundingBox = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;
    const mapBoundingBox = projectTreeState.mapState.bounds;
    const projectList = projectTreeState.projectList;

    if (projectList === undefined || projectList === null || projectList.length === 0) return false;

    const effectiveProjectList = projectList.filter(
      (project) => project.areas !== null && project.areas.length > 0
    );

    if (effectiveProjectList.length === 0) return false;

    return effectiveProjectList
      .map((project) => {
        const visibleAreasInsideBoundingBox = project
          .areas!.filter((area) => area.visible)
          .filter((area) =>
            couldMapElementBeVisibleAccordingMapBounds(area.boundingBox, mapBoundingBox)
          );
        if (visibleAreasInsideBoundingBox.length === 0) return false;

        return visibleAreasInsideBoundingBox
          .map((area) => area.beingDeleted || area.isUpdating || area.isRunningMissionPlanner)
          .reduce((a, b) => a || b);
      })
      .reduce((a, b) => a || b);
  });
  return (
    <>
      {isLoadingAreaInsideBoundingBox && (
        <Box style={boxStyle}>
          <OverlayContainer
            style={{
              width: "32px",
              height: "32px",
              background: "rgba(255,255,255,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "center",
                height: "100%",
              }}
            >
              <CircularProgress style={{ color: "black" }} size="24px" />
            </div>
          </OverlayContainer>
        </Box>
      )}
    </>
  );
}

const boxStyle: React.CSSProperties = {
  position: "absolute",
  display: "flex",
  justifyContent: "flex-start",
  flex: 1,
  alignItems: "flex-end",
  flexDirection: "column",
  zIndex: 20,
  backgroundColor: "rgba(255,255,255,0)",
  top: 12,
  left: 0,
  right: 65,
  bottom: 0,
  pointerEvents: "none",
};
