import React, { useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox, CheckboxProps, Divider, FormControlLabel } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { BaseMapTypeId } from "../../../../../../components/map/BaseMap";

interface Props {
  onChanged: (newId: BaseMapTypeId) => void;
  value: BaseMapTypeId;
}

const useStyles = makeStyles({
  divider: {
    margin: 0.2,
    backgroundColor: "#EF7622",
  },
});

const ChangeMapTypesSelector = withStyles({
  root: {
    margin: "3px",
    outline: "none",
    boxSizing: "border-box",
    cursor: "pointer",
    borderRadius: "5px",
    border: "1px solid #EF7622",
    background: "rgba(0,0,0,0)",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
})(ToggleButtonGroup);

const ChangeMapTypesSelectorButton = withStyles({
  root: {
    color: "var(--black) !important",
    "&$selected": {
      color: "#EF7622 !important", // Standard color
    },
    height: 25,
  },
  selected: {},
})(ToggleButton);

const OrangeCheckbox = withStyles({
  root: {
    color: (props: CheckboxProps & { fade: boolean }) => (props.fade ? "#c8c8c8" : "#EF7622"),
    "&$checked": {
      color: (props: CheckboxProps & { fade: boolean }) => (props.fade ? "#c8c8c8" : "#EF7622"),
    },
  },
  checked: {},
})((props: CheckboxProps & { fade: boolean }) => <Checkbox color="default" {...props} />);

/**
 * This control renders as a selector button and a checkbox that behave like in google maps. When
 * switching to roadmap mode (which we call "default"), the "labels" checkbox is automatically
 * selected and greyed out but can still be toggled and switches between labeled roadmap and
 * plain roadmap; in satellite mode, the checkbox switches between labeled satellite and plain
 * satellite modes.
 */
export default (props: Props) => {
  const classes = useStyles();

  const intl = useIntl();
  // Gets the translations
  const termMapTypeMap = intl.formatMessage({ id: "map.type.roadmap" });
  const termMapTypeSatellite = intl.formatMessage({ id: "map.type.satellite" });

  /**
   * For "labeled_roadmap" and "plain_roadmap", we'll highlight our "default" button.
   * For "labeled_satellite" and "plain_satellite", we'll highlight our "satellite" button.
   */
  const toggleValue =
    props.value === "labeled_roadmap" || props.value === "plain_roadmap" ? "default" : "satellite";
  /**
   * The labels checkbox is only checked for labeled roadmap and labeled satellite.
   * When in roadmap mode (which we display as "default"), the checkbox will be disabled,
   * but it'll still work (since the Google Maps website works that way for whatever reason).
   */
  const checkboxValue = props.value === "labeled_roadmap" || props.value === "labeled_satellite";
  return (
    <div
      style={{
        display: "flex",
        flex: "1 1 content",
        flexDirection: "row",
      }}
    >
      {/** When this is pressed, we'll always set labels on the map. */}
      <ChangeMapTypesSelector value={toggleValue} exclusive>
        <ChangeMapTypesSelectorButton
          value="default"
          size="small"
          onClick={() => props.onChanged(checkboxValue ? "labeled_roadmap" : "plain_roadmap")}
        >
          {termMapTypeMap}
        </ChangeMapTypesSelectorButton>
        <Divider flexItem orientation="vertical" className={classes.divider} />
        {/** When this is pressed, preserve the option for labels. */}
        <ChangeMapTypesSelectorButton
          value="satellite"
          size="small"
          // Clicking on this option will always put the map in hybrid mode. We assume that the
          // checkbox below is checked.
          onClick={() => props.onChanged(checkboxValue ? "labeled_satellite" : "plain_satellite")}
        >
          {termMapTypeSatellite}
        </ChangeMapTypesSelectorButton>
      </ChangeMapTypesSelector>
      <FormControlLabel
        control={
          <OrangeCheckbox
            checked={checkboxValue}
            fade={toggleValue === "default"}
            onChange={(_) => {
              const newCheckboxValue = !checkboxValue;
              if (toggleValue === "default") {
                props.onChanged(newCheckboxValue ? "labeled_roadmap" : "plain_roadmap");
              } else {
                props.onChanged(newCheckboxValue ? "labeled_satellite" : "plain_satellite");
              }
            }}
            style={{ padding: 0, paddingLeft: 10 }}
            size="small"
          />
        }
        label={
          <span style={{ color: "var(--black)", fontSize: 12, marginRight: -5, lineHeight: -2 }}>
            {intl.formatMessage({ id: "map.enableLabels" })}
          </span>
        }
      />
    </div>
  );
};
