import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../store/reducers/systemReducer";
import { Address, Profile, Profile as ProfileModel, ProfilePreferences, Role } from "biohub-model";
import EditProfilePreferences from "../Profile/containers/EditProfilePreferences";
import { Actions, Block, Loading } from "../Profile/styles";
import { updateProfile } from "../../store/actions/profileActions";
import { Button, Icon, TextField } from "@material-ui/core";
import { BiohubLocale } from "../../store/reducers/localeReducer";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const systemProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );
  const [profile, setProfile] = useState<Profile | null>(systemProfile);
  const [profileChanges, setProfileChanges] = React.useState<
    | ({ id: string } & Partial<Profile> & {
          role: Role.external | Role.admin | Role.manager | Role.master | Role.operator;
        })
    | undefined
  >(undefined);

  const onProfileChange = (updatedProfile: Partial<Profile>) => {
    if (profile !== null) {
      setProfileChanges({ id: profile.id, role: profile.role, ...updatedProfile } as any);
      setProfile({ ...profile, ...updatedProfile } as any);
    }
  };

  const _updateProfile = (
    profile?: { id: string } & Partial<Profile> & {
      role: Role.admin | Role.external | Role.manager | Role.master | Role.operator;
    }
  ) => {
    let locale: string | undefined = undefined;
    if (profile !== undefined && profile.role !== undefined && profile.role !== Role.external) {
      const cast = profile as {
        role: Role.master | Role.admin | Role.manager | Role.operator;
        address: Address;
        /** Brazil: CPF. */
        document: string;
        landlineNumber: string;
        /** Brazil: ANAC number. */
        aviationNumber: string;
        /** Brazil: MAPA number. */
        agricultureNumber: string;
        rg: string;
        crea: string;
        preferences: ProfilePreferences;
        directClientId: string;
      };

      if (cast.preferences && cast.preferences.areaPreferences && cast.preferences.unitSystem === 2) {
        const areaConfig = cast.preferences.areaPreferences;
        areaConfig.flightSpeed /= 3.281;
        areaConfig.trackWidth /= 3.281;
        areaConfig.areaPadding /= 3.281;
        areaConfig.flightHeight /= 3.281;
      }
  
      locale = cast.preferences.languageCode;
    }
    if (profile !== undefined) dispatch(updateProfile(profile, locale as BiohubLocale));
  };

  const termSave = intl.formatMessage({ id: "action.save" });
  const termInsufficientPermissions = intl.formatMessage({
    id: "placeholder.settings.insufficientpermissions",
  });

  const loadingProfile: boolean = useSelector((state: SystemState) => state.profile.loadingProfile);

  const [updating, setUpdating] = useState<boolean>(false);

  React.useEffect(() => {
    if (!loadingProfile) {
      if (updating) {
        notify();
      }
      setUpdating(false);
    }
  }, [loadingProfile]);

  const notify = () => toast(intl.formatMessage({ id: "profile.settings.updated" }));

  const [isValidAreaConfig, setIsValidAreaConfig] = useState<boolean>(false);

  return loadingProfile ? (
    <Loading>
      <CircularProgress />
    </Loading>
  ) : (
    <>
      {profile !== null && profile.role !== Role.external ? (
        <div
          style={{
            paddingLeft: 130,
            paddingRight: 115,
            paddingTop: 50,
          }}
        >
          <Block elevation={3}>
            <EditProfilePreferences
              profile={profile}
              onChange={onProfileChange}
              configScreenGridSizes={[3, 8, 1]}
              validateValue={setIsValidAreaConfig}
            />
            <Actions className={"flip-card"}>
              <Button
                disabled={!isValidAreaConfig}
                onClick={() => {
                  setUpdating(true);
                  _updateProfile(profileChanges);
                }}
              >
                <Icon>save</Icon> <h6>{termSave}</h6>
              </Button>
            </Actions>
          </Block>
        </div>
      ) : (
        <div
          style={{
            paddingLeft: 130,
            paddingRight: 115,
            paddingTop: 50,
          }}
        >
          <Block elevation={3}>
            <TextField
              disabled={true}
              fullWidth
              margin="dense"
              variant="standard"
              value={termInsufficientPermissions}
              onChange={(v) => {}}
            />
          </Block>
        </div>
      )}
    </>
  );
};
