import styled from "styled-components";

type Props = {
  sectionColor?: "light" | "primary" | "mixed" | "dark-gray" | "white" | "light-gray";
  appearence?: "section-carousel";
};

export const Section = styled.section.attrs((props: Props) => ({
  sectionColor: props.sectionColor,
  appearence: props.appearence,
}))`
  width: 100%;
  background: var(--background-default);
  position: relative;
  padding: 3rem 0 5rem;
  color: var(--text-default);
  display: flex;
  flex-direction: column;
  z-index: 0;

  ${(props: Props) =>
    props.sectionColor === "light" &&
    `
    padding: 0 0 5rem;
    
    span {
      color: var(--primary);
    }
 `}

  ${(props: Props) =>
    props.sectionColor === "primary" &&
    `
    // padding: 2rem 0;
    background: var(--primary);
    color: var(--white);

      label {
        &::before {
          background: var(--third);
        }

        & ~ span {
          margin: 1rem 0 2rem;
          color: var(--third);
        }
      }
    }
 `}

  ${(props: Props) =>
    props.sectionColor === "mixed" &&
    `
    padding: 2rem 0;
    background: linear-gradient(180deg, var(--primary) 80%, var(--white) 80%);
    color: var(--white);
    }
 `}

  ${(props: Props) =>
    props.sectionColor === "dark-gray" &&
    `
    padding: 2rem 0;
    background: var(--dark-gray);
    }
 `}

${(props: Props) =>
    props.sectionColor === "light-gray" &&
    `
    padding: 2rem 0;
    background: var(--light-gray);
    }
 `}

  ${(props: Props) =>
    props.appearence === "section-carousel" &&
    `
    .MuiContainer-maxWidthLg {
      position: relative;
    }
  `}

  ${(props: Props) =>
    props.sectionColor === "white" &&
    `
    padding: 2rem 0;
    background: var(--white);
 `}
`;
