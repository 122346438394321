import { MarkerClusterer } from "@react-google-maps/api";
import { Area, Waypoint } from "biohub-model";
import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import EditingAreaOnTheMap from "./EditingAreaOnTheMap";
import ProjectOnTheMap from "./ProjectOnTheMap";

type Props = {
  pressingCtrlKey: boolean;
  selectedWaypointsAreaId?: string;
  selectedWaypointIndexes?: number[];
  onClickWaypoint: (area: Area, waypoint: Waypoint, waypointIndex: number) => void;
  homePointMarkerZIndex: number;
  homePointElevationMarkerZIndex: number;
  homePointElevationLabelZIndex: number;
  plannedPathZIndex: number;
  plannedPathBorderZIndex: number;
  waypointsDistanceMarkerZIndex: number;
  waypointsDistanceLabelZIndex: number;
  waypointOrientationMarkerZIndex: number;
  waypointDropMarkerZIndex: number;
  waypointElevationMarkerZIndex: number;
  waypointElevationLabelZIndex: number;
  defaultAreaPolygonZIndex: number;
  selectedAreaPoygonZIndex: number;
  areaEditingPolygonPolylineZIndex: number;
  areaEditingPolygonMarkersZIndex: number;
  projectSummaryMarkerZIndex: number;
  areaSummaryMarkerZIndex: number;
  getFlightReleaseTrackZIndex: (flightIndex: number) => number;
  getFlightPathZIndex: (flightIndex: number) => number;
  getFlightPathBorderZIndex: (flightIndex: number) => number;
};

export default function (props: Props): JSX.Element {
  const isEditingArea = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    return projectTreeState.editingArea !== undefined;
  });

  const stateProjectIds = useSelector((state: SystemState) =>
    (state.projectTree.projectList ?? []).map((project) => project.id)
  );

  return (
    <>
      {isEditingArea && (
        <EditingAreaOnTheMap
          {...props}
          areaPolygonZIndex={props.selectedAreaPoygonZIndex}
          key={`area-on-the-map-editing-area`}
          pressingCtrlKey={props.pressingCtrlKey}
        />
      )}

      {/* Between projects we have a cluster with pink color */}
      <MarkerClusterer
        styles={[
          {
            textColor: "black",
            url: "/marker_cluster_pink.png",
            width: projectsClusterSize,
            height: projectsClusterSize,
          },
        ]}
      >
        {(projectCluster) => (
          <>
            {stateProjectIds.map((projectId) => (
              <ProjectOnTheMap
                {...props}
                projectId={projectId}
                projectCluster={projectCluster}
                projectClusterSize={projectClusterSize}
                areasClusterSize={areasClusterSize}
              />
            ))}
          </>
        )}
      </MarkerClusterer>
    </>
  );
}

const projectsClusterSize = 85;
const projectClusterSize = 70;
const areasClusterSize = 55;
