import React from "react";
import { Clusterer } from "@react-google-maps/api/node_modules/@react-google-maps/marker-clusterer";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import { EditingAreaType } from "../../../store/reducers/projectTreeReducer";
import LongDistanceElementsGroup from "./LongDistanceElementsGroup";
import LongDistanceProjectSummary from "./LongDistanceProjectSummary";
import { Area, Waypoint } from "biohub-model";
import { MarkerClusterer } from "@react-google-maps/api";
import AreaOnTheMap from "./AreaOnTheMap";

type Props = {
  projectClusterSize: number;
  areasClusterSize: number;
  projectId: string;
  projectCluster: Clusterer;
  pressingCtrlKey: boolean;
  selectedWaypointsAreaId?: string;
  selectedWaypointIndexes?: number[];
  onClickWaypoint: (area: Area, waypoint: Waypoint, waypointIndex: number) => void;
  homePointMarkerZIndex: number;
  homePointElevationMarkerZIndex: number;
  homePointElevationLabelZIndex: number;
  plannedPathZIndex: number;
  plannedPathBorderZIndex: number;
  waypointsDistanceMarkerZIndex: number;
  waypointsDistanceLabelZIndex: number;
  waypointOrientationMarkerZIndex: number;
  waypointDropMarkerZIndex: number;
  waypointElevationMarkerZIndex: number;
  waypointElevationLabelZIndex: number;
  defaultAreaPolygonZIndex: number;
  selectedAreaPoygonZIndex: number;
  areaEditingPolygonPolylineZIndex: number;
  areaEditingPolygonMarkersZIndex: number;
  projectSummaryMarkerZIndex: number;
  areaSummaryMarkerZIndex: number;
  getFlightReleaseTrackZIndex: (flightIndex: number) => number;
  getFlightPathZIndex: (flightIndex: number) => number;
  getFlightPathBorderZIndex: (flightIndex: number) => number;
};

export default function (props: Props): JSX.Element {
  const projectInfo = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    let editingProjectTreeAreaId: string | undefined = undefined;

    const editingArea = projectTreeState.editingArea;
    if (
      editingArea !== undefined &&
      editingArea.type !== EditingAreaType.CreatingProject &&
      editingArea.type !== EditingAreaType.DrawingNewArea
    ) {
      editingProjectTreeAreaId = editingArea.area.id;
    }

    const project = (projectTreeState.projectList ?? []).find(
      (project) => project.id === props.projectId
    );
    if (project === undefined) return undefined;

    return {
      areasIds: (project.areas ?? [])
        .map((area) => area.id)
        .filter((id) => id !== editingProjectTreeAreaId),
      boundingBoxProject: project.boundingBox,
      boundingBoxDiagonalSize: project.boundingBoxDiagonalSize,
      visibility: project.visible,
    };
  });

  if (projectInfo === undefined) return <></>;

  return (
    <LongDistanceElementsGroup
      boundingBoxDiagonalSize={projectInfo.boundingBoxDiagonalSize}
      significantSize={props.projectClusterSize}
      render={(isInsignificant) => (
        <>
          {isInsignificant ? (
            projectInfo.visibility ? (
              /* Each project if your size is insignificant render a red marker like a cluster */
              <LongDistanceProjectSummary
                size={props.projectClusterSize}
                boundingBox={projectInfo.boundingBoxProject}
                areasCounter={projectInfo.areasIds.length}
                cluster={props.projectCluster}
                zIndex={props.projectSummaryMarkerZIndex}
              />
            ) : (
              <></>
            )
          ) : (
            <>
              {/* Between areas we have a cluster with yellow color */}
              <MarkerClusterer
                styles={[
                  {
                    textColor: "black",
                    url: "/marker_cluster_yellow.png",
                    width: props.areasClusterSize,
                    height: props.areasClusterSize,
                  },
                ]}
              >
                {(projectAreasCluster) => (
                  <>
                    {projectInfo.areasIds.map((areaId) => (
                      <AreaOnTheMap
                        clusterer={projectAreasCluster}
                        {...props}
                        getFlightReleaseTrackZIndex={props.getFlightReleaseTrackZIndex}
                        getFlightPathZIndex={props.getFlightPathZIndex}
                        getFlightPathBorderZIndex={props.getFlightPathBorderZIndex}
                        key={`${props.projectId}-${areaId}-area-on-the-map`}
                        projectId={props.projectId}
                        areaId={areaId}
                        selectedWaypointIndexes={
                          areaId === props.selectedWaypointsAreaId
                            ? props.selectedWaypointIndexes
                            : undefined
                        }
                        onClickWaypoint={(area, waypoint, waypointIndex) => {
                          props.onClickWaypoint(area, waypoint, waypointIndex);
                        }}
                      />
                    ))}
                  </>
                )}
              </MarkerClusterer>
            </>
          )}
        </>
      )}
    />
  );
}
