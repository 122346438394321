import React, { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import Row from "../../../components/Atomic/Areas/Row";
import Column from "../../../components/Atomic/Areas/Column";
import UserAvatar from "../../../components/Atomic/Tools/UserInfoAvatar";
import DashboardGraphComponent from "./graph";
import {
  Grid,
  Icon,
  Card as MaterialCard,
  CardContent,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { InitialPage, Loading } from "../styles";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

/* Images */
import imageClients from "../../../assets/icon/icon_clients.svg";
import imageCoverageArea from "../../../assets/icon/icon_coverage_area.svg";
import imageFlights from "../../../assets/icon/icon_released.svg";
import imageYield from "../../../assets/icon/icon_yield.svg";
import imageDrone from "../../../assets/icon/icon_report_drone.svg";
import DashboardService from "../../../services/DashboardService";
import { DashboardSummary, Role } from "biohub-model";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
//************ */

interface Props {
  userName: string;
  userCompany: string;
  userPicture?: string;
  userList: Array<{
    userId: string;
    userName: string;
    userPicture: string | "url";
    userCompany: string;
  }>;
}

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

type DashboardCardProps = {
  value: string;
  legend: string;
  legendSufix?: string;
} & ({ material: true; icon: string } | { material: false; svgPath: string });

function DashboardCard(props: DashboardCardProps) {
  const classes = useStyles();
  return (
    <MaterialCard className={classes.root}>
      <CardContent>
        <Box height={90}>
          <Grid container xs={12}>
            <Grid item xs={4}>
              {props.material ? (
                <Icon color={"primary"} fontSize={"large"}>
                  {props.icon}
                </Icon>
              ) : (
                <SvgIcon component="object" color={"primary"} fontSize={"large"}>
                  <embed type="image/svg+xml" src={props.svgPath} style={{ height: "100%" }} />
                </SvgIcon>
              )}
            </Grid>
            <Grid item xs={8} alignContent="flex-end">
              <div
                style={{
                  fontSize: 15,
                }}
              >
                {props.value}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box height={20}>
              <>
                <div style={{ fontSize: 12 }}>{props.legend}</div>
                {props.legendSufix !== undefined && (
                  <div style={{ fontSize: 10.5 }}>{props.legendSufix}</div>
                )}
              </>
            </Box>
          </Grid>
        </Box>
      </CardContent>
    </MaterialCard>
  );
}

function formatNumber(number: number, intl: IntlShape): string {
  return intl.formatNumber(number, {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  });
}

function loadedDashBoardCards(
  summary: DashboardSummary,
  intl: IntlShape
): Array<DashboardCardProps> {
  const dashboardCards: Array<DashboardCardProps> = [
    {
      svgPath: imageFlights,
      material: false,
      value:
        formatNumber(summary.monthReleaseAmount, intl) +
        " / " +
        formatNumber(summary.yearReleaseAmount, intl),
      legend: intl.formatMessage({ id: "dashboard.total.releasedFlights" }),
      legendSufix: intl.formatMessage({ id: "dashboard.total.yearMonthLegend" }),
    },
    {
      svgPath: imageCoverageArea,
      material: false,
      value:
        formatNumber(summary.monthPlannedArea, intl) +
        " / " +
        formatNumber(summary.monthReleasedArea, intl),
      legend: intl.formatMessage({ id: "dashboard.total.monthFlightYield" }),
      legendSufix: intl.formatMessage({ id: "dashboard.leggend.plannedReleased" }) + " (ha)",
    },
    {
      icon: "description",
      material: true,
      value:
        formatNumber(summary.monthFlightAmount, intl) +
        " / " +
        formatNumber(summary.yearFlightAmount, intl),
      legend: intl.formatMessage({ id: "dashboard.total.reports" }),
      legendSufix: intl.formatMessage({ id: "dashboard.total.yearMonthLegend" }),
    },
    {
      svgPath: imageDrone,
      material: false,
      value: formatNumber(summary.droneAmount, intl),
      legend: intl.formatMessage({ id: "dashboard.total.drones" }),
    },
    {
      svgPath: imageClients,
      material: false,
      value: formatNumber(summary.clientAmount, intl),
      legend: intl.formatMessage({ id: "dashboard.total.clients" }),
    },
    {
      svgPath: imageYield,
      material: false,
      value:
        formatNumber(summary.monthInvoice, intl) + " / " + formatNumber(summary.yearInvoice, intl),
      legend: intl.formatMessage({ id: "dashboard.total.sales" }),
      legendSufix: intl.formatMessage({ id: "dashboard.total.yearMonthLegend" }),
    },
  ];
  return dashboardCards;
}

export default (props: Props) => {
  const intl = useIntl();

  const [dashboardSummary, setDashboardSummary] = useState<DashboardSummary | undefined | null>(
    undefined
  );

  useEffect(() => {
    (async () => {
      const dashboardSummary = await DashboardService.getDashboardSummary();
      if (!dashboardSummary.success) {
        setDashboardSummary(null);
      } else {
        setDashboardSummary(dashboardSummary.data);
      }
    })();
  }, []);

  const dashboardCards =
    dashboardSummary !== undefined && dashboardSummary !== null
      ? loadedDashBoardCards(dashboardSummary, intl)
      : [];

  const userName = useSelector((state: SystemState) =>
    state.profile.userProfile !== null ? state.profile.userProfile.name : ""
  );
  const userCompany = useSelector((state: SystemState) =>
    state.profile.directClient !== null && state.profile.directClient.company !== undefined
      ? state.profile.directClient.company.name
      : undefined
  );
  const userRole = useSelector((state: SystemState) =>
    state.profile.userProfile !== null ? state.profile.userProfile.role : undefined
  );

  return (
    <>
      <InitialPage>
        {/* <Container> */}
        <Row>
          <Column lg={10} sm={11}>
            <UserAvatar userName={userName} userCompany={userCompany} appearance={"user-info"} />
          </Column>
        </Row>
        {dashboardCards.length !== 0 ? (
          <Grid container xs={12} spacing={1}>
            {dashboardCards.map((cardInfo) => {
              return (
                <Grid item lg={2} sm={4}>
                  <DashboardCard {...cardInfo} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          userRole !== Role.operator &&
          userRole !== Role.external && (
            <Loading>
              <CircularProgress />
            </Loading>
          )
        )}
        <Row>
          <Column lg={12} sm={12}>
            <DashboardGraphComponent />
          </Column>
        </Row>
        {/* </Container> */}
      </InitialPage>
    </>
  );
};
