import { Location } from "biohub-model";
import React from "react";
import { MemoPolyline } from "../MemoMapComponents";

type Props = {
  flightId: string;
  flownRoute: Location[];
  pathZindex: number;
  pathBorderZIndex: number;
};

export default function (props: Props): JSX.Element {
  const flownRouteOptionsInner = {
    strokeColor: "#1565C0",
    strokeWeight: 2,
    strokeOpacity: 1,
    zIndex: props.pathZindex,
  };

  const flownRouteOptionsOuter = {
    strokeColor: "#42A5F5",
    strokeWeight: 5,
    strokeOpacity: 1,
    zIndex: props.pathBorderZIndex,
  };

  return (
    <>
      <MemoPolyline
        key={`${props.flightId}-flight-path-innercolor`}
        path={props.flownRoute}
        options={flownRouteOptionsInner}
      />
      <MemoPolyline
        key={`${props.flightId}-flight-path-outercolor`}
        path={props.flownRoute}
        options={flownRouteOptionsOuter}
      />
    </>
  );
}
