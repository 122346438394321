import styled from "styled-components";

type Props = {
  appearance?: "teaser";
};

export const Footer = styled.footer.attrs((props: Props) => ({
  appearance: props.appearance,
}))`
  width: 100%;
  background: var(--footer-background);
  flex-direction: flex-end;
  position: relative;
  bottom: 0;
  z-index: 1;

  > div:first-of-type {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  ${(props: Props) =>
    props.appearance === "teaser" &&
    `
    > div:first-of-type {
      padding-top: 0;
      padding-bottom: 0;
    }
  `}
`;

export const Container = styled.div.attrs((props: Props) => ({}))`
  width: 80vw;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 0;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 92vw;
  }
`;

export const Copy = styled.div.attrs((props: Props) => ({}))`
  width: 100%;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: var(--footer-copyright);
  font-family: "BROmny";
  ul {
    width: auto;
    font-size: 10px;
    white-space: nowrap;

    @media screen and (max-width: 991px) {
      float: right;
      a {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0;
      margin: 0;
      li {
        padding: 5px;
      }
    }

    @media screen and (max-width: 680px) {
      float: none;
    }
  }

  * {
    align-self: center;
    max-height: 40px;

    @media screen and (max-width: 768px) {
      max-height: initial;
    }
  }

  img {
    margin: 0 5px;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 10px 0 0;
  }

  @media screen and (max-width: 680px) {
    text-align: center;
  }
`;

export const Mask = styled.span.attrs((props: Props) => ({}))`
  width: 100%;
  height: 185px;
  overflow: hidden;
  background: var(--black);
  display: block;
`;

export const Infos = styled.div.attrs((props: Props) => ({}))`
  width: 100%;
  max-width: 695px;

  @media screen and (max-width: 1280px) {
    max-width: 440px;
  }
`;

export const Map = styled.iframe.attrs((props: Props) => ({}))`
  width: 100%;
  height: 200px;
  border: 0;
  margin: 0;
  position: relative;
`;

export const Info = styled.span.attrs((props: Props) => ({}))`
  display: flex;
  align-items: center;
  width: 100%;
  span,
  svg {
    color: var(--primary);
    float: left;
    display: inline-block;
    font-size: 18px;
    margin-right: 10px;
    margin-top: 5px;
  }
  a {
    transition: all 0.5s linear;
    label {
      color: var(--black);
      font-size: 12px;
      line-height: 1;
      cursor: pointer;
    }
    &:hover,
    &:focus {
      color: var(--primary);
      text-decoration: underline;
      text-decoration-color: var(--highlighted);
    }
  }

  :nth-of-type(2) span {
    font-size: 15px;
    margin-left: 2px;
    margin-right: 12px;
  }

  @media screen and (max-width: 768px) {
    span:first-of-type {
      align-self: flex-start;
    }
    margin: 5px 0;
  }
`;

export const ToTop = styled.a.attrs((props: Props) => ({}))`
  position: fixed;
  bottom: -24px;
  right: 25px;
  z-index: 2;
  width: 50px;
  height: 50px;
  background: var(--footer-copyright);
  color: var(--text-default-light);
  border-radius: 50px 50px 0 0;
  padding: 0;
  text-align: center;
  span {
    font-size: 2.5rem;
    line-height: 0.75;
  }

  @media screen and (max-width: 768px) {
    right: 5px;
  }
`;
