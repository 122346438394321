import { Location, Waypoint } from "biohub-model";
import React from "react";
import { MemoPolyline } from "../MemoMapComponents";

type Props = {
  polygonId: string;
  waypoints: Waypoint[];
  activeLiberations?: boolean[];
  onClick?: (segmentIndex: number, location: Location) => void;
  pathZIndex: number;
  pathBorderZIndex: number;
};

export default function (props: Props): JSX.Element {
  const { waypoints, activeLiberations } = props;
  return (
    <>
      {waypoints.map((waypoint, waypointIndex) => {
        const key = `${props.polygonId}-flight-planned-route-${waypointIndex}`;
        if (waypointIndex >= waypoints.length - 1) {
          return <div key={key}></div>;
        }
        const actualActive =
          activeLiberations === undefined ? true : activeLiberations[waypointIndex];

        return (
          <>
            <MemoPolyline
              key={key}
              path={[waypoint, waypoints[waypointIndex + 1]].map((point) => ({
                lat: point.location.lat,
                lng: point.location.lng,
              }))}
              options={{
                strokeWeight: 2,
                zIndex: props.pathZIndex,
                strokeColor: actualActive ? "rgba(251,140,0,1)" : "rgba(239, 83, 80, 1)",
                strokeOpacity: !actualActive ? 0 : undefined,
                icons: actualActive
                  ? undefined
                  : [
                      {
                        icon: { path: "M 0,-1 0,1", strokeOpacity: 0.75, scale: 3 },
                        offset: "0",
                        repeat: "20px",
                      },
                    ],
              }}
              onClick={(e) => {
                const latLng = e.latLng;
                if (latLng === null) return;

                props.onClick?.(waypointIndex, {
                  lat: latLng.lat(),
                  lng: latLng.lng(),
                });
              }}
            />
            {/* /// 0xFFFFCC80 == rgba(255,204,128,1) /// 0xFFFB8C00 == rgba(251,140,0,1) */}
            <MemoPolyline
              key={key}
              path={[waypoint, waypoints[waypointIndex + 1]].map((point) => ({
                lat: point.location.lat,
                lng: point.location.lng,
              }))}
              options={{
                strokeWeight: 5,
                zIndex: props.pathBorderZIndex,
                strokeColor: actualActive ? "rgba(255,204,128,1)" : "rgba(239, 83, 80, 1)",
                strokeOpacity: !actualActive ? 0 : undefined,
                icons: actualActive
                  ? undefined
                  : [
                      {
                        icon: { path: "M 0,-1 0,1", strokeOpacity: 0.75, scale: 3 },
                        offset: "0",
                        repeat: "20px",
                      },
                    ],
              }}
              onClick={(e) => {
                const latLng = e.latLng;
                if (latLng === null) return;

                props.onClick?.(waypointIndex, {
                  lat: latLng.lat(),
                  lng: latLng.lng(),
                });
              }}
            />
          </>
        );
      })}
    </>
  );
}
