/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Nav from "../Tools/Menu";
import Link from "../BasicComponents/Link";
import Image from "../BasicComponents/Image";
import Row from "../Areas/Row";
import Column from "../Areas/Column";
import {
  Header,
  Actions,
  MenuBar,
  Bar,
  Mask,
  List,
  Item,
  LinkMenu,
  Submenu,
  SubItens,
  NotifyCart,
} from "./styles";
import { Container } from "@material-ui/core";
import { ShoppingCartOutlined } from "@material-ui/icons";
import { SystemState } from "../../../store/reducers/systemReducer";

import imageLogo from "../../../assets/logo/icon_white.svg";
import imageInvert from "../../../assets/logo/icon_blue.svg";
import imageBiohubWithBioMaps from "../../../assets/gallery/logoBioHub_with_logoBioMaps.png";
import imageBiobot from "../../../assets/logo/1-bioBOT imagotipo.png";
import imageBioCote from "../../../assets/logo/1-bioCOTE imagotipo.png";
import imageBioMite from "../../../assets/logo/1-bioMITE imagotipo.png";
//import imageTag from "../../../assets/logo/icon_tag_secondary.svg";
import iconNotifyCart from "../../../assets/icon/icon_notify_cart.svg";

interface Props {
  color?: "dark" | "light";
  showSign?: boolean;
  showLogin?: boolean;
}

export type Item = {
  id: string;
  title: string;
  amount: number;
  price: number;
  isEquipment: boolean;
};
export default (props: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [thisColor, setThisColor] = React.useState(props.color);
  const initialColor = props.color;

  const cartItemsQuantity = useSelector(
    (state: SystemState) => state.provisoryCart.cartSolutions.length
  );

  const [menuItems, setMenuItems] = React.useState([
    { active: false, link: "/about", text: intl.formatMessage({ id: "menu.about" }) },
    {
      active: false,
      link: "/solutions",
      text: intl.formatMessage({ id: "menu.solutions" }),
      submenu: [
        { image: imageBiohubWithBioMaps, link: "/solutions", text: "bioHUB+bioMAPS" },
        { image: imageBiobot, link: "/product/bioBOT", text: "bioBOT" },
        { image: imageBioCote, link: "/product/bioCOTE", text: "bioCOTE" },
        { image: imageBioMite, link: "/product/bioMITE", text: "bioMITE" },
      ],
    },
    // {
    //   active: false,
    //   link: "/prices",
    //   text: intl.formatMessage({ id: "menu.pricing" }),
    //   submenu: [
    //     {
    //       image: imageBiomaps,
    //       link: "/prices",
    //       text: "",
    //       description: intl.formatMessage({ id: "menu.biomapsInfo" }),
    //     },
    //   ],
    //   subtype: [
    //     {
    //       image: imageTag,
    //       link: "/prices",
    //       text: "BioBOT",
    //     },
    //     {
    //       image: imageTag,
    //       link: "/prices",
    //       text: "BioMITE",
    //     },
    //     {
    //       image: imageTag,
    //       link: "/prices",
    //       text: "BioFUNGUS",
    //     },
    //     {
    //       image: imageTag,
    //       link: "/'product's",
    //       text: "BioCOTE",
    //     },
    //     {
    //       image: imageTag,
    //       link: "/prices",
    //       text: "BioPUPA",
    //     },
    //     {
    //       image: imageTag,
    //       link: "#",
    //       text: "PHEROBio",
    //     },
    //   ],
    // },
    { active: false, link: "/contact", text: intl.formatMessage({ id: "menu.contact" }) },
  ]);

  React.useEffect(() => {
    setMenuItems((state) =>
      state.map((item) =>
        item.link === location.pathname ? { ...item, active: true } : { ...item, active: false }
      )
    );
  }, [location.pathname]);

  function cartDirection() {
    if (cartItemsQuantity > 0) return "purchase_order_intent";
    return "solutions";
  }

  return (
    <Header id="top" color={thisColor}>
      <Container>
        <Row alignItems="center">
          <Column xl={4} lg={1} xs={2} md={1} sm={1}>
            <Link to="/">
              <Image
                src={props.color ? imageInvert : imageLogo}
                alt={intl.formatMessage({ id: "info.slogan" })}
                appearance={"img-logo"}
                className={"logo"}
              />
            </Link>
          </Column>
          <Column xl={5} lg={6} xs={1} md={5} sm={1}>
            <Mask open={open}>
              <MenuBar open={open} onClick={() => setOpen(!open)}>
                <Bar />
                <Bar />
                <Bar />
              </MenuBar>

              <List color={props.color}>
                {menuItems.map((item) => (
                  <Item
                    color={props.color}
                    key={item.text}
                    active={item.active}
                    className={item.submenu && "submenu"}
                    onMouseEnter={item.submenu && (() => setThisColor("light"))}
                    onMouseLeave={item.submenu && (() => setThisColor(initialColor))}
                  >
                    <LinkMenu to={item.link}>{item.text}</LinkMenu>
                    {item.submenu && (
                      <Submenu /*className={item.subtype && "subtype"}*/>
                        <Container>
                          {(item.submenu as any[]).map((sub: any) => (
                            <>
                              <Item color={props.color}>
                                <LinkMenu to={sub.link}>
                                  <Image
                                    src={sub.image}
                                    alt={sub.text}
                                    appearance={"img-partner"}
                                  />
                                  {sub.description}
                                </LinkMenu>
                              </Item>
                            </>
                          ))}
                          {/* {item.subtype && (
                            <SubItens>
                              {item.subtype.map((type) => (
                                <>
                                  <Item color={"light"}>
                                    <LinkMenu to={type.link}>
                                      <Image
                                        src={type.image}
                                        alt={type.text}
                                        appearance={"img-partner"}
                                      />
                                      {type.text}
                                    </LinkMenu>
                                  </Item>
                                </>
                              ))}
                            </SubItens>
                          )} */}
                        </Container>
                      </Submenu>
                    )}
                  </Item>
                ))}
              </List>
            </Mask>
          </Column>
          <Column xl={4} lg={4} xs={10} md={4} sm={10}>
            <Actions>
              <Link to="/login" appearance={"button-outline"} color={"light"} size={"medium"}>
                {intl.formatMessage({ id: "action.login" })}
              </Link>
              <Link to="/signUp" appearance={"outline-invert"} color={"light"} size={"medium"}>
                {intl.formatMessage({ id: "action.signUp" })}
              </Link>
              <Link
                to={`/${cartDirection()}`}
                appearance={"button-outline"}
                color={"light"}
                size={"medium"}
              >
                <ShoppingCartOutlined />
                {cartItemsQuantity >= 1 && (
                  <NotifyCart>
                    <Image src={iconNotifyCart} />
                  </NotifyCart>
                )}
              </Link>
            </Actions>
          </Column>
        </Row>
      </Container>
    </Header>
  );
};
