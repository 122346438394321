import React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Container from "../../components/Atomic/Areas/Container";
import Row from "../../components/Atomic/Areas/Row";
import Column from "../../components/Atomic/Areas/Column";
import Search from "../../components/Atomic/Inputs/Search";
import MenuBars from "../../components/Atomic/Tools/MenuBars";
import Menu from "../../components/Atomic/Tools/Menu/dashboard";
import UserAvatar from "../../components/Atomic/Tools/UserInfoAvatar";
import Notifications from "../../components/Atomic/Tools/NotificationsIcon";
import InitialPage from "./containers/initialPage";
import MapPage from "../map/MapPage";
import ProfilePage from "../Profile";
import { Dashboard, Header, Board, Right } from "./styles";
import { useSelector } from "react-redux";
import { SystemState } from "../../store/reducers/systemReducer";
import { BiohubLocale } from "../../store/reducers/localeReducer";
import LocalePicker from "../../components/Atomic/Tools/FlagLocalePicker";
import Settings from "../Settings/screen";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [hasNotification, setHasNotification] = React.useState(true); //TODO: if true = show badge

  //TODO: receives data from the logged in user
  const userInfo = {
    userId: "30",
    userName: "Philipe Nelms",
    userPicture: "https://i.pravatar.cc/60?img=8",
    userCompany: "NCB Sistemas Embarcados",
  };

  const userList = [
    {
      userId: "10",
      userName: "Alex Ruiz",
      userPicture: "https://i.pravatar.cc/60?img=3",
      userCompany: "Popóyan, S.A.",
    },
    {
      userId: "11",
      userName: "Alex Ruiz",
      userPicture: "https://i.pravatar.cc/60?img=6",
      userCompany: "Popóyan, S.A.",
    },
  ];

  const notificationsList = [
    {
      id: "notification",
      notificationType: "warning",
      text: intl.formatMessage({ id: "notif.newmessages" }),
      link: "/dashboard",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin efficitur posuere odio, non sodales orci dictum in. Nulla at ipsum id massa lobortis vehicula. Aliquam convallis ipsum et consectetur suscipit. Donec urna arcu, ultrices non mi sit amet, semper eleifend ligula. Phasellus quis mauris nisi. Phasellus tempor, sem vestibulum fermentum condimentum, enim tellus mattis velit, id consequat eros purus consectetur arcu. Aliquam sollicitudin lacinia auctor.",
      hasRead: true,
    },
    {
      id: "notification",
      notificationType: "info",
      text: intl.formatMessage({ id: "notif.pendencies" }),
      link: "/dashboard/profile/account",
      description:
        "Proin efficitur posuere odio, non sodales orci dictum in. Nulla at ipsum id massa lobortis vehicula. Aliquam convallis ipsum et consectetur suscipit. ",
      hasRead: true,
    },
    {
      id: "notification",
      notificationType: "error",
      text: intl.formatMessage({ id: "notif.error" }),
      link: "/dashboard/profile/settings",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sollicitudin lacinia auctor.",
      hasRead: true,
    },
    {
      id: "notification",
      notificationType: "success",
      text: intl.formatMessage({ id: "notif.success" }),
      link: "/dashboard/profile/settings",
      description:
        "Donec urna arcu, ultrices non mi sit amet, semper eleifend ligula. Phasellus quis mauris nisi. Phasellus tempor, sem vestibulum fermentum condimentum, enim tellus mattis velit, id consequat eros purus consectetur arcu. Aliquam sollicitudin lacinia auctor.",
      hasRead: false,
    },
  ];

  // this function maps the url and informs what type of component will be rendered
  function createComponent(path: any) {
    switch (path) {
      case "/dashboard/map":
        return <MapPage />;
      case "/dashboard/profile":
        return <ProfilePage />;
      case "/dashboard/profile/settings":
        return <Settings />;
      case "/dashboard/profile/account":
        return <ProfilePage activeItem="account" />;
      case "/dashboard/profile/licenses":
        return <ProfilePage activeItem="licenses" />;
      // case "/dashboard/profile/settings":
      //   return <ProfilePage activeItem="settings" />;
      // case "/dashboard/profile/users":
      //   return <ProfilePage activeItem="management" />;
      case "/dashboard/profile/notifications":
        return <ProfilePage activeItem="notifications" notificationItens={notificationsList} />;
      default:
        return (
          <InitialPage
            userPicture={userInfo.userPicture}
            userName={userInfo.userName}
            userCompany={userInfo.userCompany}
            userList={userList}
          />
        );
    }
  }

  const userName = useSelector((state: SystemState) =>
    state.profile.userProfile !== null ? state.profile.userProfile.name : ""
  );

  // Spacing for the top items in the header.
  // Ideally, the first space should actually be just enough to handle the drawer to the side,
  // but I don't know how to do that.
  // const spacing = open ? [0, 7, 5] : [0, 7, 5];
  const spacing = [0, 7, 5];

  // This is extremely hard-coded, that's because if the width is auto for the nav bar
  // The transition can not be aplyed. So, to maintain the width transition, we hard-code
  // Each width for each language
  // ! Find a better solution in the future
  ////////////////////////////////////////////////////////////////////////////////
  const localeCode = useSelector((state: SystemState) => state.locale.localeCode);
  return (
    <>
      <Dashboard>
        {/* this was put for effects-defined components in the menu */}
        <Header open={open}>
          <Container>
            <Row alignItems="center">
              <Column
                xl={spacing[0]}
                lg={spacing[0]}
                xs={spacing[0]}
                md={spacing[0]}
                sm={spacing[0]}
              >
                {/** There used to be a "MenuBars" item here too. */}
                <Menu open={open} onToggleOpen={() => setOpen(!open)} />
              </Column>
              <Column
                xl={spacing[1]}
                lg={spacing[1]}
                xs={spacing[1]}
                md={spacing[1]}
                sm={spacing[1]}
              >
                {location.pathname !== "/dashboard/map" && (
                  <div style={{
                    marginLeft: open ? 220 : 75,
                    transition: "margin-left 0.3s linear"
                  }}>
                    <Search color={"light"} open={open} />
                  </div>
                )}
              </Column>
              <Column
                xl={spacing[2]}
                lg={spacing[2]}
                xs={spacing[2]}
                md={spacing[2]}
                sm={spacing[2]}
              >
                {location.pathname !== "/dashboard/map" && (
                  <Right>
                    <Notifications notificationItens={notificationsList} />
                    <UserAvatar
                      userId={userInfo.userId}
                      userName={userName}
                      //userPicture={userInfo.userPicture}
                    />
                  </Right>
                )}
              </Column>
            </Row>
          </Container>
        </Header>
        <Board
          open={open}
          // TODO: Fix dynamic sidebar size based on language
          openWidth={"220px"}
          closeWidth={"75px"}
          retractedTop={location.pathname === "/dashboard/map" ? true : false}
        >
          {createComponent(location.pathname)}
        </Board>
        <div
          style={{
            position: "absolute",
          }}
        >
          {location.pathname === "/dashboard/profile/settings" && <LocalePicker />}
        </div>
      </Dashboard>
    </>
  );
};
