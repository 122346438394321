import styled from "styled-components";
import Grid from "@material-ui/core/Container";

type Props = {};

export const Container = styled(Grid).attrs((props: Props) => ({}))`
  z-index: 1;
`;

export const Content = styled.div.attrs((props: Props) => ({}))``;
