import { REHYDRATE } from "redux-persist/es/constants";
import {
  CHANGE_LOCALE,
  CHANGE_REGION,
  SET_LOCALE_FROM_PREFERENCES,
} from "../actions/localeActions";
import { SystemAction } from "../actions/systemActions";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export type BiohubLocale = "pt-BR" | "en" | "es" | "fr";
export type BiohubRegion = "brazil" | "es" | "other";

// Other things may come to exist in this type, but for now it really is just
// the locale.
export type LocaleState = {
  key: "locale";
  localeCode: BiohubLocale;
  changed?: boolean;
  usageRegion: BiohubRegion;
};

const initialLocaleCode = getBrowserLanguage();

const INITIAL_STATE: LocaleState = {
  key: "locale",
  localeCode: initialLocaleCode,
  usageRegion:
    initialLocaleCode === "pt-BR" ? "brazil" : initialLocaleCode === "es" ? "es" : "other",
  changed: false,
};

/**
 * Function that gets the language the browser is installed in, and returns the
 * best match among the languages in BiohubLocale.
 *
 * Note that this doesn't read the languages that the user configured as their preferred
 * languages, but rather the browser installation's language. Hopefully that is sufficient.
 */
function getBrowserLanguage(): BiohubLocale {
  const browserLang = window.navigator.language.toLocaleLowerCase();
  // TODO: There's probably a better logic for choosing the closest locale.
  if (browserLang.includes("en")) {
    return "en";
  }
  if (browserLang.includes("pt")) {
    return "pt-BR";
  }
  if (browserLang.includes("es")) {
    return "es";
  }
  if (browserLang.includes("fr")) {
    return "fr";
  }

  return "en";
}

// Reducer
export function localeReducer(state = INITIAL_STATE, action: SystemAction): LocaleState {
  const effectiveState = {
    ...INITIAL_STATE,
    ...state,
  };

  switch (action.type) {
    case REHYDRATE:
      if (action.payload?.key === "locale") {
        return {
          ...INITIAL_STATE,
          ...action.payload,
        };
      }

      return effectiveState;
    case SET_LOCALE_FROM_PREFERENCES:
      return {
        ...effectiveState,
        localeCode: getBrowserLanguage(),
      };
    case CHANGE_LOCALE:
      return {
        ...effectiveState,
        changed: true,
        localeCode: action.payload.locale,
      };
    case CHANGE_REGION:
      return {
        ...effectiveState,
        usageRegion: action.payload.region,
      };
    default:
      return effectiveState;
  }
}

export type LocalePersistedState = LocaleState;

const localePersistConfig: PersistConfig<LocaleState> = {
  key: "locale",
  storage,
  whitelist: ["key", "localeCode", "usageRegion"],
};

export const localePersistedReducer = persistReducer(localePersistConfig, localeReducer);
