import { Drone } from "biohub-model";
import { removeArrayDuplicates } from "./utils";

type DroneAndSimplifiedName = {
  drone: Drone;
  simplifiedName: string;
  serialNumberSuffix: string | undefined;
  actualSerialNumberSuffixIndex: number;
};

type SimplifiedDrone = {
  simplifiedName: string;
  id: string;
};

export const simplifyDroneNames = (drones: Drone[]): SimplifiedDrone[] => {
  const getDroneModelSimplifiedName = (droneModelName: string) => {
    const words = droneModelName.split(" ");

    return words
      .map(getDroneModelNameWordAbbreviation)
      .map((e, index, array) => {
        if (index !== 0 && index !== array.length - 1) {
          return `${e} `;
        }
        return e;
      })
      .join("");
  };

  let dronesAndSimplifiedNames: DroneAndSimplifiedName[] = drones.map((drone) => ({
    drone: drone,
    simplifiedName: getDroneModelSimplifiedName(drone.model.modelName),
    serialNumberSuffix: undefined,
    actualSerialNumberSuffixIndex: 0,
  }));

  const droneModelsIds = removeArrayDuplicates(drones.map((drone) => drone.model.id));

  for (const droneModelId of droneModelsIds) {
    const dronesAndSimplifiedNamesForThatDroneModel = dronesAndSimplifiedNames
      .map((drone, index) => ({
        ...drone,
        index: index,
      }))
      .filter((drone) => drone.drone.model.id === droneModelId);

    const dronesAndSimplifiedNamesForThatDroneModelWithSerialNumberSuffix =
      getDroneSimplifiedNamesForTheSameDroneModel(dronesAndSimplifiedNamesForThatDroneModel);

    for (const result of dronesAndSimplifiedNamesForThatDroneModelWithSerialNumberSuffix) {
      dronesAndSimplifiedNames[result.index] = result;
    }
  }

  const simplifiedDrones: SimplifiedDrone[] = dronesAndSimplifiedNames.map((e) => {
    let result: SimplifiedDrone = {
      simplifiedName: e.simplifiedName,
      id: e.drone.id,
    };

    if (e.serialNumberSuffix !== undefined) {
      if (e.serialNumberSuffix.length > 0) {
        result.simplifiedName = `${result.simplifiedName} ${e.serialNumberSuffix}`;
      }

      if (e.actualSerialNumberSuffixIndex < e.drone.serialNumber.length) {
        result.simplifiedName = `${result.simplifiedName}...`;
      }
    }

    return result;
  });

  return simplifiedDrones;
};

const hasRepeatedSimplifiedNames = (dronesAndSimplifiedNames: DroneAndSimplifiedName[]) => {
  const resultNames = getResultNames(dronesAndSimplifiedNames);
  return resultNames.length !== removeArrayDuplicates(resultNames).length;
};

const getResultNames = (dronesAndSimplifiedNames: DroneAndSimplifiedName[]) => {
  return dronesAndSimplifiedNames.map((e) => {
    let result = e.simplifiedName;
    if (e.serialNumberSuffix !== undefined) {
      if (e.serialNumberSuffix.length > 0) {
        result = `${result} ${e.serialNumberSuffix}`;
      }

      if (e.actualSerialNumberSuffixIndex < e.drone.serialNumber.length) {
        result = `${result}...`;
      }
    }

    return result;
  });
};

const getDroneModelNameWordAbbreviation = (word: string): string => {
  const lowerCaseWord = word.toLowerCase();

  if (lowerCaseWord === "matrice") {
    return "M";
  } else if (lowerCaseWord === "phantom") {
    return "P";
  } else if (lowerCaseWord === "advanced") {
    return "Adv";
  } else if (lowerCaseWord === "pro") {
    return "Pro";
  } else if (lowerCaseWord === "standard") {
    return "Std";
  } else if (!isNaN(Number(lowerCaseWord))) {
    return lowerCaseWord;
  } else if (
    lowerCaseWord.substring(0, 1) === "v" &&
    !isNaN(Number(lowerCaseWord.substring(1, lowerCaseWord.length)))
  ) {
    let versionText: string = Number(
      lowerCaseWord.substring(1, lowerCaseWord.length - 1)
    ).toString();

    return `V${versionText}`;
  }

  return lowerCaseWord.substring(0, 1).toUpperCase();
};

const getDroneSimplifiedNamesForTheSameDroneModel = (
  _dronesAndSimplifiedNames: (DroneAndSimplifiedName & { index: number })[]
): (DroneAndSimplifiedName & { index: number })[] => {
  let dronesAndSimplifiedNames = [..._dronesAndSimplifiedNames];

  while (hasRepeatedSimplifiedNames(dronesAndSimplifiedNames)) {
    for (let i = 0; i < dronesAndSimplifiedNames.length; i++) {
      let droneAndSimplifiedName = dronesAndSimplifiedNames[i];

      const actualSerialNumberSuffixIndex = droneAndSimplifiedName.actualSerialNumberSuffixIndex;
      const droneSerialNumber = droneAndSimplifiedName.drone.serialNumber;
      if (actualSerialNumberSuffixIndex < droneSerialNumber.length) {
        let serialNumberSuffix = droneAndSimplifiedName.serialNumberSuffix;
        if (serialNumberSuffix === undefined) {
          serialNumberSuffix = "";
        }
        serialNumberSuffix =
          serialNumberSuffix +
          droneSerialNumber.substring(
            actualSerialNumberSuffixIndex,
            actualSerialNumberSuffixIndex + 1
          );

        droneAndSimplifiedName = {
          ...droneAndSimplifiedName,
          serialNumberSuffix: serialNumberSuffix,
          actualSerialNumberSuffixIndex: actualSerialNumberSuffixIndex + 1,
        };
      }

      dronesAndSimplifiedNames[i] = droneAndSimplifiedName;
    }
  }

  return dronesAndSimplifiedNames;
};
