import React from "react";
import { FlipCard, Inner, Front, Back } from "./styles";

interface Props {
  frontChildren?: React.ReactNode;
  backChildren?: React.ReactNode;
  active: boolean;
  color?: "light";
  height?: number;
}

export default (props: Props) => {
  return (
    <FlipCard color={props.color} height={props.height}>
      <Inner active={props.active}>
        <Front color={props.color}>{props.frontChildren}</Front>
        <Back color={props.color}>{props.backChildren}</Back>
      </Inner>
    </FlipCard>
  );
};
