import { Location } from "biohub-model";
import React from "react";
import { MemoMarker } from "../MemoMapComponents";
import { DistanceMarker } from "./DistanceMarker";

type Props = {
  polygonId: string;
  location: Location;
  isEditing: boolean;
  showDistanceLabels?: boolean;
  waypointScale?: number;
  homePointHeightLegend?: string;
  onMoved?: (newPosition: Location) => void;
  markerZIndex: number;
  elevationMarkerZIndex: number;
  elevationLabelZIndex: number;
};

export default function (props: Props): JSX.Element {
  const sizeMultiplier = props.waypointScale ?? 1;
  const showDistanceLabel = props.showDistanceLabels ?? true;
  return (
    <>
      <MemoMarker
        key={`${props.polygonId}-home-point`}
        icon={{
          url: "/icon_homepoint.png",
          scaledSize: new google.maps.Size(sizeMultiplier * 24, sizeMultiplier * 24),
          anchor: new google.maps.Point(12, 12),
        }}
        position={props.location}
        draggable={props.isEditing}
        onDragEnd={(e) => {
          const latLng = e.latLng;
          if (latLng === null) return;

          props.onMoved?.({
            lat: latLng.lat(),
            lng: latLng.lng(),
          });
        }}
        zIndex={props.markerZIndex}
      />
      {showDistanceLabel && props.homePointHeightLegend !== undefined && (
        <DistanceMarker
          polygonId={props.polygonId}
          waypointIndex={-1}
          distanceLegend={{
            label: props.homePointHeightLegend,
            location: props.location,
          }}
          yAnchor={30}
          markerZIndex={props.elevationMarkerZIndex}
          labelZIndex={props.elevationLabelZIndex}
        />
      )}
    </>
  );
}
