/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Button as MuiButton, Snackbar } from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowLeft,
  ArrowRight,
  Delete,
  ShoppingCart,
} from "@material-ui/icons";
import MuiBox from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ArrowProps } from "react-multi-carousel";
import { useDispatch, useSelector } from "react-redux";
import Image from "../../components/Atomic/BasicComponents/Image";
import Text from "../../components/Atomic/BasicComponents/Text";
import Footer from "../../components/Atomic/Footer";
import Section from "../../components/Atomic/Section";
import Banner from "../../components/Atomic/Tools/Banner";
import SectionCompatibleDrones from "../../components/Atomic/Tools/SectionCompatibleDrones";
import SectionProduct from "../../components/Atomic/Tools/SectionProduct";
import { SystemState } from "../../store/reducers/systemReducer";
import {
  Block,
  ButtonLeftArrow,
  ButtonRightArrow,
  CarouselEquipments,
  ProductPage,
} from "./styles";

/* Images */
import iconIP67 from "../../assets/icon/icon_IP67.png";
import iconAppBioMAPS from "../../assets/icon/icon_app_bioMAPS.png";
import iconAutonomy from "../../assets/icon/icon_autonomy.png";

import imageBackground from "../../assets/background/principal.jpg";
import imageBiohub from "../../assets/gallery/bioHub_with_logo.png";
import imageBioHub from "../../assets/gallery/biohub.jpg";
import imageCropAvocado from "../../assets/gallery/cultivation_avocado.jpg";
import imageCropBanana from "../../assets/gallery/cultivation_banana.jpg";
import imageCropBeans from "../../assets/gallery/cultivation_beans.jpg";
import imageCropCoffee from "../../assets/gallery/cultivation_coffee.jpg";
import imageCropCorn from "../../assets/gallery/cultivation_corn.jpg";
import imageCropOilPalm from "../../assets/gallery/cultivation_oil_palm.jpg";
import imageCropSoy from "../../assets/gallery/cultivation_soy.jpg";
import imageCropSugarCane from "../../assets/gallery/cultivation_sugar_cane.jpg";
import imageStrawberry from "../../assets/gallery/cultivation_strawberry.jpg";
import imageTulip from "../../assets/gallery/cultivation_tulip.jpg";
import imageMelon from "../../assets/gallery/cultivation_melon.jpg";
import imagePapaya from "../../assets/gallery/cultivation_papaya.jpeg";
import {
  default as imageBiobot,
  default as imageDrone,
} from "../../assets/gallery/product_hardware_bioBOT.png";

import { useHistory, useParams } from "react-router";
import imageAmblyseius from "../../assets/icon/amblyseius.png";
import imageCotesia from "../../assets/icon/cotesia.png";
import imageCrisopideos from "../../assets/icon/crisopideos.png";
import imageTelenomus from "../../assets/icon/telenomus.png";
import imageTrichogramma from "../../assets/icon/trichogramma.png";
//import Cart from "../../components/Atomic/Cart";
import { Alert, Stack } from "@mui/material";
import styled from "styled-components";
import CartCard from "../../components/Atomic/CartCard";
import {
  biobotModelId,
  biocoteModelId,
  biomiteModelId,
} from "../../core/helper/ReleaserModelHelper";
import ProductOrderContainer from "./components/ProductOrderContainer";
import { addCartItem, clearCart, removeCartItem } from "../../store/actions/provisoryCartActions";
import {
  mandatoryBioHUBSolution,
  mandatoryBioMAPSSolution,
} from "../../services/provisoryCartSolutionsService";

interface Props {
  image?: string | "url";
}

const StyledButton = styled.button`
  background: #ef7622;
  padding: 0.75rem 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RemoveButton = styled.button`
  padding: 0.75rem 1rem;
  border-radius: 10px;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default (props: Props) => {
  const dispatch = useDispatch();

  const params = useParams<{ name: string }>();

  const solutionsNames = useSelector((state: SystemState) =>
    state.provisoryCart.availableSolutions.map((solution) => solution.name)
  );
  const availableSolutions = useSelector(
    (state: SystemState) => state.provisoryCart.availableSolutions
  );
  const cartSolutions = useSelector((state: SystemState) => state.provisoryCart.cartSolutions);

  const bioHUBAndBioMAPSInCart = useSelector((state: SystemState) => {
    const cartItems = state.provisoryCart.cartSolutions;
    return (
      cartItems.find((item) => item.name === mandatoryBioHUBSolution.name) !== undefined &&
      cartItems.find((item) => item.name === mandatoryBioMAPSSolution.name) !== undefined
    );
  });

  const cartItemsQuantity = useSelector((state: SystemState) => {
    const provisoryCartState = state.provisoryCart;

    return provisoryCartState.cartSolutions.length;
  });

  const cartTotalItemsQuantity = useSelector((state: SystemState) => {
    const provisoryCartState = state.provisoryCart;

    let cartItemsQuantity =
      provisoryCartState.cartSolutions.length === 0
        ? 0
        : provisoryCartState.cartSolutions.map((item) => item.amount).reduce((a, b) => a + b);

    return cartItemsQuantity;
  });

  const [previousCartQuantity, setPreviousCartQuantity] = useState<number>(cartSolutions.length);
  const [previousCartTotalItemsQuantity, setPreviousCartTotalItemsQuantity] = useState<number>(
    cartSolutions.length === 0
      ? 0
      : cartSolutions.map((solution) => solution.amount).reduce((a, b) => a + b)
  );
  const [addedCartItem, setAddedCartItem] = useState<boolean>(false);
  const [updatedCartItem, setUpdatedCartItem] = useState<boolean>(false);
  const [removedCartItem, setRemovedCartItem] = useState<boolean>(false);

  useEffect(() => {
    if (cartItemsQuantity > previousCartQuantity) {
      setAddedCartItem(true);
    } else if (cartItemsQuantity < previousCartQuantity) {
      setRemovedCartItem(true);
    } else {
      if (cartTotalItemsQuantity != previousCartTotalItemsQuantity) {
        setUpdatedCartItem(true);
      }
    }

    setPreviousCartQuantity(cartItemsQuantity);
    setPreviousCartTotalItemsQuantity(cartTotalItemsQuantity);
  }, [cartTotalItemsQuantity]);

  let solutionName: string | undefined;

  if (!solutionsNames.includes(params.name)) {
    useHistory().push("/solutions");
  } else {
    solutionName = params.name;
  }

  const releaserSolutionId: string | undefined = (() => {
    if (solutionName === "bioBOT") {
      return biobotModelId;
    } else if (solutionName === "bioMITE") {
      return biomiteModelId;
    } else if (solutionName === "bioCOTE") {
      return biocoteModelId;
    }

    return undefined;
  })();
  const compatibleInputs = useSelector((state: SystemState) =>
    state.collections.inputs.filter((input) => input.releaserModelId === releaserSolutionId)
  );

  const intl = useIntl();
  // banner information
  const pageTitle = intl.formatMessage({ id: "page.title" });
  const pageDescription = intl.formatMessage({ id: "page.description" });

  // product information
  const productTitle = intl.formatMessage({ id: "section.product." + params.name + ".title" });
  const productSubtitle = intl.formatMessage({
    id: "section.product." + params.name + ".subtitle",
  });
  const productDescription = intl.formatMessage({
    id: "section.product." + params.name + ".description",
  });
  const productAutonomy = intl.formatMessage({ id: "prod.bioBotAutonomy" });
  const productWeatherProof = intl.formatMessage({ id: "prod.bioBotWeatherProof" });
  const productWorksBioMaps = intl.formatMessage({ id: "prod.bioBotWorksBioMaps" });

  const productHighlight = intl.formatMessage({ id: "section.product.highlight" });
  const productCompatibleDroneTitle = intl.formatMessage({
    id: "section.product.compatible.drone.title",
  });
  const productBenefitedCropsTitle = intl.formatMessage({
    id: "section.product.benefited.crops.title",
  });
  const productBiologicalTitle = intl.formatMessage({ id: "section.product.biological.title" });
  const howItWorksTitle = intl.formatMessage({
    id: "section.product." + params.name + ".how_it_works.title",
  });

  // Responsive of Carousel Equipments
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const allInputList = [
    {
      id: "CROPS01",
      title: intl.formatMessage({ id: "prod.cultivationSugarCane" }),
      image: imageCropSugarCane,
      compatibleReleasersIds: [biobotModelId, biocoteModelId],
    },
    {
      id: "CROPS02",
      title: intl.formatMessage({ id: "prod.cultivationBanana" }),
      image: imageCropBanana,
      compatibleReleasersIds: [],
    },
    {
      id: "CROPS03",
      title: intl.formatMessage({ id: "prod.cultivationCoffee" }),
      image: imageCropCoffee,
      compatibleReleasersIds: [biobotModelId],
    },
    {
      id: "CROPS04",
      title: intl.formatMessage({ id: "prod.cultivationAvocado" }),
      image: imageCropAvocado,
      compatibleReleasersIds: [biomiteModelId],
    },
    {
      id: "CROPS05",
      title: intl.formatMessage({ id: "prod.cultivationSoy" }),
      image: imageCropSoy,
      compatibleReleasersIds: [biobotModelId],
    },
    {
      id: "CROPS06",
      title: intl.formatMessage({ id: "prod.cultivationOilPalm" }),
      image: imageCropOilPalm,
      compatibleReleasersIds: [],
    },
    {
      id: "CROPS07",
      title: intl.formatMessage({ id: "prod.cultivationBeans" }),
      image: imageCropBeans,
      compatibleReleasersIds: [],
    },
    {
      id: "CROPS08",
      title: intl.formatMessage({ id: "prod.cultivationCorn" }),
      image: imageCropCorn,
      compatibleReleasersIds: [biobotModelId],
    },
    {
      id: "CROPS09",
      title: intl.formatMessage({ id: "prod.cultivationStrawberry" }),
      image: imageStrawberry,
      compatibleReleasersIds: [biobotModelId, biomiteModelId],
    },
    {
      id: "CROPS10",
      title: intl.formatMessage({ id: "prod.cultivationTulip" }),
      image: imageTulip,
      compatibleReleasersIds: [biomiteModelId],
    },
    {
      id: "CROPS11",
      title: intl.formatMessage({ id: "prod.cultivationMelon" }),
      image: imageMelon,
      compatibleReleasersIds: [biomiteModelId],
    },
    {
      id: "CROPS12",
      title: intl.formatMessage({ id: "prod.cultivationPapaya" }),
      image: imagePapaya,
      compatibleReleasersIds: [biomiteModelId],
    },
  ];

  const benefitedCropsItems =
    releaserSolutionId === undefined
      ? allInputList
      : allInputList.filter((input) => input.compatibleReleasersIds.includes(releaserSolutionId));

  const droneItems = useSelector((state: SystemState) => {
    const droneModelStateList = state.collections.droneModels;

    let droneModelList = droneModelStateList;
    if (releaserSolutionId !== undefined) {
      droneModelList = droneModelStateList.filter((droneModel) =>
        droneModel.compatibleReleaserModelIds.includes(releaserSolutionId)
      );
    }

    return droneModelList.map((droneModel) => ({
      id: droneModel.id,
      title: droneModel.modelName,
      description: "",
      price: "",
      image: droneModel.imagePath,
    }));
  });

  const mediaEquipment = {
    title: howItWorksTitle,
    album: [
      {
        id: "MDA01",
        file: imageBioHub,
      },
      {
        id: "MDA02",
        file: imageBiobot,
      },
    ],
  };

  const extraDetails = [
    {
      id: "EXT01",
      detail: productWorksBioMaps,
      image: iconAppBioMAPS,
    },
    {
      id: "EXT02",
      detail: productWeatherProof,
      image: iconIP67,
    },
    {
      id: "EXT03",
      detail: productAutonomy,
      image: iconAutonomy,
    },
  ];
  const [extraDetailItems, setExtraDetailItems] = React.useState(extraDetails);
  const [isScrollEnding, setIsScrollEnding] = useState(false);

  useEffect(() => {
    const offset = window.innerHeight / 2;
    const handleScroll = () => {
      if (window.scrollY + window.innerHeight >= document.body.offsetHeight - offset) {
        setIsScrollEnding(true);
      } else {
        setIsScrollEnding(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const CustomRightArrow = ({ onClick, ...rest }: ArrowProps) => {
    return (
      <ButtonRightArrow onClick={() => onClick && onClick()}>
        <ArrowRight />
      </ButtonRightArrow>
    );
  };

  const CustomLeftArrow = ({ onClick, ...rest }: ArrowProps) => {
    return (
      <ButtonLeftArrow onClick={() => onClick && onClick()}>
        <ArrowLeft />
      </ButtonLeftArrow>
    );
  };

  const AddedToCartAlert = () => (
    <Alert severity="success" sx={{ width: "100%" }}>
      {intl.formatMessage({ id: "action.cart.add" })}
    </Alert>
  );
  const RemovedFromCartAlert = () => (
    <Alert severity="warning" sx={{ width: "100%" }}>
      {intl.formatMessage({ id: "action.cart.remove" })}
    </Alert>
  );
  const QuantityAlertAlert = () => (
    <Alert severity="info" sx={{ width: "100%" }}>
      {intl.formatMessage({ id: "action.cart.changedQuantity" })}
    </Alert>
  );

  return (
    <>
      <ProductPage>
        <Banner
          image={imageBackground}
          title={pageTitle}
          description={pageDescription}
          id="about"
        />
        <Grid container>
          <Grid item xs={12} md={7}>
            <SectionProduct
              id="product"
              mark
              image={imageBiohub}
              title={productTitle}
              subtitle={productSubtitle}
              description={productDescription}
              highlight={productHighlight}
              extraDetails={extraDetailItems}
            />

            <SectionCompatibleDrones
              sectionColor={"primary"}
              title={productCompatibleDroneTitle}
              items={droneItems}
            />

            <Section sectionTitle={productBenefitedCropsTitle} textAlign="center">
              <Grid container spacing={3} justify={"center"}>
                {benefitedCropsItems.map(function (item: any) {
                  return (
                    <Block key={item.id} className="test">
                      <Image alt={item.title} appearance={"img-product-card"} src={item.image} />
                      <Text
                        text={item.title}
                        appearance={"title-semibold"}
                        size={"small"}
                        color={"primary"}
                      />
                    </Block>
                  );
                })}
              </Grid>
            </Section>

            <Section
              sectionColor={"primary"}
              sectionTitle={productBiologicalTitle}
              textAlign="center"
            >
              <Grid container spacing={3} justify={"center"}>
                {compatibleInputs.map((input) => (
                  <Grid item lg={4}>
                    <li>{input.name}</li>
                  </Grid>
                ))}
              </Grid>
            </Section>

            <Section>
              <Grid container spacing={3} justify={"center"}>
                <Text
                  text={mediaEquipment.title}
                  appearance={"title-semibold"}
                  textAlign={"center"}
                  sectionTitleMark
                />
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CarouselEquipments
                    responsive={responsive}
                    showDots
                    customRightArrow={<CustomRightArrow onClick={() => {}} />}
                    customLeftArrow={<CustomLeftArrow onClick={() => {}} />}
                  >
                    {mediaEquipment.album.map(function (item: any) {
                      return <Image src={item.file} />;
                    })}
                  </CarouselEquipments>
                </Grid>
              </Grid>
            </Section>
          </Grid>

          <Grid item xs={12} md={5}>
            {/* <Cart isScrollEnding={isScrollEnding} variants={[]} /> */}
            <ProductOrderContainer
              actualSolutionId={solutionName ?? ""}
              availableItems={availableSolutions}
              cartItems={cartSolutions}
              bioHUBAndBioMAPSInCart={bioHUBAndBioMAPSInCart}
              onAddItem={(quantity) => {
                if (solutionName === undefined) return;

                dispatch(addCartItem(solutionName, quantity));
              }}
              onRemoveItem={(quantity) => {
                if (solutionName === undefined) return;

                dispatch(removeCartItem(solutionName, quantity));
              }}
              clearCart={() => {
                dispatch(clearCart());
              }}
            />
          </Grid>
        </Grid>
        <Footer image={true} description={intl.formatMessage({ id: "info.about" })} />
      </ProductPage>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={addedCartItem}
        onClose={() => {
          if (addedCartItem) {
            setAddedCartItem(false);
          }
        }}
        autoHideDuration={1000}
      >
        <AddedToCartAlert />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={removedCartItem}
        onClose={() => {
          if (removedCartItem) {
            setRemovedCartItem(false);
          }
        }}
        autoHideDuration={1000}
      >
        <RemovedFromCartAlert />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={updatedCartItem}
        onClose={() => {
          if (updatedCartItem) {
            setUpdatedCartItem(false);
          }
        }}
        autoHideDuration={1000}
      >
        <QuantityAlertAlert />
      </Snackbar>
    </>
  );
};
