import { CpuLog } from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  BiohubUnit,
  biohubUnit,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";

// /**
//  * This function sends a POST request to create a new CPU log record.
//  * @returns a Promise that, when resolved, contains a BiohubResponse object with the operation result.
//  */
// async function createCpuLog(): Promise<BiohubResponse<any>> {
//   try {
//     const result = await BiohubApi.post("/cpu-log");
//     const cpuLog = result.data.summary as any;
//     return newBiohubSuccess(cpuLog);
//   } catch (e) {
//     return extractBiohubErrorResponse(e);
//   }
// }

/**
 * This function sends a GET request to retrieve CPU log records based on the provided
 * directClientId and optionally, the read mode (readMode).
 * @param directClientId the ID of the direct client for which CPU log records are associated
 * @param readMode the mode for reading CPU log records. The default is "not_flights," but it can be
 * changed as needed
 * @returns Returns a Promise that, when resolved, contains a BiohubResponse object with CPU log
 * records corresponding to the provided directClientId.
 */
async function readCpuLog(
  directClientId: string,
  readMode: string = "not_flights"
): Promise<BiohubResponse<CpuLog>> {
  try {
    const result = await BiohubApi.get(
      `/cpu-log/?directClientId=${directClientId}&readMode=${readMode}`
    );
    const cpuLog = result.data as CpuLog;
    return newBiohubSuccess(cpuLog);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/**
 *
 * @param cpuLogId the ID of the CPU log record to be updated
 * @param droneSerialNumber the serial number of the drone associated with the CPU log
 * @param areaId the ID of the area associated with the CPU log record
 * @param projectId the ID of the project associated with the CPU log record.
 * @returns a Promise that, when resolved, contains a BiohubResponse object with the result of the
 * update operation
 */
async function updateCpuLog(
  cpuLogId: string,
  droneSerialNumber: string,
  areaId: string,
  projectId: string
): Promise<BiohubResponse<BiohubUnit>> {
  try {
    const requestBody = {
      droneSerialNumber: droneSerialNumber,
      projectId: projectId,
      areaId: areaId,
    };

    await BiohubApi.put(`/cpu-log/${cpuLogId}`, requestBody);

    return newBiohubSuccess(biohubUnit);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default {
  readCpuLog,
  updateCpuLog,
};
