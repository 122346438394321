import { DocumentType } from "biohub-model";
import { isUndefined, isEmpty, isNull } from "lodash";
import { isValidPhoneNumber } from "react-phone-number-input";
import countries, { CountryNumber } from "../localization/numbersCountry";

export type CountryResponse = CountryNumber & {
  phoneNumber: string;
};

export interface ErrorsForm {
  landlineNumber?: boolean;
  cellphoneNumber?: boolean;
  document?: boolean;
  rg?: boolean;
  country?: boolean;
  state?: boolean;
  name?: boolean;
  email?: boolean;
  city?: boolean;
  street?: boolean;
  number?: boolean;
  zipCode?: boolean;
  currencyCode?: boolean;
  price?: boolean;
}

function isValidFormField(key: string, value: string) {
  switch (key) {
    case "email":
      return isValidEmail(value);

    case "cellphoneNumber":
      return isValidPhone(value);

    case "landlineNumber":
      return isValidPhone(value);

    default:
      return isNotPresentValue(value);
  }
}

function isNotPresentValue(value: string) {
  value = value.trim();
  if (isEmpty(value) || isNull(value) || isUndefined(value)) {
    return true;
  }
  return false;
}

function isValidPhone(phone: string) {
  if (isValidPhoneNumber(phone)) {
    return false;
  }
  return true;
}

function isValidEmail(email: string) {
  const regEx =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (!regEx.test(email)) {
    return true;
  }
  return false;
}

function isValidNumber(number: string) {
  return isNaN(Number(number.replace(",", ".")));
}

function isValidPassword(inputPassword: string): boolean {
  let validPassword = true;
  if (inputPassword.length < 6) {
    validPassword = false;
  }
  if (!/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(inputPassword)) {
    validPassword = false;
  }
  if (inputPassword.toLowerCase() === inputPassword) {
    validPassword = false;
  }

  return !validPassword;
}

function getNumberCountry(phone?: string): CountryResponse {
  let browserLang = window.navigator.language.toUpperCase();

  let countryCurrent: CountryResponse = {
    countryCode: "BR",
    countryName: "Brazil",
    code: "55",
    phoneNumber: "",
  };

  if (phone && phone.startsWith("+")) {
    for (const country of countries) {
      if (phone.includes(`+${country.code}`)) {
        phone = phone.replace(`+${country.code}`, "");

        countryCurrent = {
          ...country,
          phoneNumber: phone,
        };
        break;
      }
    }
  } else if (phone) {
    for (const country of countries) {
      if (browserLang.length > 2) {
        browserLang = browserLang.split("-")[1];
      }
      if (browserLang.includes(country.countryCode)) {
        countryCurrent = {
          ...country,
          phoneNumber: phone,
        };
      }
    }
  } else {
    for (const country of countries) {
      if (browserLang.length > 2) {
        browserLang = browserLang.split("-")[1];
      }
      if (browserLang.includes(country.countryCode)) {
        countryCurrent = {
          ...country,
          phoneNumber: "",
        };
      }
    }
  }

  return countryCurrent;
}

function isValidCPF(cpf: any) {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split("").map((el: any) => +el);
  const rest = (count: any) =>
    ((cpf
      .slice(0, count - 12)
      .reduce((soma: any, el: any, index: any) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;
  return rest(10) === cpf[9] && rest(11) === cpf[10];
}

function isValidCNPJ(value: any) {
  let cnpj = value.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  ) {
    return false;
  }

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

function formatPrice(locale: string, value: number) {
  switch (locale) {
    case "pt-BR":
      return new Intl.NumberFormat(locale, {
        currency: "BRL",
        style: "currency",
      }).format(value);
    case "es":
      return new Intl.NumberFormat(locale, {
        currency: "EUR",
        style: "currency",
      }).format(value);
    default:
      return new Intl.NumberFormat(locale, {
        currency: "USD",
        style: "currency",
      }).format(value);
  }
}

export {
  isValidPhone,
  getNumberCountry,
  isValidEmail,
  isValidFormField,
  isNotPresentValue,
  isValidNumber,
  isValidPassword,
  isValidCPF,
  isValidCNPJ,
  formatPrice,
};
