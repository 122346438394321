import { Location } from "biohub-model";
import React from "react";
import EditingPolygonBordersMarkers from "./markers/EditingPolygonBordersMarkers";
import PolygonForArea from "./polygons/PolygonForArea";
import EditingPolygonPolyline from "./polylines/EditingPolygonPolyline";

type Props = {
  editingPolygon: Location[];
  draggableVertices: boolean;
  onVertexMoved?: (index: number, newLocation: Location) => void;
  onClickPolygon?: (location: Location) => void;
  polygonZIndex: number;
  borderMarkersZIndex: number;
  polylineZIndex: number;
};

export default function (props: Props): JSX.Element {
  return (
    <>
      <EditingPolygonBordersMarkers
        editingPolygon={props.editingPolygon}
        draggableMarkers={props.draggableVertices}
        onVertexMoved={props.onVertexMoved}
        zIndex={props.borderMarkersZIndex}
      />
      <EditingPolygonPolyline
        editingPolygon={props.editingPolygon}
        draggableVertices={props.draggableVertices}
        onVertexMoved={props.onVertexMoved}
        zIndex={props.polylineZIndex}
      />
      <PolygonForArea
        polygonId="editing-area"
        isSelected={true}
        onPolygonClicked={props.onClickPolygon}
        points={props.editingPolygon}
        showBorders={false}
        zIndex={props.polygonZIndex}
      />
    </>
  );
}
