import styled from "styled-components";

type Props = {};

export const SettingsPage = styled.section.attrs((props: Props) => ({
  className: "settings",
}))`
  background: transparent;
  width: 100%;
  text-align: left;
  position: relative;
  padding-left: 10px;
  min-height: 380px;

  .MuiDialogTitle-root {
    background: var(--dashboard-background);
    text-transform: uppercase;

    .material-icons {
      position: absolute;
      right: 10px;
      color: var(--dashboard-primary-bg);
      font-size: 34px;
      line-height: 0.8;
    }
  }

  b {
    display: block;
    margin-top: 15px;
  }

  .select-item {
    margin-top: 10px;
  }

  .react-date-picker {
    width: 100%;

    .react-date-picker__wrapper {
      margin-top: 10px;
      border-radius: 25px;
      padding: 4px 15px 7px;
      align-items: center;
      border-color: var(--dashboard-box-shadow);

      button {
        margin-top: 2px;
      }

      :hover,
      :focus {
        border-color: var(--dashboard-primary-bg);
      }
    }
  }

  .MuiTextField-root fieldset,
  .MuiInputBase-root fieldset {
    border-radius: 50px;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px;
    background: transparent;
  }

  .MuiDialogContent-root {
    padding: 20px;
  }

  .MuiDialogActions-root {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px;
    button {
      border-radius: 50px;
      padding-bottom: 10px;
      min-width: 120px;
      font-family: "GalanoGrotesque-SemiBold";
      font-weight: 600;
      &.cancel-button {
        background: transparent;
        color: var(--dashboard-secondary);
        padding: 0;
        justify-content: flex-start;
        align-items: baseline;
        .MuiButton-startIcon {
          align-self: normal;
          margin: 0;
          margin-top: 5px;
        }
      }
      &.MuiButton-containedPrimary {
        background-color: var(--dashboard-secondary);
        color: var(--white);
      }
    }
  }
`;

export const Action = styled.span.attrs((props: Props) => ({}))`
  display: table;
  position: absolute;
  top: calc(100% + 45px);
  right: 0;

  .MuiButton-containedPrimary {
    background: var(--dashboard-secondary);
    color: white;
    padding-bottom: 10px;
    border-radius: 25px;
    min-width: 120px;
    :hover,
    :focus {
      color: white;
      background: var(--dashboard-primary-bg);
    }
  }

  button {
    color: var(--dashboard-secondary);
    .material-icons {
      margin-right: 5px;
    }
    :hover,
    :focus {
      background: transparent;
      color: var(--dashboard-primary-bg);
    }
  }
`;

export const Toggle = styled.span.attrs((props: Props) => ({}))`
  display: block;
  padding: 12px;
  .MuiSwitch-root {
    margin-top: -6px;
  }
`;

export const Adjust = styled.div.attrs((props: Props) => ({}))`
  min-height: 380px;
`;
