import styled from "styled-components";

type Props = {};

export const Block = styled.div.attrs((props: Props) => ({}))`
  background: transparent;
  padding: 10px;
  position: relative;
  display: flex;
  display: inline-flex;
  width: 30%;
  margin: 1rem;

  img {
    width: 140px;
    object-fit: cover;
    z-index: 1;
    box-shadow: 0 0 2px var(--box-shadow);
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
    margin: 1rem;
  }

  @media screen and (max-width: 991px) {
    width: 70%;
    > span {
      padding: 15px;
      height: auto;
      margin-left: 0;
      display: table;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 1rem 0 2rem;
  }
`;

export const Description = styled.span.attrs((props: Props) => ({}))`
  background: var(--white);
  padding: 10px;
  position: relative;
  box-shadow: 0 3px 15px var(--box-shadow);
  line-height: 1.2;
  min-height: 170px;
  margin-left: 0;
  width: 100%;
  text-align: left;

  hr {
    margin: 1rem 0;
    border: 0;
    width: 100%;
    height: 2px;
    background: var(--primary);
  }

  @media screen and (max-width: 991px) {
    padding: 5rem 10px 10px;
    height: auto;
    margin-left: 0;
    display: table;
    margin-top: 5rem;
  }

  @media screen and (max-width: 680px) {
    width: 94%;
  }
`;

export const Infos = styled.span.attrs((props: Props) => ({}))`
  margin-top: 1rem;
  display: block;
  position: absolute;
  bottom: 20px;

  @media screen and (max-width: 991px) {
    position: relative;
    bottom: initial;
  }
`;
