import React from "react";
import { useIntl } from "react-intl";
import Container from "../../Areas/Container";
import Section from "../../Section";
import PeopleCard from "../../BasicComponents/TeamCard";
import SimpleCard from "../../BasicComponents/SimpleCard";
import { Center } from "./styles";
import Text from "../../BasicComponents/Text";

interface Props {
  items: Array<{
    id: string;
    name: string;
    role: any;
    description: string;
    image: string | "url";
  }>;
}

export default (props: Props) => {
  const intl = useIntl();

  return (
    <>
      <Section sectionColor={"primary"}>
        <Container>
          <Text
            text={intl.formatMessage({ id: "info.aboutTeamTitle" })}
            textAlign={"center"}
            appearance={"title-semibold"}
            size={"medium"}
            sectionTitleMark
          />
          <Center>
            {props.items.map((item) =>
              item.role.id === "01" || item.role.id === "02" ? (
                <PeopleCard
                  key={item.id}
                  title={item.name}
                  subtitle={item.role.title}
                  description={item.description}
                  image={item.image}
                />
              ) : (
                <SimpleCard
                  key={item.id}
                  title={item.name}
                  subtitle={item.role.title}
                  description={item.description}
                  image={item.image}
                />
              )
            )}
          </Center>
        </Container>
      </Section>
    </>
  );
};
