import React from "react";
import { Container, Content } from "./styles";

interface Props {
  children?: React.ReactNode;
}

export default (props: Props) => {
  return (
    <Container maxWidth="lg">
      <Content>{props.children}</Content>
    </Container>
  );
};
