import { Grid, MenuItem } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import Container from "../../Areas/Container";
import Row from "../../Areas/Row";
import Image from "../../BasicComponents/Image";
import Carousel from "../../BasicComponents/ProductsCarousel";
import Text from "../../BasicComponents/Text";
import Section from "../../Section";
import { SelectFilterOptions } from "./styles";
interface Props {
  title: string;
  subtitle: string;
  items: Array<{
    id: string;
    product_id?: string;
    title: string;
    description: string;
    price: string;
    image: string | "url";
  }>;
  showButtons?: boolean | true;
  optionsDrone: Array<{
    id: string;
    title: string;
    value: string;
  }>;
  optionsCultivation: Array<{
    id: string;
    title: string;
    value: string;
  }>;
  optionsBiological: Array<{
    id: string;
    title: string;
    value: string;
  }>;
  changeDrone: Function;
  droneSelected: string;
  changeCultivation: Function;
  cultivationSelected: string;
  changeBiological: Function;
  biologicalSelected: string;
  category?: string;
  //subDescription?: string;
  subDescriptionCultivation?: string;
  subDescriptionDrone?: string;
  subDescriptionBiological?: string;
  descriptionRequired?: string;
  image?: string;
}

export default (props: Props) => {
  const intl = useIntl();
  return (
    <>
      <Section
        sectionTitle={props.title}
        sectionColor={"primary"}
        id="prices"
        sectionTitleMark
        textAlign={"center"}
        category={props.category}
        appearanceText="subtitle-medium"
      >
        <Container>
          <Text text={props.subtitle} appearance={"carousel-description"} color={"light"} />
          <Row alignItems={"center"} justify={"center"}>
            <Text
              text={props.subDescriptionDrone}
              color={"light"}
              appearance={"cultivation-text"}
              textAlign={"center"}
            />
            <SelectFilterOptions
              key={"company-country"}
              onChange={(e) => props.changeDrone(String(e.target.value))}
              value={props.droneSelected}
            >
              {props.optionsDrone.map((item) => (
                <MenuItem value={item.value}>{item.title}</MenuItem>
              ))}
            </SelectFilterOptions>
          </Row>
          <Row alignItems={"center"} justify={"center"}>
            <Text
              text={props.subDescriptionCultivation}
              color={"light"}
              appearance={"cultivation-text"}
              textAlign={"center"}
            />
            <SelectFilterOptions
              key={"company-country"}
              onChange={(e) => props.changeCultivation(String(e.target.value))}
              value={props.cultivationSelected}
            >
              {props.optionsCultivation.map((item) => (
                <MenuItem value={item.value}>{item.title}</MenuItem>
              ))}
            </SelectFilterOptions>
          </Row>
          <Row alignItems={"center"} justify={"center"}>
            <Text
              text={props.subDescriptionBiological}
              color={"light"}
              appearance={"cultivation-text"}
              textAlign={"center"}
            />
            <SelectFilterOptions
              key={"company-country"}
              onChange={(e) => props.changeBiological(String(e.target.value))}
              value={props.biologicalSelected}
            >
              {props.optionsBiological.map((item) => (
                <MenuItem value={item.value}>{item.title}</MenuItem>
              ))}
            </SelectFilterOptions>
          </Row>
          <Carousel items={props.items} color={"primary"} showButtons={true} flewFlow="column" />
          <Text
            text={props.descriptionRequired}
            color={"third"}
            appearance={"info-required"}
            textAlign={"center"}
          />
          {props.image && (
            <Grid container justify={"center"} alignItems={"center"}>
              <Image src={props.image} alt={""} appearance={"img-logos-together"} />
            </Grid>
          )}
        </Container>
      </Section>
    </>
  );
};
