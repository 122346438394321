import { BoundingBox, Location } from "biohub-model";
import BaseMapController from "../BaseMapController";

const zoomInOutBaseStep = 0.5;

class GoogleMapController implements BaseMapController {
  constructor(mapRef: google.maps.Map) {
    this._mapRef = mapRef;
  }

  _mapRef: google.maps.Map;

  async moveTo(coordinate: Location, zoomLevel?: number): Promise<void> {
    this._mapRef.panTo?.(coordinate);
    if (zoomLevel !== undefined) {
      this._mapRef.setZoom(zoomLevel);
    }
  }

  async moveToBoundingBox(box: BoundingBox): Promise<void> {
    return this._mapRef.fitBounds?.(
      new google.maps.LatLngBounds(
        // Southwest:
        new google.maps.LatLng(box.south, box.west),
        // Northeast:
        new google.maps.LatLng(box.north, box.east)
      )
    );
  }

  async zoomIn(steps?: number): Promise<void> {
    const _steps: number = steps === undefined ? zoomInOutBaseStep : steps;
    const _newZoomLevel = (this._mapRef.getZoom() || 14) + _steps;
    return this._mapRef.setZoom?.(_newZoomLevel);
  }

  async zoomOut(steps?: number): Promise<void> {
    const _steps: number = steps === undefined ? zoomInOutBaseStep : steps;
    const _newZoomLevel = (this._mapRef.getZoom() || 14) - _steps;
    return this._mapRef.setZoom?.(_newZoomLevel);
  }

  async getCenter(): Promise<Location | undefined> {
    const center = this._mapRef.getCenter() as google.maps.LatLng | undefined;
    if (center === undefined) return undefined;
    return { lat: center.lat(), lng: center.lng() };
  }

  async getMapBounds(): Promise<BoundingBox | undefined> {
    const bounds = this._mapRef.getBounds();
    const southWestBounds = bounds?.getSouthWest();
    const northEastBounds = bounds?.getNorthEast();
    if (southWestBounds === undefined || northEastBounds === undefined) {
      return undefined;
    }

    return {
      north: northEastBounds.lat(),
      south: southWestBounds.lat(),
      east: northEastBounds.lng(),
      west: southWestBounds.lng(),
    };
  }

  async getZoom(): Promise<number | undefined> {
    return this._mapRef.getZoom();
  }
}

export default GoogleMapController;
