import React from "react";
import { useIntl } from "react-intl";
import Container from "../../../components/Atomic/Areas/Container";
import Row from "../../../components/Atomic/Areas/Row";
import Column from "../../../components/Atomic/Areas/Column";
import Header from "../../../components/Atomic/Header";
import Footer from "../../../components/Atomic/Footer";
import Text from "../../../components/Atomic/BasicComponents/Text";
import Button from "../../../components/Atomic/BasicComponents/Button";
import {
  VerificationPage,
  ContentPage,
  DetailsDescription,
  DetailsData,
  DetailsCode,
} from "../styles";

import {
  IconButton,
  Input,
  InputAdornment,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Create, Done } from "@material-ui/icons";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();
  const [disable, setDisable] = React.useState(true);
  const [confirmation, setConfirmation] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  //terms
  const termTitle = intl.formatMessage({ id: "info.verificationTitle" });
  const termDetails = intl.formatMessage({ id: "info.verificationDescription" });
  const termDataTitle = intl.formatMessage({ id: "info.emailAddress" });
  const termActionSubmit = intl.formatMessage({ id: "action.sendVerification" });
  const termDetailsConfirmation = intl.formatMessage({
    id: "info.verificationCodeDescription",
  });
  const termDataTitleConfirmation = intl.formatMessage({ id: "info.confirmationCode" });
  const termActionSubmitConfirmation = intl.formatMessage({ id: "action.confirm" });
  const termAboutInfo = intl.formatMessage({ id: "info.about" });

  return (
    <>
      <VerificationPage>
        <Header color={"dark"} />
        <Container>
          <ContentPage>
            <Row>
              <Column xs={12}>
                <Text text={termTitle} color={"primary"} size={"large"} />
              </Column>
              <Column xs={12} md={12}>
                <DetailsDescription>
                  <Text
                    text={confirmation ? termDetailsConfirmation : termDetails}
                    color={"primary"}
                  />
                </DetailsDescription>
              </Column>
              <Column xs={12} md={12}>
                {confirmation ? (
                  <DetailsCode>
                    <Text
                      text={termDataTitleConfirmation}
                      color={"primary"}
                      appearance={"info-title"}
                      size={"medium"}
                    />
                    <TextField
                      defaultValue={"X X X X X X"}
                      id="code-verification"
                      variant="outlined"
                    />
                  </DetailsCode>
                ) : (
                  <DetailsData>
                    <Text
                      text={termDataTitle}
                      color={"primary"}
                      appearance={"info-title"}
                      size={"medium"}
                    />
                    <FormControl>
                      <Input
                        id="email-verification"
                        type="text"
                        defaultValue={"d*************o@gmail.com"}
                        onChange={() => {}}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="E-mail"
                              onClick={() => {
                                setDisable(!disable);
                              }}
                            >
                              {disable ? <Create /> : <Done />}
                            </IconButton>
                          </InputAdornment>
                        }
                        disabled={disable}
                      />
                    </FormControl>
                  </DetailsData>
                )}
              </Column>
              <Column xs={12} md={12}>
                <Button
                  color={"highlighted"}
                  size={"large"}
                  position={"right"}
                  action={() => {
                    confirmation ? setLoading(true) : setConfirmation(!confirmation);
                  }}
                >
                  {confirmation ? termActionSubmitConfirmation : termActionSubmit}
                  {loading && <CircularProgress />}
                </Button>
              </Column>
            </Row>
          </ContentPage>
        </Container>
        <Footer image={true} description={termAboutInfo} />
      </VerificationPage>
    </>
  );
};
