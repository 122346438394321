/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import Banner from "../../../components/Atomic/Tools/Banner";
import Footer from "../../../components/Atomic/Footer";
import Text from "../../../components/Atomic/BasicComponents/Text";
import Image from "../../../components/Atomic/BasicComponents/Image";
import { Add, ArrowLeft, ArrowRight } from "@material-ui/icons";
import Section from "../../../components/Atomic/Section";
import SectionTextImage from "../../../components/Atomic/Tools/SectionTextImage";
import HowWorks from "../../../components/Atomic/Tools/SectionHowWorks";
import Prices from "../../../components/Atomic/Tools/SectionPrices";
import Clickable from "../../../components/Atomic/BasicComponents/Link";
import Button from "../../../components/Atomic/BasicComponents/Button";
import { ArrowProps } from "react-multi-carousel";
import { SystemState } from "../../../store/reducers/systemReducer";

import {
  SolutionsPage,
  MenuButtonEquipments,
  CultivationItem,
  CultivationItemOthers,
  CarouselEquipments,
  SpecificationItem,
  ButtonRightArrow,
  ButtonLeftArrow,
} from "../styles";

// Images
import imageBiocote from "../../../assets/gallery/produto_biocote_01.png";
import imageBiobot from "../../../assets/gallery/product_hardware_bioBOT.png";
import bioBot from "../../../assets/gallery/bioBot.gif";
import iconAppBioMAPS from "../../../assets/icon/icon_app_bioMAPS.png";
import iconBioMAPS from "../../../assets/icon/icon_app_bioMAPS_alpha.png";
import iconIP67 from "../../../assets/icon/icon_IP67.png";
import iconAutonomy from "../../../assets/icon/icon_autonomy.png";
import imageBackground from "../../../assets/background/product.png";
import imageSugarCane from "../../../assets/gallery/cultivation_sugar_cane.jpg";
import imageBanana from "../../../assets/gallery/cultivation_banana.jpg";
import imageCoffee from "../../../assets/gallery/cultivation_coffee.jpg";
import imageAvocado from "../../../assets/gallery/cultivation_avocado.jpg";
import imageSoy from "../../../assets/gallery/cultivation_soy.jpg";
import imageOilPalm from "../../../assets/gallery/cultivation_oil_palm.jpg";
import imageBeans from "../../../assets/gallery/cultivation_beans.jpg";
import imageCorn from "../../../assets/gallery/cultivation_corn.jpg";
import imageOthers from "../../../assets/gallery/cultivation_others.png";
import imageBioHub from "../../../assets/gallery/biohub.jpg";
import imageBiohub from "../../../assets/gallery/bioHub_with_logo.png";
import imageBiomaps from "../../../assets/gallery/bioMaps_with_logo.png";
import imagebioMite from "../../../assets/gallery/biomite.jpg";
import logoBioHubWithBioMaps from "../../../assets/gallery/logoBioHub_with_logoBioMaps.png";
import imageSeparatorPlus from "../../../assets/gallery/separator_plus.png";
import imageDiagrammeEs from "../../../assets/gallery/diagramme_work_es.png";
import imageDiagrammeEn from "../../../assets/gallery/diagramme_work_en.png";
import imageDiagrammeFr from "../../../assets/gallery/diagramme_work_fr.png";
import imageDiagrammePtBr from "../../../assets/gallery/diagramme_work_ptBR.png";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();
  const currentLocale = useSelector((state: SystemState) => state.locale.localeCode);

  const [imageDiagramme, setImageDiagramme] = React.useState("");

  const solutionMap = [
    {
      name: "bioBOT",
      drone: ["Phantom4", "Matrix100", "Matrix200"],
      cultivation: ["Sugarcane"],
      biological: ["TrichogrammaExiguum", "TrichogrammaGalloi"],
    },
    {
      name: "bioBOT",
      drone: ["Phantom4", "Matrix100", "Matrix200"],
      cultivation: ["Corn"],
      biological: ["TrichogrammaBrassicae", "TrichogrammaPretiosum"],
    },
    {
      name: "bioBOT",
      drone: ["Phantom4", "Matrix100", "Matrix200"],
      cultivation: ["Coffee", "Strawberry"],
      biological: ["ChrysoperlaExterna"],
    },
    {
      name: "bioBOT",
      drone: ["Phantom4", "Matrix100", "Matrix200"],
      cultivation: ["Soybeans"],
      biological: ["TelenomusPodisi", "TelenomusRemus"],
    },
    {
      name: "bioCOTE",
      drone: ["Matrix200"],
      cultivation: ["Sugarcane"],
      biological: ["CotesiaFlavipes"],
    },
    {
      name: "bioMITE",
      drone: ["Matrix100", "Matrix200"],
      cultivation: ["Tulip", "Avocado", "Strawberry", "Melon", "Papaya"],
      biological: ["AmblyseiusCalifornicus", "PhytoseiulusPersimilis"],
    },
  ];

  const optionsUsageChoose = intl.formatMessage({ id: "action.usageChoose" });

  // Select Choose Drone
  const optionsDronePhantom4 = intl.formatMessage({ id: "drone.usagePhantom4" });
  const optionsDroneMatrix100 = intl.formatMessage({ id: "drone.usageDroneMatrix100" });
  const optionsDroneMatrix200 = intl.formatMessage({ id: "drone.usageDroneMatrix200" });

  // Select Choose Cultivation
  const optionsCultivationStrawberry = intl.formatMessage({ id: "prod.usageStrawberry" });
  const optionsCultivationTulip = intl.formatMessage({ id: "prod.usageTulip" });
  const optionsCultivationCoffee = intl.formatMessage({ id: "prod.usageCoffee" });
  const optionsCultivationSugarCane = intl.formatMessage({ id: "prod.usageSugarcane" });
  const optionsCultivationAvocado = intl.formatMessage({ id: "prod.usageAvocado" });
  const optionsCultivationMelon = intl.formatMessage({ id: "prod.usageMelon" });
  const optionsCultivationPapaya = intl.formatMessage({ id: "prod.usagePapaya" });
  const optionsCultivationCorn = intl.formatMessage({ id: "prod.usageCorn" });
  const optionsCultivationSoybeans = intl.formatMessage({ id: "prod.usageSoybeans" });

  const optionsDrone = [
    {
      id: "usageChoose",
      title: optionsUsageChoose,
      value: "UsageChoose",
    },
    {
      id: "Matrix100",
      title: optionsDroneMatrix100,
      value: "Matrix100",
    },
    {
      id: "Matrix200",
      title: optionsDroneMatrix200,
      value: "Matrix200",
    },
    {
      id: "Phantom4",
      title: optionsDronePhantom4,
      value: "Phantom4",
    },
  ];

  const optionsCultivation = [
    {
      id: "usageChoose",
      title: optionsUsageChoose,
      value: "UsageChoose",
    },
    {
      id: "Avocado",
      title: optionsCultivationAvocado,
      value: "Avocado",
    },
    {
      id: "Coffee",
      title: optionsCultivationCoffee,
      value: "Coffee",
    },
    {
      id: "Sugarcane",
      title: optionsCultivationSugarCane,
      value: "Sugarcane",
    },
    {
      id: "Corn",
      title: optionsCultivationCorn,
      value: "Corn",
    },
    {
      id: "Melon",
      title: optionsCultivationMelon,
      value: "Melon",
    },
    {
      id: "Strawberry",
      title: optionsCultivationStrawberry,
      value: "Strawberry",
    },
    {
      id: "Papaya",
      title: optionsCultivationPapaya,
      value: "Papaya",
    },
    {
      id: "Soybeans",
      title: optionsCultivationSoybeans,
      value: "Soybeans",
    },
    {
      id: "Tulip",
      title: optionsCultivationTulip,
      value: "Tulip",
    },
  ];

  const [optSelectDrone, setOptSelectDrone] = React.useState(optionsDrone[0].value);

  const [optSelectCultivation, setOptSelectCultivation] = React.useState(
    optionsCultivation[0].value
  );

  // Select Choose Biological
  const optionsBioAmblyseiusCalifornicus = intl.formatMessage({
    id: "bio.usageAmblyseiusCalifornicus",
  });
  const optionsBioTrichogrammaExiguum = intl.formatMessage({ id: "bio.usageTrichogrammaExiguum" });
  const optionsBioTrichogrammaGalloi = intl.formatMessage({ id: "bio.usageTrichogrammaGalloi" });
  const optionsBioTrichogrammaBrassicae = intl.formatMessage({
    id: "bio.usageTrichogrammaBrassicae",
  });
  const optionsBioTrichogrammaPretiosum = intl.formatMessage({
    id: "bio.usageTrichogrammaPretiosum",
  });
  const optionsBioChrysoperlaExterna = intl.formatMessage({ id: "bio.usageChrysoperlaExterna" });
  const optionsBioTelenomusPodisi = intl.formatMessage({ id: "bio.usageTelenomusPodisi" });
  const optionsBioTelenomusRemus = intl.formatMessage({ id: "bio.usageTelenomusRemus" });
  const optionsBioPhytoseiulusPersimilis = intl.formatMessage({
    id: "bio.usagePhytoseiulusPersimilis",
  });
  const optionsBioCotesiaFlavipes = intl.formatMessage({ id: "bio.usageCotesiaFlavipes" });

  const optionsBiological = [
    {
      id: "usageChoose",
      title: optionsUsageChoose,
      value: "UsageChoose",
    },
    {
      id: "AmblyseiusCalifornicus",
      title: optionsBioAmblyseiusCalifornicus,
      value: "AmblyseiusCalifornicus",
    },
    {
      id: "ChrysoperlaExterna",
      title: optionsBioChrysoperlaExterna,
      value: "ChrysoperlaExterna",
    },
    {
      id: "CotesiaFlavipes",
      title: optionsBioCotesiaFlavipes,
      value: "CotesiaFlavipes",
    },
    {
      id: "PhytoseiulusPersimilis",
      title: optionsBioPhytoseiulusPersimilis,
      value: "PhytoseiulusPersimilis",
    },
    {
      id: "TelenomusPodisi",
      title: optionsBioTelenomusPodisi,
      value: "TelenomusPodisi",
    },
    {
      id: "TelenomusRemus",
      title: optionsBioTelenomusRemus,
      value: "TelenomusRemus",
    },
    {
      id: "Trichogramma Brassicae",
      title: optionsBioTrichogrammaBrassicae,
      value: "TrichogrammaBrassicae",
    },
    {
      id: "TrichogrammaExiguum",
      title: optionsBioTrichogrammaExiguum,
      value: "TrichogrammaExiguum",
    },
    {
      id: "TrichogrammaGalloi",
      title: optionsBioTrichogrammaGalloi,
      value: "TrichogrammaGalloi",
    },
    {
      id: "TrichogrammaPretiosum",
      title: optionsBioTrichogrammaPretiosum,
      value: "TrichogrammaPretiosum",
    },
  ];

  const [optSelectBiological, setOptSelectBiological] = React.useState(optionsBiological[0].value);
  const [solutions, setSolutions] = React.useState(solutionsFiltered());

  function solutionsFiltered() {
    if (
      optSelectBiological === "UsageChoose" &&
      optSelectCultivation === "UsageChoose" &&
      optSelectDrone === "UsageChoose"
    )
      return solutionMap;

    return filterBiological(
      filterCultivation(filterDrone(solutionMap, optSelectDrone), optSelectCultivation),
      optSelectBiological
    );
  }

  // about information
  const aboutTitle = intl.formatMessage({ id: "section.about.title" });
  const aboutCategory = intl.formatMessage({ id: "section.about.category" });
  const aboutInfoSubtitle = intl.formatMessage({ id: "section.about.info.subtitle" });
  const aboutInfoDescription = intl.formatMessage({ id: "section.about.info.description" });

  // biomaps information
  const biomapsSubtitle = intl.formatMessage({ id: "section.biomaps.subtitle" });
  const biomapsDescription = intl.formatMessage({ id: "section.biomaps.description" });
  const bioMapsRequired = intl.formatMessage({ id: "section.biomaps.required" });

  // How Works information
  const howWorksTitle = intl.formatMessage({ id: "section.how.works.title" });

  // terms
  const bannerTitle = intl.formatMessage({ id: "menu.solutions" });

  // products information
  const productsCategory = intl.formatMessage({ id: "section.products.category" });
  const productsDescription = intl.formatMessage({ id: "section.products.description" });
  const productsSubDescriptionCultivation = intl.formatMessage({
    id: "section.products.subDescriptionCultivation",
  });
  const productsSubDescriptionDrone = intl.formatMessage({
    id: "section.products.subDescriptionDrone",
  });
  const productsSubDescriptionBiological = intl.formatMessage({
    id: "section.products.subDescriptionBiological",
  });
  const productsRequired = intl.formatMessage({ id: "section.products.required" });
  const bioBOTDescription = intl.formatMessage({ id: "section.card.product.description.bioBOT" });
  const bioCOTEDescription = intl.formatMessage({ id: "section.card.product.description.bioCOTE" });
  //Need update according with required description to be provided by NCB.
  const bioMiteDescription = intl.formatMessage({
    id: "section.card.product.description.PHERObio",
  });

  // pricing information
  const pricesList = [
    {
      id: "PRICE01",
      product_id: "bioBOT",
      title: "bioBOT",
      description: bioBOTDescription,
      image: imageBiobot,
      price: "R$ 1000",
      showButtons: true,
      link: "product/bioBOT",
    },
    {
      id: "PRICE02",
      product_id: "bioCOTE",
      title: "bioCOTE",
      description: bioCOTEDescription,
      image: imageBiocote,
      price: "R$ 1000",
      showButtons: true,
      link: "product/bioCOTE",
    },
    {
      id: "PRICE03",
      product_id: "bioMITE",
      title: "bioMITE",
      description: bioMiteDescription,
      image: imagebioMite,
      price: "R$ 1000",
      showButtons: true,
      link: "product/bioMITE",
    },
  ];

  const [productItems, setProductItems] = React.useState(pricesList);

  function priceListFiltered() {
    return pricesList.filter((price: any) =>
      solutions.some((sol: any) => price.title === sol.name)
    );
  }

  // banner information
  const pageTitle = intl.formatMessage({ id: "page.title" });
  const pageDescription = intl.formatMessage({ id: "page.description" });

  const handleChangeDrone = (item: string) => {
    setOptSelectDrone(item);
  };
  const handleChangeBiological = (item: string) => {
    setOptSelectBiological(item);
  };

  const handleChangeCultivation = (item: string) => {
    setOptSelectCultivation(item);
  };

  React.useEffect(() => {
    setSolutions(solutionsFiltered());
    setProductItems(priceListFiltered());
  }, [currentLocale, optSelectCultivation, optSelectBiological, optSelectDrone]);

  // Responsive of Carousel Equipments
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const cultivations = [
    {
      id: "USO01",
      name: intl.formatMessage({ id: "prod.cultivationSugarCane" }),
      image: imageSugarCane,
    },
    {
      id: "USO02",
      name: intl.formatMessage({ id: "prod.cultivationSoy" }),
      image: imageSoy,
    },
    {
      id: "USO03",
      name: intl.formatMessage({ id: "prod.cultivationAvocado" }),
      image: imageAvocado,
    },
    {
      id: "USO04",
      name: intl.formatMessage({ id: "prod.cultivationCorn" }),
      image: imageCorn,
    },
    {
      id: "USO05",
      name: intl.formatMessage({ id: "prod.cultivationBeans" }),
      image: imageBeans,
    },
    {
      id: "USO06",
      name: intl.formatMessage({ id: "prod.cultivationBanana" }),
      image: imageBanana,
    },
    {
      id: "USO07",
      name: intl.formatMessage({ id: "prod.cultivationCoffee" }),
      image: imageCoffee,
    },
    {
      id: "USO08",
      name: intl.formatMessage({ id: "prod.cultivationOilPalm" }),
      image: imageOilPalm,
    },
    {
      id: "USO09",
      name: intl.formatMessage({ id: "prod.cultivationOthers" }),
      image: imageOthers,
    },
  ];

  const specifications = [
    {
      id: "ESP01",
      name: intl.formatMessage({ id: "prod.specificationSubTitle" }),
      description: intl.formatMessage({ id: "prod.specificationInfo" }),
    },
    {
      id: "ESP02",
      name: intl.formatMessage({ id: "prod.specificationSubTitle" }),
      description: intl.formatMessage({ id: "prod.specificationInfo" }),
    },
    {
      id: "ESP03",
      name: intl.formatMessage({ id: "prod.specificationSubTitle" }),
      description: intl.formatMessage({ id: "prod.specificationInfo" }),
    },
  ];

  const detailsEquipment = {
    subTitle: intl.formatMessage({ id: "prod.bioBotSubTitle" }),
    description: intl.formatMessage({ id: "prod.bioBotDescription" }),
    imageEquipment: imageBiobot,
    extraDetails: [
      {
        id: "EXT01",
        detail: intl.formatMessage({ id: "prod.bioBotWorksBioMaps" }),
        image: iconAppBioMAPS,
      },
      {
        id: "EXT02",
        detail: intl.formatMessage({ id: "prod.bioBotWeatherProof" }),
        image: iconIP67,
      },
      {
        id: "EXT03",
        detail: intl.formatMessage({ id: "prod.bioBotAutonomy" }),
        image: iconAutonomy,
      },
    ],
    extraDetails2: [
      {
        id: "EXT01",
        detail: intl.formatMessage({ id: "prod.bioBotWorksBioMaps" }),
        image: iconBioMAPS,
      },
      {
        id: "EXT02",
        detail: intl.formatMessage({ id: "prod.bioBotWeatherProof" }),
        image: iconBioMAPS,
      },
      {
        id: "EXT03",
        detail: intl.formatMessage({ id: "prod.bioBotAutonomy" }),
        image: iconBioMAPS,
      },
    ],
  };

  const mediaEquipment = {
    title: intl.formatMessage({ id: "prod.equipmentTitle" }),
    description: intl.formatMessage({ id: "prod.equipmentDescription" }),
    album: [
      {
        id: "MDA01",
        file: imageBioHub,
      },
      {
        id: "MDA02",
        file: imageBiobot,
      },
      {
        id: "MDA03",
        file: bioBot,
      },
    ],
  };

  const dataEquipmentsStatic = [
    {
      id: "EQUIP01",
      productKey: "bioHUB",
      name: "bioHUB",
      type: "software",
      isActive: false,
      cultivation: cultivations,
      specification: specifications,
      details: detailsEquipment,
      media: mediaEquipment,
      linkTo: "/solutions",
    },
    {
      id: "EQUIP02",
      productKey: "bioMAPS",
      name: "bioMAPS",
      type: "software",
      isActive: false,
      cultivation: cultivations,
      specification: specifications,
      details: detailsEquipment,
      media: mediaEquipment,
      linkTo: "/solutions",
    },
    {
      id: "EQUIP03",
      productKey: "bioBOT",
      name: "bioBOT",
      type: "hardware",
      isActive: true,
      cultivation: cultivations,
      specification: specifications,
      details: detailsEquipment,
      media: mediaEquipment,
      linkTo: "/product/bioBOT",
    },
    {
      id: "EQUIP04",
      productKey: "bioMITE",
      name: "bioMITE",
      type: "hardware",
      isActive: true,
      cultivation: cultivations,
      specification: specifications,
      details: detailsEquipment,
      media: mediaEquipment,
      linkTo: "/product/bioMITE",
    },
    {
      id: "EQUIP05",
      productKey: "bioFUNGUS",
      name: "bioFUNGUS",
      type: "hardware",
      isActive: false,
      cultivation: cultivations,
      specification: specifications,
      details: detailsEquipment,
      media: mediaEquipment,
      linkTo: "/product/bioFUNGUS",
    },
    {
      id: "EQUIP06",
      productKey: "bioCOTE",
      name: "bioCOTE",
      type: "hardware",
      isActive: true,
      cultivation: cultivations,
      specification: specifications,
      details: detailsEquipment,
      media: mediaEquipment,
      linkTo: "/product/bioCOTE",
    },
    {
      id: "EQUIP07",
      productKey: "bioPUPA",
      name: "bioPUPA",
      type: "hardware",
      isActive: false,
      cultivation: cultivations,
      specification: specifications,
      details: detailsEquipment,
      media: mediaEquipment,
      linkTo: "/product/bioPUPA",
    },
    {
      id: "EQUIP08",
      productKey: "PHERObio",
      name: "PHERObio",
      type: "hardware",
      isActive: false,
      cultivation: cultivations,
      specification: specifications,
      details: detailsEquipment,
      media: mediaEquipment,
      linkTo: "/product/PHERObio",
    },
  ];

  // When Change Language, to replace diagrams images
  React.useEffect(() => {
    switch (currentLocale) {
      case "en": {
        setImageDiagramme(imageDiagrammeEn);
        break;
      }
      case "es": {
        setImageDiagramme(imageDiagrammeEs);
        break;
      }
      case "fr": {
        setImageDiagramme(imageDiagrammeFr);
        break;
      }
      case "pt-BR": {
        setImageDiagramme(imageDiagrammePtBr);
        break;
      }
      default:
        setImageDiagramme(imageDiagrammeEn);
        break;
    }
  }, [currentLocale]);

  function filterDrone(map: any[], filter: string) {
    return map.filter((item) => item.drone.indexOf(filter) >= 0);
  }

  function filterCultivation(map: any[], filter: string) {
    return map.filter((item) => item.cultivation.indexOf(filter) >= 0);
  }

  function filterBiological(map: any[], filter: string) {
    return map.filter((item) => item.biological.indexOf(filter) >= 0);
  }

  const [dataEquipments, setDataEquipments] = React.useState(dataEquipmentsStatic);

  const handleChangeProduct = (id: string) => {
    setDataEquipments((state) =>
      state.map((item) =>
        item.id === id ? { ...item, isActive: true } : { ...item, isActive: false }
      )
    );
  };

  const CustomRightArrow = ({ onClick, ...rest }: ArrowProps) => {
    return (
      <ButtonRightArrow onClick={() => onClick && onClick()}>
        <ArrowRight />
      </ButtonRightArrow>
    );
  };

  const CustomLeftArrow = ({ onClick, ...rest }: ArrowProps) => {
    return (
      <ButtonLeftArrow onClick={() => onClick && onClick()}>
        <ArrowLeft />
      </ButtonLeftArrow>
    );
  };

  return (
    <>
      <SolutionsPage>
        <Banner image={imageBackground} title={bannerTitle} />
        <SectionTextImage
          sectionTitle={aboutTitle}
          sectionTitleMark
          category={aboutCategory}
          title={aboutInfoSubtitle}
          description={aboutInfoDescription}
          image={imageBiohub}
          imageSeparatorPlus={imageSeparatorPlus}
          id="products"
          extraDetails={detailsEquipment.extraDetails}
          textAlign={"center"}
          highlight={intl.formatMessage({ id: "section.product.highlight" })}
          labelButtonTerm={"section.buy"}
          linkTo="/solutions"
        />
        <SectionTextImage
          title={biomapsSubtitle}
          description={biomapsDescription}
          image={imageBiomaps}
          direction={"row-reverse"}
          sectionColor={"primary"}
          textEndSection={bioMapsRequired}
          extraDetails={detailsEquipment.extraDetails2}
          textAlign={"center"}
          highlight={intl.formatMessage({ id: "section.product.highlight" })}
          labelButtonTerm={"section.buy"}
          linkTo="/solutions"
        />
        <HowWorks title={howWorksTitle} image={imageDiagramme} />
        <Prices
          title={aboutTitle}
          subtitle={productsDescription}
          category={productsCategory}
          subDescriptionDrone={productsSubDescriptionDrone}
          subDescriptionCultivation={productsSubDescriptionCultivation}
          subDescriptionBiological={productsSubDescriptionBiological}
          descriptionRequired={productsRequired}
          items={priceListFiltered()}
          image={logoBioHubWithBioMaps}
          optionsDrone={optionsDrone}
          changeDrone={handleChangeDrone}
          droneSelected={optSelectDrone}
          optionsCultivation={optionsCultivation}
          changeCultivation={handleChangeCultivation}
          cultivationSelected={optSelectCultivation}
          optionsBiological={optionsBiological}
          changeBiological={handleChangeBiological}
          biologicalSelected={optSelectBiological}
        />
        {dataEquipments.map(
          (item) =>
            item.isActive &&
            solutions.some((sol) => sol.name === item.name) && (
              <>
                <Section>
                  <Grid container spacing={3}>
                    <Grid item lg={8} md={8} sm={12} xs={12} className="descriptionProduct">
                      <Text
                        text={item.name}
                        appearance={"title-bold"}
                        color={"primary"}
                        size={"large"}
                      />
                      <Text
                        text={item.details.subTitle}
                        appearance={"info-subtitle"}
                        color={"highlighted"}
                      />
                      <Text text={item.details.description} appearance={"description-block"} />
                      <Grid item lg={12} md={12} sm={12} className="detailsProduct">
                        {item.details.extraDetails.map((item) => (
                          <Grid key={item.id} item lg={3} md={3} sm={3} className="alignImages">
                            <Image src={item.image} />
                            <Text text={item.detail} textAlign={"center"} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} className="alignImages">
                      <Image src={item.details.imageEquipment} alt={item.name} />
                      <Clickable to={item.linkTo}>
                        <Button
                          text={intl.formatMessage({ id: "section.buy" })}
                          appearance={"outline-more"}
                          color={"highlighted"}
                        />
                      </Clickable>
                    </Grid>
                  </Grid>
                </Section>

                <Section sectionColor={"primary"}>
                  <Grid container spacing={3} justify={"center"}>
                    <Grid item lg={12} md={12} sm={12}>
                      <Text
                        text={item.media.title}
                        appearance={"title-semibold"}
                        textAlign={"center"}
                        sectionTitleMark
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6}>
                      <Text text={item.media.description} textAlign={"center"} color={"light"} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <CarouselEquipments
                        responsive={responsive}
                        showDots
                        customRightArrow={<CustomRightArrow onClick={() => {}} />}
                        customLeftArrow={<CustomLeftArrow onClick={() => {}} />}
                      >
                        {item.media.album.map((item) => (
                          <Image src={item.file} />
                        ))}
                      </CarouselEquipments>
                    </Grid>
                  </Grid>
                </Section>
              </>
            )
        )}
        <Footer image={true} description={intl.formatMessage({ id: "info.about" })} />
      </SolutionsPage>
    </>
  );
};
