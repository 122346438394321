import { IntlShape } from "react-intl";
import BiohubLatestApi, {
  BiohubResponse,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";
import { ProvisoryCartSolution } from "../store/reducers/provisoryCartReducer";
import { Address } from "biohub-model";

export type Price = {
  brazilPrice: number;
  europePrice: number;
  otherPrice: number;
};

export type SoftwareSolution = {
  name: string;
  monthlyPrice: Price;
  earlyPrice: Price;
};

export type HardwareSolution = {
  name: string;
  setupPrice: {
    rent: {
      firstEquipmentMonthlyPrice: Price;
      otherEquipmentsMonthlyPrice: Price;
      firstEquipmentEarlyPrice: Price;
      otherEquipmentsEarlyPrice: Price;
    };
    buy: {
      firstEquipmentPrice: Price;
      otherEquipmentsPrice: Price;
    };
  };
  usagePrice: {
    hectarePerMonthPrice: Price;
  };
};

export const mandatoryBioHUBSolution = {
  name: "bioHUB",
  earlyPrice: {
    brazilPrice: 380,
    europePrice: 180,
    otherPrice: 200,
  },
  monthlyPrice: {
    brazilPrice: 3876,
    europePrice: 1836,
    otherPrice: 2040,
  },
};

export const mandatoryBioMAPSSolution = {
  name: "bioMAPS",
  earlyPrice: {
    brazilPrice: 1200,
    europePrice: 600,
    otherPrice: 720,
  },
  monthlyPrice: {
    brazilPrice: 12240,
    europePrice: 4920,
    otherPrice: 720,
  },
};

export const mandatorySoftwareSolutions: SoftwareSolution[] = [
  mandatoryBioMAPSSolution,
  mandatoryBioMAPSSolution,
];

export const hardwareSolutions: HardwareSolution[] = [
  {
    name: "bioBOT",
    setupPrice: {
      buy: {
        firstEquipmentPrice: {
          brazilPrice: 1800,
          europePrice: 1000,
          otherPrice: 1000,
        },
        otherEquipmentsPrice: {
          brazilPrice: 800,
          europePrice: 600,
          otherPrice: 650,
        },
      },
      rent: {
        firstEquipmentEarlyPrice: {
          brazilPrice: 360,
          europePrice: 200,
          otherPrice: 200,
        },
        otherEquipmentsEarlyPrice: {
          brazilPrice: 160,
          europePrice: 120,
          otherPrice: 130,
        },
        firstEquipmentMonthlyPrice: {
          brazilPrice: 35,
          europePrice: 20,
          otherPrice: 20,
        },
        otherEquipmentsMonthlyPrice: {
          brazilPrice: 16,
          europePrice: 12,
          otherPrice: 13,
        },
      },
    },
    usagePrice: {
      hectarePerMonthPrice: {
        brazilPrice: 1,
        europePrice: 2,
        otherPrice: 1,
      },
    },
  },
  {
    name: "bioCOTE",
    setupPrice: {
      buy: {
        firstEquipmentPrice: {
          brazilPrice: 1800,
          europePrice: 1000,
          otherPrice: 1000,
        },
        otherEquipmentsPrice: {
          brazilPrice: 800,
          europePrice: 600,
          otherPrice: 650,
        },
      },
      rent: {
        firstEquipmentEarlyPrice: {
          brazilPrice: 360,
          europePrice: 200,
          otherPrice: 200,
        },
        otherEquipmentsEarlyPrice: {
          brazilPrice: 160,
          europePrice: 120,
          otherPrice: 130,
        },
        firstEquipmentMonthlyPrice: {
          brazilPrice: 35,
          europePrice: 20,
          otherPrice: 20,
        },
        otherEquipmentsMonthlyPrice: {
          brazilPrice: 16,
          europePrice: 12,
          otherPrice: 13,
        },
      },
    },
    usagePrice: {
      hectarePerMonthPrice: {
        brazilPrice: 1,
        europePrice: 2,
        otherPrice: 1,
      },
    },
  },
  {
    name: "bioMITE",
    setupPrice: {
      buy: {
        firstEquipmentPrice: {
          brazilPrice: 1800,
          europePrice: 1000,
          otherPrice: 1000,
        },
        otherEquipmentsPrice: {
          brazilPrice: 800,
          europePrice: 600,
          otherPrice: 650,
        },
      },
      rent: {
        firstEquipmentEarlyPrice: {
          brazilPrice: 360,
          europePrice: 200,
          otherPrice: 200,
        },
        otherEquipmentsEarlyPrice: {
          brazilPrice: 160,
          europePrice: 120,
          otherPrice: 130,
        },
        firstEquipmentMonthlyPrice: {
          brazilPrice: 35,
          europePrice: 20,
          otherPrice: 20,
        },
        otherEquipmentsMonthlyPrice: {
          brazilPrice: 16,
          europePrice: 12,
          otherPrice: 13,
        },
      },
    },
    usagePrice: {
      hectarePerMonthPrice: {
        brazilPrice: 1,
        europePrice: 2,
        otherPrice: 1,
      },
    },
  },
];

export function convertProductsToOrderDetail(
  cartItems: ProvisoryCartSolution[],
  intl: IntlShape
): string {
  if (cartItems.length === 0) return "";

  return cartItems
    .map((cartItem) => {
      return `${intl.formatMessage({ id: "purchase.order.mail.content.product" })}: ${
        cartItem.name
      }\n${intl.formatMessage({ id: "purchase.order.mail.content.quantity" })}: ${
        cartItem.amount
      }\n`;
    })
    .join("\n");
}

export type OrderInformation = {
  orderId: string;
  administratorInfo: {
    name: string;
    document: string;
    email: string;
    phone: string;
    address: Omit<Address, "id" | "cityCode">;
  };
  companyInfo:
    | {
        name: string;
        tradeName: string;
        document: string;
        email: string;
        phone: string;
        address: Omit<Address, "id" | "cityCode">;
      }
    | undefined;
  notes: string;
  orderDetail: string;
};

export async function sendPurchaseOrderIntentMail(
  data: OrderInformation
): Promise<BiohubResponse<string>> {
  const options = {
    headers: { "Content-Type": "application/json" },
  };
  let returnedResponse: BiohubResponse<string> = newBiohubSuccess(
    "purchase.order.intent.mail.success"
  );
  await BiohubLatestApi.post("/send-po-intent-mail", data, options).catch((error) => {
    returnedResponse = extractBiohubErrorResponse(
      error.status === 400
        ? "purchase.order.intent.mail.error"
        : "purchase.order.intent.mail.validation.error"
    );
  });
  return returnedResponse;
}
