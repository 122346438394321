import styled from "styled-components";

type Props = {
  margin_bottom:string | "0em",
};

export const Card = styled.div.attrs((props: Props) => ({
  className: "box",
  margin_bottom: props.margin_bottom
}))<{ margin_bottom?: string; }>`
  display: flex;
  border: 1px solid var(--primary);
  border-radius: 20px;
  flex-direction: column;
  margin-bottom: ${props => props.margin_bottom}; 

  > * {
    &:first-child {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
  }

  > * {
    &:last-child {
      border-bottom-right-radius: 18px;
      border-bottom-left-radius: 18px;
    }
  }

  > * {
    &:not(:last-child) {
      border-bottom: 1px solid var(--primary);
    }
  }
`;

export const CardTitle = styled.div.attrs((props: Props) => ({
  className: "box",
}))`
  background-color: var(--primary);
  font-weight: bold;
  color: white;
  padding: 10px;
`;

export const CardContent = styled.div.attrs((props: Props) => ({
  className: "box",
}))`
  background-color: white;
  padding: 10px;
`;
