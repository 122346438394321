import { Location, ReleaserConfiguration } from "biohub-model";
import Shpjs from "shpjs";
import { DOMParser } from "xmldom";
import * as turf from "@turf/turf";
import {
  AreaConfigWithoutId,
  AreaCreationParameters,
} from "../../store/actions/projectTreeActions";

// Type alias just to make it easier to read. This is the type returned from
// the shp js function call.
type Geojson = Shpjs.FeatureCollectionWithFilename;

export type ImportedRouteData = (Location & { alt?: number })[];

/**
 * Reads a .zip file from a buffer, that contains a shapefile, and returns the
 * areas contained in it.
 */
async function extractAreasFromShpZip(
  zipFilename: string,
  zipContents: Promise<ArrayBuffer>,
  areaConfig: AreaConfigWithoutId,
  releaserConfiguration: ReleaserConfiguration[],
  optimize: boolean
): Promise<AreaCreationParameters[]> {
  const projectName = zipFilename.replace(/\.zip$/, "");

  // The shp js library expects zip files with the extension.
  const geojson = (await Shpjs(await zipContents)) as Geojson;

  return processGeojson(geojson, projectName, areaConfig, releaserConfiguration, optimize);
}

async function extractAreasFromKml(
  kmlFilename: string,
  kmlContents: string,
  areaConfig: AreaConfigWithoutId,
  releaserConfiguration: ReleaserConfiguration[],
  optimize: boolean
): Promise<AreaCreationParameters[]> {
  // Unfortunately i've not found a module that could be used as an ES module...
  // Maybe in the future we will substitute this
  const tj = require("@tmcw/togeojson");

  const areaName = kmlFilename.replace(/\.kml$/, "");

  const kmlStringParsed = new DOMParser().parseFromString(kmlContents);
  const convertedGeoJson = tj.kml(kmlStringParsed) as Geojson;

  return processGeojson(convertedGeoJson, areaName, areaConfig, releaserConfiguration, optimize);
}

function processGeojson(
  geojson: Geojson | Geojson[],
  projectName: string,
  areaConfig: AreaConfigWithoutId,
  releaserConfiguration: ReleaserConfiguration[],
  optimize: boolean
): AreaCreationParameters[] {
  // This is the tolerance used on Remer-Douglas-Peucker algorithm
  // Can be ajusted depending on the results
  const tolerance = 0.0001;
  // The received "geojson" object may be one feature collection or an array of many
  // feature collections. Here we make an object that's always an array.
  let geoJsonToBeProcessed = Array.isArray(geojson) ? geojson : [geojson];
  // If the opmized option is enabled, we will try to optimize it
  if (optimize) {
    const optimizedArray = geoJsonToBeProcessed.map((geojson) => {
      try {
        return turf.simplify(geojson as turf.AllGeoJSON, {
          tolerance: tolerance,
          highQuality: false,
        }) as Shpjs.FeatureCollectionWithFilename;
      } catch (e) {
        return geojson;
      }
    });
    geoJsonToBeProcessed = optimizedArray;
  }
  // Now we have an array where each item is a feature collection, containing many
  // features. Put them in a single array of features.
  const features = geoJsonToBeProcessed.flatMap((featureCollection) => featureCollection.features);
  // Each feature will become an area.
  const areas = features.map((feature, featureIndex): AreaCreationParameters | null => {
    // We should let the user choose the name of a property to use as name.
    const areaName = `${projectName} ${featureIndex + 1}`;

    const geometry = feature.geometry;
    let areaPoints: Location[] = [];
    console.log(geometry);
    if (geometry.type === "Polygon") {
      // Each field in the "coordinates" field is a number[][][].
      // The first level is for different polygons, but we only support one polygon
      // per area. The second level is for the coordinates of the polygon. The third
      // level exists because each coordinate is an array of [longitude, latitude].
      areaPoints = geometry.coordinates[0].map((coord) => ({
        lat: coord[1],
        lng: coord[0],
      }));
    } else {
      // Unsupported geometry type.
      return null;
    }

    return {
      areaName: areaName,
      areaPolygon: areaPoints,
      areaConfig: areaConfig,
      configuredReleasers: releaserConfiguration,
    };
  });

  // This filters the array to keep only the objects that aren't null. The cast is
  // needed because this isn't actually type safe.
  return areas.filter((a) => a !== null) as AreaCreationParameters[];
}

export default async function importGeographicData(
  file: File,
  areaConfig: AreaConfigWithoutId,
  releaserConfiguration: ReleaserConfiguration[],
  polygonOptimizationControl: boolean
): Promise<AreaCreationParameters[]> {
  if (file.name.endsWith(".kml")) {
    const kmlFileContents = await file.text();
    const importedAreas = await extractAreasFromKml(
      file.name,
      kmlFileContents,
      areaConfig,
      releaserConfiguration,
      polygonOptimizationControl
    );

    return importedAreas;
  } else if (file.name.endsWith(".zip")) {
    const importedAreas = await extractAreasFromShpZip(
      file.name,
      file.arrayBuffer(),
      areaConfig,
      releaserConfiguration,
      polygonOptimizationControl
    );

    return importedAreas;
  }

  return [];
}

// Route import kml
function processRouteGeojson(geojson: Geojson | Geojson[], optimize: boolean): ImportedRouteData {
  // This is the tolerance used on Remer-Douglas-Peucker algorithm
  // Can be ajusted depending on the results
  const tolerance = 0.0001;
  // The received "geojson" object may be one feature collection or an array of many
  // feature collections. Here we make an object that's always an array.
  let geoJsonToBeProcessed = Array.isArray(geojson) ? geojson : [geojson];
  // If the opmized option is enabled, we will try to optimize it
  if (optimize) {
    const optimizedArray = geoJsonToBeProcessed.map((geojson) => {
      try {
        return turf.simplify(geojson as turf.AllGeoJSON, {
          tolerance: tolerance,
          highQuality: false,
        }) as Shpjs.FeatureCollectionWithFilename;
      } catch (e) {
        return geojson;
      }
    });
    geoJsonToBeProcessed = optimizedArray;
  }
  // Now we have an array where each item is a feature collection, containing many
  // features. Put them in a single array of features.
  const features = geoJsonToBeProcessed.flatMap((featureCollection) => featureCollection.features);
  // Each feature will become an area.
  const routeData = features.map((feature, featureIndex): ImportedRouteData | null => {
    const geometry = feature.geometry;
    if (geometry.type === "LineString") {
      let routePoints: ImportedRouteData = [];
      for (let coord of geometry.coordinates) {
        routePoints.push({
          lat: coord[1],
          lng: coord[0],
          alt: coord[2],
        });
      }
      return routePoints;
    } else {
      // Unsupported geometry type.
      return null;
    }
  });
  if (routeData.length === 0 || routeData[0] === null) {
    throw new Error("No route data found");
  }
  return routeData[0];
}

async function extractRouteFromKml(
  kmlContents: string,
  optimize: boolean = false
): Promise<ImportedRouteData> {
  // Unfortunately i've not found a module that could be used as an ES module...
  // Maybe in the future we will substitute this
  const tj = require("@tmcw/togeojson");
  const kmlStringParsed = new DOMParser().parseFromString(kmlContents);
  const convertedGeoJson = tj.kml(kmlStringParsed) as Geojson;
  return processRouteGeojson(convertedGeoJson, optimize);
}

export async function importRouteKml(
  file: File,
  optimize: boolean = false
): Promise<ImportedRouteData | null> {
  if (file.name.endsWith(".kml")) {
    const kmlFileContents = await file.text();
    const importedRouteData = await extractRouteFromKml(kmlFileContents, optimize);
    return importedRouteData;
  }
  return null;
}
