import {
  Cpu,
  DirectClient,
  Drone,
  DroneManufacturer,
  DroneModel,
  Profile,
  Role,
} from "biohub-model";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import UserTableComponent from "../../../components/Atomic/UserTable";
import { IntlShape, useIntl } from "react-intl";
import { v4 as uuid } from "uuid";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Modal } from "../styles";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {
  additionDrone,
  fetchCollections,
  fetchCpus,
  remotionDrone,
  updateDrone,
} from "../../../store/actions/collectionsActions";
import { Dispatch } from "redux";
import { readAllDirectClients } from "../../../store/actions/masterActions";
import DropdownOptions from "../../../components/Atomic/Inputs/DropdownOptions";
import EquipmentService from "../../../services/EquipmentService";

export default function DroneTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [editingDrone, editingDroneSetVale] = React.useState<Drone>();

  const loading = useSelector(
    (systemState: SystemState) =>
      systemState.collections.loadingDrones || systemState.collections.loadingDroneModels
  );

  const userProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );
  const directClients: Array<DirectClient> | null = useSelector(
    (state: SystemState) => state.master.directClients
  );
  const mapDirectClients = useSelector((systemState: SystemState) => {
    const directClientList = systemState.master.directClients ?? [];
    let map: { [directClientId: string]: DirectClient } = {};

    directClientList.forEach((directClient) => {
      map = {
        ...map,
        [directClient.id]: directClient,
      };
    });
    return map;
  });
  const droneModels: Array<DroneModel> | null = useSelector(
    (state: SystemState) => state.collections.droneModels
  );

  const readindDirectClients: boolean = useSelector(
    (state: SystemState) => state.master.readindDirectClients
  );

  React.useEffect(() => {
    if (!readindDirectClients) dispatch(readAllDirectClients());
  }, []);

  const drones: Array<Drone> | null = useSelector((state: SystemState) => state.collections.drones);
  const listToViewDrones: Array<Drone> = drones === null ? [] : (drones as Array<Drone>);
  const listToViewDroneModels: Array<DroneModel> =
    droneModels === null ? [] : (droneModels as Array<DroneModel>);
  const listToViewDirectClients: Array<DirectClient> =
    directClients === null ? [] : (directClients as Array<DirectClient>);

  const handleClickNewDrone = () => {
    setOpen(true);
  };
  const handleClickEditDrone = async (drone: Drone) => {
    editingDroneSetVale(drone);
    setOpen(true);
  };

  const handlerAddDrone = (form: DroneFormState) => {
    const drone: Drone = {
      id: uuid(),
      aviationNumber: form.aviationNumber,
      serialNumber: form.serialNumber,
      model: listToViewDroneModels.find((DM) => DM.id === form.droneModelId)!,
      directClientId: form.directClientId,
    };

    dispatch(additionDrone(drone));
    handleCloseNewDrone();
  };
  const handleEditDrone = (form: DroneFormState) => {
    const droneId = editingDrone!.id;
    const updatedDrone: Partial<Drone> = {
      model: listToViewDroneModels.find((model) => model.id === form.droneModelId),
      serialNumber: form.serialNumber,
      aviationNumber: form.aviationNumber,
      directClientId: form.directClientId,
    };

    dispatch(updateDrone(droneId, updatedDrone));
    dispatch(fetchCollections());
    handleCloseEditDrone();
  };

  const handleCloseNewDrone = () => {
    setOpen(false);
  };
  const handleCloseEditDrone = () => {
    setOpen(false);
    editingDroneSetVale(undefined);
  };

  // terms
  const intl = useIntl();
  const termModel = intl.formatMessage({ id: "placeholder.releaser.tablemodel" });
  const termDroneManufacturer = intl.formatMessage({ id: "info.manufacturer" });
  const termDroneAva = intl.formatMessage({ id: "placeholder.aviation.reg" });
  const termSerialNumber = intl.formatMessage({ id: "placeholder.releaser.tableserialnumber" });
  const termAddNew = intl.formatMessage({ id: "action.add.new" });
  const termDirectClient = intl.formatMessage({ id: "info.client" });

  const termDrone = intl.formatMessage({ id: "info.drones" });

  return (
    <div>
      {userProfile !== null &&
        userProfile.role <= Role.manager &&
        (!loading ? (
          <UserTableComponent<Drone>
            items={drones}
            titleTerm={termDrone}
            addTerm={
              userProfile !== null &&
              (userProfile.role === Role.master || userProfile.role === Role.admin)
                ? termAddNew
                : undefined
            }
            onAddFunction={
              userProfile !== null &&
              (userProfile.role === Role.master || userProfile.role === Role.admin)
                ? handleClickNewDrone
                : undefined
            }
            classes={
              userProfile !== null && userProfile.role === Role.master
                ? [
                    "id",
                    termDroneManufacturer,
                    termModel,
                    termSerialNumber,
                    termDroneAva,
                    termDirectClient,
                  ]
                : ["id", termDroneManufacturer, termModel, termSerialNumber, termDroneAva]
            }
            formatItems={(item: Drone) => {
              if (userProfile !== null && userProfile.role === Role.master) {
                return [
                  item.id,
                  item.model.manufacturer.name,
                  item.model.modelName,
                  item.serialNumber,
                  item.aviationNumber,
                  mapDirectClients[item.directClientId]?.company?.name ??
                    mapDirectClients[item.directClientId]?.profiles.find(
                      (profile) => profile.role === Role.admin || profile.role === Role.master
                    )?.name ??
                    "",
                ];
              }
              return [
                item.id,
                item.model.manufacturer.name,
                item.model.modelName,
                item.serialNumber,
                item.aviationNumber,
              ];
            }}
            onViewItem={async (itemId: string) => {
              let _drone: Drone | undefined = undefined;
              for (let i = 0; i < drones.length && _drone === undefined; i++) {
                if (itemId === drones[i].id) {
                  _drone = drones[i];
                }
              }
              if (_drone !== undefined) await handleClickEditDrone(_drone);
            }}
            onRemoveFunction={
              userProfile !== null && userProfile.role === Role.master
                ? (items: Array<string>) => {
                    for (let i = 0; i < items.length; i++) {
                      dispatch(remotionDrone(items[i]));
                    }
                  }
                : undefined
            }
          />
        ) : (
          <CircularProgress />
        ))}
      <div>
        <DroneForm
          intl={intl}
          open={open}
          editing={editingDrone !== undefined ? true : false}
          finishCallback={editingDrone === undefined ? handlerAddDrone : handleEditDrone}
          cancelCallback={editingDrone === undefined ? handleCloseNewDrone : handleCloseEditDrone}
          dispach={dispatch}
          listModels={listToViewDroneModels}
          listDirectClients={listToViewDirectClients}
          profileRole={userProfile!.role}
          listDrones={listToViewDrones}
          directClientId={
            userProfile?.role === Role.admin
              ? userProfile.directClientId
              : editingDrone !== undefined
              ? editingDrone.directClientId
              : undefined
          }
          serialNumber={editingDrone !== undefined ? editingDrone.serialNumber : undefined}
          aviationNumber={editingDrone !== undefined ? editingDrone.aviationNumber : undefined}
          droneModelId={editingDrone !== undefined ? editingDrone.model.id : undefined}
          droneManufacturerId={
            editingDrone !== undefined ? editingDrone.model.manufacturer.id : undefined
          }
        />
      </div>
    </div>
  );
}

interface EditProps {
  open: boolean;
  editing: boolean;
  finishCallback: Function;
  cancelCallback: Function;
  intl: IntlShape;
  dispach: Dispatch<any>;
  listModels: DroneModel[];
  listDirectClients: DirectClient[];
  profileRole: Role;
  listDrones: Drone[];
  droneModelId?: string;
  droneManufacturerId?: string;
  serialNumber?: string;
  aviationNumber?: string;
  directClientId?: string;
}

type DroneFormState = {
  open: boolean;
  editing: boolean;
  droneModelId: string;
  serialNumber: string;
  aviationNumber: string;
  directClientId: string;
  droneManufacturerId?: string;
};

class DroneForm extends React.Component<EditProps, DroneFormState> {
  _extractProps(props: EditProps): DroneFormState {
    return {
      open: props.open,
      editing: props.editing,
      droneModelId: props.droneModelId !== undefined ? props.droneModelId : "",
      serialNumber: props.serialNumber !== undefined ? props.serialNumber : "",
      aviationNumber: props.aviationNumber !== undefined ? props.aviationNumber : "",
      directClientId: props.directClientId !== undefined ? props.directClientId : "",
      droneManufacturerId:
        props.droneManufacturerId !== undefined ? props.droneManufacturerId : undefined,
    };
  }

  constructor(props: EditProps) {
    super(props);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDirectClient = this.handleChangeDirectClient.bind(this);
    this.state = this._extractProps(props);
  }

  componentWillReceiveProps(newProps: EditProps) {
    this.setState(this._extractProps(newProps));
  }

  handleChange(event: React.ChangeEvent<any>, newValue?: any) {
    if (event.target.name !== undefined) {
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    } else {
      this.setState({ ...this.state, directClientId: (newValue as string) ?? "" });
    }
  }

  async handleChangeDirectClient(event: React.ChangeEvent<any>, newValue: DirectClient | null) {
    let cpus: Cpu[] = [];
    const result = await EquipmentService.readCpus(newValue?.id ?? undefined);
    if (result.success) {
      cpus = result.data;
    }
    if (newValue) {
      this.setState({
        ...this.state,
        directClientId: newValue.id,
      });
    }
  }

  render() {
    const intl = this.props.intl;
    const manufacturerList = this.props.listModels
      .map((model) => model.manufacturer)
      .filter((value, index, array) => {
        return array.findIndex((element) => element.id === value.id) === index;
      });
    const droneList = this.props.listModels.filter(
      (model) => model.manufacturer.id === this.state.droneManufacturerId
    );

    const isSerialNumberValid = this.state.serialNumber.length > 0;
    const isAviationNumberValid = this.state.aviationNumber.length > 0;
    const isDroneModelIdValid = this.state.droneModelId.length > 0;
    const isDroneManufacturerIdValid = (this.state.droneManufacturerId ?? "").length > 0;
    const isDirectClientIdValid = this.state.directClientId.length > 0;

    const isValidData =
      isSerialNumberValid &&
      isAviationNumberValid &&
      isDroneModelIdValid &&
      isDroneManufacturerIdValid &&
      isDirectClientIdValid;

    return (
      <Modal
        open={this.state.open}
        onClose={(e) => this.props.cancelCallback()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.state.editing
            ? intl.formatMessage({ id: "info.drone.edit" })
            : intl.formatMessage({ id: "info.drone.add" })}
          <Icon onClick={(e) => this.props.cancelCallback()}>close</Icon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.editing
              ? intl.formatMessage({ id: "info.drone.edit.message" })
              : intl.formatMessage({ id: "info.drone.add.message" })}
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <TextField
                autoFocus
                margin="dense"
                error={!isSerialNumberValid}
                disabled={
                  this.props.profileRole === Role.master || this.props.profileRole === Role.admin
                    ? false
                    : true
                }
                id="serialNumber"
                label={intl.formatMessage({ id: "placeholder.releaser.serialnumber" })}
                value={this.state.serialNumber}
                fullWidth
                name="serialNumber"
                variant="outlined"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <TextField
                margin="dense"
                id="aviationNumber"
                error={!isAviationNumberValid}
                disabled={
                  this.props.profileRole === Role.master || this.props.profileRole === Role.admin
                    ? false
                    : true
                }
                label={intl.formatMessage({ id: "placeholder.aviation.reg" })}
                fullWidth
                value={this.state.aviationNumber}
                name="aviationNumber"
                variant="outlined"
                onChange={this.handleChange}
              />
            </Grid>

            {/*  ************* AUTOCOMPLETE FOR LIST OF DIRECTCLIENTS ************* */}
            {/* After a while this "autocomplete" will have to be changed due to the number of clients it will have. */}
            {this.props.profileRole === Role.master ? (
              <>
                <Grid item lg={4} xs={12}>
                  <b>{intl.formatMessage({ id: "info.drone.selectclient" })}</b>
                </Grid>
                <Grid item lg={8} xs={12} style={{ paddingTop: 12 }}>
                  <Autocomplete
                    id="directClientId"
                    fullWidth
                    options={this.props.listDirectClients}
                    size="small"
                    value={this.props.listDirectClients.find(
                      (c) => c.id === this.state.directClientId
                    )}
                    autoHighlight
                    onChange={this.handleChangeDirectClient}
                    getOptionLabel={(option) => {
                      const search = option.profiles.find(
                        (p) => p.role === Role.admin || p.role === Role.master
                      );
                      if (search !== undefined) {
                        return search.name;
                      }
                      return "";
                    }}
                    renderOption={(option) => {
                      const search = option.profiles.find(
                        (p) => p.role === Role.admin || p.role === Role.master
                      );
                      if (search !== undefined) {
                        return search.name;
                      }
                      return "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({ id: "placeholder.drone.selectclient" })}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        error={!isDirectClientIdValid}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : undefined}
            <Grid item lg={4} xs={12}>
              <b>{intl.formatMessage({ id: "info.drone.manufacturer" }) + ":"}</b>
            </Grid>
            <Grid item lg={8} xs={12} style={{ paddingTop: 12 }}>
              <DropdownOptions<DroneManufacturer>
                baseId="drone-manufacturer"
                error={!isDroneManufacturerIdValid}
                label={intl.formatMessage({ id: "placeholder.drone.selectmanufacturer" })}
                emptyOption={intl.formatMessage({ id: "info.none" })}
                options={manufacturerList}
                getOptionName={(manufacturer) => manufacturer.name}
                value={manufacturerList.find(
                  (manufacturer) => manufacturer.id === this.state.droneManufacturerId
                )}
                onChange={(manufacturer) => {
                  this.setState({ droneManufacturerId: manufacturer?.id });
                }}
              />
            </Grid>

            <Grid item lg={4} xs={12}>
              <b>{intl.formatMessage({ id: "info.drone.dronemodel" })}</b>
            </Grid>
            <Grid item lg={8} xs={12} style={{ paddingTop: 12 }}>
              <DropdownOptions<DroneModel>
                baseId="drone"
                error={!isDroneModelIdValid}
                label={intl.formatMessage({ id: "placeholder.drone.selectdronemodel" })}
                emptyOption={intl.formatMessage({ id: "info.none" })}
                options={droneList}
                getOptionName={(model) => model.modelName}
                value={droneList.find((model) => model.id === this.state.droneModelId)}
                onChange={(model) => {
                  this.setState({ droneModelId: model?.id ?? "" });
                }}
                disabled={this.state.editing}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {this.props.profileRole !== Role.master &&
        this.props.profileRole !== Role.admin ? undefined : (
          <DialogActions>
            <Button
              onClick={(e) => this.props.cancelCallback()}
              className="cancel-button"
              startIcon={<Icon>close</Icon>}
            >
              {intl.formatMessage({ id: "generic.cancel" })}
            </Button>
            <Button
              onClick={(e) => this.props.finishCallback(this.state)}
              variant="contained"
              color="primary"
              disabled={!isValidData}
            >
              {this.state.editing
                ? intl.formatMessage({ id: "action.save" })
                : intl.formatMessage({ id: "action.add" })}
            </Button>
          </DialogActions>
        )}
      </Modal>
    );
  }
}
