import styled from "styled-components";

type Props = {
  image: string;
};

export const Section = styled.section.attrs((props: Props) => ({}))`
  width: 100%;
  background: var(--background-default);
  position: relative;
  padding: 3rem 0 5rem;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;

  img {
    margin-top: 3rem;
  }
`;
