import styled from "styled-components";

type Props = {};

export const LandingPage = styled.section.attrs((props: Props) => ({}))`
  @media screen and (max-width: 580px) {
    .banner {
      padding-bottom: 3rem;

      span:after {
        left: 51px;
        font-size: 20px;
        bottom: 12px;
      }
    }

    section {
      button {
        max-height: 34px;
        margin-top: 15px;
      }
    }
  }
`;

export const SignPage = styled.section.attrs((props: Props) => ({}))``;

export const VerificationPage = styled.section.attrs((props: Props) => ({}))`
  .MuiInput-root {
    position: relative;
    margin: 1rem 0;
    min-width: 280px;
    &:hover:before,
    &:focus:before,
    &:before {
      border-bottom: 0 !important;
    }
    svg {
      fill: var(--dashboard-icon-info);
    }
  }

  @media screen and (max-width: 680px) {
    .MuiButton-label {
      line-height: 1.2;
    }
  }
`;

export const ContentPage = styled.div.attrs((props: Props) => ({}))`
  padding: 3rem 8.333333%;
`;

export const DetailsDescription = styled.div.attrs((props: Props) => ({}))`
  margin: 2rem 0;
`;

export const DetailsData = styled.div.attrs((props: Props) => ({}))`
  margin: 1rem 0 2rem;
  > label {
    margin-bottom: 10px;
  }
`;

export const DetailsDataCode = styled.div.attrs((props: Props) => ({}))`
  margin: 1rem 0 2rem;
  > label {
    margin-bottom: 10px;
  }
  input,
  .MuiFormLabel-root {
    font-size: 34px;
    text-align: center;
    color: var(--secondary);
    letter-spacing: 5px;
  }
  fieldset {
    border-radius: 50px;
    min-width: 280px;
  }
`;

export const DetailsCode = styled.div.attrs((props: Props) => ({}))`
  margin: 1rem 0 2rem;
  display: flex;
  > label {
    margin-right: 20px;
    align-self: center;
  }
  input,
  .MuiFormLabel-root {
    font-size: 34px;
    text-align: center;
    color: var(--secondary);
    letter-spacing: 5px;
  }
  fieldset {
    border-radius: 50px;
    min-width: 280px;
  }
`;

export const DetailsPassword = styled.div.attrs((props: Props) => ({}))`
  margin: 1rem auto 2rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  > label {
    margin: 0 0 10px 15px;
  }
  input,
  .MuiFormLabel-root {
    font-size: 34px;
    text-align: center;
    color: var(--secondary);
    letter-spacing: 5px;

    @media screen and (max-width: 580px) {
      font-size: 22px;
      letter-spacing: 1px;
    }
  }
  fieldset {
    border-radius: 50px;
    width: 100%;
  }
  .MuiInputAdornment-root {
    position: absolute;
    right: 20px;
  }

  @media screen and (max-width: 991px) {
    width: 60%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ChangePasswordPage = styled.section.attrs((props: Props) => ({}))``;

export const AboutPage = styled.section.attrs((props: Props) => ({}))`
  @media screen and (max-width: 1200px) {
    .banner {
      min-height: 480px;
      .MuiGrid-container {
        height: 340px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .banner {
      min-height: 420px;
      .MuiGrid-container {
        height: 300px;
      }
    }
  }
`;

export const ProductPage = styled.section.attrs((props: Props) => ({}))``;

export const ContactPage = styled.section.attrs((props: Props) => ({}))``;

export const PrivacyPage = styled.section.attrs((props: Props) => ({}))``;

export const PricesPage = styled.section.attrs((props: Props) => ({}))`
  .container {
    margin: 3rem auto;
  }
`;

export const SupportPage = styled.section.attrs((props: Props) => ({}))`
  .description {
    margin-left: 0;
    width: 100%;
  }
`;

export const TermsPage = styled.section.attrs((props: Props) => ({}))`
  .clickable {
    float: right;
    margin: 0 2rem 5rem;
  }
`;

export const Pillars = styled.section.attrs((props: Props) => ({}))`
  width: 100%;
  display: block;
  background: linear-gradient(90deg, var(--white) 50%, transparent 50%);

  @media screen and (max-width: 768px) {
    background: linear-gradient(180deg, var(--white) 50%, transparent 50%);
  }
`;

export const Content = styled.section.attrs((props: Props) => ({}))`
  width: 100%;
  display: block;
`;

export const Item = styled.div.attrs((props: Props) => ({}))`
  width: 100%;
  padding: 3rem 1.5rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;

  img {
    width: 90px;
  }

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
    img {
      width: 70px;
    }
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    img {
      width: 50px;
    }
  }
`;

export const Description = styled.span.attrs((props: Props) => ({
  className: "description",
}))`
  margin-left: 3rem;

  @media screen and (max-width: 1024px) {
    margin-left: 1rem;
  }

  @media screen and (max-width: 580px) {
    margin-left: 0;
  }

  hr {
    margin: 1rem 0;
    border: 0;
    width: 100%;
    height: 2px;
    background: var(--primary);
  }
  label {
    margin: 0 0 10px;
    line-height: 1.2;
  }
`;

export const Padding = styled.span.attrs((props: Props) => ({}))`
  padding: 7rem;
  display: table;
`;

export const Infos = styled.span.attrs((props: Props) => ({}))`
  margin-left: 5.75%;
  display: table;
`;

export const Questions = styled.div.attrs((props: Props) => ({}))`
  margin: 2rem 0;

  .MuiAccordion-rounded {
    background: var(--card-background);
  }
`;

export const Box = styled.div.attrs((props: Props) => ({
  className: "box",
}))`
  display: flex;
  column-gap: 1rem;
  margin: 3rem 0;

  .material-icons {
    align-self: center;
  }

  label:nth-of-type(2) {
    font-size: 12px;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    .material-icons {
      align-self: flex-start;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    .material-icons {
      align-self: center;
    }
  }
`;

export const Informations = styled.div.attrs((props: Props) => ({}))`
  label {
    text-align: left;
    margin: 0 0 10px;
  }

  @media screen and (max-width: 768px) {
    .box {
      flex-direction: column;
      .material-icons {
        align-self: center;
      }
    }
  }
`;

export const Contacts = styled.div.attrs((props: Props) => ({}))``;

export const Specifications = styled.div.attrs((props: Props) => ({}))`
  width: 80%;
  display: flex;
  margin: 3rem auto;
  text-align: center;

  img {
    margin: auto;
  }

  b {
    display: block;
  }

  .MuiGrid-item:nth-of-type(even) {
    position: relative;

    :before {
      content: "";
      display: block;
      width: 6px;
      height: 85px;
      background: var(--highlighted);
      border-radius: 10px;
      position: absolute;
      top: 35px;
      left: 0;
    }

    :after {
      content: "";
      display: block;
      width: 6px;
      height: 85px;
      background: var(--highlighted);
      border-radius: 10px;
      position: absolute;
      top: 35px;
      right: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    small,
    b {
      line-height: 1;
      display: block;
    }
  }

  @media screen and (max-width: 580px) {
    width: 100%;

    small {
      font-size: 12px;
    }
  }
`;

export const Details = styled.div.attrs((props: Props) => ({}))`
  width: 90%;
  margin-top: 3rem;
`;

export const Usage = styled.div.attrs((props: Props) => ({}))`
  margin: 2rem 0 5rem;
  width: 70%;

  @media screen and (max-width: 1200px) {
    line-height: 1;
    display: block;
    font-size: 12px;
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
