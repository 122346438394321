import styled from "styled-components";
import { Paper, Dialog } from "@material-ui/core";

type Props = {
  avatar: boolean;
  line: boolean;
};

export const Profile = styled.div.attrs((props: Props) => ({}))`
  padding: 3rem;
  display: flex;
  align-items: flex-start;

  h2 {
    text-align: left;
  }

  h6 {
    font-size: 11px;
    margin: 0;
  }

  .flip-card {
    h6,
    .material-icons {
      color: var(--dashboard-secondary);
      margin-right: 5px;
    }
    .MuiButton-text {
      .MuiButton-label {
        color: var(--dashboard-secondary);
        text-transform: uppercase;
      }
    }
    button {
      min-width: 120px;
      .MuiButton-label {
        text-transform: uppercase;
      }
    }
  }

  .back-flipcard {
    background: transparent;
    padding: 1rem;
  }

  .notifications {
    width: 100%;
  }

  @media screen and (max-width: 1290px) {
    div[color="light"]:nth-of-type(2) {
      .flip-card {
        margin-top: -70px;
      }
    }
  }

  @media screen and (max-width: 680px) {
    padding: 0;
    .MuiGrid-container {
      width: 100% !important;
      margin: 0;
    }
  }
`;

// Profile Informations
export const Block = styled(Paper).attrs((props: Props) => ({
  avatar: props.avatar,
  line: props.line,
}))`
  padding: 2rem 1rem 1rem;
  border: 1px solid var(--dashboard-highlighted);
  background: var(--card-background) !important;

  li {
    list-style-type: none;
  }

  ${(props: Props) =>
    props.avatar &&
    `
      background: linear-gradient(
        180deg,
        var(--dashboard-highlighted) 30px,
        var(--card-background) 60px
      ) !important;
      padding: 1rem;
  `}

  ${(props: Props) =>
    props.line &&
    `
      background: linear-gradient(
        180deg,
        var(--dashboard-highlighted) 10px,
        var(--card-background) 30px
      ) !important;
      padding: 2rem 1rem 1rem;
  `}
`;

export const Avatar = styled.div.attrs((props: Props) => ({}))`
  border-radius: 50%;
  border: 5px solid var(--dashboard-border-light);
  overflow: hidden;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: -30px auto 15px;
  box-shadow: 0 3px 6px var(--dashboard-box-shadow);
`;

export const Logo = styled.div.attrs((props: Props) => ({}))`
  border-radius: 50%;
  border: 5px solid var(--dashboard-border-light);
  overflow: hidden;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: -30px auto 7px;
  box-shadow: 0 3px 6px var(--dashboard-box-shadow);
`;

export const Title = styled.label.attrs((props: Props) => ({}))`
  font-size: 22px;
  line-height: 1;
  font-family: "GalanoGrotesque-SemiBold";
  text-align: center;
  color: var(--dashboard-primary-bg);
  display: block;
`;

export const Infos = styled.span.attrs((props: Props) => ({}))`
  ul {
    text-align: center;
    margin: 5px 0;
    li {
      a {
        font-family: "GalanoGrotesque-SemiBold";
        font-size: 14px;
        color: var(--dashboard-text-default);
        text-decoration: none;
        transition: all 0.3s linear;
      }
      span {
        font-size: 14px;
        vertical-align: text-bottom;
        margin-right: 2px;
        color: var(--dashboard-icon-info);
      }
      :focus,
      :hover {
        a {
          text-decoration: underline;
          text-decoration-color: var(--dashboard-secondary);
        }
      }
    }
  }
`;

export const Description = styled.span.attrs((props: Props) => ({}))`
  font-size: 14px;
  text-align: center;
  margin: 1.5rem 0;
  display: block;
  font-family: "Galano Grotesque";
  color: var(--dashboard-icon-info);
`;

export const CompanyInfo = styled.span.attrs((props: Props) => ({}))`
  display: block;
  position: relative;
  text-align: center;

  b {
    font-size: 16px;
    text-align: center;
    font-weight: normal;
    font-family: "GalanoGrotesque-Semibold";
    display: block;
    color: var(--dashboard-text-default);
  }

  ul {
    margin-top: 0;
    li {
      font-size: 12px;
      font-family: "GalanoGrotesque-Medium";
      line-height: 1.2;
    }
  }
`;

export const Actions = styled.span.attrs((props: Props) => ({}))`
  border-top: 0.1px solid var(--overlay-20);
  padding: 1rem 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-left: -1rem;
  width: calc(100% + 2rem);
  font-size: 12px;

  a {
    color: var(--dashboard-secondary);
    font-family: "GalanoGrotesque-Medium";
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    transition: all 0.3s ease;

    .material-icons {
      font-size: 14px;
      margin-right: 3px;
      margin-top: 4px;
    }

    :hover,
    :focus {
      color: var(--dashboard-primary-bg);
      text-decoration: none;
    }
  }

  .MuiButton-text {
    color: var(--dashboard-secondary);
    .material-icons {
      margin-right: 5px;
      margin-top: 4px;
    }
  }

  button {
    .MuiCircularProgress-root {
      width: 28px !important;
      height: 28px !important;
      color: var(--white);
      margin-left: 10px;
    }
  }
`;

export const Separator = styled.span.attrs((props: Props) => ({}))`
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;

  .material-icons {
    color: var(--dashboard-icon-info);
  }

  :before {
    content: "";
    display: table;
    width: 80px;
    margin: 0 auto;
    position: absolute;
    bottom: 4px;
    height: 1px;
    left: calc(50% - 40px);
    background: var(--dashboard-separator-line);
  }

  :after {
    content: "";
    display: table;
    width: 30px;
    position: absolute;
    bottom: 2px;
    left: calc(50% - 15px);
    height: 5px;
    border-radius: 10px;
    background: var(--dashboard-separator-tag);
  }
`;

//List
export const TableList = styled.div.attrs((props: Props) => ({}))`
  margin-bottom: 1rem;

  .MuiPaper-root {
    background: var(--card-background);
  }

  .table-header {
    background: var(--dashboard-table-header);
    border-radius: 4px 4px 0 0;
    font-family: "GalanoGrotesque-Semibold";
    text-transform: uppercase;
    letter-spacing: 0.35px;
    justify-content: space-between;

    button {
      padding: 0 10px;
      border-radius: 50px;
      color: var(--dashboard-menu-bg);
      padding-bottom: 5px;
      font-family: "GalanoGrotesque-SemiBold";
      font-weight: 600;
      margin-right: -20px;

      .material-icons {
        font-size: 20px;
        margin-top: 3px;
        margin-left: 3px;
      }
    }
  }

  svg,
  .material-icons {
    color: var(--dashboard-primary-bg);
    font-size: 22px;
    vertical-align: middle;
  }
`;

export const EditAvatar = styled.span.attrs((props: Props) => ({}))`
  img {
    border: 5px solid var(--dashboard-light-border);
    box-shadow: 0 0 3px var(--dashboard-box-shadow);
    margin: 2rem auto 0;
  }
  button {
    font-size: 10px;
    display: block;
    text-align: center;
    width: 100%;
    color: var(--dashboard-separator-title);
    text-transform: uppercase;
    .material-icons {
      font-size: 14px;
      vertical-align: bottom;
    }
  }
`;

export const Contract = styled.span.attrs((props: Props) => ({}))`
  margin: 3rem auto 0;
  display: block;
  text-align: center;
  width: 100%;
  a {
    font-size: 14px;
    color: var(--dashboard-secondary);
    text-transform: uppercase;
    text-decoration: none;
  }
  .material-icons {
    font-size: 18px;
    vertical-align: bottom;
    margin-left: 5px;
    color: var(--dashboard-secondary);
    cursor: pointer;
    :hover,
    :focus {
      color: var(--dashboard-highlighted);
    }
  }
  :hover,
  :focus {
    cursor: pointer;
    color: var(--dashboard-primary-bg);
  }
`;

export const Loading = styled.div.attrs((props: Props) => ({}))`
  display: table;
  margin: 2rem auto;
`;

export const Action = styled.span.attrs((props: Props) => ({}))`
  display: table;

  button {
    background: var(--dashboard-secondary);
    padding-bottom: 10px;
    border-radius: 25px;
    min-width: 120px;
  }

  @media screen and (max-width: 1290px) {
    position: relative;
    width: 100%;
    text-align: right;
    padding-top: 45px;
  }
`;

export const Modal = styled(Dialog).attrs((props: Props) => ({}))`
  .MuiDialogTitle-root {
    background: var(--dashboard-background);
    text-transform: uppercase;

    .material-icons {
      position: absolute;
      right: 10px;
      color: var(--dashboard-primary-bg);
      font-size: 34px;
      line-height: 0.8;
    }
  }

  b {
    display: block;
    margin-top: 15px;
  }

  .select-item {
    margin-top: 10px;
  }

  .react-date-picker {
    width: 100%;

    .react-date-picker__wrapper {
      margin-top: 10px;
      border-radius: 25px;
      padding: 4px 15px 7px;
      align-items: center;
      border-color: var(--dashboard-box-shadow);

      button {
        margin-top: 2px;
      }

      :hover,
      :focus {
        border-color: var(--dashboard-primary-bg);
      }
    }
  }

  .MuiTextField-root fieldset,
  .MuiInputBase-root fieldset {
    border-radius: 50px;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px;
    background: transparent;
  }

  .MuiDialogContent-root {
    padding: 20px;
  }

  .MuiDialogActions-root {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px;
    button {
      border-radius: 50px;
      padding-bottom: 5px;
      padding-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
      min-width: 120px;
      font-family: "GalanoGrotesque-SemiBold";
      font-weight: 600;
      &.cancel-button {
        background: transparent;
        color: var(--dashboard-secondary);
        padding: 0;
        padding-right: 5px;
        padding-left: 5px;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        .MuiButton-startIcon {
          align-self: center;
          margin-top: -2px;
          padding-left: 5px;
        }
      }
      &.MuiButton-containedPrimary {
        background-color: var(--dashboard-secondary);
        color: var(--white);
      }
    }
  }
`;

export const NotificationsPage = styled.div.attrs((props: Props) => ({}))`
  .block-item {
    width: 100%;
  }
`;

export const Questions = styled.div.attrs((props: Props) => ({}))`
  margin: 2rem 0;

  .MuiAccordion-rounded {
    background: var(--card-background);
  }

  .MuiAlert-root {
    width: 100%;
    background: transparent;
  }
`;

export const SettingsPage = styled.section.attrs((props: Props) => ({
  className: "settings",
}))`
  background: transparent;
  width: 100%;
  text-align: left;
  position: relative;
  padding-left: 10px;
  min-height: 380px;

  .MuiDialogTitle-root {
    background: var(--dashboard-background);
    text-transform: uppercase;

    .material-icons {
      position: absolute;
      right: 10px;
      color: var(--dashboard-primary-bg);
      font-size: 34px;
      line-height: 0.8;
    }
  }

  b {
    display: block;
    margin-top: 15px;
  }

  .select-item {
    margin-top: 10px;
  }

  .react-date-picker {
    width: 100%;

    .react-date-picker__wrapper {
      margin-top: 10px;
      border-radius: 25px;
      padding: 4px 15px 7px;
      align-items: center;
      border-color: var(--dashboard-box-shadow);

      button {
        margin-top: 2px;
      }

      :hover,
      :focus {
        border-color: var(--dashboard-primary-bg);
      }
    }
  }

  .MuiTextField-root fieldset,
  .MuiInputBase-root fieldset {
    border-radius: 50px;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 10px;
    background: transparent;
  }

  .MuiDialogContent-root {
    padding: 20px;
  }

  .MuiDialogActions-root {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 20px;
    button {
      border-radius: 50px;
      padding-bottom: 10px;
      min-width: 120px;
      font-family: "GalanoGrotesque-SemiBold";
      font-weight: 600;
      &.cancel-button {
        background: transparent;
        color: var(--dashboard-secondary);
        padding: 0;
        justify-content: flex-start;
        align-items: baseline;
        .MuiButton-startIcon {
          align-self: normal;
          margin: 0;
          margin-top: 5px;
        }
      }
      &.MuiButton-containedPrimary {
        background-color: var(--dashboard-secondary);
        color: var(--white);
      }
    }
  }
`;

export const Toggle = styled.span.attrs((props: Props) => ({}))`
  display: block;
  padding: 12px;
  .MuiSwitch-root {
    margin-top: -6px;
  }
`;

export const ManagePage = styled.div.attrs((props: Props) => ({}))`
  background: transparent;
  .flip {
    margin-top: 15px;
  }
  .select-item {
    width: 100%;
    .MuiInputLabel-outlined {
      top: -8px;
    }
    .MuiFormLabel-filled {
      top: 0;
    }
  }

  @media screen and (max-width: 579px) {
    .all-terms {
      display: flex;
      flex-direction: column;
      button {
        margin-bottom: 10px;
      }
    }
  }
`;
