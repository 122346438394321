import { AreaConfig, Location, Role, UnitSystem, Waypoint } from "biohub-model";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MapAreaComponent from "../../../components/map/impl/components/Area";
import UnitConversionHelper from "../../../core/helper/UnitConversionHelper";
import { updateEditingArea } from "../../../store/actions/projectTreeActions";
import { EditingArea, EditingAreaType } from "../../../store/reducers/projectTreeReducer";
import { SystemState } from "../../../store/reducers/systemReducer";

type Props = {
  pressingCtrlKey: boolean;
  homePointMarkerZIndex: number;
  homePointElevationMarkerZIndex: number;
  homePointElevationLabelZIndex: number;
  plannedPathZIndex: number;
  plannedPathBorderZIndex: number;
  waypointsDistanceMarkerZIndex: number;
  waypointsDistanceLabelZIndex: number;
  waypointOrientationMarkerZIndex: number;
  waypointDropMarkerZIndex: number;
  waypointElevationMarkerZIndex: number;
  waypointElevationLabelZIndex: number;
  areaPolygonZIndex: number;
  areaEditingPolygonPolylineZIndex: number;
  areaEditingPolygonMarkersZIndex: number;
  getFlightReleaseTrackZIndex: (
    areaIndex: number,
    amountOfAreas: number,
    flightIndex: number,
    amountOfFlights: number
  ) => number;
  getFlightPathZIndex: (
    areaIndex: number,
    amountOfAreas: number,
    flightIndex: number,
    amountOfFlights: number
  ) => number;
  getFlightPathBorderZIndex: (
    areaIndex: number,
    amountOfAreas: number,
    flightIndex: number,
    amountOfFlights: number
  ) => number;
};

export default function (props: Props): JSX.Element {
  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const dispatch = useDispatch();

  const mapTypeId = useSelector((state: SystemState) => {
    return state.projectTree.mapState.mapTypeId;
  });
  const editingArea = useSelector((state: SystemState) => {
    const projectTreeState = state.projectTree;

    return projectTreeState.editingArea;
  });

  if (editingArea === undefined) return <></>;

  const usingOnlineElevation = ((): boolean => {
    switch (editingArea.type) {
      case EditingAreaType.CreatingProject:
        return editingArea.creatingProject.areaConfig.mustConsiderRelief;
      case EditingAreaType.DrawingNewArea:
        return editingArea.areaConfig.mustConsiderRelief;
      case EditingAreaType.EditingEverything:
      case EditingAreaType.EditingPlanPoints:
      case EditingAreaType.EditingPolygon:
        return editingArea.area.areaConfig.mustConsiderRelief;
    }
  })();

  return (
    <MapAreaComponent
      {...props}
      polygonId={"editing-polygon"}
      areaEditionMode={editingArea.type}
      mapType={
        mapTypeId === "labeled_roadmap" || mapTypeId === "plain_roadmap" ? "roadmap" : "satellite"
      }
      isSelected={true}
      unitSystem={unitSystem}
      polygon={((): Location[] => {
        switch (editingArea.type) {
          case EditingAreaType.CreatingProject:
          case EditingAreaType.DrawingNewArea:
          case EditingAreaType.EditingEverything:
          case EditingAreaType.EditingPolygon:
            return editingArea.polygon;
          case EditingAreaType.EditingPlanPoints:
            return editingArea.area.planned.polygon;
          default:
            return [];
        }
      })()}
      homePoint={((): Location | undefined => {
        switch (editingArea.type) {
          case EditingAreaType.EditingPlanPoints:
          case EditingAreaType.EditingEverything:
            return editingArea.homePoint;
          default:
            return undefined;
        }
      })()}
      waypoints={((): Waypoint[] => {
        switch (editingArea.type) {
          case EditingAreaType.EditingEverything:
          case EditingAreaType.EditingPlanPoints:
            return editingArea.waypoints;
          default:
            return [];
        }
      })()}
      formatHeight={(value) => {
        return `${UnitConversionHelper.distanceValue(
          value,
          unitSystem,
          1
        )} ${UnitConversionHelper.distanceUnit(unitSystem)}`;
      }}
      selectedWaypointsIndexes={[]}
      onClickWaypoint={(_, waypointIndex) => {
        const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;
        if (
          newEditingArea.type === EditingAreaType.EditingPlanPoints ||
          newEditingArea.type === EditingAreaType.EditingEverything
        ) {
          newEditingArea.waypoints.splice(waypointIndex, 1);
        } else {
          return;
        }

        dispatch(updateEditingArea(newEditingArea));
      }}
      onPolygonClicked={(location) => {
        const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

        if (
          props.pressingCtrlKey &&
          (newEditingArea.type === EditingAreaType.EditingPlanPoints ||
            newEditingArea.type === EditingAreaType.EditingEverything)
        ) {
          newEditingArea.homePoint = location;
        } else if (
          newEditingArea.type === EditingAreaType.EditingPlanPoints ||
          newEditingArea.type === EditingAreaType.EditingEverything
        ) {
          const areaConfig = newEditingArea.area.areaConfig;

          newEditingArea.waypoints.push(createWaypoint(location, areaConfig));
        } else if (
          newEditingArea.type === EditingAreaType.CreatingProject ||
          newEditingArea.type === EditingAreaType.DrawingNewArea ||
          newEditingArea.type === EditingAreaType.EditingPolygon
        ) {
          newEditingArea.polygon.push(location);
        } else {
          return;
        }

        dispatch(updateEditingArea(newEditingArea));
      }}
      onVertexMoved={(index, location) => {
        const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

        if (
          newEditingArea.type === EditingAreaType.CreatingProject ||
          newEditingArea.type === EditingAreaType.DrawingNewArea ||
          newEditingArea.type === EditingAreaType.EditingPolygon ||
          newEditingArea.type === EditingAreaType.EditingEverything
        ) {
          newEditingArea.polygon[index] = location;
        } else {
          return;
        }

        dispatch(updateEditingArea(newEditingArea));
      }}
      onHomePointMoved={(location) => {
        const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

        if (
          newEditingArea.type === EditingAreaType.EditingPlanPoints ||
          newEditingArea.type === EditingAreaType.EditingEverything
        ) {
          newEditingArea.homePoint = location;
        } else {
          return;
        }

        dispatch(updateEditingArea(newEditingArea));
      }}
      onWaypointMoved={(index, location) => {
        const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

        if (
          newEditingArea.type === EditingAreaType.EditingPlanPoints ||
          newEditingArea.type === EditingAreaType.EditingEverything
        ) {
          newEditingArea.waypoints[index].location = location;
        } else {
          return;
        }

        dispatch(updateEditingArea(newEditingArea));
      }}
      onClickPlannedRoute={(segmentIndex, location) => {
        const newEditingArea = JSON.parse(JSON.stringify(editingArea)) as EditingArea;

        if (
          newEditingArea.type === EditingAreaType.EditingPlanPoints ||
          newEditingArea.type === EditingAreaType.EditingEverything
        ) {
          const areaConfig = newEditingArea.area.areaConfig;

          newEditingArea.waypoints.splice(
            segmentIndex + 1,
            0,
            createWaypoint(location, areaConfig)
          );
        } else {
          return;
        }

        dispatch(updateEditingArea(newEditingArea));
      }}
      showWaypointDistances={false}
      usingOnlineElevation={usingOnlineElevation}
    />
  );
}

const createWaypoint = (location: Location, areaConfig: AreaConfig): Waypoint => {
  return {
    location: location,
    height: areaConfig.flightHeight,
    speed: areaConfig.flightSpeed,
    orientation: 0,
    curveRadius: 0.2,
    droneActions: [],
    releaserActions: {},
    elevation: 0.0,
  };
};
