export const timeToHMS = (hours: number, minutes: number, seconds: number): string => {
  let hoursComponent = hours.toFixed(0);
  let minutesComponent = minutes.toFixed(0);
  let secondsComponent = seconds.toFixed(0);

  if (hoursComponent.length < 2) {
    hoursComponent = "0" + hoursComponent;
  }
  if (minutesComponent.length < 2) {
    minutesComponent = "0" + minutesComponent;
  }
  if (secondsComponent.length < 2) {
    secondsComponent = "0" + secondsComponent;
  }

  return hoursComponent + "h" + minutesComponent + "m" + secondsComponent + "s";
};
