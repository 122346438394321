import { CircularProgress } from "@material-ui/core";
import { BoundingBox, Location } from "biohub-model";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import React, { useState } from "react";
import Constants from "../../../Constants";
import { initialProjectTreeMapState } from "../../../store/reducers/projectTreeReducer";
import { BaseMapProps } from "../BaseMap";
import GoogleMapController from "./MapController";

const _LIBRARIES: Libraries = ["places"];

export default function MapImplGoogle(props: BaseMapProps): JSX.Element {
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);

  // Required initialization. This has to do with the react lib we're using to render the map.
  const loadScript = useLoadScript({
    region: props.region,
    language: props.languageCode,
    googleMapsApiKey: Constants.GOOGLE_MAPS_API_KEY,
    libraries: _LIBRARIES,
  });

  if (loadScript.loadError) {
    return (
      <div>
        <p>{loadScript.loadError.message}</p>
      </div>
    );
  }

  if (!loadScript.isLoaded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  let googleMapTypeId: string = "hybrid";
  switch (props.mapTypeId) {
    case "labeled_roadmap":
      googleMapTypeId = "roadmap";
      break;
    case "plain_roadmap":
      googleMapTypeId = "plain";
      break;
    case "labeled_satellite":
      googleMapTypeId = "hybrid";
      break;
    case "plain_satellite":
      googleMapTypeId = "satellite";
  }

  return (
    <>
      <GoogleMap
        tilt={45}
        heading={0}
        mapContainerStyle={{
          height: "100%",
          width: "auto",
        }}
        center={initialProjectTreeMapState.center}
        onCenterChanged={async () => {
          const newCenter = googleMapsPositionToLocation(mapRef?.getCenter());
          if (newCenter === undefined) return;

          /// Wait to check if that center will consolidate
          await delay(500);

          const actualCenter = googleMapsPositionToLocation(mapRef?.getCenter());
          if (actualCenter === undefined) return;

          if (JSON.stringify(newCenter) !== JSON.stringify(actualCenter)) return;

          props.onCurrentCenterChanged(actualCenter);
        }}
        onBoundsChanged={async () => {
          const newBounds = googleBoundsToBoundingBox(mapRef?.getBounds());
          if (newBounds === undefined) return;

          /// Wait to check if that center will consolidate
          await delay(500);

          const actualBounds = googleBoundsToBoundingBox(mapRef?.getBounds());
          if (actualBounds === undefined) return;

          if (JSON.stringify(newBounds) !== JSON.stringify(actualBounds)) return;

          props.onMapBoundsChanged(actualBounds);
        }}
        options={{
          zoomControl: false,
          mapTypeId: googleMapTypeId,
          mapTypeControl: false,
          fullscreenControl: false,
          scaleControl: true,
          streetViewControl: false,
          panControl: false,
          rotateControl: false,
          tilt: 0,
          keyboardShortcuts: false,
          minZoom: 2,
          isFractionalZoomEnabled: true,
        }}
        mapTypeId={googleMapTypeId}
        zoom={initialProjectTreeMapState.zoom}
        onZoomChanged={() => {
          const zoom = mapRef?.getZoom();

          if (zoom !== undefined) {
            props.onZoomChanged(zoom);
          }
        }}
        onLoad={(mapRef) => {
          props.onInitialized(new GoogleMapController(mapRef));
          setMapRef(mapRef);

          const plainRoadmapCustomMapType = new google.maps.StyledMapType(
            [{ featureType: "all", elementType: "labels", stylers: [{ visibility: "off" }] }],
            { name: "Plain Roadmap" }
          );
          // Add our custom roadmap without labels.
          mapRef.mapTypes.set("plain", plainRoadmapCustomMapType);
        }}
        onClick={(e) => {
          const latLng = e.latLng;
          if (latLng === null) return;

          props.onClick?.({
            lat: latLng.lat(),
            lng: latLng.lng(),
          });
        }}
      >
        {props.children}
      </GoogleMap>
    </>
  );
}

function delay(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

function googleMapsPositionToLocation(
  position: google.maps.LatLng | undefined
): Location | undefined {
  if (position === undefined) return undefined;

  return {
    lat: position.lat(),
    lng: position.lng(),
  };
}

function googleBoundsToBoundingBox(
  bounds: google.maps.LatLngBounds | undefined
): BoundingBox | undefined {
  if (bounds === undefined) return undefined;

  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  return {
    north: northEast.lat(),
    south: southWest.lat(),
    east: northEast.lng(),
    west: southWest.lng(),
  };
}
