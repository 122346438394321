import styled from "styled-components";

type Props = {
  color?: "light" | "primary" | "secondary";
  size?: "small" | "medium" | "large";
  textAlign?: "center" | "left" | "right";
  appearance?: "title-semibold" | "subtitle-semibold" | "subtitle-medium";
};

export const Tag = styled.span.attrs((props: Props) => ({
  color: props.color,
  size: props.size,
  appearance: props.appearance,
}))`
  display: block;
  white-space: pre-line;
  color: var(--tag-text-color);
  font-size: 24px;
  margin: 1rem 0 3rem;
  font-family: "GalanoGrotesque-Medium";

  ${(props: Props) =>
    props.color === "light" &&
    `
    color: var(--white);
    border-color: var(--white);
  `}

  ${(props: Props) =>
    props.color === "primary" &&
    `
    color: var(--primary);
  `}

${(props: Props) =>
    props.color === "secondary" &&
    `
    color: var(--secondary);
  `}

  ${(props: Props) =>
    props.size === "small" &&
    `
    font-size: 12px;
  `}

  ${(props: Props) =>
    props.size === "medium" &&
    `
    font-size: 22px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  `}

  ${(props: Props) =>
    props.size === "large" &&
    `
    font-size: 38px;
    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  `}

${(props: Props) =>
    props.textAlign === "center" &&
    `
    text-align: center;
  `}

${(props: Props) =>
    props.textAlign === "right" &&
    `
    text-align: right;
  `}

${(props: Props) =>
    props.appearance === "subtitle-semibold" &&
    `
    line-height: 1;
    font-size: 24px !important;
    font-family: "GalanoGrotesque-SemiBold";

    @media screen and (max-width: 680px) {
      font-size: 20px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 18px !important;
    } 
  `}

${(props: Props) =>
    props.appearance === "subtitle-medium" &&
    `
    line-height: 1;
    font-size: 24px !important;
    font-family: "GalanoGrotesque-Medium";

    @media screen and (max-width: 680px) {
      font-size: 20px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 18px !important;
    } 

  `}

${(props: Props) =>
    props.appearance === "title-semibold" &&
    props.size === "medium" &&
    `
    line-height: 1;
    font-size: 34px !important;
    font-family: "GalanoGrotesque-SemiBold";

    @media screen and (max-width: 1200px) {
      font-size: 28px !important;
    } 

    @media screen and (max-width: 680px) {
      font-size: 24px !important;
    } 

    @media screen and (max-width: 480px) {
      font-size: 20px !important;
    }
  `}
`;
