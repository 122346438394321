import styled from "styled-components";
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type Props = {};

export const Carousel = styled(Slider).attrs((props: Props) => ({}))`
  margin: 3rem 0 0;

  li {
    margin: 2px;
  }

  .react-multiple-carousel__arrow {
    background: var(--background-default);
    border: 3px solid var(--primary);
    :before {
      color: var(--primary);
    }
    :hover,
    :focus {
      background: var(--secondary);
    }
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }
`;
