import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import React, { useState } from "react";
import { AreaInProjectTree, ProjectInProjectTree } from "../../store/reducers/projectTreeReducer";
import { TextField } from "../Atomic/Inputs/Field/styles";
import SearchImage from "../../assets/icon/icon_search_thick_without_circle.svg";
import PlusIcon from "../../assets/icon/icon_orange_plus.svg";

import { useIntl } from "react-intl";
import ListProjectsInProjectTree from "./ListProjectsInProjectTree";

type Props = {
  onClickToCreateProject: () => void;
  onClickToEditProjectSettings: (project: ProjectInProjectTree) => void;
  onClickToAddAreaInProject: (projectId: ProjectInProjectTree) => void;
  onClickToDeleteProject: (project: ProjectInProjectTree) => void;
  onClickToEditAreaSettings: (area: AreaInProjectTree) => void;
  onClickToDeleteArea: (area: AreaInProjectTree) => void;
  onClickDuplicateArea: (area: AreaInProjectTree) => void;
  onClickCopyArea: (area: AreaInProjectTree) => void;
  onClickPasteCopiedArea: (project: ProjectInProjectTree) => void;
  height: number;
};

function ProjectOverlay(props: Props): JSX.Element {
  const [filter, setFilter] = useState("");

  /// For handling the search label's shrink property.
  const [searchFocus, setSearchFocus] = useState<boolean>(false);

  const intl = useIntl();

  return (
    <>
      <ThemeProvider theme={theme}>
        <TextField
          label={
            filter.length > 0
              ? ""
              : `${searchFocus ? "" : "   "}${intl.formatMessage({
                  id: "placeholder.search",
                })}`
          }
          onFocus={(_) => {
            setSearchFocus(true);
          }}
          onBlur={(_) => {
            setSearchFocus(false);
          }}
          InputLabelProps={{ shrink: searchFocus }}
          color="secondary"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          variant="outlined"
          size="small"
          style={{
            position: "absolute",
            top: "12px",
            left: "10px",
            right: "100px",
          }}
          InputProps={{
            startAdornment: (
              <img
                src={SearchImage}
                style={{ height: 18, width: 18 }}
                alt={"searchfield-start-adornment"}
              />
            ),
          }}
          inputProps={{
            style: { color: "black" },
          }}
        />
      </ThemeProvider>

      {/* Add New Project Button */}
      <div
        onClick={(_) => {
          props.onClickToCreateProject();
        }}
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "12px",
          right: "40px",
          marginRight: "10px",
        }}
      >
        <img
          src={PlusIcon}
          style={{ height: "35px", width: "35px" }}
          alt={"add-project-plus-icon"}
        />
      </div>

      {/* List of projects */}
      <ListProjectsInProjectTree
        height={props.height}
        onClickToAddAreaInProject={props.onClickToAddAreaInProject}
        onClickToEditAreaSettings={props.onClickToEditAreaSettings}
        onClickToEditProjectSettings={props.onClickToEditProjectSettings}
        onClickToDeleteArea={props.onClickToDeleteArea}
        onClickToDeleteProject={props.onClickToDeleteProject}
        onClickDuplicateArea={props.onClickDuplicateArea}
        onClickCopyArea={props.onClickCopyArea}
        onClickPasteCopiedArea={props.onClickPasteCopiedArea}
        searchFilter={filter}
      />
    </>
  );
}

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#ef7622",
    },
    text: {
      primary: "black",
      hint: "var(--dashboard-secondary)",
      secondary: "var(--dashboard-secondary)",
    },
  },
});

function compareProjectOverlayProps(prevProps: Props, newProps: Props): boolean {
  if (prevProps.height !== newProps.height) return false;
  return true;
}

export default React.memo(ProjectOverlay, compareProjectOverlayProps);
