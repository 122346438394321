import React from "react";
import Tag from "../BasicComponents/Tag";
import Text from "../BasicComponents/Text";
import Container from "../Areas/Container";
import { Section } from "./styles";

interface Props {
  sectionTitle?: string;
  sectionColor?: "light" | "primary" | "mixed" | "dark-gray" | "white" | "light-gray";
  appearence?: "section-carousel";
  category?: string;
  direction?: string;
  children?: React.ReactNode;
  title?: string;
  description?: string;
  image?: string;
  textEndSection?: string;
  imageSeparatorPlus?: string;
  id?: string;
  textAlign?: "center";
  sectionTitleMark?: boolean;
  appearanceText?: "title-semibold" | "subtitle-semibold" | "subtitle-medium";
  sizeText?: "small" | "medium" | "large";
}

export default (props: Props) => {
  return (
    <>
      <Section sectionColor={props.sectionColor} appearence={props.appearence} id={props.id}>
        <Container>
          {props.sectionTitle && (
            <Text
              text={props.sectionTitle}
              appearance={"title-semibold"}
              sectionTitleMark={props.sectionTitleMark}
              textAlign={props.textAlign}
            />
          )}
          {props.category && (
            <Tag
              text={props.category}
              appearance={props.appearanceText}
              textAlign={props.textAlign}
              size={props.sizeText}
            />
          )}
          {props.children}
        </Container>
      </Section>
    </>
  );
};
