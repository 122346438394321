import { Box, Grid, Button as MuiButton } from "@material-ui/core";
import React from "react";
import CartCard from "../../../components/Atomic/CartCard";
import Text from "../../../components/Atomic/BasicComponents/Text";
import MuiBox from "@mui/material/Box";
import { Stack } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, Delete, ShoppingCart } from "@material-ui/icons";
import styled from "styled-components";
import { formatPrice } from "../../../services/FormValidatorsService";
import { useIntl } from "react-intl";
import {
  AvailableSolution,
  ProvisoryCartSolution,
} from "../../../store/reducers/provisoryCartReducer";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import { Price } from "../../../services/provisoryCartSolutionsService";
import { BiohubRegion } from "../../../store/reducers/localeReducer";

export default (props: {
  cartItems: ProvisoryCartSolution[];
  bioHUBAndBioMAPSInCart: boolean;
  availableItems: AvailableSolution[];
  actualSolutionId: string;
  onAddItem: (quantity: number) => void;
  onRemoveItem: (quantity: number) => void;
  clearCart: () => void;
}): JSX.Element => {
  const intl = useIntl();

  const usageRegion = useSelector((state: SystemState) => state.locale.usageRegion);

  const groups: {
    info: {
      cartItem: ProvisoryCartSolution | undefined;
      availableSolution: AvailableSolution;
    }[];
    amount: number;
    mandatory: boolean;
  }[] = [];

  const bioHUBSolution = props.availableItems.find((item) => item.id === "bioHUB");
  const bioMAPSSolution = props.availableItems.find((item) => item.id === "bioMAPS");

  if (bioHUBSolution !== undefined && bioMAPSSolution !== undefined) {
    groups.push({
      info: [
        {
          availableSolution: bioHUBSolution,
          cartItem: props.cartItems.find((solution) => solution.id === "bioHUB"),
        },
        {
          availableSolution: bioMAPSSolution,
          cartItem: props.cartItems.find((solution) => solution.id === "bioMAPS"),
        },
      ],
      amount: props.bioHUBAndBioMAPSInCart ? 1 : 0,
      mandatory: true,
    });
  }

  for (const availableItem of props.availableItems.filter(
    (item) => item.id === props.actualSolutionId
  )) {
    const cartItem = props.cartItems.find((solution) => solution.id === availableItem.id);
    groups.push({
      info: [
        {
          availableSolution: availableItem,
          cartItem: cartItem,
        },
      ],
      amount: cartItem?.amount ?? 0,
      mandatory: false,
    });
  }

  return (
    <>
      {groups.map((groupOfItems) => {
        let sectionContainer: JSX.Element | undefined;
        let itemContainer: JSX.Element;
        let unitaryValueContainer: JSX.Element | undefined;
        let amountContainer: JSX.Element | undefined;
        let totalValueContainer: JSX.Element | undefined;
        let generalAddOrRemoveContainer: JSX.Element | undefined;

        if (groupOfItems.info.length === 1) {
          const item = groupOfItems.info[0];

          const availableSolution = item.availableSolution;
          const cartItem = item.cartItem;

          itemContainer = (
            <CartCard isActive={availableSolution.id === props.actualSolutionId}>
              {availableSolution.name}
            </CartCard>
          );
          unitaryValueContainer = (
            <PriceComponent
              buyPrice={getPriceConsideringRegion(availableSolution.buyPrice, usageRegion)}
              earlyRentPrice={getPriceConsideringRegion(availableSolution.earlyPrice, usageRegion)}
              monthlyRentPrice={getPriceConsideringRegion(
                availableSolution.monthlyPrice,
                usageRegion
              )}
              hectarePerMonthPrice={getPriceConsideringRegion(
                availableSolution.hectarePerMonthPrice,
                usageRegion
              )}
              major={false}
            />
          );

          amountContainer = (
            <AmountComponent
              mandatory={
                availableSolution.id === bioHUBSolution?.id ||
                availableSolution.id === bioMAPSSolution?.id
              }
              amount={groupOfItems.amount}
              onAddItem={() => props.onAddItem(1)}
              onRemoveItem={() => props.onRemoveItem(1)}
            />
          );

          totalValueContainer = (
            <PriceComponent
              buyPrice={getPriceConsideringRegion(cartItem?.buyTotalPrice, usageRegion)}
              earlyRentPrice={getPriceConsideringRegion(cartItem?.earlyTotalPrice, usageRegion)}
              monthlyRentPrice={getPriceConsideringRegion(cartItem?.monthlyTotalPrice, usageRegion)}
              hectarePerMonthPrice={getPriceConsideringRegion(
                cartItem?.hectarePerMonthPriceTotalPrice,
                usageRegion
              )}
              major={true}
            />
          );
          if (!groupOfItems.mandatory) {
            generalAddOrRemoveContainer =
              groupOfItems.amount === 0 ? (
                <StyledButton
                  onClick={(e: any) => {
                    props.onAddItem(1);
                  }}
                >
                  <ShoppingCart />
                </StyledButton>
              ) : (
                <RemoveButton
                  onClick={() => {
                    props.onRemoveItem(groupOfItems.amount);
                  }}
                >
                  <Delete />
                </RemoveButton>
              );
            sectionContainer = <Text text={intl.formatMessage({ id: "cart.product" })} />;
          } else {
            sectionContainer = <Text text={intl.formatMessage({ id: "cart.alreadyIncluded" })} />;
          }
        } else {
          itemContainer = (
            <CartCard isActive={false}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {groupOfItems.info.map((item, index) => (
                  <>
                    <CartCard isActive={false}>{item.availableSolution.name}</CartCard>
                    {index !== groupOfItems.info.length - 1 && <Text text="+" />}
                  </>
                ))}
              </div>
            </CartCard>
          );
          unitaryValueContainer = (
            <PriceComponent
              buyPrice={groupOfItems.info
                .map((info) =>
                  getPriceConsideringRegion(info.availableSolution.buyPrice, usageRegion)
                )
                .reduce(sumUndefinedValues)}
              earlyRentPrice={groupOfItems.info
                .map((info) =>
                  getPriceConsideringRegion(info.availableSolution.earlyPrice, usageRegion)
                )
                .reduce(sumUndefinedValues)}
              monthlyRentPrice={groupOfItems.info
                .map((info) =>
                  getPriceConsideringRegion(info.availableSolution.monthlyPrice, usageRegion)
                )
                .reduce(sumUndefinedValues)}
              hectarePerMonthPrice={groupOfItems.info
                .map((info) =>
                  getPriceConsideringRegion(
                    info.availableSolution.hectarePerMonthPrice,
                    usageRegion
                  )
                )
                .reduce(sumUndefinedValues)}
              major={false}
            />
          );

          if (groupOfItems.mandatory) {
            sectionContainer = <Text text={intl.formatMessage({ id: "cart.alreadyIncluded" })} />;
          } else {
            sectionContainer = <Text text={intl.formatMessage({ id: "cart.product" })} />;
          }

          amountContainer = (
            <AmountComponent
              mandatory={groupOfItems.mandatory}
              amount={groupOfItems.amount}
              onAddItem={() => props.onAddItem(1)}
              onRemoveItem={() => props.onRemoveItem(1)}
            />
          );

          if (!groupOfItems.mandatory) {
            generalAddOrRemoveContainer =
              groupOfItems.amount === 0 ? (
                <StyledButton
                  onClick={(e: any) => {
                    props.onAddItem(1);
                  }}
                >
                  <ShoppingCart />
                </StyledButton>
              ) : (
                <RemoveButton
                  onClick={() => {
                    props.onRemoveItem(groupOfItems.amount);
                  }}
                >
                  <Delete />
                </RemoveButton>
              );
          }

          totalValueContainer = (
            <PriceComponent
              buyPrice={groupOfItems.info
                .map((info) => getPriceConsideringRegion(info.cartItem?.buyTotalPrice, usageRegion))
                .reduce(sumUndefinedValues)}
              earlyRentPrice={groupOfItems.info
                .map((info) =>
                  getPriceConsideringRegion(info.cartItem?.earlyTotalPrice, usageRegion)
                )
                .reduce(sumUndefinedValues)}
              monthlyRentPrice={groupOfItems.info
                .map((info) =>
                  getPriceConsideringRegion(info.cartItem?.monthlyTotalPrice, usageRegion)
                )
                .reduce(sumUndefinedValues)}
              hectarePerMonthPrice={groupOfItems.info
                .map((info) =>
                  getPriceConsideringRegion(
                    info.cartItem?.hectarePerMonthPriceTotalPrice,
                    usageRegion
                  )
                )
                .reduce(sumUndefinedValues)}
              major={true}
            />
          );
        }

        return (
          <ItemRow
            sectionContainer={sectionContainer}
            itemContainer={itemContainer}
            unitaryValueContainer={unitaryValueContainer}
            amountContainer={amountContainer}
            totalValueContainer={totalValueContainer}
            generalAddOrRemoveContainer={generalAddOrRemoveContainer}
          />
        );
      })}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingRight: "40px",
          paddingTop: "40px",
        }}
      >
        <CartCard isActive={true}>{intl.formatMessage({ id: "info.total" })}</CartCard>
        <PriceComponent
          buyPrice={groups
            .map(
              (group) =>
                group.info
                  .map((info) =>
                    getPriceConsideringRegion(info.cartItem?.buyTotalPrice, usageRegion)
                  )
                  .reduce(sumUndefinedValues) ?? 0
            )
            .reduce((a, b) => a + b)}
          earlyRentPrice={groups
            .map(
              (group) =>
                group.info
                  .map((info) =>
                    getPriceConsideringRegion(info.cartItem?.earlyTotalPrice, usageRegion)
                  )
                  .reduce(sumUndefinedValues) ?? 0
            )
            .reduce((a, b) => a + b)}
          monthlyRentPrice={groups
            .map(
              (group) =>
                group.info
                  .map((info) =>
                    getPriceConsideringRegion(info.cartItem?.monthlyTotalPrice, usageRegion)
                  )
                  .reduce(sumUndefinedValues) ?? 0
            )
            .reduce((a, b) => a + b)}
          hectarePerMonthPrice={groups
            .map(
              (group) =>
                group.info
                  .map((info) =>
                    getPriceConsideringRegion(
                      info.cartItem?.hectarePerMonthPriceTotalPrice,
                      usageRegion
                    )
                  )
                  .reduce(sumUndefinedValues) ?? 0
            )
            .reduce((a, b) => a + b)}
          major={true}
        />
        <RemoveButton
          onClick={() => {
            props.clearCart();
          }}
        >
          <Delete />
        </RemoveButton>
      </div>
    </>
  );
};

const ItemRow = (props: {
  sectionContainer: JSX.Element | undefined;
  itemContainer: JSX.Element | undefined;
  unitaryValueContainer: JSX.Element | undefined;
  amountContainer: JSX.Element | undefined;
  totalValueContainer: JSX.Element | undefined;
  generalAddOrRemoveContainer: JSX.Element | undefined;
}): JSX.Element => {
  return (
    <>
      {props.sectionContainer !== undefined && (
        <div
          style={{
            paddingTop: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.sectionContainer}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px 10px 10px 10px",
        }}
      >
        <Grid item md={5}>
          <div
            style={{
              transform: "scale(0.85)",
            }}
          >
            {props.itemContainer ?? <Box />}
          </div>
        </Grid>
        <Grid item md={2}>
          <div
            style={{
              transform: "scale(0.85)",
            }}
          >
            {props.unitaryValueContainer ?? <Box />}
          </div>
        </Grid>
        <Grid item md={1}>
          <div
            style={{
              transform: "scale(0.85)",
            }}
          >
            {props.amountContainer ?? <Box />}
          </div>
        </Grid>
        <Grid item md={2}>
          <div
            style={{
              transform: "scale(0.85)",
            }}
          >
            {props.totalValueContainer ?? <Box />}
          </div>
        </Grid>
        <Grid item md={2}>
          <div
            style={{
              transform: "scale(0.85)",
            }}
          >
            {props.generalAddOrRemoveContainer ?? <Box />}
          </div>
        </Grid>
      </div>
    </>
  );
};

const PriceComponent = (props: {
  buyPrice: number | undefined;
  earlyRentPrice: number | undefined;
  monthlyRentPrice: number | undefined;
  hectarePerMonthPrice: number | undefined;
  major: boolean;
}): JSX.Element => {
  const cardBackgroundColor = props.major ? "#1d4f90" : "#000000";
  const opacity = props.major ? 1.0 : 0.5;

  const intl = useIntl();

  if (
    props.buyPrice === undefined &&
    props.earlyRentPrice === undefined &&
    props.monthlyRentPrice === undefined &&
    props.hectarePerMonthPrice === undefined
  )
    return <></>;

  return (
    <MuiBox
      borderRadius="10px"
      sx={{
        background: cardBackgroundColor,
        paddingY: " .75rem",
        paddingX: "1rem",
        opacity: opacity,
      }}
    >
      <Stack
        direction="column"
        textAlign="left"
        sx={{
          textJustify: "inter-word",
          fontSize: 13,
        }}
      >
        <Text
          color="light"
          text={props.buyPrice !== undefined ? formatPrice("pt-BR", props.buyPrice) : ""}
        />
        <Text
          color="light"
          text={
            props.earlyRentPrice !== undefined
              ? `${formatPrice("es", props.earlyRentPrice)} /${intl.formatMessage({id: "general.year"})}`
              : ""
          }
        />
        <Text
          color="light"
          text={
            props.monthlyRentPrice !== undefined
              ? `${formatPrice("es", props.monthlyRentPrice)} /${intl.formatMessage({id: "general.month"})}`
              : ""
          }
        />
        <Text
          color="light"
          text={
            props.hectarePerMonthPrice !== undefined
              ? `${formatPrice("es", props.hectarePerMonthPrice)} /${intl.formatMessage({id: "general.hectares"})} /${intl.formatMessage({id: "general.month"})}`
              : ""
          }
        />
      </Stack>
    </MuiBox>
  );
};

const AmountComponent = (props: {
  mandatory: boolean;
  amount: number;
  onAddItem: () => void;
  onRemoveItem: () => void;
}): JSX.Element => {
  const canAdd = props.mandatory ? false : true;
  const canRemove = props.mandatory || props.amount === 0 ? false : true;

  return (
    <Stack direction="column" alignItems="center">
      <MuiButton
        style={{
          opacity: canAdd ? 1.0 : 0.3,
        }}
        onClick={() => {
          if (!canAdd) return;

          props.onAddItem();
        }}
      >
        <ArrowDropUp />
      </MuiButton>
      <MuiBox>
        <Text text={String(props.amount)} />
      </MuiBox>
      <MuiButton
        style={{
          opacity: canRemove ? 1.0 : 0.3,
        }}
        onClick={() => {
          if (!canRemove) return;

          props.onRemoveItem();
        }}
      >
        <ArrowDropDown />
      </MuiButton>
    </Stack>
  );
};

const getPriceConsideringRegion = (
  price: Price | undefined,
  region: BiohubRegion
): number | undefined => {
  if (price === undefined) return undefined;

  switch (region) {
    case "brazil":
      return price.brazilPrice;
    case "es":
      return price.europePrice;
    case "other":
      return price.otherPrice;
  }
};

const sumUndefinedValues = (a: number | undefined, b: number | undefined): number | undefined => {
  if (a === undefined && b === undefined) return undefined;
  if (a !== undefined && b !== undefined) return a + b;
  return a ?? b;
};

const StyledButton = styled.button`
  background: #ef7622;
  padding: 0.75rem 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RemoveButton = styled.button`
  padding: 0.75rem 1rem;
  border-radius: 10px;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
`;
