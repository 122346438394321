import styled from "styled-components";

type Props = {
  appearance?: "user-info";
};

export const UserAvatar = styled.div.attrs((props: Props) => ({
  className: "user-avatar",
}))`
  display: flex;
  align-items: center;
  letter-spacing: 0.65px;
  column-gap: 10px;

  @media screen and (max-width: 768px) {
    column-gap: 0;
  }

  label {
    font-family: "GalanoGrotesque-SemiBold";
    line-height: 1.4;

    @media screen and (max-width: 1300px) {
      font-size: 14px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }

    @media screen and (max-width: 991px) {
      font-size: 10px;
    }
  }

  ${(props: Props) =>
    props.appearance === "user-info" &&
    `
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 5rem;

      label:nth-of-type(1) { 
        font-size: 36px;
        line-height: 1;
        white-space: normal;
        color: var(--primary);
      }
      label:nth-of-type(2) { 
        font-size: 30px;
        line-height: 1;
        font-family: "GalanoGrotesque-Semibold";
        color: var(--dashboard-highlighted);
      }
      > span {
        display: none;
        pointer-events: none;
      } 

      li {
        padding: 0;
        a {
          width: 100%;
          padding: 5px 10px;
        }
      }

      @media screen and (max-width: 1300px) {
        margin-bottom: 2.5rem;
        label:nth-of-type(1) { 
          font-size: 32px;
        }
        label:nth-of-type(2) { 
          font-size: 26px; 
        }
      }

      @media screen and (max-width: 1200px) {
        label:nth-of-type(1) { 
          font-size: 26px;
        }
        label:nth-of-type(2) { 
          font-size: 24px; 
        }
      }

      @media screen and (max-width: 680px) {
        margin-bottom: 0;
        label:nth-of-type(1) { 
          font-size: 20px;
        }
        label:nth-of-type(2) { 
          font-size: 16px; 
        }
      }

  `}
`;
