import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  AreaConfig,
  Input,
  Profile,
  Releaser,
  ReleaseRateUnit,
  ReleaserModel,
  Role,
  UnitSystem,
} from "biohub-model";
import { useSelector } from "react-redux";
import { SystemState } from "../../../../store/reducers/systemReducer";
import { useIntl } from "react-intl";
import { TextField } from "../Field/styles";
import PlusIcon from "../../../../assets/icon/icon_orange_plus.svg";
import { DeleteForever, ExpandMore } from "@material-ui/icons";
import CompleteNumberEditor, { ColorScheme, GridScheme } from "../CompleteNumberEditor/default";
import UnitConversionHelper from "../../../../core/helper/UnitConversionHelper";
import ReleaserModelHelper from "../../../../core/helper/ReleaserModelHelper";
import InstantaneousReleaseRate from "./containers/InstantaneousReleaseRate";

/** Type to be used with the releaser config editor.
 * It's similar to a ReleaserConfiguration, but makes it easier to be used because the fields are
 * real objects, and they're nullable so they can be used in dropdowns.
 * */
export type ReleaserConfigEditorItem = {
  releaser: Releaser | null;
  input: Input | null;
  releaseRate: number;
  calibrationFactor?: number;
};

interface Props {
  releaserConfigurations: ReleaserConfigEditorItem[];
  onConfigurationChanged: (value: ReleaserConfigEditorItem[]) => void;
  // For calculating and displaying the instantaneous release rate in real time.
  pathParameters: AreaConfig;

  colorScheme: ColorScheme;
  gridScheme: GridScheme;

  validateValue: (valid: boolean) => void;
}

export default (props: Props) => {
  const intl = useIntl();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const releasers = useSelector((state: SystemState) => state.collections.releasers);
  const userProfile = useSelector((state: SystemState) => state.profile.userProfile);

  useEffect(() => {
    if (props.releaserConfigurations.length === 0) {
      props.validateValue(false);
    }
  }, [props.releaserConfigurations.length]);

  return (
    <>
      <div style={{ paddingBottom: 4 }}>
        <Grid item xs={12}>
          <Typography>{intl.formatMessage({ id: "map.newProject.releaser" })}:</Typography>
          {props.releaserConfigurations.length === 0 && (
            <>
              <img
                onClick={(_) => {
                  setOpenDropdown(true);
                }}
                src={PlusIcon}
                style={{ height: 28, width: 28, cursor: "pointer" }}
              />
              <Select
                MenuProps={{
                  disablePortal: true,
                }}
                value={""} //Bug fix
                open={openDropdown}
                onBlur={(e) => setOpenDropdown(false)}
                onClose={(e) => setOpenDropdown(false)}
                style={{ width: "0px" }}
                variant="standard"
                renderValue={(_) => <div />}
                onChange={(e) => {
                  const releaserId = e.target.value as string;
                  const releaser = releasers.find((r) => r.id === releaserId);
                  // Add an empty configuration.
                  props.onConfigurationChanged(
                    props.releaserConfigurations.concat([
                      {
                        releaser: releaser ?? null,
                        input: null,
                        releaseRate: 10,
                        calibrationFactor:
                          releaser !== undefined &&
                          ReleaserModelHelper.releaserModelHasCalibrationFactor(
                            releaser.releaserModelId
                          )
                            ? 1
                            : undefined,
                      },
                    ])
                  );
                  setOpenDropdown(false);
                }}
                IconComponent={() => <div />}
              >
                {releasers.map((r) => (
                  <MenuItem value={r.id}>{getReleaserLabel(r, userProfile)}</MenuItem>
                ))}
              </Select>
            </>
          )}
        </Grid>
        {props.releaserConfigurations.map((config, index) => (
          <Item
            itemIndex={index}
            releaserConfiguration={config}
            onConfigurationChanged={(config) => {
              const newArray = props.releaserConfigurations.slice();
              newArray[index] = config;
              props.onConfigurationChanged(newArray);
            }}
            onDeleted={() => {
              const newArray = props.releaserConfigurations.slice();
              newArray.splice(index, 1);
              props.onConfigurationChanged(newArray);
            }}
            deletable={props.releaserConfigurations?.length > 1}
            pathParameters={props.pathParameters}
            colorScheme={props.colorScheme}
            gridScheme={props.gridScheme}
            validateValue={props.validateValue}
          />
        ))}
      </div>
    </>
  );
};

interface ItemProps {
  itemIndex: number;
  releaserConfiguration: ReleaserConfigEditorItem;
  onConfigurationChanged: (value: ReleaserConfigEditorItem) => void;
  onDeleted: () => void;
  deletable: boolean;
  pathParameters: AreaConfig;
  colorScheme: ColorScheme;
  gridScheme: GridScheme;

  validateValue: (valid: boolean) => void;
}

function Item(props: ItemProps) {
  const selectedReleaser = props.releaserConfiguration.releaser;
  const selectedInput = props.releaserConfiguration.input;

  const selectedReleaserModel = useSelector((state: SystemState) =>
    state.collections.releaserModels.find((model) => model.id === selectedReleaser?.releaserModelId)
  );

  const releasers = useSelector((state: SystemState) => state.collections.releasers);
  const releaserModels = useSelector((state: SystemState) => state.collections.releaserModels);
  const inputs = useSelector((state: SystemState) =>
    selectedReleaser !== null
      ? ReleaserModelHelper.releaserModelInputs(
          selectedReleaser.releaserModelId,
          state.collections.inputs
        )
      : []
  );
  const userProfile = useSelector((state: SystemState) => state.profile.userProfile);

  const intl = useIntl();

  const unitSystem: UnitSystem = useSelector((state: SystemState) => {
    if (state.profile.userProfile !== null && state.profile.userProfile.role !== Role.external) {
      return state.profile.userProfile.preferences.unitSystem;
    }
    return UnitSystem.metric;
  });

  const volumePerSpaceConversionFactor = UnitConversionHelper.volumePerSpaceConversion(unitSystem);
  const cupPerSpaceConversionFactor = UnitConversionHelper.cupPerSpaceConversion(unitSystem);
  const releaseRateInterval = UnitConversionHelper.releaseRateInterval(
    props.releaserConfiguration.releaser?.releaserModelId ?? "",
    releaserModels
  );

  const conversionFactor =
    selectedReleaserModel === undefined
      ? undefined
      : selectedReleaserModel.releaseRateUnit === ReleaseRateUnit.volume
      ? volumePerSpaceConversionFactor
      : cupPerSpaceConversionFactor;

  const volumePerSpaceUnit = UnitConversionHelper.volumePerSpaceUnit(unitSystem);
  const cupPerSpaceUnit = UnitConversionHelper.cupPerSpaceUnit(unitSystem);
  const releaseRateUnitString =
    selectedReleaserModel?.releaseRateUnit === ReleaseRateUnit.cup
      ? cupPerSpaceUnit
      : volumePerSpaceUnit;

  const [isValidReleaseRate, setIsValidReleaseRate] = useState<boolean>(false);
  const [isValidCalibrationFactor, setIsValidCalibrationFactor] = useState<boolean>(true);
  const [isValidInput, setIsValidInput] = useState<boolean>(false);
  useEffect(() => {
    setIsValidInput(selectedInput !== null);
  }, [selectedInput?.id ?? ""]);

  useEffect(() => {
    props.validateValue(isValidReleaseRate && isValidCalibrationFactor && isValidInput);
  }, [isValidReleaseRate, isValidCalibrationFactor, isValidInput]);

  return (
    <>
      <Grid item xs={1}>
        <b style={{ color: "black", textAlign: "center" }}>{props.itemIndex + 1}</b>
      </Grid>
      <Grid container xs={11}>
        <>
          <Grid item xs={5}>
            <FormControl className="select-dropdown" style={{ color: "black" }} variant="outlined">
              <InputLabel id="select-dropdown-releaser">
                {intl.formatMessage({ id: "map.newProject.releaser" })}
              </InputLabel>
              <Select
                labelId="select-dropdown-releaser"
                MenuProps={{
                  disablePortal: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                style={{ width: "190px", fontSize: 15 }}
                variant="outlined"
                label={intl.formatMessage({ id: "map.newProject.releaser" })}
                value={selectedReleaser?.id ?? ""}
                onChange={(e) => {
                  const releaserId = e.target.value as string;
                  const releaser = releasers.find((r) => r.id === releaserId);
                  const newConfig = {
                    ...props.releaserConfiguration,
                    releaser: releaser,
                  } as ReleaserConfigEditorItem;
                  props.onConfigurationChanged(newConfig);
                }}
                // IconComponent={() => <ExpandMore style={{ marginRight: 7 }} htmlColor="black" />}
              >
                {releasers.map((r) => (
                  <MenuItem value={r.id}>{getReleaserLabel(r, userProfile)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className="select-dropdown" variant="outlined">
              <InputLabel id="select-dropdown-input">
                {intl.formatMessage({ id: "map.newProject.input" })}
              </InputLabel>
              <Select
                labelId="select-dropdown-input"
                MenuProps={{
                  disablePortal: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                style={{ width: "210px", fontSize: 15 }}
                variant="outlined"
                label={intl.formatMessage({ id: "map.newProject.input" })}
                // value={selectedInput?.id ?? ""}
                value={selectedInput !== null ? selectedInput.id : "   "}
                onChange={(e) => {
                  const inputId = e.target.value as string;
                  const input = inputs.find((i) => i.id === inputId);
                  const newConfig = {
                    ...props.releaserConfiguration,
                    input: input,
                  } as ReleaserConfigEditorItem;
                  props.onConfigurationChanged(newConfig);
                }}
                // IconComponent={() => <ExpandMore style={{ marginRight: 7 }} htmlColor="black" />}
              >
                {inputs.map((i) => (
                  <MenuItem value={i.id}>{i.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={1}>
            {/* {props.deletable && ( */}
            <div
              onMouseUp={(_) => {
                props.onDeleted();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <DeleteForever color="error" />
            </div>
            {/* )} */}
          </Grid>
          <Grid xs={12}>
            <Box height={10} />
          </Grid>
          <Grid xs={12} justify="center">
            <CompleteNumberEditor
              initialValue={props.releaserConfiguration.releaseRate}
              unitText={releaseRateUnitString}
              label={intl.formatMessage({ id: "map.newProject.releaseRate" })}
              minValue={releaseRateInterval.minimum}
              maxValue={releaseRateInterval.maximum}
              inComingConversionFactor={conversionFactor?.incoming ?? 1}
              outComingConversionFactor={conversionFactor?.outgoing ?? 1}
              onChange={(n) =>
                props.onConfigurationChanged({
                  ...props.releaserConfiguration,
                  releaseRate: n,
                })
              }
              validateValue={setIsValidReleaseRate}
              colorScheme={props.colorScheme}
              gridScheme={props.gridScheme}
            />
          </Grid>
          {selectedReleaser !== null &&
            ReleaserModelHelper.releaserModelHasCalibrationFactor(
              selectedReleaser.releaserModelId
            ) && (
              <>
                <Grid xs={12} justify="center">
                  <CompleteNumberEditor
                    initialValue={
                      props.releaserConfiguration.calibrationFactor !== undefined
                        ? props.releaserConfiguration.calibrationFactor
                        : 1
                    }
                    unitText={""}
                    label={intl.formatMessage({ id: "map.newProject.calibrationFactor" })}
                    minValue={0.1}
                    maxValue={10}
                    sliderStep={0.001}
                    fieldStep={0.001}
                    inComingConversionFactor={1}
                    outComingConversionFactor={1}
                    onChange={(n) =>
                      props.onConfigurationChanged({
                        ...props.releaserConfiguration,
                        calibrationFactor: n,
                      })
                    }
                    validateValue={setIsValidCalibrationFactor}
                    colorScheme={props.colorScheme}
                    gridScheme={props.gridScheme}
                  />
                </Grid>
                {/* If necessary put the others releasers configurations */}
              </>
            )}
          {selectedReleaserModel !== undefined && (
            <Grid xs={12} style={{ paddingTop: "5px", paddingRight: "10px" }}>
              <InstantaneousReleaseRate
                flightSpeed={props.pathParameters.flightSpeed}
                trackWidth={props.pathParameters.trackWidth}
                releaseRate={props.releaserConfiguration.releaseRate}
                releaseRateUnit={selectedReleaserModel.releaseRateUnit}
              />
            </Grid>
          )}
          <Grid xs={12}>
            <div style={{ height: "12px" }} />
          </Grid>
        </>
      </Grid>
    </>
  );
}

function tryParse(s: string): number | null {
  try {
    const value = parseFloat(s.replace(/\.$/, ""));
    if (isNaN(value)) {
      return null;
    }
    return value;
  } catch (_) {
    return null;
  }
}

const getReleaserLabel = (releaser: Releaser, profile: Profile | null): string => {
  if (profile !== null && profile.role === Role.master) {
    return releaser.nickname + " - " + releaser.serialNumber;
  }
  return releaser.nickname;
};
