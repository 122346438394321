import { Location, WeatherInfo } from "biohub-model";
import {
  boundingBoxForAreas,
  centerOfBoundingBox,
  distanceBetween,
} from "../../core/geometricFunctions";
import WeatherService from "../../services/WeatherService";
import { SystemThunk } from "../systemThunk";

export const FETCH_WEATHER_DATA = "FETCH_WEATHER_DATA";
export const SET_WEATHER_DATA = "SET_WEATHER_DATA";

export type WeatherAction =
  | {
      type: typeof FETCH_WEATHER_DATA;
    }
  | {
      type: typeof SET_WEATHER_DATA;
      payload: {
        weatherInfo: WeatherInfo;
        weatherSource:
          | "home-point"
          | "area-center"
          | "project-center"
          | "current-location"
          | "map-center";
      };
    };

/**
 * Fetch weather data and update the state.
 * This action attempts to fetch weather data for the first of the following coordinates, whichever
 * exists:
 * 1. The home point of the currently selected area.
 * 2. The center of the currently selected area.
 * 3. The center point of the currently selected project.
 * 4. The current user location
 * 5. The map's center
 * 6. None. Weather data won't be updated.
 */
export function fetchWeatherData(): SystemThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const projectTreeState = state.projectTree;

    let location: Location | null = null;
    let source:
      | "home-point"
      | "area-center"
      | "project-center"
      | "current-location"
      | "map-center"
      | "none" = "none";

    const selectedProjectId = projectTreeState.selectedProjectId;
    if (selectedProjectId !== null) {
      const selectedProject = projectTreeState.projectList?.find(
        (project) => project.id === selectedProjectId
      );

      if (selectedProject !== undefined) {
        const selectedAreaId = selectedProject.selectedAreaId;
        if (selectedAreaId !== null) {
          const selectedArea = selectedProject.areas?.find((area) => area.id === selectedAreaId);
          if (selectedArea !== undefined) {
            if (selectedArea.planned.homePoint !== undefined) {
              source = "home-point";
              location = selectedArea.planned.homePoint;
            } else {
              source = "area-center";
              location = centerOfBoundingBox(selectedArea.boundingBox);
            }
          } else if (selectedProject.areas !== null && selectedProject.areas.length > 0) {
            source = "project-center";
            location = centerOfBoundingBox(
              boundingBoxForAreas(selectedProject.areas.map((area) => area.planned.polygon))!
            );
          }
        }
      }
    }

    if (location === null) {
      const userLocation = projectTreeState.userLocation;
      if (userLocation !== null && userLocation !== undefined) {
        source = "current-location";
        location = userLocation;
      }
    }
    if (location === null) {
      const mapCenter = projectTreeState.mapState.center;
      if (mapCenter !== null) {
        source = "map-center";
        location = mapCenter;
      }
    }

    if (location && source !== "none") {
      // If the previous data fetched was closer than five kilometers away from the current
      // location, skip fetching new data.
      const previousData = state.weather.weatherInfo;
      if (previousData) {
        const distance = distanceBetween(previousData.location, location);
        if (distance < 5000) {
          // Return without fetching.
          return;
        }
      }

      const weatherResult = await WeatherService.retrieveWeatherData(location);
      if (weatherResult.success) {
        dispatch({
          type: SET_WEATHER_DATA,
          payload: {
            weatherInfo: weatherResult.data,
            weatherSource: source,
          },
        });
      }
    }
  };
}
