import { max } from "lodash";

export default function (label: string, yAnchor: number): google.maps.Icon {
  const lines = label.split("\n");
  const biggestLineLength = max(lines.map((line) => line.length))!;
  const width = 10 + 6 * biggestLineLength;
  const labelOrigin = new google.maps.Point(width / 2, 9 * lines.length);
  const anchor = new google.maps.Point(width / 2, yAnchor + 9 * lines.length);
  return {
    url: "/icon_marker_distance.png",
    scaledSize: new google.maps.Size(width, 18 * lines.length),
    anchor: anchor,
    labelOrigin: labelOrigin,
  };
}
