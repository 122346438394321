import React from "react";
import { ModalContainer } from "./ModalContainer";
import { Actions } from "./styles";
import { Button, CircularProgress } from "@material-ui/core";
import { useIntl } from "react-intl";

type Props = {
  title: string;
  label: string;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function (props: Props): JSX.Element {
  const intl = useIntl();

  return (
    <ModalContainer height={170} showModal={true} onClose={props.onClose} title={props.title}>
      <label>{props.label}</label>
      <Actions>
        <Button
          disabled={props.loading}
          variant="contained"
          color="primary"
          onClick={props.onConfirm}
        >
          {props.loading ? <CircularProgress /> : intl.formatMessage({ id: "action.confirm" })}
        </Button>
        <Button
          disabled={props.loading}
          variant="contained"
          color="primary"
          onClick={props.onClose}
        >
          {intl.formatMessage({ id: "action.cancel" })}
        </Button>
      </Actions>
    </ModalContainer>
  );
}
