import styled from "styled-components";

type Props = {
  slogan?: string;
  lang?: string;
};

export const Form = styled.form.attrs((props: Props) => ({}))`
  border-radius: 25px;
  padding: 2rem;
  background: var(--form-sign-background);
  border: 1px solid var(--form-sign-border);
  width: 100%;
  max-width: 33vw;
  button {
    margin: 0 auto;
    width: 100%;
  }
  .MuiOutlinedInput-root {
    border-radius: 25px;
  }
  .MuiSelect-icon {
    color: var(--secondary);
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
  }
  fieldset,
  .MuiSelect-select {
    border-color: var(--input-border);
  }
  input,
  .MuiSelect-select {
    border-radius: 25px;
    // background: var(--input-background);
    color: var(--input-text);
    &:focus {
      color: var(--input-text-focus) !important;
    }
  }
  .MuiOutlinedInput-root {
    background: var(--input-background);
  }
  input:-internal-autofill-selected {
    background: var(--input-background) !important;
  }
  div label {
    color: var(--input-text-highlighted);
  }
  .Mui-focused {
    fieldset,
    .MuiSelect-select {
      border-color: var(--input-border-focus) !important;
    }
    color: var(--input-text-focus) !important;
  }
  label.MuiInputLabel-shrink,
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense) {
    background: var(--input-text-focus-bg);
    padding: 2px 4px 0 2px;
    border-radius: 0 8px 0 0;
    filter: contrast(0.7);
    color: var(--input-label-focus);
  }
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense) {
    top: -10px;
    width: calc(100% - 35px);
    opacity: 0.8;
    font-weight: 300;
    font-size: 15px;
  }
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense).Mui-focused,
  .MuiFormControl-root label:not(.MuiInputLabel-marginDense).MuiFormLabel-filled {
    top: initial;
    width: auto;
    opacity: 1;
    font-weight: 300;
    font-size: 15px;
    color: var(--input-text-focus) !important;
    padding-left: 8px;
    filter: grayscale(1);
  }
  .MuiFormControlLabel-root {
    float: left;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1440px) {
    max-width: 45vw;
  }

  @media screen and (max-width: 1024px) {
    max-width: 480px;
  }

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
    width: 100%;
    margin: 3rem 0;
    max-width: 100%;
  }
`;

export const Fields = styled.div.attrs((props: Props) => ({}))`
  padding: 80px 0 0;
  width: 100%;
  > div {
    width: 100%;
    margin-bottom: 1rem;
  }
  label {
    margin-bottom: 2rem;
  }
`;

export const Loading = styled.span.attrs((props: Props) => ({}))`
  position: absolute;
  bottom: 2px;
  left: 10px;
  .MuiCircularProgress-root {
    width: 28px !important;
    height: 28px !important;
  }
  * {
    stroke: white;
    color: white;
  }
`;

export const Logo = styled.span.attrs((props: Props) => ({
  slogan: props.slogan,
  lang: props.lang,
}))`
  color: var(--white);
  position  absolute;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(50% - 95px);
  top: -110px;

  
  @media screen and (max-width: 768px) {
    img {
      width: 220px;
      margin-top: 0;
    }
  }
  
  ${(props: Props) =>
    props.slogan &&
    `
      :after {
        content: "${props.slogan}";
        font-size: 15px;
        font-family: "Galano Grotesque";
        line-height: 1;
        left: 10px;
        position: absolute;
        bottom: 22px;
        font-weight: 500;
        letter-spacing: .125px;
        white-space: nowrap;
      }
  `}

  
  ${(props: Props) =>
    props.lang === "BR" &&
    `
      :after {
        font-size: 14.75px;
      }
  `}

  ${(props: Props) =>
    props.lang === "EN" &&
    `
      :after { 
        font-size: 17.45px;
      }
  `}

  ${(props: Props) =>
    props.lang === "ES" &&
    `
      :after { 
        font-size: 13.45px;
      }
  `}

  ${(props: Props) =>
    props.lang === "FR" &&
    `
      :after { 
        font-size: 17.45px;
      }
  `}

`;
