import {
  FormControl,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { formatPrice } from "../../../../../services/FormValidatorsService";

import { updateAmount } from "../../../../../store/actions/cartActions";
import { BiohubLocale } from "../../../../../store/reducers/localeReducer";
import { SystemState } from "../../../../../store/reducers/systemReducer";

import { TableCart } from "../styles";

type Props = {
  dataTable: any[];
  isDiscountUpdate?: boolean;
};

export default (props: Props) => {
  const intl = useIntl();

  // terms
  const termThTableName = intl.formatMessage({ id: "placeholder.name" });
  const termThTableAmount = intl.formatMessage({ id: "info.amount" });
  const termThTablePrice = intl.formatMessage({ id: "info.price" });
  const termRowEquipment = intl.formatMessage({ id: "info.termComparison.accessTitleBioBOT" });

  const dispatch = useDispatch();
  const localeSystem: BiohubLocale = useSelector((state: SystemState) => state.locale.localeCode);

  const onChangeUpdateAmount = (id: string, e: React.ChangeEvent<any>) => {
    dispatch(updateAmount(id, e.target.value));
  };

  return (
    <TableCart>
      <TableHead>
        <TableRow>
          <TableCell>{termThTableName}</TableCell>
          <TableCell>{termThTableAmount}</TableCell>
          <TableCell align="right">{termThTablePrice}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.dataTable.map(
          (item) =>
            !item.isEquipment && (
              <TableRow key={item.id}>
                <TableCell>{item.title}</TableCell>
                <TableCell>
                  {props.isDiscountUpdate ? (
                    <FormControl variant={"outlined"} fullWidth className="selectAmount-cart">
                      <Select
                        fullWidth
                        value={item.amount}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                          onChangeUpdateAmount(item.id, e)
                        }
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    item.amount
                  )}
                </TableCell>
                <TableCell align="right">{formatPrice(localeSystem, item.price)}</TableCell>
              </TableRow>
            )
        )}
        {props.dataTable.find((i) => i.isEquipment && i) && (
          <TableRow>
            <TableCell className={"tableCell-equipment"}>{termRowEquipment}</TableCell>
          </TableRow>
        )}
        {props.dataTable.map(
          (item) =>
            item.isEquipment && (
              <TableRow key={item.id} className={"tableRow-equipment"}>
                <TableCell className={"tableCell-equipment"}>{item.title}</TableCell>
                <TableCell className={"tableCell-equipment"}>
                  {props.isDiscountUpdate ? (
                    <FormControl variant={"outlined"} fullWidth className="selectAmount-cart">
                      <Select
                        fullWidth
                        value={item.amount}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                          onChangeUpdateAmount(item.id, e)
                        }
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    item.amount
                  )}
                </TableCell>
                <TableCell className={"tableCell-equipment"} align="right">
                  {formatPrice(localeSystem, item.price)}
                </TableCell>
              </TableRow>
            )
        )}
      </TableBody>
    </TableCart>
  );
};
