/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  Cpu,
  Device,
  DeviceModel,
  DirectClient,
  Drone,
  Profile,
  Releaser,
  ReleaserModel,
  Role,
} from "biohub-model";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import UserTableComponent from "../../../components/Atomic/UserTable";
import { IntlShape, useIntl } from "react-intl";
import { Modal } from "../styles";
import {
  additionReleaser,
  fetchCollections,
  remotionReleaser,
  updateReleaser,
} from "../../../store/actions/collectionsActions";
import { readAllDirectClients } from "../../../store/actions/masterActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Icon from "@material-ui/core/Icon";
import DropdownOptions from "../../../components/Atomic/Inputs/DropdownOptions";

export default function TableReleasers() {
  const [open, setOpen] = React.useState(false);
  const [editingReleaser, editingReleaserSetVale] = React.useState<Releaser>();

  const dispatch = useDispatch();

  const userProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  const releasers: Array<Releaser> | null = useSelector(
    (state: SystemState) => state.collections.releasers
  );

  const releaserModels: Array<ReleaserModel> = useSelector(
    (state: SystemState) => state.collections.releaserModels
  );

  const drones: Array<Drone> = useSelector((state: SystemState) => state.collections.drones);

  const cpus: Array<Cpu> = useSelector((state: SystemState) => state.collections.cpus);

  const directClients = useSelector(
    (systemState: SystemState) => systemState.master.directClients ?? []
  );
  const mapDirectClients = useSelector((systemState: SystemState) => {
    const directClientList = systemState.master.directClients ?? [];
    let map: { [directClientId: string]: DirectClient } = {};

    directClientList.forEach((directClient) => {
      map = {
        ...map,
        [directClient.id]: directClient,
      };
    });
    return map;
  });

  const mapCpus = useSelector((systemState: SystemState) => {
    const cpus = systemState.collections.cpus;
    let map: { [cpuId: string]: Cpu } = {};
    cpus.forEach((cpu) => {
      map = {
        ...map,
        [cpu.id]: cpu,
      };
    });
    return map;
  });

  const loading = useSelector((systemState: SystemState) => {
    const profile = systemState.profile.userProfile;
    if (profile === null) return true;
    const { loadingReleaserModels, loadingDeviceModels, loadingReleasers } =
      systemState.collections;
    const { readindDirectClients } = systemState.master;
    const { loadingDirectClient } = systemState.profile;
    if (
      loadingReleaserModels ||
      loadingDeviceModels ||
      readindDirectClients ||
      loadingDirectClient ||
      loadingReleasers
    )
      return true;
    return false;
  });

  const getFormatedCpuFromReleaser = (releaser: Releaser): string => {
    const cpuId = releaser.cpuId;
    if (cpuId === null) return "-";
    if (!Object.keys(mapCpus).includes(cpuId)) return "";
    const cpu = mapCpus[cpuId];
    return `${cpu.serialNumber}`;
  };

  const directClient = useSelector((systemState: SystemState) => systemState.profile.directClient);

  const deviceModels = useSelector(
    (systemState: SystemState) => systemState.collections.deviceModels
  );

  const handleCloseNewReleaser = () => {
    setOpen(false);
  };

  const handleClickEditReleaser = (_releaser: Releaser) => {
    editingReleaserSetVale(_releaser);
    setOpen(true);
  };

  const handleCloseEditReleaser = () => {
    setOpen(false);
    editingReleaserSetVale(undefined);
  };

  const handleAddReleaser = (form: ReleaserFormState) => {
    const releaserId: string = uuid();
    const releaser: Omit<Releaser, "serialNumber" | "devices"> = {
      id: releaserId,
      nickname: form.nickname,
      releaserModelId: form.releaserModel!.id,
      directClientId: form.directClient!.id,
      cpuId: form.cpuId,
    };

    dispatch(additionReleaser(releaser));
    handleCloseNewReleaser();
  };

  const handleEditReleaser = (form: ReleaserFormState) => {
    const previousReleaser: Releaser = editingReleaser as Releaser;

    const updatedReleaser: Releaser = {
      id: previousReleaser.id,
      serialNumber: form.serialNumber,
      nickname: form.nickname,
      releaserModelId: form.releaserModel!.id,
      directClientId: form.directClient!.id,
      devices: form.devices.map((device) => ({
        id: device.id ?? uuid(),
        deviceModel: device.deviceModel!,
        serialNumber: device.serialNumber,
        releaserId: previousReleaser.id,
      })),
      cpuId: form.cpuId,
    };

    dispatch(updateReleaser(updatedReleaser));

    handleCloseEditReleaser();
  };

  // terms
  const intl = useIntl();
  const termNickName = intl.formatMessage({ id: "placeholder.releaser.nickname" });
  const termModel = intl.formatMessage({ id: "placeholder.releaser.tablemodel" });
  const termSerialNumber = intl.formatMessage({ id: "placeholder.releaser.tableserialnumber" });
  const termAddNew = intl.formatMessage({ id: "action.add.new" });

  const termReleasers = intl.formatMessage({ id: "info.releasers" });
  const termDrone = intl.formatMessage({ id: "info.drone" });
  const termDirectClient = intl.formatMessage({ id: "info.client" });

  const termCpu = intl.formatMessage({ id: "info.cpu" });

  const termDevices = intl.formatMessage({ id: "info.devices" });

  const handleClickNewReleaser = () => {
    editingReleaserSetVale(undefined);
    setOpen(true);
  };

  return (
    <div>
      {userProfile !== null && userProfile.role <= Role.manager && (
        <>
          {loading ? (
            <CircularProgress />
          ) : (
            <UserTableComponent<Releaser>
              items={releasers}
              titleTerm={termReleasers}
              addTerm={
                userProfile !== null && userProfile.role === Role.master ? termAddNew : undefined
              }
              onAddFunction={
                userProfile !== null && userProfile.role === Role.master
                  ? handleClickNewReleaser
                  : undefined
              }
              classes={
                userProfile.role === Role.master
                  ? [
                      "id",
                      termModel,
                      termNickName,
                      termSerialNumber,
                      termDevices,
                      termDirectClient,
                      termCpu,
                    ]
                  : ["id", termModel, termNickName, termSerialNumber, termDevices, termCpu]
              }
              formatItems={(item: Releaser) =>
                userProfile.role === Role.master
                  ? [
                      item.id,
                      releaserModels.find((model) => model.id === item.releaserModelId)?.name ?? "",
                      item.nickname,
                      item.serialNumber,
                      devicesColumnForReleaser(item, deviceModels),
                      mapDirectClients[item.directClientId]?.company?.name ??
                        mapDirectClients[item.directClientId]?.profiles.find(
                          (profile) => profile.role === Role.admin || profile.role === Role.master
                        )?.name ??
                        "",
                      getFormatedCpuFromReleaser(item),
                    ]
                  : [
                      item.id,
                      releaserModels.find((model) => model.id === item.releaserModelId)?.name ?? "",
                      item.nickname,
                      item.serialNumber,
                      devicesColumnForReleaser(item, deviceModels),
                      getFormatedCpuFromReleaser(item),
                    ]
              }
              onViewItem={(itemId: string) => {
                let _releaser: Releaser | undefined = undefined;
                for (let i = 0; i < releasers.length && _releaser === undefined; i++) {
                  if (itemId === releasers[i].id) {
                    _releaser = releasers[i];
                  }
                }
                if (_releaser !== undefined) handleClickEditReleaser(_releaser);
              }}
              onRemoveFunction={
                userProfile !== null && userProfile.role === Role.master
                  ? (items: Array<string>) => {
                      for (let i = 0; i < items.length; i++) {
                        dispatch(remotionReleaser(items[i]));
                      }
                    }
                  : undefined
              }
            />
          )}
        </>
      )}
      <div>
        <ReleaserForm
          deviceModels={deviceModels}
          releaserModels={releaserModels}
          intl={intl}
          open={open}
          editing={editingReleaser !== undefined ? true : false}
          finishCallback={editingReleaser === undefined ? handleAddReleaser : handleEditReleaser}
          cancelCallback={
            editingReleaser === undefined ? handleCloseNewReleaser : handleCloseEditReleaser
          }
          profileRole={userProfile!.role}
          listDirectClients={
            userProfile!.role === Role.master
              ? directClients
              : directClient != null
              ? [directClient]
              : []
          }
          cpus={cpus}
          releaser={editingReleaser}
        />
      </div>
    </div>
  );
}

interface EditProps {
  open: boolean;
  editing: boolean;
  finishCallback: (form: ReleaserFormState) => void;
  cancelCallback: () => void;
  intl: IntlShape;
  releaserModels: ReleaserModel[];
  deviceModels: DeviceModel[];
  profileRole: Role;
  listDirectClients: DirectClient[];
  releaser?: Releaser;
  cpus: Cpu[];
}

type ReleaserFormState = {
  nickname: string;
  serialNumber: string;
  releaserModel?: ReleaserModel;
  directClient?: DirectClient;
  devices: {
    id?: string;
    deviceModel?: DeviceModel;
    serialNumber: string;
    releaserId?: string;
  }[];
  cpuId: string | null;
};

class ReleaserForm extends React.Component<EditProps, ReleaserFormState> {
  _extractProps(props: EditProps): ReleaserFormState {
    if (props.editing && props.releaser !== undefined) {
      return {
        nickname: props.releaser.nickname,
        serialNumber: props.releaser.serialNumber,
        releaserModel: props.releaserModels.find(
          (model) => model.id === props.releaser?.releaserModelId
        ),
        directClient: props.listDirectClients.find(
          (directClient) => props.releaser?.directClientId === directClient.id
        ),
        devices: props.releaser.devices,
        cpuId: props.releaser.cpuId,
      };
    } else {
      return {
        nickname: "",
        serialNumber: "",
        releaserModel: undefined,
        directClient: undefined,
        devices: [],
        cpuId: null,
      };
    }
  }

  constructor(props: EditProps) {
    super(props);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this._extractProps = this._extractProps.bind(this);
    this.state = this._extractProps(props);
    this.validate = this.validate.bind(this);
  }

  componentWillReceiveProps(newProps: EditProps) {
    this.setState(this._extractProps(newProps));
  }

  validate(): boolean {
    const { nickname, releaserModel, directClient } = this.state;
    if (nickname.length === 0 || releaserModel === undefined || directClient === undefined)
      return false;
    return true;
  }

  render() {
    const intl = this.props.intl;

    return (
      <Modal
        open={this.props.open}
        onClose={(e) => this.props.cancelCallback()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.editing
            ? intl.formatMessage({ id: "info.releaser.edit" })
            : intl.formatMessage({ id: "info.releaser.add" })}
          <Icon onClick={(e) => this.props.cancelCallback()}>close</Icon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.editing
              ? intl.formatMessage({ id: "info.releaser.edit.message" })
              : intl.formatMessage({ id: "info.releaser.add.message" })}
          </DialogContentText>
          <Grid container spacing={1}>
            {this.props.editing && (
              <Grid item lg={12} xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  disabled={this.props.profileRole !== Role.master}
                  id="serialNumber"
                  label={intl.formatMessage({ id: "placeholder.releaser.serialnumber" })}
                  value={this.state.serialNumber}
                  fullWidth
                  name="serialNumber"
                  variant="outlined"
                  onChange={(e) => this.setState({ serialNumber: e.target.value })}
                />
              </Grid>
            )}
            <Grid item lg={12} xs={12}>
              <TextField
                margin="dense"
                id="nickname"
                disabled={
                  this.props.profileRole !== Role.master &&
                  this.props.profileRole !== Role.admin &&
                  this.props.profileRole !== Role.manager
                }
                label={intl.formatMessage({ id: "placeholder.releaser.nickname" })}
                fullWidth
                value={this.state.nickname}
                name="nickname"
                variant="outlined"
                onChange={(e) => this.setState({ nickname: e.target.value })}
              />
            </Grid>

            <>
              {/*  ************* AUTOCOMPLETE FOR LIST OF DIRECTCLIENTS ************* */}
              {/* After a while this "autocomplete" will have to be changed due to the number of clients it will have. */}
              <Grid item lg={4} xs={12}>
                <b>{intl.formatMessage({ id: "info.releaser.selectclient" })}</b>
              </Grid>
              <Grid item lg={8} xs={12}>
                <Autocomplete<DirectClient>
                  id="combo-search-direct-clients"
                  options={this.props.listDirectClients}
                  fullWidth
                  disabled={this.props.profileRole === Role.master ? false : true}
                  onChange={(e, value) => {
                    if (value !== null) {
                      this.setState({ directClient: value });
                    }
                  }}
                  value={this.state.directClient}
                  getOptionLabel={(option) => {
                    let value: string = "";
                    if (option.company !== undefined) {
                      value = option.company.name + " - ";
                    }
                    const search = option.profiles.find(
                      (p) => p.role === Role.admin || p.role === Role.master
                    );
                    if (search !== undefined) {
                      value = value + search.name;
                    }
                    return value;
                  }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        placeholder={intl.formatMessage({
                          id: "placeholder.releaser.searchclient",
                        })}
                        style={{
                          borderRadius: 25,
                          fontSize: 16,
                          paddingLeft: 13,
                          height: 40,
                          width: "100%",
                          borderWidth: 1,
                          borderColor: "#b4b4b4",
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
              </Grid>

              {/* ************* SELECTBOX FOR LIST OF LIBERATORS MODELS ************* */}
              <Grid item lg={4} xs={12}>
                <b>{intl.formatMessage({ id: "info.releaser.liberatormodel" })}</b>
              </Grid>
              <Grid item lg={8} xs={12}>
                <DropdownOptions
                  disabled={this.props.profileRole !== Role.master}
                  baseId="releaser-model"
                  label={intl.formatMessage({ id: "info.releaser.models" })}
                  emptyOption={intl.formatMessage({ id: "info.none" })}
                  options={this.props.releaserModels}
                  getOptionName={(model) => model.name}
                  value={this.state.releaserModel}
                  onChange={(model) => {
                    if (model === undefined) {
                      this.setState({
                        devices: [],
                      });
                    }
                    this.setState({ releaserModel: model });
                  }}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <b>{intl.formatMessage({ id: "info.cpu" })}</b>
              </Grid>
              <Grid item lg={8} xs={12}>
                <Autocomplete<Cpu>
                  id="cpus"
                  options={this.props.cpus.filter((cpu) => {
                    const selectedDirectClientId = this.state.directClient?.id;
                    if (selectedDirectClientId === undefined) return true;

                    return cpu.directClientId === selectedDirectClientId;
                  })}
                  fullWidth
                  onChange={(e, value) => {
                    if (value !== null) {
                      const cpu = value as Cpu;

                      const selectedDirectClient = this.props.listDirectClients.find(
                        (directClient) => directClient.id === cpu.directClientId
                      );

                      this.setState({
                        ...this.state,
                        cpuId: cpu.id,
                        directClient: selectedDirectClient,
                      });
                    } else {
                      this.setState({
                        ...this.state,
                        cpuId: null,
                      });
                    }
                  }}
                  value={this.props.cpus.find((c) => c.id === this.state.cpuId)}
                  getOptionLabel={(cpu) => {
                    return `${cpu.serialNumber}`;
                  }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        placeholder={intl.formatMessage({
                          id: "info.cpu",
                        })}
                        style={{
                          borderRadius: 25,
                          fontSize: 16,
                          paddingLeft: 13,
                          height: 40,
                          width: "100%",
                          borderWidth: 1,
                          borderColor: "#b4b4b4",
                        }}
                        type="text"
                        {...params.inputProps}
                      />
                    </div>
                  )}
                />
              </Grid>

              {this.props.editing && (
                <>
                  <Grid item lg={4} xs={12}>
                    <b>{intl.formatMessage({ id: "info.devices" }) + ":"}</b>
                  </Grid>
                  <br />
                  {this.state.devices.map((device, deviceIndex) => (
                    <>
                      <Grid item xs={12}>
                        <Box height={3} />
                      </Grid>
                      <Grid item xs={1}>
                        <b style={{ color: "black", textAlign: "center" }}>{deviceIndex + 1}</b>
                      </Grid>
                      <Grid container xs={11}>
                        <Grid item lg={8} xs={12}>
                          <DropdownOptions
                            disabled={this.props.profileRole !== Role.master}
                            key={uuid()}
                            baseId={"device-model-" + deviceIndex.toString()}
                            label={intl.formatMessage({ id: "info.device.model" })}
                            emptyOption={intl.formatMessage({ id: "info.none" })}
                            options={this.props.deviceModels}
                            getOptionName={(model) => model.name}
                            value={device.deviceModel}
                            onChange={(model) => {
                              const devices = this.state.devices;
                              devices[deviceIndex].deviceModel = model;
                              this.setState({ devices: devices });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box height={10} />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <TextField
                            autoFocus
                            margin="dense"
                            disabled={this.props.profileRole !== Role.master}
                            id={"device-" + deviceIndex.toString() + "-serialNumber"}
                            label={intl.formatMessage({ id: "placeholder.releaser.serialnumber" })}
                            value={device.serialNumber}
                            fullWidth
                            name="serialNumber"
                            variant="outlined"
                            onChange={(e) => {
                              const devices = this.state.devices;
                              devices[deviceIndex].serialNumber = e.target.value;
                              this.setState({ devices: devices });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box height={10} />
                    </>
                  ))}
                </>
              )}

              {this.props.editing && this.props.profileRole === Role.master && (
                <>
                  <Button
                    disabled={this.state.devices.length === 0}
                    onClick={() => {
                      let devices = this.state.devices;
                      if (devices.length > 0) {
                        devices.pop();
                      }
                      this.setState({ devices: devices });
                    }}
                    color="secondary"
                  >
                    <div style={{ marginTop: 5 }}>
                      {intl.formatMessage({ id: "info.releaser.removeDevice" })}
                    </div>
                    <Icon>cancel</Icon>
                  </Button>

                  <Button
                    disabled={
                      this.state.releaserModel === undefined ||
                      this.state.devices.length >= getNumberOfDevices(this.state.releaserModel)
                    }
                    onClick={() => {
                      if (this.state.releaserModel === undefined) return;
                      let devices = this.state.devices;
                      if (devices.length < getNumberOfDevices(this.state.releaserModel)) {
                        this.setState({
                          devices: [...devices, { serialNumber: "" }],
                        });
                      }
                    }}
                    color="primary"
                  >
                    <div style={{ marginTop: 5 }}>
                      {intl.formatMessage({ id: "info.releaser.addDevice" })}
                    </div>
                    <Icon>add</Icon>
                  </Button>
                </>
              )}
            </>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => this.props.cancelCallback()}
            className="cancel-button"
            startIcon={<Icon>close</Icon>}
          >
            {intl.formatMessage({ id: "generic.cancel" })}
          </Button>
          <Button
            disabled={!this.validate()}
            onClick={(e) => this.props.finishCallback(this.state)}
            variant="contained"
            color="primary"
          >
            {this.props.editing
              ? intl.formatMessage({ id: "action.save" })
              : intl.formatMessage({ id: "action.add" })}
          </Button>
        </DialogActions>
      </Modal>
    );
  }
}

const getNumberOfDevices = (model: ReleaserModel): number => {
  switch (model.type) {
    case 1:
      return 1;
    case 2:
      switch (model.model) {
        case 2:
          return 16;
        case 3:
          return 2;
        default:
          return 0;
      }
    default:
      return 0;
  }
};

const devicesColumnForReleaser = (releaser: Releaser, deviceModels: DeviceModel[]): string => {
  if (deviceModels.length === 0) return "";
  let modelIds = releaser.devices.map((device) => device.deviceModel.id);

  let modelIdsAndAmount: {
    [modelId: string]: number;
  } = {};

  for (let i = 0; i < modelIds.length; i++) {
    const modelId = modelIds[i];
    if (modelIdsAndAmount[modelId] === undefined) {
      modelIdsAndAmount = {
        ...modelIdsAndAmount,
        [modelId]: 0,
      };
    }

    modelIdsAndAmount[modelId]++;
  }

  let info = "";

  modelIds = Object.keys(modelIdsAndAmount);
  for (let i = 0; i < modelIds.length; i++) {
    const modelId = modelIds[i];

    const findDeviceModel = deviceModels.find((model) => model.id === modelId);
    if (findDeviceModel !== undefined) {
      info = info + modelIdsAndAmount[modelId].toString() + "x " + findDeviceModel.name + " ";
    }
  }

  return info;
};
