import { Location } from "biohub-model";
import { BiohubErrorCode, BiohubResponse } from "../axios/BiohubApi";

/**
 * Decimal precision for the coordinates when making the api calls.
 * This will affect whether two values match when checking the cache.
 */
const coordinatePrecision = 6;

/**
 * Object where we'll store cached elevations.
 * The keys are coordinates formatted as lat,lng.
 *
 * It would be interesting to restrict the requests to be done only one at a time,
 * to avoid cache misses that would be cache hits if one of the functions calls
 * had waited the other.
 */
const cachedElevations: {
  [key: string]: number;
} = {};

/**
 * Get data from an elevation service. The data is cached, so that requesting
 * elevation data for a coordinate only causes a request the first time.
 */
async function getElevationForCoordinate(location: Location): Promise<BiohubResponse<number>> {
  // Convert the location to the cache format.
  // Formerly, we had to convert the location to a string anyway because
  // the rest api calls use those strings, but that was in the app, and
  // in this case, in the browser, we have to use the elevation js lib.
  // We still cache using strings, though, and it's probably not that
  // much extra processing.
  const cacheKey: string = formatLatLng(location);

  // Check the cache.
  // Because this is an object map, indexing it would normally give a number,
  // but we're expecting to use indexes that don't get any values, which
  // results in undefined.
  const cachedValue: number | undefined = cachedElevations[cacheKey];

  if (cachedValue) {
    // Cache hit!
    return {
      success: true,
      data: cachedValue,
    };
  }

  // Make the request.
  const response = await googleMapsRequest([location]);
  if (response.success) {
    // Success. Cache the result and return it.
    cachedElevations[cacheKey] = response.data[0];
    return {
      success: true,
      data: response.data[0],
    };
  } else {
    // Error. The object is already in the correct format to be returned.
    return response;
  }
}

async function getElevationsForPath(path: Location[]): Promise<BiohubResponse<number[]>> {
  // For checking the cache, we'll check for every single value in the cache.
  // Return a cached value only if not one single value misses. Even then,
  // only search for the missing values.
  const cacheKeys = path.map<string>(formatLatLng);
  const cacheHits = cacheKeys.map<number | undefined>((key) => cachedElevations[key]);

  // If every single path was a cache hit, make a new array to return.
  if (cacheHits.every((location) => location !== undefined)) {
    return {
      success: true,
      data: cacheHits as number[],
    };
  }

  // Otherwise, we'll make a new request. We'll only ask for the locations
  // we don't have in the cache, so get those indices first.
  const missingLocationsIndices: number[] = [];
  for (let i = 0; i < cacheHits.length; i++) {
    if (cacheHits[i] === undefined) {
      missingLocationsIndices.push(i);
    }
  }

  // Get the locations for those indices and make a request.
  const missingLocations = missingLocationsIndices.map((index) => path[index]);

  const response = await googleMapsRequest(missingLocations);
  if (response.success) {
    // Success.
    // First, assemble the whole array of elevations, with the ones we already
    // had in the cache and the new ones from this request.
    // For that, we make a copy of the array with cache hits, and then replace
    // its items with the ones we requested. I have faith that the resulting
    // array will never be left with undefined entries in it.
    const finalArray = cacheHits.slice();
    for (let i = 0; i < missingLocationsIndices.length; i++) {
      finalArray[missingLocationsIndices[i]] = response.data[i];
    }
    if (cacheKeys.length !== finalArray.length) {
      console.warn("getElevationsForPath: Input and output lengths do not match.");
    }
    if (!finalArray.every((num) => typeof num === "number")) {
      console.warn("getElevationsForPath: Not every value in the resulting array was a number.");
    }
    for (let i = 0; i < finalArray.length; i++) {
      cachedElevations[cacheKeys[i]] = finalArray[i]!;
    }
    return {
      success: true,
      data: finalArray as number[],
    };
  } else {
    // Error. The object is already in the correct format to be returned.
    return response;
  }
}

/**
 * This function makes the call to the google elevation api using the javascript client
 * library. Note that due to a CORS issue, it is not possible to call the google
 * elevation api through the url directly.
 * Because the javascript library only exposes a function with callbacks without support
 * for promises, this function converts that call into an async function that's easier
 * to use.
 * Just like all other promises that return BiohubResponse, this will never reject. In
 * case of failure, it'll resolve with a failure.
 */
function googleMapsRequest(locations: Location[]): Promise<BiohubResponse<number[]>> {
  return new Promise((resolve, reject) => {
    const elevator = new google.maps.ElevationService();
    elevator.getElevationForLocations(
      {
        // We can use our object directly as a parameter here because
        // they're both lat,lng
        locations: locations,
      },
      (results, status) => {
        // We'll resolve our promise either way. But resolve with a success
        // only for successful status.
        switch (status) {
          case google.maps.ElevationStatus.OK:
            // Each item of the result also has location and resolution, but we
            // only need elevation.
            if (results === null) {
              reject(results);
            }
            resolve({
              success: true,
              data: results!.map((gmapsResult) => gmapsResult.elevation),
            });
            // Don't forget that a call to resolve() isn't a return. Need to break.
            break;
          case google.maps.ElevationStatus.INVALID_REQUEST:
            resolve({
              success: false,
              error: {
                errorCode: BiohubErrorCode.ELEVATION_INVALID_REQUEST,
              },
            });
            break;
          case google.maps.ElevationStatus.OVER_QUERY_LIMIT:
            resolve({
              success: false,
              error: {
                errorCode: BiohubErrorCode.ELEVATION_OVER_QUERY_LIMIT,
              },
            });
            break;
          case google.maps.ElevationStatus.REQUEST_DENIED:
            resolve({
              success: false,
              error: {
                errorCode: BiohubErrorCode.ELEVATION_REQUEST_DENIED,
              },
            });
            break;
          case google.maps.ElevationStatus.UNKNOWN_ERROR:
          default:
            resolve({
              success: false,
              error: {
                errorCode: BiohubErrorCode.ELEVATION_UNKNOWN_ERROR,
              },
            });
            break;
        }
      }
    );
  });
}

/**
 * For internal use only. Formats coordinate as lat,lng for making the requests.
 */
function formatLatLng(location: Location): string {
  return `${location.lat.toFixed(coordinatePrecision)},${location.lng.toFixed(
    coordinatePrecision
  )}`;
}

export default {
  getElevationForCoordinate,
  getElevationsForPath,
};
