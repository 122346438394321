import styled from "styled-components";
import { Typography } from "@material-ui/core";

type Props = {
  color?: "light";
};

export const InstantaneousReleaseRateLabel = styled(Typography).attrs((props: Props) => ({
  color: props.color,
  align: "right",
}))`
  color: var(--text-default) !important;

  ${(props: Props) =>
    props.color === "light" &&
    `
      color: var(--white)!important;
  `}
`;
