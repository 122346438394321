import React from "react";
import { useIntl } from "react-intl";
import Footer from "../../../components/Atomic/Footer";
import Text from "../../../components/Atomic/BasicComponents/Text";
import Image from "../../../components/Atomic/BasicComponents/Image";
import Banner from "../../../components/Atomic/Tools/Banner";
import Values from "../../../components/Atomic/Tools/CarouselSimpleText";
import Team from "../../../components/Atomic/Tools/SectionTeam";

import { Container, Grid } from "@material-ui/core";

import { AboutPage, Pillars, Item, Description } from "../styles";

/* Images */
import imageBackground from "../../../assets/background/about.jpg";
import imageMask from "../../../assets/logo/icon_tag.svg";
import iconVision from "../../../assets/logo/icon_vision.svg";
import iconMission from "../../../assets/logo/icon_mission.svg";
import iconPrecision from "../../../assets/logo/icon_precision.svg";
import iconTraceability1 from "../../../assets/logo/icon_traceability_1.svg";
import iconTraceability2 from "../../../assets/logo/icon_traceability_2.svg";
import imageCEO from "../../../assets/gallery/image_ceo.png";
import imageManager from "../../../assets/gallery/image_manager.png";
import imageEmployee from "../../../assets/gallery/image_employee.png";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();

  // terms
  const pageTitle = intl.formatMessage({ id: "info.aboutTitle" });
  const pageDescription = intl.formatMessage({ id: "info.aboutDescription" });
  const pageVision = intl.formatMessage({ id: "info.aboutVisionTitle" });
  const pageVisionSub = intl.formatMessage({ id: "info.aboutVisionSub" });
  const pageVisionText = intl.formatMessage({ id: "info.aboutVisionText" });
  const pageMission = intl.formatMessage({ id: "info.aboutMissionTitle" });
  const pageMissionSub = intl.formatMessage({ id: "info.aboutMissionSub" });
  const pageMissionText = intl.formatMessage({ id: "info.aboutMissionText" });
  const pageValues = intl.formatMessage({ id: "info.aboutValues" });
  const precisionTitle = intl.formatMessage({ id: "info.aboutPrecisionTitle" });
  const precisionText = intl.formatMessage({ id: "info.aboutPrecisionText" });
  const traceabilityTitle = intl.formatMessage({ id: "info.aboutTraceabilityTitle" });
  const traceabilityText = intl.formatMessage({ id: "info.aboutTraceabilityText" });
  const titleCEORole = intl.formatMessage({ id: "info.aboutTeamRoleCEO" });
  const titleManagerRole = intl.formatMessage({ id: "info.aboutTeamRoleManager" });
  const titleEmployeeRole = intl.formatMessage({ id: "info.aboutTeamRoleEmployee" });
  const descriptionCEO = intl.formatMessage({ id: "info.aboutTeamDescriptionCEO" });
  const descriptionManager = intl.formatMessage({ id: "info.aboutTeamDescriptionManager" });
  const descriptionEmployee = intl.formatMessage({ id: "info.aboutTeamDescriptionEmployee" });

  //Temporary
  const valuesList = [
    {
      id: "TEXT01",
      title: precisionTitle,
      description: precisionText,
      image: iconPrecision,
    },
    {
      id: "TEXT02",
      title: traceabilityTitle,
      description: traceabilityText,
      image: iconTraceability1,
    },
    {
      id: "TEXT03",
      title: traceabilityTitle,
      description: traceabilityText,
      image: iconTraceability2,
    },
    {
      id: "TEXT04",
      title: precisionTitle,
      description: precisionText,
      image: iconPrecision,
    },
    {
      id: "TEXT05",
      title: traceabilityTitle,
      description: traceabilityText,
      image: iconTraceability1,
    },
  ];

  const teamList = [
    {
      id: "TEAM01",
      name: "Fernando Nicodemos",
      role: {
        id: "01",
        title: titleCEORole,
      },
      description: descriptionCEO,
      image: imageCEO,
    },
    {
      id: "TEAM02",
      name: "Marilayne Mayrink",
      role: {
        id: "02",
        title: titleManagerRole,
      },
      description: descriptionManager,
      image: imageManager,
    },
    {
      id: "TEAM03",
      name: "Carlos Takahashi",
      role: {
        id: "03",
        title: titleEmployeeRole,
      },
      description: descriptionEmployee,
      image: imageEmployee,
    },
    {
      id: "TEAM04",
      name: "Carlos Takahashi",
      role: {
        id: "03",
        title: "Engineering",
      },
      description: descriptionEmployee,
      image: imageEmployee,
    },
    {
      id: "TEAM05",
      name: "Carlos Takahashi",
      role: {
        id: "03",
        title: "Engineering",
      },
      description: descriptionEmployee,
      image: imageEmployee,
    },
    {
      id: "TEAM06",
      name: "Carlos Takahashi",
      role: {
        id: "03",
        title: "Engineering",
      },
      description: descriptionEmployee,
      image: imageEmployee,
    },
    {
      id: "TEAM07",
      name: "Carlos Takahashi",
      role: {
        id: "03",
        title: "Engineering",
      },
      description: descriptionEmployee,
      image: imageEmployee,
    },
    {
      id: "TEAM08",
      name: "Carlos Takahashi",
      role: {
        id: "03",
        title: "Engineering",
      },
      description: descriptionEmployee,
      image: imageEmployee,
    },
  ];

  return (
    <>
      <AboutPage>
        <Banner
          image={imageBackground}
          title={pageTitle}
          description={pageDescription}
          id="about"
        />
        <Pillars>
          <Container>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Item>
                  <Image src={iconVision} alt={pageVision} />
                  <Description>
                    <Text
                      text={pageVision}
                      color={"primary"}
                      appearance={"title-semibold"}
                      size={"medium"}
                    />
                    <Text text={pageVisionSub} color={"highlighted"} appearance={"info-subtitle"} />
                    <Text text={pageVisionText} color={"dark"} appearance={"description-block"} />
                  </Description>
                </Item>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Item>
                  <Image src={iconMission} alt={pageMission} />
                  <Description>
                    <Text
                      text={pageMission}
                      color={"primary"}
                      appearance={"title-semibold"}
                      size={"medium"}
                    />
                    <Text
                      text={pageMissionSub}
                      color={"highlighted"}
                      appearance={"info-subtitle"}
                    />
                    <Text text={pageMissionText} color={"dark"} appearance={"description-block"} />
                  </Description>
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Pillars>
        <Values title={pageValues} items={valuesList} color={"light-gray"} />
        <Team items={teamList} />
        <Footer image={true} description={intl.formatMessage({ id: "info.about" })} />
      </AboutPage>
    </>
  );
};
