import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import _ from "lodash";

type Props<T> = {
  label: string;
  value?: T;
  baseId: string;
  options: T[];
  getOptionName: (option: T) => string;
  onChange: (option?: T) => void;
  disabled?: boolean;
  error?: boolean;
  emptyOption?: string;
};

function CustomDropdown<T>(props: Props<T>): JSX.Element {
  const { label, baseId, value, options, getOptionName, onChange, disabled, emptyOption } = props;

  const [selectedIndex, setSelectedIndex] = useState<number>(
    value !== undefined ? indexOf(options, value!) : -1
  );

  const [focused, setFocused] = useState<boolean>(false);

  return (
    <FormControl variant="outlined" fullWidth className="select-item">
      <InputLabel
        style={{
          margin: !focused && selectedIndex === -1 ? "-8px 0px" : "0px 0px",
        }}
        id={baseId + "-label"}
      >
        {label}
      </InputLabel>
      <Select
        labelId={baseId + "-label"}
        id={baseId + "-dropdown"}
        value={selectedIndex === -1 ? undefined : selectedIndex}
        disabled={disabled}
        error={props.error}
        onChange={(e) => {
          const value = e.target.value;
          if (value === "") {
            onChange(undefined);
            setSelectedIndex(-1);
          } else {
            const index = value as number;
            onChange(options[index]);
            setSelectedIndex(index);
          }
        }}
        label={label}
        name={baseId}
        onFocus={(e) => setFocused(true)}
        onBlur={(e) => setFocused(false)}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {emptyOption !== undefined && (
          <MenuItem value={""}>
            <em>{emptyOption}</em>
          </MenuItem>
        )}

        {options.map((option, index) => (
          <MenuItem key={index} value={index}>
            {getOptionName(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomDropdown;

function indexOf<T>(array: T[], element: T): number {
  for (let i = 0; i < array.length; i++) {
    if (_.isEqual(array[i], element)) {
      return i;
    }
  }

  return -1;
}
