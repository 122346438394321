import React from "react";
import { useIntl } from "react-intl";
import Container from "../../../components/Atomic/Areas/Container";
import Row from "../../../components/Atomic/Areas/Row";
import Column from "../../../components/Atomic/Areas/Column";
import Header from "../../../components/Atomic/Header";
import Footer from "../../../components/Atomic/Footer";
import Text from "../../../components/Atomic/BasicComponents/Text";
import Button from "../../../components/Atomic/BasicComponents/Button";
import { VerificationPage, ContentPage, DetailsDescription, DetailsPassword } from "../styles";

import { IconButton, InputAdornment, TextField, CircularProgress } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();
  const [visibleChange, setVisibleChange] = React.useState(false);
  const [visibleChangeConfirmation, setVisibleChangeConfirmation] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  //terms
  const termTitle = intl.formatMessage({ id: "info.newPassword" });
  const termDetails = intl.formatMessage({ id: "info.newPasswordDescription" });
  const termNewPassword = intl.formatMessage({ id: "info.newPasswordDetails" });
  const termNewPasswordConfirm = intl.formatMessage({ id: "info.newPasswordConfirm" });
  const termActionSave = intl.formatMessage({ id: "action.saveData" });
  const termAboutInfo = intl.formatMessage({ id: "info.about" });

  return (
    <>
      <VerificationPage>
        <Header color={"dark"} />
        <Container>
          <ContentPage>
            <Row>
              <Column md={5} sm={12} xs={12}>
                <Text text={termTitle} color={"primary"} size={"large"} />
                <DetailsDescription>
                  <Text text={termDetails} color={"primary"} />
                </DetailsDescription>
              </Column>
              <Column xs={12} md={12} sm={12}>
                <DetailsPassword>
                  <Text
                    text={termNewPassword}
                    color={"primary"}
                    appearance={"info-title"}
                    size={"medium"}
                  />
                  <TextField
                    defaultValue={"senhaqualquer"}
                    id="change-password"
                    variant="outlined"
                    type={visibleChange ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="visibility"
                            onClick={() => {
                              setVisibleChange(!visibleChange);
                            }}
                          >
                            {visibleChange ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </DetailsPassword>

                <DetailsPassword>
                  <Text
                    text={termNewPasswordConfirm}
                    color={"primary"}
                    appearance={"info-title"}
                    size={"medium"}
                  />
                  <TextField
                    defaultValue={"senhaqualquer"}
                    id="change-password"
                    variant="outlined"
                    type={visibleChangeConfirmation ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="visibility"
                            onClick={() => {
                              setVisibleChangeConfirmation(!visibleChangeConfirmation);
                            }}
                          >
                            {visibleChange ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </DetailsPassword>
              </Column>
              <Column xs={12} md={12}>
                <Button
                  color={"highlighted"}
                  size={"large"}
                  position={"right"}
                  action={() => {
                    setLoading(true);
                  }}
                >
                  {termActionSave}
                  {loading && <CircularProgress />}
                </Button>
              </Column>
            </Row>
          </ContentPage>
        </Container>
        <Footer image={true} description={termAboutInfo} />
      </VerificationPage>
    </>
  );
};
