import React from "react";
import { Location, UnitSystem, Waypoint } from "biohub-model";
import PolygonForArea from "./core/polygons/PolygonForArea";
import { EditingAreaType } from "../../../../store/reducers/projectTreeReducer";
import PlannedArea from "./core/PlannedArea";
import EditingAreaPolygon from "./core/EditingAreaPolygon";

type Props = {
  polygonId: string;
  polygon: Location[];
  areaEditionMode: EditingAreaType | undefined;
  isSelected: boolean;
  zoom?: number;
  isLoading?: boolean;
  onPolygonClicked: (location: Location) => void;
  onVertexMoved?: (index: number, newLocation: Location) => void;
  waypoints: Waypoint[];
  formatHeight: (value: number) => string;
  selectedWaypointsIndexes: number[];
  onClickWaypoint: (waypoint: Waypoint, waypointIndex: number) => void;
  homePoint?: Location;
  homePointElevation?: number;
  onHomePointMoved?: (newLocation: Location) => void;
  unitSystem: UnitSystem;
  onWaypointMoved?: (index: number, newLocation: Location) => void;
  onClickPlannedRoute?: (segmentIndex: number, location: Location) => void;
  showWaypointDistances?: false;
  mapType: "roadmap" | "satellite";
  usingOnlineElevation: boolean;
  homePointMarkerZIndex: number;
  homePointElevationMarkerZIndex: number;
  homePointElevationLabelZIndex: number;
  plannedPathZIndex: number;
  plannedPathBorderZIndex: number;
  waypointsDistanceMarkerZIndex: number;
  waypointsDistanceLabelZIndex: number;
  waypointOrientationMarkerZIndex: number;
  waypointDropMarkerZIndex: number;
  waypointElevationMarkerZIndex: number;
  waypointElevationLabelZIndex: number;
  areaPolygonZIndex: number;
  areaEditingPolygonPolylineZIndex: number;
  areaEditingPolygonMarkersZIndex: number;
};

export default function (props: Props) {
  const isLoading = props.isLoading ?? false;
  const areaEditionMode = props.areaEditionMode;
  const editingWaypoints =
    areaEditionMode === EditingAreaType.EditingEverything ||
    areaEditionMode === EditingAreaType.EditingPlanPoints;
  const editingPolygon =
    areaEditionMode !== undefined && areaEditionMode !== EditingAreaType.EditingPlanPoints;

  const controlAdaptivePlannedAreaRender: ControlAdaptivePlannedAreaRender | undefined =
    props.zoom !== undefined
      ? {
          waypointScale: (() => {
            if (props.zoom >= 17) return 1;
            if (props.zoom >= 16) return 0.9;
            if (props.zoom >= 15) return 0.7;
            if (props.zoom >= 14) return 0.5;
            if (props.zoom >= 13) return 0.3;
            if (props.zoom >= 12) return 0.1;
            else return 0;
          })(),
          showDistanceLabels: (() => {
            if (props.zoom >= 16) return true;
            else return false;
          })(),
        }
      : undefined;
  return (
    <>
      {!editingPolygon ? (
        <PolygonForArea
          key={`polygon-for-area-${props.polygonId}`}
          polygonId={props.polygonId}
          isLoading={props.isLoading}
          isSelected={props.isSelected}
          points={props.polygon}
          onPolygonClicked={props.onPolygonClicked}
          zIndex={props.areaPolygonZIndex}
        />
      ) : (
        <EditingAreaPolygon
          key={`editing-polygon-for-area-${props.polygonId}`}
          draggableVertices={props.areaEditionMode !== EditingAreaType.EditingPlanPoints}
          editingPolygon={props.polygon}
          onVertexMoved={props.onVertexMoved}
          onClickPolygon={props.onPolygonClicked}
          polygonZIndex={props.areaPolygonZIndex}
          polylineZIndex={props.areaEditingPolygonPolylineZIndex}
          borderMarkersZIndex={props.areaEditingPolygonMarkersZIndex}
        />
      )}
      {!isLoading &&
        props.isSelected &&
        (editingWaypoints === editingPolygon || editingWaypoints) && (
          <PlannedArea
            key={`planned-area-for-${props.polygonId}`}
            polygonId={props.polygonId}
            editing={editingWaypoints}
            waypoints={props.waypoints}
            waypointScale={controlAdaptivePlannedAreaRender?.waypointScale}
            showDistanceLabels={controlAdaptivePlannedAreaRender?.showDistanceLabels}
            homePoint={props.homePoint}
            homePointElevation={props.homePointElevation}
            formatHeight={props.formatHeight}
            selectedWaypointIndexes={props.selectedWaypointsIndexes}
            onHomePointMoved={props.onHomePointMoved}
            unitSystem={props.unitSystem}
            onClickWaypoint={props.onClickWaypoint}
            onWaypointMoved={props.onWaypointMoved}
            onClickPlannedRoute={props.onClickPlannedRoute}
            showWaypointDistances={props.showWaypointDistances}
            usingOnlineElevation={props.usingOnlineElevation}
            homePointMarkerZIndex={props.homePointMarkerZIndex}
            homePointElevationMarkerZIndex={props.homePointElevationMarkerZIndex}
            homePointElevationLabelZIndex={props.homePointElevationLabelZIndex}
            plannedPathZIndex={props.plannedPathZIndex}
            plannedPathBorderZIndex={props.plannedPathBorderZIndex}
            waypointsDistanceMarkerZIndex={props.waypointsDistanceMarkerZIndex}
            waypointsDistanceLabelZIndex={props.waypointsDistanceLabelZIndex}
            waypointOrientationMarkerZIndex={props.waypointOrientationMarkerZIndex}
            waypointDropMarkerZIndex={props.waypointDropMarkerZIndex}
            waypointElevationMarkerZIndex={props.waypointElevationMarkerZIndex}
            waypointElevationLabelZIndex={props.waypointElevationLabelZIndex}
          />
        )}
    </>
  );
}

type ControlAdaptivePlannedAreaRender = { waypointScale: number; showDistanceLabels: boolean };
