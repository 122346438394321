import { Location } from "biohub-model";

import { BiohubErrorCode, BiohubResponse, extractBiohubErrorResponse } from "./axios/BiohubApi";

export type GoogleMapsSearchInfo = {
  placeId: string;
  location: Location;
  formattedAddress: string;
};

export type AddressSearchInfo = {
  state: string;
  address: string;
  city: string;
  district: string;
  country: string;
  location: Location;
  formattedAddress: string;
  postalCode: string;
  countryShort: string;
};

function getGeocodingPlace(input: string): Promise<BiohubResponse<GoogleMapsSearchInfo>> {
  return new Promise((resolve, _) => {
    const geocoding = new google.maps.Geocoder();
    geocoding.geocode(
      {
        address: input,
      },
      (results, status) => {
        switch (status) {
          case google.maps.GeocoderStatus.OK:
            const result = (results as Array<any>)[0];
            resolve({
              success: true,
              data: {
                placeId: result["place_id"] as string,
                formattedAddress: result["formatted_address"] as string,
                location: {
                  lat: result["geometry"]["location"]["lat"](),
                  lng: result["geometry"]["location"]["lng"](),
                },
              },
            });
            // Don't forget that a call to resolve() isn't a return. Need to break.
            break;
          default:
            resolve({
              success: false,
              error: {
                errorCode: BiohubErrorCode.GEOCODER_UNKNOWN_ERROR,
              },
            });
            break;
        }
      }
    );
  });
}

function getGeocodingPlaceZip(zip: string): Promise<BiohubResponse<AddressSearchInfo>> {
  return new Promise((resolve, _) => {
    const geocoding = new google.maps.Geocoder();
    geocoding.geocode(
      {
        address: zip,
      },
      (results, status) => {
        switch (status) {
          case google.maps.GeocoderStatus.OK:
            const result = (results as Array<any>)[0];
            resolve({
              success: true,
              data: {
                state: getData(result["address_components"], "state"),
                address: getData(result["address_components"], "address"),
                city: getData(result["address_components"], "city"),
                district: getData(result["address_components"], "district"),
                country: getData(result["address_components"], "country"),
                countryShort: getData(result["address_components"], "country_short"),
                postalCode: getData(result["address_components"], "postal_code"),
                formattedAddress: result["formatted_address"] as string,
                location: {
                  lat: result["geometry"]["location"]["lat"](),
                  lng: result["geometry"]["location"]["lng"](),
                },
              },
            });
            // Don't forget that a call to resolve() isn't a return. Need to break.
            break;
          default:
            resolve({
              success: false,
              error: {
                errorCode: BiohubErrorCode.GEOCODER_UNKNOWN_ERROR,
              },
            });
            break;
        }
      }
    );
  });
}

function getData(objs: any, value: string): string {
  switch (value) {
    case "district":
      for (const obj of objs) {
        if (
          obj["types"].includes("administrative_area_level_4") ||
          obj["types"].includes("administrative_area_level_3") ||
          obj["types"].includes("sublocality") ||
          obj["types"].includes("sublocality_level_1") ||
          obj["types"].includes("sublocality_level_5")
        ) {
          return obj["long_name"];
        }
      }
      return "";

    case "address":
      for (const obj of objs) {
        if (obj["types"].includes("route")) {
          return obj["long_name"];
        }
      }
      return "";

    case "city":
      for (const obj of objs) {
        if (
          obj["types"].includes("administrative_area_level_2") ||
          obj["types"].includes("locality")
        ) {
          return obj["long_name"];
        }
      }
      return "";

    case "state":
      for (const obj of objs) {
        if (obj["types"].includes("administrative_area_level_1")) {
          return obj["long_name"];
        }
      }
      return "";

    case "country":
      for (const obj of objs) {
        if (obj["types"].includes("country")) {
          return obj["long_name"];
        }
      }
      return "";

    case "country_short":
      for (const obj of objs) {
        if (obj["types"].includes("country")) {
          return obj["short_name"];
        }
      }
      return "";

    case "postal_code":
      for (const obj of objs) {
        if (obj["types"].includes("postal_code")) {
          return obj["long_name"];
        }
      }
      return "";

    default:
      return "";
  }
}

export const searchZipCode = async (zip: string): Promise<BiohubResponse<AddressSearchInfo>> => {
  try {
    const response = await getGeocodingPlaceZip(zip);
    if (!response.success) {
      throw response.error;
    }

    return response;
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
};

export const searchPlaces = async (
  placeText: string
): Promise<BiohubResponse<GoogleMapsSearchInfo>> => {
  try {
    const response = await getGeocodingPlace(placeText);
    if (!response.success) {
      throw response.error;
    }

    return response;
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
};
