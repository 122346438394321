import { CombinedState, combineReducers } from "redux";
import { localePersistedReducer, localeReducer, LocaleState } from "./localeReducer";
import { loginPersistedReducer, loginReducer, LoginState } from "./loginReducer";
import { plansPersistedReducer, plansReducer, PlansState } from "./plansReducer";
import {
  projectTreePersistedReducer,
  projectTreeReducer,
  ProjectTreeState,
} from "./projectTreeReducer";
import { profilePersistedReducer, profileReducer, ProfileState } from "./profileReducer";
import {
  collectionsPersistedReducer,
  collectionsReducer,
  CollectionsState,
} from "./collectionsReducer";
import { dashboardPersistedReducer, dashboardReducer, DashboardState } from "./dashboardReducer";
import { weatherPersistedReducer, weatherReducer, WeatherState } from "./weatherReducer";
import { masterPersistedReducer, masterReducer, MasterState } from "./masterReducer";
import { cartPersistedReducer, cartReducer, CartState } from "./cartReducer";
import { cacheDataReducer, CacheDataState, cachePersistedReducer } from "./cacheDataReducer";
import {
  provisoryCartPersistedReducer,
  provisoryCartReducer,
  ProvisoryCartState,
} from "./provisoryCartReducer";

/// This file combines all reducers into a single root reducer, which we call the "System Reducer".

export type SystemState = {
  plans: PlansState;
  locale: LocaleState;
  login: LoginState;
  projectTree: ProjectTreeState;
  profile: ProfileState;
  collections: CollectionsState;
  dashboard: DashboardState;
  weather: WeatherState;
  master: MasterState;
  cart: CartState;
  cache: CacheDataState;
  provisoryCart: ProvisoryCartState;
};

export const systemReducer = combineReducers({
  plans: plansPersistedReducer,
  locale: localePersistedReducer,
  login: loginPersistedReducer,
  projectTree: projectTreePersistedReducer,
  profile: profilePersistedReducer,
  collections: collectionsPersistedReducer,
  dashboard: dashboardPersistedReducer,
  weather: weatherPersistedReducer,
  master: masterPersistedReducer,
  cart: cartPersistedReducer,
  cache: cachePersistedReducer,
  provisoryCart: provisoryCartPersistedReducer,
});

export type SystemCombinedState = CombinedState<{
  plans: PlansState;
  locale: LocaleState;
  login: LoginState;
  projectTree: ProjectTreeState;
  profile: ProfileState;
  collections: CollectionsState;
  dashboard: DashboardState;
  weather: WeatherState;
  master: MasterState;
  cart: CartState;
  cache: CacheDataState;
  provisoryCart: ProvisoryCartState;
}>;
