import React from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Container, Grid } from "@material-ui/core";
import Footer from "../../../components/Atomic/Footer";
import Text from "../../../components/Atomic/BasicComponents/Text";
import Button from "../../../components/Atomic/BasicComponents/Button";
import Banner from "../../../components/Atomic/Tools/Banner";
import Image from "../../../components/Atomic/BasicComponents/Image";

import { addItemCart } from "../../../store/actions/cartActions";

import { Add } from "@material-ui/icons";

import { PricesPage, PlanesContainer, PlanTextMixed } from "../styles";

// Images
import imageBackground from "../../../assets/background/plane_and_prices.png";
import logoBioMapsHexa from "../../../assets/logo/logo_bioMAPS_hexagonal.svg";
import logoBioHubHexa from "../../../assets/logo/logo_bioHUB_hexagonal.svg";
import logoBioBotHexa from "../../../assets/logo/logo_bioBOT_hexagonal.svg";
import iconCheck from "../../../assets/icon/icon_check.svg";
import iconNoCheck from "../../../assets/icon/icon_noCheck.svg";
import Acordeon from "../../../components/v2/Acordeon";

interface Props {}

export default (props: Props) => {
  const intl = useIntl();

  //terms
  const termBannerTitle = intl.formatMessage({ id: "info.banner.plan.title" });
  const termPlan = intl.formatMessage({ id: "info.plan" });
  const termPlanTypeMonthly = intl.formatMessage({ id: "info.plan.type.monthly" });
  const termPlanTypeYearly = intl.formatMessage({ id: "info.plan.type.yearly" });
  const termPlanNameBiohub = intl.formatMessage({ id: "info.plan.name.bioHUB" });
  const termPlanNameBiobot = intl.formatMessage({ id: "info.plan.name.bioBOT" });
  const termPlanNameBiomaps = intl.formatMessage({ id: "info.plan.name.bioMAPS" });
  const termPlanSubtitleBiomaps = intl.formatMessage({ id: "info.plan.subTitle.bioMAPS" });
  const termPlanDescriptionBiomaps = intl.formatMessage({ id: "info.plan.description.bioMAPS" });
  const termPlanPerDevice = intl.formatMessage({ id: "info.plan.perDevice" });
  const termPlanOfDiscount = intl.formatMessage({ id: "info.plan.ofDiscount" });
  const termCoin = intl.formatMessage({ id: "info.coin" });
  const termChoose = intl.formatMessage({ id: "info.termChoose" });
  const termComparisonTitle = intl.formatMessage({ id: "info.termComparisonTitle" });
  const termAccessComparisonTitleBioHUB = intl.formatMessage({
    id: "info.termComparison.accessTitleBioHUB",
  });
  const termAccessComparisonTitleBioMAPS = intl.formatMessage({
    id: "info.termComparison.accessTitleBioMAPS",
  });
  const termAccessComparisonTitleBioBOT = intl.formatMessage({
    id: "info.termComparison.accessTitleBioBOT",
  });
  const termComparisonEquipment = intl.formatMessage({ id: "info.termComparisonEquipment" });
  const termComparisonHectare = intl.formatMessage({ id: "info.termComparisonHectare" });
  const termComparisonPerEquipment = intl.formatMessage({ id: "info.termComparisonPerEquipment" });
  const termComparisonMonth = intl.formatMessage({ id: "info.termComparisonMonth" });
  const termComparisonMonthDevice = intl.formatMessage({ id: "info.termComparisonMonthDevice" });
  const termComparisonYear = intl.formatMessage({ id: "info.termComparisonYear" });
  const termComparisonYearDevice = intl.formatMessage({ id: "info.termComparisonYearDevice" });
  const termComparisonDescription = intl.formatMessage({ id: "info.termComparisonDescription" });
  const termComparisonDescriptionBioHUB = intl.formatMessage({
    id: "info.termComparisonDescriptionBioHUB",
  });
  const termAddCart = intl.formatMessage({ id: "info.addCart" });
  const termPurchase = intl.formatMessage({ id: "info.purchase" });

  const comparisonPlanes = [
    {
      id: "COMP01",
      name: termPlanNameBiohub,
      priceMonth: 200,
      priceYear: 2040,
      priceHectar: 0,
      accessBioHUB: true,
      accessBioMAPS: false,
      accessBioBOT: false,
      isEachDevice: false,
      isEachEquip: false,
      image: logoBioHubHexa,
      title: termPlanNameBiohub,
      price: 200,
      isEquipment: true,
    },
    {
      id: "COMP02",
      name: termPlanNameBiomaps,
      priceMonth: 21,
      priceYear: 122.4,
      priceHectar: 0,
      accessBioHUB: false,
      accessBioMAPS: true,
      accessBioBOT: false,
      isEachDevice: true,
      isEachEquip: false,
      image: logoBioMapsHexa,
      title: termPlanNameBiomaps,
      price: 250.5,
      isEquipment: false,
    },
    {
      id: "COMP03",
      name: termPlanNameBiobot,
      priceMonth: 1000,
      priceYear: 0,
      priceHectar: 1,
      accessBioHUB: false,
      accessBioMAPS: false,
      accessBioBOT: true,
      isEachDevice: false,
      isEachEquip: true,
      image: logoBioBotHexa,
      title: termPlanNameBiobot,
      price: 300.75,
      isEquipment: true,
    },
  ];

  const plans = [
    {
      id: "PLAN1",
      title: termPlan + " " + termPlanNameBiohub,
      subTitle: termPlanSubtitleBiomaps,
      name: termPlanNameBiohub,
      description: termPlanDescriptionBiomaps,
      image: logoBioHubHexa,
      priceMonthly: 12,
      priceYearly: 122.4,
      discountPercent: 15,
      price: 500,
      isEquipment: false,
    },
    {
      id: "PLAN2",
      title: termPlan + " " + termPlanNameBiomaps,
      subTitle: termPlanSubtitleBiomaps,
      name: termPlanNameBiomaps,
      description: termPlanDescriptionBiomaps,
      image: logoBioMapsHexa,
      priceMonthly: 30,
      priceYearly: 150,
      discountPercent: 15,
      price: 80.45,
      isEquipment: false,
    },
    {
      id: "PLAN3",
      title: termPlan + " " + termPlanNameBiobot,
      subTitle: termPlanSubtitleBiomaps,
      name: termPlanNameBiobot,
      description: termPlanDescriptionBiomaps,
      image: logoBioBotHexa,
      priceMonthly: 50,
      priceYearly: 200,
      discountPercent: 15,
      price: 147.15,
      isEquipment: true,
    },
  ];

  const dispatch = useDispatch();
  const handleAddItemCart = async (item: any) => {
    dispatch(addItemCart(item));
  };

  return (
    <>
      <PricesPage>
        <Banner title={termBannerTitle} image={imageBackground} />
        <Container className={"container"}>
          <PlanesContainer>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={"acordeon-container"}>
                <Acordeon
                  multiSelect={true}
                  items={plans.map((item) => ({
                    id: item.id,
                    title: item.title,
                    body: (
                      <>
                        <Grid
                          key={item.id}
                          container
                          spacing={3}
                          className={"acordeonPlan-content"}
                        >
                          <Grid container justify="center">
                            <Grid item lg={3} md={3} sm={3} xs={12} className="logoPlan">
                              <Image src={item.image} />
                            </Grid>
                            <Grid item lg={9} md={9} sm={9} xs={12}>
                              <PlanTextMixed>
                                <Text text={termPlan} color={"primary"} size={"large"} />
                                <Text
                                  text={item.name}
                                  appearance={"title-semibold"}
                                  color={"primary"}
                                />
                              </PlanTextMixed>
                              <Text
                                text={item.subTitle}
                                appearance={"title-medium"}
                                color={"highlighted"}
                                size={"medium"}
                              />
                              <Text text={item.description} appearance={"description-block"} />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} justify={"center"} className={"infoPlane"}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Text
                                text={termChoose}
                                textAlign={"center"}
                                appearance={"title-semibold"}
                                size={"medium"}
                                color={"primary"}
                              />
                            </Grid>
                            <Grid item lg={3} md={3} sm={4} xs={12} className={"details-plans"}>
                              <Text
                                text={termPlanTypeMonthly}
                                textAlign={"center"}
                                appearance="title-semibold"
                                size={"small"}
                              />
                              <PlanTextMixed>
                                <Text
                                  text={`${termCoin}${item.priceMonthly}`}
                                  textAlign="center"
                                  appearance={"title-semibold"}
                                  size={"small"}
                                />
                                <Text
                                  text={`/ ${termComparisonMonth}`}
                                  textAlign="center"
                                  size={"medium"}
                                />
                              </PlanTextMixed>
                              <Text text={termPlanPerDevice} textAlign={"center"} size={"small"} />
                              <Button
                                text={termAddCart}
                                color={"highlighted"}
                                action={() => handleAddItemCart(item)}
                              />
                            </Grid>
                            <Grid item lg={3} md={3} sm={4} xs={12} className={"details-plans"}>
                              <Text
                                text={termPlanTypeYearly}
                                textAlign={"center"}
                                appearance="title-semibold"
                                size={"small"}
                              />
                              <PlanTextMixed>
                                <Text
                                  text={`${termCoin}${item.priceYearly}`}
                                  appearance={"title-semibold"}
                                  size={"small"}
                                />
                                <Text text={`/ ${termComparisonMonth}`} size={"medium"} />
                              </PlanTextMixed>
                              <Text text={termPlanPerDevice} textAlign={"center"} size={"small"} />
                              <Text
                                text={`(${item.discountPercent}% ${termPlanOfDiscount})`}
                                textAlign={"center"}
                                size={"small"}
                              />
                              <Button
                                text={termAddCart}
                                color={"highlighted"}
                                action={() => handleAddItemCart(item)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ),
                  }))}
                ></Acordeon>
              </Grid>
            </Grid>
          </PlanesContainer>
          <Grid container spacing={3} className={"comparationPlans-container"}>
            <Text
              text={termComparisonTitle}
              appearance={"title-semibold"}
              color={"primary"}
              size={"medium"}
            />
            <Grid container justify={"flex-end"}>
              {comparisonPlanes.map((item) => (
                <Grid
                  key={item.id}
                  item
                  lg={2}
                  md={2}
                  sm={4}
                  xs={4}
                  className={"comparationPlanes"}
                >
                  <Image src={item.image} />
                  <Text
                    text={item.name}
                    appearance={"title-bold"}
                    color={"primary"}
                    size={"small"}
                  />
                  <PlanTextMixed className={"textPlans-justify"}>
                    <Text
                      text={`${termCoin}${item.priceMonth}`}
                      appearance={"title-bold"}
                      size={"x-small"}
                    />
                    <Text
                      text={`/ ${
                        (!item.isEachDevice && !item.isEachEquip && termComparisonMonth) ||
                        (item.isEachDevice && termComparisonMonthDevice) ||
                        (item.isEachEquip && termComparisonEquipment)
                      }`}
                    />
                  </PlanTextMixed>
                  {item.priceYear > 0 && (
                    <PlanTextMixed className={"textPlans-justify"}>
                      <Text
                        text={`${termCoin}${item.priceYear}`}
                        appearance={"title-bold"}
                        size={"x-small"}
                      />
                      <Text
                        text={`/ ${
                          (!item.isEachDevice && !item.isEachEquip && termComparisonYear) ||
                          (item.isEachDevice && termComparisonYearDevice)
                        }`}
                      />
                    </PlanTextMixed>
                  )}
                  {item.priceHectar > 0 && (
                    <>
                      <Add />
                      <PlanTextMixed className={"textPlans-justify"}>
                        <Text
                          text={`${termCoin}${item.priceHectar}`}
                          appearance={"title-bold"}
                          size={"x-small"}
                        />
                        <Text text={`/ ${item.priceHectar && termComparisonHectare}`} />
                      </PlanTextMixed>
                      <Text text={termComparisonPerEquipment} appearance="info-role" />
                    </>
                  )}
                  <Button
                    text={termPurchase}
                    appearance={"highlighted"}
                    action={() => handleAddItemCart(item)}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container>
              {comparisonPlanes.map((item) => (
                <Grid container className={"listComparation"}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <PlanTextMixed>
                      <Text
                        text={`${
                          (item.name === "bioHUB" && termAccessComparisonTitleBioHUB) ||
                          (item.name === "bioMAPS" && termAccessComparisonTitleBioMAPS) ||
                          (item.name === "bioBOT" && termAccessComparisonTitleBioBOT)
                        }`}
                        appearance={"title"}
                        color={"primary"}
                        size={"medium"}
                      />
                      <Text
                        text={`${
                          (item.name === "bioHUB" && item.name) ||
                          (item.name === "bioMAPS" && item.name) ||
                          (item.name === "bioBOT" && item.name)
                        }`}
                        appearance={"title-bold"}
                        color={"primary"}
                        size={"small"}
                      />
                    </PlanTextMixed>
                    <Text
                      text={` ${
                        (item.name === "bioHUB" && termComparisonDescriptionBioHUB) ||
                        (item.name === "bioMAPS" && termComparisonDescription) ||
                        (item.name === "bioBOT" && termComparisonDescription)
                      } `}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={4}>
                    <Image src={item.accessBioHUB ? iconCheck : iconNoCheck} />
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={4}>
                    <Image src={item.accessBioMAPS ? iconCheck : iconNoCheck} />
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={4}>
                    <Image src={item.accessBioBOT ? iconCheck : iconNoCheck} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
        <Footer image={true} description={intl.formatMessage({ id: "info.about" })} />
      </PricesPage>
    </>
  );
};
