import React, { useState } from "react";
import { Button, Grid, CircularProgress, makeStyles } from "@material-ui/core";

import { useIntl } from "react-intl";
import { Fields, Actions } from "../styles";
import _ from "lodash";
import { TextField } from "../../../../components/Atomic/Inputs/Field/styles";

type NameManualDrawingAreaStageProps = {
  loading: boolean;
  onClickAddProject: (name: string) => void;
};

export default function (props: NameManualDrawingAreaStageProps): JSX.Element {
  const [name, setName] = useState<string>("");
  const intl = useIntl();

  const _newAreaNameLocalized: string = intl.formatMessage({ id: "map.newArea.areaName" });
  const _actionContinueLocalized: string = intl.formatMessage({
    id: "action.continue",
  });

  const classes = useStyles();

  return (
    <Fields>
      {/** Stage to put the area name */}
      <Grid container spacing={1}>
        {
          <>
            <Grid
              item
              xs={12}
              // style={{ maxWidth: 360 }}
            >
              <TextField
                error={name.length === 0}
                inputProps={{
                  className:
                    name.length === 0 ? classes.emptyInputColor : classes.defaultInputColor,
                }}
                size="small"
                label={_newAreaNameLocalized}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
          </>
        }
      </Grid>
      <Actions>
        <Button
          variant="contained"
          color="primary"
          disabled={name.length === 0}
          onClick={() => props.onClickAddProject(name)}
          size="small"
        >
          {props.loading ? <CircularProgress /> : _actionContinueLocalized}
        </Button>
      </Actions>
    </Fields>
  );
}

const useStyles = makeStyles({
  divider: {
    margin: 0.2,
    backgroundColor: "#EF7622",
  },
  emptyInputColor: {
    color: "#fb0a0a !important",
  },
  defaultInputColor: {
    color: "black !important",
  },
});
