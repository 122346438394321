import { applyMiddleware, compose, createStore } from "redux";
import Reactotron from "../ReactotronConfig";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import {
  registerUnauthorizedErrorCallback,
  setBiohubAuthorizationToken,
} from "../services/axios/BiohubApi";
import { logOut, LOG_OUT } from "./actions/loginActions";
import { systemReducer, SystemState } from "./reducers/systemReducer";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistConfig } from "redux-persist/es/types";
import { SET_INITIALIZED as SET_INITIALIZED_CACHE_REDUX } from "./actions/cacheDataActions";

/// This file is where a store is created using our system reducer.
///
/// The store is available for use (1) inside components using react-redux and (2) outside
/// components by using the object exported in this file.

//const store = createStore(systemReducer, applyMiddleware(thunk));

const store = createStore(
  systemReducer,
  composeWithDevTools(compose(applyMiddleware(thunk), Reactotron.createEnhancer?.()!))
);
//const persistor = persistStore(store);

const persistor = persistStore(store);

// Here, we will also register a callback to the service layer:
registerUnauthorizedErrorCallback(() => {
  // This will be called whenever there's an unauthorized error in a request.
  // We'll immediately cause a logout, and also clear the authentication token.
  store.dispatch<any>(logOut());
});

export { store, persistor };
