import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";

type Props = {
  boundingBoxDiagonalSize: number;
  significantSize: number;
  render: (isInsignificant: boolean) => JSX.Element;
};

export default function (props: Props) {
  const isSizeInsignificant = useSelector((state: SystemState) => {
    const boundingBoxScreenSizeMeters = state.projectTree.mapState.boundingBoxDiagonalSize;

    const boundingBoxScreenSizePixels = state.projectTree.diagonalScreenSize;

    if (boundingBoxScreenSizePixels === undefined) return true;

    const boundingBoxSizeMeters = props.boundingBoxDiagonalSize;

    const boundingBoxSizePixels =
      (boundingBoxScreenSizePixels * boundingBoxSizeMeters) / boundingBoxScreenSizeMeters;

    const significantSize = props.significantSize;

    const isSizeInsignificant = boundingBoxSizePixels < significantSize ? true : false;

    return isSizeInsignificant;
  });

  return props.render(isSizeInsignificant);
}
