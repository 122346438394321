import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  Switch,
  TextField,
  Select,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import FlipCard from "../../../components/Atomic/BasicComponents/FlipCard";
import Text from "../../../components/Atomic/BasicComponents/Text";
import { ManagePage, Block, Actions, SettingsPage, Toggle } from "../styles";

interface Props {}

export default (props: Props) => {
  const [active, setActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [accept, setAccept] = React.useState(false);

  //terms
  const intl = useIntl();
  const termCancel = intl.formatMessage({ id: "action.back" });
  //TODO: fixed terms - temporary
  const termTitle = intl.formatMessage({ id: "Gerenciar Licenças" });
  const termBasicTerm = intl.formatMessage({ id: "Termos básicos:" });
  const termTextResume = intl.formatMessage({
    id: "Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus sed ultrices est, non rutrum lectus. Aliquam in massa suscipit, semper sapien efficitur, efficitur neque. In lorem quam, fermentum vel volutpat ac, placerat eget odio. Phasellus at rhoncus sapien, vel sagittis turpis. Aenean sodales accumsan neque, ut posuere tellus hendrerit sit amet. Praesent non sem vitae quam laoreet consequat in in lectus. Nunc at lectus purus. Quisque nec facilisis sem, ut interdum nisl. Nam pulvinar metus arcu, in tempor tortor ultricies sit amet. Morbi eget lorem molestie, commodo nibh a, faucibus ante. Donec eleifend elit a sapien congue rutrum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus faucibus, nibh sagittis dapibus rhoncus, quam velit ornare dui, a convallis dolor massa sed ipsum. Sed quis ipsum ligula. Aliquam vel leo eu turpis euismod luctus.",
  });
  const termFunctionalities = intl.formatMessage({ id: "Funcionalidades: " });
  const termFlightControl = intl.formatMessage({ id: "Controle de vôo" });
  const termInputRate = intl.formatMessage({ id: "Taxa de insumos" });
  const termLicenseType = intl.formatMessage({ id: "Tipo de Licença" });
  const termTemporary = intl.formatMessage({ id: "Temporária" });
  const termMonthly = intl.formatMessage({ id: "Mensal" });
  const termYearly = intl.formatMessage({ id: "Anual" });
  const termPartial = intl.formatMessage({ id: "Parcial" });
  const termTotal = intl.formatMessage({ id: "Total" });
  const termActivateItems = intl.formatMessage({ id: "Ativar itens vinculados:" });
  const termViewTerm = intl.formatMessage({ id: "Ver termo completo" });
  const termSave = intl.formatMessage({ id: "Aprovar Termos e Salvar" });
  const termAccept = intl.formatMessage({ id: "action.accept" });
  const termAboutBiomaps = intl.formatMessage({ id: "Sobre o bioMAPs:" });
  const termAboutBiomapsText = intl.formatMessage({
    id: "Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus sed ultrices est, non rutrum lectus. Aliquam in massa suscipit, semper sapien efficitur, efficitur neque. In lorem quam, fermentum vel volutpat ac, placerat eget odio. Phasellus at rhoncus sapien, vel sagittis turpis. Aenean sodales accumsan neque, ut posuere tellus hendrerit sit amet. Praesent non sem vitae quam laoreet consequat in in lectus. Nunc at lectus purus. Quisque nec facilisis sem, ut interdum nisl. Nam pulvinar metus arcu, in tempor tortor ultricies sit amet. Morbi eget lorem molestie, commodo nibh a, faucibus ante. Donec eleifend elit a sapien congue rutrum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus faucibus, nibh sagittis dapibus rhoncus, quam velit ornare dui, a convallis dolor massa sed ipsum. Sed quis ipsum ligula. Aliquam vel leo eu turpis euismod luctus.",
  });
  const termAboutConfig = intl.formatMessage({ id: "Sobre o bioMAPs:" });
  const termAboutConfigText = intl.formatMessage({
    id: "Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus sed ultrices est, non rutrum lectus. Aliquam in massa suscipit, semper sapien efficitur, efficitur neque. In lorem quam, fermentum vel volutpat ac, placerat eget odio. Phasellus at rhoncus sapien, vel sagittis turpis. Aenean sodales accumsan neque, ut posuere tellus hendrerit sit amet. Praesent non sem vitae quam laoreet consequat in in lectus. Nunc at lectus purus. Quisque nec facilisis sem, ut interdum nisl. Nam pulvinar metus arcu, in tempor tortor ultricies sit amet. Morbi eget lorem molestie, commodo nibh a, faucibus ante. Donec eleifend elit a sapien congue rutrum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus faucibus, nibh sagittis dapibus rhoncus, quam velit ornare dui, a convallis dolor massa sed ipsum. Sed quis ipsum ligula. Aliquam vel leo eu turpis euismod luctus.",
  });
  const termLicenseUse = intl.formatMessage({ id: "Sobre o bioMAPs:" });
  const termLicenseUseText = intl.formatMessage({
    id: "Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus sed ultrices est, non rutrum lectus. Aliquam in massa suscipit, semper sapien efficitur, efficitur neque. In lorem quam, fermentum vel volutpat ac, placerat eget odio. Phasellus at rhoncus sapien, vel sagittis turpis. Aenean sodales accumsan neque, ut posuere tellus hendrerit sit amet. Praesent non sem vitae quam laoreet consequat in in lectus. Nunc at lectus purus. Quisque nec facilisis sem, ut interdum nisl. Nam pulvinar metus arcu, in tempor tortor ultricies sit amet. Morbi eget lorem molestie, commodo nibh a, faucibus ante. Donec eleifend elit a sapien congue rutrum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus faucibus, nibh sagittis dapibus rhoncus, quam velit ornare dui, a convallis dolor massa sed ipsum. Sed quis ipsum ligula. Aliquam vel leo eu turpis euismod luctus.",
  });

  //temporary select component
  const [licenseType, setLicenseType] = React.useState("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLicenseType(event.target.value as string);
  };

  //temporary switch component
  const [state, setState] = React.useState({
    checked: false,
  });

  const activateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <ManagePage>
        <Text text={termTitle} appearance={"block-title"} color={"light"} />
        <FlipCard
          active={active}
          frontChildren={[
            <Block elevation={1}>
              <SettingsPage>
                <Grid container spacing={1}>
                  <Grid item lg={12} xs={12}>
                    <b>{termBasicTerm}</b>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <p>{termTextResume}</p>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid container item lg={12} xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <b>{termFunctionalities}</b>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <TextField
                        margin="dense"
                        id="flightControl"
                        label={termFlightControl}
                        fullWidth
                        name="flightControl"
                        defaultValue={termTotal}
                        variant="outlined"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">s</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <TextField
                        margin="dense"
                        id="inputRate"
                        label={termInputRate}
                        fullWidth
                        name="inputRate"
                        defaultValue={termPartial}
                        variant="outlined"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">m</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid container item lg={3} sm={6} xs={12}>
                      <FormControl variant="outlined" className="select-item">
                        <InputLabel id="license-type-label">{termLicenseType}</InputLabel>
                        <Select
                          labelId="license-type-label"
                          id="license-type"
                          value={licenseType}
                          onChange={handleChange}
                          label={termLicenseType}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>{termTemporary}</MenuItem>
                          <MenuItem value={20}>{termMonthly}</MenuItem>
                          <MenuItem value={30}>{termYearly}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12} alignItems={"center"}>
                      <Toggle>
                        {termActivateItems}
                        <Switch
                          onChange={activateChange}
                          checked={state.checked}
                          name="checked"
                          color="primary"
                          inputProps={{ "aria-label": termActivateItems }}
                        />
                      </Toggle>
                    </Grid>
                  </Grid>
                </Grid>

                <Actions className={"all-terms"}>
                  <Button
                    onClick={(e) => {
                      setActive(true);
                    }}
                    variant="text"
                  >
                    <Icon>visibility</Icon> {termViewTerm}
                  </Button>

                  <Button
                    onClick={(e) => {
                      setLoading(true);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {!loading && <Icon>done</Icon>} {termSave}
                    {loading && <CircularProgress />}
                  </Button>
                </Actions>
              </SettingsPage>
            </Block>,
          ]}
          backChildren={[
            <Block elevation={1}>
              <SettingsPage>
                <Grid container spacing={1}>
                  <Grid item lg={12} xs={12}>
                    <b>{termAboutBiomaps}</b>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <p>{termAboutBiomapsText}</p>
                    <Grid item lg={12} xs={12}>
                      <b>{termAboutConfig}</b>
                    </Grid>
                    <p>{termAboutConfigText}</p>
                    <Grid item lg={12} xs={12}>
                      <b>{termLicenseUse}</b>
                    </Grid>
                    <p>{termLicenseUseText}</p>
                  </Grid>
                </Grid>

                <Actions className={"all-terms"}>
                  <Button
                    onClick={(e) => {
                      setActive(false);
                      setLoading(false);
                    }}
                    variant="text"
                  >
                    <Icon>arrow_back</Icon> {termCancel}
                  </Button>
                  <Button
                    onClick={(e) => {
                      setLoading(true);
                      setAccept(!accept);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {accept ? <Icon>done_all</Icon> : <Icon>done</Icon>} {termAccept}
                  </Button>
                </Actions>
              </SettingsPage>
            </Block>,
          ]}
        />
      </ManagePage>
    </>
  );
};
