// Module for waypoint distances calculation. This doesn't expose any components.

import { Location, UnitSystem } from "biohub-model";
import haversine from "haversine";
import { midpoint } from "../../../../../core/geometricFunctions";

function midpointLabel(p1: Location, p2: Location, unitSystem: UnitSystem, lenght = 6): string {
  // The haversine distance calculates the distance between two points on a sphere.
  var distance: number = haversine(p1, p2, {
    unit: "meter", // Doesn't support ft, so we always use meters and convert later.
    format: "{lat,lng}",
  });

  var distanceText = "";

  if (unitSystem === UnitSystem.metric) {
    // Display in kilometers when the distance is larger than a kilometer.
    if (distance > 1000) {
      distanceText = (distance / 1000).toFixed(1);
      distanceText = `${distanceText}km`;
    } else {
      distanceText = distance.toFixed(0);
      distanceText = `${distanceText}m`;
    }
  } else {
    // If the distance is more than a mile (which is five thousand two hundred and eighty feet),
    // then use miles instead, with one significant place.
    distance *= 3.28; // Convert the number from meters to feet

    if (distance > 5280) {
      distance /= 5280;
      distanceText = distance.toFixed(1);
      distanceText = `${distanceText}mi`;
    } else {
      distanceText = distance.toFixed(0);
      distanceText = `${distanceText}ft`;
    }
  }

  // while (distanceText.length < lenght) {
  //   distanceText = " " + distanceText;
  // }

  return distanceText;
}

var midpointLabelsCache: {
  plannedPath: Location[];
  unitSystem: UnitSystem;
  value: WaypointDistanceLabel[];
} | null = null;

export type WaypointDistanceLabel = {
  location: Location;
  label: string;
};

export function midpointLabels(
  plannedPath: Location[],
  unitSystem: UnitSystem
): WaypointDistanceLabel[] {
  // Use a cached value if possible.
  // This adds some overhead, but I think it's worth it because the actual
  // computation involves numerous trigonometric function calls that we can avoid.
  if (midpointLabelsCache !== null) {
    if (
      midpointLabelsCache.unitSystem === unitSystem &&
      _compareLocations(midpointLabelsCache.plannedPath, plannedPath)
    ) {
      return midpointLabelsCache.value;
    }
  }

  // Loop through the locations, two at a time, and generate a label at their midpoint,
  // with text that contains the distance between them.
  const labels: WaypointDistanceLabel[] = [];
  for (var i = 0; i < plannedPath.length - 1; i++) {
    const loc = plannedPath[i];
    const nextLoc = plannedPath[i + 1];
    const midpointLoc = midpoint(loc, nextLoc);
    const label = midpointLabel(loc, nextLoc, unitSystem);
    labels.push({
      location: midpointLoc,
      label: label,
    });
  }

  return labels;
}

function _compareLocations(l1: Location[], l2: Location[]): boolean {
  if (l1.length !== l2.length) {
    return false;
  }

  return l1.every(
    (location, index) => location.lat === l2[index].lat && location.lng === l2[index].lng
  );
}
