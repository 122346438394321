import styled from "styled-components";

type Props = {
  background?: string | "url";
  slogan?: string;
};

export const LoginPage = styled.section.attrs((props: Props) => ({
  background: props.background,
}))`
  min-height: 100vh;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: var(--background-default);

  > .MuiContainer-root {
    align-items: center;
    height: 100vh;
    display: flex;
    justify-content: center;
  }

  ${(props: Props) =>
    props.background &&
    `
    background-image: url(${props.background}); 
    :before {
      content: "";
      background: var(--overlay-85);
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    } 

    animation-duration: 30s;
    animation-name: droneAnimation;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background-attachment: fixed;

  `}

  @media screen and (max-width: 1440px) {
    background-repeat: repeat-y;
  }
`;

export const Actions = styled.span.attrs((props: Props) => ({}))`
  position: absolute;
  top: 10px;
  right: 0;

  @media screen and (max-width: 767px) {
    top: 12px;
    right: 12px;
  }
`;
