import React from "react";
import _ from "lodash";
import { Location } from "biohub-model";
import { MemoPolyline } from "../MemoMapComponents";

type EditingPolygonProps = {
  editingPolygon: Location[] | null;
  draggableVertices: boolean;
  onVertexMoved?: (index: number, newLocation: Location) => void;
  zIndex: number;
};

export default function EditingPolygon(props: EditingPolygonProps): JSX.Element {
  return props.editingPolygon ? (
    <>
      {
        <MemoPolyline
          key={"edited-polyline"}
          path={props.editingPolygon}
          options={{ zIndex: props.zIndex }}
        />
      }
      {/** Make a dotted line from the last point to the first. */}
      {props.editingPolygon.length > 2 && (
        <EditingPolyline
          key={"editing-polyline"}
          editingPolygon={props.editingPolygon}
          zIndex={props.zIndex}
        />
      )}
    </>
  ) : (
    <></>
  );
}

type EditingPolylineProps = {
  editingPolygon: Array<Location>;
  zIndex: number;
};

const _EditingPolyline = function (props: EditingPolylineProps): JSX.Element {
  const { editingPolygon } = props;
  return (
    <MemoPolyline
      key={"editing-polyline-close"}
      path={[editingPolygon[editingPolygon.length - 1], editingPolygon[0]]}
      options={{
        strokeOpacity: 0,
        icons: [
          {
            icon: { path: "M 0,-1 0,1", strokeOpacity: 1, scale: 4 },
            offset: "0",
            repeat: "20px",
          },
        ],
        zIndex: props.zIndex,
      }}
    />
  );
};

const EditingPolyline = React.memo(_EditingPolyline, (prevProps, newProps) => {
  if (!_.isEqual(prevProps.editingPolygon, newProps.editingPolygon)) return false;
  return true;
});
