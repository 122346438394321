import styled from "styled-components";
import detailsBG from "../../assets/icon/icon_report_drone_bg.svg";

type Props = {
  width?: number;
  height?: number;
  strong: boolean;
  uppercase: boolean;
  gray: boolean;
  striped: boolean;
  left: boolean;
  right: boolean;
  center: boolean;
  logo: boolean;
  icon: boolean;
  iconXS: boolean;
  item: boolean;
  area: boolean;
  contrast: boolean;
  top: boolean;
  space: boolean;
  noPadding: boolean;
  breakPage: boolean;
};

export const ReportPage = styled.table.attrs((props: Props) => ({}))`
  width: 100%;
  padding: 0;
  margin: 0;
  background: var(--white);

  table {
    border-collapse: collapse;
    thead {
      border-bottom-color: var(--dashboard-table-header);
    }
    th {
      font-family: "GalanoGrotesque-Bold";
      color: var(--dashboard-primary-bg);
      padding: 20px 5px;
      line-height: 1;
      vertical-align: baseline;

      @media print {
        padding: 2mm 1mm;
        font-size: 9pt;
      }
    }
    td {
      @media print {
        font-size: 9pt;
        vertical-align: top;
      }
    }

    table {
      td {
        @media print {
          font-size: 9pt;
          vertical-align: top;
        }
      }
    }
  }

  //@media print {
  //margin: 2.5mm;
  //border: initial;
  //border-radius: initial;
  //width: initial;
  //min-height: initial;
  //box-shadow: initial;
  //background: initial;
  //page-break-after: always;
  //}
`;

export const MapPage = styled.table.attrs((props: Props) => ({
  className: "page",
}))`
  width: 100%;
  padding: 0;
  margin: 0;
  background: var(--white);
  border-collapse: collapse;
  td {
    border: 1px solid var(--dashboard-hr);
  }

  @media print {
    margin: 2.5mm;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;

    td {
      padding: 1.2mm;
      vertical-align: middle;
    }
  }
`;

export const Table = styled.table.attrs((props: Props) => ({
  width: props.width,
}))`
  width: 100%;

  ${(props) =>
    props.width &&
    `
    width: ${props.width}%;
  `}
`;

export const Header = styled.thead.attrs((props: Props) => ({
  gray: props.gray,
}))`
  width: 100%;
  display: table-caption;
  border-bottom: 2px solid var(--dashboard-highlighted);
  background: var(--white);

  tr {
    display: inline-table;
  }

  ${(props) =>
    props.gray &&
    `
    background: var(--dashboard-table-header);
    border-bottom-color: transparent;
    th {
      text-align: center;  
      color: var(--dashboard-text-default)!important;
    }
    tr {
      padding: 10px;
    }
  `}
`;

export const Content = styled.tbody.attrs((props: Props) => ({
  striped: props.striped,
}))`
  ${(props) =>
    props.striped &&
    `
    border-collapse: collapse;
    tr:nth-of-type(odd) {
      background: var(--dashboard-background);       
    }
  `}
`;

export const Footer = styled.tfoot.attrs((props: Props) => ({
  breakPage: props.breakPage,
}))`
  width: 100%;

  ${(props) =>
    props.breakPage &&
    `
    @media print {
      page-break-after: always;
    }
  `}
`;

export const Row = styled.tr.attrs((props: Props) => ({
  noPadding: props.noPadding,
  gray: props.gray,
}))`
  width: 100%;
  padding: 2rem;

  @media print {
    padding: 2.5mm;
  }

  ${(props) =>
    props.noPadding &&
    `
    padding: 0;

    @media print {
      padding: 0;
    }
  `}

  ${(props) =>
    props.gray &&
    `
    background: var(--dashboard-table-header);
  `}
`;

export const HeadColumn = styled.th.attrs((props: Props) => ({
  left: props.left,
  right: props.right,
  width: props.width,
}))`
  text-align: left;
  font-size: 15px;

  small {
    display: block;
  }

  ${(props) =>
    props.left &&
    `
    text-align: left;
  `}

  ${(props) =>
    props.right &&
    `
    text-align: right;
  `}

  ${(props) =>
    props.width &&
    `
    width: ${props.width}%;
  `}
`;

export const Column = styled.td.attrs((props: Props) => ({
  left: props.left,
  right: props.right,
  center: props.center,
  top: props.top,
  width: props.width,
}))`
  padding: 10px;
  text-align: center;

  @media print {
    padding: 1mm;
  }

  ${(props) =>
    props.left &&
    `
    text-align: left;
  `}

  ${(props) =>
    props.right &&
    `
    text-align: right;
  `}

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  ${(props) =>
    props.top &&
    `
    vertical-align: top;
  `}

  ${(props) =>
    props.width &&
    `
    width: ${props.width}%;
  `}
`;

export const Image = styled.img.attrs((props: Props) => ({
  logo: props.logo,
  icon: props.icon,
  iconXS: props.iconXS,
  item: props.item,
  area: props.area,
}))`
  ${(props) =>
    props.logo &&
    `
    width: 120px;

    @media print {
      width: 3cm;
    }

  `}

  ${(props) =>
    props.icon &&
    `
    width: 40px;
    height: 40px;

    @media print {
      width: 8mm;
      height: 8mm;
    }

  `}

  ${(props) =>
    props.iconXS &&
    `
    width: 30px;
    height: 30px;
    margin-bottom: -5px;

    @media print {
      width: 6mm;
      height: 6mm;
    }

  `}

  ${(props) =>
    props.item &&
    `
    object-fit: cover;
    height: 285px;
    width: 100%;
    margin-top: 10px;

    @media print {
      height: 4.8cm;
      margin-top: 2mm;
    }

  `}

  ${(props) =>
    props.area &&
    `
    object-fit: cover;
    height: 100%;
    max-height: 440px;
    width: 100%;

    @media print {
      max-height: 10cm;
    }
  `}
`;

export const Info = styled.span.attrs((props: Props) => ({}))`
  display: inline-block;
  text-align: right;
`;

export const Item = styled.label.attrs((props: Props) => ({
  strong: props.strong,
  uppercase: props.uppercase,
  contrast: props.contrast,
  space: props.space,
}))`
  clear: both;
  display: block;
  font-weight: 400;
  line-height: 1.4;
  font-size: 14px;

  @media print {
    font-size: 9pt;
    line-height: 10pt;
  }

  ${(props) =>
    props.strong &&
    `
    font-weight: 600;
  `}

  ${(props) =>
    props.uppercase &&
    `
    text-transform: uppercase;
  `}

  ${(props) =>
    props.contrast &&
    `
    font-size: 16px;

    @media print {
      font-size: 11pt;
      line-height: 14pt;
    }
  `}

  ${(props) =>
    props.space &&
    `
    margin-bottom: 10px;
    @media print {
      margin-bottom: 2pt;
    }
  `}
`;

export const Title = styled.h1.attrs((props: Props) => ({}))`
  font-family: "GalanoGrotesque-Bold";
  color: var(--dashboard-menu-bg);
  font-size: 24px;
  line-height: 1;
  margin: 0;

  @media print {
    font-size: 14pt;
    line-height: 16pt;
  }
`;

export const Subtitle = styled.h2.attrs((props: Props) => ({}))`
  color: var(--dashboard-primary-bg);
  margin-top: 0;
  line-height: 1;
  display: block;

  @media print {
    font-size: 12pt;
    margin: 1mm 0 0;
  }
`;

export const Details = styled.div.attrs((props: Props) => ({}))`
  border: 1px solid var(--dashboard-box-shadow);
  border-radius: 5px;
  box-shadow: 0 0 6px var(--overlay-30);
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 10px;
  position: relative;
  background-color: white;
  background-image: url(${detailsBG});
  background-position: right 2px bottom 0;
  background-repeat: no-repeat;
  background-size: 120px;
  text-align: left;

  @media print {
    padding: 1.25mm;
    background: 3cm 3cm;
    margin: 2mm 0;
  }
`;

export const List = styled.ul.attrs((props: Props) => ({}))`
  list-style: none;
  font-size: 13px;
  margin: 0 5px 10px;

  @media print {
    font-size: 8pt;
    margin: 0 1mm 2mm;
  }
`;

export const ListItem = styled.li.attrs((props: Props) => ({}))``;

export const Block = styled.span.attrs((props: Props) => ({
  height: props.height,
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${(props) =>
    props.height &&
    `
    height: ${props.height}px;

    @media print {
      height: 1.5cm;
    }
  `}
`;

export const Description = styled.span.attrs((props: Props) => ({}))`
  border-top: 10px solid var(--dashboard-menu-bg);
  color: var(--dashboard-menu-bg);
  width: 54%;
  display: block;
  padding-top: 5px;
  padding-left: 2.5%;
  margin-left: -2%;
  font-family: "GalanoGrotesque-Bold";
  font-size: 18px;

  @media print {
    border-top-width: 1.5mm;
    padding-top: 0;
    padding-left: 3mm;
    margin-left: -2.5mm;
    font-size: 12pt;
  }
`;

export const Map = styled.iframe.attrs((props: Props) => ({
  area: props.area,
}))`
  box-shadow: none;
  background: transparent;
  border: 0;
  width: calc(100% - 2px);
  margin: 10px 0;
  height: 295px;

  @media print {
    margin: 2mm 0;
    height: 5cm;
    width: 100%;
  }

  ${(props) =>
    props.area &&
    `
    margin: 0;
    height: 460px;
    width: 100%;

    @media print {
      max-height: 10cm;
    }
  `}
`;

export const Divider = styled.hr.attrs((props: Props) => ({}))`
  box-shadow: none;
  background: transparent;
  border: 0;
  border-top: 2px dashed var(--dashboard-hr);
  margin: 14px;

  @media print {
    margin: 0 2mm;
  }
`;

export const Loading = styled.div.attrs((props: Props) => ({}))`
  display: table;
  margin: 2rem auto;
`;

export const MapReport = styled.div.attrs((props: Props) => ({}))`
  box-shadow: none;
  background: transparent;
  border: 0;
  width: calc(100% - 2px);
  margin: 10px 0;
  height: 295px;

  @media print {
    margin: 2mm 0;
    height: 5cm;
    width: 100%;
  }
`;
