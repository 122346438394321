import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { Company, Discount, ExtraAddress, Item, Representative } from "../reducers/cartReducer";
import { SystemThunk } from "../systemThunk";

export const ADD_INFO_COMPANY = "ADD_INFO_COMPANY";
export const ADD_INFO_REPRESENTATIVE = "ADD_INFO_REPRESENTATIVE";
export const ADD_ORDER_NUMBER = "ADD_ORDER_NUMBER";
export const ADD_EXTRA_ADDRESS = "ADD_EXTRA_ADDRESS";
export const CLEAR_EXTRA_ADDRESS = "CLEAR_EXTRA_ADDRESS";
export const ADD_ITEM_CART = "ADD_ITEM_CART";
export const UPDATE_AMOUNT = "UPDATE_AMOUNT";
export const CALCULATE_SUB_TOTAL = "CALCULATE_SUB_TOTAL";
export const CALCULATE_TOTAL = "CALCULATE_TOTAL";
export const ADD_DISCOUNT_CART = "ADD_DISCOUNT_CART";
export const CALCULATE_TOTAL_DISCOUNT = "CALCULATE_TOTAL_DISCOUNT";
export const CLEAR_DISCOUNT_CART = "REMOVE_DISCOUNT_CART";
export const REMOVE_ITEM_CART = "REMOVE_ITEM_CART";
export const CLEAR_CART = "CLEAR_CART";

export type CartAction =
  | {
      type: typeof ADD_INFO_COMPANY;
      payload: {
        company: Company;
      };
    }
  | {
      type: typeof ADD_INFO_REPRESENTATIVE;
      payload: {
        representative: Representative;
      };
    }
  | {
      type: typeof ADD_ORDER_NUMBER;
      payload: {
        orderNumber: number;
      };
    }
  | {
      type: typeof ADD_EXTRA_ADDRESS;
      payload: {
        extraAddress: ExtraAddress;
        type: "chargeAddress" | "sendAddress";
      };
    }
  | {
      type: typeof CLEAR_EXTRA_ADDRESS;
      payload: {
        type: "chargeAddress" | "sendAddress";
      };
    }
  | {
      type: typeof ADD_ITEM_CART;
      payload: {
        item: Item[];
      };
    }
  | {
      type: typeof UPDATE_AMOUNT;
      payload: {
        id: string;
        amount: number;
      };
    }
  | {
      type: typeof CALCULATE_SUB_TOTAL;
      payload: {
        subTotal: number;
      };
    }
  | {
      type: typeof CALCULATE_TOTAL;
      payload: {
        total: number;
      };
    }
  | {
      type: typeof ADD_DISCOUNT_CART;
      payload: {
        discount: Discount;
      };
    }
  | {
      type: typeof CALCULATE_TOTAL_DISCOUNT;
      payload: {
        totalDiscount: number;
      };
    }
  | {
      type: typeof CLEAR_DISCOUNT_CART;
    }
  | {
      type: typeof REMOVE_ITEM_CART;
      payload: {
        id: string;
      };
    }
  | {
      type: typeof CLEAR_CART;
    };

const couponList = [
  {
    id: "COUPON01",
    coupon: "TESTE-01",
    value: 80,
    isActive: true,
  },
  {
    id: "COUPON02",
    coupon: "TESTE-02",
    value: 150,
    isActive: true,
  },
  {
    id: "COUPON03",
    coupon: "TESTE-03",
    value: 200,
    isActive: true,
  },
  {
    id: "COUPON04",
    coupon: "TESTE-04",
    value: 50,
    isActive: true,
  },
  {
    id: "COUPON05",
    coupon: "TESTE-05",
    value: 300,
    isActive: true,
  },
];

export function addInfoCompany(dataCompany: Company): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: ADD_INFO_COMPANY,
      payload: {
        company: dataCompany,
      },
    });
  };
}

export function addOrderNumber(value: number): SystemThunk {
  return (dispatch, getState) => {
    const orderNumber = getState().cart.orderNumber;
    if (!orderNumber) {
      dispatch({
        type: ADD_ORDER_NUMBER,
        payload: {
          orderNumber: value,
        },
      });
    }
  };
}

export function addInfoRepresentative(dataRepresentative: Representative): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: ADD_INFO_REPRESENTATIVE,
      payload: {
        representative: dataRepresentative,
      },
    });
  };
}

export function addExtraAddress(
  typeAddress: "chargeAddress" | "sendAddress",
  dataExtraAddress: ExtraAddress
): SystemThunk {
  return async (dispatch, getState) => {
    const { chargeAddress, sendAddress } = getState().cart.extraAddress;
    if (typeAddress === "chargeAddress") {
      dispatch({
        type: ADD_EXTRA_ADDRESS,
        payload: {
          extraAddress: {
            sendAddress: sendAddress,
            chargeAddress: dataExtraAddress.chargeAddress,
          },
          type: "chargeAddress",
        },
      });
    } else {
      dispatch({
        type: ADD_EXTRA_ADDRESS,
        payload: {
          extraAddress: {
            chargeAddress: chargeAddress,
            sendAddress: dataExtraAddress.sendAddress,
          },
          type: "sendAddress",
        },
      });
    }
  };
}

export function clearExtraAddress(type: "chargeAddress" | "sendAddress"): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_EXTRA_ADDRESS,
      payload: {
        type: type,
      },
    });
  };
}

function calculateTotal(): SystemThunk {
  return async (dispatch, getState) => {
    const { subTotal, discount } = getState().cart;
    if (discount.length > 0) {
      const valueDiscount = discount.map((item) => item.value);
      const reduceDiscount = valueDiscount.reduce((value, currentValue) => {
        return value + currentValue;
      });
      const total = subTotal - reduceDiscount;
      dispatch({
        type: CALCULATE_TOTAL,
        payload: { total },
      });

      return;
    }

    dispatch({
      type: CALCULATE_TOTAL,
      payload: {
        total: subTotal,
      },
    });
  };
}

function calculateSubTotal(): SystemThunk {
  return async (dispatch, getState) => {
    const { items } = getState().cart;

    const subTotal = items
      .map((item) => item.price * item.amount)
      .reduce((value, total) => {
        return value + total;
      }, 0);

    dispatch({
      type: CALCULATE_SUB_TOTAL,
      payload: {
        subTotal,
      },
    });

    dispatch(calculateTotal());
  };
}

function calculateTotalDiscount(): SystemThunk {
  return async (dispatch, getState) => {
    const { discount } = getState().cart;
    if (discount.length > 0) {
      const valueDiscount = discount.map((item) => item.value);
      const totalDiscount = valueDiscount.reduce((value: number, currentValue: number) => {
        return value + currentValue;
      });

      dispatch({
        type: CALCULATE_TOTAL_DISCOUNT,
        payload: {
          totalDiscount,
        },
      });
    }
  };
}

export function addItemCart(item: Item): SystemThunk {
  return async (dispatch, getState) => {
    const cart = getState().cart;
    const cartItemExists = cart.items.find((i) => i.title === item.title && i);

    if (cartItemExists) {
      cartItemExists.amount += 1;
      const itemUpdateAmount = cart.items.map((i) => (i.title === item.title ? cartItemExists : i));
      dispatch({
        type: ADD_ITEM_CART,
        payload: {
          item: itemUpdateAmount,
        },
      });
      dispatch(calculateSubTotal());
      return;
    }

    const data = {
      ...item,
      amount: 1,
    };
    dispatch({
      type: ADD_ITEM_CART,
      payload: {
        item: [...cart.items, data],
      },
    });
    dispatch(calculateSubTotal());
  };
}

export function updateAmount(id: string, amount: number): SystemThunk {
  return async (dispatch, getState) => {
    const { items } = getState().cart;
    if (items.length > 0) {
      dispatch({
        type: UPDATE_AMOUNT,
        payload: {
          id: id,
          amount: amount,
        },
      });

      dispatch(calculateSubTotal());
    }
  };
}

export function addDiscountCart(coupon: string, intl: IntlShape): SystemThunk {
  return async (dispatch, getState) => {
    const { discount, total } = getState().cart;

    const couponExists = couponList.find((i) => i.coupon.toLowerCase() === coupon.toLowerCase());
    const couponAddExists = discount.find((i) => i.coupon.toLowerCase() === coupon.toLowerCase());

    if (!couponExists || coupon === "") {
      toast.error(intl.formatMessage({ id: "cart.message.error.coupon.invalid" }));
      return;
    }

    if (couponAddExists) {
      toast.error(intl.formatMessage({ id: "cart.message.error.coupon.add.exists" }));
      return;
    }

    if (!couponExists.isActive) {
      return;
    }

    if (total === 0) {
      return;
    }

    const newDiscount = {
      coupon: couponExists.coupon,
      value: couponExists.value,
    };

    if (newDiscount.value > total) {
      dispatch({
        type: ADD_DISCOUNT_CART,
        payload: {
          discount: {
            coupon: couponExists.coupon,
            value: total,
          },
        },
      });
      dispatch(calculateTotalDiscount());
      dispatch(calculateTotal());
      return;
    }

    dispatch({
      type: ADD_DISCOUNT_CART,
      payload: {
        discount: newDiscount,
      },
    });
    dispatch(calculateTotalDiscount());
    dispatch(calculateTotal());
  };
}

export function removeItemCart(id: string): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: REMOVE_ITEM_CART,
      payload: {
        id,
      },
    });

    dispatch(calculateSubTotal());
  };
}

export function clearDiscountCart(): SystemThunk {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_DISCOUNT_CART,
    });
    dispatch(calculateTotalDiscount());
    dispatch(calculateTotal());
  };
}

export function clearCart(): SystemThunk {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CART,
    });
  };
}
